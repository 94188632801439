import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import ControlledOpenSelect from "../../../../Component/UI/ControlledOpenSelect/ControlledOpenSelect";
import InputField from "../../../../Component/UI/InputField/InputField";
import ResultsScoreData from "../../../../MockData/ResultsScoring.json";
import { RiDeleteBinLine } from "react-icons/ri";
import CustomizedButtons from "../../../../Component/UI/Button/CustomizeButton";
import { useDispatch, useSelector } from "react-redux";
import { Select2, TextArea } from "../../../../Component/UI/CustomInputs";
import Button from "@mui/material/Button";
import axios from "../../../../config/axiosConfig";
import { token } from "../../../../assets/constants";
import TextField from "@mui/joy/TextField";
import { Editor } from "../../../../Hooks/Editor";

export const ResultScoringWrapper = ({ levels, IndexKey, data, set }) => {
  const [inputFields, setInputFields] = useState([]);
  const [respData, setRespData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [inputid, setInputid] = useState(0);
  const [emailTemplate1, setEmailTemplate1] = useState();
  const [emailTemplate2, setEmailTemplate2] = useState();
  const [emailTemplate3, setEmailTemplate3] = useState();

  useEffect(() => {
    switch (IndexKey) {
      case 0:
        setEmailTemplate1(data?.template_value);
        break;
      case 1:
        setEmailTemplate2(data?.template_value);
        break;
      case 2:
        setEmailTemplate3(data?.template_value);
        break;
      default:
    }
  }, [data]);

  const range = data?.range?.split("-");
  // console.log("key --------- ", data);
  const handleChange = (e) => {};
  // console.log(data, "dataaaaaaaaaaa");
  return (
    <>
      <Grid item xs={4}>
        <InputField
          label="Level"
          name="level"
          values={data?.level}
          InputVisible={true}
          width="100%"
          disabled={true}
          onChange={(e) =>
            set({ key: IndexKey, name: e.target.name, value: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={4}>
        <InputField
          name="title"
          label="Title"
          values={data?.title}
          InputVisible={true}
          width="100%"
          onChange={(e) =>
            set({ key: IndexKey, name: e.target.name, value: e.target.value })
          }
        />
        {/* <TextField
          name="title"
          label="Title"
          value={data?.title}
          InputVisible={true}
          width="100%"
          onChange={(e) =>
            set({ key: IndexKey, name: e.target.name, value: e.target.value })
          }
        /> */}
      </Grid>
      <Grid item xs={2}>
        <InputField
          label="Score Range"
          name="range1"
          values={data?.range1}
          placeholder={"0"}
          InputVisible={true}
          width="100%"
          onChange={(e) =>
            set({ key: IndexKey, name: e.target.name, value: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={2}>
        <InputField
          label={"."}
          labelColor={"white"}
          name="range2"
          values={data?.range2}
          placeholder="8"
          InputVisible={true}
          width="100%"
          onChange={(e) =>
            set({ key: IndexKey, name: e.target.name, value: e.target.value })
          }
        />
      </Grid>
      {/* <Grid item xs={1} style={{ marginTop: "31px" }}>
              {res.icon === "true" && (
                <RiDeleteBinLine
                  style={{ width: "30px", height: "30px", color: "#00b091" }}
                />
              )}
            </Grid> */}
      <Grid item xs={12} style={{ marginTop: "-10px" }}>
        <InputField
          label="Enterprise Description"
          name="enterpriseDescription"
          placeholder="Enterprise Description"
          values={data?.enterpriseDescription}
          InputVisible={true}
          width="100%"
          onChange={(e) =>
            set({ key: IndexKey, name: e.target.name, value: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} style={{ marginTop: "-10px" }}>
        <InputField
          label="Scoring Description"
          name="description"
          placeholder="Description"
          values={data?.description}
          InputVisible={true}
          width="100%"
          onChange={(e) =>
            set({ key: IndexKey, name: e.target.name, value: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} style={{ marginTop: "-10px" }}>
        {/* <TextArea
          label={data?.emaillabel}
          name="template_value"
          value={data?.template_value}
          onChange={(e) =>
            set({ key: IndexKey, name: e.target.name, value: e.target.value })
          }
          height="150px"
        /> */}
        {/* <Editor
          // data={emailTemplate2}
          data={data?.["template_value"]}
          change={(val) =>
            // setEmailTemplate2(val)
            set({ key: IndexKey, name: "template_value", value: val })
          }
        /> */}
        {IndexKey === 0 && (
          <Editor
            data={emailTemplate1}
            change={(val) => {
              setEmailTemplate1(val);
              set({ key: IndexKey, name: "template_value", value: val });
            }}
          />
        )}
        {IndexKey === 1 && (
          <Editor
            data={emailTemplate2}
            change={(val) => {
              setEmailTemplate2(val);
              set({ key: IndexKey, name: "template_value", value: val });
            }}
          />
        )}
        {IndexKey === 2 && (
          <Editor
            data={emailTemplate3}
            change={(val) => {
              setEmailTemplate3(val);
              set({ key: IndexKey, name: "template_value", value: val });
            }}
          />
        )}
      </Grid>
    </>
  );
};
