import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    requirement: "0,0",
    // no_of_session: "0",
    // duration: "0",
    // session_per_month: "0",
    // session_per_week: "0",
    show_availability: "0,0",
    services_for: "0",
    is_community: "0",
  },
  status: 0,
};

const serviceDetailsSlice = createSlice({
  name: "service details",
  initialState,
  reducers: {
    serviceDetails: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    statusValue: (state, action) => {
      state.status = action.payload;
    },
    resetServiceDetails: (state) => (state = initialState),
  },
});

export const { serviceDetails, resetServiceDetails, statusValue } =
  serviceDetailsSlice.actions;

export default serviceDetailsSlice.reducer;
