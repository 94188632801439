import React from "react";
import ReactApexChart from "react-apexcharts";
import styles from "../../../../assets/css/PatientDashboard/progresstrend.module.css";
export const Alertness = () => {
  const series = [
    {
      name: "Fairy Alert",
      //   data: [44, 55, 41, 67, 22, 43],
      data: [],
    },
    {
      name: "Had to be motivated",
      //   data: [13, 23, 20, 8, 13, 27],
      data: [],
    },
  ];
  const options = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    title: {
      text: "Alertness",
      align: "left",
      style: {
        fontSize: "24px",
        fontWeight: 500,
        fontFamily: "Public Sans",
        marginTop: "10px",
      },
    },
    legend: {
      show: true,
    },
    // plotOptions: {
    //   bar: {
    //     horizontal: false,
    //     borderRadius: 10,
    //   },
    // },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
      labels: {
        show: false,

        rotateAlways: false,
        hideOverlappingLabels: false,
        trim: false,
      },
    },
    noData: {
      text: "No data is available to plot the graph",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: undefined,
      },
    },

    // legend: {
    //   position: "bottom",
    //   offsetY: 40,
    // },
    // fill: {
    //   opacity: 1,
    // },
  };

  return (
    <div className={styles.mood_div}>
      <ReactApexChart
        className={styles.chart_div}
        options={options}
        series={series}
        type="bar"
        height={310}
      />
    </div>
  );
};
