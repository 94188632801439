import React from "react";
import styles from "../../../assets/css/moodcard.module.css";
import Grid from "@mui/material/Grid";
import { BsQuestionCircle } from "react-icons/bs";
import QuestionContent from "../../../Samvenda-Patient/ClientSelfcarePlan/QuestionContent";
import { useDispatch, useSelector } from "react-redux";
import instance from "../../../config/axiosConfig";
import { API } from "../../../config/constant";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import {
  setRadioOption,
  setSelectedTime,
} from "../../../Redux/Slices/QuickAsk/index";
import { useNavigate } from "../../../../node_modules/react-router-dom/index";

const Step = (props) => {
  const { question, key } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { radio_OptionId, selectedTime } = useSelector(
    (state) => state.QuickAskData
  );

  console.log(question, key, "question--");

  console.log(radio_OptionId, "radio_OptionId---");
  const handleNext = () => {
    const lastStep = props.totalSteps - 1 === props.currentStep ? true : false;
    let payloadData = {
      question_id: question.id,
      option_id: radio_OptionId,
      answer: selectedTime,
      lastStep: lastStep,
    };

    try {
      instance.post(API.QUICKASKNEXTBTNAPI, payloadData).then((res) => {
        dispatch(setRadioOption(""));
        dispatch(setSelectedTime(""));
        if (props.totalSteps - 1 === props.currentStep) {
          navigate("/patient/progresstrends");
        } else {
          props.nextStep();
        }
      });
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleBack = () => {
    props.previousStep();
  };

  const setDisabled = radio_OptionId === "" && selectedTime === "";

  return (
    <>
      <span className={styles.length}>
        <h6 className={styles.mainHeading}>
          <span>Quick Ask</span>
        </h6>
        <span className={styles.lengthInner}>
          <BsQuestionCircle style={{ color: "purple" }} />
          <h6>{`${props.currentStep} of ${props.totalSteps - 1}`}</h6>
        </span>
      </span>
      <Grid container>
        <div className={styles.moodcard}>
          <Grid item xs={12}>
            <p className={styles.title}>{question.question}</p>
          </Grid>
          <Grid item xs={12}>
            <div className={styles.questionContainer}>
              <QuestionContent question={question} id={key} />
            </div>
          </Grid>

          <div style={{ marginTop: "10px" }}>
            {/*  <Btn
              bgColor={"#000000"}
              color= "#ffffff"
              variant="contained"
              width="80px"
              marginRight= '10px'
              disabled={props.currentStep === 1}
              click={handleBack}
            >
              Back
            </Btn> */}
            <Btn
              disabled={setDisabled}
              variant="contained"
              width="80px"
              click={handleNext}
            >
              {props.totalSteps - 1 === props.currentStep ? "Save" : "Next"}
            </Btn>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default Step;
