import { useState, Fragment, useEffect } from "react";
import styles from "../../assets/css/UI/customInputs.module.css";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AiOutlineCalendar } from "react-icons/ai";
import Checkbox from "@mui/material/Checkbox";
import { TEXT } from "../contant/constant";

export const Input = (props) => {
  return (
    <div
      className={styles.input}
      style={{
        fontSize: props.labelSize,
        margin: props.margin,
        display: props.display,
        flexDirection: props.direction,
        alignItems: props.center,
        gap: props.gap,
      }}
    >
      <label
        style={{
          fontSize: props.labelSize,
          marginLeft: props.marginLeft,
          marginRight: props.marginRight,
        }}
      >
        {props.label}
      </label>
      <br />
      <input
        style={{
          width: props.width,
          height: props.height,
          marginLeft: props.marginLeft,
          marginRight: props.marginRight,
        }}
        pattern={props.name === TEXT.SCORE && props.pattern}
        type={props.type}
        name={props.name}
        // value={props.value}
        placeholder={props.placeholder || props.label}
        onChange={props.onChange}
        onWheel={(e) => e.target.blur()}
        // value={props.value}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        className={props.className}
      />
      <p style={{ color: "red", margin: "5px" }}>{props?.error?.toString()}</p>
    </div>
  );
};
