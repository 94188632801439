import styles from "../../assets/css/PatientUI/emptyevent.module.css";
import { NoEvents } from "../../assets/icons/NoEvents";
export const EmptyEvent = () => {
  return (
    <div className={styles.box}>
      <NoEvents />
      <h2 className={styles.heading}>There are no events at this time.</h2>
      <p className={styles.text}>
        Please choose a different month to see the available events.
      </p>
    </div>
  );
};
