import React, { useEffect, useState } from "react";
import AssessmentTypeStyles from "../../assets/css/assessmentType.module.css";
import Dementia from "../../assets/icons/icon/dementia screening.svg";
import { Helpcircle } from "../../assets/icons/icon/Helpcircle";
import { Buttons } from "../../Component/UI/Button/Button";
// import { AssessmentOwner } from "./assessmentOwner/AssessmentOwner";
import Button from "@mui/material/Button";
import QuestionWrapper from "./QuestionsWrapper/index";
import {
  AddData,
  qaStore,
  setCategoryId,
  selHeight,
  selWeight,
  setAssessment_for,
  SetbmiCalculate,
  setTimetaken,
} from "../../Redux/Reducers/PatientReducers/qa_storage/qa_storage";

import { useDispatch, useSelector } from "react-redux";

export const Instructions = (props) => {
  const { InitalData, weight, height } = useSelector(
    (state) => state.qa_storage
  );
  console.log(InitalData, "InitalData---");
  const { categoryData, render } = props;
  const [show, setShow] = useState(render);

  const dispatch = useDispatch();

  console.log(categoryData, "categoryData00000");

  let PreviousAssessmentResult = categoryData?.is_mapped?.assessment_data;
  let PreviousTimetaken = categoryData?.is_mapped
    ? categoryData?.is_mapped.time_taken
    : "00:00";

  let PreviousQAData =
    PreviousAssessmentResult === undefined
      ? []
      : JSON.parse(PreviousAssessmentResult);

  let PreviousHeight = PreviousQAData.filter((item) => {
    return item.question.includes("height");
  });

  let PreviousWeight = PreviousQAData.filter((item) => {
    return item.question.includes("weight");
  });

  const bmiCalculatation = weight / (height * 0.01) ** 2;

  useEffect(() => {
    dispatch(AddData(categoryData?.question));
    dispatch(setCategoryId(categoryData?.id));
    dispatch(setTimetaken(PreviousTimetaken));
    if (PreviousQAData?.length) {
      dispatch(qaStore(PreviousQAData));
      dispatch(setAssessment_for(PreviousQAData[1]?.assesment_for || "Myself"));
      dispatch(selHeight(PreviousHeight[0]?.selectedOption));
      dispatch(selWeight(PreviousWeight[0]?.selectedOption));
      dispatch(SetbmiCalculate(bmiCalculatation));
    }
  }, [categoryData]);

  const handleClick = (show) => {
    setShow(!show);
  };

  return !show ? (
    <>
      <span id="toggleInstruction" onClick={() => setShow(true)}></span>
      <QuestionWrapper
        categoryData={categoryData}
        quesData={InitalData}
        show={show}
        handleClick={handleClick}
        setStart={props.setStart}
        enterprise={props.enterprise}
      />
    </>
  ) : (
    <div
      className={AssessmentTypeStyles.inner}
      style={{ margin: "30px 0 0 0" }}
    >
      <div className={AssessmentTypeStyles.upper}>
        <div className={AssessmentTypeStyles.left}>
          <img src={Dementia} alt="dementia" />
        </div>
        <div className={AssessmentTypeStyles.right}>
          <div className={AssessmentTypeStyles.title}>
            <h1>{categoryData?.name}</h1>
          </div>
          <div className={AssessmentTypeStyles.content}>
            <p>{categoryData?.short_categoryDatacription}</p>
          </div>
          {/* <div className={AssessmentTypeStyles.noOfQuestios}>
            <span className={AssessmentTypeStyles.noOfQuestios_left}>
              <Helpcircle width="48px" height="48px" color="#7A51AE" />
            </span>
            <span className={AssessmentTypeStyles.noOfQuestios_right}>
              <div className={AssessmentTypeStyles.label}>
                <p>No. of questions</p>
              </div>
              <div className={AssessmentTypeStyles.totalNumber}>
                <p>{categoryData?.no_of_question}</p>
              </div>
            </span>
          </div> */}
        </div>
      </div>
      <div className={AssessmentTypeStyles.horizontalLine}></div>
      <div className={AssessmentTypeStyles.lower}>
        <div className={AssessmentTypeStyles.lowerTitle}>
          <h1>Instructions</h1>
        </div>
        <div className={AssessmentTypeStyles.lowerContent}>
          <div
            dangerouslySetInnerHTML={{
              __html: categoryData?.instruction?.instruction,
            }}
          />

          {/* <ul>
            <li>
              There are total {categoryData.no_of_question} questions that will
              be the part of this assessment.
            </li>
            <li>The assessment only needs to be taken on your behalf.</li>
            <li>The total time of this assessment is 15 minutes.</li>
            <li>
              The result will be shown after login/registration on the portal.
            </li>
            <li>Sed ut perspiciatis unde omnis iste natus error sit.</li>
            <li>The total time of this assessment is 15 minutes.</li>
          </ul> */}
        </div>
        <div className={AssessmentTypeStyles.button}>
          {/* <Buttons name="Start" style={{ width: "160px", height: "40px", fontSize: "18px", lineHeight: "20px", backgroundColor: "#7A51AE" }} /> */}
          <Button
            variant="contained"
            size="small"
            style={{
              textTransform: "none",
              width: "160px",
              height: "40px",
              fontSize: "18px",
              lineHeight: "20px",
              backgroundColor: "#7A51AE",
            }}
            onClick={handleClick}
          >
            Start
          </Button>
        </div>
      </div>
    </div>
  );
};
