import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AccountCard } from "../../Components/Account/AccountCard";
import styles from "./account.module.css";
import useAuth from "../../../Hooks/useAuth";
import AuthContext from "../../../Auth/AuthContext";
import { sendOnboardLink } from "../../../services/enterprise/account";
import Popup from "../Assessment/LinkPopup";
import LinkIcon from "../../../assets/icons/LinkIcon";

export const Account = () => {
  const ctx = useContext(AuthContext);
  const slug = ctx?.loginUser.enterprise.company_slug;
  const baseUrl = window.location?.origin;
  const link = `${baseUrl}/enterprise/enroll/${slug}`;

  const [popupAction, setPopupAction] = useState({
    open: false,
    name: "",
  });
  const [disable, setDisable] = useState(false);

  const onboardLinkHandler = () => {
    if (disable) return;
    setDisable(true);
    sendOnboardLink({ link })
      .then((res) => {
        if (res.data.status) {
          setPopupAction({
            ...popupAction,
            open: true,
          });
        }
      })
      .finally(() => {
        setDisable(false);
      });
  };
  return (
    <>
      <Popup
        popupAction={popupAction}
        setPopupAction={setPopupAction}
        icon={<LinkIcon />}
        children={
          <p className={styles.linktext}>
            Link has been sent to your email id.
          </p>
        }
      />
      <div className={styles.container}>
        <Link
          to="/enterprise/account/general"
          style={{ textDecoration: "none" }}
        >
          <AccountCard title="Profile" />
        </Link>

        <Link
          to="/enterprise/account/password"
          style={{ textDecoration: "none" }}
        >
          <AccountCard title="Password" />
        </Link>
        <Link
          to="/enterprise/account/testimonial"
          style={{ textDecoration: "none" }}
        >
          <AccountCard title="Testimonial" />
        </Link>
        <div onClick={onboardLinkHandler} style={{ cursor: "pointer" }}>
          <AccountCard title="Onboarding Link" isLoading={disable} />
        </div>
      </div>
    </>
  );
};
