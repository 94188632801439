import { createSlice } from "@reduxjs/toolkit";

const wellnessActivitySlice = createSlice({
  name: "wellnessActivitySlice",
  initialState: {
    Weekly: [],
    Monthly: [],
  },
  reducers: {
    setWeekly: (state, { payload }) => {
      state.Weekly = payload;
    },
    setMonthly: (state, { payload }) => {
      state.Monthly = payload;
    },
  },
});

export const { setWeekly, setMonthly } = wellnessActivitySlice.actions;

export default wellnessActivitySlice.reducer;
