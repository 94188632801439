import React from "react";

const Star = ({ color, size }) => (
  <svg
    width={size || "20"}
    height={size || "20"}
    viewBox="0 0 20 20"
    fill="none"
    style={{ marginRight: 8 }}
  >
    <path
      d="M9.99935 1.66406L12.5743 6.88073L18.3327 7.7224L14.166 11.7807L15.1493 17.5141L9.99935 14.8057L4.84935 17.5141L5.83268 11.7807L1.66602 7.7224L7.42435 6.88073L9.99935 1.66406Z"
      fill={color}
    />
  </svg>
);

export const Rating = ({ ratings, size }) => {
  return (
    <div style={{ display: "flex" }}>
      {[...Array(5).keys()].map((_, i) => (
        <Star size={size} color={ratings > i ? "#FFB800" : "#EAEAEA"} />
      ))}
    </div>
  );
};
