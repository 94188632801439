import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import PaperWrapper from "../PaperWrapper/PaperWrapper";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setFilter,
  setFrom_date,
  setSearch_key,
  setTo_date,
  setClear,
} from "../../../Redux/Slices/AdminFilters/Search";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const Active = () => {};
  useEffect(() => {
    Active();
  }, [props.active]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AllTabs = ({ ComponentName, btnApi, tag, selectedTabID, active }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setSearch_key(""));
  };
  const dispatch = useDispatch();

  let arr = Object.keys(btnApi[0]);
  useEffect(() => {
    selectedTabID?.(arr[value]);
  }, [value]);

  const ContentTag = tag ? "div" : PaperWrapper;
  return (
    <div style={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", padding: 1 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          TabIndicatorProps={{
            sx: { color: "#00b091", backgroundColor: "#00b091" },
          }}
          sx={{
            "& button": { color: "#000;", textTransform: "Capitalize" },
            "& button:hover": { color: "#000;" },
            "& button:active": { color: "#00b091;" },
            "& button:focus": { color: "#00b091;" },
            "& button.Mui-selected": { color: "#00b091;" },
          }}
        >
          {Object.keys(btnApi[0]).map((keyResult) =>
            btnApi?.map((result, id) => (
              <Tab
                label={result[keyResult]}
                {...a11yProps({ keyResult })}
                key={id}
                disableRipple
              />
            ))
          )}
        </Tabs>
      </Box>
      {ComponentName.map((res, id) => {
        return (
          <TabPanel key={id} value={value} index={id}>
            {res}
          </TabPanel>
        );
      })}
    </div>
  );
};

export default AllTabs;
