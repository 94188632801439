import { useEffect } from "react";

function useOutsideAlerter(ref, customfunc) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        customfunc();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export default function OutsideAlerter(props) {
  useOutsideAlerter(props.wrapperRef, props.customfunc);
  return (
    <span style={{ display: "contents" }} ref={props.wrapperRef}>
      {props.children}
    </span>
  );
}
