import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "../../../../../node_modules/react-router-dom/index";
import { usePermissions } from "../../../../Hooks/usePermissions";
import { ADMIN_ROUTES } from "../../../../Routes/routesConstants";
import SimpleBreadcrum from "../../../../Component/UI/SimpleBreadcrum";
import styles from "../../../../assets/css/PatientDashboard/progresstrend.module.css";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Chart from "react-apexcharts";
import axios from "../../../../config/axiosConfig";

import Nutrition from "../../../../assets/icons/ProgressTrend/Nutrition.svg";
import Cognitve from "../../../../assets/icons/ProgressTrend/Cognitve.svg";
import Selfcare from "../../../../assets/icons/ProgressTrend/Selfcare.svg";
import Social from "../../../../assets/icons/ProgressTrend/Social.svg";
import Exercise from "../../../../assets/icons/ProgressTrend/Exercise.svg";
import Sleep from "../../../../assets/icons/ProgressTrend/Sleep";
import SleepTime from "../../../../assets/icons/ProgressTrend/SleepTime";
import moment from "../../../../../node_modules/moment/moment";
import { useState } from "react";
import { PatientCalender } from "./Datecalender";
import { useEffect } from "react";
import { getMyProgressTrendData } from "../../../../services/admin/myProgresstrends";
import { setProgressData } from "../../../../Redux/Slices/AdminProgressTrends/ProgressTrend";
import MoodAreaChart from "./MoodAreaChart";
import { DailyActivityTracker } from "./DailyActivityTracker";
import BottomCardContainer from "./BottomCardContainer";

export const MyProgressTrends = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { chartData } = useSelector((state) => state.ProgressTrendSlice);
  const [sleepData, setSleepData] = useState([]);
  const [dailyTracker, setDailyTracker] = useState();
  const [streakData, setStreakData] = useState();
  const { props, name, data } = location.state;
  const permissions = usePermissions("name", "patients");
  const { state } = useLocation();

  const links = [
    {
      path: ADMIN_ROUTES.PATIENTS,
      linkText: "Patients",
    },
    {
      path: ADMIN_ROUTES.PATIENT_PROFILE,
      linkText: state?.name,
      data: { ...state, permissions },
    },
  ];

  const [dateGap, setDateGap] = useState({
    startDate: moment().startOf("month").format("DD-MM-YYYY"),
    endDate: moment().format("DD-MM-YYYY"),
  });

  const getMyProgressTrends = () => {
    console.log(dateGap, "dateGap---");
    getMyProgressTrendData({ dateGap, props }).then((res) => {
      if (res.status) {
        dispatch(setProgressData(res.data));
      } else {
        dispatch(setProgressData({}));
      }
    });
  };

  useEffect(() => {
    getMyProgressTrends();
  }, [dateGap]);

  useEffect(() => {
    if (Object.keys(chartData).length) {
      const sortedData = [...chartData.sleep].sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      );

      const dailyTrackerObject = chartData?.tracker;

      setDailyTracker(dailyTrackerObject || []);
      setSleepData(sortedData);
      setStreakData(chartData?.sreakdata);
    }
  }, [chartData]);
  console.log(chartData, "chartData000");
  console.log(dailyTracker, "dailyTracker====");

  const options2 = {
    //data on the x-axis
    chart: {
      id: "bar-chart2",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: ["#7A51AE"],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "datetime",
      labels: {
        show: true,
        datetimeUTC: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    // markers: {
    //   size: 3,
    //   hover: {
    //     size: 9,
    //   },
    // },
    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     shadeIntensity: 1,
    //     inverseColors: false,
    //     opacityFrom: 0.05,
    //     opacityTo: 0.05,
    //     stops: [20, 100, 100, 100],
    //   },
    // },
    title: {
      text: "Sleep Tracker",
      margin: 40,
      style: {
        color: "black",
        fontSize: "20px",
        fontWeight: 700,
      },
    },
    stroke: {
      curve: "smooth",
      // width: 2, to change the line width
    },
    yaxis: [
      {
        axisBorder: {
          show: true,
          color: "#e2e2e2",
        },
      },
    ],
    noData: {
      text: "There is no data available to plot the graph",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
  };

  const series = [
    //data on the y-axis
    {
      name: "Avg",
      data: sleepData?.map((item) => {
        return {
          x: item.date
            ? moment(item.date, "YYYY-MM-DD").set({
                date: Number(moment(item.date).format("DD")),
              })
            : moment().set({
                year: item.year,
                month: moment().month(item.month).format("MM") - 1,
                // date: moment([
                //   item.year,
                //   moment().month(item.month).format("MM") - 1,
                // ])
                //   .endOf("month")
                //   ._d.getDate(),
                date: 1,
              }),
          y: item.hour ?? Math.trunc(item.average),
        };
      }),
    },
  ];

  let [hours, incHours] = String(chartData?.averagesleep).split(".");

  let minutes = `0.${incHours}` * 60;
  return (
    <>
      <SimpleBreadcrum link={links} text="Progress Trends" noHeading />
      <div style={{ display: "flex", justifyContent: "end" }}>
        {" "}
        <PatientCalender dateGap={dateGap} setDateGap={setDateGap} />
      </div>
      <div className={styles.sleepContainer}>
        <Paper elevation={3} className={styles.sleepcss}>
          {/* <Chart height="300px" options={options} series={series} type="area" /> */}
          <Chart height="300px" options={options2} series={series} type="bar" />
          <div className={styles.row}>
            <div className={styles.column}>
              <div className={styles.column}>
                <Sleep />
              </div>
              <div className={styles.flexColumn}>
                <Typography variant="caption" gutterBottom>
                  Avg. sleep hours
                </Typography>
                <div className={styles.time}>
                  {hours === "undefined" ? 0 : hours} Hrs{" "}
                  {(!isNaN(minutes) && Math.trunc(minutes)) || 0} Mins
                  {/* {data?.averagesleep?.toFixed(2)} Hours */}
                </div>
              </div>
            </div>
            <div className={styles.column}>
              <div className={styles.column}>
                <SleepTime />
              </div>
              <div className={styles.flexColumn}>
                <Typography variant="caption" gutterBottom>
                  Sleep Time
                </Typography>
                <div className={styles.time}>{chartData?.sleeptime}</div>
              </div>
            </div>
          </div>
        </Paper>
        <Paper elevation={3} className={styles.sleepcss}>
          <MoodAreaChart data={chartData} />
        </Paper>
      </div>
      {dailyTracker ? <DailyActivityTracker dailyTracker={dailyTracker} /> : ""}
      <BottomCardContainer
        dailyTracker={dailyTracker}
        streakData={streakData}
      />
    </>
  );
};
