import React from "react";

export const CalenderIcon = (props) => {
  return (
    <>
      <svg
        width={props.width || "16"}
        height={props.height || "16"}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 6.66602H14"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.6667 2.66602H3.33333C2.59695 2.66602 2 3.26297 2 3.99935V13.3327C2 14.0691 2.59695 14.666 3.33333 14.666H12.6667C13.403 14.666 14 14.0691 14 13.3327V3.99935C14 3.26297 13.403 2.66602 12.6667 2.66602Z"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.667 1.33398V4.00065"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.33301 1.33398V4.00065"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
