import React, { useState, useEffect } from "react";
import BasicBreadcrumbs from "../../../Component/UI/BreadCrumb/BreadCrumbs";
import { ADMIN_ROUTES } from "../../../Routes/routesConstants";
import Avatar from "@mui/material/Avatar";
import { CustomTable } from "../../../Component/UI/Table";
import { useLocation } from "../../../../node_modules/react-router-dom/index";
import { enterpriseAssessmentListAdmin } from "../../../services/admin/enterprise";
import moment from "../../../../node_modules/moment/moment";
import defaultImage from "../../../assets/icons/icon/default_profile.svg";
import { useDispatch, useSelector } from "react-redux";
import { resetSearchFilters } from "../../../Redux/Slices/AdminFilters/Search";
import { usePermissions } from "../../../Hooks/usePermissions";
import Modal from "./Modal";
import Reschedule from "../../../assets/images/reschedule.svg";
import { encryption } from "../../../config/encryptDecrypt";
import axios from "../../../config/axiosConfig";
import { Toaster } from "../../../../node_modules/react-hot-toast/dist/index";
const EnterpriseAssessmentList = () => {
  const [assessmentList, setAssessmentList] = useState([]);
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const domain = window.location.host;
  // const nlcolumns = Object.keys(AssessmentsData[0]);
  const { from_date, to_date } = useSelector((state) => state.SearchFilters);
  const dispatch = useDispatch();
  const [joinPopup, setJoinPopup] = useState({ open: false });
  const permissions = usePermissions("name", "enterprises");
  const nlcolumns = [
    "Assessement_Name",
    "No_of_questions",
    "starts_on",
    "ends_on",
    "Action",
  ];

  const tHead = nlcolumns;

  useEffect(() => {
    if (to_date !== "Invalid date") {
      enterpriseAssessmentListAdmin({
        enterprise_id: state?.data?.id,
        from_date,
        to_date,
      })
        .then((res) => setAssessmentList(res.data.list))
        .catch((err) => console.log(err));
    }
  }, [from_date, to_date]);

  useEffect(() => {
    return () => {
      dispatch(resetSearchFilters());
    };
  }, []);

  const links = [
    {
      path: ADMIN_ROUTES.ENTERPRISES,
      linkText: "Enterprise",
    },
  ];

  console.log(state?.data, "state-===");

  if (state?.data) {
    links.push({
      path: ADMIN_ROUTES.ENTERPRISES_VIEWBOX,
      linkText: state?.data?.company_name,
      data: { ...state?.data, permissions },
    });
  }

  console.log(joinPopup, "joinPopup---");

  const reSendPayment = async () => {
    setIsLoading(true);
    const pastData = {
      assissment_id: joinPopup?.id,
      link: joinPopup?.link,
      enterprise_id: state?.data?.id,
    };
    await axios
      .post("/admin/enterprise/assessmentslink/send", pastData)
      .then((res) => {
        setIsLoading(false);
        setJoinPopup({
          open: false,
          ...joinPopup,
          successMsg: res?.data?.message,
        });
      })
      .catch((err) => console.error(err));
  };

  const tBodyData = assessmentList?.map(
    ({
      id,
      name,
      no_of_question,
      date_of_creation,
      assessment_image,
      expiry_date,
    }) => {
      const assessments_name = (
        <div
          style={{ display: "inline-flex", alignItems: "center", gap: "5px" }}
        >
          <Avatar
            style={{
              width: "28px",
              height: "28px",
              border: "1px solid #DADADA",
            }}
            src={assessment_image || defaultImage}
          />
          <p>{name}</p>
        </div>
      );
      const end_date = moment(expiry_date).format("DD/MM/YYYY");
      const start_date = moment(date_of_creation).format("DD/MM/YYYY");

      const encryptedAssessmentId = window.btoa(encryption(`${id}`));
      const link = `http://${domain}/enterprise/${state?.data?.company_slug}/assessment`;
      const encryptedLink = `${link}/${encryptedAssessmentId}`;

      const getCreatedDate = expiry_date ? moment(expiry_date) : "";
      const getCurrentDate = moment();
      const checkDateCond = moment(getCreatedDate).isBefore(getCurrentDate);
      console.log(checkDateCond, "checkDateCond");

      return {
        assessments_name,
        no_of_question,
        start_date,
        end_date,
        action: (
          <div
            style={{
              cursor: checkDateCond ? "not-allowed" : "pointer",
            }}
            fontSize="12px"
            onClick={() => {
              !checkDateCond &&
                setJoinPopup({
                  ...joinPopup,
                  open: true,
                  link: encryptedLink,
                  id: id,
                });
            }}
          >
            <img
              style={{ marginTop: "5px" }}
              src={Reschedule}
              alt="Reschedule"
            />
            {/* {encryptedLink} */}
          </div>
        ),
      };
    }
  );

  return (
    <>
      <Toaster />
      <BasicBreadcrumbs link={links} text="Assessments" isEnableCalender={true}>
        {joinPopup.open && (
          <Modal
            link={joinPopup?.link}
            textHeading={"Do you want to resend enterprise link?"}
            successMsg={joinPopup?.successMsg}
            setJoinPopup={setJoinPopup}
            label="Enterprise Link"
            reSendPayment={reSendPayment}
            isLoading={isLoading}
          />
        )}
        <CustomTable
          tHead={tHead}
          tBody={tBodyData}
          message={"No Assessments is available for this enterprise."}
        />
      </BasicBreadcrumbs>
    </>
  );
};
export default EnterpriseAssessmentList;
