import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Link,
  useLocation,
} from "../../../../../../node_modules/react-router-dom/index";
import { Avatar } from "../../../../../Component/UI/Avatar";
import SimpleBreadcrum from "../../../../../Component/UI/SimpleBreadcrum";
import { CustomTable } from "../../../../../Component/UI/Table";
import Styles from "./service.module.css";
import {
  convertCurrency,
  formatttedDate,
} from "../../../../../config/formattedDate";
import { getPatientService } from "../../../../../services/admin/patient";
import { usePermissions } from "../../../../../Hooks/usePermissions";

export const ServicesPatient = () => {
  const { state } = useLocation();
  const [services, setServices] = useState([]);
  const customerId = state?.props || state?.data?.props;
  const customerName = state?.name || state?.data?.name;
  const permissions = usePermissions("name", "patients");

  const tableHeadings = [
    "Service Name",
    "Mobile No.",
    "Session Id",
    "Appointment Date",
    "Appointment Time",
    "Status",
  ];

  const getTypeOfService = (val) => {
    switch (val) {
      case 0:
        return "Offline";
      case 1:
        return "Online";
      case 2:
        return "Hybrid";
      default:
        return "NA";
    }
  };

  useEffect(() => {
    getPatientService(customerId).then((res) => {
      if (res?.data?.status) {
        setServices(res?.data?.list);
      }
    });
  }, []);

  const tBodyData = services?.map((item) => {
    const {
      service_name,
      mobile,
      session_id,
      appointment_date,
      appointment_time,
      service_type,
    } = item;
    return {
      name: (
        <>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              height: 0,
            }}
          >
            {
              <Avatar
                size="28px"
                // profile={customer?.customer_image}
                border="1px solid #ccc"
              />
            }
            <span className={Styles.serviceName} style={{ marginLeft: "8px" }}>
              {service_name}
            </span>
          </span>
          {/* <Link
            to={"/patientsProfile/services/details"}
            style={{
              color: "#000",
              textDecoration: "none",
              display: "flex",
              pointerEvents: "auto",
            }}
            state={{
              item,
              customerId,
              customerName,
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                height: 0,
              }}
            >
              {
                <Avatar
                  size="28px"
                 // profile={customer?.customer_image}
                  border="1px solid #ccc"
                />
              }
              <span style={{ marginLeft: "8px" }}>{service_name}</span>
            </span>
          </Link> */}
        </>
      ),
      mobile: mobile,
      session_id: session_id,
      date: formatttedDate(appointment_date),
      time: appointment_time,
      service_type: getTypeOfService(service_type),
    };
  });

  const links = [
    {
      path: "/Patients",
      linkText: "Patients",
    },
    {
      path: "/patientsProfile",
      linkText: customerName,
      data: {
        permissions,
        name: customerName,
        props: customerId,
      },
    },
  ];

  return (
    <>
      <SimpleBreadcrum text="Services" link={links} />
      <CustomTable
        tHead={tableHeadings}
        tBody={tBodyData}
        message={"No Service is available for this Patient."}
      />
    </>
  );
};
