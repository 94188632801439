export const Cross = ({ color, size, active }) => {
  return (
    <div style={{ cursor: active ? "not-allowed" : "pointer" }}>
      <svg
        width={size || "20"}
        height={size || "20"}
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M15 5L5 15"
          stroke={color || "#EB0000"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 5L15 15"
          stroke={color || "#EB0000"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
