import { SubHeader } from "../../UI/SubHeader";
import styles from "../../../assets/css/dashboard.module.css";
import { ActivityCard } from "../../ActivityCard/ActivityCard";
import { MoodCard } from "../../MoodCard/MoodCard";
import { EventCards } from "../../EventCards";
import { AppointmentCards } from "../../AppointmentCards";
import { ArticleCard } from "./ArticleCard";
import { Carousel } from "../../UI/Carousel";
import { Glance } from "../../UI/Glance";
import { AssesmentResult } from "../../UI/AssessmentResult/AssesmentResult";
import { useContext, useEffect, useState } from "react";
import { getAppointmentsTabData } from "../../../services/patient/appointments";
import { bookedAppointments } from "../../../Redux/Slices/UserAppointments/bookedAppointments";
import { useDispatch, useSelector } from "react-redux";
import { Crousel2 } from "../../UI/Crousel2";
import { enrolledEvents, getEvents } from "../../../services/patient/events";
import { allEvents } from "../../../Redux/Slices/PatientEvents";
import { getEnrolledEvents } from "../../../Redux/Slices/UserEvents/enrolledEvents";
import AuthContext from "../../../Auth/AuthContext";
import PaperWrapper from "../../../Component/UI/PaperWrapper/PaperWrapper";
import bannerImg from "../../../assets/images/banner.jpg";
import bannerImgMobile from "../../../assets/images/banner-mo.webp";
import Grid from "@mui/material/Grid";
import {
  assessmentLevel,
  assessmentLevelRelative,
  getCustomerAllTakenAssessment,
  getEnterpriseAssessment,
} from "../../../services/patient/assessment";
import { dashboardAssessment } from "../../../Redux/Slices/DashboardAssessment";
import assessmentId from "../../../Redux/Slices/assessmentId";
import { Link } from "react-router-dom";

import blogImage1 from "../../../assets/images/BlogImages/Blog_image1.jpg";
import blogImage2 from "../../../assets/images/BlogImages/Blog_image2.png";
import blogImage3 from "../../../assets/images/BlogImages/Blog_image3.png";
import { Btn } from "../../UI/Button";

import CarouselSimple from "react-simply-carousel";
import { useNavigate } from "react-router-dom";
import { API } from "../../../config/constant";
import instance from "../../../config/axiosConfig";
import { Toaster } from "react-hot-toast";
import { getBlogData } from "../../../services/patient/blog";
import { Helmet } from "react-helmet";
import Popup from "../../UI/Popup";
import EnterPrisePopup from "../../UI/EnterprisePopup";
import toast from "../../../../node_modules/react-hot-toast/dist/index";
import { mobileNoSave } from "../../../services/patient/socialLogin";
import { encryption } from "../../../config/encryptDecrypt";

export const Dashboard = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [activeSlideAppointment, setActiveSlideAppointment] = useState(0);
  const [activeSlideAssessment, setActiveSlideAssessment] = useState(0);

  const ctx = useContext(AuthContext);
  const customerName = ctx.loginUser?.customer?.name;
  const navigate = useNavigate();
  const [customerAssessment, setCustomerAssessment] = useState();
  const [countryCode, setCountryCode] = useState("+91");
  // const [popupAction, setPopupAction] = useState({
  //   open: false,
  //   // open: true,
  //   name: "",
  //   title:
  //     "Please note assessments rolled out by your organization are available in Plans tab under Assessment & Plans menu option. Your organization assessment reports can be viewed in Reports menu, Enterprise tab.      ",
  // });
  const rgx =
    /^(?!.*(\d)\1{9})(?!.*1234567890)(?!^00)(\([0-9]{3}\)\s*|[0-9]{3}[-/.]?)?[0-9]{3}[-/.]?[0-9]{4}$/;

  const dispatch = useDispatch();
  const { appointments } = useSelector((i) => i.bookedAppointments);
  // const { enrolled } = useSelector((i) => i.enrolledEvents);
  const { all } = useSelector((i) => i.PatientEvents);
  const { assessment } = useSelector((i) => i.DashboardAssessment);
  console.log(assessment, "assessment--");
  const [articles, setArticles] = useState([]);

  const enterpriseId = ctx.loginUser.customer.enterprise_id;
  useEffect(() => {
    // enrolledEvents().then((i) => dispatch(getEnrolledEvents(i)));
    getAppointmentsTabData("upcoming").then((i) => {
      const filterCommunity = i?.filter((i) => i?.service?.is_community !== 1);
      dispatch(bookedAppointments(filterCommunity));
    });
    let data = [];
    let Rdata = [];
    getCustomerAllTakenAssessment().then((data) => {
      setCustomerAssessment(data);
    });
    assessmentLevel()
      .then((i) => {
        data = i.map((item) => {
          return {
            ...item,
            assessment_name: item.id === 1 ? "Yourself" : null,
          };
        });
      })
      .then(() => {
        assessmentLevelRelative()
          .then((i) => {
            Rdata = i.map((item) => {
              return {
                ...item,
                is_mapped: item.customer,
                assessment_name: item?.customer?.customer_data.name,
              };
            });
          })
          .finally(() => {
            dispatch(dashboardAssessment([...data, ...Rdata]));
          });
      });
    getActivityData();
  }, []);
  // useEffect(() => {
  //   debugger;
  //   if (!!enterpriseId) {
  //     if (
  //       ctx?.loginUser?.customer?.enterprise_id !== null &&
  //       ctx?.loginUser?.showPopup
  //     ) {
  //       setPopupAction({
  //         ...popupAction,
  //         open: true,
  //       });
  //     }
  //   } else if (ctx?.loginUser?.customer?.mobile === null) {
  //     setPopupAction({
  //       ...popupAction,
  //       open: true,
  //     });
  //   }
  // }, [enterpriseId, customerAssessment]);

  console.log(customerAssessment, "customerAssessment");

  useEffect(() => {}, []);

  useEffect(() => {
    getBlogData().then((res) => setArticles(res.data.splice(0, 3)));
  }, []);

  useEffect(() => {
    getEvents("all").then((i) => {
      dispatch(allEvents(i));
    });

    // return () => {
    //   dispatch(setToggleSearch(false));
    //   dispatch(setSearchText(""));
    //   dispatch(setDate(`All ${new Date().getFullYear()}`));
    //   dispatch(setToggle(false));
    //   dispatch(setYear(new Date().getFullYear()));
    // };
  }, []);

  const [activityData, setActivityData] = useState(null);
  const [toggle, setToggle] = useState();
  const [loaded, setLoaded] = useState(false);
  const [mobileNo, setMobileNo] = useState();
  const getActivityData = () => {
    /*     try { */
    instance
      .get(API.TODAYACTIVITY)
      .then((res) => {
        setActivityData(res.data.list);
        setToggle(res.data.list.answered.answer);
      })
      .finally(() => setLoaded(true));
    /* } catch (error) { */
    /*   console.log(error, "error"); */
    /* } finally { */
    /*   setLoaded(true); */
    /* } */
  };

  // const handleClose = () => {
  //   ctx.auth({
  //     ...ctx?.loginUser,
  //     showPopup: false,
  //   });
  //   setPopupAction({ ...popupAction, open: false });
  // };

  // const mobileNoSubmit = () => {
  //   toast.remove();
  //   toast.remove();
  //   if (!mobileNo) {
  //     toast.error("Mobile no is required");
  //     return;
  //   }
  //   const isNum = rgx.test(mobileNo);
  //   if (!isNum) {
  //     toast.error("Invalid mobile number!");
  //     return;
  //   }
  //   let newUserpaLoad = {
  //     mobile: `${countryCode}-${mobileNo}`,
  //   };
  //   submitMobileNo(newUserpaLoad);
  // };

  const [isLoadingUser, setIsLoadingUser] = useState(false);

  // const submitMobileNo = (data) => {
  //   setIsLoadingUser(true);
  //   mobileNoSave(data).then((res) => {
  //     setIsLoadingUser(false);
  //     if (res?.data?.status) {
  //       toast.success(res?.data?.msg);
  //     }
  //     ctx.auth({
  //       ...ctx?.loginUser,
  //       showPopup: false,
  //     });
  //     setPopupAction({ ...popupAction, open: false });
  //   });
  // };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>User Dashboard - Samvedna Map</title>
        <meta name="title" content="User Dashboard - Samvedna Map" />
        <link rel="canonical" href="https://map.samvednacare.com/dashboard" />
        <meta
          name="description"
          content="MAP User dashboard provides a summary view of assessments taken, upcoming appointments and events. Start your journey with us."
        />
        <meta name="robots" content="index,follow" />
      </Helmet>

      <Toaster />
      {/* <EnterPrisePopup
        popupAction={popupAction}
        setPopupAction={setPopupAction}
        title={popupAction.title}
        description={popupAction.description}
        handleClose={handleClose}
        enterpriseMobile={ctx?.loginUser?.customer}
        mobileNo={mobileNo}
        setMobileNo={setMobileNo}
        mobileNoSubmit={mobileNoSubmit}
        isLoadingUser={isLoadingUser}
        setCountryCode={setCountryCode}
        countryCode={countryCode}
        enterpriseId={enterpriseId}
      /> */}
      <div className={styles.dashboard}>
        <div>
          <SubHeader name={customerName} />
        </div>

        <div>
          <div className={styles.preview}>
            <Glance title="Appointments">
              {appointments?.length ? (
                <CarouselSimple
                  containerProps={{
                    style: {
                      position: "relative",
                    },
                  }}
                  itemsToShow={appointments?.length > 2 ? 2 : 1}
                  activeSlideIndex={activeSlideAppointment}
                  onRequestChange={setActiveSlideAppointment}
                  hideNavIfAllVisible={true}
                  speed={300}
                  dotsNav={{
                    show: false,
                  }}
                  infinite={true}
                  forwardBtnProps={{
                    children: ">",
                    style: {
                      border: "none",
                      background: "none",
                      fontSize: "20px",
                      cursor: "pointer",
                      position: "absolute",
                      top: "-35px",
                      right: "30px",
                    },
                  }}
                  backwardBtnProps={{
                    children: "<",
                    style: {
                      border: "none",
                      background: "none",
                      fontSize: "20px",
                      cursor: "pointer",
                      position: "absolute",
                      top: "-35px",
                      right: "50px",
                    },
                  }}
                >
                  {appointments?.map(
                    ({ service, appointments, customer_id }, i) => (
                      <AppointmentCards
                        name={service?.name}
                        date={
                          appointments?.[0].appointment_date?.split(" ")?.[0]
                        }
                        start_time={appointments?.[0].appointment_time}
                        endTime={appointments?.[0].end_time}
                        key={i}
                        sessionId={appointments?.[0]?.session_id}
                        customer_id={customer_id}
                        paitient_name={appointments?.[0]?.paitient_name}
                        hideButton
                        styles={styles}
                      />
                    )
                  )}
                </CarouselSimple>
              ) : (
                <div className={styles.nodataDiv}>
                  <div className={styles.NoData}>
                    <div
                      className={styles.noData}
                      style={{ marginTop: "89px", textAlign: "center" }}
                    >
                      <h3 style={{ fontSize: "17px" }}>
                        You have no appointment booked{" "}
                      </h3>
                    </div>
                  </div>
                  <div classname={styles.btnGroup}>
                    <div className={styles.deshLink}>
                      <Btn
                        click={() => {
                          navigate("/patient/services");
                        }}
                        variant="contained"
                      >
                        Book Your Service
                      </Btn>
                    </div>
                  </div>
                </div>
              )}
            </Glance>
            {/* <Glance title="Events">
              {enrolled?.length ? (
                <CarouselSimple
                  containerProps={{
                    style: {
                      position: "relative",
                    },
                  }}
                  itemsToShow={enrolled?.length > 2 ? 2 : 1}
                  activeSlideIndex={activeSlide}
                  onRequestChange={setActiveSlide}
                  speed={300}
                  infinite={true}
                  forwardBtnProps={{
                    children: ">",
                    style: {
                      border: "none",
                      background: "none",
                      fontSize: "20px",
                      cursor: "pointer",
                      position: "absolute",
                      top: "-60px",
                      right: "30px",
                    },
                  }}
                  backwardBtnProps={{
                    children: "<",
                    style: {
                      border: "none",
                      background: "none",
                      fontSize: "20px",
                      cursor: "pointer",
                      position: "absolute",
                      top: "-60px",
                      right: "50px",
                    },
                  }}
                >
                  {enrolled?.map(
                    (
                      { name, start_time, end_time, event_date, weblink, type },
                      i
                    ) => (
                      <div style={{ width: "264px" }} key={i}>
                        <EventCards
                          btnText="Join"
                          name={name}
                          start={start_time}
                          end={end_time}
                          date={event_date}
                          height="268px"
                          margin="8px 8px"
                          link={weblink}
                          type={type}
                        />
                      </div>
                    )
                  )}
                </CarouselSimple>
              ) : (
                <div className={styles.nodataDiv}>
                  <div className={styles.NoData}>
                    <div
                      className={styles.noData}
                      style={{ marginTop: "53px", textAlign: "center" }}
                    >
                      <h3 style={{ fontSize: "17px" }}>
                        Currently there are no enrolled events
                      </h3>
                    </div>
                  </div>
                  <div classname={styles.btnGroup}>
                    <div className={styles.deshLink}></div>
                  </div>
                </div>
              )}
            </Glance> */}
            <Glance title="Events" route="/patient/events">
              {all?.length ? (
                <CarouselSimple
                  containerProps={{
                    style: {
                      position: "relative",
                    },
                  }}
                  //itemsToShow={all?.length > 2 ? 2 : 1}
                  activeSlideIndex={activeSlide}
                  onRequestChange={setActiveSlide}
                  itemsToShow={1}
                  speed={300}
                  infinite={false}
                  forwardBtnProps={{
                    children: ">",
                    style: {
                      border: "none",
                      background: "none",
                      fontSize: "20px",
                      cursor: "pointer",
                      position: "absolute",
                      top: "-50px",
                      right: "30px",
                    },
                  }}
                  backwardBtnProps={{
                    children: "<",
                    style: {
                      border: "none",
                      background: "none",
                      fontSize: "20px",
                      cursor: "pointer",
                      position: "absolute",
                      top: "-50px",
                      right: "50px",
                    },
                  }}
                >
                  {all
                    ?.slice(0, 5)
                    ?.map(
                      (
                        {
                          name,
                          start_time,
                          end_time,
                          event_date,
                          weblink,
                          type,
                          event_status,
                          speaker_name,
                        },
                        i
                      ) => (
                        <div style={{ width: "264px" }} key={i}>
                          <EventCards
                            btnText="Join"
                            name={name}
                            start={start_time}
                            end={end_time}
                            date={event_date}
                            height="268px"
                            margin="8px 2px 2px 2px"
                            link={weblink}
                            type={type}
                            status={event_status}
                            speaker_name={speaker_name}
                            marginTop="20px"
                            width={"100%"}
                            dashboard={"dashboard"}
                          />
                        </div>
                      )
                    )}
                </CarouselSimple>
              ) : (
                <div className={styles.nodataDiv}>
                  <div className={styles.NoData}>
                    <div
                      className={styles.noData}
                      style={{ marginTop: "53px", textAlign: "center" }}
                    >
                      <h3 style={{ fontSize: "17px" }}>
                        Currently there are no enrolled events
                      </h3>
                    </div>
                  </div>
                  <div classname={styles.btnGroup}>
                    <div className={styles.deshLink}></div>
                  </div>
                </div>
              )}
            </Glance>
            <Glance title="Assessments">
              {assessment?.length ? (
                <CarouselSimple
                  containerProps={{
                    style: {
                      position: "relative",
                    },
                  }}
                  itemsToShow={1}
                  activeSlideIndex={activeSlideAssessment}
                  onRequestChange={setActiveSlideAssessment}
                  hideNavIfAllVisible={false}
                  speed={300}
                  dotsNav={{
                    show: false,
                  }}
                  infinite={false}
                  forwardBtnProps={{
                    children: ">",
                    style: {
                      border: "none",
                      background: "none",
                      fontSize: "20px",
                      cursor: "pointer",
                      position: "absolute",
                      top: "-50px",
                      right: "30px",
                    },
                  }}
                  backwardBtnProps={{
                    children: "<",
                    style: {
                      border: "none",
                      background: "none",
                      fontSize: "20px",
                      cursor: "pointer",
                      position: "absolute",
                      top: "-50px",
                      right: "50px",
                    },
                  }}
                >
                  {assessment?.map((item, i) => {
                    return (
                      <div className={styles.assesmentResult} key={i}>
                        <AssesmentResult data={item} seeResults />
                      </div>
                    );
                  })}
                </CarouselSimple>
              ) : (
                <div className={styles.nodataDiv}>
                  <div className={styles.NoData}>
                    <div
                      className={styles.noData}
                      style={{ marginTop: "89px", textAlign: "center" }}
                    >
                      <h3 style={{ fontSize: "17px" }}>
                        You haven't taken any assessment{" "}
                      </h3>
                    </div>
                  </div>
                  <div classname={styles.btnGroup}>
                    <div className={styles.deshLink}>
                      <Btn
                        click={() => {
                          navigate("/patient/Assessment");
                        }}
                        variant="contained"
                      >
                        Take Assessment
                      </Btn>
                    </div>
                  </div>
                </div>
              )}
            </Glance>
          </div>
        </div>
        <Grid container spacing={0} className={styles.selfCarebox}>
          <Grid item xs={12}>
            <h2 className={styles.heading}>
              <span>Wellness Tracker</span>
              <Btn
                click={() => {
                  navigate("/clientselfcareplan");
                }}
                style={{ margin: "0 10px" }}
                variant="contained"
              >
                Track Your Wellness
              </Btn>
              {/* <Link to="/clientselfcareplan">Track Your Wellness</Link> */}
            </h2>
          </Grid>
          <div className={styles.selfCare}>
            <Grid item xs={12} md={6}>
              <h6 className={styles.mainHeading}>
                <span>Today's Activity</span>
              </h6>
              <ActivityCard
                toggle={toggle}
                getActivityData={getActivityData}
                disabled={false}
                activityAnswer={activityData?.answered}
                question={activityData?.question}
                icon={activityData?.question_image}
                loaded={loaded}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MoodCard />
            </Grid>
          </div>
        </Grid>
        <div className={styles.articles}>
          <h2 className={styles.heading}>Articles to improve yourself</h2>
          <div className={styles.articleContainer}>
            {articles.map((article, i) => (
              <div key={i} className={styles.article}>
                <ArticleCard data={article} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <p
        style={{
          lineHeight: "22px",
          padding: "0 15px",
          textAlign: "left",
        }}
      >
        <b>Disclaimer:</b> Samvedna Care is not a mental health emergency
        service. In case of severe distress or suicidal thoughts, we strongly
        advise you to contact the National Helpline Numbers for Mental Health
        Support at 08046110007.
      </p>
    </>
  );
};
