export const WellBeing = (props) => {
  
  return (
    <svg
      width={props.size || "16"}
      height={props.size || "16"}
      viewBox={props.size ? `0 0 ${props.size} ${props.size}` : "0 0 16 16"}
      fill="none"
    >
      <path
        d={
          props.thickness
            ? "M24.6357 5.38178C22.2982 3.36334 19.2942 2.2502 16.1839 2.25C13.7705 2.25006 11.4062 2.92079 9.36341 4.18491C7.32063 5.44903 5.68246 7.25512 4.63764 9.39509C3.59281 11.5351 3.18383 13.9219 3.45782 16.2805C3.73182 18.6391 4.67763 20.8736 6.1863 22.7266C7.48003 24.3142 8.17813 26.0735 8.17813 27.9319V33.75H22.5884L22.5901 29.025H25.7907C26.64 29.025 27.4546 28.6931 28.0551 28.1024C28.6557 27.5116 28.993 26.7104 28.993 25.875V21.2602L32.1297 19.9404C32.6789 19.7073 32.7269 19.1608 32.4899 18.7938L28.8874 13.2199C28.4843 10.1861 26.9731 7.40023 24.6357 5.38178Z"
            : "M10.9492 2.3919C9.91033 1.49482 8.57519 1.00009 7.19283 1C6.12021 1.00003 5.06942 1.29813 4.16152 1.85996C3.25361 2.42179 2.52554 3.2245 2.06117 4.1756C1.5968 5.12669 1.41504 6.18749 1.53681 7.23577C1.65859 8.28405 2.07895 9.27717 2.74947 10.1007C3.32446 10.8063 3.63472 11.5882 3.63472 12.4142V15H10.0393L10.04 12.9H11.4626C11.84 12.9 12.202 12.7525 12.4689 12.4899C12.7358 12.2274 12.8858 11.8713 12.8858 11.5V9.449L14.2799 8.8624C14.5239 8.7588 14.5453 8.5159 14.44 8.3528L12.8388 5.8755C12.6597 4.52718 11.988 3.28899 10.9492 2.3919Z"
        }
        stroke={props.outline || "#00B091"}
      />
      <path
        d={
          props.thickness
            ? "M16.1529 12.0696L15.1314 11.0703C14.8203 10.7551 14.4482 10.5036 14.0368 10.3306C13.6253 10.1576 13.1827 10.0665 12.7349 10.0626C12.287 10.0587 11.8429 10.1421 11.4283 10.308C11.0138 10.4738 10.6372 10.7187 10.3204 11.0284C10.0036 11.3382 9.75313 11.7065 9.58345 12.112C9.41378 12.5175 9.32835 12.9519 9.33215 13.39C9.33595 13.8281 9.42891 14.2611 9.60559 14.6637C9.78228 15.0663 10.0392 15.4304 10.3612 15.7349L16.1529 21.4025L21.9446 15.7349C22.2667 15.4304 22.5235 15.0663 22.7002 14.6637C22.8769 14.2611 22.9699 13.8281 22.9737 13.39C22.9775 12.9519 22.892 12.5175 22.7224 12.112C22.5527 11.7065 22.3022 11.3382 21.9854 11.0284C21.6687 10.7187 21.292 10.4738 20.8775 10.308C20.4629 10.1421 20.0188 10.0587 19.5709 10.0626C19.1231 10.0665 18.6805 10.1576 18.2691 10.3306C17.8576 10.5036 17.4855 10.7551 17.1744 11.0703L16.1529 12.0696Z"
            : "M7.17799 5.36469L6.72399 4.92056C6.58574 4.78047 6.42035 4.66872 6.23747 4.59183C6.0546 4.51494 5.8579 4.47445 5.65886 4.47271C5.45982 4.47098 5.26242 4.50805 5.07817 4.58175C4.89393 4.65544 4.72654 4.7643 4.58576 4.90196C4.44498 5.03962 4.33364 5.20333 4.25823 5.38354C4.18282 5.56375 4.14485 5.75684 4.14654 5.95156C4.14823 6.14627 4.18954 6.33871 4.26807 6.51764C4.34659 6.69656 4.46076 6.8584 4.60391 6.99371L7.17799 9.51266L9.75207 6.99371C9.89522 6.8584 10.0094 6.69656 10.0879 6.51764C10.1664 6.33871 10.2077 6.14627 10.2094 5.95156C10.2111 5.75684 10.1732 5.56375 10.0977 5.38354C10.0223 5.20333 9.91099 5.03962 9.77022 4.90196C9.62944 4.7643 9.46204 4.65544 9.2778 4.58175C9.09356 4.50805 8.89616 4.47098 8.69711 4.47271C8.49807 4.47445 8.30138 4.51494 8.1185 4.59183C7.93563 4.66872 7.77024 4.78047 7.63199 4.92056L7.17799 5.36469Z"
        }
        stroke={props.outline || "#00B091"}
      />
    </svg>
  );
};
