import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "../../../../../node_modules/react-router-dom/index";
import {
  Datepicker,
  Input,
  Select,
} from "../../../../Component/UI/CustomInputs";
import { setSelectedWeek } from "../../../../Redux/Slices/AdminRosterManegment/RosterManagement";
import { Btn } from "../../../../Samvenda-Patient/UI/Button";
export const RightTabHeader = ({
  styles,
  selectDropDown,
  timeSlotsBooked,
  select,
  rosterPermissions,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectWeek } = useSelector((i) => i.WeekFilterSlice);
  const writePermissions = +rosterPermissions?.access?.Write;
  return (
    <div className={styles.rightTabHeaderTop}>
      <div className={styles.selectWeek}>
        <p style={{ color: "#000", fontSize: "15px" }}>Select Week</p>
        <div style={{ width: "320px" }} className={styles.options}>
          <Select
            options={selectDropDown}
            value={selectWeek}
            onChange={(e) => {
              dispatch(setSelectedWeek(e.target.value));
            }}
          />
        </div>
      </div>
      {writePermissions ? (
        <div className={styles.editButton}>
          <Btn
            bgColor="#2A2A2A"
            width="79px"
            height="40px"
            variant="contained"
            borderRadius="100px"
            // className={styles.btn}
            click={() =>
              navigate("edit", {
                state: { timeSlotsBooked, select, selectWeek, edit: true },
              })
            }
          >
            Edit
          </Btn>
        </div>
      ) : null}
    </div>
  );
};
