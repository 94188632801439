import { createSlice } from "@reduxjs/toolkit";

const DateFilterSlice = createSlice({
  name: "DateFilterSlice",
  initialState: {
    //selectedDate: `All ${new Date().getFullYear()}`,
    toggle: false,
    year: new Date().getFullYear(),
    fullDate: new Date()
  },
  reducers: {
    setDate: (state, { payload }) => {
      state.selectedDate = payload;
    },
    setToggle: (state, { payload }) => {
      state.toggle = payload;
    },
    setYear: (state, { payload }) => {
      state.year = payload;
    },
    setMonthPicker: (state, { payload }) => {
      state.fullDate = payload;
    },
  },
});

export const { setDate, setToggle, setYear, setMonthPicker } = DateFilterSlice.actions;

export default DateFilterSlice.reducer;
