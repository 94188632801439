import React, { useEffect, useState } from "react";
import styles from "./cancelModel.module.css";
import { RiCloseLine } from "react-icons/ri";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import CancelAppAd from "../../../assets/images/CancelAppAd.svg"
import CancelSuccess from "../../../assets/images/cancelSuccess.svg"
import AssignSuccess from "../../../assets/images/assignSuccess.svg"
import { CircularProgress } from "../../../../node_modules/@mui/material/index";

 export const CancelModal = ({ setOpen, loading, cancelPopup, cancelAppointmentFun }) => {
  console.log(cancelPopup, 'cancelPopup++')
  return (
    <>
      <div onClick={() => setOpen(false)} />
      <div className={styles.centered}>
        <div className={styles.modal}>
        <div className={styles.iconimg}>
            <img src={CancelAppAd} alt=""/>
        </div>
          <div className={styles.modalContent}>Do you want to cancel the appointment?</div>
          {
            loading &&
            <div style={{ textAlign: "center" }}>
          <CircularProgress size={30} />
          <p style={{ color: "black" }}>
            Please wait ! We are validating the details...
          </p>
        </div>
        }
          <div className={styles.btnGroup}>
            <Btn
              variant="contained"
              bgColor="#fff"
              color="#000"
              width="120px"
              height="40px"
              whiteSpace="nowrap"
              borderRadius="100px"
              margin = "15px"
              click={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Btn>
            <Btn
              variant="contained"
              bgColor="#00B091"
              color="#ffffff"
              width="120px"
              height="40px"
              whiteSpace="nowrap"
              borderRadius="100px"
              click={cancelAppointmentFun}
            >
              Yes
            </Btn>
          </div>
        </div>
      </div>
    </>
  );
};

export const CancelModalSuccess = ({ setCancelSuccess, cancelSuccess }) => {
  return (
    <>
      <div onClick={() => setCancelSuccess(false)} />
      <div className={styles.centered}>
        <div className={styles.modal}>
        <div className={styles.iconimg}>
            <img src={CancelSuccess} alt=""/>
        </div>
          <div className={styles.modalContent} style={{padding: '10px 10px 0 10px'}}>{cancelSuccess?.body?.data}</div>
          <div className={styles.btnGroup}>
            <Btn
              variant="contained"
              bgColor="#000"
              color="#fff"
              width="120px"
              height="40px"
              whiteSpace="nowrap"
              borderRadius="100px"
              margin = "15px"
              click={() => {
                setCancelSuccess(false);
              }}
            >
              Close
            </Btn>
           
          </div>
        </div>
      </div>
    </>
  );
};

export const AssignModalSuccess = ({ setAssignSuccess, assignSuccess }) => {
  return (
    <>
      <div  onClick={() => setAssignSuccess(false)} />
      <div className={styles.centered}>
        <div className={styles.modal}>
        <div className={styles.iconimg}>
            <img src={AssignSuccess} alt=""/>
        </div>
          <div className={styles.modalContent} style={{padding: '10px 10px 0 10px'}}>{assignSuccess?.body?.data}</div>
          <div className={styles.btnGroup}>
            <Btn
              variant="contained"
              bgColor="#000"
              color="#fff"
              width="120px"
              height="40px"
              whiteSpace="nowrap"
              borderRadius="100px"
              margin = "15px"
              click={() => {
                setAssignSuccess(false);
              }}
            >
              Close
            </Btn>
           
          </div>
        </div>
      </div>
    </>
  );
};