import React from "react";
import styles from "./thanks.module.css";
import { useEffect } from "react";
import axios from "../../config/axiosConfig";
import AssignSuccess from "../../assets/images/assignSuccess.svg";
import CancelAppAd from "../../assets/images/CancelAppAd.svg";

export const PaymentThanks = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const razorpay_payment_id = queryParameters.get("razorpay_payment_id");
  const razorpay_payment_link_id = queryParameters.get(
    "razorpay_payment_link_id"
  );
  const razorpay_signature = queryParameters.get("razorpay_signature");
  const razorpay_payment_link_reference_id = queryParameters.get(
    "razorpay_payment_link_reference_id"
  );
  const razorpay_payment_link_status = queryParameters.get(
    "razorpay_payment_link_status"
  );
  console.log(razorpay_payment_link_status, "razorpay_payment_link_status");
  useEffect(() => {
    const postBody = {
      razorpay_payment_link_id: razorpay_payment_link_id,
      razorpay_payment_id: razorpay_payment_id,
      razorpay_signature: razorpay_signature,
      razorpay_payment_link_reference_id: razorpay_payment_link_reference_id,
      razorpay_payment_link_status: razorpay_payment_link_status,
    };
    axios.post("/verifyPaymentLink", { ...postBody });
  }, []);

  return (
    <div className={styles.box}>
      <div className={styles.innerBox}>
        {/* <div className={styles.thanksWrapper}>
        <div className={styles.thanksPay}>
        <img src={AssignSuccess} alt=""/>
        </div>
        <h2>Thank You</h2>
        <p>Thank you for your purchase. I just wanted to let you know that we have received the payment for your order.</p>
        </div> */}
        {razorpay_payment_link_status === "paid" ? (
          <div className={styles.thanksWrapper}>
            <div className={styles.thanksPay}>
              <img src={AssignSuccess} alt="" />
            </div>
            <h2>Thank You</h2>
            <p>
              Thank you for making the payment. Your purchase has been
              confirmed.
            </p>
          </div>
        ) : (
          <div className={styles.errorSome}>
            <div className={styles.iconimg}>
              <img src={CancelAppAd} alt="" />
            </div>
            <h2>Something went wrong</h2>
          </div>
        )}
      </div>
    </div>
  );
};
