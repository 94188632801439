import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "../../../../../node_modules/react-hot-toast/dist/index";
import {
  useLocation,
  useNavigate,
} from "../../../../../node_modules/react-router-dom/index";
import SimpleBreadcrum from "../../../../Component/UI/SimpleBreadcrum";
import { setErrors, setStaff } from "../../../../Redux/Slices/addStaff";
import { Btn } from "../../../../Samvenda-Patient/UI/Button";
import { editStaff } from "../../../../services/admin/staff";
import { Form } from "./Form";
import styles from "./staff.module.css";
import { useState } from "react";
import { CircularProgress } from "../../../../../node_modules/@mui/material/index";

export const EditStaff = () => {
  const { staff } = useSelector((i) => i.addStaff);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const data = state;
    const d = data?.role_name?.map((i) => {
      return { value: i?.id, label: i?.name };
    });
    const r = data?.role_name?.map((i) => i?.id);
    data.role_names = d;
    data.role = r;
    dispatch(setStaff(data));
  }, []);

  const updateStaff = () => {
    setIsLoading(true);
    const formData = new FormData();
    for (let key in staff) {
      if (key === "logo" && typeof staff[key] === "string") {
        formData.append(key, "");
      } else formData.append(key, staff[key]);
    }

    editStaff(formData).then((res) => {
      setIsLoading(false);
      const errors = res?.data?.error;
      // const errorLength = Object.keys(errors).length;
      // if (errorLength) {
      //   toast.error("Please check required fields");
      // }
      dispatch(setErrors(errors));
      if (res?.data?.status) {
        navigate("/Samvedna/staff");
        toast.success(res?.data?.msg);
      }
    });
  };
  const links = [
    {
      path: "/Samvedna/staff",
      linkText: "Staff",
    },
  ];
  return (
    <div>
      <SimpleBreadcrum text="Edit Staff" link={links} />
      <div className={styles.container}>
        <Form />
        {isLoading && (
          <div>
            <div style={{ margin: "0 0 30px 0" }}>
              <CircularProgress size={30} />
              <p style={{ color: "black" }}>
                Please wait ! We are validating the details...
              </p>
            </div>
          </div>
        )}
        <div className={styles.buttons} style={{ margin: "20px 0px" }}>
          <Btn
            width="100px"
            borderRadius="100px"
            bgColor="#2A2A2A"
            disabled={isLoading}
            click={() => navigate("/Samvedna/staff")}
          >
            Cancel
          </Btn>
          <Btn
            width="100px"
            borderRadius="100px"
            bgColor="#00B091"
            click={updateStaff}
            disabled={isLoading}
          >
            Update
          </Btn>
        </div>
      </div>
    </div>
  );
};
