import React from "react";
import { AssessmentScores } from "./AssessmentScores";
import { Buttons } from "./Buttons";
import { GeneralObservation } from "./GeneralObservation";
import { IllnessHistory } from "./IllnessHistory";
import { Needs } from "./Needs";
import classes from "../../../../assets/css/assessmentsummary.module.css";
import { Summary } from "./Summary";
import BasicTabs from "../../../UI/Tab/Tabs";
import { useState } from "react";

export const AssessmentSummary = () => {
  const [count, setCount] = useState(0);

  const items = [
    {
      label: "GeneralObservation",
      component: <GeneralObservation />,
    },
    {
      label: "IllnessHistory",
      component: <IllnessHistory />,
    },
    {
      label: "AssessmentScores",
      component: <AssessmentScores />,
    },
    {
      label: "Needs",
      component: <Needs />,
    },
    {
      label: "Summary",
      component: <Summary />,
    },
  ];
  return (
    <>
      <BasicTabs tabcontent={items} setValue={setCount} value={count} />

      <div className={classes.summary_btn}>
        <Buttons />
      </div>
    </>
  );
};
