import React from "react";
import classes from "../../assets/css/search.module.css";
import { AiOutlineSearch } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  setFilter,
  setSearch_key,
} from "../../Redux/Slices/AdminFilters/Search";

export const Search = (props) => {
  const dispatch = useDispatch();

  const { search_key } = useSelector((state) => state.SearchFilters);

  const handleChange = (e) => {
    // toast.success(e.target.value);
    dispatch(setSearch_key(e.target.value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setFilter());
  };

  return (
    <div className={[classes.search_bar, classes.search_bar_height].join(" ")}>
      <AiOutlineSearch />
      <form onSubmit={handleSubmit} style={{ width: "100%", height: "100%" }}>
        <input
          disabled={props.disabled}
          id="search-bar"
          type="text"
          placeholder={props.value}
          onChange={handleChange}
          value={search_key}
        />
      </form>
    </div>
  );
};
