import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  staff: {},
  errors: {},
};

const addStaffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    setStaff: (state, action) => {
      state.staff = {
        ...state.staff,
        ...action.payload,
      };
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    clearStaff: () => initialState,
  },
});

export const { setStaff, setErrors, clearStaff } = addStaffSlice.actions;

export default addStaffSlice.reducer;
