import React from "react";

const SleepTime = () => {
  return (
    <div>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.667 10.6665V19.3332H29.3337V12.6665C29.3337 11.5619 28.4383 10.6665 27.3337 10.6665H14.667Z"
          stroke="#E69A8D"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.66699 8V24"
          stroke="#7A51AE"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.334 19.3335V24.0002"
          stroke="#7A51AE"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.3337 19.3335H2.66699"
          stroke="#7A51AE"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.66699 15.3335C9.77156 15.3335 10.667 14.4381 10.667 13.3335C10.667 12.2289 9.77156 11.3335 8.66699 11.3335C7.56242 11.3335 6.66699 12.2289 6.66699 13.3335C6.66699 14.4381 7.56242 15.3335 8.66699 15.3335Z"
          stroke="#E69A8D"
        />
      </svg>
    </div>
  );
};

export default SleepTime;
