import React from "react";
import classes from "../../../../assets/css/assessmentsummary.module.css";
import { Input, Select } from "../../../../Component/UI/CustomInputs";

export const IllnessHistory = () => {
  return (
    <div className={classes.ill_history}>
      <div className={classes.general_obv}>
        <div>
          <Input label="Predisposing Factors" placeholder="Write Details" />
        </div>
        <div>
          <Input label="Precipitating Factors" placeholder="Write Details" />
        </div>
      </div>
      <div className={classes.general_obv}>
        <div>
          <Input label="Perpetuating Factors" placeholder="Write Details" />
        </div>
        <div>
          <Input label="Medical History" placeholder="Write Details" />
        </div>
      </div>
      <div className={classes.general_obv}>
        <div>
          <Input label="Treatment History" placeholder="Write Details" />
        </div>
        <div>
          <Input label="Family History" placeholder="Write Details" />
        </div>
      </div>
      <div className={classes.general_obv}>
        <div>
          <Input label="Personal History" placeholder="Write Details" />
        </div>
        <div>
          <Input label="Behavioral Observations" placeholder="Write Details" />
        </div>
      </div>
    </div>
  );
};
