import React, { useState } from "react";
import { useSelector } from "react-redux";
import CustomizedTabs from "../../../Component/UI/Tabs/CustomizedTabs";
import Text_Seach_Filter_Export from "../../../Hooks/Text_Seach_Filter_Export";
import AllTabs from "../../../Component/UI/Tabs/AllTabs";

import ButtonName from "../../../MockData/ButtonName.json";
import { Cancelled } from "./Cancelled";
import { Completed } from "./Completed";
import { Upcoming } from "./Upcoming";
import moment from "moment";
import { usePermissions } from "../../../Hooks/usePermissions";

export const AdminEvents = () => {
  const permissions = usePermissions("name", "events");
  console.log(permissions, "permissions");
  const fullpermissions = +permissions?.listControl?.access?.["Full Control"];
  const writePermissions = +permissions?.listControl?.access?.Write;

  const btnApi = ButtonName.Events;
  let defaultTab = Object.keys(btnApi[0]);
  const [tabID, selectedTabID] = useState(defaultTab[0]);

  const ComponentName = [
    <Upcoming permissions={permissions} />,
    <Cancelled permissions={permissions} />,
    <Completed />,
  ];

  const CollectAllTabData = {
    Upcoming: useSelector((state) => state.Events.data.upcoming),
    Cancelled: useSelector((state) => state.Events.data.cancelled),
    Completed: useSelector((state) => state.Events.data.completed),
  };

  const FinalUpcomingData = CollectAllTabData.Upcoming?.map((el) => ({
    Date: moment(el.event_date).format("DD-MM-YYYY"),
    "Event Name": el.name,
    "Start Time": el.start_time,
    "End Time": el.end_time,
    Mode: el.type === 1 ? "Online" : "Offline",
    Cordinator: el.coordinator,
    Description: el.description,
    Status: el.status === 1 ? true : false,
  }));

  const FinalCancelledData = CollectAllTabData.Cancelled?.map((el) => ({
    Date: moment(el.event_date).format("DD-MM-YYYY"),
    "Event Name": el.name,
    "Start Time": el.start_time,
    "End Time": el.end_time,
    Mode: el.type === 1 ? "Online" : "Offline",
    "Event Status": el.event_status,
    Cordinator: el.coordinator,
    Description: el.description,
  }));

  const FinalCompletedData = CollectAllTabData.Completed?.map((el) => ({
    Date: moment(el.event_date).format("DD-MM-YYYY"),
    "Event Name": el.name,
    "Start Time": el.start_time,
    "End Time": el.end_time,
    Mode: el.type === 1 ? "Online" : "Offline",
    "Event Status": el.event_status,
    Cordinator: el.coordinator,
    Description: el.description,
  }));

  const obj = {
    Upcoming: FinalUpcomingData,
    Cancelled: FinalCancelledData,
    Completed: FinalCompletedData,
  };

  return (
    <div>
      <Text_Seach_Filter_Export
        text={"Events"}
        ApiData={obj[tabID]}
        CollectAllTabData={CollectAllTabData}
        route={"/Addevents"}
        searchText="Search for events"
        permissions={permissions?.listControl}
      >
        <AllTabs
          ComponentName={ComponentName}
          btnApi={btnApi}
          selectedTabID={selectedTabID}
        />
      </Text_Seach_Filter_Export>
      {/* <CustomizedTabs
        ComponentName={ComponentName}
        btnApi={btnApi}
        text={"Events"}
        route={"/Addevents"}
      /> */}
    </div>
  );
};
