import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const { KNOW_OUR_EXPERTS } = API;

export const getDoctorsList = async () => {
  return await axios.get(KNOW_OUR_EXPERTS)
    .then((res) => res)
    .catch((err) => err);
};

export const getDoctor = async () => {
  return await axios
    .get(`/customer/get/do-know-users`)
    .then((res) => res)
    .catch((err) => err);
};


export const getDoctorNew = async (id) => {
  return await axios
    .get(`/customer/get/expert/designation?designation=${id}`)
    .then((res) => res)
    .catch((err) => err);
};

