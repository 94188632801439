import React, { useContext, useState } from "react";
import { SubscriptionIcon } from "../../../assets/icons/SubscriptionIcon";
import { Badge } from "../../../Component/UI/Badge";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import styles from "./subscriptionCard.module.css";
import axios from "../../../config/axiosConfig";
import AuthContext from "../../../Auth/AuthContext";
import { toast } from "../../../../node_modules/react-hot-toast/dist/index";
import ModalSuccess from "./ModalSuccess";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";
import moment from "moment";
export const SubscriptionCard = ({
  name,
  amount,
  extended,
  validity,
  onClick,
  payment,
  subscription_id,
  apiCall,
  paymentMode,
  created_at,
  paymentLink,
  paymentStatus,
}) => {
  const getCreatedDate = created_at ? moment(created_at).add(72, "h") : "";
  const getCurrentDate = moment();

  const checkDateCond = moment(getCreatedDate).isBefore(getCurrentDate);

  console.log(getCreatedDate, "created_at");

  const oneMonth = 30;

  const convertedValidity =
    validity >= oneMonth
      ? validity / oneMonth > 1
        ? validity / oneMonth + " Months"
        : validity / oneMonth + " Month"
      : validity + " Days";

  let nf = new Intl.NumberFormat("en-US");

  // razorpay integration
  const ctx = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const customer_id = ctx?.loginUser?.customer?.id;
  const [isOpenSuccess, setIsOpenSuccess] = useState({ open: false });
  const [serviceFor, setServiceFor] = useState({
    for: customer_id,
    email: "",
    err: "",
  });

  const postBody = {
    subscription_id: subscription_id,
    customer_id: customer_id,
  };

  const loadScript = (src) => {
    setLoading(true);
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const loadPaymentUrl = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const displayRazorpay = async () => {
    const API = "/customer/subscription/generate-order";
    // Number(serviceFor.for) !== customer_id
    //   ? "/customer/generate-order-relative"
    //   : "/customer/generate-order";
    try {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }
      setLoading(false);

      const { data } = await axios.post(API, postBody);
      console.log("data-----", data);
      if (!data.status) {
        /* toast.error(data?.message); */
        setServiceFor({
          ...serviceFor,
          err: data?.message?.email,
        });
        setLoading(false);
      } else {
        if (data?.data?.payment_require) {
          const options = {
            key: process.env.REACT_APP_PAYMENT_KEY,
            currency: data.data.currency,
            amount: "0",
            order_id: data.data.order_id,
            name: name,
            description: "Thank you for choosing samvednacare.com",
            // image: 'http://localhost:1337/logo.svg',
            handler: async (response) => {
              const paymentStatus = await axios.post(
                "/customer/verify-subscription-payment",
                { ...response, ...postBody }
              );
              if (paymentStatus.data.status) {
                setIsOpenSuccess({
                  open: true,
                  body: "Your Subscription has been Activated successfully. Please check your email for further details",
                });
                toast.success(paymentStatus.data.messege);
                setLoading(false);
                // setIsOpen(false);
                // setTimeout(() => {
                //   navigate("/patient/Appointments");
                // }, 2000);
              }
            },
            modal: {
              ondismiss: function () {
                setLoading(false);
                // setIsOpen(false);
                toast.error("Your service was not booked");
              },
            },
            prefill: {
              name: ctx?.loginUser?.customer?.name,
              email: ctx?.loginUser?.customer?.email,
              phone_number: ctx?.loginUser?.customer
                ? ctx?.loginUser?.customer?.mobile
                : "",
            },
          };
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        } else {
          setLoading(false);
          // setIsOpen(false);
          setIsOpenSuccess({
            open: true,
            body: "Your request for appointment has been received. Please check your email for further details",
          });
          toast.success("Your Subscription has been Activated successfully.");
        }
      }
    } catch (err) {
      toast.error(err.response.data.messege);
      setLoading(false);
      // setIsOpen(false);
    }
  };

  return (
    <>
      {isOpenSuccess.open && (
        <ModalSuccess
          setIsOpenSuccess={setIsOpenSuccess}
          isOpenSuccess={isOpenSuccess}
          apiCall={apiCall}
        />
      )}
      <div className={styles.card}>
        <div className={styles.head}>
          <SubscriptionIcon />
          <div className={styles.badges}>
            {validity && (
              <Badge
                text={convertedValidity}
                style={{ background: "#2A2A2A", height: "24px" }}
              />
            )}
            {extended && (
              <>
                {paymentMode === "" ||
                paymentMode === "offline" ||
                ((paymentMode === "" || paymentMode === "online") &&
                  paymentStatus === 1) ? (
                  <Badge
                    text="EXTENDED"
                    style={{ background: "#18BA4B", height: "24px" }}
                  />
                ) : (
                  <Badge
                    text="PAY TO EXTEND"
                    style={{ background: "#18BA4B", height: "24px" }}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div className={styles.content}>
          <p className={styles.name}>
            {name || "Counselling with Psychologist / Doctor"}
          </p>
          <p className={styles.amount}>{`₹${nf.format(amount)}`} </p>
          {loading && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress size={30} />
            </div>
          )}
          <div className={payment ? styles.btnGroup : ""}>
            {payment && paymentMode !== "offline" ? (
              <Btn
                disabled={checkDateCond}
                className={styles.btn}
                variant="contained"
                bgColor="#7a51ae"
                click={() => loadPaymentUrl(paymentLink)}
              >
                {checkDateCond ? "Expired" : "Pay"}
              </Btn>
            ) : (
              ""
            )}

            <Btn
              className={styles.btn}
              variant="contained"
              bgColor="#7a51ae"
              click={onClick}
            >
              Details
            </Btn>
          </div>
        </div>
      </div>
    </>
  );
};
