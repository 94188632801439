import { useDispatch, useSelector } from "react-redux";
import { Input, Select } from "../../../../Component/UI/CustomInputs";
import { addPatientData } from "../../../../Redux/Slices/addPatient";
import styles from "../../../../assets/css/AdminPatients/profile.module.css";
import { useEffect, useState } from "react";
import { formattedLocations } from "../../../../config/formattedLocations";
import {
  listCity,
  listCountries,
  listState,
} from "../../../../services/others/locations";

export const Address = ({ data, err, writePermissions }) => {
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(addPatientData({ [name]: value }));
  };

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    listCountries().then((res) => {
      const countries = formattedLocations(res, "name");
      setCountries(countries);
    });

    data?.country &&
      listState(data?.country).then((res) => {
        const states = formattedLocations(res, "name");
        setStates(states);
      });

    data?.state &&
      listCity(data?.state).then((res) => {
        const cities = formattedLocations(res, "city");
        setCities(cities);
      });
  }, [data?.country, data?.state]);

  return (
    <div className={styles.address}>
      <div>
        <div className={styles.size}>
          <Input
            name="address_line1"
            error={err?.address_line1}
            label="Address Line 1"
            value={data?.address_line1}
            onChange={handleChange}
            disabled={writePermissions === 0 ? true : false}
          />
        </div>
        <div className={styles.size}>
          <Select
            name="country"
            label="Country"
            error={err?.country}
            value={data?.country || 101}
            onChange={handleChange}
            options={countries}
            disabled={writePermissions === 0 ? true : false}
          />
        </div>
        <div className={styles.size}>
          <Select
            name="city"
            label="City"
            error={err?.city}
            value={data?.city}
            onChange={handleChange}
            options={cities}
            disabled={writePermissions === 0 ? true : false}
          />
        </div>
      </div>
      <div className={styles.sa}>
        <div className={styles.size}>
          <Input
            name="address_line2"
            label="Address Line 2"
            error={err?.address_line2}
            value={data?.address_line2}
            onChange={handleChange}
            disabled={writePermissions === 0 ? true : false}
          />
        </div>
        <div className={styles.size}>
          <Select
            name="state"
            label="State"
            error={err?.state}
            value={data?.state}
            onChange={handleChange}
            options={states}
            disabled={writePermissions === 0 ? true : false}
          />
        </div>
        <div className={styles.size}>
          <Input
            name="zipcode"
            error={err?.zipcode}
            value={data?.zipcode}
            label="Zipcode"
            onChange={handleChange}
            disabled={writePermissions === 0 ? true : false}
          />
        </div>
      </div>
    </div>
  );
};
