import React from 'react'
import { useNavigate } from '../../../../node_modules/react-router-dom/index';
import { useEffect } from 'react';

export const PrevLandingUrl = () => {
    const navigate = useNavigate();
   useEffect(() => {
    navigate("/enterprise/mental-health-in-the-workplace")
   },[])
  return (
    <></>
  )
}
