import axios from "../../config/axiosConfig";

export const addResult = async (data) => {
  const ser = { ...data, state: "1", city: "1" };
  console.log(ser);
  return await axios
    .post(`/admin/add/service`, ser)
    .then((res) => res)
    .catch((err) => console.log(err));
};
export const resultGet = async (id) => {
  return await axios
    .get(`/admin/get/assessment/scoring?assessment_id=${id}`)
    .then((res) => {
      console.log(res);
      return res.data.list;
    })
    .catch((err) => console.log(err));
};

export const assessmentStatus = async (ass_id, status) => {
  await axios
    .post(`/admin/change/assessment/status?category_id=${ass_id}`, {
      status,
    })
    .then((res) => console.log(res))
    .catch((err) => console.error(err));
};

export const resultAdd = async (id) => {
  return await axios
    .get(`/admin/add/assessment/scoring`)
    .then((res) => res)
    .catch((err) => console.log(err));
};
