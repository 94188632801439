import React from "react";

export const Enterprise = (props) => {
  return (
    <div>
      <svg
        width={props.size || "28"}
        height={props.size || "28"}
        viewBox={props.size ? `0 0 ${props.size} ${props.size}` : "0 0 28 28"}
        fill="none"
      >
        <path
          d="M0.5 27.5H27.5M9.5 8H11M9.5 14H11M9.5 20H11M17 8H18.5M17 14H18.5M17 20H18.5M3.5 27.5V3.5C3.5 2.70435 3.81607 1.94129 4.37868 1.37868C4.94129 0.81607 5.70435 0.5 6.5 0.5H21.5C22.2956 0.5 23.0587 0.81607 23.6213 1.37868C24.1839 1.94129 24.5 2.70435 24.5 3.5V27.5"
          stroke={props.outline || "#7A51AE"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
