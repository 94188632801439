import React, { useEffect, useState } from "react";
import AssessmentStyles from "../../../assets/css/assessment.module.css";
import { AssessmentCard } from "../../../Component/UI/AssessmentCard";
import Mwb from "../../../assets/icons/icon/Mental well being.svg";
import { AssessmentWrapper } from "../../AssessmentNew/AssessmentWrapper";
import { API } from "../../../config/constant";
import axios from "../../../config/axiosConfig";
import AssessmentPopup from "./AssessmentPopup";
import { assessmentListRelative } from "../../../services/patient/assessment";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";
import { Helmet } from "react-helmet";

const Assessment = () => {
  const [start, setStart] = useState(false);
  const [categoryInfo, setCategoryInfo] = useState({});
  const [categoryList, setCategoryList] = useState();
  const { ALLASSESSMENT } = API;
  const [popupAction, setPopupAction] = useState({ right: false });
  const [isLoading, setIsLoading] = useState(false);

  const [relativeCategories, setRelativeCategories] = useState([]);

  useEffect(() => {
    fetchCategories();
    getRelativeData();
  }, []);

  const fetchCategories = async () => {
    setIsLoading(true);
    await axios
      .get(ALLASSESSMENT)
      .then((res) => {
        setIsLoading(false);
        let listOfCategories = res?.data?.list;
        let newCategoryList = listOfCategories.map((category) => {
          return {
            ...category,
            is_mapped: {
              ...category.is_mapped,
              assessment_data: category.is_mapped?.retake
                ? "[]"
                : category.is_mapped?.assessment_data || "[]",
              time_taken: category.is_mapped?.retake
                ? "00:00"
                : category.is_mapped?.time_taken || "00:00",
            },
          };
        });
        setCategoryList(newCategoryList);
        // setCategoryList(res?.data?.list);
      })
      .catch((err) => console.error(err));
  };

  const getRelativeData = () => {
    assessmentListRelative().then((res) => setRelativeCategories(res));
  };

  const handleClick = (category) => {
    setCategoryInfo(category);
    setStart(true);
  };

  const CloseAssessment = (e) => {
    setStart(e);
  };

  // to make assessment data

  let myFunc = (item, index) => {
    const assessment_for_Names = {
      0: "Yourself",
      1: item || "Relative 1",
      2: item || "Relative 2",
    };
    return assessment_for_Names[index];
  };

  const ismappedRelative =
    relativeCategories?.map((item, index) => {
      return {
        ...item,
        is_mapped: item.customer,
        assessment_for: index === 0 ? "Myself" : "someone_else",
        assessment_name: myFunc(item?.customer?.customer_data.name, index),
      };
    }) || [];

  const len = ismappedRelative?.length;
  const modifiedObj = { ...ismappedRelative?.[0], is_mapped: null };
  const FinalRelativeCategories = [...ismappedRelative];
  for (let i = len; i <= 2; i++) {
    // myFunc = () => item?.customer?.customer_data.name;
    FinalRelativeCategories.push({
      ...modifiedObj,
      assessment_for: i === 0 ? "Myself" : "someone_else",
      assessment_name: myFunc(i?.is_mapped?.customer_data.name, i),
    });
  }

  let retakeFinalrelativeCategories = FinalRelativeCategories.map(
    (category) => {
      return {
        ...category,
        is_mapped: {
          ...category.is_mapped,
          assessment_data: category.is_mapped?.retake
            ? "[]"
            : category.is_mapped?.assessment_data || "[]",
          time_taken: category.is_mapped?.retake
            ? "00:00"
            : category.is_mapped?.time_taken || "00:00",
        },
      };
    }
  );

  // console.log(retakeFinalrelativeCategories, "retakeFinalrelativeCategories");

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Patient Assessment Plan - Samvedna Map</title>
        <meta name="title" content="Patient Assessment Plan - Samvedna Map" />
        <link
          rel="canonical"
          href="https://map.samvednacare.com/patient/Assessment"
        />
        <meta
          name="description"
          content="Take a self-assessment for mental wellbeing, dementia or caregiver burden. Our mental health services are based on the latest scientific literature & WHO guidelines."
        />
        <meta name="robots" content="index,follow" />
      </Helmet>
      {!start ? (
        <div style={{ margin: "auto" }}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginTop: "20px",
            }}
          >
            <p style={{ fontWeight: "bold" }}>
              Please note you can retake your self assessment after a period of
              one month.
            </p>
          </div>
          {isLoading && (
            <div style={{ textAlign: "center", marginTop: "40px" }}>
              <CircularProgress size={30} />
              <p style={{ color: "black" }}>
                Please wait ! We are validating the details...
              </p>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginTop: "20px",
            }}
          >
            <AssessmentPopup
              popupAction={popupAction}
              setPopupAction={setPopupAction}
              title="Dementia Assessment"
              name="title"
              handleClick={handleClick}
              category={retakeFinalrelativeCategories}
              // icon={<MdMail size={40} />}
            />
            {categoryList?.map((category) => {
              return (
                <AssessmentCard
                  key={category.id}
                  title={category.name}
                  Icon={category?.assessment_image || Mwb}
                  result={category?.is_mapped}
                  user={true}
                  number_of_Question={category.no_of_question}
                  date={category.date_of_creation}
                  onClick={() => handleClick(category)}
                  category={category}
                  categoryId={category.id}
                  short_desc={category.short_description}
                  setPopupAction={setPopupAction}
                  popupAction={popupAction}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <>
          <AssessmentWrapper
            start={start}
            data={categoryInfo}
            user={true}
            setStart={CloseAssessment}
            setCategoryList={setCategoryList}
            getRelativeData={getRelativeData}
          />
        </>
      )}
    </>
  );
};

export default Assessment;
