import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import styles from "./responsiveSideBar.module.css";
import { LogoNew } from "../Images/LogoNew";
import { MdMenu } from "react-icons/md";

export const ResponsiveSidebar = () => {
  const [state, setState] = React.useState({});

  const direction = "left";

  const options = [
    "About",
    "How it Works",
    "Assessments",
    "Services",
    "Enterprise",
    "Blog",
    "Call : +91-74280-97301",
  ];

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    console.log(anchor, "___");

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div className={styles.container}>
      <Box
        sx={{
          width: 240,
          color: "#2A2A2A",
        }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <div className={styles.top}>
          <LogoNew color="#000" />
        </div>
        <List>
          {options.map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => {
                  switch (text) {
                    case options[0]:
                      window.open(
                        "#https://www.samvednacare.com/about",
                        "_blank"
                      );
                      break;
                    case options[1]:
                      window.open("#howItWorks", "_self");
                      break;
                    case options[2]:
                      window.open("#assesssment", "_self");
                      break;
                    case options[3]:
                      window.open("#ourService", "_self");
                      break;
                    case options[4]:
                      window.open(
                        "/enterprise/mental-health-in-the-workplace",
                        "_blank"
                      );
                      break;
                    case options[5]:
                      window.open(
                        "https://www.samvednacare.com/blog/",
                        "_blank"
                      );
                      break;
                    case options[6]:
                      window.open("tel:+91-74280-97301", "_self");
                      break;
                    default:
                  }
                }}
              >
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <div className={styles.bottom}></div>
      </Box>
    </div>
  );

  return (
    <div>
      <div onClick={toggleDrawer(direction, true)}>
        <MdMenu size={32} color="#fff" />
      </div>
      <Drawer
        anchor={direction}
        open={state[direction]}
        onClose={toggleDrawer(direction, false)}
      >
        {list(direction)}
      </Drawer>
    </div>
  );
};
