import React, { useEffect } from "react";
import { API } from "../../config/constant";
import instance from "../../config/axiosConfig";
import styles from "../../assets/css/activitycard.module.css";
import { IconShadow } from "../../Component/UI/IconShadow";
import SwitchButton from "../../Component/UI/SwitchButton/Switch";
import moment from "moment";
import toast from "react-hot-toast";
import TodayActivityLeaf from "../../assets/icons/TodayActivityLeaf";
import { Skeleton } from "../../../node_modules/@mui/material/index";
import { StringDtoY } from "../../config/formattedDate";

export const ActivityCard = ({
  toggle,
  question,
  getActivityData,
  disabled,
  activityDate,
  activityAnswer,
  icon,
  loaded,
}) => {
  const ToggleHandler = (status) => {
    let postData = {
      answered_id: activityAnswer.id,
      answer: status ? 1 : 0,
    };

    try {
      instance.post(API.TODAYACTIVITYTOGGLE, postData).then((res) => {
        res.data.status && getActivityData();
        res.data.status && toast.success("Answer Updated Successfully!");
      });
    } catch (error) {
      console.log(error, "error");
    }
  };

  const date = (d) => moment(d).format("DD MMM YYYY");

  const TodaysActivityImage = () => (
    <img src={icon} alt="todays activity" loading="lazy" width="24px" />
  );

  return (
    <>
      <div className={styles.activityCard}>
        {loaded ? (
          question ? (
            <>
              <div className={styles.icon}>
                <IconShadow
                  size="48px"
                  color="#E69A8D"
                  icon={icon ? <TodaysActivityImage /> : <TodayActivityLeaf />}
                />
              </div>
              <div className={styles.divide}>
                <p>{date(activityDate) || date(new Date())}</p>
                <h5 className={styles.quesh5}>{question}</h5>
              </div>
              <div className={styles.button_container}>
                {toggle !== undefined && (
                  <>
                    <SwitchButton
                      status={Boolean(toggle)}
                      onClick={(status) => ToggleHandler(status)}
                      disabled={disabled}
                    />
                    <p className={styles.statuscss}>
                      {toggle ? "Done" : "Not Done"}
                    </p>
                  </>
                )}
              </div>
            </>
          ) : (
            <p>There is no Activity Available for Today!</p>
          )
        ) : (
          <>
            <div className={styles.icon}>
              <Skeleton variant="circular" width={50} height={50} />
            </div>
            <div className={styles.divide}>
              <Skeleton variant="rounded" width={"inherit"} height={50} />
            </div>
          </>
        )}
      </div>
    </>
  );
};
