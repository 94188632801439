import styles from "../../../assets/css/AdminEnterprise/enterprise.module.css";
import {
  Input,
  Select2,
  CheckBox,
  Select,
} from "../../../Component/UI/CustomInputs";
import {
  resetServiceDetails,
  serviceDetails,
} from "../../../Redux/Slices/serviceDetails";
import { useDispatch, useSelector } from "react-redux";
import { SelectCard } from "../../UI/SelectCard";
import { useState } from "react";
import CustomizedButtons from "../../../Component/UI/Button/CustomizeButton";
import { assessmentData } from "../../../Redux/Slices/AssessmentTableReducer";
import axios from "../../../config/axiosConfig";
import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { UploadProfile } from "../../../Component/UI/UploadProfile";
import { Button } from "../../../../node_modules/@mui/material/index";
import { useLocation } from "../../../../node_modules/react-router-dom/index";
import CopyUrl from "../../../assets/images/copyURL.svg";

import {
  getEnterpriseDesignationAdmin,
  getEnterpriseIndustryAdmin,
} from "../../../services/admin/enterprise";
import copy from "copy-to-clipboard";
import { toast } from "../../../../node_modules/react-hot-toast/dist/index";
export const EnterpriserGeneralProfileForm = ({
  setData,
  flag,
  error,
  checkedstate,
  state,
  setImageSize,
}) => {
  const [formdata, setformdata] = useState({
    industry: "",
    company_name: "",
    total_employee: "",
    email: "",
    mobile: "",
    designation: "",
    name: "",
    logo: "",
  });

  const [image, setImage] = useState(null);

  const [industryOptions, setIndustryOptions] = useState();
  const [designationOptions, setDesignationOptions] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformdata({ ...formdata, [name]: value });
  };

  const [slug, setSlug] = useState("");
  const rgx = /^(?:[0-9+]+)?$/;

  useEffect(() => {
    setData(formdata);
  }, [formdata]);

  useEffect(() => {
    if (state?.data) {
      const {
        industry,
        company_name,
        designation,
        email,
        total_employee,
        mobile,
        name,
        img_url,
        other_domains,
        company_slug,
        secondary_name,
        secondary_email,
        testimonial_by,
      } = state.data;

      setformdata({
        industry,
        company_name,
        designation,
        email,
        total_employee,
        mobile,
        name,
        other_domains,
        secondary_name,
        secondary_email,
        testimonial_by,
      });
      setImage(img_url);
      setSlug(company_slug);
    }

    getEnterpriseIndustryAdmin().then((res) => {
      let result = res.data.list.map((item) => {
        return {
          ...item,
          value: item.name,
        };
      });

      setIndustryOptions(result);
    });
    getEnterpriseDesignationAdmin().then((res) => {
      let result = res.data.list.map((item) => {
        return {
          ...item,
          value: item.name,
        };
      });
      setDesignationOptions(result);
    });
  }, []);

  const domain = window.location.host;
  const enterpriseURL = `${domain}/enterprise/${slug}/login`;
  const copyToClipboard = () => {
    copy(enterpriseURL);
    toast.success("Text copied");
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <div className={styles.profileContainer}>
          <Select
            label="Industry"
            name="industry"
            labelSize="14px"
            options={industryOptions}
            error={error?.industry}
            value={formdata?.industry}
            onChange={handleChange}
            // req
          />
          <Input
            type="number"
            label="No of employees"
            name="total_employee"
            placeholder="Total no. of employees"
            error={error?.total_employee?.[0]}
            value={formdata.total_employee}
            onChange={handleChange}
          />
          <Input
            placeholder="Company Name"
            label="Company Name"
            name="company_name"
            error={error?.company_name?.[0]}
            value={formdata.company_name}
            // options={data}
            onChange={handleChange}
          />
          <Input
            placeholder="Your Name"
            label="Name"
            error={error?.name?.[0]}
            name="name"
            value={formdata?.name}
            // options={data}
            onChange={handleChange}
          />
          <Input
            placeholder="Work Email"
            label="Email"
            name="email"
            error={error?.email?.[0]}
            value={formdata?.email}
            //  options={data}
            onChange={handleChange}
          />
          <Input
            placeholder="Mobile number"
            label="Mobile"
            name="mobile"
            error={error?.mobile?.[0]}
            value={formdata?.mobile}
            type="text"
            // options={data}
            onChange={(e) => {
              const isNum = rgx.test(e.target.value);
              isNum && setformdata({ ...formdata, mobile: e.target.value });
            }}
            // onChange={handleChange}
          />

          <Select
            label="Designation"
            name="designation"
            labelSize="14px"
            options={designationOptions}
            error={error.designation}
            value={formdata.designation}
            onChange={handleChange}
            // req
          />

          {state?.id ? (
            <Input
              type="text"
              label="Other Domain"
              name="other_domains"
              placeholder="Please fill your domains comma separated"
              value={formdata.other_domains}
              error={error?.other_domains?.[0]}
              onChange={handleChange}
            />
          ) : null}
          {state?.id ? <Input label="Slug" value={slug} disabled /> : null}
          {state?.id ? (
            <div className={styles.copyUrl}>
              <Input label="URL" value={enterpriseURL} disabled />
              <button
                style={{
                  background: "#fff",
                  border: "0px",
                  cursor: "pointer",
                  color: "#00B091",
                  height: "41px",
                  width: "70px",
                  marginTop: "17px",
                }}
                onClick={copyToClipboard}
                //  disabled={!text}
              >
                <img src={CopyUrl} alt="copy" /> Copy
              </button>
            </div>
          ) : null}

          <Input
            type="text"
            label="Testimonial By"
            name="testimonial_by"
            placeholder="Testimonial By"
            error={error?.testimonial_by?.[0]}
            value={formdata.testimonial_by}
            onChange={handleChange}
          />
          <Input
            type="text"
            label="Secondary Name"
            name="secondary_name"
            placeholder="Secondary Name"
            error={error?.secondary_name?.[0]}
            value={formdata.secondary_name}
            onChange={handleChange}
          />

          <Input
            type="text"
            label="Secondary Email"
            name="secondary_email"
            placeholder="Secondary Email"
            error={error?.secondary_email?.[0]}
            value={formdata.secondary_email}
            onChange={handleChange}
          />
        </div>
      </Grid>
      <Grid item xs={4}>
        <UploadProfile
          name="avtar"
          profile={image}
          value={formdata.logo}
          setImageSize={setImageSize}
          // removeButton={true}
          imgCaption="(300px * 50px)"
          color="#69707D"
          upload={(value) => setformdata({ ...formdata, logo: value })}
        />
      </Grid>
    </Grid>
  );
};
