import { Grid } from "../../../../../node_modules/@mui/material/index";
import { useNavigate } from "../../../../../node_modules/react-router-dom/index";
import { Info } from "../../../UI/Info";
import styles from "../samvedna.module.css";

export const NotificationManagement = () => {
  const CardsData = [
    {
      id: 1,
      name: "Email",
      link: "email",
    },
    {
      id: 2,
      name: "SMS",
      link: "disabled",
    },
    {
      id: 3,
      name: "Notifications",
      link: "disabled",
    },
  ];
  const navigate = useNavigate();

  const CardHandler = (linkText) => {
    linkText !== "disabled" && navigate(linkText);
  };
  return (
    <>
      <h1>Notification Management</h1>
      <div className={styles.cards}>
        {CardsData.map((card) => {
          return (
            <Grid
              item
              xs={3}
              style={{
                marginBottom: "10px",
              }}
              key={card.id}
              columns={{ xs: 1, sm: 1, md: 12 }}
            >
              <Info
                key={card.id}
                text={card.name}
                link={card.link}
                onClick={() => CardHandler(card.link)}
              />
            </Grid>
          );
        })}
      </div>
    </>
  );
};
