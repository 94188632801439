import React from "react";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
// import WhyImg from "./Images/whyImg.svg";
import WhyImg from "../../../assets/images/mental-health2.jpeg";
export const WhyShould = ({ styles }) => {
  return (
    <div className={styles.whyShould}>
      <h3> Why should I use Samvedna MAP?</h3>
      <div className={styles.whyShuldSec}>
        <div clasname={styles.imgSec}>
          <img src={WhyImg} alt="whyImg" />
        </div>
        <div className={styles.contentSec}>
          <p>
            Samvedna MAP is a mental wellbeing self-assessment tool which
            provides quick insights and helps take timely action to start your
            self-care journey. It is also a great way for organizations to
            promote{" "}
            <a
              href="https://map.samvednacare.com/enterprise/mental-health-in-the-workplace"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none", fontWeight: 700 }}
            >
              mental health in the workplace
            </a>
            . I should use Samvedna MAP :
          </p>
          <ul>
            <li>To help get a better understanding of my mental well-being</li>
            <li>
              To get daily self-care tips on improving my mental health and
              well-being
            </li>
            <li>
              To understand if I am experiencing any cognitive changes that I
              should be concerned about
            </li>
            <li>
              To understand if someone I care about has signs suggestive of
              dementia and encourage them to seek help
            </li>
            <li>
              To take a caregiver health self-assessment to understand if I am
              doing enough to take care of myself
            </li>
            <li>
              To help my employees in improving mental health in the work place.
            </li>
          </ul>
          <div className={styles.how_map_works_btn}>
            <Btn
              variant="contained"
              bgColor="#7A51AE"
              width="236px"
              height="48px"
              color="#ffffff"
              whiteSpace="nowrap"
              borderRadius="8px"
              click={() => window.open("#howItWorks", "_self")}
              // click={() => {
              //   setPopup(true);
              // }}
            >
              {"How MAP Works"}
            </Btn>
          </div>
        </div>
      </div>
    </div>
  );
};
