import React from "react";
import { CustomizedAccordions } from "../../../../../Component/UI/Accordion/CustomizedAccordions";
import { Activity } from "./Activity";
import { SelectCard } from "../../../../UI/SelectCard";
import { Observation } from "../../../../../MockData/Observation.json";

const options = {
  GeneralObservations: [
    {
      label: "GeneralAppearance",
      cont: ["Well Groomed", "Can be Groomed", "Poorly Groomed"],
    },
    {
      label: "Mood",
      cont: [
        "Very good mood",
        "General good mood today",
        "Some signs of good mood",
        "Low mood today",
        "In very low mood today",
      ],
    },
  ],

  // Communication: {
  //   items: [
  //     "Communicates very well",
  //     "Communicates well",
  //     "Some Responses",
  //     "Little Communication",
  //     "No Communication",
  //   ],
  // },
  //};
};
export const Observations = () => {
  // const accordiontitle = [
  //   "General Observations",
  //   "Observations",
  //   "Cognition",
  //   "Perception",
  //   "Thoughts",
  // ];

  // const accordion_opt = options?.map((i) => {
  //   i?.opt?.map((t) => {
  //     <SelectCard options={t} />;
  //   });
  // });
  // const accordion_opt = <SelectCard options={options} />;

  return (
    <div>
      {Object.keys(options).map((i) => {
        return (
          <>
            {options[i].map((item) => {
              return (
                <CustomizedAccordions
                  heading={i}
                  content={
                    <SelectCard label={item.label} options={item.cont} />
                  }
                />
              );
            })}
          </>
        );
      })}

      <p style={{ marginTop: "40px" }}>Councellor Remarks</p>
      <textarea
        rows="4"
        cols="160"
        style={{
          borderRadius: "8px",
          border: "1px solid #dadada",
          marginTop: "10px",
        }}
      ></textarea>
    </div>
  );
};
