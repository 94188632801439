import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import { Input } from "../../../Component/UI/CustomInputs";
import axios from "../../../config/axiosConfig";
import toast from "react-hot-toast";
import { CircularProgress } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  height: 260,
  bgcolor: "background.paper",
  outline: "none",
  borderRadius: "20px",
  boxShadow: 12,
  p: 4,
};

export default function ApprovePopup({ popupAction, setPopupAction, icon }) {
  const sucessNotify = (msg) => toast.success(msg);
  const errorNotify = (error) => toast.error(error);
  const [disableBtn, setDisableBtn] = React.useState(false);

  const [isOrgNameValid, setIsOrgNameValid] = React.useState();

  const validateOrgName = (name) => {
    const regx = /[^\w\*]/g;
    const check = regx.test(name);
    setIsOrgNameValid(!check);
    return !check;
  };

  const handleClose = () => setPopupAction({ ...popupAction, open: false });

  const handleApproved = async () => {
    if (!disableBtn) {
      if (popupAction.companyName.length > 20) {
        errorNotify("Enterprise name must be upto 20 characters!");
        return null;
      }
      if (isOrgNameValid || validateOrgName(popupAction.companyName)) {
        setDisableBtn(true);
        await axios
          .post("/enterprise/leadaction", {
            id: popupAction.id,
            action: "approved",
            remark: popupAction.companyName,
          })
          .then((res) => {
            setDisableBtn(false);
            if (res?.data?.status) {
              sucessNotify(res.data.msg);
              setPopupAction({
                ...popupAction,
                open: false,
                update: !popupAction.update,
              });
            } else {
              errorNotify(res.data.message);
            }
          })
          .catch((err) => errorNotify(err));
      } else errorNotify("Please enter a valid Organization name!");
    }
  };
  return (
    <div>
      <Modal
        open={popupAction.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "100%",
              background: "#00B091",
              top: "0px",
              left: "0px",
              position: "absolute",
              borderTopLeftRadius: "19px",
              borderTopRightRadius: "19px",
              height: "40px",
              color: "white",
              fontSize: "18px",
              fontWeight: "600",
              padding: "5px",
              textTransform: "uppercase",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                alignSelf: "center",
                width: "100%",
                textAlign: "center",
              }}
            >
              Verify Enterprise
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: "auto",
            }}
          >
            {icon}
            <h2>{popupAction.name}</h2>
            <p
              style={{
                fontSize: "17px",
                fontWeight: "500",
                margin: "20px 12px",
                textAlign: "center",
                lineHeight: "20px",
              }}
            >
              Please validate the Enterprise name
            </p>
            <Input
              placeholder="organization name"
              value={popupAction.companyName}
              onChange={(e) => {
                setPopupAction({ ...popupAction, companyName: e.target.value });
                validateOrgName(e.target.value);
              }}
            />
            <span>
              <p
                style={{ color: "#7f7f7f", fontSize: "14px", margin: "4px 0" }}
              >
                No spaces, special characters (@,&,%$)
                <p
                  style={{
                    color: "#7f7f7f",
                    fontSize: "14px",
                    margin: "4px 0",
                  }}
                >
                  Maximum upto 20 characters.
                </p>
              </p>
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "-webkit-fill-available",
                margin: "8px 0",
              }}
            >
              <Btn
                bgColor="#fff"
                color="#000"
                width="100px"
                height="36px"
                variant="contained"
                margin="0 20px"
                click={handleClose}
              >
                Cancel
              </Btn>
              <Btn
                bgColor="#00B091"
                variant="contained"
                width="100px"
                height="36px"
                margin="0 20px"
                click={handleApproved}
              >
                {disableBtn ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  "Approve"
                )}
              </Btn>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
