import { SubHeader } from "../../UI/SubHeader";
import { Upcoming } from "./Upcoming";
import { Completed } from "./Completed";
import { Cancelled } from "./Cancelled";
import { Details } from "./Details";
import { OnGoing } from "./OnGoing";
import { Helmet } from "react-helmet";

export const AppointmentsPatient = () => {
  const items = [
    {
      label: "Upcoming",
      component: <Upcoming />,
    },
    {
      label: "On Going",
      component: <OnGoing />,
    },
    {
      label: "Completed",
      component: <Completed />,
    },
    {
      label: "Cancelled",
      component: <Cancelled />,
    },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Patients Appointments - Map Samvedna Care</title>
        <link
          rel="canonical"
          href="https://map.samvednacare.com/patient/Appointments"
        />
        <meta
          name="title"
          content="Patients Appointments - Map Samvedna Care"
        />
        <meta
          name="description"
          content="Samvedna MAP Self-Assessment tool lets you schedule appointments seamlessly, it provides easy access to appointment booking and making payments."
        />
        <meta name="robots" content="index,follow" />
      </Helmet>
      <div>
        <SubHeader tab={items} />
        {/* <Details /> */}
      </div>
    </>
  );
};
