import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const { GETEMAILTEMPLATE, ADDEMAILTEMPLATE, UPDATEMAILTEMPLATE } = API;

export const getEmailTemplate = async () => {
  return await axios
    .get(GETEMAILTEMPLATE)
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const addEmailTemplate = async (data) => {
  return await axios
    .post(ADDEMAILTEMPLATE, data)
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const editEmailTemplate = async (data) => {
  return await axios
    .post(UPDATEMAILTEMPLATE, data)
    .then((res) => res)
    .catch((err) => console.log(err));
};
