import React, { useState } from "react";
import styles from "../../../assets/css/AdminDashboard/servicemodal.module.css";
import { RiCloseLine } from "react-icons/ri";
import { Select2, CheckBox2 } from "../../../Component/UI/CustomInputs";
import toast from "../../../../node_modules/react-hot-toast/dist/index";
import axios from "../../../config/axiosConfig";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const RecommendService = ({ setIsOpen, modalData, option }) => {
  console.log(modalData);
  const levels = [
    {
      id: "1",
      value: "Level 1",
    },
    {
      id: "2",
      value: "Level 2",
    },
    {
      id: "3",
      value: "Level 3",
    },
  ];
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [ass_name, setAssName] = useState();
  const [msg, setMsg] = useState();
  const [error, setError] = useState(false);
  console.log(ass_name);
  const navigate = useNavigate();

  // const categoryName = option.filter((i) => i.id === modalData?.category)[0]
  //   .name;

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  useEffect(() => {
    if (modalData?.level !== null) {
      const levelArr = modalData?.level?.split(",").filter((i) => i !== "0");
      const checkall = levelArr.length === 3;

      if (checkall) {
        setIsCheckAll(!isCheckAll);
        setIsCheck(levels?.map((li) => li.id));
        if (isCheckAll) {
          setIsCheck([]);
        }
      } else {
        setIsCheck(levelArr);
      }
    }
  }, [modalData]);

  const selectlevel = levels?.map(({ value, id }, i) => {
    return (
      <CheckBox2
        boldness={600}
        value={value}
        id={id}
        isChecked={isCheck?.includes(id)}
        name={value}
        handleClick={(e) => handleClick(e)}
      />
    );
  });
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(levels.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  useEffect(() => {
    setIsCheckAll(isCheck.length === 3 ? true : false);
  }, [isCheck]);

  const recommendservice = async () => {
    let level = isCheck.toString();
    const Data = new FormData();
    Data.append("service_id", modalData?.id);
    Data.append("assessment_id", modalData?.category);
    Data.append("level", level);
    try {
      const response = await axios.post(`/admin/map/assessment`, Data);
      if (response.data.status) {
        setError(false);
        setMsg(response?.data?.msg);
        toast.success("Recommended service has been updated.");
        navigate("/Services");
      } else {
        setError(true);
        setMsg(response?.data?.error[0]);
        toast.error(response?.data?.error[0]);
      }
    } catch (error) {
      setError(true);
      setMsg(error.response.data.msg);
      // setError(true);
    }
  };

  return (
    <>
      <div className={styles.darkBG} onClick={() => setIsOpen(false)} />
      <div className={styles.centered}>
        <div className={styles.modal_card}>
          <div className={styles.modalHeader}>
            <h2 className={styles.heading}>Recommended Services</h2>
          </div>
          <button className={styles.closeBtn} onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className={styles.modalContent}>
            <p
              style={{
                fontSize: "18px",
                fontWeight: "400",
              }}
            >
              {modalData?.category_name}
            </p>
          </div>
          {/* <div className={styles.modalContent}>
            <label>Select Level</label>
            <div className={styles.modal_checkbox}>
              {levels?.map(({ value }, i) => (
                <CheckBox
                  boldness={600}
                  value={value}
                  // check={isChecked[i]}
                  name={value}
                  // click={(val) => handleOnChange(i, val)}
                />
              ))}
            </div>
          </div> */}
          <div className={styles.modalContent}>
            <label>Select Level</label>
            <div className={styles.modal_checkbox}>
              <CheckBox2
                boldness={600}
                value="All"
                isChecked={isCheckAll}
                name="All"
                handleClick={handleSelectAll}
              />
              {selectlevel}
            </div>
          </div>
          <div className={styles.modalActions}>
            <div className={styles.actionsContainer}>
              <button
                className={styles.cancelBtn}
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </button>
              <button className={styles.deleteBtn} onClick={recommendservice}>
                Create
              </button>
            </div>
            {/* {error && (
              <div
                style={{
                  padding: "12px",
                  color: "#000000",
                  textAlign: "center",
                }}
              >
                <p>{msg}</p>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};
