import { useState } from "react";
import { CircularProgress } from "../../../node_modules/@mui/material/index";
import { Input } from "../../Component/UI/CustomInputs";
import { Btn } from "../../Samvenda-Patient/UI/Button";
import { registerEnterprise } from "../../services/enterprise/prelogin";
import SubmitPopup from "../Components/Popups/SubmitPopup";
import styles from "./prelogin.module.css";

export const ConnectForm = () => {
  const [popup, setPopup] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError({});
    registerEnterprise(data)
      .then((res) => {
        if (res.status === 200) {
          setPopup(true);
          setError({});
          setData({});
        } else {
          const errorRes = res.response.data.error;
          setError(errorRes);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  return (
    <>
      <SubmitPopup setPopup={setPopup} popup={popup} />
      <form className={styles.form} onSubmit={handleSubmit}>
        <label style={{ width: "317px", fontSize: "17px" }}>
          Please fill out the below form to connect with us
        </label>
        <div className={styles.inputs}>
          <div>
            <Input
              placeholder="Company Name"
              type="text"
              name="company_name"
              value={data?.company_name || ""}
              error={error?.company_name}
              onChange={handleChange}
            />
          </div>
          <div>
            <Input
              placeholder="Your Name"
              type="text"
              name="name"
              value={data?.name || ""}
              error={error?.name}
              onChange={handleChange}
            />
          </div>
          <div>
            <Input
              type="email"
              placeholder="Work Email"
              name="email"
              value={data?.email || ""}
              error={error?.email}
              onChange={handleChange}
            />
          </div>
          <div>
            <Input
              type="number"
              placeholder="Mobile Number"
              name="mobile"
              value={data?.mobile || ""}
              error={error?.mobile}
              onChange={handleChange}
            />
          </div>
          <div>
            <Input
              placeholder="Designation"
              type="text"
              name="designation"
              value={data?.designation || ""}
              error={error?.designation}
              onChange={handleChange}
            />
          </div>
          <div className={styles.buttons}>
            <Btn
              variant="contained"
              borderRadius="4px"
              bgColor="#3592FF"
              color="#fff"
              width="100%"
            >
              {loading ? (
                <CircularProgress color="inherit" size="25px" />
              ) : (
                "Submit"
              )}
            </Btn>
          </div>
        </div>
      </form>
    </>
  );
};
