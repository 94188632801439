import React, { useEffect, useState } from "react";
import styles from "./model.module.css";
import { RiCloseLine } from "react-icons/ri";

import Servicesuccess from "../../../assets/images/serviceBooksuccess.svg"
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import { useNavigate } from "../../../../node_modules/react-router-dom/index";

const ModalSuccess = ({ setIsOpenSuccess, isOpenSuccess }) => {
    const navigate = useNavigate()
  return (
    <>
      <div onClick={() => setIsOpenSuccess(false)} />
      <div className={styles.centered}>
        <div className={styles.modal}>
        <div className={styles.iconimg}>
            <img src={Servicesuccess} alt=""/>
        </div>
          <div className={styles.modalContent}>{isOpenSuccess?.body}</div>
          <div className={styles.btnGroup}>
            <Btn
              variant="contained"
              bgColor="#7A51AE"
              color="#ffffff"
              width="120px"
              height="40px"
              whiteSpace="nowrap"
              borderRadius="100px"
              margin = "15px"
              click={() => {
                setIsOpenSuccess(false);
                navigate("/patient/Appointments");
              }}
            >
              Close
            </Btn>
           
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalSuccess;
