import React from 'react'
import BasicBreadcrumbs from '../../../Component/UI/BreadCrumb/BreadCrumbs';
import Tables from '../../../Hooks/Table'

const serviceData = [
  {
    "name":"1. Emergency Services",
    "update_on":"21/06/2022",
    "action":""
  },
  {
    "name":"2. Appointment Management",
    "update_on":"21/06/2022",
    "action":""
  },{
    "name":"3. CGHS/Health",
    "update_on":"21/06/2022",
    "action":""
  }
]

const SamvednaServices = () => {
  const link = ["Samvedna","Content Management","Services"]
  const columns = Object.keys(serviceData[0]);
  return (
    <BasicBreadcrumbs>
      <Tables theadData={columns} tbodyData={serviceData}/>
    </BasicBreadcrumbs>
    
  )
}

export default SamvednaServices;