import React, { useEffect, useState } from "react";
import styles from "./doctorPopup.module.css";
import { RiCloseLine } from "react-icons/ri";
//import pic from "../../../assets/images/doctor.png";
import defaultImg from "../Images/default.png";

const Modal = ({ setPopupAction, modalData }) => {
  const pattern = /<\/?p>/gi;
  const tagExcluded = modalData.description.replace(pattern, " ");
  const slicedChars = window.innerWidth <= 480 ? 170 : 240;
  const specialitySlicedChars = window.innerWidth <= 480 ? 90 : 110;
  return (
    <>
      <div
        onClick={() =>
          setPopupAction((prev) => {
            return { ...prev, open: false };
          })
        }
      />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <div className={styles.heading}>
              <div style={{ width: "100%" }}>Expert Details</div>
              {
                <button
                  className={styles.closeBtn}
                  onClick={() =>
                    setPopupAction((prev) => {
                      return { ...prev, open: false };
                    })
                  }
                >
                  <RiCloseLine style={{ marginBottom: "-3px" }} />
                </button>
              }
            </div>
          </div>

          <div className={styles.modalContent}>
            <div className={styles.modelTopContent}>
              <div className={styles.doctorImg}>
                <img
                  alt={modalData?.name}
                  src={modalData?.staff_image || defaultImg}
                />
              </div>
              <div className={styles.doctorData}>
                <div className={styles.modelheaderInner}>
                  <p className={styles.modelDescription}>
                    {modalData?.name ? modalData?.name : "-"}
                  </p>
                  <p className={styles.titleHead}>Qualification</p>
                  <p className={styles.modelDescription}>
                    {modalData?.qualification ? modalData?.qualification : "-"}
                  </p>
                  <p className={styles.titleHead}>Speciality</p>
                  <p className={styles.modelDescription}>
                    {modalData?.specialization ? (
                      <ReadMore
                        data={modalData?.specialization}
                        sliceChar={specialitySlicedChars}
                      />
                    ) : (
                      "-"
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.modelBottom}>
              <p className={styles.titleHead}>Profile</p>
              <div
                className={styles.modelDescriptionAbout}
                //   dangerouslySetInnerHTML={{
                //   __html: modalData?.description,
                // }}
              >
                <ReadMore data={tagExcluded} sliceChar={slicedChars}>
                  {tagExcluded}
                </ReadMore>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;

export const ReadMore = ({ data, sliceChar }) => {
  const [more, setMore] = useState(true);
  const isMoreReq = data.length > sliceChar + 10;
  return data ? (
    <>
      {/* {data.slice(0, 150) + '...'} */}
      {more && isMoreReq ? (
        <p>
          {data?.slice(0, sliceChar) + "..."}
          <span
            onClick={() => {
              setMore(!more);
            }}
            style={{
              color: "#7A51AE",
              cursor: "pointer",
              textTransform: "none",
            }}
          >
            Read More
          </span>
        </p>
      ) : (
        <p>
          {data + "..."}{" "}
          {isMoreReq && (
            <span
              onClick={() => {
                setMore(!more);
              }}
              style={{
                color: "#7A51AE",
                cursor: "pointer",
                textTransform: "none",
              }}
            >
              Read Less
            </span>
          )}
        </p>
      )}
    </>
  ) : null;
};
