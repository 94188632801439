import React from "react";
import styles from "./header.module.css";
//import headerImage from "../../Components/Images/Mask group.png";
import { ResponsiveSidebar } from "../../Components/UI/ResponsiveSidebar";
import { ResponsiveLogo } from "../../Components/Images/ResponsiveLogo";
import { Logo } from "./Images/Logo";
import HelthImg from "./Images/helth-banner.svg";
import { Card } from "./Components/Card";
import CaregiverIMG from "./Images/caregiver.svg";
import DementiaImg from "./Images/dementia.svg";
import MentalImg from "./Images/mental.svg";
import { ButtonGroup } from "./Components/ButtonGroup";

export const Header = ({ setPopup }) => {
  const cardData = [
    {
      image: MentalImg,
      title: "Understand Your Mental Wellbeing",
    },
    {
      image: DementiaImg,
      title: "Dementia Screening For Yourself Or Your Loved One",
    },
    {
      image: CaregiverIMG,
      title: "Evaluate Your Caregiver Stress",
    },
  ];

  return (
    <>
      <div className={styles.responsiveHeader}>
        <div className={styles.hamburger}>
          <a
            href="https://www.samvednacare.com/"
            target="_blank"
            rel="noreferrer"
          >
            <ResponsiveLogo />
          </a>
          <ResponsiveSidebar />
        </div>
        <div className={styles.bannerWrapResponsive}>
          <div className={styles.bannerMain}>
            <div className={styles.content}>
              <div className={styles.contentBox}>
                <h2>MAP (My Assessment & Plan)</h2>
                <h2>Take charge of your mental health.</h2>
                <h3>Get started with your self-assessment.*</h3>
              </div>
              <div className={styles.textBoxes}>
                {cardData?.map((item) => {
                  return <Card item={item} styles={styles} />;
                })}
              </div>
              <div className={styles.btnGroup}>
                <ButtonGroup styles={styles} />
              </div>
            </div>
            <div className={styles.bannerImg}>
              <img src={HelthImg} alt="bannerImg" />
            </div>
          </div>
          <div className={styles.bottomLine}>
            *Our mental health services are based on the latest scientific
            literature and WHO guidelines.
          </div>
        </div>
      </div>
      <div className={styles.header}>
        <div className={styles.logo}>
          <a
            href="https://www.samvednacare.com/"
            target="_blank"
            rel="noreferrer"
          >
            <Logo />
          </a>
          <div className={styles.links}>
            <ul>
              <li>
                <a href="https://www.samvednacare.com/about" target="blank">
                  About
                </a>
              </li>
              <li>
                <a href="#howItWorks">How it Works</a>
              </li>
              <li>
                <a href="#assesssment">Assessments</a>
              </li>
              <li>
                <a href="#ourService">Services</a>
              </li>
              <li>
                <a href="/enterprise/mental-health-in-the-workplace">Enterprise</a>
              </li>
              <li>
                <a
                  href="https://www.samvednacare.com/blog/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Blog
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div
          className={styles.call}
          onClick={() => window.open("tel:+91-74280-97301", "_self")}
        >
          <a className={styles.call_link} href="tel:+91-74280-97301">
            Call : +91-74280-97301
          </a>
        </div>
      </div>
      <div className={styles.bannerWrap}>
        <div className={styles.bannerMain}>
          <div className={styles.content}>
            <div className={styles.contentBox}>
              <h1>MAP (My Assessment & Plan)</h1>
              <h2>Take charge of your mental health.</h2>
              <h3>Get started with your self-assessment.*</h3>
            </div>
            <div className={styles.textBoxes}>
              {cardData?.map((item) => {
                return <Card item={item} styles={styles} />;
              })}
            </div>
            <div className={styles.btnGroup}>
              <ButtonGroup styles={styles} />
            </div>
          </div>
          <div className={styles.bannerImg}>
            <img src={HelthImg} alt="bannerImg" />
          </div>
        </div>
        <div className={styles.bottomLine}>
          *Our mental health services are based on the latest scientific
          literature and WHO guidelines.
        </div>
      </div>
    </>
  );
};
