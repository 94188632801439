import { createSlice } from "@reduxjs/toolkit";

const getFeedbackSlice = createSlice({
  name: "get feedbacks",
  initialState: {
    newFeedbacks: [],
    acceptedFeedbacks: [],
    rejectedFeedbacks: [],
  },
  reducers: {
    getNewFeedbacks: (state, action) => {
      state.newFeedbacks = action.payload;
    },
    getAcceptedFeedbacks: (state, action) => {
      state.acceptedFeedbacks = action.payload;
    },
    getRejectedFeedbacks: (state, action) => {
      state.rejectedFeedbacks = action.payload;
    },
  },
});

export const { getNewFeedbacks, getAcceptedFeedbacks, getRejectedFeedbacks } =
  getFeedbackSlice.actions;

export default getFeedbackSlice.reducer;
