import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Chart from "react-apexcharts";
import axios from "../../../../config/axiosConfig";

import MoodAreaChart from "./MoodAreaChart";
import CircularBar from "./CircularBar";
import ProgressBar from "./ProgressBar";
import { Btn } from "../../../UI/Button";

import styles from "../../../../assets/css/PatientDashboard/progresstrend.module.css";
import Nutrition from "../../../../assets/icons/ProgressTrend/Nutrition.svg";
import Cognitve from "../../../../assets/icons/ProgressTrend/Cognitve.svg";
import Selfcare from "../../../../assets/icons/ProgressTrend/Selfcare.svg";
import Social from "../../../../assets/icons/ProgressTrend/Social.svg";
import Exercise from "../../../../assets/icons/ProgressTrend/Exercise.svg";
import Sleep from "../../../../assets/icons/ProgressTrend/Sleep";
import SleepTime from "../../../../assets/icons/ProgressTrend/SleepTime";
import BottomCardContainer from "./BottomCardContainer";
import { useNavigate } from "react-router-dom";
import { setProgressData } from "../../../../Redux/Slices/Patient/ProgressTrend";
import moment from "moment";
import { useDispatch } from "react-redux";

const MentalWellBeing = ({ dateGap, filterType, data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [hour, setHour] = useState([]);
  const [month, setmonth] = useState([]);
  const [sleepData, setSleepData] = useState([]);
  const [dailyTracker, setDailyTracker] = useState();
  const [streakData, setStreakData] = useState();
  useEffect(() => {
    if (Object.keys(data).length) {
      const sortedData = [...data.sleep].sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      );

      const dailyTrackerObject = data?.tracker;

      setDailyTracker(dailyTrackerObject);
      setSleepData(sortedData);
      setStreakData(data?.sreakdata);
    }
  }, [data]);

  useEffect(() => {
    axios
      .get(
        `/customer/show/quick/ask/graph?type=${filterType}&fromdate=${dateGap.startDate}&todate=${dateGap.endDate}`
      )
      .then((res) => {
        dispatch(setProgressData(res.data));
      })
      .catch((err) => {
        dispatch(setProgressData({}));
        console.log({ err });
      });
  }, [filterType, dateGap]);

  const series = [
    //data on the y-axis
    {
      name: "Avg",
      data: sleepData?.map((item) => {
        return {
          x: item.date
            ? moment(item.date, "YYYY-MM-DD").set({
                date: Number(moment(item.date).format("DD")),
              })
            : moment().set({
                year: item.year,
                month: moment().month(item.month).format("MM") - 1,
                // date: moment([
                //   item.year,
                //   moment().month(item.month).format("MM") - 1,
                // ])
                //   .endOf("month")
                //   ._d.getDate(),
                date: 1,
              }),
          y: item.hour ?? Math.trunc(item.average),
        };
      }),
    },
  ];

  const options = {
    //data on the x-axis
    chart: {
      id: "area-chart",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: ["#7A51AE"],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "datetime",
      labels: {
        show: true,
        datetimeUTC: false,
      },
    },
    markers: {
      size: 3,
      hover: {
        size: 9,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.05,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    title: {
      text: "Sleep Tracker",
      margin: 40,
      style: {
        color: "black",
        fontSize: "25px",
        fontWeight: 700,
      },
    },
    stroke: {
      curve: "smooth",
      // width: 2, to change the line width
    },
    yaxis: [
      {
        axisBorder: {
          show: true,
          color: "#e2e2e2",
        },
      },
    ],
    noData: {
      text: "There is no data available to plot the graph",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
  };

  const options2 = {
    //data on the x-axis
    chart: {
      id: "bar-chart2",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: ["#7A51AE"],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "datetime",
      labels: {
        show: true,
        datetimeUTC: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    // markers: {
    //   size: 3,
    //   hover: {
    //     size: 9,
    //   },
    // },
    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     shadeIntensity: 1,
    //     inverseColors: false,
    //     opacityFrom: 0.05,
    //     opacityTo: 0.05,
    //     stops: [20, 100, 100, 100],
    //   },
    // },
    title: {
      text: "Sleep Tracker",
      margin: 40,
      style: {
        color: "black",
        fontSize: "25px",
        fontWeight: 700,
      },
    },
    stroke: {
      curve: "smooth",
      // width: 2, to change the line width
    },
    yaxis: [
      {
        axisBorder: {
          show: true,
          color: "#e2e2e2",
        },
      },
    ],
    noData: {
      text: "There is no data available to plot the graph",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
  };

  const onCheckTodayActivityHandler = () => {
    navigate("/clientselfcareplan");
  };

  // Array.from({ length: 10 }, (_, i) => {
  //   return {
  //     hour:'i',
  //     date:moment()
  //   }
  // });

  const icons = {
    "Nutritional Wellness": Nutrition,
    "Cognitive Wellness": Cognitve,
    "Emotional Wellness": Selfcare,
    "Social Wellness": Social,
    "Physical Wellness": Exercise,
  };

  let [hours, incHours] = String(data?.averagesleep).split(".");

  let minutes = `0.${incHours}` * 60;

  return (
    <>
      <div className={styles.sleepContainer}>
        <Paper elevation={3} className={styles.sleepcss}>
          {/* <Chart height="300px" options={options} series={series} type="area" /> */}
          <Chart height="300px" options={options2} series={series} type="bar" />
          <div className={styles.row}>
            <div className={styles.column}>
              <div className={styles.column}>
                <Sleep />
              </div>
              <div className={styles.flexColumn}>
                <Typography variant="caption" gutterBottom>
                  Avg. sleep hours
                </Typography>
                <div className={styles.time}>
                  {hours === "undefined" ? 0 : hours} Hrs{" "}
                  {(!isNaN(minutes) && Math.trunc(minutes)) || 0} Mins
                  {/* {data?.averagesleep?.toFixed(2)} Hours */}
                </div>
              </div>
            </div>
            <div className={styles.column}>
              <div className={styles.column}>
                <SleepTime />
              </div>
              <div className={styles.flexColumn}>
                <Typography variant="caption" gutterBottom>
                  Sleep Time
                </Typography>
                <div className={styles.time}>{data?.sleeptime}</div>
              </div>
            </div>
          </div>
        </Paper>
        <Paper elevation={3} className={styles.sleepcss}>
          <MoodAreaChart data={data} />
        </Paper>
      </div>
      {/* <Paper elevation={3} className={styles.sleepcss2}>
        <Chart
          height="300px"
          // width="100%"
          options={options2}
          series={series}
          type="bar"
        />
      </Paper> */}

      {dailyTracker && (
        <>
          <div className={styles.secondPaperContainer}>
            <Paper elevation={3} className={styles.circularcss}>
              <div className={styles.DailyTrack}>
                <Typography variant="h6" gutterBottom>
                  Daily Activity Tracker
                </Typography>
                <Typography>No. of Activities</Typography>
                <Typography variant="h4">
                  {dailyTracker?.question?.answered}/
                  {dailyTracker?.question?.total}
                </Typography>
                <ProgressBar
                  bgcolor={"#E69A8D"}
                  completed={Math.floor(
                    (dailyTracker?.question?.answered /
                      dailyTracker?.question?.total) *
                      100
                  )}
                  // completed={10}
                />
                <div className={styles.btncss}>
                  <Btn
                    color={"white"}
                    width={"100%"}
                    height={"40px"}
                    click={onCheckTodayActivityHandler}
                  >
                    Check today activity
                  </Btn>
                </div>
              </div>

              {/* {dailyTracker?.map((item) => {
            return (
              <CircularBar
                Name={item.title}
                percentage={item.value}
                barcolor={'#50cd89'}
                icon={Nutrition}
              />
            );
          })} */}

              {Object.keys(dailyTracker).length ? (
                <>
                  <CircularBar
                    Name={"Nutrition"}
                    percentage={Math.trunc(
                      dailyTracker["Nutritional Wellness"] * 100
                    )}
                    barcolor={"#50cd89"}
                    icon={Nutrition}
                  />
                  <CircularBar
                    Name={"Cognition"}
                    percentage={Math.trunc(
                      dailyTracker["Cognitive Wellness"] * 100
                    )}
                    barcolor={"#00ccf1"}
                    icon={Cognitve}
                  />

                  <CircularBar
                    Name={"Self-Care"}
                    percentage={Math.trunc(
                      dailyTracker["Emotional Wellness"] * 100
                    )}
                    barcolor={"#ff5a5a"}
                    icon={Selfcare}
                  />
                  <CircularBar
                    Name={"Exercise"}
                    percentage={Math.trunc(
                      dailyTracker["Physical Wellness"] * 100
                    )}
                    barcolor={"#ffc070"}
                    icon={Exercise}
                  />
                  <CircularBar
                    Name={"Socialisation"}
                    percentage={Math.trunc(
                      dailyTracker["Social Wellness"] * 100
                    )}
                    barcolor={"#7a51ae"}
                    icon={Social}
                  />
                </>
              ) : null}
            </Paper>
          </div>
          <BottomCardContainer
            dailyTracker={dailyTracker}
            streakData={streakData}
          />
        </>
      )}
    </>
  );
};

export default MentalWellBeing;
