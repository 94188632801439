import React from "react";
import { useState } from "react";
import { Star, StarSmall } from "./Star";

export const FeedbackStarRating = ({
  setRatingStars,
  ratingStars,
  setHover,
  hover,
}) => {
  return (
    <div style={{ display: "flex" }}>
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <>
            <div
              key={index}
              onClick={() => setRatingStars(index)}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(ratingStars)}
              style={{ cursor: "pointer" }}
            >
              {index <= (hover || ratingStars) ? (
                <Star color="#FFB800" />
              ) : (
                <Star />
              )}
            </div>
          </>
        );
      })}
    </div>
  );
};

export const FeedbackStarView = ({ starRating, hover }) => {
  return (
    <div style={{ display: "flex" }}>
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <>
            <div key={index} style={{ cursor: "pointer" }}>
              {index <= (hover || starRating) ? (
                <StarSmall color="#FFB800" />
              ) : (
                <StarSmall />
              )}
            </div>
          </>
        );
      })}
    </div>
  );
};
