import React from "react";
import {
  Input,
  RadioChoice,
  SelectCard,
} from "../../../../Component/UI/CustomInputs";
import styles from "./subscription.module.css";
// import { SelectCard } from "../../../UI/SelectCard";

export const SubscriptionPricing = ({
  discountOptions,
  handleChange,
  servicesCardData,
  subscriptionData,
  netPayable,
  subTotal,
  totalTax,
  discountType,
  netDiscount,
  error,
  expiryDate,
  additionalAmt,
  extendedVal,
  is_extended

}) => {

  const disablePaymentMode = subscriptionData?.status ? subscriptionData?.additional_amount === "" 
                              || subscriptionData?.additional_amount === null 
                              || subscriptionData?.additional_amount === "0" : false

  return (
    <>
      <div style={{ width: "882px" }}>
        <div
          className={`${styles.pricingTab} ${expiryDate ? styles.first : ""}`}
        >
          <Input
            name="total_service"
            label="Total Services"
            width="260px"
            value={servicesCardData?.length}
            disabled
          />
          <Input
            name="sum_price"
            label="Sub Total"
            value={subTotal || 0}
            width="260px"
            disabled
          />
          {expiryDate && (
            <Input
              name="additional_amount"
              type="number"
              label="Additional Amount"
              placeholder={"0"}
              error={error?.additional_amount}
              value={subscriptionData?.additional_amount}
              width="260px"
              onChange={handleChange}
              disabled={additionalAmt}
            />
          )}
        </div>

       

        <div className={styles.pricingTab}>
          <SelectCard
            label="Discount"
            labelstyle={{ width: "fit-content" }}
            optionStyle={{ fontSize: "12px", width: "max-content" }}
            titleMargin="0 0 10px 0"
            options={["No Discount", "Flat", "Percentage"]}
            value={subscriptionData?.discount_type}
            width="260px"
            name="discount_type"
            onChange={handleChange}
            disabled={expiryDate}
          />
          <Input
            name="discount_percentage"
            type="number"
            min={0}
            label="Discount (₹/%)"
            placeholder="Discount"
            width="260px"
            error={error?.discount_percentage}
            value={subscriptionData?.discount_percentage}
            onChange={handleChange}
            disabled={subscriptionData?.discount_type === "0" || expiryDate}
          />

         
        </div>
        <div className={styles.pricingTab}>
          <Input
            name="net_discount"
            label="Net Discount"
            width="260px"
            value={netDiscount || 0}
            // error={error?.netDiscount}
            disabled
          />
          <Input
            name="tax_amount"
            label="Tax"
            value={totalTax || 0}
            width="260px"
            disabled
          />
        </div>
        <div className={styles.pricingTab}>
          <Input
            name="net_price"
            label="Net Payable Amount"
            width="260px"
            value={netPayable || 0}
            disabled
          />
          {expiryDate && (
            <>
            <Input
              name="extended_validity"
              type="number"
              label="Extented Validity"
              placeholder="No. of Days"
              error={error?.extended_validity}
              value={subscriptionData?.extended_validity}
              width="260px"
              onChange={handleChange}
              disabled={extendedVal && is_extended === "1"}
            />
            {/* <p
              style={{
                color: "red",
                margin: "5px",
              }}
            >
              {error?.extendedValidity?.toString()}
            </p> */}
            </>
          )
          
          }
        </div>
        <div
          className={`${styles.pricingTab}`}
        >
           <SelectCard
            label="Payment Mode"
            labelstyle={{ width: "fit-content" }}
            optionStyle={{ fontSize: "12px", width: "max-content" }}
            titleMargin="0 0 10px 0"
            options={["Online", "Offline"]}
            value={subscriptionData?.payment_mode === "online" ? "0" : 
                  subscriptionData?.payment_mode === "offline" ? "1" : ""}
            width="260px"
            name="payment_mode"
            onChange={handleChange}
            disabled={(disablePaymentMode) || (extendedVal && is_extended === "1")}
            error={error?.paymentMode}
          />
         
            <Input
            disabled={subscriptionData?.payment_mode === "online"}
            name="remark"
            type="text"
            label="Remarks"
            //placeholder="Amount"
            error={error?.remarks}
            value={subscriptionData?.remark}
            width="260px"
            onChange={handleChange}
            //disabled={additionalAmt}
          /> 

          
         
         
        </div>
      </div>
      <div>
        {/* <Input
          label="Total Amount"
          width="250px"
          value={sumTotalAmount}
          disabled
        /> */}
      </div>
    </>
  );
};
