import React, { useEffect, useState } from "react";

import ButtonName from "../../../MockData/ButtonName.json";
import Text_Seach_Filter_Export from "../../../Hooks/Text_Seach_Filter_Export";
import { useDispatch, useSelector } from "react-redux";
import SelfCareTipTable from "./SelfcareTipTable";
import { assessmentData } from "../../../Redux/Slices/AssessmentTableReducer";
import axios from "../../../config/axiosConfig";
import QuickAsk from "./QuickAsk";
import AllTabs from "../../../Component/UI/Tabs/AllTabs";
import { AssessmentTable } from "./AssessmentTable";
import TodayActivity from "./TodayActivity";
import { quickAskSelector } from "../../../Redux/Slices/QuickAsk";
import { todayActivitySelector } from "../../../Redux/Slices/TodayActivitySlice";
import { Toaster } from "../../../../node_modules/react-hot-toast/dist/index";
import { usePermissions } from "../../../Hooks/usePermissions";

export default function AdminAssessment() {
  const btnApi = ButtonName.Assessment;
  const tHead = [
    // "",
    "Assessment Name",
    "Number of questions",
    "Score",
    "Date",
    "Status",
    "Action",
  ];

  const assessmentsPerms = usePermissions("name", "assessments");

  const dispatch = useDispatch();

  const { data } = useSelector((i) => i.assessmentData);
  const [id, name, date_of_creation] = data;

  let defaultTab = Object.keys(btnApi[0]);
  const [tabID, selectedTabID] = useState(defaultTab[0]);

  const Routes = {
    Assessments: "/AddAssessment",
    Quick: "/addcategoryquickask",
    TActivity: "/addcategorytodayactivity",
  };

  useEffect(() => {
    getTable();
  }, []);
  const getTable = async () => {
    try {
      const response = await axios.get("/admin/assessment/category");

      dispatch(assessmentData(response.data.list));
    } catch (error) {
      console.log(error);
    }
  };

  const exportData = {
    Assessments: useSelector((i) => i.assessmentData?.data),
    Selfcare: useSelector((state) => state.selfCare?.selfCare),
    Quick: useSelector(quickAskSelector).quickAsk,
    TActivity: useSelector(todayActivitySelector).todayActivity,
  };

  const FinalDataAssessment = data?.map((result) => ({
    "Assessment Name": result.name,
    "Number of Question": result.no_of_question,
    Description: result.short_description,
    Date: result.date_of_creation,
    Status: result.status === 1 ? "True" : "False",
  }));

  const FinalDataSelfcare = exportData.Selfcare?.map((result) => ({
    "Category Name": result.name,
    "Number of Tips": result.selfcare_count,
    Date: result.date_of_creation,
    Status: result.status === 1 ? "Active" : "Disabled",
  }));

  const FinalDataQuick = exportData.Quick?.map((result) => ({
    Name: result.name,
    "Number of Question": result.no_of_question,
    Date: result.category_date,
    Status: result.status === 1 ? "True" : "False",
  }));

  const FinalDataTodayActivity = exportData.TActivity?.map((result) => ({
    Name: result.name,
    "Number of Question": result.no_of_question,
    Date: result.category_date,
    Status: result.status === 1 ? "True" : "False",
  }));

  const obj = {
    Assessments: FinalDataAssessment,
    Selfcare: FinalDataSelfcare,
    "Quick Asks": FinalDataQuick,
    "Today Activity": FinalDataTodayActivity,
  };

  const assessments = <AssessmentTable permissions={assessmentsPerms} />;

  const selfCareTip = <SelfCareTipTable permissions={assessmentsPerms} />;

  const quickAsks = <QuickAsk permissions={assessmentsPerms} />;

  const todayactivity = <TodayActivity permissions={assessmentsPerms} />;

  const ComponentName = [assessments, selfCareTip, quickAsks, todayactivity];

  return (
    <>
      <Toaster />
      <Text_Seach_Filter_Export
        permissions={assessmentsPerms?.listControl}
        text={tabID}
        route={Routes[tabID]}
        ApiData={obj[tabID]}
        searchText={`Search for ${tabID.split(" ").at(-1).toLowerCase()}`}
      >
        <AllTabs
          ComponentName={ComponentName}
          btnApi={btnApi}
          selectedTabID={selectedTabID}
        />
      </Text_Seach_Filter_Export>
    </>
  );
}
