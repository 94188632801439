import styles from '../../../assets/css/PatientUI/level.module.css';

export const Level = ({level,  large }) => {
  
  const coordinates = [
    { 1: '58px', 2: '202px', 3: '352px' },
    { 1: '42px', 2: '145px', 3: '248px' },
  ];

  const move = large ? coordinates[0][level] : coordinates[1][level];

  const containerStyle = large ? { height: '32px', width: '456px' } : null;
  const adjacentStyle = large ? { height: '32px', width: '33.33%' } : null;
  const rangeSize = large ? '48px' : null;

  return (
    <div className={styles.container} style={containerStyle}>
      <div className={styles.rangeContainer} style={{left: ((level-1) * 33 )+ "%", height: large && "32px" }}>
        <div
          className={styles.range}
          style={{ left: move, width: rangeSize, height: rangeSize }}
        ></div>
      </div>
      <div className={styles.level}>
        <div className={styles.green} style={adjacentStyle}></div>
        <div className={styles.red} style={adjacentStyle}></div>
      </div>
      <div className={styles.level}>
        <p>Level 1</p>
        <p>Level 2</p>
        <p>Level 3</p>
      </div>
    </div>
  );
};
