import React, { useState } from "react";
import { useLocation } from "../../../../../node_modules/react-router-dom/index";
import ViewSubscriptionServices from "./ViewSubscriptionServices";
import BasicTabs from "../../../../Samvenda-Admin/UI/Tab/Tabs";
import SummaryData from "./SummaryData";
import styles from "./subscriptionsDetails.module.css";
import moment from "../../../../../node_modules/moment/moment";

export const SubscriptionDetails = () => {
  const { state } = useLocation();

  const [count, setCount] = useState(0);

  const convertDateTime = (t) =>
    t ? moment(new Date(t)).format("DD/MM/YYYY, hh:mmA") : "--";

  const oneMonth = 30;
  const convertValidity = (days) =>
    days >= oneMonth
      ? days / oneMonth > 1
        ? days / oneMonth + " Months"
        : days / oneMonth + " Month"
      : days + " Days";

  let nf = new Intl.NumberFormat("en-US");

  const discountType = (flat, perct, na) =>
    state?.discount_type === "1"
      ? flat
      : state?.discount_type === "2"
      ? perct
      : na;

  const subscriptionDetails = [
    {
      name: "Name",
      value: state?.name,
    },
    {
      name: "User Type",
      value: state?.subscription_type == "0" ? "General" : "Enterprise",
    },

    {
      name: "Enterprise",
      value: state?.enterprise_name,
    },
    {
      name: "Business Segment",
      value: state?.business_name,
    },
    {
      name: "Validity",
      value: convertValidity(state?.validity),
    },
    {
      name: "Date of Creation",
      value: convertDateTime(state?.created_at),
    },
    {
      name: "Date of Start",
      value: convertDateTime(state?.approved_at),
    },
    {
      name: "Date of Expiry",
      value: convertDateTime(state?.expiry_at),
    },
  ];

  const subscriptionPricing = [
    {
      name: "Total Service",
      value: state?.total_service,
    },
    {
      name: "Sub Total",
      value: `₹${nf.format(state?.sum_price)}`,
    },

    {
      name: "Discount",
      value: discountType("Flat", "Percentage", "N/A"),
    },
    {
      name: `Discount ( ${discountType("₹", "%", "N/A")})`,
      value: `${discountType(
        "₹" + nf.format(state?.discount_percentage),
        state?.discount_percentage + "%",
        "N/A"
      )}`,
    },
    {
      name: "Net Discount",
      value: `₹${nf.format(state?.net_discount)}`,
    },
    {
      name: "Tax",
      value: `₹${nf.format(state?.tax_amount)}`,
    },
    {
      name: "Net payable Amount",
      value: `₹${nf.format(state?.net_price)}`,
    },
    
  ];

  const extendedSubscription = [
    {
      name: "Extended Validity",
      value: convertValidity(state?.extended_validity),
    },
    {
      name: "Additional Amount",
      value: `₹${nf.format(state?.additional_amount)}`,
    },
  ];

  const tabData = [
    {
      label: "Subscription Details",
      component: <SummaryData data={subscriptionDetails} />,
    },
    {
      label: "Services",
      component: <ViewSubscriptionServices data={state.subscriptionservice} />,
      //error: isErrorService,
    },
    {
      label: "Pricing",
      component: (
        <SummaryData
          data={subscriptionPricing}
          moreData={extendedSubscription}
          isMoreData={state?.extended_validity}
        />
      ),
    },
  ];
  return (
    <div className={styles.details}>
      <BasicTabs
        tabcontent={tabData}
        value={count}
        setValue={setCount}
        color="#3592FF"
        width="auto"
      />
    </div>
  );
};
