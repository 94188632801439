import { useEffect, useState } from "react";
import styles from "../../assets/css/toggle.module.css";

export const Toggle = (props) => {
  const [toggle, setToggle] = useState(false);

  const ToggleHandle = () => {
    props.toggleHandler();
    setToggle(!toggle);
  };

  useEffect(() => {
    setToggle(props.value);
  }, [props.value]);

  return (
    <div
      className={styles.button}
      style={{
        justifyContent: toggle ? "flex-end" : "flex-start",
        backgroundColor: toggle ? "#0BAE36" : "#ccc",
        width: props.toggleWidth,
        height: props.toggleHeight,
        cursor: "pointer",
      }}
      onClick={ToggleHandle}
    >
      <div
        className={styles.thumb}
        style={{
          backgroundColor: "#FFF",
          width: props.thumbSize,
          height: props.thumbSize,
        }}
      ></div>
    </div>
  );
};
