import React, { useState } from "react";
import classes from "../../assets/css/accordionOption.module.css";

export const SelectCard = ({
  options,
  label,
  style,
  labelstyle,
  onChange,
  name,
  value,
  flag,
  error,
  titleMargin,
}) => {
  return (
    <div className={classes.accordion_opt}>
      <div className={classes.accordion_title} style={{ margin: titleMargin }}>
        {label}
      </div>
      <div className={classes.accordion_flex} style={style}>
        {options?.map((option, i) => (
          <>
            <label className={classes.labl} style={labelstyle}>
              <input
                type="radio"
                name={name}
                value={i}
                checked={flag ? value == i : value}
                onChange={onChange}
              />
              <div key={i}>{option}</div>
            </label>
          </>
        ))}

        {/* <select
          onChange={(e) => onChange(e)}
          name={props.name}
          value={props.value}
        >
          <option selected disabled>
            Select
          </option>
          {props.options?.map((option, i) => (
            <Fragment key={i}>
              <option>{option}</option>
            </Fragment>
          ))}
        </select> */}
      </div>
      <p style={{ color: "red", margin: "5px" }}>{error?.toString()}</p>
    </div>
  );
};
