import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "../../../../../../node_modules/react-router-dom/index";
import { Trash } from "../../../../../assets/icons/Trash";
import { Avatar } from "../../../../../Component/UI/Avatar";
import Popup from "../../../../../Component/UI/Popup/Popup";
import { CustomTable } from "../../../../../Component/UI/Table";
import {
  setFilter,
  setFrom_date,
  setSearch_key,
  setTo_date,
} from "../../../../../Redux/Slices/AdminFilters/Search";
import { getRejectedTestimonials } from "../../../../../Redux/Slices/adminTestimonial";
import {
  listTestimonial,
  updateTestimonial,
} from "../../../../../services/admin/testimonial";
import { Rating } from "./Rating";

export const RejectedTestimonial = ({
  customerId,
  testimonialsPermissions,
  pFeedback,
}) => {
  const fullpermissions = pFeedback
    ? 1
    : +testimonialsPermissions?.access?.["Full Control"];

  const { rejectedTestimonials } = useSelector((i) => i.adminTestimonial);
  const dispatch = useDispatch();
  const [popupAction, setPopupAction] = useState({ open: false });
  const tableHeadings = [
    "Date",
    "Name",
    "Rating",
    "Description",
    ...(fullpermissions ? ["Action"] : []),
  ];
  const navigate = useNavigate();

  const { search_key, from_date, to_date, filter } = useSelector(
    (i) => i.SearchFilters
  );

  const getRejectedTestimonialList = () =>
    listTestimonial(3, customerId, search_key, from_date, to_date, filter).then(
      (res) => {
        const data = res?.data;
        if (data?.status) dispatch(getRejectedTestimonials(data?.list));
      }
    );

  useEffect(() => {
    getRejectedTestimonialList();
  }, [filter]);

  useEffect(() => {
    return () => {
      dispatch(setSearch_key(""));
      dispatch(setTo_date(""));
      dispatch(setFrom_date(""));
      dispatch(setFilter());
    };
  }, []);

  const handleDelete = (id, status) => {
    setPopupAction({
      ...popupAction,
      open: true,
      action: () => {
        updateTestimonial(id, status).then((res) => {
          const data = res?.data;
          if (data?.status) {
            getRejectedTestimonialList();
            setPopupAction({ ...popupAction, open: false });
          }
        });
      },
    });
  };

  const tBodyData = rejectedTestimonials.map((item) => {
    const { id, thoughts, customer, created_at, rating, enterprise } = item;
    const date = moment(created_at).format("DD/MM/YYYY");
    return {
      date,
      name: (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            height: 0,
          }}
        >
          <Avatar
            size="28px"
            profile={customer?.customer_image}
            border="1px solid #ccc"
          />
          <div
            style={{ cursor: "pointer", marginLeft: "8px" }}
            onClick={() => navigate("details", { state: { item, customerId } })}
          >
            {customer?.name ? customer?.name : enterprise?.name}
          </div>
        </span>
      ),
      rating: <Rating ratings={rating} />,
      thoughts: `${thoughts.slice(0, 30)}${thoughts.length > 30 ? "..." : ""}`,
      ...(fullpermissions && {
        action: (
          <div style={{ display: "flex" }}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleDelete(id, 0)}
            >
              <Trash />
            </div>
          </div>
        ),
      }),
    };
  });

  return (
    <>
      <Popup
        popupAction={popupAction}
        setPopupAction={setPopupAction}
        heading="Delete Testimonial"
        title="Are you sure you want to delete this testimonial?"
        styles={{ width: 400, height: 230 }}
        btnMargin="0 10px"
      />
      <CustomTable tHead={tableHeadings} tBody={tBodyData} />
    </>
  );
};
