import { ClassNames } from "@emotion/react";
import React from "react";
import { SelectCard } from "../../../UI/SelectCard";
import classes from "../../../../assets/css/assessmentsummary.module.css";
import { Input, Select } from "../../../../Component/UI/CustomInputs";

export const GeneralObservation = () => {
  const adequateopt = ["Adequate", "Inadequate"];
  const reliableopt = ["Reliable", "Unreliable"];
  const languageopt = ["Yes", "No"];
  const cog_level = [
    "Mild Stage Dementia",
    "Moderate Stage Dementia",
    "Advance Stage Dementia",
    "No Significant Impairment on HMSE/MMSE",
  ];
  const vision = [
    "Adequate Vision",
    "Assistive Vision",
    "Blind",
    "Partial Blind",
  ];

  return (
    <>
      <div className={classes.heading}>
        <h1>Details</h1>
      </div>
      <div className={classes.general_obv}>
        <div>
          <Input label="Date of Assessment" />
        </div>
        <div>
          <Select label="Mode of Assessment" />
        </div>
      </div>
      <div className={classes.general_obv}>
        <div>
          <Input label="Age" />
        </div>
        <div>
          <Select label="Years of Education" />
        </div>
      </div>
      <div className={classes.general_obv}>
        <div>
          <Select label="Relationship" />
        </div>
        <div>
          <SelectCard label="Adequacy" options={adequateopt} />
        </div>
        <div>
          <SelectCard label="Reliability" options={reliableopt} />
        </div>
      </div>
      <div className={classes.heading}>
        <h1>Languages</h1>
      </div>
      <div className={classes.general_obv}>
        <div>
          <Select label="Languages(Multiple)" />
        </div>
      </div>

      <div className={classes.general_obv}>
        <div>
          <Input label="Language" />
        </div>
        <div>
          <SelectCard options={languageopt} />
        </div>
        <div>
          <SelectCard options={languageopt} />
        </div>
      </div>
      <div className={classes.general_obv}>
        <div>
          <Input label="Language" />
        </div>
        <div>
          <SelectCard options={languageopt} />
        </div>
        <div>
          <SelectCard options={languageopt} />
        </div>
      </div>

      <div className={classes.heading}>
        <h1>Observations</h1>
      </div>

      <div className={classes.general_obv_opt}>
        <div>
          <SelectCard label="Cognitive Level" options={cog_level} />
        </div>
        <div>
          <SelectCard label="Vision" options={vision} />
        </div>
        <div>
          <SelectCard label="Motor Coordination" options={cog_level} />
        </div>
        <div>
          <SelectCard label="Restlessness" options={cog_level} />
        </div>
        <div>
          <SelectCard label="Hearing" options={vision} />
        </div>
      </div>
      <div className={classes.heading}>
        <h1>Other</h1>
      </div>

      <div className={classes.general_obv}>
        <div>
          <Select label="Reason For Referral" />
        </div>
        <div>
          <Select label="Chief Complaints" />
        </div>
      </div>
      <div className={classes.general_obv}>
        <div>
          <Input label="Onset" />
        </div>
        <div>
          <Select label="Interest/Hobbies(Multiple)" />
        </div>
      </div>
    </>
  );
};
