const ProgressBar = (props) => {
  const { bgcolor, completed } = props;

  const containerStyles = {
    height: 15,
    width: "100%",
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    marginTop: "10px",
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: "inherit",
    textAlign: "right",
    paddingRight: completed < 3 && completed != 0 ? "5px" : "",
  };

  const labelStyles = {
    padding: 5,
    color: "black",
    fontWeight: "bold",
    marginTop: "5px",
  };

  return (
    <>
      <div style={containerStyles}>
        <div style={fillerStyles}></div>
        <div style={labelStyles}>{`${completed}% Completed`}</div>
      </div>
    </>
  );
};

export default ProgressBar;
