import { createSlice } from "@reduxjs/toolkit";

const patientSearchSlice = createSlice({
  name: "patient events",
  initialState: { searchText: "", toggleSearch: false },
  reducers: {
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setToggleSearch: (state, action) => {
      state.toggleSearch = action.payload;
    },
  },
});

export const { setSearchText, setToggleSearch } = patientSearchSlice.actions;

export default patientSearchSlice.reducer;
