export const Send = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M18.3327 1.66797L9.16602 10.8346"
        stroke="#2A2A2A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3327 1.66797L12.4993 18.3346L9.16602 10.8346L1.66602 7.5013L18.3327 1.66797Z"
        stroke="#2A2A2A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
