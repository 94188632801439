import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classess from "../../../../assets/css/assessment/range.module.css";
import {
  selOption,
  setRelationName,
} from "../../../../Redux/Reducers/PatientReducers/qa_storage/qa_storage";

const InputText = ({ question }) => {
  const [inputText, setinputText] = useState("");
  const { selectedOption } = useSelector((state) => state.qa_storage);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setinputText(e.target.value);
    dispatch(selOption(e.target.value));
    if (question?.question.toLowerCase().includes("name")) {
      dispatch(setRelationName(e.target.value));
    }
  };

  useEffect(() => {
    setinputText(selectedOption);
  }, [selectedOption]);

  return (
    <>
      <div
        style={{ width: "320px", margin: "30px 0" }}
        className={classess.rangeInput}
      >
        <input
          type="text"
          value={inputText}
          onChange={(e) => handleChange(e)}
          maxlength="100"
        />
      </div>
    </>
  );
};

export default InputText;
