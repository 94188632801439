import React from "react";
import { Paper } from "@mui/material/index";
import { ReportIcon } from "./ReportIcon";
import { Helpcircle } from "../../../assets/icons/icon/Helpcircle";
import { CalenderIcon } from "./CalenderIcon";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import styles from "./report.module.css";

export const ReportCard = ({
  title,
  date,
  questions,
  handleBtn,
  imageIcon,
}) => {
  return (
    <>
      <Paper
        elevation={1}
        className={styles.mainCont}
        sx={{
          width: "280px",
          borderRadius: "15px",
          padding: "10px 20px",
          margin: "10px",
        }}
      >
        <div style={{ marginTop: "25px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={imageIcon}
              alt="assessment img"
              width="100px"
              height="100px"
              style={{ objectFit: "contain" }}
            />
            {/* <ReportIcon /> */}
          </div>
          <p
            style={{
              fontSize: "18px",
              lineHeight: "21px",
              margin: "10px 0",
            }}
          >
            {title}
          </p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex" }}>
              <span>
                <Helpcircle width="16" height="16" color="black" />
              </span>
              <span style={{ marginLeft: "3px", fontSize: "14px" }}>
                {questions} Questions
              </span>
            </div>
            <div style={{ display: "flex" }}>
              <span>
                <CalenderIcon />
              </span>
              <span style={{ marginLeft: "3px", fontSize: "14px" }}>
                {date}
              </span>
            </div>
          </div>
          <div
            style={{
              margin: "10px 0px",
            }}
          >
            <Btn
              variant="contained"
              borderRadius="8px"
              bgColor="#3592FF"
              width="100%"
              height="36px"
              click={handleBtn}
            >
              Download
            </Btn>
          </div>
        </div>
      </Paper>
    </>
  );
};
