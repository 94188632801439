import React, { useEffect, useState } from "react";
import { TabView2 } from "../../UI/TabView/TabView2";
import axios from "../../../config/axiosConfig";
import { Toaster } from "../../../../node_modules/react-hot-toast/dist/index";
import { Select } from "../../../Component/UI/CustomInputs";
import styles from "./Service.module.css";
import { useAuth } from "../../../Auth/AuthProvider";
import { getServiceEnterprise } from "../../../services/patient/assessment";
import { Helmet } from "react-helmet";
export const ServicesPatient = () => {
  const [data, setData] = useState([{ id: 0, name: "Recommended" }]);
  const ctx = useAuth();
  const [selectEnterPrise, setSelectEnterPrise] = useState(
    ctx?.loginUser?.customer?.enterprise_id,
  );
  useEffect(() => {
    axios.get(`customer/all/assessment`).then((res) => {
      setData([...data, ...res.data.list]);
    });
  }, []);

  const options = [
    {
      id: ctx?.loginUser?.customer?.enterprise_id,
      value: `${ctx?.loginUser?.customer?.enterprise_name} Services`,
    },
    {
      id: 0,
      value: "General",
    },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Services - Samvedna Map</title>
        <link
          rel="canonical"
          href="https://map.samvednacare.com/patient/services"
        />
        <meta name="title" content="Services - Samvedna Map" />
        <meta
          name="description"
          content="Samvedna Map provides online counselling  services based your assessment results. Check which services works best for you and goahead and sign up."
        />
        <meta name="robots" content="index,follow" />
      </Helmet>
      <div style={{ margin: "16px" }}>
        <Toaster />
        {ctx?.loginUser?.customer?.enterprise_id && (
          <div className={styles.dropDown}>
            <Select
              options={options}
              value={selectEnterPrise}
              onChange={(e) => setSelectEnterPrise(e.target.value)}
            />
          </div>
        )}

        <TabView2
          values={data}
          selectEnterPrise={selectEnterPrise}
          enterpriseId={ctx?.loginUser?.customer?.enterprise_id}
        />

        {/* <div className={classes.service_card}>
        {data.map((i) => {
          return <ServicesCards data={i} />;
        })}
      </div> */}
      </div>
    </>
  );
};
