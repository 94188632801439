import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  search_key: "",
  from_date: "",
  to_date: "",
  filter: false,
  clear: false,
};

const SearchFilters = createSlice({
  name: "SearchFilters",
  initialState,
  reducers: {
    setSearch_key: (state, { payload }) => {
      state.search_key = payload;
    },
    setFrom_date: (state, { payload }) => {
      state.from_date = payload;
    },
    setTo_date: (state, { payload }) => {
      state.to_date = payload;
    },
    setFilter: (state, { payload }) => {
      state.filter = !state.filter;
    },
    setClear: (state) => {
      state.clear = !state.clear;
    },
    resetSearchFilters: () => initialState,
  },
});

export const {
  setSearch_key,
  setFrom_date,
  setTo_date,
  setFilter,
  setClear,
  resetSearchFilters,
} = SearchFilters.actions;

export default SearchFilters.reducer;
