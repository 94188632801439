import React, { useState, useEffect } from "react";
import styles from "./myaccount.module.css";
import { InputWithIcon } from "../../../Component/UI/CustomInputs";

import { Eye } from "../../../assets/icons/Eye";
import { passwordValidate } from "../../../config/passwordValidate";
import toast from "../../../../node_modules/react-hot-toast/dist/index";
import { useAuth } from "../../../Auth/AuthProvider";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import { updatePassword } from "../../../services/admin/myAccount";

export const ChangePassword = () => {
  const [passwords, setPasswords] = useState({});
  const [passwordErr, setPasswordErr] = useState("");
  const [confirmErr, setConfirmErr] = useState("");
  const [err, setErr] = useState({});
  const ctx = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  let fields = [
    { name: "old_password", label: "Current Password" },
    { name: "new_password", label: "New Password" },
    { name: "confirm_password", label: "Confirm Password" },
  ];

  useEffect(() => {
    if (passwords.new_password !== passwords.confirm_password) {
      setConfirmErr("Confirmation password is not equal to the new password");
    } else setConfirmErr("");
  }, [passwords]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswords({ ...passwords, [name]: value });

    const check = passwordValidate(passwords.new_password);

    if (check !== true) {
      setPasswordErr(
        "Password should be at least of 8 characters. Must contain CAPITAL letter, Special character (@,$...) & number"
      );
    } else setPasswordErr("");
  };

  const handleClick = () => {
    if (passwordErr === "" && confirmErr === "") {
      setIsLoading(true);
      updatePassword(passwords)
        .then((res) => {
          setIsLoading(false);
          if (res?.data?.status) toast.success(res?.data?.msg);
          setErr(res?.data?.error);
        })
        .catch((err) => {
          setErr(err?.data?.error);
        });
    } else
      setErr({
        ...err,
        new_password: passwordErr,
        confirm_password: confirmErr,
      });
  };
  return (
    <div className={styles.password}>
      <h2>Update Password</h2>
      {fields.map(({ label, name }, i) => {
        return (
          <InputWithIcon
            key={i}
            type="password"
            name={name}
            error={err?.[name]}
            placeholder="Enter password"
            label={label}
            width="400px"
            icon={Eye}
            onChange={handleChange}
            req
          />
        );
      })}
      <div className={styles.btn}>
        <Btn
          width="100px"
          borderRadius="100px"
          bgColor="#00B091"
          disabled={isLoading}
          click={handleClick}
        >
          Update
        </Btn>
      </div>
    </div>
  );
};
