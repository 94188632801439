import React, { useState } from "react";
import {
  NativeDatePicker,
  Select,
} from "../../../../Component/UI/CustomInputs";
import styles from "./service.module.css";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  useLocation,
  useNavigate,
} from "../../../../../node_modules/react-router-dom/index";
import { Calender } from "../../../../Component/UI/Calender";
import { FileUpload } from "../../../../Component/UI/FileUpload";
import { FileUploadPdf } from "../../../../Component/UI/PdfUpload";
import { useRef } from "react";
import {
  Toaster,
  toast,
} from "../../../../../node_modules/react-hot-toast/dist/index";
import { importPdfFile } from "../../../../services/admin/patient";
import moment from "../../../../../node_modules/moment/moment";
export const AddReports = () => {
  const dropDownData = [
    {
      id: "Psychological_Assessment",
      value: "Psychological Assessment",
    },
    {
      id: "Consultation_Reports",
      value: "Consultation Reports",
    },
    {
      id: "Medical_Reports",
      value: "Medical Reports",
    },
  ];

  const [excelName, setExcelName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dataReports, setDataReports] = useState([]);
  const { state } = useLocation();
  console.log(state, "state=====");
  const navigate = useNavigate();
  const actualBtnRef = useRef();
  const acceptExtentions = ["pdf"];
  const importPdf = (e) => {
    const f = e?.target?.files[0];
    const fileExtention = f?.name.split(".").at(-1);
    if (acceptExtentions.includes(fileExtention)) {
      setExcelName(f);
    } else {
      setExcelName("");
      toast.error("Please select valid format file");
    }
  };

  console.log(dataReports, excelName, "dataReports===");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataReports({ ...dataReports, [name]: value });
  };

  const submitExcel = () => {
    toast.remove();
    if (!dataReports?.reportType) {
      toast.error("Please Select Report Type");
      return;
    }
    if (!dataReports?.reportDate) {
      toast.error("Please Select Report Date");
      return;
    }
    if (!excelName) {
      toast.error("Please Select Report PDF File");
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", excelName);
    formData.append("report_type", dataReports?.reportType);
    formData.append("report_date", dataReports?.reportDate);
    formData.append("customer_id", state?.customerId);
    importPdfFile(formData).then((res) => {
      setIsLoading(false);
      setExcelName("");
      actualBtnRef.current.value = "";
      if (res?.data?.status) {
        toast.success(res?.data?.msg);
        navigate("/patientsProfile/adminReports", {
          state: {
            customerId: state?.customerId,
            customerName: state?.customerName,
          },
        });
      } else {
        toast.error(res?.data?.error[0]);
      }
    });
  };

  return (
    <>
      <Toaster />
      <div className={styles.addReports}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Select
                name="reportType"
                label="Report Type"
                options={dropDownData}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <NativeDatePicker
                label="Report Date"
                name="reportDate"
                max={moment().format("YYYY-MM-DD")}
                width="100%"
                // value={staff?.dob}
                // error={errors?.dob}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={8}>
              <div>
                <label style={{ marginBottom: "9px", display: "block" }}>
                  Select PDF
                </label>
                <FileUploadPdf
                  marginTop={0}
                  onChange={importPdf}
                  actualBtnRef={actualBtnRef}
                  fileName={excelName?.name}
                />
              </div>
            </Grid>

            <Grid item xs={8}>
              <div style={{ textAlign: "right" }}>
                <button
                  onClick={submitExcel}
                  className={styles.addButtuon}
                  disabled={isLoading}
                >
                  ADD
                </button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};
