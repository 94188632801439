import { CustomTable } from "../../../../Component/UI/Table";
import { useLocation } from "react-router-dom";
import { Badges } from "./Badges";

export const AssessmentData = () => {
  const { state } = useLocation();
  const { name, data, bmi } = state;

  const tHead = ["Q.No.", "Question", ""];
  const prefix = (val) => (val <= 9 ? "0" + val : val);

  const tBodyData = data?.map(
    ({
      serial_number,
      question,
      selectedOption,
      tag,
      subquestionSelectedOption,
      subquestion,
    }) => {
      return {
        no: <p style={{ paddingBottom: "28px" }}>{prefix(serial_number)}</p>,
        question: (
          <div style={{ padding: "8px" }}>
            <p>{question}</p>
            {typeof selectedOption !== "object" ? (
              <div style={{ display: "flex" }}>
                <Badges text={selectedOption} />
                {subquestion && <Badges text={subquestionSelectedOption} />}
              </div>
            ) : (
              <div style={{ display: "flex" }}>
                {selectedOption?.map((text) => (
                  <>
                    <Badges text={text} />
                    {subquestion && <Badges text={subquestionSelectedOption} />}
                  </>
                ))}
              </div>
            )}
          </div>
        ),
        score: "",
      };
    }
  );
  return (
    <div>
      <h1 style={{ margin: "12px 0", fontSize: "24px", fontWeight: "500" }}>
        {name}
      </h1>
      <CustomTable tHead={tHead} tBody={tBodyData} />
    </div>
  );
};
