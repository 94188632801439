import React from "react";
import { useLocation } from "../../../../../../node_modules/react-router-dom/index";
import SimpleBreadcrum from "../../../../../Component/UI/SimpleBreadcrum";
import { Rating } from "../../Testimonial/TabComponents/Rating";
import styles from "../feedback.module.css";

export const ViewFeedback = () => {
  const { state } = useLocation();
  const links = !state?.customerId
    ? [
        {
          path: "/Samvedna/feedback",
          linkText: "Feedbacks",
        },
      ]
    : [
        {
          path: "/Patients",
          linkText: "Patients",
        },
        {
          path: "/patientsProfile",
          linkText: state?.item?.customer?.name,
          data: { name: state?.item?.customer?.name, props: state?.customerId },
        },
      ];
  return (
    <>
      <SimpleBreadcrum text="Feedback Details" link={links} />
      <div className={styles.details_card}>
        <div>
          <div>
            <p className={styles.sub_heading}>{state?.item?.feedback_type}</p>
            <div className={styles.icons}></div>
          </div>
          {/* <p className={styles.text}>{serviceName}</p> */}
        </div>
        <div>
          <p className={styles.text}>
            <Rating size={40} ratings={state?.item?.rating} />
          </p>
          <p className={styles.sub_heading}>Description</p>
          <p className={styles.text}>{state?.item?.feedback}</p>
        </div>
      </div>
    </>
  );
};
