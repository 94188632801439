import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  qaData: [],
  InitalData: [],
  selectedOption: "",
  selectedScore: "",
  age: "40",
  weight: "0",
  height: "0",
  cigarettes: "",
  bmiCalculate: "",
  multiOption: [],
  multiScore: [],
  range: "",
  assessment_for: "Myself",
  category_id: "",
  handleNext: true,
  numberOfQues: "number",
  Timetaken: "00:00",
  subquestion: "",
  relation: "",
  relationName: "",
  relation_id: "",
  retakeType: "first",
  employee_email: "",
};

const qaStorage = createSlice({
  name: "qaStore",
  initialState,
  reducers: {
    qaStore: (state, { payload }) => {
      state.qaData = payload;
    },
    AddData: (state, { payload }) => {
      state.InitalData = payload;
    },
    selHeight: (state, action) => {
      state.height = action.payload;
    },
    selAge: (state, action) => {
      state.age = action.payload;
    },
    selRange: (state, action) => {
      state.range = action.payload;
    },
    selWeight: (state, action) => {
      state.weight = action.payload;
    },
    selCigarettes: (state, action) => {
      state.cigarettes = action.payload;
    },
    SetbmiCalculate: (state, action) => {
      state.bmiCalculate = action.payload;
    },
    selOption: (state, action) => {
      state.selectedOption = action.payload;
    },
    selScore: (state, action) => {
      state.selectedScore = action.payload;
    },
    selectMultiOption: (state, action) => {
      state.multiOption = action.payload;
      // console.log("redux",state.multiOption);
    },
    selectMultiScore: (state, action) => {
      state.multiScore = action.payload;
    },
    setAssessment_for: (state, action) => {
      state.assessment_for = action.payload;
    },
    setCategoryId: (state, action) => {
      state.category_id = action.payload;
    },
    setHandleNext: (state, action) => {
      state.handleNext = action.payload;
    },
    setNumberOfQues: (state, action) => {
      state.numberOfQues = action.payload;
    },
    setTimetaken: (state, action) => {
      state.Timetaken = action.payload;
    },
    setSubquestion: (state, action) => {
      state.subquestion = action.payload;
    },
    setRelation: (state, action) => {
      state.relation = action.payload;
    },
    setRelationName: (state, action) => {
      state.relationName = action.payload;
    },
    setRelationId: (state, action) => {
      state.relation_id = action.payload;
    },
    setRetakeType: (state, action) => {
      state.retakeType = action.payload;
    },
    setEmployeeEmail: (state, action) => {
      state.employee_email = action.payload;
    },
    resetQaStorage: (state) => (state = initialState),
  },
});

export const {
  qaStore,
  SetbmiCalculate,
  selHeight,
  selAge,
  selCigarettes,
  selWeight,
  bmi,
  selOption,
  selScore,
  selectMultiOption,
  selectMultiScore,
  AddData,
  selRange,
  setAssessment_for,
  setCategoryId,
  setHandleNext,
  setNumberOfQues,
  setTimetaken,
  setSubquestion,
  setRelation,
  setRelationName,
  setRelationId,
  setRetakeType,
  setEmployeeEmail,
  resetQaStorage,
} = qaStorage.actions;

export default qaStorage.reducer;
