import React from "react";
import Chart from "../Charts/Charts";
import Excellent from "../Charts/images/Excellent.svg";
import Happy from "../Charts/images/Happy.svg";
import Upset from "../Charts/images/Upset.svg";
import Angry from "../Charts/images/Angry.svg";
import Sad from "../Charts/images/Sad.svg";
import { Link } from "react-router-dom";
import { SkeletonLoaderNew } from "../../../../Component/Common/SkeletonLoader";
export const MoodScore = ({
  styles,
  styles2,
  sleepHourData,
  getSleepTrackerData,
  isLoadingHours,
}) => {
  console.log(sleepHourData, "sleepHourData-");
  return (
    <div className={styles.cardChart}>
      <div className={styles.chartHead}>Mood Score</div>
      {!isLoadingHours ? (
        <div className={styles2.mainChart}>
          <Chart
            type={"donut"}
            labels={["Excellent", "Happy", "Upset", "Angry", "Sad"]}
            colors={["#66CC39", "#AAC514", "#FFD936", "#F77B33", "#FBAA34"]}
            sleepHourData={sleepHourData}
            width="220"
          />
          <div className={styles2.customLabel}>
            <div className={styles2.firstRow}>
              <div className={styles2.chartImg}>
                <img src={Excellent} alt="Excellent" />
                <div className={styles2.chartText}>
                  Excellent
                  {
                    <span>
                      <svg width="8" height="8" viewBox="0 0 8 8" fill="none">
                        <rect width="8" height="8" fill="#66CC39" />
                      </svg>
                      {sleepHourData[0] ? sleepHourData[0] + "%" : 0 + "%"}
                    </span>
                  }
                </div>
              </div>
              <div className={styles2.chartImg}>
                <img src={Happy} alt="Happy" />
                <div className={styles2.chartText}>
                  Happy
                  {
                    <span>
                      <svg
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="8" height="8" fill="#AAC514" />
                      </svg>

                      {sleepHourData[1] ? sleepHourData[1] + "%" : 0 + "%"}
                    </span>
                  }
                </div>
              </div>
            </div>

            <div className={styles2.secondRow}>
              <div className={styles2.chartImg}>
                <img src={Upset} alt="Upset" />
                <div className={styles2.chartText}>
                  Upset
                  {
                    <span>
                      <svg width="8" height="8" viewBox="0 0 8 8" fill="none">
                        <rect width="8" height="8" fill="#FFD936" />
                      </svg>

                      {sleepHourData[2] ? sleepHourData[2] + "%" : 0 + "%"}
                    </span>
                  }
                </div>
              </div>
              <div className={styles2.chartImg}>
                <img src={Angry} alt="Angry" />
                <div className={styles2.chartText}>
                  Angry
                  {
                    <span>
                      <svg width="8" height="8" viewBox="0 0 8 8" fill="none">
                        <rect width="8" height="8" fill="#F77B33" />
                      </svg>

                      {sleepHourData[3] ? sleepHourData[3] + "%" : 0 + "%"}
                    </span>
                  }
                </div>
              </div>
            </div>
            <div className={styles2.thirdRow}>
              <div className={styles2.chartImg}>
                <img src={Sad} alt="Sad" />
                <div className={styles2.chartText}>
                  Sad
                  {
                    <span>
                      <svg width="8" height="8" viewBox="0 0 8 8" fill="none">
                        <rect width="8" height="8" fill="#FBAA34" />
                      </svg>

                      {sleepHourData[4] ? sleepHourData[4] + "%" : 0 + "%"}
                    </span>
                  }
                </div>
              </div>

              <div></div>
            </div>
          </div>
        </div>
      ) : (
        <SkeletonLoaderNew />
      )}
      <div className={styles.bottomChart}>
        <div className={styles.bottomText}>
          <p>
            <Link
              to="/Patients"
              style={{
                textDecoration: "none",
                color: "#7a51ae",
              }}
              state={{
                data: getSleepTrackerData?.customer_Data_id,
                wellness: true,
              }}
            >
              #Users tracking progress: {getSleepTrackerData?.attemptedPerson}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
