import React from 'react'
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import BasicBreadcrumbs from '../../../../Component/UI/BreadCrumb/BreadCrumbs';
import style from "./NotificationDetails.module.css"

const NotificationDetails = () => {
  
  // Responsive Position By Js
  const w = window.innerWidth;
  const paperLeft = (w<600)?"40px":"260px";
  const paperTop = (w<600)?"310px":"175px";
  return (
    <>
      <BasicBreadcrumbs
        link={"Notification Management"}
        text={"Notification Details"}
      >
        <div
          style={{
            display: "flex",
            width: "100,%",
            padding: "25px 25px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="contained"
            className={style.delBtn}
            style={{
              backgroundColor: "#00B091",
              borderRadius: 50,
              width: 150,
              height: 50,
              position:"absolute",
              zIndex:1,
              right:20,
              top:100,
            }}
          >
            {" "}
            Delete
          </Button>

          <Paper
            elevation={1}
            style={{
              position: "absolute",
              top: paperTop,
              width: "82%",
              left: paperLeft,
              height: "70%",
              padding: "25px 30px",
            }}
          >
            <div style={{ width: "85%" }}>
              <div> Name </div>
              <div style={{ marginTop: "15px" }}>
                <strong>Welcome Email</strong>
              </div>
            </div>
            <div style={{ width: "85%", marginTop: "25px" }}>
              <div> Welcome Notification </div>
              <div style={{ marginTop: "15px" }}>
                <strong>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </strong>
              </div>
            </div>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#00B091",
                borderRadius: 50,
                width: 100,
                height: 50,
                bottom: 20,
                position: "absolute",
              }}
            >
              {" "}
              Edit{" "}
            </Button>
          </Paper>
        </div>
      </BasicBreadcrumbs>
    </>
  );
}

export default NotificationDetails