import { useDispatch, useSelector } from "react-redux";
import {
  Input,
  Select,
  SelectWithId,
} from "../../../../Component/UI/CustomInputs";
import { addPatientData } from "../../../../Redux/Slices/addPatient";
import styles from "../../../../assets/css/AdminPatients/profile.module.css";

export const PrimaryCareGiver = ({
  data,
  err,
  writePermissions,
  CountryCodes,
}) => {
  const dispatch = useDispatch();
  const rgx = /^(?:[0-9+]+)?$/;

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(addPatientData({ [name]: value }));
  };
  console.log(data, "data99999");
  return (
    <>
      <div className={styles.caregiver}>
        <div>
          <div className={styles.size}>
            <Select
              name="has_primary"
              value={data?.has_primary}
              error={err?.has_primary}
              options={[
                { id: 1, value: "Yes" },
                { id: 2, value: "No" },
              ]}
              disabled={writePermissions === 0 ? true : false}
              label="Has Primary Care Giver?"
              onChange={handleChange}
            />
          </div>
          <div className={styles.size}>
            <Select
              name="caregiver_relationship"
              value={data?.caregiver_relationship}
              error={err?.caregiver_relationship}
              options={[
                { id: 1, value: "Yes" },
                { id: 2, value: "No" },
              ]}
              label="Relationship"
              disabled={writePermissions === 0 ? true : false}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className={styles.sa}>
          <div className={styles.size}>
            <Input
              name="caregiver_name"
              label="Name"
              value={data?.caregiver_name ? data?.caregiver_name : ""}
              error={err?.caregiver_name}
              placeholder="Full Name"
              disabled={writePermissions === 0 ? true : false}
              onChange={handleChange}
            />
          </div>
          <div
            style={{ display: "flex", alignItems: "center", gap: "10px" }}
            className={`${styles.size}${styles.phoneSec}`}
          >
            <SelectWithId
              name="caregiverCountryCode"
              options={CountryCodes}
              width="180px"
              label="Country Code"
              value={data?.caregiverCountryCode}
              onChange={handleChange}
            />
            <Input
              name="caregiver_mobile"
              label="Mobile"
              value={data?.caregiver_mobile}
              error={err?.caregiver_mobile}
              onChange={(e) => {
                const isNum = rgx.test(e.target.value);
                isNum &&
                  dispatch(
                    addPatientData({ caregiver_mobile: e.target.value })
                  );
              }}
              disabled={writePermissions === 0 ? true : false}
            />
          </div>
        </div>
      </div>
    </>
  );
};
