import { Logout } from "../../assets/icons/Logout";
import styles from "../../assets/css/UI/logout.module.css";
import { useAuth } from "../../Auth/AuthProvider";
import { useNavigate } from "react-router-dom";
import User from "../../assets/icons/icon/user.svg";

export const LogoutBox = ({ setAnchorEl }) => {
  const ctx = useAuth();
  const navigate = useNavigate();
  return (
    <>
      <div
        className={styles.logout}
        onClick={() => {
          navigate("/myaccount");
          setAnchorEl(null);
        }}
      >
        <div className={styles.content}>
          <img src={User} alt="userIcon" />
          <p>Profile</p>
        </div>
      </div>
      <div
        className={styles.logout}
        onClick={() => {
          ctx.logout();
          navigate("/smv-admin/signin");
        }}
      >
        <div className={styles.content}>
          <Logout />
          <p>Logout</p>
        </div>
      </div>
    </>
  );
};
