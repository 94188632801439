import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { teal } from "@mui/material/colors";

const ColorButton = styled(Button)(
  ({ theme, width, btncoloractive, height }) => ({
    color: theme.palette.getContrastText(teal[500]),
    backgroundColor: btncoloractive ? "#2A2A2A" : "#00B091",
    borderRadius: "20px",
    width: `${width}`,
    height: height || "40px",
    "&:hover": {
      background: btncoloractive ? "#2A2A2A" : teal[500],
    },
    textTransform: "none",
  })
);

export default function CustomizedButtons({
  ButtonLabel,
  width,
  btncoloractive,
  click,
  type,
  height,
  disabled
}) {
  return (
    <Stack spacing={2} direction="row">
      <ColorButton
        disabled={disabled}
        variant="contained"
        onClick={click}
        btncoloractive={btncoloractive}
        width={width}
        height={height}
        type={type}
      >
        {ButtonLabel}
      </ColorButton>
    </Stack>
  );
}
