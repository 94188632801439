import { EmptyEvent } from "../../UI/EmptyEvent";
import { AppointmentCards } from "../../AppointmentCards";

import styles from "../../../assets/css/PatientDashboard/appointments.module.css";
import { getAppointmentsTabData } from "../../../services/patient/appointments";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { completedAppointments } from "../../../Redux/Slices/UserAppointments/typeAppointments";
import { EmptyAppointment } from "../../UI/EmptyAppointment";
import { searchAndFilter } from "./Upcoming";
import {
  setSearchText,
  setToggleSearch,
} from "../../../Redux/Slices/patientSearch";
import {
  setDate,
  setToggle,
  setYear,
} from "../../../Redux/Slices/Patient/PatienCalender";
import { useNavigate } from "../../../../node_modules/react-router-dom/index";

export const OnGoing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { completed } = useSelector((i) => i.typeAppointments);
  const { searchText } = useSelector((i) => i.patientSearch);
  const [allData, setAllData] = useState([]);
  const { selectedDate } = useSelector((i) => i.DateFilterSlice);
  const appPage = "ongoing";
  useEffect(() => {
    getAppointmentsTabData("ongoing").then((i) => {
      const filterCommunity = i?.filter((i) => i?.service?.is_community !== 1);
      dispatch(completedAppointments(filterCommunity));
      setAllData(filterCommunity);
    });
    return () => {
      dispatch(setToggleSearch(false));
      dispatch(setSearchText(""));
      // dispatch(setDate(''));
      dispatch(setDate(`All ${new Date().getFullYear()}`));
      dispatch(setToggle(false));
      dispatch(setYear(new Date().getFullYear()));
    };
  }, []);

  useEffect(() => {
    const searchResult = searchAndFilter(allData, searchText, selectedDate);
    dispatch(completedAppointments(searchResult));
  }, [searchText, selectedDate, allData]);

  return (
    <div className={styles.upcoming}>
      {completed?.length ? (
        completed?.map((data, i) => (
          <AppointmentCards
            key={i}
            onClick={() => navigate("details", { state: { data, appPage } })}
            name={data?.service?.name}
            date={data?.appointments?.[0].appointment_date?.split(" ")?.[0]}
            start_time={data?.appointments?.[0].appointment_time}
            endTime={data?.appointments?.[0].end_time}
            sessionId={data?.appointments[0]?.session_id}
            customer_id={data?.customer_id}
            paitient_name={data?.appointments?.[0]?.paitient_name}
            data={data}
            styles={styles}
          />
        ))
      ) : (
        <EmptyAppointment />
      )}
    </div>
  );
};
