import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
export const LeaveCheckBox = ({leave, handleLeaveChange}) => {
const radioColor = "#00B091"
  return (
    <div style={{marginTop: '-8px'}}>
      <FormControl>
        <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="controlled-radio-buttons-group"
        value={leave}
        onChange={handleLeaveChange}
      >
        <FormControlLabel 
        value="yes" 
        control={<Radio 
            sx={{
                color: "#00B091",
                '&.Mui-checked': {
                  color: "#00B091",
                },
              }}
        />} label="Yes" />
        <FormControlLabel 
        value="no" 
        control={<Radio 
         sx={{
            color:"#00B091",
            '&.Mui-checked': {
              color: "#00B091",
            },
          }}
        />} label="No" />
      </RadioGroup>
      </FormControl>
    </div>
  );
};
