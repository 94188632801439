import React, { useEffect, useState } from "react";
import styles from "./popup.module.css";
import { Btn } from "../../../../../Samvenda-Patient/UI/Button";
import { useNavigate } from "../../../../../../node_modules/react-router-dom/index";

export const AddRosterSuccesses = ({
  popupOpen,
  setLeave,
  setPopupOpen,
  RosterAddIcon,
  closeBtn,
  yesBtnText,
  onChangeLeave,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div onClick={() => setPopupOpen(false)} />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.iconimg}>
            <img src={RosterAddIcon} alt="" />
          </div>
          <div
            className={styles.modalContent}
            style={{ padding: "10px 10px 0 10px" }}
          >
            {popupOpen?.body?.data}
          </div>
          <div className={styles.btnGroup}>
            {yesBtnText ? (
              <Btn
                variant="contained"
                bgColor="#00B091"
                color="#fff"
                width="120px"
                height="40px"
                whiteSpace="nowrap"
                borderRadius="100px"
                margin="15px"
                click={onChangeLeave}
              >
                {yesBtnText}
              </Btn>
            ) : (
              ""
            )}
            <Btn
              variant="contained"
              bgColor="#000"
              color="#fff"
              width="120px"
              height="40px"
              whiteSpace="nowrap"
              borderRadius="100px"
              margin="15px"
              click={() => {
                if (closeBtn === "Close") {
                  setPopupOpen(false);
                  navigate("/Samvedna/roster");
                } else {
                  setLeave("no");
                  setPopupOpen(false);
                }
              }}
            >
              {closeBtn}
            </Btn>
          </div>
        </div>
      </div>
    </>
  );
};
