export const EnterpriseSidebarLinks = () => {
  const login_url =
    sessionStorage.getItem("login_url").slice(1) || "enterprise/mental-health-in-the-workplace";
  let sidebarLinks = [
    { id: 51, text: "Dashboard", link: "dashboard" },
    { id: 52, text: "Assessments", link: "enterprise/assessments" },
    { id: 53, text: "Services", link: "enterprise/services" },
    { id: 54, text: "Subscriptions", link: "enterprise/subscriptions" },
    { id: 55, text: "Account", link: "enterprise/account" },
    { id: 56, text: "Reports", link: "enterprise/reports" },
    { id: 57, text: "Help & support", link: "enterprise/help-support" },
    {
      id: 58,
      text: "Logout",
      link: `${login_url}`,
      logout: true,
    },
  ];

  return sidebarLinks;
};
