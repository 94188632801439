import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "../../../../node_modules/moment/moment";
import axios from "../../../config/axiosConfig";
import { formatttedDate } from "../../../config/formattedDate";
import {
  setDate,
  setToggle,
  setYear,
} from "../../../Redux/Slices/Patient/PatienCalender";
import { AssessmentCard2 } from "../../Components/Assessments/AssessmentCard2";
import { Empty } from "../../Components/UI/Empty";
import styles from "./assessment.module.css";

export const InActiveAssessments = () => {
  const [inactiveData, setInactiveData] = useState([]);

  useEffect(() => {
    // axios.post("/enterprise/getAssessments", { status: 0 }).then((res) => {
    //   setInactiveData(res.data.list);
    // });

    axios.post("/enterprise/getAssessmentsByService").then((res) => {
      let FinalList = res.data.list?.filter((item) => {
        return item.expiryService === true;
      });
      setInactiveData(FinalList);
    });
  }, []);

  const dispatch = useDispatch();

  const { selectedDate } = useSelector((i) => i.DateFilterSlice);

  const FinalArr = inactiveData || [];

  const searchResult = FinalArr.filter((event) => {
    return selectedDate.includes("All")
      ? event.date_of_creation.includes(selectedDate.split(" ")?.[1])
      : event.date_of_creation.includes(moment(selectedDate).format("YYYY-MM"));
  });

  useEffect(() => {
    return () => {
      dispatch(setDate(`All ${new Date().getFullYear()}`));
      dispatch(setToggle(false));
      dispatch(setYear(new Date().getFullYear()));
    };
  }, []);

  const navigate = useNavigate();
  return (
    <>
      <div
        className={styles.cards}
        style={
          searchResult?.length
            ? {}
            : { justifyContent: "center", translate: "0 50px" }
        }
      >
        {searchResult?.length ? (
          searchResult?.map((assessment) => {
            const date = formatttedDate(assessment?.date_of_creation);
            const AssessmentImage = () => (
              <img
                src={assessment?.assessment_image}
                alt=""
                width="100px"
                height="100px"
                style={{ objectFit: "contain" }}
              />
            );
            return (
              <div className={styles.container}>
                <AssessmentCard2
                  title={assessment.name}
                  image={<AssessmentImage />}
                  numberofques={`${assessment.no_of_question} Questions`}
                  date={date}
                  detailsButton="Details"
                  height="95%"
                  handleDetailsBtn={() => {
                    navigate("/enterprise/assessments/details", {
                      state: {
                        id: assessment?.id,
                      },
                    });
                  }}
                />
              </div>
            );
          })
        ) : (
          <Empty title="There are no assessment at this time." />
        )}
      </div>
    </>
  );
};
