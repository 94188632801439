import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const initialState = {
  loading: false,
  hasErrors: false,
  data: [],
};

const selfCareSlice = createSlice({
  name: "instruction",
  initialState,
  reducers: {
    getselfCare: (state) => {
      state.loading = true;
    },
    getSelfCareSuccess: (state, { payload }) => {
      state.selfCare = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getselfCareFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const { getselfCare, getselfCareSuccess, getselfCareFailure } =
  selfCareSlice.actions;

// A selector
export const selfCareSelector = (state) => state.selfCare;
// The reducer
export default selfCareSlice.reducer;

export function fetchInstrcution(addCat) {
  const token =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiOTAyNzE2NmU1NjYxODQwNjBlNGE0MGVhOWQxNmFmMmM3Zjg0ZjIxZjVhYzc1NDYwNjNkMjg1YThmMTZlODI0NDg0MTk2NDFjZDk3ZTc3ZTQiLCJpYXQiOjE2NjA4MTYxMTkuODQ5NzA4LCJuYmYiOjE2NjA4MTYxMTkuODQ5NzEsImV4cCI6MTY5MjM1MjExOS44NDQ0MDQsInN1YiI6IjYiLCJzY29wZXMiOlsidXNlciJdfQ.JHVafL8tnkh7XHI5iRLsW4u3xz0AXH41-0DsG7AWSlRBHxRrFMH3eRaDw8pNTrHY6N_NiX0XoRHX6WtXs7XnVZMtyJzfadQNC4zZ5a9XXDyL-ZTXnCWryopcAguJv2X66TfsjxEjd0AiKyb5224z1w2bztaqaWUP02oG5vnOPeRviT2Zj8LWt6W5ZUQxygmcX3UMfO5fQTMssd7yIi5NM4HQthNHTs-0f-8Y_pXDtXRrYUy-eLsxEBkfef3UGYZbCgXOiS4ui5tDJ1LTPidMLa7ZFNailTZsu0t1g9mxvoUu0ES56jta6NX8c7-I6lQQNeZmmbgTPh2sgMOec4iSFro8KJ3ZUQ6TW-bXgo9MoksqJomQICvBxNEYm3MC_TKNB1AvLN2RLUrOLj1DwZj56nae3_LnrrSI6Ifqz9iW8E099WdrtDcWmeUedXeGZ-huCXHxoHHgR4bvfukBKBFhJ4_nSAqIhaop31rPhKqkOYM4bIkmujmQ4uC-G-v-XL3k8MtOcWwHVlSNRdOd9jPGdsZDlKcDG7L8HgOObLlQF2_YntAUub51mqX7L5gXtRDQ0VRnZ83TYDt0VTIdAnmjgFon3p6OxmfH2xzoeph8GRdVzoEXg3NkCz-2AZ0hfXHYQ8Lbs6jW9_lt6tlkAQZihF9G2own3tvhjWhckBdPla8";

  return async (dispatch) => {
    dispatch(getselfCare());
    try {
      const response = await axios.post(
        "http://demo3.iitpl.com/api/admin/add/assessment/instruction",
        addCat,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.json();
      console.log(data, "datadatadatadatadatadatadata");
      dispatch(getselfCareSuccess(data));
    } catch (error) {
      dispatch(getselfCareFailure());
    }
  };
}
