import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { AiOutlineCalendar } from "react-icons/ai";
import "react-datepicker/dist/react-datepicker.css";
import classes from "../../../../assets/css/calender.module.css";
import { useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  setFrom_date,
  setTo_date,
} from "../../../../Redux/Slices/AdminFilters/Search";

export const Calender = ({
  dateGap,
  setDateGap,
  width,
  init,
  disabled,
  date,
}) => {
  // const [startDate, setStartDate] = useState(moment().startOf("month")._d);
  // const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const dispatch = useDispatch();
  const { clear } = useSelector((i) => i.SearchFilters);

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div className={classes.customDate_div} onClick={props.onClick}>
        <div className={classes.calender_input}>
          {props.value || props.placeholder}
        </div>

        <div className={classes.calender_icon}>
          <AiOutlineCalendar color="#00B091" />
        </div>
      </div>
    );
  });

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    const start_date = moment(start).format("YYYY-MM-DD");
    const end_date = moment(end).format("YYYY-MM-DD");
    dispatch(setFrom_date(start_date));
    dispatch(setTo_date(end_date));
  };

  useEffect(() => {
    // console.log(getDayDifference(startDate, endDate));
    if (startDate && endDate) {
      console.log("date changed", startDate, endDate);
      setDateGap?.({
        startDate: moment(startDate).format("DD-MM-YYYY"),
        endDate: moment(endDate).format("DD-MM-YYYY"),
      });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    setStartDate("");
    setEndDate("");
  }, [clear]);

  return (
    <div className={classes.datepicker_div} style={{ width: width }}>
      <DatePicker
        style={{ width: "10px" }}
        dateFormat="dd MMM yyyy"
        selectsRange
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
        popperClassName="date-picker-reports"
        customInput={<CustomInput />}
        maxDate={new Date()}
        disabled={disabled}
      />
    </div>
  );
};
