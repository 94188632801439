import React from "react";
import { AssessmentOverview } from "./AssessmentOverview";
import { ServiceOverview } from "./ServiceOverview";

import {
  setDate,
  setToggle,
  setYear,
} from "../../../Redux/Slices/Patient/PatienCalender";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

export const Dashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setDate(`All ${new Date().getFullYear()}`));
      dispatch(setToggle(false));
      dispatch(setYear(new Date().getFullYear()));
    };
  }, []);

  return (
    <>
      <AssessmentOverview />
      {/* <ServiceOverview /> */}
    </>
  );
};
