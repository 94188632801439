import React from "react";
import { Details } from "../../UI/Details";
import classes from "../../../assets/css/events.module.css";
import { useLocation } from "react-router-dom";
import PaperWrapper from "../../../Component/UI/PaperWrapper/PaperWrapper";
import BasicBreadcrumbs from "../../../Component/UI/BreadCrumb/BreadCrumbs";
import { to12Hour } from "../../../config/formattedDate";
import moment from "../../../../node_modules/moment/moment";

export const EventDetail = ({ state }) => {
  const location = useLocation();
  const data = location.state;
  console.log(data);

  let EventType;
  if (data?.type === 1) {
    EventType = "Online";
  } else {
    EventType = "Offline";
  }

  const links = [
    {
      path: "/Events",
      linkText: "Events",
    },
  ];

  return (
    <>
      <BasicBreadcrumbs link={links} text="Event Details">
        <div className={classes.event_detail}>
          <Details title="Name" description={data.name} />
          <Details
            title="Date"
            description={moment(data.event_date).format("DD/MM/YYYY")}
          />
          <Details title="Type of Event" description={EventType} />
          <Details title="Coordinator" description={data.coordinator} />
          <Details title="Start Time" description={to12Hour(data.start_time)} />
          <Details title="End Time" description={to12Hour(data.end_time)} />
          <Details title="Description" description={data.description} />
        </div>
      </BasicBreadcrumbs>
    </>
  );
};
