import React from "react";
import { Helmet } from "react-helmet";
import { SubHeader } from "../../UI/SubHeader";
import { Active } from "./Active";
import { Expired } from "./Expired";
import { Inactive } from "./Inactive";

export const SubscriptionsPatient = () => {
  const items = [
    {
      label: "Active",
      component: <Active />,
    },
    {
      label: "Inactive",
      component: <Inactive payment={true}/>,
    },
    {
      label: "Expired",
      component: <Expired />,
    },
  ];

  return (
    <>
     <Helmet>
        <meta charSet="utf-8" />
        <title>Patient Subscriptions - Samvedna Map</title>
        <link rel="canonical" href="https://map.samvednacare.com/patient/Subscriptions" />
        <meta name="title" content="Patient Subscriptions - Samvedna Map" />
        <meta
          name="description"
          content="Samvedna MAP lets users renew their subscriptions online and keep a track of their care plan."
        />
        <meta name="robots" content="index,follow" />
      </Helmet>
    <div>
      <SubHeader tab={items} />
      {/* <Details /> */}
    </div>
    </>
    
  );
};
