import { useDispatch, useSelector } from "react-redux";
import { DateNavigation } from "../../../Component/UI/DateNavigation";
import {
  setDate,
  setToggle,
} from "../../../Redux/Slices/Patient/PatienCalender";

export const Select = ({ styles, months, dateObj, year }) => {
  const { toggle } = useSelector((i) => i.DateFilterSlice);

  const dispatch = useDispatch();

  return (
    <div className={styles.select}>
      <DateNavigation
        styles={styles}
        dateObj={dateObj}
        // setYear={setYear}
        // year={year}
      />
      <div className={styles.months}>
        {months.map((month, id) => (
          <p
            className={styles.month}
            onClick={() => {
              dispatch(setToggle(!toggle));
              if (`${month} ${year}`.includes("All")) {
                dispatch(setDate(`All ${year}`));
              } else {
                dispatch(setDate(`${month} ${year}`));
              }
            }}
            key={id}
          >
            {month}
          </p>
        ))}
      </div>
    </div>
  );
};
