import React, { useEffect, useState } from "react";
import styles from "./model.module.css";
import { RiCloseLine } from "react-icons/ri";
import { Btn } from "../../UI/Button";
import Success from "../../../assets/images/success.svg";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";
const Modal = ({ setOpen, cancleAppointments, loading }) => {
  return (
    <>
      <div onClick={() => setOpen(false)} />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.iconimg}>
            <img src={Success} alt="" />
          </div>
          <div className={styles.modalContent}>
            Do you want to cancel the appointment?
          </div>
          {loading && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress size={30} />
              <p style={{ color: "black" }}>
                Please wait ! We are validating the details...
              </p>
            </div>
          )}
          <div className={styles.btnGroup}>
            <Btn
              variant="contained"
              bgColor="#fff"
              color="#000"
              width="120px"
              height="40px"
              whiteSpace="nowrap"
              borderRadius="100px"
              margin="15px"
              click={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Btn>
            <Btn
              variant="contained"
              bgColor="#7A51AE"
              color="#ffffff"
              width="120px"
              height="40px"
              whiteSpace="nowrap"
              borderRadius="100px"
              click={cancleAppointments}
            >
              Yes
            </Btn>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
