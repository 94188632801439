import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import thankyou  from "../../../assets/icon/thankyou.svg"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  height: 200,
  bgcolor: "background.paper",
  outline: "none",
  borderRadius: "8px",
  boxShadow: 12,
  p: 4,
};

export default function SubmitPopup({ popup, setPopup }) {
  const handleClose = () => setPopup(false);
  return (
    <div>
     
      <Modal
        open={popup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ display: "flex", alignItems: "center" ,height:"auto", width: "315px",color:"black"}}>
      
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={thankyou} alt="thank you"/>
            <h3 style={{fontSize: "24px", marginTop: "16px"}}>Thank You !</h3>
            <p
              style={{
                fontSize: "15px",
                fontWeight: "500",
                margin: "3px 12px 14px 0px",
                textAlign: "center",
                lineHeight: "20px",
              }}
            >
              Your request has been submitted. We will connect with you shortly
            </p>
            <Btn
              bgColor="#3592FF"
              color="#fff"
              width="90px"
              variant="contained"
              click={handleClose}
              borderRadius="10px"
            >
              Close
            </Btn>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
