import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import { RiCloseLine } from "react-icons/ri";
import styles from "../../../assets/css/PatientUI/servicepopup.module.css";

const closeBtn = {
  cursor: "pointer",
  fontWeight: "500",
  padding: "5px 8px",
  borderRadius: "8px",
  border: "none",
  fontSize: "18px",
  color: "#2c3e50",
  background: "white",
  transition: "all 0.25s ease",
  boxShadow: "0 5px 20px 0 rgb(0 0 0 / 6%)",
  position: "relative",
  bottom: "0px",
  left: "0px",
};

export default function FeedbackPopup({
  popupAction,
  setPopupAction,
  title,
  icon,
}) {
  const handleClose = () => setPopupAction({ ...popupAction, open: false });
  return (
    <div>
      <Modal open={popupAction.open}>
        <Box className={styles.popUpContainer}>
          <div
            style={{
              width: "100%",
              background: "#7a51ae",
              top: "0px",
              left: "0px",
              position: "absolute",
              borderTopLeftRadius: "19px",
              borderTopRightRadius: "19px",
              height: "40px",
              color: "white",
              fontSize: "18px",
              fontWeight: "600",
              padding: "5px",
              textTransform: "uppercase",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                alignSelf: "center",
                width: "100%",
                textAlign: "center",
              }}
            >
              Delete Feedback
            </div>
            <button style={closeBtn} onClick={handleClose}>
              <RiCloseLine style={{ marginBottom: "-3px" }} />
            </button>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "40px",
            }}
          >
            {icon}
            <p style={{ fontSize: "18px", lineHeight: "28px" }}>
              Are you sure you want to delete the feedback?
            </p>
            <p
              style={{
                fontSize: "18px",
                fontWeight: "500",
                margin: "20px 12px",
                textAlign: "center",
                lineHeight: "26px",
              }}
            >
              {title}
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "-webkit-fill-available",
                margin: "4px 0",
              }}
            >
              <Btn
                bgColor="#7A51AE"
                width="160px"
                height="40px"
                variant="contained"
                className={styles.btn}
                click={popupAction.action}
              >
                Delete
              </Btn>
              <Btn
                bgColor="#FFF"
                color="#000"
                variant="contained"
                width="160px"
                height="40px"
                className={styles.btn}
                click={handleClose}
              >
                Close
              </Btn>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
