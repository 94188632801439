import { createSlice } from "@reduxjs/toolkit";

const ProgressTrendSlice = createSlice({
  name: "DateFilterSlice",
  initialState: {
    chartData: {},
  },
  reducers: {
    setProgressData: (state, { payload }) => {
      state.chartData = payload;
    },
  },
});

export const { setProgressData } = ProgressTrendSlice.actions;

export default ProgressTrendSlice.reducer;
