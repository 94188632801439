import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  patient: {
    country: "101",
    emergency_country: "101",
    countryCode: "+91",
    emergencyCountryCode: "+91",
    caregiverCountryCode: "+91",
  },
};

const addPatientSlice = createSlice({
  name: "add patient",
  initialState,
  reducers: {
    addPatientData: (state, action) => {
      state.patient = {
        ...state.patient,
        ...action.payload,
      };
    },
    clearPatient: () => initialState,
  },
});

export const { addPatientData, clearPatient } = addPatientSlice.actions;

export default addPatientSlice.reducer;
