import React from "react";
import { Input } from "../../Component/UI/CustomInputs";
import { Btn } from "../../Samvenda-Patient/UI/Button";
import { CircularProgress } from "../../../node_modules/@mui/material/index";
import { useState } from "react";
import styles from "./header.module.css";
import { registerEnterprise } from "../../services/enterprise/prelogin";

export const Form = ({ setPopup }) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError({});
    registerEnterprise(data)
      .then((res) => {
        if (res.status === 200) {
          setPopup(true);
          // window.location.href =
          //   "https://www.samvednacare.com/thank-you-for-contact-us";
          setError({});
          setData({});
        } else {
          const errorRes = res.response.data.error;
          setError(errorRes);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  return (
    <form id="contactLink" className={styles.contact} onSubmit={handleSubmit}>
      <div>
        <h2>Request a Call-back</h2>
        <div>
          <Input
            name="company_name"
            placeholder="Company Name"
            type="text"
            error={error?.company_name}
            value={data?.company_name || ""}
            height="48px"
            errorSize="14px"
            onChange={handleChange}
          />
        </div>
        <div>
          <Input
            placeholder="Your Name"
            name="name"
            type="text"
            error={error?.name}
            value={data?.name || ""}
            height="48px"
            errorSize="14px"
            onChange={handleChange}
          />
        </div>
        <div>
          <Input
            placeholder="Work Email"
            name="email"
            type="email"
            value={data?.email || ""}
            error={error?.email}
            height="48px"
            errorSize="14px"
            onChange={handleChange}
          />
        </div>
        <div>
          <Input
            placeholder="Mobile Number"
            name="mobile"
            type="number"
            error={error?.mobile}
            value={data?.mobile || ""}
            height="48px"
            errorSize="14px"
            onChange={handleChange}
          />
        </div>
        <div>
          <Input
            placeholder="Designation"
            name="designation"
            type="text"
            value={data?.designation || ""}
            error={error?.designation}
            height="48px"
            errorSize="14px"
            onChange={handleChange}
          />
        </div>
        <Btn
          variant="contained"
          bgColor="#3592FF"
          fontWeight="400"
          width="100%"
          height="45px"
          fontSize="19px"
          fontFamily="Urbanist"
          margin="6px 0"
          disabled={loading}
        >
          {loading ? (
            <CircularProgress color="inherit" size="25px" />
          ) : (
            "Book a call"
          )}
        </Btn>
      </div>
    </form>
  );
};
