import React, { useEffect, useState } from "react";
import styles from "./model.module.css";
import { RiCloseLine } from "react-icons/ri";

import Servicesuccess from "../../../assets/images/serviceBooksuccess.svg";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import { useNavigate } from "../../../../node_modules/react-router-dom/index";
import { CheckBox } from "../CustomInputs";
import { consentSubmit } from "../../../services/patient/appointments";
import { toast } from "../../../../node_modules/react-hot-toast/dist/index";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";
import { useAuth } from "../../../Auth/AuthProvider";

const ConsentPopup = ({
  setIsOpenConstent,
  setIsOpen,
  isOpenConstent,
  isLoading,
}) => {
  const navigate = useNavigate();
  // const getMydata = JSON.parse(sessionStorage.getItem("mydata"));
  const [error, setError] = useState(false);
  const { loginUser } = useAuth();

  return (
    <>
      <div
        onClick={() =>
          setIsOpenConstent((prev) => {
            return {
              ...prev,
              open: false,
            };
          })
        }
      />
      <div className={styles.centeredModel}>
        <div className={styles.modalConsent}>
          <div className={styles.consentForm}>
            <div className={styles.consentWrapper}>
              <div className={styles.consentHead}>Consent Form</div>
              <div className={styles.consentBody}>
                <div className={styles.custName}>
                  <span>
                    {" "}
                    <strong>Name: </strong>
                    {loginUser?.customer?.name}
                  </span>
                  <span>
                    {" "}
                    <strong>Email: </strong>
                    {loginUser?.customer?.email}
                  </span>
                  <span>
                    {" "}
                    <strong>Phone: </strong>
                    {loginUser?.customer?.mobile}
                  </span>
                </div>
                <div className={styles.consentBodyContent}>
                  Informed consent for video/audio/in-person consultation with
                  Clinical Psychologist for psychotherapy/ psychological
                  assessment and psychological interventions.
                </div>
                <div className={styles.consentBodyTitle}>
                  General Information provided to me about
                </div>
                <div className={styles.consentBodyContent}>
                  psychotherapy/ psychological assessment/ psychological
                  interventions: Psychological assessments help understand the
                  nature of difficulties and challenges being faced.
                  Psychotherapy is a way to help people experiencing significant
                  emotional distress that is coming in the way of them being
                  physically well, enjoying personal relationships or working
                  productively. Psychotherapy begins with the therapist
                  understanding the background of the person seeking help and
                  the concerns that led them to seek help. Following this, the
                  client and psychotherapist come to an agreement about the
                  goals sessions.
                </div>
                <div className={styles.consentBodyContent}>
                  Tele-psychotherapy refers to the provision of psychotherapy
                  services using telecommunication technologies including email,
                  text messaging, video conferencing, online chat, messaging, or
                  internet phone. I understand that tele-psychotherapy services
                  are by appointment only and that these consultations may not
                  suitable for help during an emergency when I may be advised to
                  obtain treatment at the nearest available mental health
                  facility/hospital or emergency service.
                </div>
                <div className={styles.consentBodyTitle}>
                  Confidentiality and Recording
                </div>
                <div className={styles.consentBodyContent}>
                  I understand that this audio/ video consultation is strictly
                  confidential. I agree to use a secure line/connection for
                  these consultations, in a relatively quiet and private space.
                  I understand that my psychotherapist will not audio or video
                  record the session (either on mobile, using an app or online)
                  and will not share the proceedings of this consultation with
                  any other individual or agency unless there is a risk of
                  self-harm or harm to others in which case I provide consent
                  for the therapist to share this information with the concerned
                  organization/my family as agreed upon during my sessions to
                  safeguard my interests. I understand that the proceedings of
                  these consultations are not to be recorded, shared or
                  disseminated by me or my relatives / other contacts to any
                  third person or through social media, or be used for
                  medico-legal purposes. However, despite safety measures taken,
                  there are chances for breach in security of technology. In
                  such instances, both client and psychotherapist will not hold
                  the other responsible for the breach.
                </div>

                <div className={styles.consentBodyContent}>
                  I understand that my consent expressed online would suffice
                  for me to receive the above services.
                </div>
                <div className={styles.consentBodyContent}>
                  <strong>Consent:</strong> I hereby provide my informed consent
                  for video/audio consultations for psychotherapy/ psychological
                  assessment.
                </div>
                {/* <div className={styles.custName}>
                  <span>
                    {" "}
                    <strong>Signature:</strong>-
                  </span>
                  <span>
                    {" "}
                    <strong>Name: </strong>
                  
                  </span>
                </div> */}
              </div>
              <div className={styles.declareForm}>
                <CheckBox
                  boldness={600}
                  value="I provide my consent"
                  check={isOpenConstent.consent}
                  name="consent_declaration"
                  click={(val) => {
                    setIsOpenConstent((prev) => {
                      return {
                        ...prev,
                        consent: val,
                      };
                    });

                    setError("");
                  }}
                />
                <span>
                  <p style={{ color: "red" }}>{error}</p>
                </span>
              </div>
            </div>
          </div>
          {isLoading && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress size={30} />
              <p style={{ color: "black" }}>
                Please wait ! We are validating the details...
              </p>
            </div>
          )}

          <div className={styles.btnGroupConsent}>
            <Btn
              variant="contained"
              bgColor="#ffffff"
              color="#000000"
              width="120px"
              height="40px"
              whiteSpace="nowrap"
              borderRadius="100px"
              margin="15px"
              click={() => {
                setIsOpenConstent(false);
                //  navigate("/patient/Appointments");
              }}
            >
              Close
            </Btn>
            <Btn
              variant="contained"
              bgColor="#7A51AE"
              color="#ffffff"
              width="120px"
              height="40px"
              whiteSpace="nowrap"
              borderRadius="100px"
              margin="15px"
              click={isOpenConstent.action}
              disabled={isOpenConstent.consent === false}
            >
              Submit
            </Btn>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConsentPopup;
