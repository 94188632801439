import React, { useEffect, useState } from "react";

import Tables from "../../../Hooks/Table";
import EnterpricesData from "../../../MockData/Enterprise.json";
import ButtonName from "../../../MockData/ButtonName.json";
import RejectedData from "../../../MockData/EntRejected.json";
import CustomizedTabs from "../../../Component/UI/Tabs/CustomizedTabs";
import { NewLeads } from "./NewLeads";
import { Rejected } from "./Rejected";
import { EnterpriseList } from "./EnterpriseList";
import Text_Seach_Filter_Export from "../../../Hooks/Text_Seach_Filter_Export";
import AllTabs from "../../../Component/UI/Tabs/AllTabs";
import { ADMIN_ROUTES } from "../../../Routes/routesConstants";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { findFunc } from "../../../config/methods";
import { usePermissions } from "../../../Hooks/usePermissions";

export default function Enterprise() {
  const btnApi = ButtonName.Enterprise;
  let defaultTab = Object.keys(btnApi[0]);
  const [tabID, selectedTabID] = useState(defaultTab[0]);
  const [enterpriseData, setEnterpriseData] = useState();
  const [rejectedData, setRejectedData] = useState();
  const [newLeadsData, setNewLeadsData] = useState();
  const [exportData, setExportData] = useState([]);
  const enterprisePerms = usePermissions("name", "enterprises");

  btnApi[0].Leads = newLeadsData
    ? "New Leads (" + newLeadsData.length + ")"
    : btnApi[0].Leads;
  const ComponentName = [
    <EnterpriseList
      setEnterpriseData={setEnterpriseData}
      setExportData={setExportData}
      permissions={enterprisePerms}
    />,
    <Rejected
      setRejectedData={setRejectedData}
      setExportData={setExportData}
    />,
    <NewLeads
      setNewLeadsData={setNewLeadsData}
      setExportData={setExportData}
      permissions={enterprisePerms}
    />,
  ];

  const obj = {
    Upcoming: enterpriseData,
    onGoing: rejectedData,
    Cancelled: newLeadsData,
  };

  useEffect(() => {
    return () => toast.remove();
  }, []);

  return (
    <Text_Seach_Filter_Export
      text={"Enterprises"}
      ApiData={exportData.filter((obj) => Object.keys(obj).length > 0)}
      route={ADMIN_ROUTES.ENTERPRISE_GENERAL_PROFILE_ADD}
      searchText="Search by Org"
      permissions={enterprisePerms?.listControl}
    >
      <AllTabs
        ComponentName={ComponentName}
        btnApi={btnApi}
        selectedTabID={selectedTabID}
      />
    </Text_Seach_Filter_Export>
  );
}
