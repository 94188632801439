import React, { useRef } from "react";
import { Button } from "../../../../node_modules/@mui/material/index";
import { useReactToPrint } from "react-to-print";
import { ProgressTrends } from "./ProgressTrends";
import { Print } from "./PrintIcon";
import { useAuth } from "../../../Auth/AuthProvider";
import { PatientHeader } from "../../../Component/Header/PatientHeader";
import { useLocation } from "react-router-dom";
import Layout from "../../../Component/Layout/Layoutnew";
import styles from "../../../assets/css/PatientDashboard/progresstrend.module.css";

export default function PrintComponent() {
  const ctx = useAuth();
  const role = ctx?.loginUser?.role;
  const location = useLocation();
  const currentPath = location.pathname;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <div id={styles.printButton}>
      <div style={{textAlign: 'right'}}>
      <Button
          variant="contained"
          style={{
            backgroundColor: "#7A51AE",
            borderRadius: 50,
            marginRight: 25,
            position: 'absolute',
            top: '65px',
            right: 0
          
          }}
          onClick={handlePrint}
        >
          <Print /> <span style={{ marginLeft: "5px" }}> Print Report</span>
        </Button>
      </div>
       

        <ProgressTrends ref={componentRef} />
      </div>
    </>
  );
}
