import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import PROGRESS from "../../../../Samvenda-Admin/contant/ProgressConstant";
import styles from "../../../../assets/css/PatientDashboard/progresstrend.module.css";

const mockData = {
  mood: [
    {
      id: 1,
      month: "Jan",
      emoji: "😄",
      value: "2",
    },
    {
      id: 2,
      month: "Feb",
      emoji: "😁",
      value: "3",
    },
    {
      id: 3,
      month: "Mar",
      emoji: "🙂",
      value: "5",
    },
    {
      id: 4,
      month: "Apr",
      emoji: "😞",
      value: "4",
    },
    {
      id: 5,
      month: "May",
      emoji: "😡",
      value: "2",
    },
    {
      id: 6,
      month: "Jun",
      emoji: "😭",
      value: "1",
    },
  ],
};

const MoodAreaChart = ({ data }) => {
  const [moodEmoji, setMoodEmoji] = useState([]);
  const [month, setmonth] = useState([]);
  const [value, setValue] = useState([]);
  const [dataValue, setDataValue] = useState([]);
  const [common, setCommon] = useState([]);

  // useEffect(() => {
  //   getData();
  // }, []);

  const allmodes = {
    "Very Good Mood": "😄",
    "Regular Mood": "🙂",
    "Feeling Low": "😞",
    "Feel Like Crying": "😭",
    "Feeling Angry": "😡",
  };

  // const getData = async () => {
  //   try {
  //     setMoodEmoji(mockData.mood?.map((item) => item.emoji));
  //     setmonth(mockData.mood?.map((item) => item.month));
  //     setValue(mockData.mood?.map((item) => item.value));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      const dataset = Object.keys(data.moodavg).map((key) => ({
        title: key,
        value: data.moodavg[key] * 100,
      }));
      const moodCount = Object.keys(data?.moodcount).map((key) => {
        return {
          title: key,
          value: data.moodcount[key],
        };
      });
      const commonMode = moodCount.sort((a, b) => b.value - a.value);

      setCommon(commonMode);
      setDataValue(dataset);
    }
  }, [data]);

  const series = [
    //data on the y-axis
    {
      name: "Mood",
      data: dataValue ? dataValue.map((item) => item.value) : [],
    },
  ];
  const options = {
    //data on the x-axis
    chart: {
      id: "mood-chart",
      toolbar: {
        show: false,
      },
    },
    colors: ["#7A51AE"],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: dataValue ? dataValue.map((item) => item.title) : [],
      labels: {
        show: true,
        formatter: function (value) {
          return allmodes[value];
        },
        style: {
          fontSize: "18px",
        },
      },
      mood: allmodes,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value + "%";
        },
      },
      tickAmount: 5,
      min: 0,
      max: 100,
      axisBorder: {
        show: true,
        color: "#e2e2e2",
      },
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text: "Mood Tracker",
      margin: 40,
      style: {
        color: "black",
        fontSize: "25px",
        fontWeight: 700,
      },
    },
    tooltip: {
      enabled: false,
    },
    noData: {
      text: "There is no data available",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
    axisBorder: {
      show: true,
      color: "#F0F0F0",
      offsetX: 0,
      offsetY: 0,
    },
  };

  return (
    <>
      <Chart type="bar" height="380px" series={series} options={options} />
      {/* <div className={styles.bottomheading}>
        <span className={styles.Moodtitle}>{PROGRESS.MOODHEADING}</span>
        {common.slice(0, 3).map((item) => {
          return (
            <span className={styles.MoodIcons}>
              {allmodes[item.title]}
              <b>{Math.floor(item.value)}</b>
            </span>
          );
        })}
      </div> */}
    </>
  );
};

export default MoodAreaChart;
