import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "./AuthContext";

const getData = () => JSON.parse(sessionStorage.getItem("mydata"));

export const AuthProvider = ({ children }) => {
  const [loginUser, setLoginUser] = useState(getData());
  const navigate = useNavigate();

  const auth = (Auth) => {
    setLoginUser(Auth);
    const data = JSON.stringify(Auth);
    sessionStorage.setItem("mydata", data);
  };

  const logout = () => {
    sessionStorage.clear();
    setLoginUser({});
    navigate("/");
  };

  const contextValue = {
    loginUser,
    auth,
    logout,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
