import { SubHeader } from "../../UI/SubHeader";
import { All } from "./All";
import { Upcoming } from "./Upcoming";
import { Completed } from "./Completed";
import { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet";

export const Events = () => {
  const items = [
    {
      label: "All",
      component: <All />,
    },
    {
      label: "Upcoming",
      component: <Upcoming />,
    },
    {
      label: "Completed",
      component: <Completed />,
    },
  ];
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Events - Samvedna Map</title>
        <link rel="canonical" href="https://map.samvednacare.com/patient/events" />
        <meta name="title" content="Events - Samvedna Map" />
        <meta
          name="description"
          content="Samvedna Map regularly hosts events for customers to spread awareness about mental health, dementia care and healthcare. Attend our upcoming events to learn about mental health issues of interest to you."
        />
        <meta name="robots" content="index,follow" />
      </Helmet>
    <div style={{ paddingBottom: "10px" }}>
      <Toaster />
      <SubHeader tab={items} />
    </div>
    </>
    
  );
};
