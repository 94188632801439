import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const { GET_ENTERPRISE_ALL_ASSESSMENT, DOWNLOAD_ENTERPRISE_REPORT } = API;

export const getEnterpriseAllAssessments = async () => {
  return await axios
    .get(GET_ENTERPRISE_ALL_ASSESSMENT)
    .then((res) => res)
    .catch((err) => err);
};

export const downloadPdfReport = async (data) => {
  return await axios
    .get(
      DOWNLOAD_ENTERPRISE_REPORT +
        `?assessment_id=${data.assessment_id}&month=${data.month}&year=${data.year}&service_id=${data.service_id}`
    )
    .then((res) => res)
    .catch((err) => err);
};
