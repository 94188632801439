import { PersonalDetails } from "./PersonalDetails";
import { EmergencyContact } from "./EmergencyContact";
import BasicTabs from "../../../UI/Tab/Tabs";
import { PrimaryCareGiver } from "./PrimaryCareGiver";
import { Address } from "./Address";
import { useLocation } from "react-router-dom";
import {
  addPatient,
  getPatientDetails,
} from "../../../../services/admin/patient";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  addPatientData,
  clearPatient,
} from "../../../../Redux/Slices/addPatient";
import { editPatient } from "../../../../services/admin/patient";
import { useNavigate } from "react-router-dom";
import styles from "../../../../assets/css/AdminPatients/profile.module.css";
import { Btn } from "../../../../Samvenda-Patient/UI/Button";
import BasicBreadcrumbs from "../../../../Component/UI/BreadCrumb/BreadCrumbs";
import CountryCodes from "../../../../assets/common/countryCode.json";

const PatientProfile = () => {
  const [count, setCount] = useState(0);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const id = state?.props;
  const [response, setResponse] = useState({});
  const navigate = useNavigate();
  const { permissions } = state;
  const { patient } = useSelector((i) => i.addPatient);
  console.log(permissions, "permissions---");
  // permission code
  const fullpermissions = +permissions?.access?.["Full Control"];
  const writePermissions = +permissions?.access?.Write;
  // permissions end

  useEffect(() => {
    if (id) {
      getPatientDetails(id).then((i) => {
        debugger;
        const splitMono = i?.mobile?.split("-");
        const mobileNumber = splitMono ? ([1] ? splitMono[1] : i?.mobile) : "";
        const countryCode = splitMono[0] ? splitMono[0] : "+91";

        const splitMonoEmer = i?.emergency_mobile?.split("-");
        const mobileNumberEmr = splitMonoEmer
          ? splitMonoEmer[1]
            ? splitMonoEmer[1]
            : i?.emergency_mobile
          : "";
        const countryCodeEmr = splitMonoEmer[0] ? splitMonoEmer[0] : "+91";

        const splitcaregiver_mobile = i?.caregiver_mobile?.split("-");
        const caregiver_mobile_split = splitcaregiver_mobile
          ? splitcaregiver_mobile[1]
            ? splitcaregiver_mobile[1]
            : i?.caregiver_mobile
          : "";
        const countryCodeCaregiver = splitcaregiver_mobile[0]
          ? splitcaregiver_mobile[0]
          : "+91";

        const data = {
          ...i,
          avtar: "",
          mobile: mobileNumber ? mobileNumber : i?.mobile,
          countryCode: countryCode?.length < 5 ? countryCode : "+91",

          emergency_mobile: mobileNumberEmr
            ? mobileNumberEmr
            : i?.emergency_mobile,
          emergencyCountryCode:
            countryCodeEmr?.length < 5 ? countryCodeEmr : "+91",

          caregiver_mobile: caregiver_mobile_split
            ? caregiver_mobile_split
            : i?.caregiver_mobile,
          caregiverCountryCode:
            countryCodeCaregiver !== "null"
              ? countryCodeCaregiver?.length < 5
                ? countryCodeCaregiver
                : "+91"
              : "+91",
        };

        dispatch(addPatientData(data));
      });
    }
  }, []);

  useEffect(() => {
    return () => dispatch(clearPatient());
  }, []);

  const validate = (res) => {
    if (res?.data) setResponse(res.data);
    if (res?.data?.status) navigate("/patients");
  };

  const handleClick = () => {
    const payload = {
      ...patient,
      mobile: `${patient?.countryCode}-${patient?.mobile}`,
      emergency_mobile: `${patient?.emergencyCountryCode}-${patient?.emergency_mobile}`,
    };
    const formData = new FormData();
    for (let key in payload) formData.append(key, payload[key]);

    if (count < tabData.length - 1) {
      setCount(count + 1);
    } else {
      if (id === undefined) {
        addPatient(formData).then((i) => validate(i));
      } else {
        formData.append("customer_id", id);
        editPatient(formData).then((i) => validate(i));
      }
    }
  };

  const patientErr = [
    "name",
    "dob",
    "email",
    "mobile",
    "marital_status",
    "year_of_education",
    "lives_with",
  ];

  const addressErr = [
    "address_line1",
    "state",
    "zipcode",
    "address_line2",
    "city",
  ];

  const emergencyErr = [
    "emergency_name",
    "emergency_relationship",
    "emergency_email",
    "emergency_mobile",
    "emergency_address_line1",
    "emergency_address_line2",
    "emergency_state",
    "emergency_city",
    "emergency_zipcode",
  ];

  const caregiverErr = [
    "caregiver_name",
    "has_primary",
    "caregiver_relationship",
    "caregiver_mobile",
  ];

  const err = response?.error || {};
  const errArr = Object.keys(err);

  const checkErr = (tabErr) => {
    const check = tabErr.map((i) => errArr.includes(i));
    return check.some((i) => i === true);
  };

  const patientCheck = checkErr(patientErr);
  const emergencyCheck = checkErr(emergencyErr);
  const caregiverCheck = checkErr(caregiverErr);
  const addressCheck = checkErr(addressErr);

  const tabData = [
    {
      label: "Personal Details",
      component: (
        <PersonalDetails
          writePermissions={writePermissions}
          data={patient}
          err={err}
          CountryCodes={CountryCodes}
        />
      ),
      error: patientCheck,
    },
    {
      label: "Emergency Contact",
      component: (
        <EmergencyContact
          writePermissions={writePermissions}
          data={patient}
          err={err}
          CountryCodes={CountryCodes}
        />
      ),
      error: emergencyCheck,
    },
    {
      label: "Primary Care Giver",
      component: (
        <PrimaryCareGiver
          writePermissions={writePermissions}
          data={patient}
          err={err}
          CountryCodes={CountryCodes}
        />
      ),
      error: caregiverCheck,
    },
    {
      label: "Address",
      component: (
        <Address writePermissions={writePermissions} data={patient} err={err} />
      ),
      error: addressCheck,
    },
  ];

  let btnText = tabData.length - 1 === count ? "Save" : "Next";
  const link = [
    { path: "/Patients", linkText: "Patients" },
    // {
    //   path: '/Patients/patientProfile',
    //   linkText: 'General',
    //   // catergoryId: cid,
    // },
  ];

  return (
    <BasicBreadcrumbs link={link} text="Patient Profile">
      <div className={styles.patientProfile}>
        <div className={styles.form}>
          <BasicTabs tabcontent={tabData} value={count} setValue={setCount} />
        </div>
        <div className={styles.buttons}>
          <Btn
            variant="contained"
            bgColor="#2A2A2A"
            borderRadius="100px"
            width="100px"
            click={() => navigate("/patients")}
          >
            Cancel
          </Btn>
          <Btn
            variant="contained"
            bgColor="#00B091"
            borderRadius="100px"
            width="85px"
            click={handleClick}
          >
            {btnText}
          </Btn>
        </div>
      </div>
    </BasicBreadcrumbs>
  );
};

export default PatientProfile;
