import React from 'react'
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import {MdVisibility,MdVisibilityOff} from 'react-icons/md'
import BasicBreadcrumbs from '../../../Component/UI/BreadCrumb/BreadCrumbs'
import PaperWrapper from '../../../Component/UI/PaperWrapper/PaperWrapper';

const Password = () => {
  const textname = ["Current Password", "New Password","Confirm Password"]
  const link = ["Samvedna", "Settings", "Password"]
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <BasicBreadcrumbs>
      <PaperWrapper>
        <Typography variant="h5" component="div" gutterBottom>
          Update Password
        </Typography>
        {textname.map((res)=>{
        return(
          <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
            )})}
      </PaperWrapper>
    </BasicBreadcrumbs>
  )
}

export default Password