import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const { UPDATE_PASSWORD } = API;

export const password = async (data) => {
  return await axios
    .post(UPDATE_PASSWORD, data)
    .then((res) => res)
    .catch((err) => console.log(err));
};

