import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import React from "react";

export const Editor = ({ data, change }) => {
  ClassicEditor.defaultConfig = {
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "heading",
        "|",
        "bold",
        "italic",
        "blockQuote",
        "link",
        "|",
        "bulletedList",
        "numberedList",
        // "uploadImage",
      ],
    },
  };
  CKEditor.editorConfig = function (config) {
    // Define changes to default configuration here. For example:
    // config.language = 'fr';
    // config.uiColor = '#AADC6E';
    config.height = "800px";
    // config.enterMode = CKEditor.ENTER_BR;
  };

  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        data={data}
        // onReady={(editor) => {
        //   // You can store the "editor" and use when it is needed.
        //   console.log("Editor is ready to use!", editor);
        // }}
        onChange={(event, editor) => {
          const data = editor.getData();
          change(data);
          //   console.log({ event, editor, data });
        }}
      />
    </div>
  );
};
