import React, { useState, useEffect } from "react";
import BasicBreadcrumbs from "../../../Component/UI/BreadCrumb/BreadCrumbs";
import { ADMIN_ROUTES } from "../../../Routes/routesConstants";
import axios from "../../../config/axiosConfig";
import { Link } from "react-router-dom";
import { CustomTable } from "../../../Component/UI/Table";
import ServicesData from "../../../MockData/Services.json";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetSearchFilters } from "../../../Redux/Slices/AdminFilters/Search";
import { enterpriseServiceListAdmin } from "../../../services/admin/enterprise";
import { usePermissions } from "../../../Hooks/usePermissions";

const EnterpriseServices = () => {
  const [serviceList, setServiceList] = useState([]);
  const { state } = useLocation();
  // const nlcolumns = Object.keys(AssessmentsData[0]);
  const { from_date, to_date } = useSelector((state) => state.SearchFilters);
  const dispatch = useDispatch();
  const permissions = usePermissions("name", "enterprises");

  const tHead = [
    "Service_Name",
    "Amount",
    "Type_Of_Service",
    "No. Of Sessions/ Assessments",
  ];

  useEffect(() => {
    if (to_date !== "Invalid date") {
      enterpriseServiceListAdmin({
        enterprise_id: state?.data?.id,
        from_date,
        to_date,
      })
        .then((res) => setServiceList(res.data.list))
        .catch((err) => console.log(err));
    }
  }, [from_date, to_date]);

  useEffect(() => {
    return () => {
      dispatch(resetSearchFilters());
    };
  }, []);

  const links = [
    {
      path: ADMIN_ROUTES.ENTERPRISES,
      linkText: "Enterprise",
    },
  ];

  if (state?.data) {
    links.push({
      path: ADMIN_ROUTES.ENTERPRISES_VIEWBOX,
      linkText: state?.data?.company_name,
      data: { ...state?.data, permissions },
    });
  }

  const getType = (type) => {
    switch (type) {
      case "0":
        return "Offline";
      case "1":
        return "Online";
      case "2":
        return "Hybrid";
      default:
        return "N/A";
    }
  };

  const tBodyData = serviceList?.map(
    ({ id, name, amount, type_of_service, number_assessment }) => {
      const service_name = (
        <div
          style={{ display: "inline-flex", alignItems: "center", gap: "5px" }}
        >
          {/* <Link
            to="/Services/ServiceDetail"
            style={{ color: "#000", textDecoration: "none", display: "flex" }}
            state={{ props: id }}
          > */}
          {name}
          {/* </Link> */}
        </div>
      );

      const typeservice = getType(type_of_service);
      const AmountWithR = amount ? `₹${amount}` : `₹${0}`;

      return {
        service_name,
        AmountWithR,
        typeservice,
        number_assessment,
      };
    }
  );

  return (
    <BasicBreadcrumbs link={links} text="Services" isEnableCalender={true}>
      <CustomTable
        tHead={tHead}
        tBody={tBodyData}
        message={"No Services is available for this enterprise."}
      />
    </BasicBreadcrumbs>
  );
};
export default EnterpriseServices;
