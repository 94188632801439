import React from "react";
import Chart from "../Charts/Charts";
import { SkeletonLoaderNew } from "../../../../Component/Common/SkeletonLoader";

export const SleepQuality = ({
  styles,
  styles2,
  sleepHourData,
  isLoadingSleepQuality,
}) => {
  return (
    <div className={styles.cardChart}>
      <div className={styles.chartHead}>Sleep Quality</div>
      <div className={styles2.mainChartWrapper}>
        {!isLoadingSleepQuality ? (
          <>
            <Chart
              type={"bar"}
              sleepHourDataCat={["Poor", "Good", "Very Good"]}
              labels={["Poor", "Good", "Very Good"]}
              colors={["#EE6D25", "#AAC514", "#66CC39"]}
              sleepHourData={sleepHourData}
            />
            <div className={styles2.bottomLabel}>
              <div className={styles2.bottomlabelText}>
                <span className={styles2.LevelFour}></span>
                <div className={styles2.bottomText}>
                  <p className={styles2.levelSec}>Poor</p>
                </div>
              </div>
              <div className={styles2.bottomlabelText}>
                <span className={styles2.LevelTwo}></span>
                <div className={styles2.bottomText}>
                  <p className={styles2.levelSec}>Good</p>
                </div>
              </div>
              <div className={styles2.bottomlabelText}>
                <span className={styles2.LevelThree}></span>
                <div className={styles2.bottomText}>
                  <p className={styles2.levelSec}>Very Good</p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <SkeletonLoaderNew />
        )}
      </div>
    </div>
  );
};
