import { useState } from "react";
import { Accordion } from "./Accordion";
import { AccordionSummary } from "./AccordionSummary";
import { AccordionDetails } from "./AccordionDetails";
import Typography from "@mui/material/Typography";

export const BetterAccordion = ({
  heading,
  headingStyle,
  i,
  children,
  handleChange,
  expanded,
}) => {
  return (
    <>
      <Accordion
        expanded={expanded === `panel${i}`}
        onChange={handleChange(`panel${i}`)}
        //  defaultExpanded={true}
      >
        <AccordionSummary
          aria-controls={`panel${i}d-content`}
          id={`panel${i}d-header`}
        >
          <Typography style={headingStyle}>{heading}</Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </>
  );
};
