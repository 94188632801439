import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useState } from "react";
import { useEffect } from "react";
import { questionSerialization } from "../../services/admin/assessmentQuestions";
import { useDispatch, useSelector } from "react-redux";
import { getAssessmentQues } from "../../Redux/Slices/assesmentQuestions";
import axios from "../../config/axiosConfig";
import { setChangeSequence } from "../../Redux/Slices/AdminAssessment/changeSequence";

export const DragDropTable = (props) => {
  const [dragDropList, setDragDropList] = useState([]);
  const { quest } = useSelector((i) => i.assesmentQuestions);
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state.changeSequence);

  const FinalTableData = props?.tBody?.filter(
    (value) => Object.keys(value).length !== 0
  );

  useEffect(() => {
    setDragDropList(FinalTableData);
  }, [props]);

  function capitalizeFirstLetter(string) {
    string = string.split("_").join(" ");
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // const handleDragEnd = (e) => {
  //   if (!e.destination) return;
  //   const arr = [...dragDropList];
  //   let [removedItem] = arr.splice(e.source.index, 1);
  //   arr.splice(e.destination.index, 0, removedItem);
  //   setDragDropList(arr);
  // };

  const [data, setData] = useState(props.tBody);

  useEffect(() => {
    setData(props.tBody);
  }, [props.tBody]);

  const handleDragEnd = (result, provided) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const selectedRow = quest[result.source.index];
    const destinationRow = quest[result.destination.index];
    const postData = {
      assessment_for: selectedRow.assesment_for,
      category_id: selectedRow.category_id,
      question_id: selectedRow.id,
      sequence_question_id: destinationRow.id,
    };

    questionSerialization(postData).then((res) => {
      if (res?.data?.status) {
        dispatch(setChangeSequence());
      }
    });
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">&nbsp;</TableCell>
              {props.tHead?.map((head, index) => (
                <TableCell key={index} sx={{ fontWeight: 400, fontSize: 15 }}>
                  {capitalizeFirstLetter(head)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable" direction="vertical">
              {(droppableProvided) => (
                <TableBody
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                >
                  {data.map((rows, index) => (
                    <Draggable
                      key={rows.serial_number}
                      draggableId={rows.serial_number}
                      index={index}
                    >
                      {(draggableProvided, snapshot) => {
                        const a = Object.values(rows);
                        return (
                          <TableRow
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            style={{
                              ...draggableProvided.draggableProps.style,
                              background: snapshot.isDragging
                                ? "rgba(245,245,245, 0.75)"
                                : "none",
                            }}
                          >
                            {/* note: `snapshot.isDragging` is useful to style or modify behaviour of dragged cells */}
                            <TableCell align="left">
                              <div {...draggableProvided.dragHandleProps}>
                                :::
                              </div>
                            </TableCell>
                            {a?.map((d, i) => (
                              <TableCell
                                key={i}
                                sx={{
                                  fontSize: 14,
                                  paddingTop: 1,
                                  paddingBottom: 1,
                                }}
                              >
                                {d}
                              </TableCell>
                            ))}
                          </TableRow>
                        );
                      }}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
    </>
  );
};
