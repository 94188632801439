import React from "react";
import { TabViewEnterprise } from "../../Components/UI/TabViewEnterprise/TabViewEnterprise";
import { ActiveSubscriptions } from "./ActiveSubscriptions";
import { ExpiredSubscriptions } from "./ExpiredSubscriptions";
import { InactiveSubscriptions } from "./InactiveSubscriptions";

export const Subscriptions = () => {
  return (
    <>
      <TabViewEnterprise
        values={[
          { label: "Active", component: <ActiveSubscriptions /> },
          { label: "Inactive", component: <InactiveSubscriptions /> },
          { label: "Expired", component: <ExpiredSubscriptions /> },
        ]}
      />
    </>
  );
};
