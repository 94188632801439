import React, { useEffect } from "react";
import { CustomTable } from "../../../Component/UI/Table";
import SwitchButton from "../../../Component/UI/SwitchButton/Switch";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getClients } from "../../../Redux/Slices/getClients";
import { clientGet, clientStatus } from "../../../services/admin/client";
import { formatttedDate } from "../../../config/formattedDate";
import { Edit } from "../../../assets/icons/Edit";
import { Avatar } from "../../../Component/UI/Avatar";
import {
  setFilter,
  setFrom_date,
  setSearch_key,
  setTo_date,
  setClear,
} from "../../../Redux/Slices/AdminFilters/Search";
import { useState } from "react";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";

const Clients = ({ permissions }) => {
  const { data } = useSelector((i) => i.getClients);
  const dispatch = useDispatch();
  const [isLoading, setIsloading] = useState(false);
  const fullpermissions = permissions?.listControl?.access?.["Full Control"];
  const writePermissions = permissions?.listControl?.access?.Write;
  const tHead = [
    "Client Name",
    "Email",
    "Mobile",
    "Date",
    ...(fullpermissions ? ["Status"] : []),
    ...(writePermissions ? ["Action"] : []),
  ];

  const { filter, search_key, from_date, to_date } = useSelector(
    (state) => state.SearchFilters
  );

  useEffect(() => {
    setIsloading(true);
    clientGet(search_key, from_date, to_date).then(
      (i) => (setIsloading(false), dispatch(getClients(i)))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    return () => {
      // dispatch(setSearch_key(""));
      dispatch(setFrom_date(""));
      dispatch(setTo_date(""));
      dispatch(setFilter());
      dispatch(setClear());
    };
  }, []);

  const tBodyData = data?.map(
    ({ name, email, mobile, client_image, created_at, id, status }) => {
      const date = formatttedDate(created_at);
      return {
        name: (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              height: 0,
            }}
          >
            {<Avatar size="28px" profile={client_image} />}
            <span style={{ marginLeft: "8px" }}>{name}</span>
          </span>
        ),
        email,
        mobile,
        date,
        ...(fullpermissions && {
          status: (
            <>
              <SwitchButton
                disabled={!fullpermissions}
                status={status}
                onClick={(value) => clientStatus(id, value)}
              />
            </>
          ),
        }),

        ...(writePermissions && {
          action: (
            <>
              <Link
                to="edit"
                style={{
                  color: "#000",
                  pointerEvents: !writePermissions ? "none" : "",
                }}
                state={{ props: id }}
              >
                <Edit />
              </Link>
            </>
          ),
        }),
      };
    }
  );
  return !isLoading ? (
    <CustomTable tHead={tHead} tBody={tBodyData} tableHeight={380} />
  ) : (
    <div className="loader" style={{ textAlign: "center", margin: "60px" }}>
      <CircularProgress size={28} />
    </div>
  );
};

export default Clients;
