import React from "react";
import { Routes, Route } from "react-router-dom";
import Protected from "./Protected";
import LayoutNew from "../Component/Layout/Layoutnew";
//---------------components-------------------
import FeedbackCards from "../Samvenda-Patient/DashboardPages/Feedback/index";
import { HelpAndSupport } from "../Component/UI/HelpAndSupport/HelpAndSupport";
import PatientTestimonial from "../Samvenda-Patient/DashboardPages/Testimonial/index";

//---------------pages------------------------
import { Faq } from "../Samvenda-Patient/Faq";
import AssessmentPlans from "../Samvenda-Patient/DashboardPages/Assessment_Plans";
import { AssessmentResults } from "../Samvenda-Patient/DashboardPages/Assessments/AssessmentResults";
import ClientSelfcarePlan from "../Samvenda-Patient/ClientSelfcarePlan/ClientSelfcarePlan";
import { Dashboard } from "../Samvenda-Patient/DashboardPages/Dashboard/Dashboard";
import { Reports } from "../Samvenda-Patient/DashboardPages/Reports/Reports";
import { MyAccount } from "../Samvenda-Patient/MyAccount/MyAccount";
import { Events } from "../Samvenda-Patient/DashboardPages/Events/Events";
import { AssessmentsPage } from "../Samvenda-Patient/DashboardPages/Assessments/Assessments";
import { Assessment2 } from "../Samvenda-Patient/assessment/Assessment2";
import { AppointmentsPatient } from "../Samvenda-Patient/DashboardPages/Appointments/Appointments";
import { SubscriptionsPatient } from "../Samvenda-Patient/DashboardPages/Subscriptions/Subscriptions";
import { ReportsSummary } from "../Samvenda-Patient/DashboardPages/Reports/Summary";
import { ServicesPatient } from "../Samvenda-Patient/DashboardPages/Services/Services";
import { ProgressTrends } from "../Samvenda-Patient/DashboardPages/ProgressTrends/ProgressTrends";
import { AddAppointments } from "../Samvenda-Admin/DashboardPages/Appointments/AddAppointments";

import { EditUser } from "../Samvenda-Admin/DashboardPages/Appointments/EditUser";
import { AssessmentNew } from "../Samvenda-Patient/AssessmentNew/index";
import { ADMIN_ROUTES, CLIENT_ROUTES } from "./routesConstants";
import NotExist from "../Component/Common/NotExist";
import { FeedbackPage } from "../Component/UI/FeedbackCards/FeedbackPage";
import { ViewSubscription } from "../Samvenda-Patient/DashboardPages/Subscriptions/ViewSubscriptions";
import { Details } from "../Samvenda-Patient/DashboardPages/Appointments/Details";
import Doctors from "../Samvenda-Patient/DashboardPages/Doctors/Doctors";
import ProgressTrendsIndex from "../Samvenda-Patient/DashboardPages/ProgressTrends/index";
import { KnowYourExport } from "../Samvenda-Patient/DashboardPages/Doctors/Index";
import Consent from "../Samvenda-Patient/MyAccount/Consent";
import ConsentTwo from "../Samvenda-Patient/MyAccount/ConsentTwo";
import { SocialMedia } from "../Samvenda-Patient/DashboardPages/Socials_Media/Index";
import { JoinSupport } from "../Samvenda-Patient/DashboardPages/JoinSupport/JoinSupport";
import { HelpSupport } from "../Samvenda-Patient/DashboardPages/HelpSupport/index";

const ROLES = {
  Client: "client",
  Admin: "Admin!$23",
};

const patient = "patient";

const ClientRoutes = () => {
  return (
    <Routes>
      <Route element={<Protected role={ROLES.Client} />}>
        <Route element={<LayoutNew />}>
          <Route
            path={CLIENT_ROUTES.TESTIMONIALS}
            element={<PatientTestimonial />}
          />
          {/* <Route path="/reports/session" element={<SessionReports />} /> */}
          {/* <Route path="/myaccount/password" element={<PatientPasswords />} /> */}
          <Route path={CLIENT_ROUTES.FAQ} element={<Faq />} />
          <Route path={CLIENT_ROUTES.FEEDBACK} element={<FeedbackCards />} />
          <Route path={CLIENT_ROUTES.CONNECT_US} element={<HelpAndSupport />} />
          <Route
            path={CLIENT_ROUTES.ASSESSMENT_RESULTS}
            element={<AssessmentResults />}
          />
          <Route
            path={CLIENT_ROUTES.ASSESSMENT}
            element={<AssessmentPlans />}
          />

          <Route
            path={CLIENT_ROUTES.CLIENT_SELF_CARE_PLAN}
            element={<ClientSelfcarePlan />}
          />
          <Route
            path={CLIENT_ROUTES.PATIENT_DASHBOARD}
            element={<Dashboard role={ROLES.Client} />}
          />
          {/* <Route path={`${patient}/signin`} element={<PatientLogin />} /> */}
          <Route path={CLIENT_ROUTES.MY_ACCOUNT} element={<MyAccount />} />
          {/* <Route path={CLIENT_ROUTES.RANDOM} element={<Assessment2 />} /> */}
          {/* <Route
        path='/'
        element={
          <Protected role={ROLES.Client}>
            <AssessmentNew />
          </Protected>
        }
      /> */}
          {/* <Route path={`${admin}/signin`} element={<LoginContainer />} /> */}
          <Route
            path={CLIENT_ROUTES.EVENTS}
            element={<Events role={ROLES.Client} />}
          />
          <Route
            path={CLIENT_ROUTES.REPORTS}
            element={<Reports role={ROLES.Client} />}
          />
          <Route
            path={CLIENT_ROUTES.ASSESSMENTS}
            element={<AssessmentsPage />}
          />
          <Route
            path={CLIENT_ROUTES.APPOINTMENTS}
            element={<AppointmentsPatient />}
          />

          <Route
            path={CLIENT_ROUTES.APPOINTMENTS_DETAILS}
            element={<Details />}
          />

          <Route
            path={CLIENT_ROUTES.SUBSCRIPTIONS}
            element={<SubscriptionsPatient />}
          />
          <Route
            path={CLIENT_ROUTES.SUBSCRIPTIONS_DETAILS}
            element={<ViewSubscription />}
          />
          <Route
            path={CLIENT_ROUTES.PATIENT_SOCIAL}
            element={<SocialMedia />}
          />

          <Route
            path={CLIENT_ROUTES.JOINSUPPORTGROUP}
            element={<JoinSupport />}
          />

          <Route
            path={CLIENT_ROUTES.REPORTS_SUMMARY}
            element={<ReportsSummary />}
          />
          <Route
            path={CLIENT_ROUTES.SERVICES}
            element={<ServicesPatient role={ROLES.Client} />}
          />

          <Route
            path={CLIENT_ROUTES.DOCTORS_PANEL}
            element={<KnowYourExport role={ROLES.Client} />}
          />
          <Route
            path={CLIENT_ROUTES.HELP_SUPPORT}
            element={<HelpSupport role={ROLES.Client} />}
          />

          <Route
            path={CLIENT_ROUTES.CONSENT}
            element={<Consent role={ROLES.Client} patient={"consentForm"} />}
          />

          <Route
            path={CLIENT_ROUTES.CONSENT_TWO}
            element={<ConsentTwo role={ROLES.Client} />}
          />

          <Route
            path={CLIENT_ROUTES.PROGRESS_TRENDS}
            element={<ProgressTrendsIndex />}
          />
          {/* <Route path="/patient/assessment" element={<AssessmentPlan />} /> */}
          {/* <Route
        path='/add_appointments'
        element={
          <Protected role={ROLES.Client}>
            <AddAppointments />
          </Protected>
        }
      /> */}
          {/* <Route path={CLIENT_ROUTES.USER_EDIT} element={<EditUser />} /> */}
        </Route>
      </Route>
      <Route path="*" element={<NotExist />} />
    </Routes>
  );
};

export default ClientRoutes;
