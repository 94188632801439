import React from "react";

import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import TableHeadData from "./TableHead";
import TableRows from "./TableRow";

const Tables = ({ theadData, tbodyData }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 1237 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {theadData.map((headData, id) => {
              return <TableHeadData key={id} item={headData} />;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tbodyData.map((rowdata, id) => {
            return (
              <TableRows
                key={rowdata.id}
                columnData={theadData}
                rowData={rowdata}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default Tables;
