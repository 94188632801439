import React from "react";
import { Grid } from "../../../../node_modules/@mui/material/index";
import {
  useLocation,
  useNavigate,
} from "../../../../node_modules/react-router-dom/index";
import { Info } from "../../UI/Info";
import styles from "../SamvednaNew/samvedna.module.css";
import BasicBreadcrumbs from "../../../Component/UI/BreadCrumb/BreadCrumbs";
import { ADMIN_ROUTES } from "../../../Routes/routesConstants";
import { findFunc } from "../../../config/methods";
const EnterpriseCardView = () => {
  const CardsData = [
    {
      id: 1,
      name: "General Profile",
      link: ADMIN_ROUTES.ENTERPRISE_GENERAL_PROFILE_EDIT,
    },
    {
      id: 2,
      name: "Assessments",
      link: ADMIN_ROUTES.ENTERPRISES_ASSESSMENT_LIST,
    },
    {
      id: 3,
      name: "Services",
      link: ADMIN_ROUTES.ENTERPRISE_SERVICE_LIST,
      // link: "disabled",
    },
    {
      id: 4,
      name: "Reports",
      link: ADMIN_ROUTES.ENTERPRISE_REPORT_LIST,
      // link: "disabled",
    },
    {
      id: 5,
      name: "Subscriptions",
      link: ADMIN_ROUTES.ENTERPRISE_SUBSCRIPTION_LIST,
      // link: "disabled",
    },
    {
      id: 6,
      name: "Testimonials",
      link: ADMIN_ROUTES.ENTERPRISES_TESTIMONIALS,
      // link: "disabled",
    },
  ];
  const navigate = useNavigate();
  const { state } = useLocation();
  const enterprisePerms = state?.permissions || state?.data?.permissions;
  console.log(enterprisePerms, "enterprisePerms");

  const CardHandler = (linkText) => {
    linkText !== "disabled" && navigate(linkText, { state });
  };
  const links = [
    {
      path: ADMIN_ROUTES.ENTERPRISES,
      linkText: "Enterprise",
    },
  ];

  return (
    <BasicBreadcrumbs link={links} text={state?.data?.company_name}>
      <div className={styles.cards}>
        {CardsData.map((card) => {
          const findName = findFunc(
            enterprisePerms?.subCategories,
            "listName",
            card?.name
          );
          const findReadPerms = findName?.access?.Read;
          return (
            <>
              {+findReadPerms ? (
                <Grid
                  item
                  xs={3}
                  style={{
                    marginBottom: "10px",
                  }}
                  key={card.id}
                  columns={{ xs: 1, sm: 1, md: 12 }}
                >
                  <Info
                    key={card.id}
                    text={card.name}
                    link={card.link}
                    onClick={() => CardHandler(card.link)}
                  />
                </Grid>
              ) : null}
            </>
          );
        })}
      </div>
    </BasicBreadcrumbs>
  );
};

export default EnterpriseCardView;
