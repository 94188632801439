import React from "react";
import { TabViewEnterprise } from "../../Components/UI/TabViewEnterprise/TabViewEnterprise";
import { ActiveServices } from "./ActiveServices";
import { ExpiredServices } from "./ExpiredServices";
import { InActiveServices } from "./InActiveServices";
import { OtherServices } from "./OtherServices";

export const Services = () => {
  return (
    <>
      <TabViewEnterprise
        values={[
          { label: "Active", component: <ActiveServices /> },
          { label: "Inactive", component: <InActiveServices /> },
          { label: "Expired", component: <ExpiredServices /> },
          { label: "Others", component: <OtherServices /> },
        ]}
      />
    </>
  );
};
