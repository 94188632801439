import * as React from "react";

import ButtonName from "../../../MockData/ButtonName.json";
import Tables from "../../../Hooks/Table";
import cancelledData from "../../../MockData/Cancelled.json";
import CustomizedTabs from '../../../Component/UI/Tabs/CustomizedTabs';


export default function ServiceStatus() {
  const btnApi = ButtonName.Subscriptions;
  const columns = Object.keys(Active[0]);
  const cacolumns = Object.keys(Cancelled[0]);
  const cocolumns = Object.keys(cancelledData[0]);

  //TODO:Active
  const Active = <Tables theadData={columns} tbodyData={Active} />;

  //TODO:Paused
  const Completed = <Tables theadData={cocolumns} tbodyData={Cancelled} />;

  //TODO:Cancelled
  const Cancelled = <Tables theadData={cacolumns} tbodyData={Cancelled} />;

  const ComponentName = [Active,Completed,Cancelled]

  return (
    <CustomizedTabs ComponentName={ComponentName} btnApi={btnApi} text={"Service"}/>
  );
}
