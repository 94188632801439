import React from "react";
import styles from "../../../../assets/css/UI/summaryCard.module.css";
import SummaryData from "./SummaryData";

function SummaryService({ data }) {
  let nf = new Intl.NumberFormat("en-US");
  console.log(data, "++++data");

  const intervalTypes = {
    0: "Daily",
    1: "Weekly",
    2: "Monthly",
    3: "Twice a week",
    4: "Thrice a week",
  };

  const subscriptionDetails = [
    {
      name: "Service Name",
      value: data?.service_name,
    },
    {
      name: "Service Price",
      value: `₹${nf.format(data?.unit_price)}`,
    },

    {
      name: "Tax Applicable",
      value: `${
        data?.tax_applicable.toString() !== "1"
          ? "No"
          : `Yes, ${data?.tax_value} %`
      }`,
    },
    {
      name: "No. of Session",
      value: data?.number_session,
    },
    {
      name: "Interval Type",
      value: intervalTypes[data?.interval_type],
    },
    {
      name: "Mode",
      value: ["Offline", "Online", "Hybrid"][data?.mode],
    },
    {
      name: "Duration (HH:MM)",
      value: data?.duration,
    },
    // {
    //   name: "Minimum Validity (Days)",
    //   value: data?.minimum_validity,
    // },
    {
      name: "Sub Total",
      value: `₹${nf.format(data?.total_price)}`,
    },
  ];
  return (
    <div className={styles.summaryCard}>
      <div
        className={styles.dataService}
        // style={{ gridTemplateColumns: `repeat(${props.rows}, 1fr)` }}
      >
        {subscriptionDetails.map(({ name, value }, id) => {
          return (
            <>
              <div className={styles.dataRow}>
                <p className={styles.name}>{name}</p>
                <p className={styles.value}>{value}</p>
              </div>
            </>
          );
        })}
      </div>
    </div>
    // <SummaryData data = {subscriptionDetails}/>
  );
}

export default SummaryService;
