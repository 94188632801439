import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Btn } from "../../../../Samvenda-Patient/UI/Button";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 242,
  bgcolor: "background.paper",
  outline: "none",
  borderRadius: "20px",
  boxShadow: 8,
  p: 4,
};

const Icon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none">
    <path
      d="M32.0002 5.3335L5.3335 18.6668L32.0002 32.0002L58.6668 18.6668L32.0002 5.3335Z"
      stroke="#00B091"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.3335 45.3335L32.0002 58.6668L58.6668 45.3335"
      stroke="#2A2A2A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.3335 32L32.0002 45.3333L58.6668 32"
      stroke="#2A2A2A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default function DeletePopup({ popupAction, setPopupAction, title }) {
  const handleClose = () => setPopupAction({ ...popupAction, open: false });
  return (
    <div>
      <Modal
        open={popupAction.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {<Icon />}
            <h2 style={{ width: "-webkit-fill-available", marginLeft: "12px" }}>
              {popupAction.name}
            </h2>
            <p
              style={{
                fontSize: "20px",
                fontWeight: "500",
                margin: "20px 0",
                textAlign: "center",
                lineHeight: "20px",
              }}
            >
              {title}
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "-webkit-fill-available",
                margin: "4px 0",
              }}
            >
              <Btn
                bgColor="#2A2A2A"
                color="#fff"
                width="150px"
                height="44px"
                variant="contained"
                click={handleClose}
              >
                No
              </Btn>
              <Btn
                bgColor="#00B091"
                variant="contained"
                width="150px"
                height="44px"
                click={popupAction.action}
              >
                Yes
              </Btn>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
