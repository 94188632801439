import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SimpleHeader } from "../../../../Hooks/SimpleHeader";
import styles from "./rosterManagement.module.css";
import { StaffList } from "./StaffList";
import {
  convertedMonthArr,
  filterWeek,
  getDay,
  getDropDownlist,
  selectedMonthArr,
  weekDays,
} from "./rosterFun";
import { useEffect } from "react";
import { setSelectedWeek } from "../../../../Redux/Slices/AdminRosterManegment/RosterManagement";
import {
  gettimeSlots,
  staffListRoster,
} from "../../../../services/admin/roster";
import { useState } from "react";
import { Toaster } from "../../../../../node_modules/react-hot-toast/dist/index";
import moment from "../../../../../node_modules/moment/moment";
import { usePermissions } from "../../../../Hooks/usePermissions";
import { findFunc } from "../../../../config/methods";
const RosterManagement = () => {
  const links = [
    {
      path: "/Samvedna",
      linkText: "Samvedna",
    },
    // {
    //   path: "/roster",
    //   linkText: "Roster Management",
    //   data: state,
    // },
  ];

  const permissions = usePermissions("name", "samvedna");
  const rosterPermissions = findFunc(
    permissions?.subCategories,
    "listName",
    "Roster Management"
  );

  const { selectedDate, toggle, year, fullDate } = useSelector(
    (i) => i.DateFilterSlice
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [searchStaff, setSearchStaff] = useState("");
  const { leaveUserData, setLeaveUserData } = useState();
  const { selectWeek } = useSelector((i) => i.WeekFilterSlice);
  const [staffListData, setStaffListData] = useState();
  const [staffTimeSlotdata, setStaffTimeSlotdata] = useState();

  const daysInSelectedMonth = new Date(
    fullDate.getFullYear(),
    fullDate.getMonth() + 1,
    0
  ).getDate();
  console.log(staffListData, "staffListData");

  //week Dropdown

  const startDay = getDay(1, fullDate);
  const endDay = getDay(daysInSelectedMonth, fullDate);
  const [selectStaffId, setSelectStaffId] = useState();
  const daysInPrevMonth = new Date(
    fullDate.getFullYear(),
    fullDate.getMonth(),
    0
  ).getDate();
  const daysInNextMonth = new Date(
    fullDate.getFullYear(),
    fullDate.getMonth() + 2,
    0
  ).getDate();

  const totalWeekDays = 6;
  const startDayIndex = weekDays?.findIndex((e) => e === startDay);
  const endDayIndex = totalWeekDays - weekDays?.findIndex((e) => e === endDay);

  const prevMonthDaysArr = selectedMonthArr(daysInPrevMonth).slice(
    -startDayIndex
  );
  const nextMonthDaysArr = selectedMonthArr(daysInNextMonth).slice(
    0,
    endDayIndex
  );

  const currentMonthArr = selectedMonthArr(daysInSelectedMonth);
  const prevMonthArrData = convertedMonthArr(prevMonthDaysArr, true);
  const nextMonthArrData = convertedMonthArr(nextMonthDaysArr, true);
  const currentMonthArrData = convertedMonthArr(currentMonthArr, false);
  const finalMonthArr = [
    ...prevMonthArrData,
    ...currentMonthArrData,
    ...nextMonthArrData,
  ];
  const tHead = filterWeek(finalMonthArr, selectWeek)?.map((i) => i);
  useEffect(() => {
    dispatch(setSelectedWeek("1"));
    return () => {
      dispatch(setSelectedWeek("1"));
    };
  }, [fullDate]);

  useEffect(() => {
    setIsLoading(true);
    staffListRoster(searchStaff)
      .then((res) => {
        setIsLoading(false);
        setStaffListData(res.data.list);
      })
      .catch((err) => console.log(err));
  }, [searchStaff]);

  useEffect(() => {
    setIsLoadingData(true);
    var data = {
      staff_id: selectStaffId,
      month: moment(fullDate).format("MM"),
      year: moment(fullDate).format("YYYY"),
    };
    gettimeSlots(data)
      .then((res) => {
        setIsLoadingData(false);
        setStaffTimeSlotdata(res?.data?.list);
      })
      .catch((err) => console.log(err));
  }, [selectStaffId, fullDate]);

  // const dateSame = moment(bookedDate).isSame(appDate?.start_date);
  const timeSlotsBooked = staffTimeSlotdata?.data?.map(
    ({
      id,
      start_time,
      end_time,
      schedule_date,
      staff_id,
      available_type,
      is_booked,
    }) => {
      return {
        id: id,
        date: moment(schedule_date).format("DD"),
        timeSlot:
          moment(start_time, "hh:mm").format("hh:mm") +
          "-" +
          moment(end_time, "hh:mm").format("hh:mm"),
        timeId: moment(start_time, "hh:mm").format("HH"),
        available_type: available_type,
        is_booked: is_booked,
      };
    }
  );

  return (
    <>
      <Toaster />
      <SimpleHeader
        text="Roster Management"
        btnText={"Add"}
        links={links}
        basic
        noPaddingTop
        select={selectStaffId}
        rosterPermissions={rosterPermissions}
      >
        <div className={styles.staffListMain}>
          <StaffList
            selectDropDown={getDropDownlist(finalMonthArr)}
            tHead={tHead}
            isLoading={isLoading}
            staffListData={staffListData}
            setSelectStaffId={setSelectStaffId}
            selectStaffId={selectStaffId}
            isLoadingData={isLoadingData}
            timeSlotsBooked={timeSlotsBooked}
            setSearchStaff={setSearchStaff}
            searchStaff={searchStaff}
            leaveUserData={leaveUserData}
            staffTimeSlotdata={staffTimeSlotdata}
            rosterPermissions={rosterPermissions}
          />
        </div>

        {/* <AllTabs ComponentName={ComponentName} btnApi={btnApi} /> */}
      </SimpleHeader>
    </>
  );
};

export default RosterManagement;
