import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "../../../../../../node_modules/moment/moment";
import { Check } from "../../../../../assets/icons/Check";
import { Cross } from "../../../../../assets/icons/Cross";
import { CustomTable } from "../../../../../Component/UI/Table";
import { getNewTestimonials } from "../../../../../Redux/Slices/adminTestimonial";
import {
  getPatientTestimonials,
  listTestimonial,
  updateTestimonial,
} from "../../../../../services/admin/testimonial";
import Popup from "../../../../../Component/UI/Popup/Popup";
import Popup2 from "../../../../../Component/UI/Popup/Popup2";
import { Rating } from "./Rating";
import { useNavigate } from "../../../../../../node_modules/react-router-dom/index";
import { Avatar } from "../../../../../Component/UI/Avatar";
import {
  setFilter,
  setFrom_date,
  setSearch_key,
  setTo_date,
} from "../../../../../Redux/Slices/AdminFilters/Search";

export const NewTestimonial = ({
  customerId,
  testimonialsPermissions,
  pFeedback,
}) => {
  const writePermissions = pFeedback
    ? 1
    : +testimonialsPermissions?.access?.Write;
  const { newTestimonials } = useSelector((i) => i.adminTestimonial);
  const [popupAction, setPopupAction] = useState({ open: false });
  const [popupActionRej, setPopupActionRej] = useState({ open: false });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tableHeadings = [
    "Date",
    "Name",
    "Rating",
    "Description",
    ...(writePermissions ? ["Action"] : []),
  ];

  const { search_key, from_date, to_date, filter } = useSelector(
    (i) => i.SearchFilters
  );

  const getNewTestimonialList = () =>
    getPatientTestimonials(1, search_key, from_date, to_date).then((res) => {
      const data = res?.data;
      if (data?.status) dispatch(getNewTestimonials(data?.list));
    });

  useEffect(() => {
    getNewTestimonialList();
  }, [filter]);

  useEffect(() => {
    return () => {
      dispatch(setSearch_key(""));
      dispatch(setTo_date(""));
      dispatch(setFrom_date(""));
      dispatch(setFilter());
    };
  }, []);

  const handleApprove = (id, status) => {
    setPopupAction({
      ...popupAction,
      open: true,
      action: () => {
        updateTestimonial(id, status).then((res) => {
          const data = res?.data;
          if (data?.status) {
            getNewTestimonialList();
            setPopupAction({ ...popupAction, open: false });
          }
        });
      },
    });
  };

  const handleReject = (id, status) => {
    setPopupActionRej({
      ...popupActionRej,
      open: true,
      action: () => {
        updateTestimonial(id, status).then((res) => {
          const data = res?.data;
          if (data?.status) {
            getNewTestimonialList();
            setPopupActionRej({ ...popupActionRej, open: false });
          }
        });
      },
    });
  };

  const tBodyData = newTestimonials.map((item) => {
    const { id, thoughts, customer, created_at, rating } = item;
    const date = moment(created_at).format("DD/MM/YYYY");
    return {
      date,
      name: (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            height: 0,
          }}
        >
          <Avatar
            size="28px"
            profile={customer?.customer_image}
            border="1px solid #ccc"
          />
          <div
            style={{ cursor: "pointer", marginLeft: "8px" }}
            onClick={() => navigate("details", { state: { item, customerId } })}
          >
            {customer?.name}
          </div>
        </span>
      ),
      rating: <Rating ratings={rating} />,
      thoughts: `${thoughts.slice(0, 30)}${thoughts.length > 30 ? "..." : ""}`,
      ...(writePermissions && {
        action: (
          <div style={{ display: "flex" }}>
            <div onClick={() => handleApprove(id, 2)}>
              <Check />
            </div>
            <div onClick={() => handleReject(id, 3)}>
              <Cross />
            </div>
          </div>
        ),
      }),
    };
  });

  return (
    <>
      <Popup
        popupAction={popupAction}
        setPopupAction={setPopupAction}
        heading="Accept Testimonial"
        title="Are you sure you want to approve this testimonial?"
        styles={{ width: 400, height: 230 }}
        btnMargin="0 10px"
      />
      <Popup2
        popupAction={popupActionRej}
        setPopupAction={setPopupActionRej}
        heading="Reject Testimonial"
        title="Are you sure you want to reject this testimonial?"
        styles={{ width: 400, height: 230 }}
        btnMargin="0 10px"
      />
      <CustomTable tHead={tableHeadings} tBody={tBodyData} />
    </>
  );
};
