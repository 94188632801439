import React from "react";
import { Input } from "../../Component/UI/CustomInputs";
import { Btn } from "../../Samvenda-Patient/UI/Button";
import { LogoNew } from "../Components/Images/LogoNew";
import styles from "./landing.module.css";
import pvr from "../Components/Images/Sponsors/pvr.png";
import jkc from "../Components/Images/Sponsors/jkc.png";
import hero from "../Components/Images/Sponsors/hero.png";
import panasonic from "../Components/Images/Sponsors/panasonic.png";
import decathlon from "../Components/Images/Sponsors/decathlon.png";
import { StressCard } from "../Components/UI/StressCard";
import { Mind } from "../Components/Images/Stress/Mind";
import { Peace } from "../Components/Images/Stress/Peace";
import { Heart } from "../Components/Images/Stress/Heart";
import { Pain } from "../Components/Images/Stress/Pain";
import rightArrow from "../Components/Images/Stress/rightArrow.svg";
import leftArrow from "../Components/Images/Stress/leftArrow.svg";
import mentalHealth from "../Components/Images/mentalHealth.png";
import { HealthFactsCard } from "../Components/UI/HealthFactsCard";
import { SamvednaCare } from "./Landing/SamvednaCare";
import { OverBrand } from "./Landing/OurBrands";
import { Assesssment } from "./Landing/Assesssment";
import { Offering } from "./Landing/Offering";
import { Services } from "./Landing/Services";
import { Testimonial } from "./Landing/Testimonial";
import { About } from "./Landing/About";
import { useState } from "react";
import SubmitPopup from "../Components/Popups/SubmitPopup";
import { Header } from "./Header";
import { Helmet } from "react-helmet";
import { ClientLogos } from "./MapLanding/ClientLogo";

export default function LandingPageNew() {
  const [popup, setPopup] = useState(false);

  const domain = window.location.host;
  const canonicalUrl = `https://${domain}/enterprise/mental-health-in-the-workplace`;

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Mental Health in the Workplace | Mental Well being at the Workplace
        </title>
        <meta
          name="title"
          content="Mental Health in the Workplace | Mental Well being at the Workplace"
        />
        <meta
          name="description"
          content="Our custom self-assessments are tailored to organization needs and is an excellent tool to enhance the well-being of your team and support employee mental health in the workplace."
        />
        <meta
          name="keywords"
          content="Mental Health in the Workplace, Employee Mental Health, Improving Mental Health in the Workplace, Mental Well being at the Workplace, Mental Health Solutions"
        />
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <SubmitPopup setPopup={setPopup} popup={popup} />
      <Header setPopup={setPopup} />
      <div className={styles.sponsors}>
        <img src={pvr} alt="pvr" />
        <img src={hero} alt="hero" />
        <img src={jkc} alt="jkc" />
        <img src={panasonic} alt="panasonic" />
        <img src={decathlon} alt="decathlon" />
      </div>
      <div className={styles.therapy}>
        <div>
          <h2 className={styles.therapyHeading}>
            Burnout To Breakdown, More Employees Are Experiencing Organizational
            Stress!
          </h2>
          <p>
            Investing in employee mental health can lead to lower absenteeism,
            reduced turnover rates, and increased job satisfaction, ultimately
            resulting in a more positive work environment.
          </p>
          <p>
            By promoting <strong>mental health in the workplace</strong> and
            providing resources for employees to manage stress and other mental
            health concerns, organization can foster a culture of support,
            well-being and resilience.
          </p>
        </div>
        {/* <div className={styles.cards}>
          <StressCard
            icon={<Mind />}
            description="Increased role-strain during pandemic"
          />
          <StressCard
            icon={<Peace />}
            description="Difficulty maintaining
          work-life balance"
          />
          <StressCard
            icon={<Heart />}
            description="Increase in relationship 
          difficulties"
          />
          <StressCard
            icon={<Pain />}
            description="Increased physical 
          aches and pain"
          />
        </div> */}
        <img
          src={rightArrow}
          width="213px"
          height="307px"
          alt=""
          className={styles.rightArrow}
        />
        <img
          src={leftArrow}
          width="213px"
          height="307px"
          alt=""
          className={styles.leftArrow}
        />
      </div>
      <div className={styles.mentalHealthFacts}>
        <div>
          <h2 className={styles.mentalHealthHeading}>
            Mental Health Facts
            <sup style={{ fontSize: "12px" }}>**</sup>
          </h2>
          <p>
            For every US$ 1 invested on mental wellness, it returns US$ 4 in
            improved health and productivity.
          </p>
          <div className={styles.healthCards}>
            <div>
              <HealthFactsCard
                perct={59}
                description="Work force struggles with symptoms of depression"
              />
              <HealthFactsCard
                perct={55}
                description="Employees reported 
                emotional burn out"
              />
            </div>
            <div>
              <HealthFactsCard
                perct={51}
                description="Employees report
                irritability and outbursts"
              />
              <HealthFactsCard
                perct={50}
                description="Employees report lack of 
                sleep and other physical 
                issues"
              />
            </div>
          </div>
        </div>
        <div>
          <img src={mentalHealth} alt="" />
          <span>
            ** Mental health and well-being in the workplace, Deloitte Report
            2022
          </span>
        </div>
      </div>
      <SamvednaCare />
      <div id="clientLogo">
        <ClientLogos styles={styles} />
      </div>
      <div id="assessmentsLink">
        <Assesssment />
      </div>
      <Offering />
      <div id="servicesLink">
        <Services />
      </div>
      <OverBrand />
      <Testimonial />
      <div id="aboutLink">
        <About />
      </div>
      <footer className={styles.footer}>
        <div className={styles.footerBottom}>
          <p
            style={{
              lineHeight: "22px",
              padding: "0 5px",
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            <b>Disclaimer:</b> Samvedna Care is not a mental health emergency
            service. In case of severe distress or suicidal thoughts, we
            strongly advise you to contact the National Helpline Numbers for
            Mental Health Support at 08046110007.
          </p>
        </div>
        <div> Samvedna Care &copy; {new Date().getFullYear()}</div>
      </footer>
    </div>
  );
}
