import React from 'react'
import styles from './checkBox.module.css';
export const MultiSelectCheckBox = ({
  tHead,
  checked, 
  singleCheckBox, 
  selectAllCheckBox, 
  checkedAll,
  weekDays 
}) => {
  const allDisabled = tHead?.every((k) => k.disabled)
  return (
    <div className={styles.checkBoxes}>
      {
        tHead?.map((item, i) => {
          return (
            <div className={`${styles.container}`}>
            <div className={styles.weekDays}>{weekDays[i]}</div>
            <div className={`${styles.checkBox} ${styles.checkedCheckBox} ${item.disabled === true ? styles.timeDisable: ''}`}>
            <label>
              <input disabled={item.disabled} type="checkbox" 
               onChange={(e) => 
                singleCheckBox(e.target.value)
              }
               value={item.date}
              checked={item.disabled === true ? '' : checked ? checked[item.date] : ''}
               />
              <span>{item.date}</span>
            </label>
          </div>
        </div>
          )
        })
      }

       <div className={styles.container}>
            <div className={styles.weekDays}>{"All"}</div>
            <div className={`${styles.checkBox} ${styles.checkedCheckBox} ${allDisabled === true ? styles.timeDisable: ''}`}>
            <label>
              <input type="checkbox" 
               onChange={(event) => selectAllCheckBox(event.target.checked)}
               checked={allDisabled ? '' : checkedAll}
                />
              <span>{'All'}</span>
            </label>
          </div>
        </div>
       
    </div>
  )
}
