import React from "react";
import { CustomizedAccordions } from "./Components/FaqCard";
export const Faq = ({ styles }) => {
  const faqData = [
    {
      Title: "What is Samvedna MAP?",
      content: {
        desc: [
          "Samvedna MAP stands for – My Self-Assessments & Plan. MAP is a mental wellbeing self-care portal which helps you evaluate your mental well-being and get personalized recommendations for improving your mental health. You can also speak to our counsellors and seek expert consultations for various concerns like stress management, counselling for depression, anxiety, relationship issues, family counselling, dementia care and caregiver stress etc.",
        ],
      },
    },
    {
      Title: "What is special about the Samvedna MAP Self-Assessments? ",
      content: {
        desc: [
          `The Samvedna <a href="https://map.samvednacare.com/self-assessment" target="_blank">MAP self-assessments</a> have been curated by our experts based on scientific literature, research and WHO guidelines. We have designed these to promote early detection of mental health issues and encourage you to seek help in time. The self-assessments will help you become more self-aware and encourage you to start your journey to mental wellbeing. With a just  few clicks, get your care recommendations and speak to our counsellors to understand how to proceed with your mental health journey.`,
        ],
      },
    },
    {
      Title: "What are the self-assessments available on Samvedna MAP ?",
      content: {
        desc: [
          "The Samvedna MAP self-assessments help individuals and their families: ",
        ],
        points: [
          "Evaluate their Mental Wellbeing.",
          "Take Dementia self-assessment for a loved one or themselves.",
          "Evaluate their caregiver burden.",
        ],
      },
    },
    {
      Title: "Who can benefit from Samvedna MAP tool ? ",
      content: {
        desc: [
          "Samvedna MAP self-assessment tool can benefit any individual who may be experiencing mental health concerns or symptoms, as well as those who are interested in promoting their own self-awareness and proactively managing their mental wellbeing.",
          "These can be people from :",
        ],
        points: [
          "Any gender",
          "Above the age of 18+ years",
          "Working Professionals",
          "Older Adults",
          "Senior Citizens",
          "Organizations",
          "Families of people suffering from an illness",
        ],
      },
    },
    {
      Title: "How long does the self-assessment take?   ",
      content: {
        desc: [
          "The time required for the self-assessment can vary, but it typically takes around 5-6 minutes to complete it. We encourage you to sit in a quiet place and answer the questions as they come to your mind.",
        ],
      },
    },
    {
      Title: "How do I get started with Samvedna MAP?  ",
      content: {
        desc: [
          "To get started with Samvedna MAP all you need to do is visit our website https://map.samvednacare.com and check which assessments you would like to take. Once you take the assessment you will be asked to submit your answers. To get your personalized care recommendations in your mail, signup, or sign in to get started. ",
        ],
      },
    },
    {
      Title: "How can I sign up for Samvedna MAP ?  ",
      content: {
        desc: [
          "You can setup your account using your personal email id and phone number or your social account like Google or Facebook.  ",
        ],
      },
    },
    {
      Title: "What happens once I take a self-assessment ?",
      content: {
        desc: [
          "After finishing the self-assessment, you will receive a report with personalized recommendations via email. You may also download the report from your personal dashboard. If you wish to speak with one of our counselors, you may choose a date and time that suits you for a complimentary first consultation. ",
        ],
      },
    },
    {
      Title: "Is the self-assessment confidential? ",
      content: {
        desc: [
          "Yes absolutely! We value your privacy; your results will remain confidential and will only be communicated to you through email. Only when you decide to speak to our experts we will guide you with a care plan. Your information is secure with us and not shared with any third party.  ",
        ],
      },
    },
    {
      Title:
        "My employer has asked me to take a self-assessment, will they come to know my concerns and how I have scored?",
      content: {
        desc: [
          "Samvedna is a leading, professional mental health service provider, we understand and deeply respect the client – counsellor privacy and confidentiality. The results and finding of any Samvedna self-assessments taken by you on behalf of your employer will be strictly confidential and your score and concerns will not be shared with anyone, except the Samvedna counsellor.  ",
        ],
      },
    },
    {
      Title: "Is the Samvedna MAP self-assessment free?  ",
      content: {
        desc: [
          "Yes, there is no fee for taking the self-assessment. We offer customized self-care plan,  a wellness tracker and one-on-one complimentary consultation with a mental health therapist as a part of the self-assessment. ",
        ],
      },
    },
    {
      Title:
        "How can I book my first complimentary consultation from Samvedna MAP ? ",
      content: {
        desc: [
          "Once you have taken anyone of our self-assessments go right ahead and book your complimentary first consultation with our experts. Select a date and time and we will revert to you with your appointment confirmation.  ",
        ],
      },
    },
    {
      Title:
        "If I it take an assessment can I retake it ? Can I take the self-assessment multiple times?  ",
      content: {
        desc: [
          "Yes, you can take the self-assessment after a period of one month to track your progress over time. We recommend a time gap between assessments to allow for any changes in your mental wellbeing. ",
        ],
      },
    },
    {
      Title: "How do I see my self-assessment results? ",
      content: {
        desc: [
          "Once you complete the Samvedna Care self-assessment, you can access and download the report containing personalized recommendations from your MAP dashboard, after signing in. In addition, a report with your personalized recommendations will also be sent to your email. The dashboard provides a comprehensive overview of your mental health and well-being, including your assessment results and progress over time. If you have any questions or concerns regarding your results, you can avail of our complimentary 'Speak to our Counselor' service to get started. ",
        ],
      },
    },
    {
      Title: "What is the self-care plan on the Samvedna Care dashboard?",
      content: {
        desc: [
          "The self-care plan is a personalized plan designed to help you manage and improve your mental health and well-being. The self-care plan consists of tips, daily activities, and a wellness tracker to check on your wellbeing. It is based on your assessment results and includes customized recommendations, resources, and tools to support your mental health and well-being. ",
        ],
      },
    },
    {
      Title: "What does the wellness tracker on Samvedna MAP do?",
      content: {
        desc: [
          "The wellness tracker help you maintain wellbeing on different dimensions of wellness like nutrition, cognition, socialization, physical health and most importantly self-care activities. It will also help you track your daily mood and sleep. You can check your progress and see how the activities are helping you improve yourself.  ",
        ],
      },
    },
    {
      Title: "What can I see on my Samvedna MAP dashboard? ",
      content: {
        desc: [
          "Your self-assessment dashboard is for you to manage your appointments, see your upcoming events and check your latest assessment results. With a few clicks you can also book a service or consultation to get started with your journey.  ",
        ],
      },
    },
    {
      Title: "How can I book other Samvedna Services ?",
      content: {
        desc: [
          "From your MAP dashboard, check the services tab and select the service and go right ahead and book any of our counselling, caregiving or healthcare services.",
        ],
      },
    },
    {
      Title:
        "You only have three assessments, I am looking for specific assessments for anger management, depression, anxiety etc. How can I do a  self-assessment for these ? ",
      content: {
        desc: [
          "The assessments we have chosen are designed to help you screen yourself for mental health concerns. At Samvedna we adhere to a scientific, evidenced based approach to provide solutions for your treatment plan. The self-assessments are to guide for you to seek timely help, a proper psychological evaluation by an expert is required to treat any specific issues like depression, anxiety, stress etc.  An expert clinical psychologist will conduct a psychological evaluation and get you started with the therapeutic interventions.",
        ],
      },
    },
    {
      Title: "What is a psychological evaluation/ assessment?  ",
      content: {
        desc: [
          "Psychological assessment consists of a series of standardized tests and a clinical interview by a trained mental health professional. The interview comprises of a series of questions in a conversational manner where a professional enquires about the problems one is experiencing. One or more standardized tests are used to identify all the problem areas, some underlying and others more evident.",
          "Whenever one visits a mental health therapist, the first step after the initial interaction is generally a psychological assessment. A psychological assessment helps in the following ways:",
        ],
        points: [
          "Identification of the current problems and symptoms",
          "Understanding the biological, social, personality and emotional factors contributing to the problem",
          "Creating a baseline of problems from which comparisons could be made later to monitor progress",
          "Formulation of a management plan for problem resolution.",
        ],
      },
    },
  ];
  return (
    <div className={styles.faqSec}>
      <h3>Frequently Asked Questions</h3>
      {/* {
            faqData?.map((item, i) => (
                <CustomizedAccordions heading={item.Title} content={item.content} index={i}/>
            ))
        } */}
      <CustomizedAccordions content={faqData} />
    </div>
  );
};
