import React from "react";
import ServiceIcon from "../Images/ServiceIcon.svg";
import styles from "./servicecard.module.css";
import { Readmore } from "../../../../Component/UI/FeedbackCards/Readmore";
import { useState } from "react";

export const ServiceCard = ({ name, description, amount }) => {
  const [more, setMore] = useState(true);
  const slicedChars = window.innerWidth <= 480 ? 240 : 280;
  const feedBackText = (...c) => description.slice(...c);
  const isMoreReq = description.length > slicedChars + 10;
  return (
    <div className={styles.service_card}>
      <div className={styles.icon}>
        <img src={ServiceIcon} alt="ServiceIcon" />
      </div>
      <div className={styles.content}>
        <p className={styles.title}>{name}</p>
        <p className={styles.amount}>₹{amount}</p>
        {more && isMoreReq ? (
          <p className={styles.description}>
            {description?.slice(0, slicedChars) + "..."}
            <span
              onClick={() => setMore(!more)}
              style={{ color: "#7A51AE", cursor: "pointer" }}
            >
              ReadMore
            </span>
            {/* {feedBackText(0, 400)}
          {feedBackText(0).length > 140 ? (
            <Readmore text={feedBackText(130)} />
          ) : null} */}
          </p>
        ) : (
          <p className={styles.description}>
            {description + "..."}{" "}
            {isMoreReq && (
              <span
                onClick={() => setMore(!more)}
                style={{ color: "#7A51AE", cursor: "pointer" }}
              >
                ReadLess
              </span>
            )}
            {/* {feedBackText(0, 400)}
        {feedBackText(0).length > 140 ? (
          <Readmore text={feedBackText(130)} />
        ) : null} */}
          </p>
        )}
        {/* <p className={styles.description}>{description}</p> */}
      </div>
    </div>
  );
};
