import React from "react";
import { BsFilter } from "react-icons/bs";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
// import toast from "../../../../node_modules/react-hot-toast/dist/index";
import {
  setFilter,
  setClear,
  setSearch_key,
  setTo_date,
  setFrom_date,
} from "../../../Redux/Slices/AdminFilters/Search";
import { useState } from "react";
import { Menu, MenuItem } from "../../../../node_modules/@mui/material/index";

export const Filter = ({disabled}) => {
  const { search_key, from_date, to_date, filter } = useSelector(
    (state) => state.SearchFilters
  );
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilter = () => {
    dispatch(setFilter(!filter));
    handleClose();
  };
  const handleClear = () => {
    dispatch(setSearch_key(""));
    dispatch(setTo_date(""));
    dispatch(setFrom_date(""));
    dispatch(setFilter());
    dispatch(setClear());
    handleClose();
  };

  return (
    <>
      <Button
        disabled={disabled}
        type="submit"
        size="large"
        variant="outlined"
        color={"inherit"}
        style={{
          width: "100%",
          textTransform: "none",
          height: "40px",
          background: "#fff",
          border: "1px solid #DADADA",
          borderRadius: "8px",
          fontWeight: 400,
          fontSize: "14px",
          color: "#323232",
        }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        //onClick={handleFilter}
      >
        Filter <BsFilter style={{ marginLeft: "10px" }} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          style: { display: "flex", flexDirection: "column" },
        }}
      >
        <MenuItem
          onClick={handleFilter}
          style={{ fontWeight: 400, fontSize: "14px" }}
        >
          Apply
        </MenuItem>
        <MenuItem
          onClick={handleClear}
          style={{ fontWeight: 400, fontSize: "14px" }}
        >
          Clear
        </MenuItem>
      </Menu>
    </>
  );
};
