import "./App.css";
// import { RouteComponent } from "./Routes/Routes";
// import { Layout } from "./Component/Layout/Layout";
// import { AppointmentCards } from "./Component/UI/AppointmentCards/AppointmentCards";
// import { Calender } from "./Component/UI/Calender";
// import { DashboardCards } from "./Component/UI/DashboardCard/DashboardCard";
// import { Login } from "./Samvenda-Admin/Login";
// import { CustomizedAccordions } from "./Samvenda-Patient/accordion/CustomizedAccordions";
// import ProfileForm from "./Samvenda-Patient/Profile/ProfileForm";
import { TestAddAssesment } from "./Samvenda-Admin/DashboardPages/Assessment/TestAddAssesment";
import { RouteComponent } from "./Routes/Routes";
import { useContext, useState } from "react";
import AuthContext from "./Auth/AuthContext";
import { setToken } from "./config/axiosConfig";
import { useAuth } from "./Auth/AuthProvider";
import "./global.css";
import { useEffect } from "react";

function App() {
  const ctx = useAuth();
  setToken(ctx?.loginUser?.token);
  useEffect(() => {
    if (new Date().getTime() > ctx?.loginUser?.expireDate) {
      ctx.logout();
    }
  }, [ctx]);
  return (
    <div className="App">
      {/* <TestAddAssesment /> */}
      <RouteComponent />
    </div>
  );
}

export default App;
