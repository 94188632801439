import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Input } from "../../../../Component/UI/CustomInputs";
import styles from "./rosterManagement.module.css";
import { StaffDetails } from "./StaffDetails";
import { Tab } from "./Tab/Tab";
export const StaffList = ({
  selectDropDown,
  tHead,
  data,
  staffListData,
  isLoading,
  setSelectStaffId,
  selectStaffId,
  isLoadingData,
  timeSlotsBooked,
  setSearchStaff,
  searchStaff,
  tHeadAdd,
  staffTimeSlotdata,
  rosterPermissions,
}) => {
  return (
    <div className={styles.staffList}>
      <div className={styles.stafftab}>
        <Tab
          selectDropDown={selectDropDown}
          tHead={tHead}
          Component={StaffDetails}
          staffListData={staffListData}
          isLoading={isLoading}
          setSelectStaffId={setSelectStaffId}
          selectStaffId={selectStaffId}
          isLoadingData={isLoadingData}
          timeSlotsBooked={timeSlotsBooked}
          setSearchStaff={setSearchStaff}
          searchStaff={searchStaff}
          tHeadAdd={tHeadAdd}
          staffTimeSlotdata={staffTimeSlotdata}
          rosterPermissions={rosterPermissions}
        />
      </div>
    </div>
  );
};
