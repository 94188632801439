import "./FileUpload.css";

export const FileUpload = ({
  marginTop,
  icon,
  actualBtnRef,
  onChange,
  fileName,
}) => {
  return (
    <>
      <div
        style={{
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: marginTop,
        }}
      >
        <div>
          <input
            type="file"
            id="actual-btn"
            hidden
            ref={actualBtnRef}
            onChange={onChange}
          />
          <label htmlFor="actual-btn" className="uploadLabel">
            Choose File
          </label>
        </div>
        <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
          <span id="file-chosen">{fileName}</span>
        </div>
      </div>
    </>
  );
};

export const FileUploadXl = ({
  marginTop,
  icon,
  actualBtnRef,
  onChange,
  fileName,
}) => {
  return (
    <>
      <div className="fileUpload">
        <div className="fileSelect">
          <div className="fileSelectButton" id="fileName">
            {"Choose File"}
          </div>
          <div className="fileSelectName" id="noFile">
            {fileName ? fileName : "No file chosen..."}
          </div>
          <input
            type="file"
            ref={actualBtnRef}
            name="chooseFile"
            id="chooseFile"
            accept=".xlsx,.csv,.xls"
            onChange={onChange}
          />
        </div>
      </div>
    </>
  );
};
