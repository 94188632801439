import { SelfAssessments } from "./SelfAssessments";
import { SubHeader } from "../../UI/SubHeader";
import { UploadedAssessments } from "./UploadedAssessments";
import { EnterprisesAssessments } from "./EnterprisesAssessments";
import { useAuth } from "../../../Auth/AuthProvider";

export const Reports = () => {
  const ctx = useAuth();
  const items = [
    {
      label: "Self Assessments",
      component: <SelfAssessments />,
    },
    {
      label: "Other Reports",
      component: <UploadedAssessments />,
    },
    ctx?.loginUser?.customer?.enterprise_id !== null && {
      label: "Enterprises Assessments",
      component: <EnterprisesAssessments />,
    },
  ];

  return <SubHeader calendar={"no"} tab={items} />;
};
