import { createSlice } from "@reduxjs/toolkit";
// import { useAxiosPrivate } from "../../Hooks/useAxiosPrivate";
import { API } from "../../config/constant";
import { token } from "../../assets/constants";
import instance from "../../config/axiosConfig";
import { useSelector } from "react-redux";

export const initialState = {
  loading: false,
  hasErrors: false,
  selfCare: [],
  postdata: {},
};

const selfCareSlice = createSlice({
  name: "selfCare",
  initialState,
  reducers: {
    getselfCare: (state) => {
      state.loading = true;
    },
    getSelfCareSuccess: (state, { payload }) => {
      state.selfCare = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    postSelfCareSuccess: (state, { payload }) => {
      state.postdata = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getselfCareFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const {
  getselfCare,
  getSelfCareSuccess,
  postSelfCareSuccess,
  getselfCareFailure,
} = selfCareSlice.actions;

// The reducer
export default selfCareSlice.reducer;

//post api
export function fetchSelfCare(form_data) {
  return async (dispatch) => {
    dispatch(getselfCare());
    try {
      const response = await instance.post(API.ADDSELFCARETIPS, form_data);
      const data = response;
      dispatch(postSelfCareSuccess(data.data));
    } catch (error) {
      dispatch(getselfCareFailure());
    }
  };
}

//GetApi
let isMounted = true;
const controller = new AbortController();
export function fetchAddSelfCare(search_key, from_date, to_date, setIsLoading) {
  return async (dispatch) => {
    setIsLoading(true);
    dispatch(getselfCare());
    try {
      const response = await instance.get(
        `${API.GETSELFCARETIPS}?search_key=${search_key}&from_date=${from_date}&to_date=${to_date}`
      );
      // const data = isMounted && response
      setIsLoading(false);
      const data = response.data;

      dispatch(getSelfCareSuccess(data.list));
    } catch (error) {
      dispatch(getselfCareFailure());
      isMounted = false;
      controller.abort();
    }
  };
}
