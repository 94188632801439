import React from "react";

export const Helpcircle = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeWidth="0.75"
        d="M8.00016 14.6663C11.6821 14.6663 14.6668 11.6816 14.6668 7.99967C14.6668 4.31778 11.6821 1.33301 8.00016 1.33301C4.31826 1.33301 1.3335 4.31778 1.3335 7.99967C1.3335 11.6816 4.31826 14.6663 8.00016 14.6663Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        strokeWidth="0.75"
        d="M6.06006 6.00038C6.21679 5.55483 6.52616 5.17912 6.93336 4.9398C7.34056 4.70049 7.81932 4.61301 8.28484 4.69285C8.75036 4.7727 9.1726 5.01473 9.47678 5.37607C9.78095 5.7374 9.94743 6.19473 9.94672 6.66705C9.94672 8.00038 7.94673 8.66705 7.94673 8.66705"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        strokeWidth="0.75"
        d="M8 11.333H8.00667"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
