import React, { useState } from "react";
import MentalWellBeing from "./MentalWellBeingCharts/MentalWellBeing";
import Dementia from "./Dementia/Dementia";
import { SelectType } from "../../../Component/UI/CustomInputs";
import PROGRESS from "../../../Samvenda-Admin/contant/ProgressConstant";
import styles from "../../../assets/css/PatientDashboard/progresstrend.module.css";

import { useEffect } from "react";
import { PatientCalender } from "../../../Component/UI/PatientCalender.jsx";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { forwardRef } from "react";
import { PatientHeader } from "../../../Component/Header/PatientHeader";
import Logo from "../../../assets/icons/icon/logo.svg";
import { useAuth } from "../../../Auth/AuthProvider";

// const options = ['Dementia', 'Mental Well Being'];
const options = ["Mental Well Being"];

const Dateoptions = [
  { id: 0, name: "7 Days" },
  { id: 1, name: "1 Month" },
  { id: 2, name: "6 Months" },
  { id: 3, name: "Custom" },
];

const filterType = {
  0: "week",
  1: "month",
  2: "sixmonth",
  3: "custom",
};

export const ProgressTrends = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { chartData } = useSelector((state) => state.ProgressTrendSlice);
  const [selected, setSelected] = useState("Mental Well Being");
  const [select, setSelect] = useState(0);
  const [active, setActive] = useState(false);
  const logInUser = useAuth();
  const [dateGap, setDateGap] = useState({
    startDate: moment().add(-6, "days").format("DD-MM-YYYY"),
    endDate: moment().format("DD-MM-YYYY"),
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log(logInUser, "logInUser");

  useEffect(() => {
    select === Dateoptions.length - 1 && setActive(true);
  }, [select]);

  const selecedHandleChange = (e) => {
    setSelected(e.target.value);
  };

  const DateHandler = (id) => {
    setSelect(id);

    id !== Dateoptions.length - 1 && setActive(false);
    const dateGapType = {
      week: {
        startDate: moment().add(-6, "days").format("DD-MM-YYYY"),
        endDate: moment().format("DD-MM-YYYY"),
      },
      month: {
        startDate: moment().add(-29, "days").format("DD-MM-YYYY"),
        endDate: moment()
          // .add(-1, "months").endOf("month")
          .format("DD-MM-YYYY"),
      },
      sixmonth: {
        startDate: moment()
          .add(-6, "months")
          .startOf("month")
          .format("DD-MM-YYYY"),
        endDate: moment().add(-1, "months").endOf("month").format("DD-MM-YYYY"),
      },
      custom: {
        startDate: moment().startOf("month").format("DD-MM-YYYY"),
        endDate: moment().format("DD-MM-YYYY"),
      },
    };

    setDateGap({
      startDate: dateGapType[filterType[id]].startDate,
      endDate: dateGapType[filterType[id]].endDate,
    });
  };

  return (
    <div ref={ref}>
      <div className={styles.printSec}>
        <div className={styles.username}>
          User: {logInUser?.loginUser?.customer?.name}
        </div>
        <div className={styles.userlogo}>
          <img src={Logo} />
        </div>
      </div>
      <div className={styles.selectcontainer} style={{ marginTop: "20px" }}>
        <div className={styles.datecontainer}>
          {Dateoptions.map((el) => {
            return (
              <div
                key={el.id}
                className={styles.slider}
                style={{
                  color: Dateoptions[select].id === el.id ? "#ffc070" : "black",
                  fontSize: "16px",
                  width: "170px",
                  cursor: "pointer",
                  boxShadow:
                    Dateoptions[select].id === el.id ? "0 2px 0 0" : "",
                  textAlign: "center",
                }}
                onClick={() => DateHandler(el.id)}
              >
                {el.name}
              </div>
            );
          })}
        </div>
        {active && (
          <PatientCalender dateGap={dateGap} setDateGap={setDateGap} />
        )}
      </div>
      {selected === PROGRESS.DEMENTIA ? (
        <Dementia />
      ) : (
        <MentalWellBeing
          filterType={filterType[select]}
          data={chartData}
          dateGap={dateGap}
        />
      )}
      <div className={styles.printFooter}>
        This is the Progress Trend Report based on the activities measured.
      </div>
      <div className={styles.printFooter} style={{ visibility: "hidden" }}>
        This is the Progress Trend Report based on the activities measured.
      </div>
    </div>
  );
});
