import { createSlice } from "@reduxjs/toolkit";

const optionsSlice = createSlice({
  name: "select options",
  initialState: { options: [], customerRelativeData: [] },
  reducers: {
    setSelectOptions: (state, action) => {
      state.options = action.payload;
    },
    setCustomerRelativeData: (state, action) => {
      state.customerRelativeData = action.payload;
    },
  },
});

export const { setSelectOptions, setCustomerRelativeData } =
  optionsSlice.actions;

export default optionsSlice.reducer;
