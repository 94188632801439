import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "../../../../../node_modules/react-router-dom/index";
import { setEmailTemplate } from "../../../../Redux/Slices/AdminSamvedna/EmailTemplateForm";
import { editEmailTemplate } from "../../../../services/admin/emailTemplate";
import EmailTemplateForm from "./EmailTemplateForm";

const EditEmailTemplate = () => {
  const { state } = useLocation();
  const { email } = useSelector((state) => state.EmailTemplateForm);
  const dispatch = useDispatch();

  useEffect(() => {
    const data = {
      type: state.temaplate_name,
      subject: state.subject,
      description: state.description,
    };
    dispatch(setEmailTemplate(data));
  }, []);

  const handleClick = () => {
    editEmailTemplate(email).then((res) => console.log(res));
  };
  return <EmailTemplateForm click={handleClick} />;
};

export default EditEmailTemplate;
