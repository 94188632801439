import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import styles from "../../../assets/css/AdminDashboard/overview.module.css";
import { Appointment } from "../../../assets/icons/Appointment";
import { Calender } from "../../../Component/UI/Calender";
import { Card } from "../../../Component/UI/Card";
import { Heading } from "../../../Component/UI/Heading";
import { getDashboardAppointment } from "../../../services/admin/dashboard";
import { checkWidth } from "./Overview";

export const OverviewAppointment = () => {
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });

  const [state, setState] = useState();

  useEffect(() => {
    console.log(date);
    getDashboardAppointment(date?.startDate, date?.endDate).then((res) =>
      setState(res?.data?.list)
    );
  }, [date]);

  const series = [
    {
      name: "Upcoming",
      data: state?.graph?.map((item) => item.data.upcoming),
    },
    {
      name: "Cancelled",
      data: state?.graph?.map((item) => item.data.cancelled),
    },
    {
      name: "Completed",
      data: state?.graph?.map((item) => item.data.completed),
    },
  ];

  const options = {
    colors: ["#F19204", "#EB0000", "#18BA4B"],
    chart: {
      height: 300,
      type: "bar",
      stacked: "true",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    animations: {
      enabled: true, //no animations
    },
    dataLabels: {
      enabled: false,
    },

    xaxis: {
      type: "datetime",
      labels: {
        show: true,
        datetimeUTC: false,
      },
      categories: state?.graph?.map((item) => item.date),
    },
    tooltip: {
      enabled: true,
    },
    fill: {
      opacity: 1,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
        columnWidth: checkWidth(state?.graph?.length),
        dataLabels: {
          total: {
            enabled: false,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
          },
        },
      },
    },

    yaxis: {
      show: true,
      title: {
        text: "No. of Appointments",
        style: {
          fontSize: "12px",
          fontWeight: 500,
        },
      },
      seriesName: "My Data",
      labels: {
        formatter: function (value) {
          return value;
        },
      },
      axisBorder: {
        show: true,
        color: "#F0F0F0",
        offsetX: 0,
        offsetY: 0,
      },
      // min: 8,
      // max: 40,
      // tickAmount: 4,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 0,
      width: 400,
      markers: {
        radius: 20,
        width: 12,
      },
      itemMargin: {
        horizontal: 20,
        vertical: 0,
      },
    },

    // title: {
    //   text: "XYZ - Stock Analysis (2009 - 2016)",
    //   align: "left",
    //   offsetX: 110,
    // },
    noData: {
      text: "No data is available to plot the graph",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
  };
  const appointment1 = <Appointment size="40" outline="#7A51AE" thickness />;
  const appointment2 = <Appointment size="40" outline="#f7c375" thickness />;
  const appointment3 = <Appointment size="40" outline="#EB0000" thickness />;
  const appointment4 = <Appointment size="40" outline="#18BA4B" thickness />;

  return (
    <>
      <div className={styles.mainWrap}>
        <div className={styles.sub_title}>
          <Heading title="Appointments" />
        </div>
        <div>
          <Calender setDateGap={setDate} init />
        </div>
      </div>

      <div className={styles.app_over}>
        <div>
          <Card
            text="Total Appointments"
            num={state?.appointments?.total_appointments}
            icon={appointment1}
            color="#f2eef7"
          />
          <Card
            text="Upcoming"
            num={state?.appointments?.upcoming}
            icon={appointment2}
            color="#fef4e6"
          />
          <Card
            text="Cancelled"
            num={state?.appointments?.cancelled}
            icon={appointment3}
            color="#fde5e5"
          />
          <Card
            text="Completed"
            num={state?.appointments?.completed}
            icon={appointment4}
            color="#e8f8ed"
          />
        </div>
        <div className={styles.mood_div}>
          <ReactApexChart
            className={styles.chart_div}
            options={options}
            type="bar"
            series={series}
            height={400}
            width="100%"
          />
        </div>
      </div>
    </>
  );
};
