import React from "react";
import { Btn } from "../../UI/Button";
import styles from "./doctor.module.css";
import pic from "../../../assets/images/doctor.png";

export const DoctorCard = ({ onClick, data }) => {
  return (
    <div>
      <div className={styles.card}>
        <div className={styles.frame}>
          <img src={data?.staff_image ?  data?.staff_image : pic} alt={data?.name} />
        </div>
        <div className={styles.details}>
          <p>{data?.name}</p>
          <p>{data?.qualification}</p>
          <Btn click={onClick}>Details</Btn>
        </div>
      </div>
    </div>
  );
};
