import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "../../../../node_modules/moment/moment";
import {
  toast,
  Toaster,
} from "../../../../node_modules/react-hot-toast/dist/index";
import { Cross } from "../../../assets/icons/Cross";
import { Edit } from "../../../assets/icons/Edit";
import { Avatar } from "../../../Component/UI/Avatar";
import Popup from "../../../Component/UI/Popup/Popup";
import SwitchToggle from "../../../Component/UI/Switch";
import { CustomTable } from "../../../Component/UI/Table";
import { formatttedDate } from "../../../config/formattedDate";
import Text_Seach_Filter_Export from "../../../Hooks/Text_Seach_Filter_Export";
import {
  setFilter,
  setFrom_date,
  setSearch_key,
  setTo_date,
} from "../../../Redux/Slices/AdminFilters/Search";
import { ADMIN_ROUTES } from "../../../Routes/routesConstants";
import {
  changeStatus,
  deleteSubscription,
  getSubscriptionstable,
} from "../../../services/admin/subscriptions";
import ConfirmPopup from "../Service/ConfirmPopup";
import { usePermissions } from "../../../Hooks/usePermissions";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";

export const SubscriptionsTable = () => {
  const permissions = usePermissions("name", "subscriptions");
  const fullpermissions = +permissions?.listControl?.access?.["Full Control"];
  const writePermissions = +permissions?.listControl?.access?.Write;
  const [isLoading, setIsLoading] = useState(false);
  const tableHeadings = [
    "Subscription Name",
    "User Type",
    "Amount",
    "Date",
    "Validity",
    "Extended Validity",
    "Payment Mode",
    ...(fullpermissions ? ["Status"] : []),
    ...(writePermissions ? ["Action"] : []),
  ];
  const dispatch = useDispatch();

  const [subscriptionData, setSubscriptionData] = useState();
  const [popupAction, setPopupAction] = useState({ open: false });
  const [statusUpdate, setStatusUpdate] = useState(false);
  const [statusDisabled, setStatusDisable] = useState(false);
  const { search_key, from_date, to_date, filter } = useSelector(
    (i) => i.SearchFilters
  );

  const [popupActionDel, setPopupActionDel] = useState({ open: false });

  const convertDate = (t) => moment(t).format("DD/MM/YYYY");

  const oneMonth = 30;
  const convertValidity = (days) =>
    days >= oneMonth
      ? days / oneMonth > 1
        ? days / oneMonth + " Months"
        : days / oneMonth + " Month"
      : days + " Days";

  const getListing = () => {
    setIsLoading(true);
    getSubscriptionstable(search_key, from_date, to_date).then(
      (i) => (setIsLoading(false), setSubscriptionData(i))
    );
  };

  useEffect(() => {
    getListing();
  }, [statusUpdate, filter]);

  useEffect(() => {
    return () => {
      // dispatch(setSearch_key(""));
      dispatch(setTo_date(""));
      dispatch(setFrom_date(""));
      dispatch(setFilter());
    };
  }, []);

  const handleStatusToggle = (id, status, popup, setToggle) => {
    if (statusDisabled) return;
    if (popup) {
      setPopupAction({
        ...popupAction,
        open: true,
        action: () => {
          setStatusDisable(true);
          changeStatus(id, !status).then((res) => {
            if (res?.data?.status) {
              setStatusDisable(false);
              // setToggle(!status);
              setStatusUpdate(!statusUpdate);
              setPopupAction({
                ...popupAction,
                open: false,
              });
            }
          });
        },
      });
    } else {
      setStatusDisable(true);
      setToggle(!status);
      changeStatus(id, !status).then((res) => {
        if (res?.data?.status) {
          setStatusDisable(false);
          setStatusUpdate(!statusUpdate);
        }
      });
    }
  };

  let nf = new Intl.NumberFormat("en-US");

  const tBodyData = subscriptionData?.map((item) => {
    const {
      name,
      net_price,
      id,
      status,
      created_at,
      validity,
      extended_validity,
      subscription_type,
      enterprise_name,
      expiry_at,
      payment_mode,
      is_extended,
    } = item;
    const isExpired = moment(expiry_at).isBefore(new Date(), "day");
    console.log(isExpired, "isExpired");
    return {
      name: (
        <>
          <Link
            to={ADMIN_ROUTES.DETAILS_SUBSCRIPTIONS}
            style={{
              color: "#000",
              textDecoration: "none",
              display: "flex",
              pointerEvents: "auto",
            }}
            state={{ ...item, permissions }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                height: 0,
              }}
            >
              {
                <Avatar
                  size="28px"
                  // profile={customer_image}
                  border="1px solid #ccc"
                />
              }
              <span style={{ marginLeft: "8px" }}>{name}</span>
            </span>
          </Link>
        </>
      ),
      user: subscription_type === "0" ? "General" : enterprise_name,
      amount: `₹${nf.format(net_price)}`,
      date: formatttedDate(created_at),
      validity:
        Number(validity) >= 30
          ? `${Math.round(validity / 30)} ${
              validity / 30 > 1 ? "Months" : "Month"
            }`
          : `${validity} Days`,
      extended_validity: extended_validity
        ? Number(extended_validity) >= 30
          ? `${Math.round(extended_validity / 30)} ${
              extended_validity / 30 > 1 ? "Months" : "Month"
            }`
          : `${extended_validity} Days`
        : "",
      //type_of_service: getTypeOfService(type_of_service),
      // numberOfSessions,
      payment_mode: payment_mode,
      ...(fullpermissions && {
        status:
          +status === 2 ? (
            <div style={{ color: "red" }}>Expired</div>
          ) : (
            <div key={id}>
              <SwitchToggle
                status={status}
                disabled={!isExpired && expiry_at !== null}
                onClick={(setToggle) =>
                  expiry_at
                    ? handleStatusToggle(id, status, false, setToggle)
                    : handleStatusToggle(id, status, true)
                }
                // disabled
              />
            </div>
          ),
      }),
      ...(writePermissions && {
        action: (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              // pointerEvents:
              //   subscription_type === "1"
              //     ? isExpired
              //       ? "none"
              //       : "unset"
              //     : "unset",
            }}
          >
            {!isExpired ? (
              is_extended === "0" ? (
                <Link
                  to={"edit"}
                  style={{ color: "#000" }}
                  state={{ edit: true, id }}
                >
                  <Edit />
                </Link>
              ) : (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    toast.remove();
                    toast.error(
                      "Subscription is already extended ! You can't edit it."
                    );
                  }}
                >
                  <Edit />
                </div>
              )
            ) : (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  toast.remove();
                  toast.error("Subscription is expired ! You can't edit it.");
                }}
              >
                <Edit />
              </div>
            )}
            <div
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => {
                toast.remove();
                if (expiry_at === null) {
                  setPopupActionDel({
                    ...popupActionDel,
                    open: true,
                    action: () => {
                      deleteSubscription(id).then((res) => {
                        if (res?.data?.status) {
                          toast.success(res?.data?.msg);
                          getListing();
                          setPopupActionDel({
                            ...popupActionDel,
                            open: false,
                          });
                        }
                      });
                    },
                  });
                } else {
                  var msg = `You can't delete the ${
                    isExpired ? "expired" : "active"
                  } subscription!`;
                  toast.error(msg);
                }
              }}
            >
              <Cross />
            </div>
          </div>
        ),
      }),
    };
  });
  const convertDateTime = (t) => moment(t).format("DD/MM/YYYY, HH:MMA");

  const intervalTypes = {
    0: "Daily",
    1: "Weekly",
    2: "Monthly",
    3: "Twice a week",
    4: "Thrice a week",
  };

  console.log(subscriptionData, "subscriptionData-----");

  const exportSubsData = subscriptionData?.flatMap((response) => {
    return response.subscriptionservice.map(
      (
        {
          service_name,
          duration,
          total_price,
          unit_price,
          tax_applicable,
          tax_value,
          number_session,
          consume,
          minimum_validity,
          interval_type,
          mode,
          service_id,
        },
        i
      ) => {
        return {
          Id: response.id,
          "Subscription Name": response.name,
          "Patient Name": response?.customer_name,
          "User Type":
            response.subscription_type === "0"
              ? "General"
              : response.enterprise_name,
          Status: response.status === 1 ? "Active" : "InActive",
          "Total Amount": i === 0 ? response.net_price : "",
          "Discount Type":
            response.discount_type === "0"
              ? "No Discount"
              : response.discount_type === "1"
              ? "Flat"
              : response.discount_type === "2"
              ? "Percentage"
              : "",
          "Discount Value": i === 0 ? response.discount_percentage : "",
          "Tax Amount": i === 0 ? response.tax_amount : "",

          "Created Date": response.created_at
            ? convertDate(response.created_at)
            : "",
          Validity: convertValidity(response.validity),
          "Started Date": response.approved_at
            ? convertDate(response.approved_at)
            : "",
          "Expiry Date": response.expiry_at
            ? convertDate(response.expiry_at)
            : "",
          "Extended validity": response.extended_validity
            ? convertValidity(response.extended_validity)
            : "",

          "Additional Amount": response.additional_amount,

          "Service Id": service_id,
          "Service Name": service_name,
          "Duration (HH:MM)": duration,
          "Number of Session": number_session,
          "Number of Session Booked": consume ? consume : 0,
          "Service Unit Price": unit_price,
          "Service Total Price": total_price,
          "Tax Applicable": `${
            tax_applicable.toString() !== "1" ? "No" : `Yes`
          }`,
          "Tax value": tax_value,

          "Min Validity": convertValidity(minimum_validity),
          "Interval Type": intervalTypes[interval_type],
          Mode: ["Offline", "Online", "Hybrid"][mode],
          "Payment mode": response?.payment_mode,
          Remarks: response?.payment_mode === "offline" ? response?.remark : "",
        };
      }
    );
  });

  return (
    <>
      <Toaster />
      <Text_Seach_Filter_Export
        ApiData={exportSubsData}
        text="Subscriptions"
        route="add"
        permissions={permissions?.listControl}
      >
        <Popup
          popupAction={popupActionDel}
          setPopupAction={setPopupActionDel}
          title="Are you sure you want to delete this subscription?"
          heading="Subscription Delete"
        />
        <ConfirmPopup
          popupAction={popupAction}
          setPopupAction={setPopupAction}
          title="Are you sure you want to enable this subscription? once it's enabled an expiry date will be set according to the validity"
          heading="Subscription Status"
        />
        {!isLoading ? (
          <CustomTable tHead={tableHeadings} tBody={tBodyData} />
        ) : (
          <div
            className="loader"
            style={{ textAlign: "center", margin: "60px", width: "100%" }}
          >
            <CircularProgress size={28} />
          </div>
        )}
      </Text_Seach_Filter_Export>
    </>
  );
};
