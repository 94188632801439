import { useSelector } from "react-redux";
import { addEmailTemplate } from "../../../../services/admin/emailTemplate";
import EmailTemplateForm from "./EmailTemplateForm";

const AddEmailTemplate = () => {
  const { email } = useSelector((state) => state.EmailTemplateForm);

  const handleClick = () => {
    addEmailTemplate(email)
      .then((res) => console.log(res))
      .catch();
  };

  return <EmailTemplateForm click={handleClick} />;
};

export default AddEmailTemplate;
