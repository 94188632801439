import React from "react";
import { useEffect } from "react";
import { ReportCard } from "../../Components/Report/ReportCard";
import styles from "./report.module.css";
import { enterpriseAssessmentList } from "../../../services/enterprise/dashboard";
import { useState } from "react";
import { downloadPdfReport } from "../../../services/enterprise/reports";
import { useDispatch, useSelector } from "react-redux";
import {
  setDate,
  setToggle,
  setYear,
} from "../../../Redux/Slices/Patient/PatienCalender";
import moment from "../../../../node_modules/moment/moment";
import { Empty } from "../../Components/UI/Empty";

const months = [
  "All",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const Reports = () => {
  const [reportsData, setReportsData] = useState();

  const { selectedDate, year } = useSelector((state) => state.DateFilterSlice);

  const monthSelectd = months.findIndex(
    (element) => element === selectedDate?.split(" ")[0]
  );

  useEffect(() => {
    enterpriseAssessmentList().then((res) => setReportsData(res.data.list));
  }, []);

  const downloadReport = (id, service_id) => {
    downloadPdfReport({
      assessment_id: id,
      month: monthSelectd,
      year: year,
      service_id,
    })
      .then((res) => {
        res?.data?.status && window.open(res?.data.url, "Assessment Report");
      })
      .catch((err) => console.log(err));
  };

  const FinalArr = reportsData || [];

  const searchResult = FinalArr?.filter((service) => {
    return selectedDate?.includes("All")
      ? service.created_at?.includes(selectedDate?.split(" ")?.[1])
      : service.created_at?.includes(moment(selectedDate).format("YYYY-MM"));
  });

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setDate(`All ${new Date().getFullYear()}`));
      dispatch(setToggle(false));
      dispatch(setYear(new Date().getFullYear()));
    };
  }, []);

  return (
    <>
      <div className={styles.container}>
        {searchResult.length ? (
          searchResult?.map((item) => {
            return (
              <div key={item?.id}>
                <ReportCard
                  title={item?.name}
                  date={item?.date_of_creation}
                  questions={item?.no_of_question}
                  imageIcon={item?.assessment_image}
                  handleBtn={() => downloadReport(item?.id, item?.service_id)}
                />
              </div>
            );
          })
        ) : (
          <div style={{ margin: "auto" }}>
            <Empty title="No reports available at this time!" />
          </div>
        )}
      </div>
    </>
  );
};
