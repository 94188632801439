import React from "react";
import { Assesssment } from "./Assesssment";
import { Faq } from "./Faq";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { HowItWorks } from "./HowItWorks";
import { KnowOurExperts } from "./KnowOurExperts";
import styles from "./maplanding.module.css";
import { OurDoctors } from "./OurDoctors";
import { OurService } from "./OurService";
import { Testimonials } from "./Testimonials";
import { ViewPackageSubscription } from "./ViewPackageSubscription";
import { WhyShould } from "./WhyShould";
import whatsapp from "../../../../src/assets/images/whatsapp_icon.png";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { ClientLogos } from "./ClientLogo";
export const MapLanding = () => {
  const [start, setStart] = useState(false);
  const domain = window.location.host;
  const canonicalUrl = `https://${domain}/`;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Improving Mental Health in the Workplace - Samvedna Map</title>
        <meta
          name="title"
          content="Improving Mental Health in the Workplace - Samvedna Map"
        />
        <meta
          name="description"
          content="Samvedna MAP is a mental wellbeing self-assessment tool which provides quick insights and helps take timely action to start your self-care journey."
        />
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div>
        {!start && (
          <>
            <Header />
            <div id="howItWorks">
              <HowItWorks styles={styles} />
            </div>
            <div id="whyShould">
              <WhyShould styles={styles} />
            </div>
          </>
        )}
        <div id="assesssment">
          <Assesssment styles={styles} start={start} setStart={setStart} />
        </div>

        {!start && (
          <>
            <div id="ourService">
              <OurService styles={styles} />
            </div>
            <ViewPackageSubscription styles={styles} />
            <div id="clientLogo">
              <ClientLogos styles={styles} />
            </div>
            <OurDoctors styles={styles} />
            <KnowOurExperts styles={styles} />
            <Testimonials styles={styles} />
            <Faq styles={styles} />
            <Footer styles={styles} />
            <div
              style={{
                position: "fixed",
                bottom: "14px",
                left: "14px",
                cursor: "pointer",
                zIndex: 1,
              }}
            >
              <img
                src={whatsapp}
                alt="whats-app"
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send/?phone=%2B919810887895&text&type=phone_number"
                  )
                }
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
