import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import instance from "../../config/axiosConfig";
import { API } from "../../config/constant";
import StepWizard from "react-step-wizard";
import Step from "../../Component/UI/SelfCareCards/Step";
import styles from "../../assets/css/moodcard.module.css";
import {
  setRadioOption,
  setSelectedTime,
} from "../../Redux/Slices/QuickAsk/index";
import { useNavigate } from "../../../node_modules/react-router-dom/index";

export const MoodCard = () => {
  const dispatch = useDispatch();
  const [cardData, setCardData] = useState(null);
  const navigate = useNavigate();

  const allCarData = async () => {
    try {
      instance.get(API.CLIENTQUICKASK).then((res) => {
        res.data.status === true && setCardData(res.data.list);
      });
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    allCarData();

    return () => {
      dispatch(setRadioOption(""));
      dispatch(setSelectedTime(""));
    };
  }, []);

  console.log(cardData, "cardData-");

  let custom = {};
  return (
    <>
      {/* {cardData && ( */}
      <StepWizard transitions={custom} isLazyMount={true}>
        {cardData?.map((question, index) => {
          return <Step key={question.id} question={question} />;
        })}

        <div className={styles.mood_card}>
          <h6 className={styles.mainHeading}>
            <span>Quick Ask</span>
          </h6>
          <div className={styles.moodcard}>
            <p className={styles.completed}>
              Congratulations! Your Quick Asks is completed for the day.
            </p>
          </div>
        </div>
      </StepWizard>
      {/* )} */}
    </>
  );
};
