import React from "react";
import classes from "../../assets/css/card.module.css";
import { IconShadow } from "../UI/IconShadow";

export const Card = ({ icon, text, num, color, numSize, Outerwidth }) => {
  return (
    <div className={classes.outer_card} style={{ width: Outerwidth }}>
      <IconShadow icon={icon} size="70px" color={color} />
      <div className={classes.card_text}>
        <p className={classes.card_title}>{text}</p>
        <p style={{ fontSize: numSize }} className={classes.card_quantity}>
          {num}
        </p>
      </div>
    </div>
  );
};
