import React from "react";
import { Routes, Route } from "react-router-dom";
import Protected from "./Protected";
import LayoutNew from "../Component/Layout/Layoutnew";
import { ENTERPRISE_ROUTES } from "./routesConstants";

// Components

// import { EnterpriseLandingPage } from "../Samvedna-Enterprise/PreLogin/LandingPage.jsx";
import { Dashboard } from "../Samvedna-Enterprise/DashboardPages/Dashboard/index";
import { Assessments } from "../Samvedna-Enterprise/DashboardPages/Assessment/index";
import { AssessmentDetails } from "../Samvedna-Enterprise/DashboardPages/Assessment/AssessmentDetails/AssessmentDetails.jsx";
import { Services } from "../Samvedna-Enterprise/DashboardPages/Services/index";
import { Account } from "../Samvedna-Enterprise/DashboardPages/Account/index";
import { Reports } from "../Samvedna-Enterprise/DashboardPages/Reports/index";
import { HelpSupport } from "../Samvedna-Enterprise/DashboardPages/HelpSupport/index";

import { General } from "../Samvedna-Enterprise/DashboardPages/Account/General.jsx";
import { Password } from "../Samvedna-Enterprise/DashboardPages/Account/Password.jsx";

import NotExist from "../Component/Common/NotExist";
import { Subscriptions } from "../Samvedna-Enterprise/DashboardPages/Subscriptions/index";
import { SubscriptionDetails } from "../Samvedna-Enterprise/DashboardPages/Subscriptions/SubscriptionDetails/SubscriptionsDetails";
import { Testimonial } from "../Samvedna-Enterprise/DashboardPages/Account/Testimonial";

const EnterpriseRoutes = () => {
  return (
    <Routes>
      <Route element={<Protected />}>
        <Route element={<LayoutNew />}>
          <Route path={ENTERPRISE_ROUTES.DASHBOARD} element={<Dashboard />} />
          <Route
            path={ENTERPRISE_ROUTES.ASSESSMENTS}
            element={<Assessments />}
          />
          <Route path={ENTERPRISE_ROUTES.SERVICES} element={<Services />} />
          <Route
            path={ENTERPRISE_ROUTES.SUBSCRIPTIONS}
            element={<Subscriptions />}
          />
          <Route
            path={ENTERPRISE_ROUTES.SUBSCRIPTIONS_DETAILS}
            element={<SubscriptionDetails />}
          />
          <Route path={ENTERPRISE_ROUTES.ACCOUNT} element={<Account />} />
          <Route path={ENTERPRISE_ROUTES.REPORTS} element={<Reports />} />
          <Route path={ENTERPRISE_ROUTES.HELP} element={<HelpSupport />} />
          <Route
            path={ENTERPRISE_ROUTES.ACCOUNTGENERAL}
            element={<General />}
          />
          <Route
            path={ENTERPRISE_ROUTES.ACCOUNTPASSWORD}
            element={<Password />}
          />
          <Route
            path={ENTERPRISE_ROUTES.ASSESSMENTDETAILS}
            element={<AssessmentDetails />}
          />
          <Route
            path={ENTERPRISE_ROUTES.TESTIMONIALS}
            element={<Testimonial />}
          />
        </Route>
      </Route>
      <Route path="*" element={<NotExist />} />
    </Routes>
  );
};
export default EnterpriseRoutes;
