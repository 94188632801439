import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "../../../../../node_modules/react-router-dom/index";
import SimpleBreadcrum from "../../../../Component/UI/SimpleBreadcrum";
import BasicTabs from "../../../UI/Tab/Tabs";
import { AcceptedFeedback } from "../../SamvednaNew/Feedback/TabComponents/AcceptedFeedback";
import { NewFeedback } from "../../SamvednaNew/Feedback/TabComponents/NewFeedback";
import { RejectedFeedbacks } from "../../SamvednaNew/Feedback/TabComponents/RejectedFeedbacks";
import { usePermissions } from "../../../../Hooks/usePermissions";

export const PatientFeedback = () => {
  const { newFeedbacks } = useSelector((i) => i.adminFeedback);
  const [count, setCount] = useState(0);
  const { state } = useLocation();
  const permissions = usePermissions("name", "patients");

  const tabData = [
    {
      label: `New Feedback(${newFeedbacks.length})`,
      component: <NewFeedback pFeedback={true} customerId={state?.props} />,
    },
    {
      label: "Accepted",
      component: (
        <AcceptedFeedback pFeedback={true} customerId={state?.props} />
      ),
    },
    {
      label: "Rejected",
      component: (
        <RejectedFeedbacks pFeedback={true} customerId={state?.props} />
      ),
    },
  ];

  const links = [
    {
      path: "/Patients",
      linkText: "Patients",
    },
    {
      path: "/patientsProfile",
      linkText: state?.name,
      data: { name: state?.name, props: state?.props, permissions },
    },
  ];

  return (
    <div>
      <SimpleBreadcrum text="Others" title="Feedbacks" link={links} />
      <BasicTabs
        tabcontent={tabData}
        value={count}
        setValue={setCount}
        fullWidth
      />
    </div>
  );
};
