import React from "react";

export const Gift = (props) => {
  return (
    <svg
      width={props.size || "16"}
      height={props.size || "16"}
      viewBox={props.size ? `0 0 ${props.size} ${props.size}` : "0 0 16 16"}
      fill="none"
    >
      <path
        d="M30 18V33H6V18"
        stroke="#FB7C35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33 10.5H3V18H33V10.5Z"
        stroke="#FB7C35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 33V10.5"
        stroke="#FB7C35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 10.5H11.25C10.2554 10.5 9.30161 10.1049 8.59835 9.40165C7.89509 8.69839 7.5 7.74456 7.5 6.75C7.5 5.75544 7.89509 4.80161 8.59835 4.09835C9.30161 3.39509 10.2554 3 11.25 3C16.5 3 18 10.5 18 10.5Z"
        stroke="#FB7C35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 10.5H24.75C25.7446 10.5 26.6984 10.1049 27.4017 9.40165C28.1049 8.69839 28.5 7.74456 28.5 6.75C28.5 5.75544 28.1049 4.80161 27.4017 4.09835C26.6984 3.39509 25.7446 3 24.75 3C19.5 3 18 10.5 18 10.5Z"
        stroke="#FB7C35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
