import React from "react";
import classes from "../../assets/css/heading.module.css";

export const Heading = (props) => {
  return (
    <div className={classes.heading}>
      <p className={classes.title}>{props.title}</p>
    </div>
  );
};
