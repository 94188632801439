import axios from "../../config/axiosConfig";

export const listFeedback = async (
  status,
  customer_id = "",
  search_key,
  from_date,
  to_date
) => {
  return await axios
    .get(
      `admin/feedback?status=${status}&customer_id=${customer_id}&search_key=${search_key}&fromdate=${from_date}&todate=${to_date}`
    )
    .then((res) => res)
    .catch((err) => err);
};

export const updateFeedback = async (feedback_id, status) => {
  return await axios
    .get(
      `admin/update/feedback/status?feedback_id=${feedback_id}&status=${status}`
    )
    .then((res) => res)
    .catch((err) => err);
};
