import React from "react";
import styles from "../../assets/css/header.module.css";
import logo from "../../assets/icons/icon/logo.svg";
import { Search } from "../UI/Search";
import { Notification } from "../UI/Notification";
import { Avatar } from "../UI/Avatar";
import { useAuth } from "../../Auth/AuthProvider";
import { useEffect } from "react";
import profilePic from "../../assets/icons/icon/default_profile.svg";
import { MdMenu } from "react-icons/md";
import { useDispatch } from "react-redux";
import { loadData } from "../../Redux/Slices/RolesAndPermissions/rolesAndPermissions";
import Data from "../../Samvenda-Admin/DashboardPages/SamvednaNew/Roles and Permissions/Data.json";
import { getRolesAndPermissions } from "../../services/admin/rolesAndPermissions";
import { useState } from "react";
import { CircularProgress } from "../../../node_modules/@mui/material/index";
import { AdminNotification } from "../UI/AdminNotification";

export const Header = ({ setLogout, currentPath, setDrawer, drawer }) => {
  const ctx = useAuth();
  const adminId = ctx?.loginUser?.admin?.id;
  const profileName = ctx?.loginUser?.admin?.name;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    getRolesAndPermissions(adminId).then((res) => {
      dispatch(loadData(res[0]));
      setIsLoading(false);
    });
  }, []);

  // useEffect(() => {
  //   dispatch(loadData(Data?.categories));
  // }, []);

  return (
    <>
      {isLoading && (
        <div className={styles.beforeLoader}>
          <div className={styles.beforeHeaderInner}>
            <CircularProgress size={30} />
            <p style={{ color: "black" }}>
              Please wait ! We are getting things for you...
            </p>
          </div>
        </div>
      )}

      <header className={styles.header}>
        <div className={styles.enableHeader}>
          <div className={styles.MenuEnable} onClick={() => setDrawer(!drawer)}>
            <MdMenu size={32} />
          </div>
          {/* <h1 className={styles.title}>{title}</h1> */}
        </div>
        <div className={styles.mainLogo}>
          <img className={styles.logo} src={logo} alt="logo" />
        </div>
        <div className={styles.options}>
          <div className={styles.search}></div>
          <div className={styles.rightHeader}>
            <div className={styles.profileName}>{profileName}</div>
            <AdminNotification dot />
            <div onClick={setLogout}>
              <Avatar profile={profilePic} />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
