import React from "react";
import { CustomTable } from "../../../../Component/UI/Table";
import { NavLink, Link } from "react-router-dom";
import { TbEdit } from "react-icons/tb";
import SwitchButton from "../../../../Component/UI/SwitchButton/Switch";
import Plan from "../../../../MockData/Plan.json";
import { Cross } from "../../../../assets/icons/Cross";
import Text_Seach_Filter_Export from "../../../../Hooks/Text_Seach_Filter_Export";

export const ExpertCarePlan = () => {
  const tHead = [
    // "",
    "Date",
    "Care Staff",
    "No. of Activities",
    "Status",
    "Action",
  ];

  const tBodyData = Plan?.map(
    ({
      // assessment_image,
      date,
      carestaff,
      no_of_activities,
      status,
    }) => {
      return {
        date,
        carestaff,
        no_of_activities,

        status,
        action: (
          //   <NavLink
          //     to="/AddAssessment"
          //     state={{ from: "edit", ass_id: id }}
          //     style={{ textDecoration: "none" }}
          //   >
          <>
            <TbEdit />
            <Cross />
          </>
        ),
      };
    }
  );
  return (
    <div>
      <Text_Seach_Filter_Export text={"Expert Care Plan"} route={"/addplan"}>
        <CustomTable tHead={tHead} tBody={tBodyData} />
      </Text_Seach_Filter_Export>
    </div>
  );
};
