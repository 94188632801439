import React from 'react'

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {RiDeleteBinLine} from 'react-icons/ri';

import BasicBreadcrumbs from '../../../Component/UI/BreadCrumb/BreadCrumbs'
import CustomizedButtons from '../../../Component/UI/Button/CustomizeButton';
import PaperWrapper from '../../../Component/UI/PaperWrapper/PaperWrapper';
import ContactUsdata from '../../../MockData/ContactUs.json'
import ControlledOpenSelect from '../../../Component/UI/ControlledOpenSelect/ControlledOpenSelect';
import InputField from '../../../Component/UI/InputField/InputField';
import Box from '@mui/material/Box';

const ContactUs = () => {
  const link = ["Savedna","Content Management","Contact Us"]

  return (
    <BasicBreadcrumbs link={link}>
      <PaperWrapper>
      <Box sx={{ flexGrow: 1 , margin:4}}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
        <Typography variant="h5" component="div" gutterBottom>
          Address
        </Typography>
        </Grid>
        <Grid item xs={6} style={{float:"right"}}>
          <CustomizedButtons ButtonLabel={"Add"}/>
        </Grid>
        {ContactUsdata.address.map((res)=>{
          return(
            <>
            <Grid item xs={4}>
            <ControlledOpenSelect
              inputLabel={res.location}
              width="100%"
              placeholder={"Gurugram"}
              />
            </Grid>
            <Grid item xs={7}>
            <InputField
              label={res.address}
              placeholder={"addddrreeeeessss"}
              InputVisible={true}
              width="100%"
              />
            </Grid>
            <Grid item xs={1}>
              <RiDeleteBinLine style={{ width: "30px", height: "30px", color: "#00b091", marginTop:30}}/>
            </Grid>
            </>
          )
        })}
        {/* Email */}
        <Grid item xs={6}>
        <Typography variant="h5" component="div" gutterBottom>
          Email
        </Typography>
        </Grid>
        <Grid item xs={6}>
          <CustomizedButtons ButtonLabel={"Add"}/>
        </Grid>
            <Grid item xs={11}>
            <InputField
              label={"email"}
              placeholder={"addddrreeeeessss"}
              InputVisible={true}
              width="100%"
              />
            </Grid>
            <Grid item xs={1}>
              <RiDeleteBinLine style={{ width: "30px", height: "30px", color: "#00b091", marginTop:30}}/>
            </Grid>
        {/* Phone */}
        <Grid item xs={6}>
        <Typography variant="h5" component="div" gutterBottom>
          Phone
        </Typography>
        </Grid>
        <Grid item xs={6}>
          <CustomizedButtons ButtonLabel={"Add"}/>
        </Grid>
        {ContactUsdata.phone.map((res)=>{
          return(
            <>
            <Grid item xs={11}>
            <InputField
              label={res.phone}
              placeholder={"addddrreeeeessss"}
              InputVisible={true}
              width="100%"
              />
            </Grid>
            <Grid item xs={1}>
              <RiDeleteBinLine style={{ width: "30px", height: "30px", color: "#00b091", marginTop:30}}/>
            </Grid>
            </>
          )
        })}
      </Grid>
      </Box>
      </PaperWrapper>
    </BasicBreadcrumbs>
  )
}

export default ContactUs