import * as React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineRight } from "react-icons/ai";
import Stack from "@mui/material/Stack";
import classes from "../../../assets/css/layout.module.css";
import { Calender } from "../../../Component/UI/Calender";
import { Card } from "@mui/material";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import CustomizedButtons from "../Button/CustomizeButton";

export default function BasicBreadcrumbs({
  link,
  text,
  children,
  margin,
  isEnableCalender,
  isEnableAddButton,
  addTestimonial,
}) {
  const navigate = useNavigate();

  function handleClick(event, path, cid, data) {
    event.preventDefault();
    navigate(path, {
      state: { cid: cid, data },
    });
  }

  const links = link?.map((el, id, data) => {
    return (
      <Link
        key={id}
        to={`${el.path}`}
        state={{ cid: el.cid }}
        onClick={(e) => handleClick(e, el.path, el.cid, data[1]?.data)}
        className={classes.breadcrumbsCss}
      >
        {el.linkText}
      </Link>
    );
  });

  return (
    <>
      <Stack
        spacing={2}
        className={classes.stackbreadcrumbsCss}
        style={{ margin }}
      >
        <Breadcrumbs
          separator={<AiOutlineRight fontSize="small" />}
          aria-label="breadcrumb"
        >
          {links}
          <span>
            <Typography key={text}>{text}</Typography>
          </span>
        </Breadcrumbs>
      </Stack>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "1%",
        }}
      >
        <Typography variant="h5" key="3" className={classes.fontstyle}>
          {text}
        </Typography>
        {isEnableCalender && <Calender width="20%" />}
        {isEnableAddButton && (
          <Btn
            borderRadius="30px"
            bgColor="#00B091"
            color="#fff"
            width="100px"
            margin="0 10px"
            border="2"
            padding="5px 5px"
            click={addTestimonial}
          >
            Add
          </Btn>
        )}
      </div>

      <Card sx={{ padding: 2, margin: "15px" }}>{children}</Card>
    </>
  );
}
