import { createSlice } from "@reduxjs/toolkit";

const getAssessmentIdSlice = createSlice({
  name: "assessmentId",
  initialState: { cat_id: "" },
  reducers: {
    getAssessmentId: (state, action) => {
      state.cat_id = action.payload;
    },
  },
});

export const { getAssessmentId } = getAssessmentIdSlice.actions;

export default getAssessmentIdSlice.reducer;
