import axios from "../../config/axiosConfig";


export const staffListRoster = async (searchStaff) => {
  return await axios
    .get(`/admin/list/active/staff?search_key=${searchStaff ? searchStaff : ''}`)
    .then((res) => res)
    .catch((err) => err);
};

export const addRoster = async (data, edit) => {
    return await axios
      .post(`${edit ? '/admin/update/staff/timeSlot' : '/admin/add/staff/timeSlot'}`, data)
      .then((res) => res)
      .catch((err) => err);
  };

  export const gettimeSlots = async (data) => {
    return await axios
      .post("/admin/get/staff/timeSlot", data)
      .then((res) => res)
      .catch((err) => err);
  };

// export const staffStatus = async (staff_id, status) => {
//   return await axios
//     .post(STAFFSTATUS, {
//       staff_id,
//       status,
//     })
//     .then((res) => res)
//     .catch((err) => err);
// };

