import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Btn } from "../../../../Samvenda-Patient/UI/Button";
export const CustomTable = (props) => {
  const FinalTableData = props?.tBody?.filter(
    (value) => Object.keys(value).length !== 0
  );

  function capitalizeFirstLetter(string) {
    string = string.split("_").join(" ");
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleChange = (event) => {
    props.setSelectedValue(event.target.value);
  };

  console.log(props?.tHeadData, "tHeadData");

  return (
    <TableContainer
      component={!props.wrapper ? Paper : null}
      style={{ height: props.tableHeight }}
    >
      {/* <TableContainer> */}
      <Table sx={{ minWidth: 650 }}>
        <TableHead style={{ backgroundColor: "#7A51AE", color: "#ffffff" }}>
          <TableRow>
            {props.tHeadData?.map((head, index) => (
              <>
                <TableCell
                  style={{ height: "30px", padding: "4px 10px", color: "#fff" }}
                  key={index}
                  sx={{ fontWeight: 400, fontSize: 13 }}
                >
                  {head?.planeName}
                </TableCell>
                <TableCell
                  style={{ height: "30px", padding: "4px 10px", color: "#fff" }}
                  key={index}
                  sx={{ fontWeight: 400, fontSize: 13 }}
                >
                  {head?.month?.oneMonth}
                </TableCell>
                <TableCell
                  style={{ height: "30px", padding: "4px 10px", color: "#fff" }}
                  key={index}
                  sx={{ fontWeight: 400, fontSize: 13 }}
                >
                  {head?.month?.threeMonth}
                </TableCell>
                <TableCell
                  style={{ height: "30px", padding: "4px 10px", color: "#fff" }}
                  key={index}
                  sx={{ fontWeight: 400, fontSize: 13 }}
                >
                  {head?.month?.sixMonth}
                </TableCell>
                <TableCell
                  style={{ height: "30px", padding: "4px 10px", color: "#fff" }}
                  key={index}
                  sx={{ fontWeight: 400, fontSize: 13 }}
                >
                  {head?.month?.twelveMonth}
                </TableCell>
                <TableCell
                  style={{ height: "30px", padding: "4px 10px", color: "#fff" }}
                  key={index}
                  sx={{ fontWeight: 400, fontSize: 13 }}
                >
                  {}
                </TableCell>
              </>
            ))}
          </TableRow>
        </TableHead>
        {FinalTableData?.map((rows, index) => {
          const a = Object.values(rows);
          return (
            <TableBody key={index}>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: 14,
                    paddingTop: 1,
                    paddingBottom: 1,
                  }}
                >
                  {rows?.planName}
                </TableCell>

                {rows?.radioGroup?.map(({ label, value, subLabel }) => (
                  <TableCell
                    sx={{
                      fontSize: 12,
                      paddingTop: 1,
                      paddingBottom: 1,
                    }}
                  >
                    {label ? (
                      <label className={props.styles.formControl}>
                        <p className={props.styles.labelPrice}>{`₹${label}`}</p>
                        {subLabel && (
                          <p
                            className={props.styles.subLabelPrice}
                          >{`(${subLabel} ${
                            subLabel > 1 ? "Sessions" : "Session"
                          } )`}</p>
                        )}

                        <div className={props.styles.radioBtn}>
                          <input
                            style={{ margin: "0 20px" }}
                            // checked={selectedValue === value}
                            onChange={(e) => handleChange(e)}
                            name={"radio"}
                            value={value}
                            type={"radio"}
                          />
                        </div>
                      </label>
                    ) : (
                      ""
                    )}
                  </TableCell>
                ))}

                <TableCell
                  sx={{
                    fontSize: 14,
                    paddingTop: 1,
                    paddingBottom: 1,
                  }}
                >
                  <Btn
                    click={rows?.bookNowBtn?.click}
                    variant="contained"
                    bgColor="#FFA800"
                    width="100px"
                    height="32px"
                    whiteSpace="nowrap"
                    //disabled={rows?.bookNowBtn?.disabled}
                    borderRadius="8px"
                  >
                    {rows?.bookNowBtn?.btnText}
                  </Btn>
                </TableCell>
              </TableRow>
            </TableBody>
          );
        })}
      </Table>
      {FinalTableData?.length === 0 && (
        <div
          style={{
            padding: "16px",
            textAlign: "center",
            display: props.message ? "block" : "none",
          }}
        >
          {props.message}
        </div>
      )}
    </TableContainer>
  );
};
