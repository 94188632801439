import * as React from "react";

import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import ButtonName from "../../../../MockData/ButtonName.json";

import QuestionData from "../../../../MockData/Questions.json";

import Instruction from "./Instruction";

import BasicBreadcrumbs from "../../../../Component/UI/BreadCrumb/BreadCrumbs";
import Details from "./Details";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BasicTabs from "../../../UI/Tab/Tabs";
import { ResultandScoring } from "./ResultScoring";
import { Questions } from "./Questions";
import { useEffect } from "react";
import { getAssessmentId } from "../../../../Redux/Slices/assessmentId";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function AddAssessment() {
  const { state } = useLocation();

  const flag = state?.from;
  console.log(state);

  const { cat_id } = useSelector((i) => i.assessmentId);

  const id = state?.ass_id || cat_id || state?.cid;

  const [error, setError] = React.useState({});
  const btnApi = ButtonName.addAssessment;
  const columns = Object.keys(QuestionData[0]);
  const dispatch = useDispatch();
  const { data } = useSelector((i) => i.assessmentDetails);
  const updateData = { ...data, id: id };
  const addData = { ...data, id: 0 };

  const [count, setCount] = React.useState(state?.count || 0);

  //TODO:Question

  useEffect(() => {
    return () => {
      dispatch(getAssessmentId(""));
    };
  }, []);

  const tabData = [
    {
      label: "Details",
      component: (
        <Details
          flag={flag}
          id={id}
          ass_id={state?.ass_id}
          setCount={setCount}
        />
      ),
      disabled: 1,
    },
    {
      label: "Instructions",
      component: (
        <Instruction
          id={id}
          flag={flag}
          setCount={setCount}
          ass_id={state?.ass_id}
        />
      ),
      disabled: id,
    },

    {
      label: "Questions",
      component: (
        <Questions
          id={id}
          flag={flag}
          setCount={setCount}
          ass_id={state?.ass_id}
        />
      ),
      disabled: id,
    },
    {
      label: "Result/Scoring",
      component: (
        <ResultandScoring id={id} flag={flag} ass_id={state?.ass_id} />
      ),
      disabled: id,
    },
  ];

  const links = [{ path: "/Assessments", linkText: "Assessments" }];

  return (
    <>
      <BasicBreadcrumbs
        link={links}
        text={`${state?.from === "edit" ? "Edit" : "Add"} Assessment`}
      >
        <BasicTabs
          tabcontent={tabData}
          value={count}
          setValue={setCount}
          allowDisable
        />
      </BasicBreadcrumbs>
    </>
  );
}
