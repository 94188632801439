import { createSlice } from "@reduxjs/toolkit";

const updateRolesAndPermissionsSlice = createSlice({
  name: "roles and permissions update",
  initialState: {data:[]  },
  reducers: {
    updateData: (state, action) => {
      state.data = action.payload
    },
  },
});

export const { updateData } =
updateRolesAndPermissionsSlice.actions;

export default updateRolesAndPermissionsSlice.reducer;
