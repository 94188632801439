import { createSlice } from "@reduxjs/toolkit";

const WeekFilterSlice = createSlice({
  name: "WeekFilterSlice",
  initialState: {
   selectWeek : '1',
   selectStaff : ''
  },
  reducers: {
    setSelectedWeek: (state, { payload }) => {
      state.selectWeek = payload;
    },
    setSelectedStaff: (state, { payload }) => {
      state.selectStaff = payload;
    },
  },
});

export const { setSelectedWeek, setSelectedStaff } = WeekFilterSlice.actions;

export default WeekFilterSlice.reducer;
