export const formattedLocations = (data, propertyName) => {
  const change = data?.map((i) => {
    return {
      ...i,
      value:
        i[propertyName].charAt(0).toUpperCase() +
        i[propertyName].slice(1).toLowerCase(),
    };
  });
  return change;
};
