import React from "react";
import { ServiceIcon } from "../../Components/Images/ServiceIcon";
import styles from "./servicecard.module.css";

export const ServiceCard = ({ name, description }) => {
  return (
    <div className={styles.service_card}>
      <div className={styles.icon}>
        <ServiceIcon styles={styles} />
      </div>
      <div className={styles.content}>
        <p className={styles.title}>{name}</p>
        {/* <p className={styles.amount}>₹2500</p> */}
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  );
};
