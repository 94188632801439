import React from "react";
import WeeklyCard from "../../UI/wellnessTracker/WeeklyCard";
import BasicTabs from "../../UI/BasicTabs";
import { getAllDaysInMonth } from "../../../config/formattedDate";
import { useSelector } from "react-redux";

const Weekly = () => {
  const { month, year } = useSelector((state) => state.MonthChangerSlice);

  const dates = getAllDaysInMonth(year, month);

  const ComponentName = [
    <WeeklyCard week={1} from={1} to={7} />,
    <WeeklyCard week={2} from={8} to={14} />,
    <WeeklyCard week={3} from={15} to={21} />,
    <WeeklyCard week={4} from={22} to={28} />,
    <WeeklyCard week={5} from={29} to={dates.at(-1).getDate()} />,
  ];
  const btnApi = [
    {
      week1: "Week 1",
      week2: "Week 2",
      week3: "Week 3",
      week4: "Week 4",
      week5: "Week 5",
    },
  ];

  return (
    <>
      <BasicTabs ComponentName={ComponentName} btnApi={btnApi} />
    </>
  );
};

export default Weekly;
