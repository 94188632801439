import React from "react";
import ReactApexChart from "react-apexcharts";
import styles from "../../../../assets/css/PatientDashboard/progresstrend.module.css";

export const Anxiety = () => {
  const series = [
    {
      type: "line", //render a line chart for this data
      name: "minimal",
      data: [],
      //   data: [25, 20, 25, 17, 36, 20],
    },
    {
      type: "line", //render a line chart for this data
      name: "moderate",
      data: [],
      //   data: [30, 10, 25, 15, 30, 10],
    },
    {
      type: "line", //render a line chart for this data
      name: "severe",
      data: [],
      //   data: [10, 20, 15, 18, 20, 18],
    },
  ];
  const options = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      stacked: true,
    },
    animations: {
      enabled: false, //no animations
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    title: {
      text: "Anxiety/Restlessness ",
      align: "left",
      style: {
        fontSize: "24px",
        fontWeight: 500,
        fontFamily: "Public Sans",
        marginTop: "10px",
      },
    },
    grid: {
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    },
    tooltip: {
      enabled: false,
    },

    yaxis: {
      show: true,

      labels: {
        formatter: function (value) {
          return value;
          return value <= 8 ? "😭" : "";
          //   ) : value <= 16 ? (
          //     Regular
          //   ) : value <= 24 ? (
          //     Grinning
          //   ) : value <= 32 ? (
          //     Frowning
          //   ) : (
          //     Crying
          //   );
        },
      },
      axisBorder: {
        show: true,
        color: "#F0F0F0",
        offsetX: 0,
        offsetY: 0,
      },
    },
    noData: {
      text: "No data is available to plot the graph",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
  };

  return (
    <div className={styles.mood_div}>
      <ReactApexChart
        className={styles.chart_div}
        options={options}
        type="line"
        series={series}
        height={310}
        // width="40%"
      />
    </div>
  );
};
