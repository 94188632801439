import React from "react";
import { Input, Select } from "../../../../Component/UI/CustomInputs";
import classes from "../../../../assets/css/assessmentsummary.module.css";

export const AssessmentScores = () => {
  return (
    <>
      <div>
        <Select label="Add Assessment" />
      </div>
      <div className={classes.score_container}>
        <div className={classes.score_container_title}>
          <p>HMSE (Hindi Mental Status Examination)</p>
        </div>
        <div className={classes.score_container_desc}>
          <p>
            Hindi Mental Status Examination is a brief quantitative measure of
            cognitive abilities to determine the severity of cognitive
            impairment. The HMSE looks at various cognitive domains including
            orientation to time and place, repetition, verbal recall, attention,
            calculation abilities and language.
          </p>
          <div className={classes.score_container_inputs}>
            <div>
              <Input label="Add Score" />
            </div>
            <div>
              <Input label="Checked On" />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.score_container}>
        <div className={classes.score_container_title}>
          <p>ZBI-22 (Zarit Burden Interview)</p>
        </div>
        <div className={classes.score_container_desc}>
          <p>
            Zarit Burden Interview is used to understand the level of stress
            experienced by a caregiver providing or managing care for the
            relative being assessed.
          </p>
          <div className={classes.score_container_inputs}>
            <div>
              <Input label="Add Score" />
            </div>
            <div>
              <Input label="Checked On" />
            </div>
            <div>
              <Select label="Checked On" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
