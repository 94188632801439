import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getGeneralService, getUnmappedServices } from "../../../services/enterprise/prelogin";
import { Carousel } from "./Components/Carousel";
import { ServiceCard } from "./Components/ServiceCard";

export const OurService = ({ styles }) => {
  const [services, setServices] = useState();

  useEffect(() => {
    getGeneralService()
      .then((res) => {
        res.data.list.length && setServices(res.data.list);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <div className={styles.ourService}>
      <h3>Our Services</h3>
      <div className={styles.slider}>
        {/* <CustomSlider data={services} Component={ServiceCard} slides={2} /> */}
        <div
          style={{
            display: services?.length > 2 ? "block" : "flex",
            justifyContent: "center",
          }}
        >
          {services?.length > 2 ? (
            <Carousel data={services} Component={ServiceCard} slides={2} />
          ) : (
            services?.map((item) => <ServiceCard {...item} item={item} />)
          )}
        </div>
      </div>
    </div>
  );
};
