import React from "react";
import classes from "../../assets/css/info.module.css";
import { InfoIconLeft } from "../../assets/icons/InfoIconLeft";
import { InfoIconRight } from "../../assets/icons/InfoIconRight";
import { TEXT } from "../contant/constant";

export const Info = ({ text, link, onClick }) => {
  return (
    <div className={classes.outer_infocard} onClick={onClick}>
      <div
        className={classes.infocard}
        style={{
          cursor:
            link === TEXT.PATIENTPROFILECARDDISABLED
              ? "not-allowed"
              : "pointer",
          background: link === TEXT.PATIENTPROFILECARDDISABLED && "#E7E7E7",
        }}
      >
        <div className={classes.icon_right}>
          <InfoIconRight />
        </div>

        <p>{text}</p>
        <div className={classes.icon_left}>
          <InfoIconLeft />
        </div>
      </div>
    </div>
  );
};
