import React from "react";
import { FeedbackCards } from "../../../Component/UI/FeedbackCards/FeedbackCards";
import { FeedbackPage } from "../../../Component/UI/FeedbackCards/FeedbackPage";

const FeedbackIndex = () => {
  return (
    <div>
      {/* <FeedbackCards /> */}
      <FeedbackPage />
    </div>
  );
};

export default FeedbackIndex;
