import React from "react";

const Sleep = () => {
  return (
    <div>
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.244 0.97998C7.86034 2.17121 7.66554 3.41516 7.66667 4.66665C7.66667 11.294 13.0393 16.6666 19.6667 16.6666C21.3567 16.6666 22.9647 16.3173 24.4227 15.6866C22.8673 20.5113 18.3413 24 13 24C6.37267 24 1 18.6273 1 12C1 7.06198 3.982 2.82131 8.244 0.97998V0.97998Z"
          stroke="#7A51AE"
          stroke-linejoin="round"
        />
        <path
          d="M18.107 2.6665H24.3337L17.667 7.99984H24.3337"
          stroke="#E69A8D"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default Sleep;
