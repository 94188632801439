import React, { useState } from "react";
import { Input, RadioChoice, Select } from "../../../Component/UI/CustomInputs";
import styles from "./form.module.css";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "../../../config/axiosConfig";
import { SubscriptionDetails } from "./TabComponents/SubscriptionDetails";
import { SubscriptionServices } from "./TabComponents/SubscriptionServices";
import { SubscriptionPricing } from "./TabComponents/SubscriptionPricing";
import BasicTabs from "../../UI/Tab/Tabs";
import { useLocation } from "../../../../node_modules/react-router-dom/index";
import { toast } from "../../../../node_modules/react-hot-toast/dist/index";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";
import { getPatientList } from "../../../services/admin/subscriptions";

export const Form = () => {
  const { state } = useLocation();
  const defaultServiceCardValues = { id: Date.now(), number_session: 1 };
  const [servicesCardData, setServicesCardData] = useState([
    defaultServiceCardValues,
  ]);

  const [enterpriseOptions, setEnterpriseOptions] = useState([]);
  const [serviceError, setServiceError] = useState([]);
  const [buisnessOptions, setBuisnessOptions] = useState();
  const [isErrorService, setIsErrorService] = useState();
  const [subscriptionData, setSubscriptionData] = useState({
    discount_type: "0",
    discount_percentage: 0,
    subscription_type: "0",
    payment_mode: "online",
  });
  // const [paymentMode, setPaymentMode] =useState ({
  //   payment_Mode : "0",
  //   remark : ""
  // })
  const [subscriptionServices, setSubscriptionServices] = useState();
  const [expiryDate, setExpiryDate] = useState(null);
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    setCount(state?.edit ? (expiryDate ? 2 : 0) : 0);
  }, [expiryDate]);

  useEffect(() => {
    if (!state?.edit)
      setSubscriptionData({
        ...subscriptionData,
        customer_id: "",
        enterprise_id: "",
      });
  }, [subscriptionData?.subscription_type]);

  const subscriptionForOptions = [
    {
      name: "General",
      value: 0,
    },
    {
      name: "Enterprise",
      value: 1,
    },
  ];
  const discountOptions = [
    {
      name: "Flat",
      value: "0",
    },
    {
      name: "Perct.",
      value: "1",
    },
  ];

  // Total Price
  const totalPriceArray = servicesCardData?.map(({ total_price }) =>
    Number(total_price || 0)
  );
  const subTotal = totalPriceArray.length
    ? totalPriceArray.reduce((acc, current) => acc + current)
    : 0;

  // Taxes Array
  const discountType = subscriptionData?.discount_type;

  const netDiscount =
    discountType === "2"
      ? (subTotal * subscriptionData?.discount_percentage) / 100
      : subscriptionData?.discount_percentage || 0;

  const taxes = servicesCardData.map(({ total_price, tax_value = 0 }) => {
    const effictivePrice = total_price - (total_price / subTotal) * netDiscount;
    const tax = (effictivePrice * tax_value) / 100;
    return tax || 0;
  });

  // Sum Total Tax
  const finalTax = taxes.length
    ? taxes.reduce((acc, current) => acc + current)
    : 0;

  const totalTax = finalTax.toFixed(2);

  const [err, setErr] = useState({});
  const finalPrice = subTotal - netDiscount + Number(totalTax);
  const netPayable = finalPrice.toFixed(2);
  const [extendedVal, setExtendedVal] = useState();
  const [is_extended, setIs_extended] = useState();
  const [patientEmail, setPatientEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [additionalAmt, setAdditionalAmt] = useState();
  const [patientsList, setPatientsList] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState({});

  console.log(
    subTotal - netDiscount + Number(totalTax),
    totalTax,
    netDiscount,
    subTotal,
    "total"
  );

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    getPatientList().then((res) => {
      const patientData = res?.data?.list?.map(({ id, name, email }) => {
        return { value: id, label: name, email };
      });
      setPatientsList(patientData);
    });
  }, []);

  useEffect(() => {
    const getPatientData = patientsList?.find(
      ({ value }) => value === subscriptionData?.customer_id
    );

    setSelectedPatient(getPatientData);
    setPatientEmail(getPatientData?.email ? getPatientData?.email : "");
  }, [patientsList, subscriptionData, selectedPatient]);
  const handleChange = (e) => {
    debugger;
    const { name, value } = e.target;
    if (value < 0) return;
    const obj = {};
    if (name === "discount_type") {
      switch (value) {
        case "0":
          obj.discount_percentage = 0;
          break;
        default:
          obj.discount_percentage = "";
      }
    }

    if (name === "validity") {
      setServicesCardData(
        servicesCardData.map((item) => {
          if (item.interval_type) {
            return { ...item, interval_type: "", minimum_validity: "" };
          }
          return item;
        })
      );
    }

    if (name === "payment_mode") {
      if (value === "0") {
        setSubscriptionData({
          ...subscriptionData,
          ...obj,
          [name]: "online",
          remark: "",
        });
      } else {
        setSubscriptionData({ ...subscriptionData, ...obj, [name]: "offline" });
      }
      return;
    }

    setSubscriptionData({ ...subscriptionData, ...obj, [name]: value });
  };

  // const handChangePayment_Mode = (e) => {
  //   const { name, value } = e.target;
  //   setPaymentMode({...paymentMode, [name]:value})
  // }

  useEffect(() => {
    axios.get("/admin/fetch/enterprise").then((res) => {
      if (res?.data?.status) {
        setEnterpriseOptions(
          res.data?.list.map(({ company_name, id }) => {
            return { id, value: company_name };
          })
        );
      }
    });

    axios.post("/admin/businessSegment").then((res) => {
      if (res?.data?.status) {
        setBuisnessOptions(
          res.data?.list.map(({ name, id }) => {
            return { id, value: name };
          })
        );
      }
    });

    if (state?.edit) {
      axios
        .get(`/admin/detail/subscription?subscription_id=${state?.id}`)
        .then((res) => {
          if (res?.data?.status) {
            setSubscriptionData(res?.data?.list);
            setExtendedVal(res?.data?.list.extended_validity);
            setIs_extended(res?.data?.list.is_extended);

            setAdditionalAmt(res?.data?.list.additional_amount);
            setServicesCardData(res?.data?.list?.subscriptionservice);
            setExpiryDate(res?.data?.list?.expiry_at);
          }
        });
    }
  }, []);

  useEffect(() => {
    if (subscriptionData?.status) {
      if (
        !subscriptionData?.additional_amount ||
        subscriptionData?.additional_amount === "0"
      ) {
        setSubscriptionData({
          ...subscriptionData,
          payment_mode: "",
        });
      } else {
        setSubscriptionData({
          ...subscriptionData,
          payment_mode: "online",
        });
      }
    }
  }, [subscriptionData?.additional_amount, subscriptionData?.status]);

  useEffect(() => {
    if (state?.edit) {
      if (
        !subscriptionData?.payment_mode ||
        subscriptionData?.payment_mode === null
      ) {
        setSubscriptionData({
          ...subscriptionData,
          payment_mode: "offline",
        });
      }
    }
  }, [state?.edit, subscriptionData?.payment_mode]);

  const serviceCardSelectedIds = servicesCardData.map(({ service_id }) =>
    service_id?.toString()
  );

  const disabledSelectedServices = (data) => {
    setSubscriptionServices(
      data?.map((item) =>
        serviceCardSelectedIds.includes(item.id.toString())
          ? { ...item, disabled: true }
          : { ...item, disabled: false }
      )
    );
  };

  useEffect(() => {
    disabledSelectedServices(subscriptionServices);
  }, [servicesCardData]);

  useEffect(() => {
    if (subscriptionData?.subscription_type) {
      axios
        .get(
          `/admin/subscription/services?subscription_type=${subscriptionData?.subscription_type}`
        )
        .then((res) => {
          if (res?.data?.status) {
            const subscriptionData = res.data?.list.map(
              ({ name, id, amount, requirement, type_of_service }) => {
                return {
                  id,
                  value: name,
                  amount,
                  requirement,
                  type_of_service,
                };
              }
            );
            setSubscriptionServices(subscriptionData);
            disabledSelectedServices(subscriptionData);
          }
        });
    }
  }, [subscriptionData?.subscription_type]);

  const totalAmount = servicesCardData.map(({ unit_price }) => unit_price);
  const totalSessions = servicesCardData.map(
    ({ number_session }) => number_session
  );

  const totalValidity = servicesCardData.map(
    ({ interval_type, number_session }) => {
      const intervalValues = {
        0: 1,
        1: 7,
        2: 30,
        3: 3.5,
        4: 2.3,
      };
      const intervalValue = intervalValues[interval_type];
      return Math.round(number_session * intervalValue);
    }
  );
  const maxValidity = Math.max(...totalValidity);
  console.log(maxValidity, "maxValidity");

  const sumTotalAmount =
    (totalAmount.length
      ? totalAmount.reduce((acc, current) => acc + current)
      : "") || "";

  //Error JSON object for Service Tab
  const serviceErrors = () => {
    return servicesCardData.map((item) => {
      const obj = {};
      if (!item.service_id) {
        obj.service_id = "The service field is required.";
      }
      if (item.tax_value === "" && item.tax_applicable === "1") {
        obj.tax_value = "The tax field is required.";
      }
      if (!item.number_session) {
        obj.number_session = "The number of session field is required.";
      }
      if (!item.interval_type) {
        obj.interval_type = "The interval type field is required.";
      }
      if (!item.duration) {
        obj.duration = "The duration field is required.";
      }

      return obj;
    });
  };
  console.log(defaultServiceCardValues, "defaultServiceCardValues");
  const saveSubscriptionData = () => {
    setServiceError([]);
    const isError = serviceErrors().filter((i) => Object.keys(i).length).length;
    setIsErrorService(isError);
    if (isError) {
      setServiceError(serviceErrors());
      return;
    }
    if (!patientEmail && subscriptionData?.subscription_type === "0") {
      setErr({
        email: "Email field is required",
      });
      return;
    }
    if (netDiscount > subTotal) {
      setErr({
        discount_percentage: "Discount should not be greater than Sub Total",
      });
      return;
    }
    if (subscriptionData?.payment_mode === "offline") {
      if (!subscriptionData?.remark) {
        setErr({
          remarks: "The Remarks field is required.",
        });
        return;
      }
    }

    if (subscriptionData?.validity < maxValidity) {
      setErr({
        validity:
          "Subscription validity should not be smaller than max service validity",
      });
      return;
    }

    if (subscriptionData?.status && !subscriptionData?.extended_validity) {
      setErr({
        extended_validity: "Extented validity filed is required ",
      });
      return;
    }
    if (netPayable < "1.00") {
      toast.error("Net Payable amount should be greater than 1. Please check.");
      // setErr({
      //   paymentMode:
      //     "Please Select Payment Mode Offline When Net Payable Amount Is less than 1.",
      // });
      return;
    }

    setIsloading(true);
    const apiData = {
      ...subscriptionData,
      total_service: servicesCardData?.length,
      sum_price: subTotal,
      net_discount: netDiscount,
      tax_amount: totalTax,
      net_price: netPayable,
      service: JSON.stringify(servicesCardData),
      ...(state?.edit && { subscription_id: state?.id }),
    };
    const subscriptionAPI = state?.edit
      ? "/admin/update/subscription"
      : "/admin/add/subscription";
    axios.post(subscriptionAPI, apiData).then((res) => {
      setIsloading(false);
      if (res?.data?.status) {
        toast.success(res?.data?.msg);
        navigate("/subscriptions");
      }
      setErr(res?.data?.error);
    });
  };

  const subscriptionDetailsError = [
    "name",
    "subscription_type",
    "enterprise_name",
    "business_segment",
    "validity",
    "mode",
    "email",
  ];

  const subscriptionPriceError = ["discount_percentage"];

  const errArr = Object.keys(err || {});
  const checkErr = (tabErr) => {
    const check = tabErr.map((i) => errArr.includes(i));
    return check.some((i) => i === true);
  };

  const subscriptionDetailCheck = checkErr(subscriptionDetailsError);
  const subscriptionPricingCheck = checkErr(subscriptionPriceError);
  const tabData = [
    {
      label: "Subscription Details",
      component: (
        <SubscriptionDetails
          handleChange={handleChange}
          enterpriseOptions={enterpriseOptions}
          subscriptionForOptions={subscriptionForOptions}
          buisnessOptions={buisnessOptions}
          subscriptionData={subscriptionData}
          error={err}
          expiryDate={expiryDate}
          edit={state?.edit}
          setPatientEmail={setPatientEmail}
          patientEmail={patientEmail}
          patientsList={patientsList}
          selectedPatient={selectedPatient}
          setSelectedPatient={setSelectedPatient}
          // emailError={emailError}
          // setEmailError={setEmailError}
        />
      ),
      error: subscriptionDetailCheck,
    },
    {
      label: "Services",
      component: (
        <SubscriptionServices
          amount={totalAmount}
          sessions={totalSessions}
          subscriptionData={subscriptionData}
          setServicesCardData={setServicesCardData}
          servicesCardData={servicesCardData}
          subscriptionServices={subscriptionServices}
          error={serviceError}
          expiryDate={expiryDate}
        />
      ),
      error: isErrorService,
    },
    {
      label: "Pricing",
      component: (
        <SubscriptionPricing
          discountOptions={discountOptions}
          servicesCardData={servicesCardData}
          subscriptionData={subscriptionData}
          handleChange={handleChange}
          sumTotalAmount={sumTotalAmount}
          subTotal={subTotal}
          totalTax={totalTax}
          discountType={discountType}
          netDiscount={netDiscount}
          netPayable={netPayable}
          error={err}
          expiryDate={expiryDate}
          extendedVal={extendedVal}
          is_extended={is_extended}
          additionalAmt={additionalAmt}
          // handChangePayment_Mode={handChangePayment_Mode}
          // paymentMode={paymentMode}
        />
      ),
      error: subscriptionPricingCheck,
    },
  ];

  let btnText =
    tabData.length - 1 === count ? (state?.edit ? "Update" : "Create") : "Next";

  const handleClick = () => {
    if (count < tabData.length - 1) {
      // if (!patientEmail && subscriptionData?.subscription_type === "0") {
      //   setEmailError("Email felid is required");
      //   return;
      // }
      setCount(count + 1);
    } else {
      saveSubscriptionData();
    }
  };

  console.log(subscriptionData, "subscriptionData---");

  return (
    <>
      <div className={styles.formContainer}>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div style={{ width: "auto" }}>
            <BasicTabs
              margin="0 12% 0 0"
              tabcontent={tabData}
              value={count}
              setValue={setCount}
              handleClick={() =>
                servicesCardData.length < 10 &&
                setServicesCardData([
                  ...servicesCardData,
                  defaultServiceCardValues,
                ])
              }
              btnText={count === 1 ? (expiryDate !== null ? "" : "Add") : ""}
              disabledBtn={servicesCardData.length >= 10}
            />
          </div>

          <div style={{ marginTop: "16px" }}>
            {isLoading && (
              <div style={{ textAlign: "center" }}>
                <CircularProgress size={30} />
                <p style={{ color: "black" }}>
                  Please wait ! We are validating the details...
                </p>
              </div>
            )}
            <button
              type="button"
              onClick={() => navigate("/subscriptions")}
              className={styles.addButtuon}
            >
              Cancel
            </button>
            {/* <Btn
              variant="contained"
              borderRadius="100px"
              bgColor="#2A2A2A"
              width="100px"
              margin="0 4px 0 0"
              click={() => navigate("/subscriptions")}
            >
              Cancel
            </Btn> */}
            <Btn
              variant="contained"
              borderRadius="100px"
              bgColor="#00B091"
              width="100px"
              margin="0 0 0 4px"
              click={handleClick}
              disabled={isLoading}
            >
              {btnText}
            </Btn>
          </div>
        </form>
      </div>
    </>
  );
};
