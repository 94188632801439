import { Paper } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Input, InputWithIcon } from "../../../Component/UI/CustomInputs";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import { Eye } from "../../../assets/icons/Eye";
import { password } from "../../../services/enterprise/password";
import { useState } from "react";
import toast, {
  Toaster,
} from "../../../../node_modules/react-hot-toast/dist/index";
import { useEffect } from "react";
import { passwordValidate } from "../../../config/passwordValidate";
import { EnterpriseTestimonial } from "../../Components/Testimonial/Testimonial";

export const Testimonial = () => {
  return (
    <>
      <Toaster />

      <EnterpriseTestimonial />
    </>
  );
};
