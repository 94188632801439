import React, { useContext } from "react";
import { useState } from "react";
import leftArrow from "../../Components/Images/Stress/leftArrow.svg";
import { Carousel } from "./Components/Carousel";
import mentalWellBeing from "./Images/mentalWellBeing.svg";
import dementiaScreening from "./Images/dementiaScreening.svg";
import caregiverBurden from "./Images/caregiverBurden.svg";
import { AssessmentCard } from "../../../Component/UI/AssessmentCard";
import { API } from "../../../config/constant";
import { useNavigate } from "../../../../node_modules/react-router-dom/index";
import { useEffect } from "react";
import axios from "../../../config/axiosConfig";
import { AssessmentCarousel } from "./Components/AssessmentCarousel";
import { AssessmentWrapper } from "../../../Samvenda-Patient/AssessmentNew/AssessmentWrapper";
import AuthContext from "../../../Auth/AuthContext";

export const Assesssment = ({ styles, start, setStart }) => {
  // const [start, setStart] = useState(false);
  const [assessmentsData, setAssessmentsData] = useState([]);
  const [categoryInfo, setCategoryInfo] = useState({});

  const { ASSESSMENTCATEGORYDETAIL } = API;
  const ctx = useContext(AuthContext);

  const is_loggedin = ctx?.loginUser?.is_loggedin;

  const navigate = useNavigate();
  useEffect(() => {
    fetchData();
  }, []);

  const handleStart = (e) => {
    setStart(true);
    setCategoryInfo(e);
  };

  const fetchData = async () => {
    await axios.get(ASSESSMENTCATEGORYDETAIL).then(async (res) => {
      setAssessmentsData(res.data.list);
    });
  };
  // const assessmentsData = [
  //   {
  //     name: "Dementia Screening",
  //     assessment_image: dementiaScreening,
  //     btnText : "Start"
  //   },
  //   {
  //     name: "Mental Wellbeing",
  //     assessment_image: mentalWellBeing,
  //     btnText : "Start"
  //   },
  //   {
  //     name: "Caregiver Burden",
  //     assessment_image: caregiverBurden,
  //     btnText : "Start"
  //   },
  //   {
  //       name: "Dementia Screening",
  //       assessment_image: dementiaScreening,
  //       btnText : "Start"
  //     },
  //     {
  //       name: "Mental Wellbeing",
  //       assessment_image: mentalWellBeing,
  //       btnText : "Start"
  //     },
  //     {
  //       name: "Caregiver Burden",
  //       assessment_image: caregiverBurden,
  //       btnText : "Start"
  //     },
  // ];

  const customProps = {
    btntext: "Details",
    height: "276px",
    btnbg: "#2A2A2A",
    handleBtn: (data) => {
      //   setPopup(true);
      //   setClickData(data);
    },
  };

  console.log(assessmentsData, "assessmentsData");

  return !start ? (
    <div className={styles.assessments}>
      <h3>Get Started with Your Self-Assessment</h3>
      <div className={styles.assessmentsDetails}>
        <div
          className={styles.slider}
          style={{
            display: assessmentsData.length > 2 ? "block" : "flex",
            justifyContent: "center",
          }}
        >
          <AssessmentCarousel
            data={assessmentsData}
            customProps={customProps}
            Component={AssessmentCard}
            slides={3}
            handleStart={handleStart}
          />
        </div>
        {/* Mobile Responsive */}
        {/* <div className={styles.responsive_carousel}>
          {assessmentsData ? (
            <Carousel data={assessmentsData} Component={AssessmentCard} slides={2} />
          ) : 
          null}
        </div> */}
      </div>
    </div>
  ) : (
    <AssessmentWrapper
      start={start}
      setStart={setStart}
      data={categoryInfo}
      user={is_loggedin}
    />
  );
};
