import styles from "../../assets/css/UI/glance.module.css";
import { Link } from "react-router-dom";

export const Glance = (props) => {
  return (
    <div className={styles.glance} styles={{ width: props.width }}>
      <div className={styles.header}>
        <h2>{props.title}</h2>
        {props.route && (
          <Link
            style={{ textDecoration: "none", color: "#7a51ae" }}
            to={props.route}
          >
            <p>View All</p>
          </Link>
        )}
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", height: "100%" }}
      >
        {props.children}
      </div>
    </div>
  );
};

Glance.defaultProps = {
  route: "",
};
