import React from "react";
import classes from "../../assets/css/iconshadow.module.css";

export const IconShadow = ({ size, Wsize, Hsize, color, icon }) => {
  return (
    <div
      className={classes.image_icon}
      style={{
        background: color,
        width: size || Wsize,
        height: size || Hsize,
      }}
    >
      {icon}
    </div>
  );
};
