import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Link,
  useLocation,
} from "../../../../../../node_modules/react-router-dom/index";
import { Avatar } from "../../../../../Component/UI/Avatar";
import SimpleBreadcrum from "../../../../../Component/UI/SimpleBreadcrum";
import { CustomTable } from "../../../../../Component/UI/Table";
import {
  convertCurrency,
  formatttedDate,
} from "../../../../../config/formattedDate";
import { getPatientSubscriptions } from "../../../../../services/admin/patient";
import { useSelector } from "react-redux";
import { findFunc } from "../../../../../config/methods";
import { usePermissions } from "../../../../../Hooks/usePermissions";

export const SubscriptionsPatient = () => {
  const { state } = useLocation();
  const [subscriptions, setSubscriptions] = useState([]);
  const customerId = state?.props || state?.data?.props;
  const customerName = state?.name || state?.data?.name;
  const permissions = usePermissions("name", "patients");

  const tableHeadings = [
    "Subscription Name",
    "Amount",
    "Date",
    "No of Services",
    "Payment Mode",
    "Remarks",
    "Validity",
    "Status",
  ];

  useEffect(() => {
    getPatientSubscriptions(customerId).then((res) => {
      if (res?.data?.status) {
        setSubscriptions(res?.data?.list);
      }
    });
  }, []);

  const tBodyData = subscriptions?.map((item) => {
    const {
      name,
      customer,
      subscriptionservice,
      net_price,
      created_at,
      payment_mode,
      remark,
      validity,
      status,
    } = item;
    return {
      name: (
        <>
          <Link
            to={"/patientsProfile/subscriptions/details"}
            style={{
              color: "#000",
              textDecoration: "none",
              display: "flex",
              pointerEvents: "auto",
            }}
            state={{
              item,
              customerId,
              customerName,
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                height: 0,
              }}
            >
              {
                <Avatar
                  size="28px"
                  profile={customer?.customer_image}
                  border="1px solid #ccc"
                />
              }
              <span style={{ marginLeft: "8px" }}>{name}</span>
            </span>
          </Link>
        </>
      ),
      amount: convertCurrency(net_price),
      date: formatttedDate(created_at),
      noOfServices: subscriptionservice?.length,
      payment_mode: payment_mode,
      remark: payment_mode === "offline" ? remark : "",
      validity:
        Number(validity) >= 30
          ? `${validity / 30} ${validity / 30 > 1 ? "Months" : "Month"}`
          : `${validity} Days`,
      status: status ? "Active" : "Inactive",
    };
  });

  console.log("subs", subscriptions);

  const links = [
    {
      path: "/Patients",
      linkText: "Patients",
    },
    {
      path: "/patientsProfile",
      linkText: customerName,
      data: {
        permissions,
        name: customerName,
        props: customerId,
      },
    },
  ];

  return (
    <>
      <SimpleBreadcrum text="Subscriptions" link={links} />
      <CustomTable
        tHead={tableHeadings}
        tBody={tBodyData}
        message={"No Subscriptions is available for this Patient."}
      />
    </>
  );
};
