import React, { useContext } from "react";
import { useState } from "react";
import leftArrow from "../../Components/Images/Stress/leftArrow.svg";
import { Carousel } from "./Components/Carousel";
import mentalWellBeing from "./Images/mentalWellBeing.svg";
import dementiaScreening from "./Images/dementiaScreening.svg";
import caregiverBurden from "./Images/caregiverBurden.svg";
import { AssessmentCard } from "../../../Component/UI/AssessmentCard";
import { API } from "../../../config/constant";
import { useNavigate } from "../../../../node_modules/react-router-dom/index";
import { useEffect } from "react";
import axios from "../../../config/axiosConfig";
import { AssessmentCarousel } from "./Components/AssessmentCarousel";
import { AssessmentWrapper } from "../../../Samvenda-Patient/AssessmentNew/AssessmentWrapper";
import AuthContext from "../../../Auth/AuthContext";
import { ClientCarousel } from "./Components/ClientCarousel";
import { ClientLogoCard } from "./Components/ClientLogocard";
import Accenture from "../../../assets/images/clients/accenture.svg";
import Cachedigitech from "../../../assets/images/clients/cachedigitech.svg";
import Cadilapharma from "../../../assets/images/clients/cadilapharma.svg";
import Emarson from "../../../assets/images/clients/emarson.svg";
import Intellivatetech from "../../../assets/images/clients/intellivatetech.svg";
import Roibypractus from "../../../assets/images/clients/roibypractus.svg";
import Successpact from "../../../assets/images/clients/successpact.svg";
import Teampumpkin from "../../../assets/images/clients/teampumpkin.svg";
import Tnspl from "../../../assets/images/clients/tnspl.svg";
import Urbanic from "../../../assets/images/clients/urbanic.svg";
import Vega from "../../../assets/images/clients/vega.svg";

export const ClientLogos = ({ styles, start, setStart }) => {
  // const [start, setStart] = useState(false);
  const [assessmentsData, setAssessmentsData] = useState([]);
  const [categoryInfo, setCategoryInfo] = useState({});

  const { ASSESSMENTCATEGORYDETAIL } = API;
  const ctx = useContext(AuthContext);

  const clientLogo = [
    {
      logo: Accenture,
    },
    {
      logo: Cachedigitech,
    },
    {
      logo: Cadilapharma,
    },
    {
      logo: Emarson,
    },
    {
      logo: Intellivatetech,
    },
    {
      logo: Roibypractus,
    },
    {
      logo: Successpact,
    },
    {
      logo: Teampumpkin,
    },
    {
      logo: Tnspl,
    },
    {
      logo: Urbanic,
    },
    {
      logo: Vega,
    },
  ];

  const is_loggedin = ctx?.loginUser?.is_loggedin;

  console.log(assessmentsData, "assessmentsData");

  return (
    <div className={styles.clientSlider}>
      <h3>Our Clients</h3>
      <div className={styles.clientSliderDetails}>
        <div
          id="ourClient"
          className={styles.slider}
          style={{
            display: clientLogo.length > 2 ? "block" : "flex",
            justifyContent: "center",
          }}
        >
          <ClientCarousel
            data={clientLogo}
            Component={ClientLogoCard}
            slides={4}
          />
        </div>
      </div>
    </div>
  );
};
