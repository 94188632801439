import { useState, Fragment, useEffect } from "react";

import styles from "../../assets/css/UI/customInputs.module.css";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AiOutlineCalendar } from "react-icons/ai";
import Checkbox from "@mui/material/Checkbox";

import Typography from "@mui/material/Typography";

export const AllSelect = (props) => {
  const {
    label,
    onChange,
    name,
    value,
    labelSize,
    options,
    error,
    placeholder,
    req,
    width,
    disabled,
    fontSize,
  } = props;
  return (
    <div
      className={styles.select}
      style={{
        fontSize: props.labelSize,
        margin: props.margin,
        width: props.width,
      }}
    >
      {label && (
        <>
          <label style={{ fontSize: labelSize }}>
            {label}
            <span style={{ color: "#D3232F" }}>{req ? "*" : null}</span>
          </label>
          <br />
        </>
      )}
      <select
        onChange={(e) => onChange(e)}
        name={name}
        value={value}
        disabled={disabled}
        className={props.className}
        style={{
          marginTop: props.marginTop,
          fontSize,
          backgroundColor: props.disabled
            ? "#EBEBEB"
            : props.backgroundColor || "#fff",
        }}
      >
        <option selected disabled value="">
          {placeholder || "Select"}
        </option>
        <option selected value="All">
          All
        </option>
        {options?.map(({ id, value, disabled, selected }, i) => (
          <Fragment key={i}>
            <option value={id} disabled={disabled} selected={selected}>
              {value}
            </option>
          </Fragment>
        ))}
      </select>
      <p style={{ color: "red", margin: "5px", fontSize: props.errorSize }}>
        {error?.toString()}
      </p>
    </div>
  );
};

export const Select = (props) => {
  const {
    label,
    onChange,
    name,
    value,
    labelSize,
    options,
    error,
    placeholder,
    req,
    width,
    disabled,
    fontSize,
  } = props;
  return (
    <div
      className={styles.select}
      style={{
        fontSize: props.labelSize,
        margin: props.margin,
        width: props.width,
      }}
    >
      {label && (
        <>
          <label style={{ fontSize: labelSize }}>
            {label}
            <span style={{ color: "#D3232F" }}>{req ? "*" : null}</span>
          </label>
          <br />
        </>
      )}
      <select
        onChange={(e) => onChange(e)}
        name={name}
        value={value}
        disabled={disabled}
        className={props.className}
        style={{
          marginTop: props.marginTop,
          fontSize,
          backgroundColor: props.disabled
            ? "#EBEBEB"
            : props.backgroundColor || "#fff",
        }}
      >
        <option selected disabled value="">
          {placeholder || "Select"}
        </option>
        {options?.map(({ id, value, disabled, selected }, i) => (
          <Fragment key={i}>
            <option value={id} disabled={disabled} selected={selected}>
              {value}
            </option>
          </Fragment>
        ))}
      </select>
      <p style={{ color: "red", margin: "5px", fontSize: props.errorSize }}>
        {error?.toString()}
      </p>
    </div>
  );
};

export const SelectWithId = (props) => {
  const {
    label,
    onChange,
    name,
    value,
    labelSize,
    options,
    error,
    placeholder,
    req,
    width,
    disabled,
    fontSize,
  } = props;
  return (
    <div
      className={styles.select}
      style={{
        fontSize: props.labelSize,
        margin: props.margin,
        width: props.width,
      }}
    >
      {label && (
        <>
          <label style={{ fontSize: labelSize }}>
            {label}
            <span style={{ color: "#D3232F" }}>{req ? "*" : null}</span>
          </label>
          <br />
        </>
      )}
      <select
        onChange={(e) => onChange(e)}
        name={name}
        value={value}
        disabled={disabled}
        className={props.className}
        style={{
          marginTop: props.marginTop,
          fontSize,
          backgroundColor: props.disabled
            ? "#EBEBEB"
            : props.backgroundColor || "#fff",
        }}
      >
        <option selected disabled value="">
          {placeholder || "Select"}
        </option>
        {options?.map(({ id, value, disabled, selected }, i) => (
          <Fragment key={i}>
            <option value={id} disabled={disabled} selected={selected}>
              {`${value} (${id})`}
            </option>
          </Fragment>
        ))}
      </select>
      <p style={{ color: "red", margin: "5px", fontSize: props.errorSize }}>
        {error?.toString()}
      </p>
    </div>
  );
};

export const Select2 = (props) => {
  // let err;
  // if (props.formErr && !props.value) {
  //   err = `${props.label} is Required`;
  // }
  return (
    <div
      className={styles.select}
      style={{ fontSize: props.labelSize, margin: props.margin }}
    >
      <label style={{ fontSize: props.labelSize }}>{props.label}</label>
      <br />
      <select
        onChange={(e) => {
          props.onChange(e);
        }}
        //onChange={props.onChange}
        // defaultValue={props.value}
        value={props.value}
      >
        <option value="" selected disabled>
          Select
        </option>
        {props.options?.map(({ id, value, name }, i) => (
          <Fragment key={i}>
            <option value={id}>{name}</option>
          </Fragment>
        ))}
      </select>
      <p style={{ color: "red", margin: "5px" }}>{props?.error?.toString()}</p>
    </div>
  );
};

export const SelectSelf = ({
  label,
  options,
  onChange,
  clickedData,
  labelSize,
  margin,
}) => {
  return (
    <div className={styles.select} style={{ fontSize: labelSize, margin }}>
      <label style={{ fontSize: labelSize }}>{label}</label>
      <br />
      <select onChange={onChange}>
        <option selected disabled>
          {clickedData || "Select"}
        </option>
        {options?.map((option) => (
          <option key={option.value} value={option.text}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
};
export const SelectType = ({
  option,
  value,
  onChange,
  labelSize,
  label,
  margin,
  disabled,
}) => {
  return (
    <div className={styles.select} style={{ margin }}>
      <label style={{ fontSize: labelSize }}>{label}</label>
      <br />
      <select value={value} onChange={onChange} disabled={disabled}>
        {option.map((item) => {
          return <option value={item}>{item}</option>;
        })}
      </select>
    </div>
  );
};
export const Input = (props) => {
  return (
    <div
      className={styles.input}
      style={{
        fontSize: props.labelSize,
        margin: props.margin,
        width: props.width,
      }}
    >
      {props.label && (
        <>
          <label
            style={{
              fontSize: props.labelSize,
              marginLeft: props.marginLeft,
              marginRight: props.marginRight,
              color: props.color,
            }}
          >
            {props.label}
            <span style={{ color: "#D3232F" }}>{props.req ? "*" : null}</span>
          </label>
          <br />
        </>
      )}
      <input
        style={{
          width: props.width,
          height: props.height,
          marginLeft: props.marginLeft,
          fontSize: props.fontSize,
          marginRight: props.marginRight,
          marginTop: props.marginTop,
          color: props.color,
          backgroundColor: props.disabled
            ? "#EBEBEB"
            : props.backgroundColor || "#fff",
        }}
        type={props.type}
        name={props.name}
        placeholder={props.placeholder || props.label}
        onChange={props.onChange}
        className={props.className}
        value={props.value}
        min={props.min}
        max={props.max}
        disabled={props.disabled}
        maxLength={props.maxlength}
        onWheel={(e) => e.target.blur()}
        onBlur={props.onBlur}
        autoComplete={props.autoComplete}
        // defaultValue={props.defaultValue}
      />
      <p
        style={{
          color: "red",
          fontSize: props.errorSize,
          margin: "5px",
          height: props.errorSize || props.errorHeight,
        }}
      >
        {props?.error?.toString()}
      </p>
    </div>
  );
};

export const Input2 = (props) => {
  let err;
  if (props.formErr && !props.value) {
    err = `${props.label} is Required`;
  }
  return (
    <div
      className={styles.input}
      style={{
        fontSize: props.labelSize,
        margin: props.margin,
        display: props.hidden ? "none" : "block",
      }}
    >
      <label
        style={{
          fontSize: props.labelSize,
          marginLeft: props.marginLeft,
          marginRight: props.marginRight,
        }}
      >
        {props.label}
      </label>
      <br />
      <input
        style={{
          width: props.width,
          height: props.height,
          marginLeft: props.marginLeft,
          marginRight: props.marginRight,
          backgroundColor: props.disabled
            ? "#EBEBEB"
            : props.backgroundColor || "#fff",
        }}
        type={props.type}
        name={props.name}
        placeholder={props.placeholder || props.label}
        onChange={props.onChange}
        value={props.value}
        onWheel={(e) => e.target.blur()}
        //defaultValue={props.defaultValue}
        disabled={props.disabled}
      />
      <p style={{ color: "red", margin: "5px" }}>
        {props?.error?.toString() || err}
      </p>
    </div>
  );
};

export const TextArea = (props) => {
  let err;
  if (props.formErr && !props.value) {
    err = `${props.label} is Required`;
  }
  return (
    <div
      className={styles.textarea}
      style={{ fontSize: props.labelSize, margin: props.margin }}
    >
      <label
        style={{
          fontSize: props.labelSize,
          marginLeft: props.marginLeft,
          marginRight: props.marginRight,
        }}
      >
        {props.label}
      </label>
      <br />
      <textarea
        style={{
          width: props.width,
          height: props.height,
          marginLeft: props.marginLeft,
          marginRight: props.marginRight,
          resize: "none",
        }}
        type={props.type}
        name={props.name}
        placeholder={props.placeholder || props.label}
        onChange={props.onChange}
        value={props.value}
        maxlength={props.maxlength}
        //defaultValue={props.defaultValue}
      />
      <p style={{ color: "red", margin: "5px" }}>
        {props?.error?.toString() || err}
      </p>
    </div>
  );
};

export const InputWithIcon = (props) => {
  const [change, setChange] = useState(false);
  return (
    <div
      className={styles.inputWithIcon}
      style={{ fontSize: props.labelSize, margin: props.margin }}
    >
      <label
        style={{
          fontSize: props.labelSize,
          marginLeft: props.marginLeft,
          marginRight: props.marginRight,
        }}
      >
        {props.label}
        <span style={{ color: "#D3232F" }}>{props.req ? "*" : null}</span>
      </label>
      <br />
      <div>
        <input
          style={{
            width: props.width,
            height: props.height,
            marginLeft: props.marginLeft,
            marginRight: props.marginRight,
          }}
          type={change ? "text" : props.type}
          name={props.name}
          placeholder={props.placeholder || props.label}
          onChange={props.onChange}
          value={props.value}
        />
        <div>{<props.icon change={change} setChange={setChange} />}</div>
      </div>
      <p style={{ color: "red", fontSize: props.errorSize, marginTop: "4px" }}>
        {props?.error}
      </p>
    </div>
  );
};

export const CheckBox = (props) => {
  const [check, setCheck] = useState(props.check);
  useEffect(() => {
    setCheck(props.check);
  }, [props]);
  return (
    <div
      className={styles.checkbox}
      style={{ fontSize: props.labelSize, margin: props.margin }}
    >
      <Checkbox
        sx={{
          color: "#DADADA",
          "&.Mui-checked": {
            color: "#7A51AE",
          },
        }}
        checked={check}
        onClick={() => {
          props.click?.(!check);
          setCheck(!check);
        }}
        value={props.value}
      />
      <label style={{ fontWeight: props.boldness, fontSize: props.fontSize }}>
        {props.value}
      </label>
    </div>
  );
};

export const CheckBox2 = ({
  id,
  type,
  name,
  handleClick,
  isChecked,
  value,
}) => {
  // const [check, setCheck] = useState(props.check);
  return (
    <div
      className={styles.checkbox}
      // style={{ fontSize: props.labelSize, margin: props.margin }}
    >
      <Checkbox
        sx={{
          color: "#DADADA",
          "&.Mui-checked": {
            color: "#7A51AE",
          },
        }}
        onChange={handleClick}
        checked={isChecked}
        value={value}
        name={name}
        id={id}
      />
      <label>{value}</label>
    </div>
  );
};
export const Datepicker = (props) => {
  const [value, setValue] = useState(props.defaultDate || null);

  useEffect(() => {
    props.defaultDate && setValue(props.defaultDate);
  }, [props.defaultDate]);

  return (
    <>
      <div
        style={{
          fontSize: props.labelSize,
          margin: props.margin,
          display: props.display,
          alignItems: props.alignItems,
          width: props.width,
        }}
        className={styles.directionset}
      >
        <div
          className={styles.setdatepicker}
          style={{ display: props.display }}
        >
          {props.enableLabel ? (
            <label>{props.label}</label>
          ) : (
            <div style={{ marginBottom: "0" }}>
              {props?.label}
              <span style={{ color: "#D3232F" }}>{props.req ? "*" : null}</span>
            </div>
          )}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={value}
              inputFormat="dd/MM/yyyy"
              minDate={props.minDate}
              maxDate={props.maxDate}
              disabled={props.disabled}
              onChange={(value) => {
                setValue(value);
                props.onChange(value);
              }}
              components={{
                OpenPickerIcon: AiOutlineCalendar,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "DD/MM/YYYY",
                  }}
                  onFocus={(e) => (e.target.readOnly = props.editable)}
                  style={{
                    marginTop: props.enableLabel ? "6px" : null,
                    background: "white",
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <div style={{ marginTop: "5px", color: "red" }}>
          {props?.error ? props?.error : null}
        </div>
      </div>
    </>
  );
};
export const CustomRadio = ({ text, values, checked, click, margin }) => {
  // const [selected, setSelected] = useState(checked);

  return (
    <div className={styles.radio} style={{ margin: margin }}>
      <Typography variant="subtitle1">{text}</Typography>
      <div className={styles.options}>
        {values?.map(({ id, value }, i) => {
          return (
            <div key={i}>
              <input
                type="radio"
                id={value}
                name="r"
                checked={id === checked}
                onClick={() => {
                  // checked(id);
                  click(id);
                }}
              />
              <label htmlFor={value}> {value} </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const Datepicker2 = (props) => {
  const [value, setValue] = useState(props.defaultDate || null);
  return (
    <>
      <div style={{ margin: "0 0 0 9px" }}>
        <div>
          <label style={{ marginBottom: "0", fontSize: "14px" }}>
            {props?.label}
          </label>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={value}
              inputFormat="dd/MM/yyyy"
              minDate={props.minDate}
              onChange={(value) => {
                setValue(value);
                props.onChange(value);
              }}
              components={{
                OpenPickerIcon: AiOutlineCalendar,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "DD/MM/YYYY",
                    style: { height: "10px" },
                  }}
                  onFocus={(e) => (e.target.readOnly = props.editable)}
                  style={{
                    width: "320px",
                    borderRadius: "8px",
                    marginTop: "4px",
                    backgroundColor: "#fff",
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <div style={{ marginTop: "5px", color: "red" }}>
          {props?.error ? props?.error : null}
        </div>
      </div>
    </>
  );
};

export const InputWithText = (props) => {
  return (
    <div
      className={styles.inputWithIcon}
      style={{ fontSize: props.labelSize, margin: props.margin }}
    >
      <label
        style={{
          fontSize: props.labelSize,
          marginLeft: props.marginLeft,
          marginRight: props.marginRight,
        }}
      >
        {props.label}
      </label>
      <br />
      <div style={{ width: "100%" }}>
        <input
          style={{
            width: props.width,
            height: props.height,
            marginLeft: props.marginLeft,
            marginRight: props.marginRight,
          }}
          type={props.type}
          name={props.name}
          value={props.value}
          placeholder={props.placeholder || props.label}
          onChange={props.onChange}
          onWheel={(e) => e.target.blur()}
          readOnly
        />
        <div className={styles.text}>
          <div style={{ background: props.dot }}></div>
          <p style={{ width: "max-content" }}>{props.bmiText}</p>
        </div>
      </div>
      <p style={{ color: "red" }}>{props?.error}</p>
    </div>
  );
};

export const MultiSelect = (props) => {
  const {
    label,
    onChange,
    name,
    value,
    labelSize,
    options,
    error,
    placeholder,
    req,
    handleClick,
    isChecked,
  } = props;
  return (
    <div
      className={styles.select}
      style={{
        fontSize: props.labelSize,
        margin: props.margin,
        width: props.width,
      }}
    >
      {label && (
        <>
          <label style={{ fontSize: labelSize }}>
            {label}
            <span style={{ color: "#D3232F" }}>{req ? "*" : null}</span>
          </label>
          <br />
        </>
      )}
      <select onChange={(e) => onChange(e)} name={name} value={value}>
        <option selected disabled value="">
          {placeholder || "Select"}
        </option>
        {options?.map(({ id, value }, i) => {
          return (
            <Fragment key={i}>
              <Checkbox
                sx={{
                  color: "#DADADA",
                  "&.Mui-checked": {
                    color: "#7A51AE",
                  },
                }}
                onChange={handleClick}
                checked={isChecked}
                value={value}
                name={name}
                id={id}
              />
              <label>{value}</label>
            </Fragment>
          );
        })}
      </select>
      <p style={{ color: "red", margin: "5px" }}>{error?.toString()}</p>
    </div>
  );
};

export const RadioChoice = ({
  onChange,
  label,
  options,
  req,
  inputName,
  labelSize,
  disabled,
  index,
}) => {
  return (
    <div style={{ justifyContent: "space-between" }}>
      <div style={{ marginLeft: "12px" }}>
        <p style={{ fontSize: labelSize }}>
          {label}
          {req && <span style={{ color: "#D3232F" }}>*</span>}
        </p>
        <div style={{ display: "flex" }}>
          {options?.map(({ name, value, checked }) => {
            return (
              <div
                style={{
                  display: "flex",
                  marginTop: 20,
                  marginRight: 8,
                }}
              >
                <input
                  type="radio"
                  id={inputName + index}
                  name={inputName + index}
                  value={value}
                  style={{ outline: "none" }}
                  onChange={onChange}
                  disabled={disabled}
                  checked={checked}
                  // checked={detail?.created_for.toString() === "0"}
                  // disabled={catID}
                />
                <label style={{ marginLeft: 4 }} for={name}>
                  {name}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const Time = ({
  label,
  name,
  placeholder,
  onChange,
  labelSize,
  value,
  width,
  error,
  req,
  min,
  disabled,
}) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className={styles.input} style={{ width }}>
        <label
          style={{
            fontSize: labelSize,
          }}
        >
          {label}
          <span style={{ color: "#D3232F" }}>{req ? "*" : null}</span>
        </label>
        <input
          name={name}
          placeholder={label || placeholder}
          type="time"
          value={value}
          min={min}
          onChange={onChange}
          style={{
            backgroundColor: disabled ? "#EBEBEB" : "#fff",
          }}
          disabled={disabled}
        />
      </div>
      <p style={{ color: "red", margin: "5px" }}>{error?.toString()}</p>
    </div>
  );
};

export const SelectCard = ({
  options,
  label,
  style,
  labelstyle,
  onChange,
  name,
  value,
  flag,
  error,
  titleMargin,
  width,
  optionStyle,
  disabled,
}) => {
  return (
    <div className={styles.accordion_opt} style={{ width }}>
      <h3 className={styles.accordion_title} style={{ margin: titleMargin }}>
        {label}
      </h3>
      <div className={styles.accordion_flex} style={style}>
        {options?.map((option, i) => (
          <>
            <label className={styles.label} style={labelstyle}>
              <input
                type="radio"
                name={name}
                value={i}
                checked={value === i.toString()}
                onChange={onChange}
                disabled={disabled}
              />
              <div style={optionStyle} key={i}>
                {option}
              </div>
            </label>
          </>
        ))}
      </div>
      <p style={{ color: "red", margin: "5px" }}>{error?.toString()}</p>
    </div>
  );
};

export const Duration = (props) => {
  const getValue = (i) => props.value?.split(":").at(i);

  const [hh, setHH] = useState(getValue(0) || "00");
  const [mm, setMM] = useState(getValue(-1) || "00");

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const inputStyle = {
    border: "none",
    outline: "none",
    fontSize: 16,
    padding: 8,
    borderRadius: 8,
    height: "100%",
  };

  const inputProps = {
    type: "number",
    onKeyPress: blockInvalidChar,
    disabled: props.disabled,
  };

  return (
    <div
      style={{
        fontSize: props.labelSize,
        margin: props.margin,
        width: props.width,
      }}
      className={styles.duration}
    >
      <label style={{ fontSize: props.labelSize }}>
        {props.label}
        {props.req ? <span style={{ color: "#D3232F" }}>*</span> : null}
      </label>
      <div
        style={{
          border: "1px solid #dadada",
          borderRadius: 8,
          backgroundColor: props.disabled
            ? "#EBEBEB"
            : props.backgroundColor || "#fff",
          height: 42,
          margin: "6px 0",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 166,
            height: "100%",
            backgroundColor: props.disabled
              ? "#EBEBEB"
              : props.backgroundColor || "#fff",
            borderRadius: 8,
          }}
        >
          <input
            style={{ ...inputStyle, width: 70 }}
            {...inputProps}
            max={23}
            value={hh}
            onChange={(e) => {
              const rgx = /^(0?[0-9]|1?[0-9]|2?[0-3]?)$/;
              const val = e.target.value;
              const check = rgx.test(val);
              if (check) {
                setHH(val);
                props.onChange(val + ":" + mm);
              } else {
                setHH("00");
                props.onChange(`00 : ${mm}`);
              }
            }}
          />
          <p>:</p>
          <input
            style={{ ...inputStyle, textAlign: "center", width: 80 }}
            {...inputProps}
            max={59}
            value={mm}
            onChange={(e) => {
              const rgx = /^(0?[0-9]|[1-5]?[0-9]?)$/;
              const val = e.target.value;
              const check = rgx.test(val);
              if (check) {
                setMM(val);
                props.onChange(hh + ":" + val);
              } else {
                setMM("00");
                props.onChange(`${hh} : 00`);
              }
            }}
          />
        </div>
      </div>
      <p style={{ color: "red", margin: "5px" }}>{props.error?.toString()}</p>
    </div>
  );
};

export const RadioCard = ({
  options,
  label,
  style,
  labelstyle,
  onChange,
  name,
  value,
  flag,
  error,
  titleMargin,
  width,
  optionStyle,
  disabled,
}) => {
  return (
    <div className={styles.accordion_opt} style={{ width }}>
      <div className={styles.accordion_title} style={{ margin: titleMargin }}>
        {label}
      </div>
      <div className={styles.accordion_flex} style={style}>
        {options?.map((option, i) => (
          <>
            <label className={styles.label} style={labelstyle}>
              <div
                style={{
                  ...optionStyle,
                  background: value === i.toString() ? "#f19204" : "#fff",
                  color: value === i.toString() ? "#fff" : "#000",
                  height: 46,
                  borderRadius: 8,
                  border: "1px solid #dadada",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 14,
                  fontSize: 14,
                  fontWeight: 300,
                }}
                key={i}
              >
                {option}
              </div>
            </label>
          </>
        ))}
      </div>
      <p style={{ color: "red", margin: "5px" }}>{error?.toString()}</p>
    </div>
  );
};

export const NativeDatePicker = (props) => {
  const { value, name, onChange, label, error, max, width } = props;
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <label>{label}</label>
      <input
        type="date"
        name={name}
        value={value}
        onChange={onChange}
        // min={moment().format("YYYY-MM-DD")}
        max={max}
        style={{
          width: width ? width : "250px",
          borderRadius: "8px",
          height: "40px",
          outline: "none",
          border: "1px solid #dadada",
          fontSize: "16px",
          marginTop: "8px",
          paddingLeft: "8px",
        }}
      />
      <p style={{ color: "red", margin: "5px" }}>{error?.toString()}</p>
    </div>
  );
};
