import React from "react";
import { usePermissions } from "../../../../Hooks/usePermissions";
import { findFunc } from "../../../../config/methods";
import { Toaster } from "../../../../../node_modules/react-hot-toast/dist/index";
import { SimpleHeader } from "../../../../Hooks/SimpleHeader";
import { CustomTable } from "../../../../Component/UI/Table";
import {
  changeStatusAnnouncement,
  deleteAnnouncement,
  getBannerImg,
} from "../../../../services/admin/patient";
import { useState } from "react";
import { useEffect } from "react";
import moment from "../../../../../node_modules/moment/moment";
import { Avatar } from "../../../../Component/UI/Avatar";
import { Trash } from "../../../../assets/icons/Trash";
import Popup from "../../../../Component/UI/Popup/Popup";
import SwitchButton from "../../../../Component/UI/SwitchButton/Switch";

export const AnnouncementMgt = () => {
  const [popupAction, setPopupAction] = useState({ open: false });
  const [bannerList, setBannerList] = useState([]);

  const links = [
    {
      path: "/Samvedna",
      linkText: "Samvedna",
    },
    // {
    //   path: "/roster",
    //   linkText: "Roster Management",
    //   data: state,
    // },
  ];
  console.log(bannerList, "bannerList");
  useEffect(() => {
    getBannerList();
  }, []);

  const getBannerList = () => {
    getBannerImg().then((res) => {
      if (res?.data?.status) {
        setBannerList(res?.data?.list);
      }
    });
  };

  const permissions = usePermissions("name", "samvedna");
  const rosterPermissions = findFunc(
    permissions?.subCategories,
    "listName",
    "Roster Management"
  );

  const handleDelete = (id) => {
    setPopupAction({
      ...popupAction,
      open: true,
      action: () => {
        deleteAnnouncement(id).then((res) => {
          const data = res?.data;
          if (data?.status) {
            getBannerList();
            setPopupAction({ ...popupAction, open: false });
          }
        });
      },
    });
  };
  const tBodyData = bannerList?.map((item) => {
    const { id, banner_type, banner_url, created_at, status } = item;

    const createdAt = moment(created_at).format("DD/MM/YYYY");

    return {
      banner_type: +banner_type === 0 ? "Desktop Banner" : "Mobile Banner",
      banner_url: (
        <Avatar size="28px" profile={banner_url} border="1px solid #ccc" />
      ),
      createdAt,
      status: (
        <>
          <SwitchButton
            status={status}
            onClick={(value) => changeStatusAnnouncement(id, value)}
          />
        </>
      ),
      action: (
        <div style={{ display: "flex" }}>
          <div style={{ cursor: "pointer" }} onClick={() => handleDelete(id)}>
            <Trash />
          </div>
        </div>
      ),
    };
  });

  return (
    <div>
      <Toaster />
      <Popup
        popupAction={popupAction}
        setPopupAction={setPopupAction}
        heading="Delete Banner"
        title="Are you sure you want to delete this Banner?"
        styles={{ width: 400, height: 230 }}
        btnMargin="0 10px"
      />
      <SimpleHeader
        text="Content Management"
        btnText={"Add"}
        links={links}
        basic
        noPaddingTop
        roles={true}
        rosterPermissions={rosterPermissions}
      >
        <CustomTable
          tHead={[
            "Banner Type",
            "Banner Image",
            "Created Date ",
            "Status",
            "Action",
          ]}
          tBody={tBodyData}
        />
      </SimpleHeader>
    </div>
  );
};
