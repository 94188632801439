import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const { ADDASSESSMENTQUEST, UPDATEASSESSMENTQUEST, DELETEASSESSMENTQUESTION, QUESTION_SERIALIZATION, QUESTION_SERIALIZATION_ASK } =
  API;

export const addAssessmentQuest = async (data) => {
  return await axios
    .post(ADDASSESSMENTQUEST, data)
    .then((res) => res)
    .catch((err) => err);
};

export const editAssessmentQuest = async (data) => {
  return await axios
    .post(UPDATEASSESSMENTQUEST, data)
    .then((res) => res)
    .catch((err) => err);
};

export const delAssessmentQuest = async (quest_id) => {
  return await axios
    .post(DELETEASSESSMENTQUESTION + quest_id)
    .then((res) => res)
    .catch((err) => err);
};

export const questionSerialization = async (data) => {
  return await axios
    .post(QUESTION_SERIALIZATION, data)
    .then((res) => res)
    .catch((err) => err);
};

export const questionSerializationAsk = async (data) => {
  return await axios
    .post(QUESTION_SERIALIZATION_ASK, data)
    .then((res) => res)
    .catch((err) => err);
};

