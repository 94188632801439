import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { Input, Select } from "../../../../Component/UI/CustomInputs";
import TextEditor from "../../../../Hooks/TextEditor";
import BasicBreadcrumbs from "../../../../Component/UI/BreadCrumb/BreadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import {
  clearEmailTemplate,
  setEmailTemplate,
} from "../../../../Redux/Slices/AdminSamvedna/EmailTemplateForm";

const EmailTemplateForm = ({ click }) => {
  const options = [
    { id: 1, value: "Welcome" },
    { id: 2, value: "Forgot Password" },
    { id: 3, value: "Appointment booked" },
    { id: 4, value: "Events Enrolled" },
    { id: 5, value: "Event send by Admin" },
    { id: 6, value: "Event Reminder" },
    { id: 7, value: "Birthday Email" },
    { id: 8, value: "Activity Reminder" },
  ];

  const dispatch = useDispatch();
  const { email } = useSelector((state) => state.EmailTemplateForm);

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(setEmailTemplate({ [name]: value }));
  };

  useEffect(() => {
    return () => dispatch(clearEmailTemplate());
  }, []);

  return (
    <>
      <h1>Add Email Template</h1>
      {/* <BasicBreadcrumbs link={"Notification Management > Email > Add Email Template"} text={"Add Email Template"}> */}
      <div style={{ paddingTop: 20 }}>
        <Paper
          elevation={1}
          style={{
            position: "absolute",
            top: "150px",
            width: "82%",
            left: "260px",
            height: "70%",
            padding: "25px 30px",
          }}
        >
          <div
            style={{
              width: "60%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Select
                name="type"
                label="Name"
                value={email?.value}
                options={options}
                onChange={handleChange}
              />
            </div>
            <div>
              <Input
                name="subject"
                value={email?.subject}
                label="Subject"
                onChange={handleChange}
              />
            </div>
          </div>

          <div style={{ marginTop: "25px" }}>
            <div>Description</div>
            <TextEditor />
          </div>
          <div
            className="addSmsBtn"
            style={{ position: "absolute", bottom: 20 }}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: "#2A2A2A",
                borderRadius: 50,
                marginRight: 10,
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: "#00B091", borderRadius: 50 }}
              onClick={click}
            >
              Create
            </Button>
          </div>
        </Paper>
      </div>
      {/* </BasicBreadcrumbs> */}
    </>
  );
};

export default EmailTemplateForm;
