import React from "react";
import { MdMenu } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { Btn } from "../../Samvenda-Patient/UI/Button";
import { MonthPicker } from "../../Samvenda-Patient/UI/monthPicker/MonthPicker";
import { requestAssessmentChange } from "../../services/enterprise/assessment";
import SimpleBreadcrum from "../UI/SimpleBreadcrum";
import styles from "./enterpriseHeader.module.css";
import Popup from "../../Samvedna-Enterprise/DashboardPages/Assessment/LinkPopup";
import RequestSentIcon from "../../assets/icons/RequestSentIcon";
import { useState } from "react";
import { CircularProgress } from "../../../node_modules/@mui/material/index";
import { AssessmentFeedbackPop } from "../../Samvedna-Enterprise/Components/Assessments/AssessmentFeedbackPop";
import { useAuth } from "../../Auth/AuthProvider";

export const EnterpriseHeader = ({
  title,
  setDrawer,
  drawer,
  hidden,
  link,
  btnText,
}) => {
  const { state } = useLocation();
  const [popupAction, setPopupAction] = React.useState({
    open: false,
  });
  const [popup, setPopup] = useState(false);

  const getCtxData = useAuth();
  const companyName = getCtxData.loginUser.enterprise.lead.company_name;

  return (
    <>
      <Popup
        popupAction={popupAction}
        setPopupAction={setPopupAction}
        children={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: "18px 10px",
              gap: "10px",
            }}
          >
            <h2>Request Sent</h2>
            <p> We will connect you shortly</p>
          </div>
        }
        icon={<RequestSentIcon />}
      />
      <AssessmentFeedbackPop
        popup={popup}
        setPopup={setPopup}
        assissement_id={state?.id}
        popupAction={popupAction}
        setPopupAction={setPopupAction}
      />

      <div
        className={styles.headerContainer}
        style={{
          justifyContent: hidden ? "start" : null,
        }}
      >
        <div className={styles.MenuEnable} onClick={() => setDrawer(!drawer)}>
          <MdMenu size={32} />
        </div>
        {!hidden ? (
          <>
            <p className={styles.title}>
              {title?.includes("Dashboard")
                ? `${companyName} Dashboard`
                : `${companyName} `}
              {title?.includes("Dashboard") ? null : title}
            </p>
            <div>
              <MonthPicker />
            </div>
          </>
        ) : (
          <div>
            <SimpleBreadcrum
              text={
                ["Account", "Help & Support"].includes(title)
                  ? `${companyName} ${title}`
                  : title
              }
              link={link}
            />
          </div>
        )}
      </div>
      {btnText ? (
        <div className={styles.request_for_change}>
          <Btn
            variant="contained"
            bgColor="#3592FF"
            width="100px"
            height="34px"
            whiteSpace="nowrap"
            borderRadius="100px"
            click={() => {
              setPopup(true);
            }}
          >
            {btnText}
          </Btn>
        </div>
      ) : null}
    </>
  );
};
