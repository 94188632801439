import React from "react";
import PaperWrapper from "../../../../Component/UI/PaperWrapper/PaperWrapper";
import Grid from "@mui/material/Grid";
import { SelfCareCards } from "../../../../Component/UI/SelfCareCards/SelfCareCards";
import { useEffect } from "react";
import classes from "./SelfCarePlan.module.css";
import { TabView } from "../../../../Samvenda-Patient/UI/TabView";
import Monthly from "./wellnessTracker/Monthly";

import MonthChanger from "./wellnessTracker/MonthChanger";
import { useDispatch } from "react-redux";
import {
  setMonth,
  setYear,
} from "../../../../Redux/Slices/WellnessTracker/monthChangerSlice";
import { Helmet } from "react-helmet";
import { Toaster } from "../../../../../node_modules/react-hot-toast/dist/index";
import { useLocation } from "../../../../../node_modules/react-router-dom/index";
import { ADMIN_ROUTES } from "../../../../Routes/routesConstants";
import SimpleBreadcrum from "../../../../Component/UI/SimpleBreadcrum";
import { usePermissions } from "../../../../Hooks/usePermissions";
import { SubHeader } from "../../../../Hooks/SubHeader";
export const SelfCarePlan = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { props, name, data } = location.state;
  const permissions = usePermissions("name", "patients");
  const { state } = useLocation();
  useEffect(() => {
    return () => {
      dispatch(setMonth(new Date().getMonth()));
      dispatch(setYear(new Date().getFullYear()));
    };
  }, []);

  const links = [
    {
      path: ADMIN_ROUTES.PATIENTS,
      linkText: "Patients",
    },
    {
      path: ADMIN_ROUTES.PATIENT_PROFILE,
      linkText: state?.name,
      data: { ...state, permissions },
    },
  ];

  return (
    <>
      <SimpleBreadcrum link={links} text="Self Care Plan" noHeading />
      <div className={classes.selfcare_plan}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} sm={12} lg={12} sx={{ height: "100%" }}>
            <PaperWrapper flag="100%" style={{ borderRadius: "10px" }}>
              <div className={classes.monthContainer}>
                <h3
                  style={{
                    margin: "30px 0",
                    fontWeight: 500,
                    fontSize: "24px",
                    padding: "0 15px",
                  }}
                >
                  Wellness Tracker
                </h3>
                <MonthChanger />
              </div>

              <div style={{ padding: "0 15px" }}>
                <Monthly id={props} />
                {/* <TabView
                  values={[{ label: "Monthly", component: <Monthly /> }]}
                  calendar
                /> */}
              </div>
            </PaperWrapper>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
