import React from 'react'
import TableCell from '@mui/material/TableCell';

const TableHead = ({item}) => {
  return (
    <>
      <TableCell align="left">{item.toUpperCase()}</TableCell>
    </>
  )
}

export default TableHead