import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "../../../../../../node_modules/moment/moment";
import { useNavigate } from "../../../../../../node_modules/react-router-dom/index";
import { Trash } from "../../../../../assets/icons/Trash";
import { Avatar } from "../../../../../Component/UI/Avatar";
import Popup from "../../../../../Component/UI/Popup/Popup";
import { CustomTable } from "../../../../../Component/UI/Table";
import { getAcceptedFeedbacks } from "../../../../../Redux/Slices/adminFeedback";
import {
  setFilter,
  setFrom_date,
  setSearch_key,
  setTo_date,
} from "../../../../../Redux/Slices/AdminFilters/Search";
import {
  listFeedback,
  updateFeedback,
} from "../../../../../services/admin/feedback";
import { Rating } from "../../Testimonial/TabComponents/Rating";

export const AcceptedFeedback = ({
  customerId,
  feedbackPermissions,
  pFeedback,
}) => {
  const fullpermissions = pFeedback
    ? 1
    : +feedbackPermissions?.access?.["Full Control"];

  const { acceptedFeedbacks } = useSelector((i) => i.adminFeedback);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tableHeadings = [
    "Date",
    "Name",
    "Rating",
    "Service Name",
    "Description",
    ...(fullpermissions ? ["Action"] : []),
  ];

  const [popupAction, setPopupAction] = useState({ open: false });

  const { search_key, from_date, to_date, filter } = useSelector(
    (i) => i.SearchFilters
  );

  const getAcceptedFeedbackList = () =>
    listFeedback(2, customerId, search_key, from_date, to_date).then((res) => {
      const data = res?.data;
      if (data?.status) dispatch(getAcceptedFeedbacks(data?.list));
    });

  useEffect(() => {
    getAcceptedFeedbackList();
  }, [filter]);

  useEffect(() => {
    return () => {
      dispatch(setSearch_key(""));
      dispatch(setTo_date(""));
      dispatch(setFrom_date(""));
      dispatch(setFilter());
    };
  }, []);

  const handleDelete = (id, status) => {
    setPopupAction({
      ...popupAction,
      open: true,
      action: () => {
        updateFeedback(id, status).then((res) => {
          const data = res?.data;
          if (data?.status) {
            getAcceptedFeedbackList();
            setPopupAction({ ...popupAction, open: false });
          }
        });
      },
    });
  };

  const tBodyData = acceptedFeedbacks.map((item) => {
    const { id, feedback, customer, created_at, feedback_type, rating } = item;
    const date = moment(created_at).format("DD/MM/YYYY");
    return {
      date,
      name: (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            height: 0,
          }}
        >
          <Avatar
            size="28px"
            profile={customer?.customer_image}
            border="1px solid #ccc"
          />
          <div
            style={{ cursor: "pointer", marginLeft: "8px" }}
            onClick={() => navigate("details", { state: { item, customerId } })}
          >
            {customer?.name}
          </div>
        </span>
      ),
      rating: <Rating ratings={rating} />,
      feedback_type,
      feedback: `${feedback.slice(0, 30)}${feedback.length > 30 ? "..." : ""}`,
      ...(fullpermissions && {
        action: (
          <div style={{ display: "flex" }}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleDelete(id, 0)}
            >
              <Trash />
            </div>
          </div>
        ),
      }),
    };
  });

  return (
    <>
      <Popup
        popupAction={popupAction}
        setPopupAction={setPopupAction}
        heading="Delete Feedback"
        title="Are you sure you want to delete this feedback?"
        styles={{ width: 400, height: 230 }}
        btnMargin="0 10px"
      />
      <CustomTable tHead={tableHeadings} tBody={tBodyData} />
    </>
  );
};
