import axios from "../../config/axiosConfig";

export const getSubscriptionstable = async (search, from_date, to_date) => {
  return await axios
    .get(
      `/admin/get/subscription?search_key=${search}&from_date=${from_date}&to_date=${to_date}`
    )
    .then((res) => {
      const data = res.data.list;
      return data;
      // return index !== undefined ? data?.[index] : data;
    })
    .catch((err) => console.log(err));
};

export const changeStatus = async (subscription_id, status) => {
  return await axios
    .post(`/admin/subscription/status/change`, { subscription_id, status })
    .then((res) => res)
    .catch((err) => err);
};

export const deleteSubscription = async (subscription_id) => {
  return await axios
    .post(`/admin/delete/subscription`, { subscription_id })
    .then((res) => res)
    .catch((err) => err);
};

export const deleteService = async (id) => {
  return await axios
    .get(`/admin/services/delete?id=${id}`)
    .then((res) => res)
    .catch((err) => err);
};

export const getPatientList = async () => {
  return await axios
    .get(`admin/general/patient`)
    .then((res) => res)
    .catch((err) => err);
};
