import React, { useEffect, useState } from "react";
import EnterpricesData from "../../../MockData/Enterprise.json";
import { CustomTable } from "../../../Component/UI/Table";
import SwitchButton from "../../../Component/UI/SwitchButton/Switch";
import { Link } from "react-router-dom";
import { Edit } from "../../../assets/icons/Edit";
import axios from "../../../config/axiosConfig";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  setFilter,
  setFrom_date,
  setSearch_key,
  setTo_date,
  setClear,
} from "../../../Redux/Slices/AdminFilters/Search";
import { Avatar } from "../../../Component/UI/Avatar";
import { useLocation } from "react-router-dom";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";

export const EnterpriseList = ({
  setEnterpriseData,
  setExportData,
  permissions,
}) => {
  const [client, setClient] = useState([]);
  const nlcolumns = Object.keys(EnterpricesData[0]);
  const fullpermissions = +permissions?.listControl?.access?.["Full Control"];
  const writePermissions = +permissions?.listControl?.access?.Write;
  const tHead = [
    "organisation_name",
    "contact_person",
    "Mobile",
    "Email",
    ...(fullpermissions ? ["Status"] : []),
    ...(writePermissions ? ["Action"] : []),
  ];
  // const tHead = nlcolumns;
  const dispatch = useDispatch();

  const { state } = useLocation();
  const activeStatus = state?.status;
  const [isLoading, setIsLoading] = useState(false);

  const { search_key, from_date, to_date, filter } = useSelector(
    (i) => i.SearchFilters
  );

  const getApi = () => {
    setIsLoading(true);
    axios
      .post(`/admin/enterprise/filter`, {
        type: "enterprise",
        search_key,
        from_date,
        to_date,
      })
      .then((res) => {
        setIsLoading(false);
        setClient(res.data.list);

        setExportData(
          res.data.list.map((i) => {
            if (activeStatus === "active" && i?.status !== 1) return {};
            if (activeStatus === "inactive" && i?.status !== 0) return {};
            return {
              Company: i.company_name,
              Designation: i.designation,
              Email: i.email,
              Domain: i.domain,
              Name: i.name,
              Mobile: i.mobile,
              "Total Employee": i.total_employee,
              "Other Domains": i.other_domains,
              Industry: i.industry,
              Status: i.status === 1 ? "active" : "inactive",
            };
          })
        );
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getApi();
    //toggle status
    // toggleStatus(2, 0);
  }, [filter]);

  useEffect(() => {
    setEnterpriseData(client);
  }, [client]);

  useEffect(() => {
    return () => {
      // dispatch(setSearch_key(""));
      dispatch(setTo_date(""));
      dispatch(setFrom_date(""));
      dispatch(setFilter());
      dispatch(setClear());
    };
  }, []);

  const sucessNotify = (msg) => toast.success(msg);

  const errorNotify = (error) => toast.error(error);

  const clientStatus = async (enterprise_id, status) => {
    await axios
      .post("/admin/get/enterprise/status", {
        enterprise_id: enterprise_id,
        status: status,
      })
      .then((res) => {
        sucessNotify(res.data.message);
        console.log(res);
      })
      .catch((err) => errorNotify(err));
  };

  const tBodyData = client?.map(
    ({ id, company_name, name, mobile, email, status, img_url }, index) => {
      if (activeStatus === "active" && status !== 1) return {};
      if (activeStatus === "inactive" && status !== 0) return {};

      const person_name = (
        <div
          style={{ display: "inline-flex", alignItems: "center", gap: "5px" }}
        >
          {name}
        </div>
      );

      return {
        name: (
          <Link
            style={{ textDecoration: "none", color: "#2A2A2A" }}
            to="/Enterprises/view"
            state={{ from: "edit", id, data: client[index], permissions }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                height: 0,
              }}
            >
              <Avatar size="28px" profile={img_url} border="1px solid #ccc" />{" "}
              <span style={{ marginLeft: "8px" }}> {company_name} </span>
            </span>
          </Link>
        ),
        person_name,
        mobile,
        email,
        ...(fullpermissions && {
          status: (
            <>
              <SwitchButton
                disabled={!fullpermissions}
                status={status}
                onClick={(value) => clientStatus(id, value)}
              />
            </>
          ),
        }),
        // status: (
        //   <SwitchButton
        //     status={status}
        //     disabled={!updatePerms}
        //     onClick={(value) => clientStatus(id, value)}
        //   />
        // ),

        ...(writePermissions && {
          action: (
            <>
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <Link
                  to={`editprofile`}
                  style={{
                    textDecoration: "none",
                    pointerEvents: !writePermissions ? "none" : "",
                  }}
                  state={{ from: "edit", id, data: client[index] }}
                >
                  <Edit />
                </Link>
              </div>
            </>
          ),
        }),
        // action: (
        //   <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        //     <Link
        //       to={`editprofile`}
        //       style={{
        //         textDecoration: "none",
        //         pointerEvents: !updatePerms ? "none" : "",
        //       }}
        //       state={{ from: "edit", id, data: client[index] }}
        //     >
        //       <Edit />
        //     </Link>
        //   </div>
        // ),
      };
    }
  );

  return (
    <>
      {!isLoading ? (
        <CustomTable tHead={tHead} tBody={tBodyData} />
      ) : (
        <div className="loader" style={{ textAlign: "center", margin: "60px" }}>
          <CircularProgress size={28} />
        </div>
      )}

      <Toaster />
    </>
  );
};
