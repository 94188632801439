import React, { useState, useMemo } from "react";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Stack from "@mui/material/Stack";

function MyFormHelperText() {
  const { focused } = useFormControl() || {};

  const helperText = useMemo(() => {
    if (focused) {
      return "This field is being focused";
    }

    return "Helper text";
  }, [focused]);

  return <FormHelperText>{helperText}</FormHelperText>;
}

export default function InputField({
  label,
  placeholder,
  InputVisible,
  width,
  name,
  values,
  onChange,
  labelColor,
  disabled,
}) {
  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <InputLabel
        htmlFor="input-with-icon-adornment"
        sx={{ fontSize: "14px", color: labelColor && labelColor }}
      >
        {label}
      </InputLabel>
      {InputVisible ? (
        <Box component="form" noValidate autoComplete="off">
          <FormControl sx={{ width: `${width}` }}>
            <OutlinedInput
              name={name}
              size="small"
              sx={{ fontSize: "14px", marginTop: "5px" }}
              placeholder={placeholder}
              value={values}
              onChange={onChange}
              disabled={disabled}
            />
          </FormControl>
        </Box>
      ) : (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={3}>
            <DesktopDatePicker
              inputFormat="MM/dd/yyyy"
              value={value}
              onChange={handleChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </LocalizationProvider>
      )}
    </>
  );
}
