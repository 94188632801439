import { createSlice } from "@reduxjs/toolkit";

const clientDetailsSlice = createSlice({
  name: "client details",
  initialState: { data: { country: "101", countryCode: "+91" } },
  reducers: {
    clientDetails: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    clearClient: (state) => {
      state.data = "";
    },
  },
});

export const { clientDetails, clearClient } = clientDetailsSlice.actions;

export default clientDetailsSlice.reducer;
