import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Report from "../../assets/icons/icon/hideResult.svg";
import Classess from "../../assets/css/Patient-login/patientlogin.module.css";
import Fb from "../../assets/icons/icon/facebook.svg";
import Google from "../../assets/icons/icon/google.svg";
import GoogleLoginComp from "../Auth/GoogleLogin";
import FacebookLoginComp from "../Auth/FacebookLoginComp";
// import { PatientLogin } from "../../Patient-login/PatientLogin";

export const Submition = (props) => {
  const [name, setName] = useState("");

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div style={{ margin: "20px 0" }}>
        <h2
          style={{ fontSize: "18px", color: "#7a51ae", fontWeight: "normal" }}
        >
          {name}
        </h2>
      </div>
      <div style={{ margin: "20px 0" }}>
        <p style={{ fontSize: "32px" }}>Congratulations!</p>
      </div>
      <div style={{ margin: "20px 0" }}>
        <p style={{ fontSize: "18px", fontWeight: "normal" }}>
          You have successfully completed the assessment
        </p>
      </div>
      <div>
        <img src={Report} alt="hide-result" />
      </div>
      <div
        style={{
          width: "350px",
          textAlign: "center",
          border: "1px solid #dadada",
          padding: "15px",
          borderRadius: "8px",
          textAlign: "center",
          margin: "20px 0",
          background: "#7a51ae",
          color: "#fff",
        }}
      >
        <span style={{ width: "100%" }}>
          <Link
            to="/user/signin"
            style={{ textDecoration: "none", color: "#fff" }}
          >
            Sign in with Email
          </Link>
        </span>
      </div>
      <div style={{ width: "350px" }}>
        <div>
          <div>
            <GoogleLoginComp />
            {/* <FacebookLoginComp /> */}
            <div className={Classess.signup}>
              <p>
                Don't have an account?
                <span style={{ color: "#7a51ae" }}>
                  <Link
                    to="/user/signup"
                    state={{ from: "submittion_Screen", status: "completed" }}
                  >
                    Sign up
                  </Link>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
