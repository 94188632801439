import React, { useState } from "react";
import classes from "../../../assets/css/PatientUI/servicecard.module.css";
import Card from "@mui/material/Card";

import {
  CardActionArea,
  Typography,
} from "../../../../node_modules/@mui/material/index";
import bookServiceImg from "../../../assets/icons/bookServiceImg.svg";
import { Buttons } from "../../../Component/UI/Button/Button";
import Modal from "./Modal";
import { counsellor } from "../../../Component/UI/ServicesCard/speaktocounsellorid";
import ConsentPopup from "../../../Component/UI/ServicesCard/ConsentPopup";
import { useNavigate } from "../../../../node_modules/react-router-dom/index";
import toast from "../../../../node_modules/react-hot-toast/dist/index";
import ModalSuccess from "../Subscriptions/ModalSuccess";
export const ServicesCard = ({ data, selectEnterPrise, select, status }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenConstent, setIsOpenConstent] = useState({
    open: false,
    consent: false,
  });
  const [userProfile, setUserProfile] = React.useState({ country: "105" });

  const [isOpenSuccess, setIsOpenSuccess] = useState({ open: false });
  const [isLoading, setIsLoading] = useState(false);

  // const is_consent = JSON.parse(sessionStorage.getItem("is_consent"));
  const [popupAction, setPopupAction] = useState({
    open: false,
    action: () => navigate("/patient/Assessment"),
  });

  // const checkCond = parseInt(data?.consume) === parseInt(data?.number_session);

  // const checkDataCond = allDataList?.filter((i) => i?.id === data?.id);

  // const checkData = checkDataCond?.some((i) => i?.booked === true);

  // console.log(checkCond, 'checkCond--')

  // new code start
  // const checkSameData = allDataList?.filter((i) => i?.id === data?.id);
  // console.log(checkSameData, "checkSameData---");
  // const getBookedTrue = checkSameData?.find((i) => i?.booked === true);
  // const addThreeMonth = moment(getBookedTrue?.subscription_expiry)
  //   .add(3, "months")
  //   .format("YYYY-MM-DD");
  // const approveDate = moment(data?.subscription_approve).format("YYYY-MM-DD");
  // const checkDateCond = data?.booked
  //   ? false
  //   : getBookedTrue !== undefined
  //   ? moment(approveDate).isBefore(addThreeMonth)
  //   : false;

  // console.log(addThreeMonth, approveDate, getBookedTrue, "rajeev");
  // console.log(checkDateCond, 'checkDateCond--')

  // const addThreeMonth = getBookedTrue
  //     ?.map((i) => {
  //       const bookedDate = moment(i?.subscription_expiry).add(3, "month").format("YYYY-MM-DD");
  //       return bookedDate

  //     })
  //     .filter((i) => i !== null);

  // const getApproveDate = getBookedFalse
  //     ?.map((i) => {
  //       const bookedDate = moment(i?.subscription_approve).format("YYYY-MM-DD");
  //       return bookedDate

  //     })
  //     .filter((i) => i !== null);

  // const compareDate =  addThreeMonth?.map((i) => moment(i).isBefore(getApproveDate[i]))

  // console.log(compareDate,getApproveDate,addThreeMonth, 'compareDate')

  const handleClick = () => {
    // if (is_consent === 0) {
    //   setIsOpenConstent(true);
    // }
    if (selectEnterPrise !== "0") {
      if (parseInt(data?.consume) === parseInt(data?.number_session)) {
        toast.error(
          "All sessions have been exhausted. Please reach out to your organisation for renewal/extension"
        );
        return;
      } else {
        if (select !== 0) {
          if (
            data?.id === counsellor?.[data?.category] &&
            status !== "completed"
          ) {
            setPopupAction({ ...popupAction, open: true });
          } else {
            setIsOpen(true);
          }
        } else {
          setIsOpen(true);
        }
      }
      return;
    }
    if (select !== 0) {
      if (data?.id === counsellor?.[data?.category] && status !== "completed") {
        setPopupAction({ ...popupAction, open: true });
      } else {
        setIsOpen(true);
      }
    } else {
      setIsOpen(true);
    }
    setIsOpen(true);
  };

  return (
    <>
      <div className={`${classes.serviceCardContainerJoin}`}>
        <Card
          style={{
            borderRadius: "20px",
            paddingBotttom: "300px",
            height: "100%",
          }}
        >
          <CardActionArea>
            <div
              style={{
                height: "140px",
                backgroundColor: "#fdf5f4",
              }}
            >
              <div
                style={{
                  // backgroundImage: `url(${bookServiceImg})`,
                  width: "72px",
                  height: "83px",
                  margin: "auto",
                  backgroundPosition: "center",
                }}
              >
                <img src={bookServiceImg} style={{ marginTop: "25px" }} />
              </div>
            </div>

            <div
              style={{
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "175px",
              }}
            >
              <div>
                <Typography
                  gutterBottom
                  variant="h2"
                  component="div"
                  style={{
                    display: "flex",
                    textAlign: "left",
                    fontSize: "18px",
                    fontWeight: "400",
                    minHeight: "50px",
                  }}
                >
                  {data?.name}
                </Typography>

                <div
                  style={{
                    display: "flex",
                    textAlign: "left",
                    fontSize: "18px",
                    fontWeight: "400",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{
                      display: "flex",
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    {data?.amount === "0" ? "Free" : `Rs. ${data?.amount}`}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{
                      display: "flex",
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    {data?.type_of_service === "0" ? (
                      <span
                        style={{
                          backgroundColor: "#F19204",
                          color: "#ffffff",
                          width: "78px",
                          height: "24px",
                          padding: "5px",
                          fontSize: "12px",
                          textAlign: "center",
                          borderRadius: "20px",
                          textTransform: "uppercase",
                        }}
                      >
                        Offline
                      </span>
                    ) : data?.type_of_service === "1" ? (
                      <span
                        style={{
                          backgroundColor: "#18BA4B",
                          color: "#ffffff",
                          width: "78px",
                          height: "24px",
                          fontSize: "12px",
                          padding: "5px",
                          textAlign: "center",
                          borderRadius: "20px",
                          textTransform: "uppercase",
                        }}
                      >
                        Online
                      </span>
                    ) : data?.type_of_service === "2" ? (
                      <span
                        style={{
                          backgroundColor: "#047FF1",
                          color: "#ffffff",
                          width: "78px",
                          height: "24px",
                          padding: "5px",
                          fontSize: "12px",
                          textAlign: "center",
                          borderRadius: "20px",
                          textTransform: "uppercase",
                        }}
                      >
                        Hybrid
                      </span>
                    ) : (
                      ""
                    )}
                  </Typography>
                </div>
              </div>

              {/* {!data?.booked ? ( */}
              <Buttons
                className={classes.disabledBtn}
                click={handleClick}
                //   disabled={
                //     checkDateCond
                //       ? false
                //       : checkData || checkCond || data?.booked
                //   }
                name={"Book Now"}
                style={{
                  backgroundColor: "#7A51AE",
                  color: "ffffff",
                  marginTop: "10px",
                  borderRadius: "8px",
                  textTransform: "none",
                }}
              />
              {isOpenConstent.open && (
                <ConsentPopup
                  setIsOpenConstent={setIsOpenConstent}
                  isOpenConstent={isOpenConstent}
                  setIsOpen={setIsOpen}
                  isLoading={isLoading}
                />
              )}

              {isOpen && (
                <Modal
                  setIsOpenSuccess={setIsOpenSuccess}
                  setIsOpen={setIsOpen}
                  modalData={data}
                  counsellorId={counsellor}
                  // is_consent={is_consent}
                  isOpenConstent={isOpenConstent}
                  setIsOpenConstent={setIsOpenConstent}
                  setIsLoading={setIsLoading}
                  // selectEnterPrise={selectEnterPrise}
                />
              )}
              {isOpenSuccess.open && (
                <ModalSuccess
                  setIsOpenSuccess={setIsOpenSuccess}
                  isOpenSuccess={isOpenSuccess}
                />
              )}
            </div>
          </CardActionArea>
        </Card>
      </div>
    </>
  );
};
