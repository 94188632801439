import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import { Avatar } from "../../../../Component/UI/Avatar";
import SimpleBreadcrum from "../../../../Component/UI/SimpleBreadcrum";
import { CustomTable } from "../../../../Component/UI/Table";
import Styles from "./service.module.css";
import {
  convertCurrency,
  formatttedDate,
} from "../../../../config/formattedDate";
import {
  deleteReportsList,
  getPatientService,
  getReportsPatient,
} from "../../../../services/admin/patient";
import { usePermissions } from "../../../../Hooks/usePermissions";
import {
  useLocation,
  useNavigate,
} from "../../../../../node_modules/react-router-dom/index";
import moment from "../../../../../node_modules/moment/moment";
import { FaFilePdf } from "react-icons/fa";
import { Trash } from "../../../../assets/icons/Trash";
import { Toaster } from "../../../../../node_modules/react-hot-toast/dist/index";
import Popup from "../../../../Component/UI/Popup/Popup";
import { Select } from "../../../../Component/UI/CustomInputs";
export const AdminReports = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [customerReport, setCustomerReport] = useState([]);
  const [popupAction, setPopupAction] = useState({ open: false });
  const customerId = state?.props || state?.data?.props || state?.customerId;
  const customerName = state?.name || state?.data?.name || state?.customerName;
  const [dataReports, setDataReports] = useState([]);
  const permissions = usePermissions("name", "patients");
  console.log(state, "state");
  const tableHeadings = [
    "Report Type",
    "Report Name",
    "Report Date",
    "Date of Upload",
    "PDF Report	",
    "Action",
  ];

  const dropDownData = [
    {
      id: "",
      value: "All",
    },
    {
      id: "Psychological",
      value: "Psychological Assessment",
    },
    {
      id: "Consultation",
      value: "Consultation Reports",
    },
    {
      id: "Medical",
      value: "Medical Reports",
    },
  ];

  const getTypeOfService = (val) => {
    switch (val) {
      case 0:
        return "Offline";
      case 1:
        return "Online";
      case 2:
        return "Hybrid";
      default:
        return "NA";
    }
  };

  const handlePdfDownload = (url) => {};

  console.log(customerReport, "customerReport");

  useEffect(() => {
    gerReportList();
  }, [dataReports]);

  const gerReportList = () => {
    getReportsPatient(customerId, dataReports).then((res) => {
      if (res?.data?.status) {
        setCustomerReport(res?.data?.list);
      }
    });
  };

  const handleDelete = (id) => {
    setPopupAction({
      ...popupAction,
      open: true,
      action: () => {
        deleteReportsList(id).then((res) => {
          const data = res?.data;
          if (data?.status) {
            gerReportList();
            setPopupAction({ ...popupAction, open: false });
          }
        });
      },
    });
  };
  const tBodyData = customerReport?.map((item) => {
    const {
      id,
      report_type,
      report_name,
      report_date,
      report_url,
      created_at,
    } = item;

    const reportDate = moment(report_date).format("DD/MM/YYYY");
    const createdAt = moment(created_at).format("DD/MM/YYYY");

    return {
      report_type,
      report_name,
      reportDate,
      createdAt,
      actionPdf: (
        <div style={{ cursor: "pointer", display: "contents" }}>
          <a href={report_url} target="_blank">
            <FaFilePdf color="#dd2121cc" size={24} />
          </a>
        </div>
      ),
      action: (
        <div style={{ display: "flex" }}>
          <div style={{ cursor: "pointer" }} onClick={() => handleDelete(id)}>
            <Trash />
          </div>
        </div>
      ),
    };
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataReports({ ...dataReports, [name]: value });
  };

  console.log(dataReports, "dataReports");

  const links = [
    {
      path: "/Patients",
      linkText: "Patients",
    },
    {
      path: "/patientsProfile",
      linkText: customerName,
      data: {
        permissions,
        name: customerName,
        props: customerId,
      },
    },
  ];

  const addReports = () => {
    navigate("/patientsProfile/adminReports/addReport", {
      state: {
        customerId: customerId,
        customerName: customerName,
      },
    });
  };

  return (
    <>
      <Toaster />
      <Popup
        popupAction={popupAction}
        setPopupAction={setPopupAction}
        heading="Delete Report"
        title="Are you sure you want to delete this Report?"
        styles={{ width: 400, height: 230 }}
        btnMargin="0 10px"
      />
      <SimpleBreadcrum
        text="Reports"
        link={links}
        buttonText={true}
        addReports={addReports}
      />
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Select
          name="reportType"
          //  label="Report Type"
          options={dropDownData}
          onChange={handleChange}
        />
      </div>

      <CustomTable
        tHead={tableHeadings}
        tBody={tBodyData}
        message={"No Reports is available for this Patient."}
      />
    </>
  );
};
