import React, { useEffect, useState } from "react";
import axios from "../../../../config/axiosConfig";
import { useLocation } from "../../../../../node_modules/react-router-dom/index";
import { CustomTable } from "../../../../Component/UI/Table";
import moment from "../../../../../node_modules/moment/moment";

export const ConsentList = () => {
  const location = useLocation();
  const [consentList, setConsentList] = useState();
  const { props } = location.state;
  useEffect(() => {
    getConsentList();
  }, []);
  const getConsentList = () => {
    axios
      .get(`admin/getSecondConsentPatient?id=${props}`)
      .then((res) => {
        setConsentList(res?.data?.data);
      })
      .catch((err) => err);
  };

  const tHead = ["Patient Name", "Address", "Created Date", "Status"];

  const tBodyData = consentList?.map(
    ({ patient_name, patient_address, created_at, is_consent }) => {
      return {
        patient_name: patient_name ? patient_name : "-",
        patient_address: patient_address ? patient_address : "-",
        created_at: created_at ? moment(created_at).format("DD-MM-YYYY") : "-",
        is_consent: is_consent === 1 ? "Approved" : "Pending",
      };
    }
  );

  return (
    <div>
      <CustomTable tHead={tHead} tBody={tBodyData} />
    </div>
  );
};
