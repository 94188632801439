import { useState } from "react";
import moment from "moment";
import { FiDownload } from "react-icons/fi";
import { MyAccount } from "../../HOC/MyAccount";
import { CustomTable } from "../../Component/UI/Table";
import { jsPDF } from "jspdf";
import { useEffect } from "react";
import { API } from "../../config/constant";
import instance from "../../config/axiosConfig";

const PaymentHistory = () => {
  const [paymentRespone, setPaymentRespone] = useState();
  const tHead = [
    "Date",
    "Transation ID",
    "Service Name",
    "Subscription Name",
    "Payment Status",
    "Mode Of Payment",
    "Amount",
  ];

  useEffect(() => {
    try {
      instance.get(API.CUSTOMERPAYMENTHISTORY).then((res) => {
        res.data.status === true && setPaymentRespone(res.data.list);
      });
    } catch (error) {
      console.log(error, "error");
    }
  }, []);

  console.log(paymentRespone, "paymentRespone");
  const tBodyData = paymentRespone?.map(
    ({
      service,
      subscription,
      payment_done,
      payment_id,
      name,
      amount,
      created_at,
    }) => {
      return {
        created_at: moment(created_at).format("DD-MM-YYYY"),
        payment_id: payment_id,
        name: service?.name ? service?.name : "-",
        subscription: subscription?.name ? subscription?.name : "-",
        payment_done:
          (payment_done === 0 && "Pending") ||
          (payment_done === 1 && "Completed") ||
          (payment_done === 2 && "Cancelled") ||
          (payment_done === 3 && "Failed"),
        mode: "Online",
        amount: amount,
        // action: (
        //   <FiDownload
        //     onClick={() =>
        //       onButtonClick(Date, TID, SName, PStatus, ModOfPayment, Amount)
        //     }
        //     style={{
        //       width: "25px",
        //       height: "25px",
        //       cursor: "pointer",
        //       color: "purple",
        //     }}

        //   />
        // ),
      };
    }
  );
  const onButtonClick = (date, TID, SName, status, mode, amt) => {
    let doc = new jsPDF("p", "pt");
    doc.text(20, 20, `Date:${date}`);
    doc.addFont("helvetica", "normal");
    doc.text(20, 40, `Transation ID:${TID}`);
    doc.text(20, 60, `Service Name:${SName}`);
    doc.text(20, 80, `Payment Status:${status}`);
    doc.text(20, 100, `Mode Of Payment:${mode}`);
    doc.text(20, 120, `Amount:${amt}`);

    doc.save("PaymentDetials.pdf");
  };
  return <CustomTable tHead={tHead} tBody={tBodyData} />;
};

export const PaymentHistoryHOC = MyAccount(PaymentHistory);
