import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

import { CardActionArea } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import DrPic from "../assets/images/doctor.png";

import { AiOutlineCalendar, AiOutlineClockCircle } from "react-icons/ai";
import { Btn } from "./UI/Button";
import { Avatar } from "../Component/UI/Avatar";
import { Link } from "react-router-dom";
import { to12Hour, to12Hours } from "../config/formattedDate";
import { useContext } from "react";
import AuthContext from "../Auth/AuthContext";

export const AppointmentCards = ({
  name,
  onClick,
  date,
  start_time,
  endTime,
  sessionId,
  customer_id,
  paitient_name,
  hideButton,
  data,
  styles,
}) => {
  const ctx = useContext(AuthContext);
  const ProfileId = ctx.loginUser?.customer?.id;

  return (
    <div>
      <Box sx={{ margin: "10px" }}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Card
              style={{
                width: "264px",
                height: "275px",
                borderRadius: "20px",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
              }}
            >
              <CardActionArea
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
                disableRipple
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <CardContent
                    style={{
                      paddingBottom: "4px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#E69A8D33",
                        color: "#E69A8D",
                        width: "78px",
                        height: "24px",
                        padding: "5px",
                        textAlign: "center",
                        borderRadius: "20px",
                        textTransform: "uppercase",
                      }}
                    >
                      {sessionId}
                    </div>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      style={{
                        display: "flex",
                        textAlign: "left",
                        marginTop: "5px",
                        fontSize: "18px",
                        fontWeight: "400",
                      }}
                    >
                      {name}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        display: "flex",
                        textAlign: "left",
                        alignItems: "center",
                        fontSize: "14px",
                        fontWeight: "300",
                      }}
                    >
                      <AiOutlineCalendar
                        style={{
                          color: "#7A51AE",
                          fontSize: "16px",
                          marginRight: "6px",
                        }}
                      />

                      {date}
                    </Typography>

                    <Typography
                      variant="body2"
                      style={{
                        display: "flex",
                        textAlign: "left",
                        alignItems: "center",
                        fontSize: "14px",
                        fontWeight: "300",
                      }}
                    >
                      <AiOutlineClockCircle
                        style={{
                          color: "#7A51AE",
                          fontSize: "16px",
                          marginRight: "6px",
                        }}
                      />
                      {to12Hour(start_time)} - {to12Hour(endTime)}
                    </Typography>
                    {customer_id !== ProfileId ? (
                      <div style={{ marginTop: "10px", textAlign: "left" }}>
                        <>
                          <h4>
                            Booked for :{" "}
                            <span style={{ fontWeight: "normal" }}>
                              {paitient_name.slice(0, 25)}
                              {paitient_name.length > 25 && "..."}
                            </span>
                          </h4>
                        </>
                      </div>
                    ) : null}
                    {data?.staff_name &&
                      data?.staff_name != null &&
                      data?.staff_name != "" && (
                        <Grid
                          container
                          spacing={1}
                          style={{
                            marginTop: "5px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={2}>
                            <CardMedia>
                              <Avatar
                                profile={
                                  data?.staff_logo ? data?.staff_logo : DrPic
                                }
                                size="36px"
                              />
                            </CardMedia>
                          </Grid>
                          <Grid
                            item
                            xs={8}
                            style={{
                              textAlign: "left",
                              marginLeft: "5px",
                              fontSize: "15px",
                              marginTop: "3px",
                            }}
                          >
                            {data?.staff_name}
                            <br />
                            <p className={styles.maxLimit}>
                              {data?.staff_specialization}
                            </p>
                          </Grid>
                        </Grid>
                      )}
                  </CardContent>
                </div>

                {!hideButton && (
                  <Btn
                    marginBottom={"5px"}
                    click={onClick}
                    width={"90%"}
                    height={36}
                    variant="contained"
                  >
                    Details
                  </Btn>
                )}
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

AppointmentCards.defaultProps = { link: "" };
