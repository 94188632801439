import React from "react";
import classes from "../../assets/css/events.module.css";

export const Details = ({ title, description }) => {
  return (
    <div
      style={{ flex: "0 0 50%", padding: "20" }}
      className={classes.detail_card}
    >
      <p className={classes.detail_card_title}>{title}</p>
      <p className={classes.detail_card_description}>{description}</p>
    </div>
  );
};
