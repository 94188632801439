import React, { useEffect, useState } from "react";
import classes from "../../../assets/css/events.module.css";
import TimePickers from "../../UI/TimePicker";
import { Input, TextArea } from "../../../Component/UI/CustomInputs";
import { DatePicker } from "../../UI/DatePicker";
import PaperWrapper from "../../../Component/UI/PaperWrapper/PaperWrapper";
import CustomizedButtons from "../../../Component/UI/Button/CustomizeButton";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../../config/axiosConfig";
import { StringDtoY } from "../../../config/formattedDate";
import { SelectCard2 } from "../../UI/SelectCard2";
import BasicBreadcrumbs from "../../../Component/UI/BreadCrumb/BreadCrumbs";
import TimerForEditEvent from "./TimerForEditEvent";
import moment from "../../../../node_modules/moment/moment";
import toast, {
  Toaster,
} from "../../../../node_modules/react-hot-toast/dist/index";
import { UploadProfile } from "../../../Component/UI/UploadProfile";

export const EditEvent = () => {
  const today = StringDtoY(new Date());

  const { state } = useLocation();
  // let mockData = {
  //   type: "",
  //   event_date: today,
  //   name: "",
  //   coordinator: "",
  //   start_time: "",
  //   end_time: "",
  //   description: "",
  //   staff: "staff",
  //   weblink: "",
  // };

  const [err, setErr] = useState({});

  const [data, setData] = useState({ staff: "staff", event_date: today });
  const [dateSelected, setDateSelected] = useState(new Date());

  useEffect(() => {
    getEventDetail(state?.event_id);
  }, []);

  const getEventDetail = async (event_id) => {
    await axios
      .get("/admin/detail/events", {
        params: {
          event_id,
        },
      })
      .then((res) => {
        setErr(res?.data?.error);
        let resData = res?.data?.list;
        // let eventType = resData?.type === 1 ? "Online" : "Offline";
        setData({
          ...data,
          event_id: event_id,
          type: resData?.type,
          event_date: resData?.event_date.split(" ")[0],
          name: resData?.name,
          coordinator: resData?.coordinator,
          start_time: resData?.start_time,
          end_time: resData?.end_time,
          description: resData?.description,
          weblink: resData?.weblink,
          avtar: resData?.avtar,
          speaker_name: resData?.speaker_name,
        });

        setDateSelected(resData.event_date);
      })
      .catch((err) => console.error(err));
  };

  const navigate = useNavigate();

  const getSelectedOption = (option) => {
    let typeStatus;
    if (option === "Online") {
      typeStatus = 1;
    } else {
      typeStatus = 0;
    }
    setData({ ...data, type: typeStatus });
  };

  const getSelectedDate = (e) => {
    setData({
      ...data,
      event_date: StringDtoY(e),
    });
    setDateSelected(e);
  };

  const handleCancel = () => {
    navigate("/events");
  };
  const handleSubmit = async () => {
    const currentDate = moment(new Date()).format("YYYY-MM-DD");

    const selectedStartTime = moment(
      currentDate + " " + data.start_time,
      "YYYY-MM-DD hh:mm"
    );
    const selectedEndTime = moment(
      currentDate + " " + data.end_time,
      "YYYY-MM-DD hh:mm"
    );

    const diffMin = selectedEndTime.diff(selectedStartTime, "seconds");

    const endDiff = selectedEndTime.diff(moment(), "minutes");
    const startDiff = selectedStartTime.diff(moment(), "minutes");

    const eventDateDiff = moment().diff(
      moment(dateSelected).format("YYYY-MM-DD hh:mm"),
      "hours"
    );

    const formData = new FormData();
    for (let key in data) formData.append(key, data[key]);

    if (diffMin > 0) {
      if (endDiff < 0 && eventDateDiff >= 0) {
        toast.error("End time should be more than current time!");
      } else if (startDiff < 0 && eventDateDiff >= 0) {
        toast.error("Start time should be more than current time!");
      } else {
        await axios
          .post("/admin/update/events", formData)
          .then((res) => {
            if (res.data.status === true) {
              toast.success("Event Updated successfully!");
              navigate("/events");
            } else setErr(res?.data?.error);
          })
          .catch((err) => console.error(err));
      }
    } else {
      toast.error("End time should be more than start time!");
    }
  };

  const getStartTime = (e) => {
    setData({ ...data, start_time: e.target.value });
  };

  const getEndTime = (e) => {
    setData({ ...data, end_time: e.target.value });
  };

  const links = [{ path: "/events", linkText: "Events" }];

  return (
    <>
      <Toaster />
      <BasicBreadcrumbs link={links} text="Edit Events">
        <div className={classes.addEvent}>
          <div className={classes.avatar}>
            <UploadProfile
              name="avtar"
              profile={data?.avtar}
              upload={(value) => {
                setData({ ...data, banner_image: value });
              }}
            />
          </div>
          <div>
            <div className={classes.general_obv}>
              <div>
                <Input
                  label="Name"
                  type="text"
                  value={data.name}
                  error={err?.name}
                  onChange={(e) => {
                    setData({ ...data, name: e.target.value });
                  }}
                  maxlength="40"
                />
              </div>
              <div>
                <DatePicker
                  label="Date"
                  minDate={new Date()}
                  getSelectedDate={getSelectedDate}
                  defaultDate={data?.event_date}
                />
              </div>
            </div>
            <div className={classes.general_obv}>
              <div>
                <SelectCard2
                  options={["Online", "Offline"]}
                  label="Type of Event"
                  error={err?.type}
                  style={{
                    // border: "1px solid #dadada",
                    width: "315px",
                    borderRadius: "8px",
                  }}
                  labelstyle={{ width: "50%" }}
                  getSelectedOption={getSelectedOption}
                  value={data.type === 1 ? "Online" : "Offline"}
                />
              </div>

              <div>
                <Input
                  label="Coordinator"
                  type="text"
                  value={data.coordinator}
                  error={err?.coordinator}
                  onChange={(e) => {
                    setData({ ...data, coordinator: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className={classes.general_obv}>
              <div>
                <TimerForEditEvent
                  labelName={"Start Time"}
                  defaultValue={data?.start_time}
                  onChange={getStartTime}
                />
              </div>
              <div>
                <TimerForEditEvent
                  labelName={"End Time"}
                  defaultValue={data?.end_time}
                  onChange={getEndTime}
                />
              </div>
            </div>
            <div className={classes.general_obv}>
              <div>
                <Input
                  label="Speaker Name"
                  type="text"
                  error={err?.speaker_name}
                  value={data.speaker_name}
                  onChange={(e) => {
                    setData({ ...data, speaker_name: e.target.value });
                  }}
                />
              </div>
              <div>
                <Input
                  label="Webinar Link"
                  type="text"
                  width="712px"
                  value={data.weblink}
                  onChange={(e) => {
                    setData({ ...data, weblink: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className={classes.general_obv}>
              <TextArea
                label="Description"
                type="text"
                error={err?.description}
                width="712px"
                height="96px"
                value={data.description}
                onChange={(e) => {
                  setData({ ...data, description: e.target.value });
                }}
                // maxlength="200"
              />
            </div>
            <div className={classes.AddEventButtonsWrapper}>
              <CustomizedButtons
                click={handleCancel}
                ButtonLabel={"Cancel"}
                btncoloractive={"active"}
              />
              <CustomizedButtons ButtonLabel={"Save"} click={handleSubmit} />
            </div>
          </div>
        </div>
      </BasicBreadcrumbs>
    </>
  );
};
