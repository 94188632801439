import React from "react";
import classes from "../../assets/css/login.module.css";
import AssessmentStyles from "../../assets/css/assessment.module.css";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import LoginImage from "../../assets/icons/LoginImage.svg";
import logo from "../../assets/icons/icon/logo.svg";
import { Input } from "../../Component/UI/CustomInputs";
import { useState } from "react";
import { forgotPassword } from "../../services/patient/forgotPassword";
import { Toaster } from "react-hot-toast";
import toast from "react-hot-toast";
import { Button } from "@mui/material";
import { useContext, useEffect } from "react";
import AuthContext from "../../Auth/AuthContext";
import { Helmet } from "react-helmet";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState();
  const [msg, setMessage] = useState();
  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const [disableBtn, setDisableBtn] = useState(false);

  const navigate = useNavigate();
  const ctx = useContext(AuthContext);
  const is_loggedin = ctx?.loginUser?.is_loggedin;

  useEffect(() => {
    is_loggedin && navigate("/dashboard");
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setDisableBtn(true);
    forgotPassword(email)
      .then((res) => {
        setDisableBtn(false);
        if (res?.data?.status) {
          setMessage(res.data.msg);
          toast.success("Password sent successfully");
          setTimeout(() => {
            navigate("/user/signin");
          }, 2000);
          setError();
        } else {
          setError(res.response.data.error);
          setDisableBtn(false);
        }
      })
      .catch((err) => setDisableBtn(false));
  };
  const domain = window.location.host;
  const canonicalUrl = `https://${domain}/user/forgot`;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>User - Forgot Your Password - Samvedna Map</title>
        <meta
          name="title"
          content="User - Forgot Your Password - Samvedna Map"
        />
        <meta
          name="description"
          content="Forgot your password- Please enter your email address so we can send you an email to reset your password. For more info visit map samvedna care."
        />
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Toaster />
      <div
        className={classes.login_container}
        style={{ background: `url(${LoginImage})` }}
      >
        <header className={AssessmentStyles.header}>
          <Link to="/">
            <img className={AssessmentStyles.logo} src={logo} alt="logo" />
          </Link>
        </header>
        <div className={classes.login_subcontainer}>
          <form onSubmit={onSubmit}>
            <h1 className={classes.heading}>Forgot Your Password !</h1>

            <div className={classes.input}>
              <Input
                type="text"
                id="email"
                value={email}
                label="Email Address:"
                name="email"
                onChange={(e) => handleChange(e)}
                placeholder="Email Address"
                style={{ outlineColor: "#7a51ae" }}
                autoComplete="off"
                error={error}
              />
            </div>
            {msg && <p>{msg}</p>}

            <div className={classes.submit} style={{ margin: "20px 0" }}>
              <Button
                variant="contained"
                size="small"
                // disabled={disableBtn}
                style={{
                  textTransform: "none",
                  width: "100%",
                  height: "48px",
                  backgroundColor: "#7A51AE",
                }}
                onClick={onSubmit}
              >
                {disableBtn ? (
                  <CircularProgress color="inherit" />
                ) : (
                  "Send Password"
                )}
              </Button>
            </div>
            <Link to="/user/signin">
              <p style={{ color: "#7a51ae" }}>Back to Sign In</p>
            </Link>
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
