import React from "react";
import { TabViewEnterprise } from "../../Components/UI/TabViewEnterprise/TabViewEnterprise";
import { ActiveAssessments } from "./ActiveAssessments";
import { InActiveAssessments } from "./InActiveAssessments";
import { OtherAssessments } from "./OtherAssessments";

export const Assessments = () => {
  return (
    <>
      <div>
        <TabViewEnterprise
          values={[
            { label: "Active", component: <ActiveAssessments /> },
            { label: "Expired", component: <InActiveAssessments /> },
            { label: "Others", component: <OtherAssessments /> },
          ]}
        />
      </div>
    </>
  );
};
