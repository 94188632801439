import React, { useEffect, useState } from "react";
import styles from "./model.module.css";
import { RiCloseLine } from "react-icons/ri";
import { Btn } from "../../UI/Button";
import Success from "../../../assets/images/success.svg";
import { Datepicker, Select } from "../../../Component/UI/CustomInputs";
import {
  getreschedulestaff,
  getStaffTimeSlot,
  resAppointment,
} from "../../../services/patient/appointments";
import moment from "moment";
import { toast } from "../../../../node_modules/react-hot-toast/dist/index";
import { useNavigate } from "../../../../node_modules/react-router-dom/index";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";
import { to12Hour } from "../../../config/formattedDate";

const RescheduleModal = ({ setOpenReschedule, openReschedule }) => {
  const updateTime = openReschedule?.body?.appointment_time?.split(":");
  const [appDate, setAppDate] = useState({
    start_date: moment(openReschedule?.body?.appointment_date).format(
      "YYYY-MM-DD"
    ),
    staffId: openReschedule?.body?.staff_id,
    start_time: updateTime[0],
  });
  const [staff, setStaff] = useState();
  const navigate = useNavigate();
  const [selectedStaffList, setSelectedStaffList] = useState();
  const [filterStaffList, setFilterStaffList] = useState();
  const [err, setErr] = useState({});
  const [reserveTime, setReserveTime] = useState();
  const [timeSlotFilter, setTimeSlotFilter] = useState([]);
  const [currentDate, setCurrentDate] = useState();
  const [loading, setLoading] = useState(false);
  const [timeSlotApp, setTimeSlotApp] = useState();
  const [selectedRosterId, setSelectedRosterId] = useState();
  const rosteridSelectedTime = openReschedule?.body?.roster_id;

  const staffname = openReschedule?.body?.staffName;

  useEffect(() => {
    setCurrentDate(moment(appDate?.start_date).isSame(moment(), "day"));
    const timer = setTimeout(() => {
      getreschedulestaff(appDate?.start_date).then((res) => {
        setStaff(res?.data?.list);
      });
    }, 1000);
    if (!staffname) {
      getStaffTimeSlot(appDate?.start_date).then((res) => {
        const response = res?.data?.list;
        const times = [];
        for (let time in response) {
          const addOneHour = +time + 1;
          const value = `${to12Hour(time)} - ${to12Hour(addOneHour)}`;
          if (response[time]) {
            times.push({
              value,
              id: time?.padStart(2, 0),
              disabled: true,
            });
          } else {
            times.push({
              value,
              id: time,
              disabled: false,
            });
          }
        }
        setTimeSlotApp(times);
      });
    }
  }, [appDate?.start_date, appDate?.staffId]);

  const timeSlotdata = [
    {
      id: "09",
      value: "09:00 AM - 10:00 AM",
    },
    {
      id: "10",
      value: "10:00 AM - 11:00 AM",
    },
    {
      id: "11",
      value: "11:00 AM - 12:00 PM",
    },
    {
      id: "12",
      value: "12:00 PM - 01:00 PM",
    },
    {
      id: "13",
      value: "01:00 PM - 02:00 PM",
    },
    {
      id: "14",
      value: "02:00 PM - 03:00 PM",
    },
    {
      id: "15",
      value: "03:00 PM - 04:00 PM",
    },
    {
      id: "16",
      value: "04:00 PM - 05:00 PM",
    },
    {
      id: "17",
      value: "05:00 PM - 06:00 PM",
    },
    {
      id: "18",
      value: "06:00 PM - 07:00 PM",
    },
  ];

  console.log(staff, "staff");
  console.log(appDate?.staffId, "seleStaffId");

  useEffect(() => {
    setReserveTime(
      staff?.find((i) => i.id?.toString() === appDate?.staffId?.toString())
        ?.availability
    );
  }, [staff, appDate?.staffId]);

  console.log(reserveTime, "reserveTime---");

  useEffect(() => {
    const currentTime = moment().add(1, "hour").format("HH");

    const reserveTimedata = reserveTime
      ?.map((i) => {
        const bookedDate = moment(i?.schedule_date).format("YYYY-MM-DD");
        const dateSame = moment(bookedDate).isSame(appDate?.start_date);
        if (dateSame && i?.status !== 2 && i?.status !== 4) {
          const sueduledDate = i?.schedule_date + " " + i?.slot_time;
          return moment(sueduledDate).format("HH");
        } else {
          return null;
        }
      })
      .filter((i) => i !== null);

    //   const reserveTimeId = reserveTime?.map((i) =>

    //   {
    //     const bookedDate = moment(i?.appointment_date).format("YYYY-MM-DD")
    //     const dateSame = moment(bookedDate).isSame(appDate?.start_date)
    //     if(dateSame){
    //       return {
    //         ...i,
    //        id :  moment(i?.appointment_date).format("HH")
    //      }
    //      }
    //      else {
    //        return null
    //      }
    //   }

    //  ).filter((i) => i !== null);

    const timeSlotList = timeSlotdata?.map((i) => {
      //  const reserveTimeStatus = reserveTimeId?.find((j) => j.id === i.id)
      if (reserveTimedata?.includes(i.id)) {
        return { ...i, disabled: false };
      } else {
        return { ...i, disabled: true };
      }
    });
    setTimeSlotFilter(timeSlotList);

    if (currentDate) {
      setTimeSlotFilter(
        timeSlotList.map((i) => {
          if (i.id <= currentTime) {
            return { ...i, disabled: true };
          } else {
            return i;
          }
        })
      );
    }
  }, [reserveTime, appDate?.staffId, staff]);

  //get rosterId base on time slot
  useEffect(() => {
    //get Roster id base on Select time slot

    reserveTime
      ?.map((i) => {
        const bookedDate = moment(i?.schedule_date).format("YYYY-MM-DD");
        // const rosterIdInTime = i?timeSlot === appDate?.start_time +
        const dateSame = moment(bookedDate).isSame(appDate?.start_date);
        const sueduledDateTime = i?.schedule_date + " " + i?.slot_time;
        const rosterIdInTime = moment(sueduledDateTime).format("HH");
        if (dateSame && i?.status !== 2 && i?.status !== 4) {
          if (rosterIdInTime === appDate?.start_time) {
            return setSelectedRosterId(i?.id);
          }
        } else {
          return null;
        }
      })
      .filter((i) => i !== null);
  }, [appDate?.start_time, reserveTime]);

  useEffect(() => {
    setSelectedStaffList(
      staff
        ?.filter((i) => i !== "")
        ?.map((i) => {
          const availability = i?.availability?.some(
            (k) => moment(k?.schedule_date).isSame(appDate?.start_date),
            "day"
          );
          if (availability) {
            return { id: i.id, value: i.name };
          }
        })
        .filter((i) => i !== undefined)
    );
  }, [staff]);

  console.log(selectedStaffList, "selectedStaffList");

  const handleSubmit = () => {
    if (!appDate?.start_time) {
      toast.error("Time Slot is required");
      return;
    }
    setLoading(true);
    resAppointment({
      appointment_id: openReschedule?.body?.appId,
      staff_id: appDate?.staffId,
      start_time: appDate?.start_time + ":00:00",
      appointment_date: appDate?.start_date,
      roster_id: selectedRosterId ? selectedRosterId : rosteridSelectedTime,
    }).then((res) => {
      setLoading(false);
      setErr(res?.data?.error);
      console.log(res, "res++++");
      if (res?.data?.status) {
        setOpenReschedule({ open: false });
        toast.success(res?.data?.msg);
        navigate("/patient/Appointments");
      } else {
        setOpenReschedule({ open: false });
        toast.error(res?.data?.msg);
      }
    });
  };

  return (
    <>
      <div onClick={() => setOpenReschedule(false)} />
      <div className={styles.centered}>
        <div className={styles.reschModal}>
          <div className={styles.reschModalHeader}>
            <div className={styles.reshHeading}>
              <div
                className={styles.reshModulehead}
                style={{ width: "100%", textAlign: "left" }}
              >
                Reschedule
              </div>
              {
                <button
                  className={styles.closeBtn}
                  onClick={() => setOpenReschedule(false)}
                >
                  <RiCloseLine style={{ marginBottom: "-3px" }} />
                </button>
              }
            </div>
          </div>

          <div className={styles.modelContent}>
            <div className={styles.reschduleforms}>
              <div className={styles.resch_app_time}>
                <div
                  style={{
                    marginBottom: "0",
                    lineHeight: "45px",
                    fontSize: "15px",
                    letterSpacing: "0.02857em",
                  }}
                >
                  Appointment Date:
                </div>
                <Datepicker
                  label=""
                  name="start_date"
                  // display="flex"
                  minDate={new Date()}
                  editable
                  maxDate={moment().add(4, "months").toDate()}
                  defaultDate={appDate?.start_date}
                  onChange={(e) => {
                    setAppDate({
                      ...appDate,
                      staffId: "",
                      start_time: "",
                      start_date: moment(e).format("YYYY-MM-DD"),
                    });
                  }}
                />
              </div>

              <div className={styles.resch_app_time}>
                <div
                  style={{
                    marginBottom: "0",
                    lineHeight: "45px",
                    fontSize: "15px",
                    letterSpacing: "0.02857em",
                  }}
                >
                  Select Doctor
                </div>
                <Select
                  name="staff_id"
                  label=""
                  // value={datetime.start_time}
                  width="100%"
                  fontSize={15}
                  marginTop={0}
                  labelSize="14px"
                  error={err?.staff_id}
                  options={selectedStaffList}
                  onChange={(e) => {
                    var selectedId = e.target.value;
                    setAppDate({
                      ...appDate,
                      staffId: selectedId,
                      start_time: "",
                    });
                  }}
                  disabled={!appDate?.start_date || !staffname}
                  //    error={err?.staff_id}
                  value={appDate.staffId}
                  req
                />
              </div>

              <div className={styles.resch_app_time}>
                <div
                  style={{
                    marginBottom: "0",
                    lineHeight: "45px",
                    fontSize: "15px",
                    letterSpacing: "0.02857em",
                  }}
                >
                  Time Slot
                </div>
                <Select
                  name="start_time"
                  label=""
                  value={appDate?.start_time}
                  className={styles.timeSlot}
                  width="100%"
                  fontSize={15}
                  marginTop={0}
                  labelSize="14px"
                  // error={error?.service_id}
                  options={staffname ? timeSlotFilter : timeSlotApp}
                  onChange={(e) => {
                    if (updateTime[0] === e.target.value) {
                      toast.error("You can not select same appointment time");
                    } else {
                      setAppDate({
                        ...appDate,
                        start_time: e.target.value,
                      });
                    }
                  }}
                  // disabled={!appDate?.staffId}
                  //disabled={expiryDate}
                  req
                />
              </div>
            </div>
            {loading && (
              <div style={{ textAlign: "center" }}>
                <CircularProgress size={30} />
                <p style={{ color: "black" }}>
                  Please wait ! We are validating the details...
                </p>
              </div>
            )}
            <div className={styles.reschBtnGroup}>
              <Btn
                variant="contained"
                bgColor="#fff"
                color="#000"
                width="120px"
                height="40px"
                whiteSpace="nowrap"
                borderRadius="100px"
                click={() => {
                  setOpenReschedule(false);
                }}
              >
                Cancel
              </Btn>
              <Btn
                disabled={loading}
                variant="contained"
                bgColor="#7A51AE"
                color="#ffffff"
                width="120px"
                height="40px"
                whiteSpace="nowrap"
                borderRadius="100px"
                click={handleSubmit}
              >
                Yes
              </Btn>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RescheduleModal;
