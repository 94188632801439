import React from "react";
import classes from "./login.module.css";
import AssessmentStyles from "../../assets/css/assessment.module.css";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import { Input } from "../../Component/UI/CustomInputs";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import toast from "react-hot-toast";
import { Button } from "@mui/material";
import { useContext, useEffect } from "react";
import AuthContext from "../../Auth/AuthContext";
import { Helmet } from "react-helmet";
import { forgetPasswordAdmin } from "../../services/admin/myAccount";
import LoginImage from "../../assets/icons/LoginImage.svg";

const ForgotPasswordAdmin = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState();
  const [msg, setMessage] = useState();
  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const [disableBtn, setDisableBtn] = useState(false);
  const navigate = useNavigate();
  const onSubmit = (e) => {
    e.preventDefault();
    setDisableBtn(true);
    forgetPasswordAdmin(email)
      .then((res) => {
        setDisableBtn(false);
        if (res?.data?.status) {
          setMessage(res.data.msg);
          toast.success("Password sent successfully");
          setTimeout(() => {
            navigate("/smv-admin/signin");
          }, 2000);
          setError();
        } else {
          setError(res.response.data.error);
          setDisableBtn(false);
        }
      })
      .catch((err) => setDisableBtn(false));
  };

  return (
    <>
      <Toaster />
      <div
        className={classes.login_container}
        style={{ background: `url(${LoginImage})` }}
      >
        <div className={classes.login_subcontainer}>
          <form onSubmit={onSubmit}>
            <h1 className={classes.heading}>Forgot Your Password !</h1>

            <label>Email Address:</label>
            <div className={classes.input}>
              <input
                type="text"
                id="email"
                name="email"
                required
                placeholder="Email Address"
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
            <p style={{ color: "red", marginTop: "10px" }}>{error}</p>
            {msg && <p>{msg}</p>}
            <div className={classes.submit} style={{ margin: "20px 0" }}>
              <Button
                variant="contained"
                size="small"
                // disabled={disableBtn}
                style={{
                  textTransform: "none",
                  width: "100%",
                  height: "48px",
                  backgroundColor: "#00B091",
                }}
                onClick={onSubmit}
              >
                {disableBtn ? (
                  <CircularProgress color="inherit" />
                ) : (
                  "Send Password"
                )}
              </Button>
            </div>
            <Link to="/smv-admin/signin">
              <p style={{ color: "#00B091", textDecoration: "underline" }}>
                Back to Sign In
              </p>
            </Link>
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordAdmin;
