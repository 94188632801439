import React from "react";
import { RightTabHeader } from "./RightTabHeader";
import styles from "./rosterManagement.module.css";
import { StaffDataSec } from "./StaffDataSec";
import CreateRoster from "../../../../assets/images/createRoster.svg";
import SelectStaff from "../../../../assets/images/selectStaff.svg";
import { CircularProgress } from "../../../../../node_modules/@mui/material/index";
import { Btn } from "../../../../Samvenda-Patient/UI/Button";
import { useNavigate } from "../../../../../node_modules/react-router-dom/index";
export const StaffDetails = ({
  select,
  selectDropDown,
  tHead,
  isLoadingData,
  timeSlotsBooked,
  tHeadAdd,
  staffTimeSlotdata,
  rosterPermissions,
}) => {
  const navigate = useNavigate();
  const writePermissions = +rosterPermissions?.access?.Write;

  return (
    <>
      {isLoadingData && (
        <div style={{ textAlign: "center" }}>
          <CircularProgress size={30} />
        </div>
      )}
      {select === undefined ? (
        <div className={styles.selectStaffSec}>
          <div className={styles.selectStaffImg}>
            <img src={SelectStaff} alt="staff" />
          </div>
          {/* <div className={styles.selectStaffText}>
            Please select a person from staff list to show roaster plan
          </div> */}
        </div>
      ) : timeSlotsBooked?.length > 0 ? (
        <div className={styles.staffDetailsMain}>
          <div className={styles.rightTabHeader}>
            <RightTabHeader
              select={select}
              selectDropDown={selectDropDown}
              timeSlotsBooked={timeSlotsBooked}
              styles={styles}
              rosterPermissions={rosterPermissions}
            />
          </div>
          <div className={styles.staffMainData}>
            <StaffDataSec
              tHead={tHead}
              timeSlotsBooked={timeSlotsBooked}
              staffTimeSlotdata={staffTimeSlotdata}
            />
          </div>
        </div>
      ) : (
        <div className={styles.selectStaffSec}>
          <div className={styles.selectStaffImg}>
            <img src={CreateRoster} alt="staff" />
          </div>
          {writePermissions ? (
            <Btn
              bgColor="#00B091"
              width="140px"
              height="40px"
              variant="contained"
              borderRadius="100px"
              click={() => navigate("add", { state: { select } })}
            >
              {"Add Roster"}
            </Btn>
          ) : null}

          {/* <div className={styles.selectStaffText}>
           Please select a person from staff list to show roaster plan
         </div> */}
        </div>
      )}
    </>
  );
};
