import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const { BOOKEDAPPOINMENTS } = API;

// not used for now
export const getBookedAppointments = async () => {
  return await axios
    .get(BOOKEDAPPOINMENTS)
    .then((res) => res.data.list)
    .catch((err) => console.log(err));
};

export const getAppointments = async (type) => {
  return await axios
    .get(`/customer/booked/services?type=${type}`)
    .then((res) => res.data.list)
    .catch((err) => console.log(err));
};

export const getreschedulestaff = async (data) => {
  //customer/appointment/getreschedulestaff
  return await axios
    .get(`/customer/appointment/getreschedulestaffNew?appt_date=${data}`)
    .then((res) => res)
    .catch((err) => err);
};

export const getStaffTimeSlot = async (data) => {
  //appointment/time-slot
  return await axios
    .get(`/customer/appointment/time-slot-new?appt_date=${data}`)
    .then((res) => res)
    .catch((err) => err);
};

export const getAppointmentsTabData = async (type) => {
  return await axios
    .get(`/customer/appointments?type=${type}`)
    .then((res) => res.data.list)
    .catch((err) => console.log(err));
};

export const cancelAppointment = async (data) => {
  return await axios
    .post(`customer/cancel/appointment`, data)
    .then((res) => res)
    .catch((err) => err);
};

export const resAppointment = async (data) => {
  //customer/update/reschedule-staff
  return await axios
    .post(`customer/update/reschedule-staffNew`, data)
    .then((res) => res)
    .catch((err) => err);
};

export const consentSubmit = async (data) => {
  return await axios
    .post(`customer/consentValue`, data)
    .then((res) => res)
    .catch((err) => err);
};

export const getConsentData = async (customer_id, consentId) => {
  return await axios
    .get(
      `customer/get/consent?customer_id=${customer_id}&consentId=${consentId}`
    )
    .then((res) => res)
    .catch((err) => err);
};

export const consentSubmitTwo = async (data) => {
  return await axios
    .post(`customer/consentTwoValueUpdate`, data)
    .then((res) => res)
    .catch((err) => err);
};

export const getBannerAPI = async (banner_type) => {
  return await axios
    .get(`customer/get/uploaded/banner?banner_type=${banner_type}`)
    .then((res) => res)
    .catch((err) => err);
};
