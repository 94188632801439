import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import LoginImage from "../../assets/icons/LoginImage.svg";
import logo from "../../assets/icons/icon/logo.svg";
import classes from "./common.module.css";
import { useNavigate } from "react-router-dom";

const Unauthorised = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/user/signin");
  }, []);

  return (
    <div
      className={classes.common_container}
      style={{ background: `url(${LoginImage})` }}
    >
      <div style={{ margin: "10px" }}>
        <img src={logo} alt="" height="50px" />
      </div>

      <div className={classes.commom_subcontainer}>
        <h1 style={{ margin: "20px" }}>Unauthorised User !</h1>
        {/* <img src="" alt="" /> */}
        <p className={classes.common_text}>
          <Link to="/user/signin" style={{ textDecoration: "none" }}>
            Click here{" "}
          </Link>
          to login and view your dashboard.
        </p>
      </div>
    </div>
  );
};

export default Unauthorised;
