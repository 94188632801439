import React from 'react'
import BasicBreadcrumbs from '../../../Component/UI/BreadCrumb/BreadCrumbs'
import {Card} from '../../../Component/UI/Card';

const Settings = () => {
  const link = ["Samvedna","Setting"]
  const card = ["Profile","Password","Roles & Permission","Notifications"]

  return (
    <BasicBreadcrumbs link1={link} link3={"Add Asessment"}>
      {card.map((res)=>{
        return (<Card/>)})}
    </BasicBreadcrumbs>
  )
}

export default Settings