import React from 'react'
import BasicBreadcrumbs from '../../../Component/UI/BreadCrumb/BreadCrumbs'
import PaperWrapper from '../../../Component/UI/PaperWrapper/PaperWrapper'
import TextEditor from '../../../Hooks/TextEditor'

const About = () => {
  const link = ["Samvedna","Content LockManagement","Experts"]
  return (
    <BasicBreadcrumbs link1={link} link3={"Add Asessment"}>
      <PaperWrapper>
        <TextEditor/>
      </PaperWrapper>
    </BasicBreadcrumbs>
  )
}

export default About