//

import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const { ENTERPRISE_ASSESSMENT_LIST, ENTERPRISE_DASHBOARD_DATA } = API;

export const enterpriseAssessmentList = async () => {
  return await axios
    .post(ENTERPRISE_ASSESSMENT_LIST)
    .then((res) => res)
    .catch((err) => err);
};

export const dashboardDatas = async (data) => {
  return await axios
    .post(ENTERPRISE_DASHBOARD_DATA, data)
    .then((res) => res)
    .catch((err) => err);
};

export const getDailyActivity = async (data) => {
  return await axios
    .post(`/enterprise/enterpriseDailyActivity`, data)
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const getMoodActivityData = async (data) => {
  return await axios
    .post(`/enterprise/enterpriseUserMood`, data)
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const getSleepTrackerData = async (data) => {
  return await axios
    .post(`/enterprise/enterpriseUserSleepTracker`, data)
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const getSleepQualityData = async (data) => {
  return await axios
    .post(`/enterprise/enterpriseUserSleepQuality`, data)
    .then((res) => res)
    .catch((err) => console.log(err));
};
