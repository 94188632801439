import React, { useEffect } from "react";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";
import { ServicesCards } from "../../../Component/UI/ServicesCard/ServicesCard";
import { useState } from "react";
import classes from "./plans.module.css";
import NoServices from "./NoJoinSupport";
import NoJoinSupport from "./NoJoinSupport";
import axios from "../../../config/axiosConfig";
import {
  ToastBar,
  Toaster,
} from "../../../../node_modules/react-hot-toast/dist/index";

import {
  getCustomerAllTakenAssessment,
  getEnterpriseAssessment,
} from "../../../services/patient/assessment";
import { useContext } from "react";
import AuthContext from "../../../Auth/AuthContext";
import { encryption } from "../../../config/encryptDecrypt";
import PlansCard from "./PlansCard";

const Plans = () => {
  const [isLoading, setLsLoading] = useState(false);
  const [isLoadingAss, setLsLoadingAss] = useState(false);
  const [allData, setAllData] = useState();
  const ctx = useContext(AuthContext);
  const enterpriseId = ctx.loginUser.customer.enterprise_id;
  const [disableButton, setDisableButton] = useState(false);
  const [customerAssessment, setCustomerAssessment] = useState();
  useEffect(() => {
    setLsLoading(true);
    getCustomerAllTakenAssessment().then((data) => {
      setLsLoading(false);
      setCustomerAssessment(data);
    });
  }, []);

  useEffect(() => {
    if (!!enterpriseId) {
      setLsLoadingAss(true);
      getEnterpriseAssessment(enterpriseId).then((res) => {
        setLsLoadingAss(true);
        setAllData(res);
        const id = res?.assessment_id?.toString();
        const match = customerAssessment?.some(
          (i) => i?.assessment_id?.toString() === id
        );
        const assessmentLength = Object.keys(res).length;
        const showDes = !match && !!assessmentLength;
        console.log(showDes, "showDes");
        if (match === undefined) return;
        if (ctx?.loginUser?.customer?.enterprise_id !== null) {
          if (!showDes) {
            setDisableButton(true);
          }
        }
      });
    }
  }, [enterpriseId, customerAssessment]);
  const handleClick = () => {
    if (!disableButton) {
      const id = allData?.assessment_id?.toString();
      const encryptedAssessmentId = window.btoa(encryption(id));
      const slug = ctx?.loginUser.customer.enterprise_slug;
      const baseUrl = window.location?.origin;
      const link = `${baseUrl}/enterprise/${slug}/assessment/${encryptedAssessmentId}`;
      window.open(link, "_blank");
    }
  };

  return (
    <>
      <Toaster />
      {isLoading ? (
        <div style={{ textAlign: "center", marginTop: "40px" }}>
          <CircularProgress size={30} />
          <p style={{ color: "black" }}>
            Please wait ! We are validating the details...
          </p>
        </div>
      ) : (
        <div className={classes.service_card}>
          {allData?.assessments?.id ? (
            <PlansCard
              data={allData}
              disableButton={disableButton}
              handleClick={handleClick}
            />
          ) : (
            <NoJoinSupport />
          )}
        </div>
      )}
    </>
  );
};
export default Plans;
