import * as React from "react";
import Skeleton from "@mui/material/Skeleton";

export const SkeletonLoader = () => {
  return (
    <div style={{ display: "flex", width: 490, gap: 25 }}>
      <div style={{ width: "50%" }}>
        <Skeleton variant="circular" height={200} />
      </div>
      <div style={{ width: "50%" }}>
        <Skeleton height={60} animation="wave" />
        <Skeleton height={60} animation="wave" />
        <Skeleton height={60} animation={false} />
      </div>
    </div>
  );
};

export const SkeletonLoaderNew = () => {
  return (
    <div style={{ display: "flex", width: 450, gap: 25 }}>
      <div style={{ width: "100%" }}>
        <Skeleton height={40} animation="wave" />
        <Skeleton height={40} animation="wave" />
        <Skeleton height={40} animation={false} />
      </div>
    </div>
  );
};
