import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const {
  GETPATIENTS,
  ADDPATIENT,
  EDITPATIENT,
  PATIENTSTATUS,
  GETPATIENTDETAILS,
} = API;

export const patientGet = async (searchKey, from, to) => {
  return await axios
    .get(GETPATIENTS + `?search_key=${searchKey}&fromdate=${from}&todate=${to}`)
    .then((res) => res.data.list)
    .catch((err) => console.log(err));
};

export const getPatientDetails = async (id) => {
  return await axios
    .get(GETPATIENTDETAILS + id)
    .then((res) => {
      return res.data.list;
    })
    .catch((err) => console.log(err));
};

export const addPatient = async (data) => {
  return await axios
    .post(ADDPATIENT, data)
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const editPatient = async (data) => {
  return await axios
    .post(EDITPATIENT, data)
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const patientStatus = async (customer_id, status) => {
  await axios
    .post(PATIENTSTATUS, {
      customer_id,
      status,
    })
    .then((res) => console.log(res))
    .catch((err) => console.error(err));
};

export const getSelfAssessment = async (id, status) => {
  return await axios
    .get(`/admin/customer/assessment?customer_id=${id}&status=${status}`)
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const getPatientSubscriptions = async (customer_id) => {
  return await axios
    .post(`admin/patient/subscription`, { customer_id })
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const getPatientService = async (customer_id) => {
  return await axios
    .post(`admin/get/bookingservices`, { customer_id })
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const importExcelSheet = async (data) => {
  return await axios
    .post("/admin/importPatient", data)
    .then((res) => res)
    .catch((err) => err);
};

export const getReportsPatient = async (customer_id, dataReports) => {
  return await axios
    .get(
      `/admin/get/customerReport?id=${customer_id}&search_key=${
        dataReports?.reportType ? dataReports?.reportType : ""
      }`
    )
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const importPdfFile = async (data) => {
  return await axios
    .post("/admin/add/customerReport", data)
    .then((res) => res)
    .catch((err) => err);
};

export const deleteReportsList = async (testimonial_id) => {
  return await axios
    .get(`admin/delete/customerReport?id=${testimonial_id}`)
    .then((res) => res)
    .catch((err) => err);
};

export const addAnnouncement = async (data) => {
  return await axios
    .post("/admin/add/banner", data)
    .then((res) => res)
    .catch((err) => err);
};

export const getBannerImg = async (customer_id, dataReports) => {
  return await axios
    .get(`/admin/get/banner`)
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const deleteAnnouncement = async (id) => {
  return await axios
    .get(`admin/delete/banner?id=${id}`)
    .then((res) => res)
    .catch((err) => err);
};

export const changeStatusAnnouncement = async (id, status) => {
  await axios
    .post(`admin/banner/status/change`, {
      id,
      status,
    })
    .then((res) => console.log(res))
    .catch((err) => console.error(err));
};
