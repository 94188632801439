import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { getDoctor, getDoctorsList } from "../../../services/patient/doctor";
import { Carousel } from "./Components/Carousel";
import { ExpertCard } from "./Components/ExpertCard";
import Modal from "./Components/Popup";
import ExpertImg from "./Images/drImg.png";
import Image85 from "../../../assets/images/image 85.png";
import Image86 from "../../../assets/images/image 86.png";
export const KnowOurExperts = ({ styles }) => {
  const [popupAction, setPopupAction] = useState({ open: false });
  const customProps = {
    openPopup: (data) => {
      setPopupAction({ ...popupAction, open: true, data });
    },
  };
  const { openPopup } = customProps;
  const expertList = [
    {
      staff_image: ExpertImg,
      name: "Dr. Jayashree Dasgupta",
      qualification: "Neuropsychologist",
    },
    {
      staff_image: ExpertImg,
      name: "Dr. Jayashree Dasgupta",
      qualification: "Neuropsychologist",
    },
    {
      staff_image: ExpertImg,
      name: "Dr. Jayashree Dasgupta",
      qualification: "Neuropsychologist",
    },
    {
      staff_image: ExpertImg,
      name: "Dr. Jayashree Dasgupta",
      qualification: "Neuropsychologist",
    },
    {
      staff_image: ExpertImg,
      name: "Dr. Jayashree Dasgupta",
      qualification: "Neuropsychologist",
    },
  ];
  const [doctorList, setDoctorList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    // getDoctor().then((res) => {
    //   setIsLoading(false);
    //   setDoctorList(res?.data?.list);
    // });
    getDoctorsList().then((res) => {
      setIsLoading(false);
      setDoctorList(res?.data?.list);
    });
  }, []);
  return (
    <div className={styles.knowOurExperts}>
      <h3 className={styles.title}>Know Our Experts</h3>
      <img className={styles.bgImage85} src={Image85} alt="image85" />
      <div className={styles.knowOurExpertSec}>
        {/* Big Screens */}
        <div
          className={styles.slider}
          style={{
            display: doctorList?.length > 2 ? "block" : "flex",
            justifyContent: "center",
          }}
        >
          {doctorList?.length > 3 ? (
            <Carousel
              data={doctorList}
              customProps={customProps}
              Component={ExpertCard}
              slides={3}
            />
          ) : (
            doctorList?.map((item) => (
              <>
                <ExpertCard item={item} openPopup={openPopup} />
              </>
            ))
          )}
        </div>
        {popupAction.open && (
          <Modal setPopupAction={setPopupAction} modalData={popupAction.data} />
        )}
        {/* Mobile Responsive */}
        {/* <div className={styles.responsive_carousel}>
            {assessmentsData ? (
              <Carousel data={assessmentsData} Component={AssessmentCard} slides={2} />
            ) : 
            null}
          </div> */}
      </div>
      <img className={styles.bgImage86} src={Image86} alt="image86" />
    </div>
  );
};
