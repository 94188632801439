import * as React from "react";
import { styled } from "@mui/material/styles";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

import { CardActionArea } from "@mui/material";

import { Buttons } from "../Button/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import img from "../../../assets/images/Group320.svg";
import img1 from "../../../assets/images/Group321.svg";
import img2 from "../../../assets/images/Group322.svg";
import img3 from "../../../assets/images/Group323.svg";
import img4 from "../../../assets/images/Group324.svg";
import { BsFilter } from "react-icons/bs";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const HelpAndSupport = () => {
  return (
    <Grid container style={{ marginLeft: "0px" }}>
      <Grid item xs={6} md={4} lg={4} style={{ marginTop: "19px" }}>
        <Box sx={{ margin: "0px" }}>
          <Card
            style={{
              padding: "20px",
              width: "500px",
              borderRadius: "10px",
              paddingBottom: "70px",
            }}
          >
            <Grid container spacing={1} style={{ marginTop: "5px" }}>
              <Grid item xs={6}>
                {" "}
                <h3
                  style={{
                    textAlign: "left",
                    fontSize: "20px",
                    marginBottom: "20px",
                  }}
                >
                  Connect With Us
                </h3>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  textAlign: "right",
                  fontSize: "18px",
                  color: "#85219C",
                }}
              ></Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Card style={{ width: "450px", borderRadius: "10px" }}>
                  <CardActionArea>
                    <CardContent>
                      <Grid container spacing={1} style={{ marginTop: "5px" }}>
                        <Grid item xs={2}>
                          <img src={img} />
                        </Grid>

                        <Grid
                          item
                          xs={8}
                          style={{ display: "flex", textAlign: "left" }}
                        >
                          <Grid
                            style={{
                              marginLeft: "5px",
                              fontSize: "15px",
                              marginTop: "10px",
                            }}
                          >
                            <p>Call Us</p>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        spacing={1}
                        style={{ marginTop: "-39px" }}
                      >
                        <Grid item xs={2} md={2} lg={2}></Grid>

                        <Grid
                          item
                          xs={6}
                          md={6}
                          lg={6}
                          style={{ display: "flex", textAlign: "left" }}
                        >
                          <Grid
                            style={{
                              marginLeft: "5px",
                              fontSize: "15px",
                              marginTop: "3px",
                              paddingBottom: "10px",
                            }}
                          >
                            <h4>+(31)123456789</h4>
                          </Grid>
                        </Grid>

                        <Grid item xs={4} md={4} lg={4}>
                          <Grid>
                            <Buttons
                              name="Call Now"
                              style={{
                                backgroundColor: "#7A51AE",
                                marginTop: "0px",
                                borderRadius: "8px",
                                padding: "5px 20px 5px 20px",
                                marginTop: "-20px",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Card
                  style={{
                    width: "450px",
                    borderRadius: "10px",
                    marginTop: "20px",
                  }}
                >
                  <CardActionArea>
                    <CardContent>
                      <Grid container spacing={1} style={{ marginTop: "5px" }}>
                        <Grid item xs={2}>
                          <img src={img1} />
                        </Grid>

                        <Grid
                          item
                          xs={8}
                          style={{ display: "flex", textAlign: "left" }}
                        >
                          <Grid
                            style={{
                              marginLeft: "5px",
                              fontSize: "15px",
                              marginTop: "10px",
                            }}
                          >
                            <p>Write Us</p>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        spacing={1}
                        style={{ marginTop: "-39px" }}
                      >
                        <Grid item xs={2} md={2} lg={2}></Grid>

                        <Grid
                          item
                          xs={6}
                          md={6}
                          lg={6}
                          style={{ display: "flex", textAlign: "left" }}
                        >
                          <Grid
                            style={{
                              marginLeft: "5px",
                              fontSize: "15px",
                              marginTop: "3px",
                              paddingBottom: "10px",
                            }}
                          >
                            <h4>contact@samvednacare.com</h4>
                          </Grid>
                        </Grid>

                        <Grid item xs={4} md={4} lg={4}>
                          <Grid>
                            <Buttons
                              name="Email Us"
                              style={{
                                backgroundColor: "#7A51AE",
                                marginTop: "0px",
                                padding: "5px 20px 5px 20px",
                                borderRadius: "8px",
                                marginTop: "-20px",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Card
                  style={{
                    width: "450px",
                    borderRadius: "10px",
                    marginTop: "20px",
                  }}
                >
                  <CardActionArea>
                    <CardContent>
                      <Grid container spacing={1} style={{ marginTop: "5px" }}>
                        <Grid item xs={2}>
                          <img src={img2} />
                        </Grid>

                        <Grid
                          item
                          xs={8}
                          style={{ display: "flex", textAlign: "left" }}
                        >
                          <Grid
                            style={{
                              marginLeft: "5px",
                              fontSize: "15px",
                              marginTop: "10px",
                            }}
                          >
                            <p>Say Hi on Whatsapp</p>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        spacing={1}
                        style={{ marginTop: "-39px" }}
                      >
                        <Grid item xs={2} md={2} lg={2}></Grid>

                        <Grid
                          item
                          xs={6}
                          md={6}
                          lg={6}
                          style={{ display: "flex", textAlign: "left" }}
                        >
                          <Grid
                            style={{
                              marginLeft: "5px",
                              fontSize: "15px",
                              marginTop: "3px",
                              paddingBottom: "10px",
                            }}
                          >
                            <h4>contact@samvednacare.com</h4>
                          </Grid>
                        </Grid>

                        <Grid item xs={4} md={4} lg={4}>
                          <Grid>
                            <Buttons
                              name="Say Hi"
                              style={{
                                backgroundColor: "#7A51AE",
                                marginTop: "0px",
                                padding: "5px 20px 5px 20px",
                                borderRadius: "8px",
                                marginTop: "-20px",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Grid>

      <Grid item xs={6} md={4} lg={4}>
        <Box sx={{ margin: "20px" }}>
          <Card
            style={{
              padding: "20px",
              width: "500px",
              borderRadius: "10px",
              paddingBottom: "60px",
            }}
          >
            <Grid container spacing={1} style={{ marginTop: "5px" }}>
              <Grid item xs={12}>
                {" "}
                <h3
                  style={{
                    textAlign: "left",
                    fontSize: "20px",
                    marginBottom: "20px",
                  }}
                >
                  Meet Us At Our Location
                </h3>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Card
                  style={{
                    width: "450px",
                    borderRadius: "10px",
                    marginTop: "0px",
                  }}
                >
                  <CardActionArea>
                    <CardContent>
                      <Grid container spacing={1} style={{ marginTop: "5px" }}>
                        <Grid item xs={2}>
                          <img src={img3} />
                        </Grid>

                        <Grid
                          item
                          xs={8}
                          style={{ display: "flex", textAlign: "left" }}
                        >
                          <Grid
                            style={{
                              marginLeft: "5px",
                              fontSize: "15px",
                              marginTop: "10px",
                              color: "#7A51AE",
                            }}
                          >
                            <p>Gurugram</p>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        spacing={1}
                        style={{ marginTop: "-39px" }}
                      >
                        <Grid item xs={2}></Grid>

                        <Grid
                          item
                          xs={8}
                          style={{ display: "flex", textAlign: "left" }}
                        >
                          <Grid
                            style={{
                              marginLeft: "5px",
                              fontSize: "15px",
                              marginTop: "3px",
                              paddingBottom: "10px",
                            }}
                          >
                            <h4>
                              Samvedna Senior Care, G-7 Oriental Villas, Sushant
                              Lok III, Sector 57, Gurugram
                            </h4>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container spacing={1} style={{ marginTop: "5px" }}>
                        <Grid item xs={2}></Grid>

                        <Grid
                          item
                          xs={8}
                          style={{ display: "flex", textAlign: "left" }}
                        >
                          <Grid
                            style={{
                              marginLeft: "5px",
                              fontSize: "15px",
                              marginTop: "3px",
                              paddingBottom: "10px",
                            }}
                          >
                            <Buttons
                              name="View Map"
                              style={{
                                backgroundColor: "#7A51AE",
                                marginTop: "0px",
                                padding: "5px 20px 5px 20px",
                                borderRadius: "8px",
                                marginTop: "-20px",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Card
                  style={{
                    width: "450px",
                    borderRadius: "10px",
                    marginTop: "20px",
                  }}
                >
                  <CardActionArea>
                    <CardContent>
                      <Grid container spacing={1} style={{ marginTop: "5px" }}>
                        <Grid item xs={2}>
                          <img src={img4} />
                        </Grid>

                        <Grid
                          item
                          xs={8}
                          style={{ display: "flex", textAlign: "left" }}
                        >
                          <Grid
                            style={{
                              marginLeft: "5px",
                              fontSize: "15px",
                              marginTop: "10px",
                              color: "#7A51AE",
                            }}
                          >
                            <p>New Delhi</p>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        spacing={1}
                        style={{ marginTop: "-39px" }}
                      >
                        <Grid item xs={2}></Grid>

                        <Grid
                          item
                          xs={8}
                          style={{ display: "flex", textAlign: "left" }}
                        >
                          <Grid
                            style={{
                              marginLeft: "5px",
                              fontSize: "15px",
                              marginTop: "3px",
                              paddingBottom: "10px",
                            }}
                          >
                            <h4>
                              Samvedna Senior Care, C-13 Anand Niketan, New
                              Delhi
                            </h4>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container spacing={1} style={{ marginTop: "5px" }}>
                        <Grid item xs={2}></Grid>

                        <Grid
                          item
                          xs={8}
                          style={{ display: "flex", textAlign: "left" }}
                        >
                          <Grid
                            style={{
                              marginLeft: "5px",
                              fontSize: "15px",
                              marginTop: "3px",
                              paddingBottom: "10px",
                            }}
                          >
                            <Buttons
                              name="View Map"
                              style={{
                                backgroundColor: "#7A51AE",
                                marginTop: "0px",
                                padding: "5px 20px 5px 20px",
                                borderRadius: "8px",
                                marginTop: "-20px",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
};
