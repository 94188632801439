import React, { useEffect } from "react";
import { CustomTable } from "../../../Component/UI/Table";
import SwitchButton from "../../../Component/UI/SwitchButton/Switch";
import { NavLink } from "react-router-dom";
import { TbEdit } from "react-icons/tb";
import { TbSend } from "react-icons/tb";
import { IoCloseOutline } from "react-icons/io5";
import axios from "../../../config/axiosConfig";

import { MdMail } from "react-icons/md";

import { fetchUpcomingEvents } from "../../../Redux/Slices/Events/Events";
import { useDispatch, useSelector } from "react-redux";
import { to12Hour, to12Hours, YtoD } from "../../../config/formattedDate";
import { Edit } from "../../../assets/icons/Edit";
import { Cross } from "../../../assets/icons/Cross";
import { Send } from "../../../assets/icons/Send";
import Popup from "../../../Component/UI/Popup/Popup";
import {
  setFilter,
  setFrom_date,
  setSearch_key,
  setTo_date,
  setClear,
} from "../../../Redux/Slices/AdminFilters/Search";

export const Upcoming = ({ permissions }) => {
  const dispatch = useDispatch();
  const { search_key, from_date, to_date, filter } = useSelector(
    (i) => i.SearchFilters
  );

  const { data, loading, hasErrors } = useSelector((state) => state.Events);
  const [popupAction, setPopupAction] = React.useState({
    open: false,
    action: "",
    name: "",
  });
  const fullpermissions = +permissions?.listControl?.access?.["Full Control"];
  const writePermissions = +permissions?.listControl?.access?.Write;

  useEffect(() => {
    dispatch(fetchUpcomingEvents(search_key, from_date, to_date));
  }, [dispatch, filter]);

  useEffect(() => {
    return () => {
      //dispatch(setSearch_key(""));
      dispatch(setTo_date(""));
      dispatch(setFrom_date(""));
      dispatch(setFilter());
      dispatch(setClear());
    };
  }, []);

  const toggleStatus = async (event_id, status) => {
    let status_value = status ? 0 : 1;
    await axios
      .get("/admin/change/events/status", {
        params: {
          event_id,
          status: status_value,
        },
      })
      .then((res) => {
        dispatch(fetchUpcomingEvents());
      })
      .catch((err) => console.error(err));
  };

  const handleDelete = async (event_id, status) => {
    await axios
      .get("/admin/change/events/status", {
        params: {
          event_id,
          status: 2,
        },
      })
      .then((res) => {
        dispatch(fetchUpcomingEvents());
      })
      .catch((err) => console.error(err));
  };

  const handleSend = async (event_id) => {
    setPopupAction({ ...popupAction, open: false });
    await axios
      .get("admin/send/event/email", {
        params: {
          event_id,
        },
      })
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
  };

  const tBodyData = data?.upcoming?.map(
    ({
      name,
      speaker_name,
      event_date,
      start_time,
      end_time,
      type,
      status,
      id,
      coordinator,
      description,
    }) => {
      const time = to12Hour(start_time) + " - " + to12Hour(end_time);
      let Mode = type ? (
        <div style={{ color: "green" }}>Online</div>
      ) : (
        "Offline"
      );
      const date = YtoD(event_date.split(" ")[0]);
      return {
        date,
        name: (
          <NavLink
            to="/Eventdetails"
            state={{
              name,
              speaker_name,
              event_date,
              start_time,
              end_time,
              type,
              status,
              id,
              coordinator,
              description,
            }}
            style={{ textDecoration: "none", color: "black" }}
          >
            {name}
          </NavLink>
        ),
        speaker_name,
        time,
        Mode,
        ...(fullpermissions && {
          status: (
            <SwitchButton
              status={status}
              onClick={(status) => toggleStatus(id, !status)}
            />
          ),
        }),
        ...(writePermissions && {
          action: (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{ color: "black", cursor: "pointer" }}
                onClick={() =>
                  setPopupAction({
                    ...popupAction,
                    open: true,
                    action: () => handleSend(id),
                    name,
                  })
                }
              >
                <Send />
              </div>
              <NavLink
                to="/editevents"
                state={{ from: "edit", event_id: id }}
                style={{ color: "black", textDecoration: "none" }}
              >
                <Edit />
              </NavLink>
              <div
                style={{ color: "black", cursor: "pointer" }}
                onClick={(status) => handleDelete(id, status)}
              >
                <Cross />
              </div>
            </div>
          ),
        }),
      };
    }
  );

  const tHead = [
    "Date",
    "Event Name",
    "Speaker Name",
    "Time",
    "Mode",
    ...(fullpermissions ? ["Status"] : []),
    ...(writePermissions ? ["Action"] : []),
  ];

  return (
    <>
      <Popup
        popupAction={popupAction}
        setPopupAction={setPopupAction}
        title="Do you want to notify all the users about this event?"
        heading="Event Notification"
        // icon={<MdMail size={40} />}
      />
      <CustomTable tHead={tHead} tBody={tBodyData} />
    </>
  );
};
