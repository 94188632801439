import { createSlice } from "@reduxjs/toolkit";

const getPatientsSlice = createSlice({
  name: "get patients",
  initialState: { data: [] },
  reducers: {
    getPatients: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { getPatients } = getPatientsSlice.actions;


export default getPatientsSlice.reducer;