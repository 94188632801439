import * as React from "react";
import { Btn } from "../../UI/Button";
import { Link } from "../../../../node_modules/react-router-dom/index";
import Close from "../../../assets/icons/icon/x.svg";
import Mwb from "../../../assets/icons/icon/dementia screening.svg";
import myself from "../../../assets/images/myself.png";
import someoneElse from "../../../assets/images/someoneelse.png";
import calendarImg from "../../../assets/icons/icon/calenderIcon.svg";
import SamvednaLogo from "../../../assets/icons/icon/default_profile.svg";

import {
  setAssessment_for,
  setRelation,
  setRelationId,
  setRelationName,
  setRetakeType,
} from "../../../Redux/Reducers/PatientReducers/qa_storage/qa_storage";
import { useDispatch, useSelector } from "react-redux";
import styles from "./AssessmentSidebar.module.css";
import {
  Button,
  CircularProgress,
  Drawer,
  Paper,
} from "../../../../node_modules/@mui/material/index";
import moment from "../../../../node_modules/moment/moment";

export default function AssessmentPopup({
  popupAction,
  setPopupAction,
  title,
  handleClick,
  category,
}) {
  const dispatch = useDispatch();
  const { assessment_for } = useSelector((state) => state.qa_storage);

  const handleClose = () => setPopupAction({ right: false });
  const handlebutton = (e) => {
    handleClick(e);
    dispatch(setAssessment_for(e.assessment_for));
    dispatch(setRelation(e?.is_mapped?.customer_data.relation));
    dispatch(setRelationName(e?.is_mapped?.customer_data.name));
    dispatch(setRelationId(e?.is_mapped?.customer_data.id));
    handleClose();
  };

  const YourselfAssessment = category.filter((item) => {
    return item.assessment_for === "Myself" && !item.is_mapped.status;
  });

  const RelativeAssessments = category.filter((item) => {
    return item.assessment_for === "someone_else" && !item.is_mapped.status;
  });

  const CompletedAssessments = category.filter((item) => {
    return item.is_mapped?.status === "completed";
  });
  const inProgressAssessments = category.filter((item) => {
    return item.is_mapped?.status === "inprogress";
  });

  return (
    <Drawer
      anchor={"right"}
      open={popupAction.right}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderTopLeftRadius: "15px",
          borderBottomLeftRadius: "15px",
        },
      }}
    >
      <div role="presentation" className={styles.sidebarDementia}>
        <div className={styles.assessment_popup}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "20px 12px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p
                style={{
                  fontSize: "24px",
                  fontWeight: "500",
                  lineHeight: "28px",
                }}
              >
                {title}
              </p>
              <div onClick={handleClose} style={{ cursor: "pointer" }}>
                <img src={Close} alt="close" height={"30px"} />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div>
                <img src={Mwb} alt="Mwb" height="96px" />
              </div>
              <div
                style={{
                  margin: "20px 15px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  fontSize: "18px",
                }}
              >
                Dementia Screening
              </div>
            </div>

            {!!(YourselfAssessment.length + RelativeAssessments.length) && (
              <div style={{ margin: "20px 0px" }}>
                <div style={{ fontWeight: "500" }}>
                  Start dementia screening for
                </div>

                <div style={{ display: "flex", margin: "15px 0 0 0" }}>
                  {!!YourselfAssessment.length && (
                    <Button
                      variant="contained"
                      sx={{
                        width: "200px",
                        height: "50px",
                        background: "#ffffff",
                        color: "#000",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#ffffff90",
                          color: "#000",
                        },
                        marginRight: "10px",
                      }}
                      onClick={() => handlebutton(YourselfAssessment[0])}
                    >
                      <img
                        src={myself}
                        alt="myself"
                        height="32px"
                        style={{ marginRight: "5px" }}
                      />
                      Yourself
                    </Button>
                  )}
                  {!!RelativeAssessments.length && (
                    <Button
                      variant="contained"
                      sx={{
                        width: "200px",
                        background: "#ffffff",
                        color: "#000",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#ffffff90",
                          color: "#000",
                        },
                        marginLeft: "10px",
                      }}
                      onClick={() => handlebutton(RelativeAssessments[0])}
                    >
                      <img
                        src={someoneElse}
                        alt="someone else"
                        height="32px"
                        style={{ marginRight: "10px" }}
                      />
                      Someone Else
                    </Button>
                  )}
                </div>
              </div>
            )}

            <div style={{ margin: "20px 0px" }}>
              <p style={{ fontWeight: "500", marginBottom: "10px" }}>
                On Going
              </p>
              {inProgressAssessments.length ? (
                inProgressAssessments.map((item) => {
                  return (
                    <>
                      <div>
                        <Paper
                          sx={{
                            width: "350px",
                            borderRadius: "15px",
                            margin: "10px 0",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <div className={styles.progressContainerWrapper}>
                              <CircularProgress
                                variant={"determinate"}
                                sx={{
                                  color: "#3DD365",
                                  padding: "10px",
                                }}
                                size={"120px"}
                                thickness={10}
                                value={item.is_mapped?.percentage}
                              />
                              <div className={styles.progressTextContainer}>
                                <p className={styles.progressValue}>
                                  {Math.floor(item.is_mapped?.percentage)} %
                                </p>
                              </div>
                            </div>
                            <div>
                              <p
                                style={{
                                  lineHeight: "30px",
                                  fontWeight: "500",
                                }}
                              >
                                {item.assessment_name.slice(0, 25)}
                                {item.assessment_name.length > 25 && "..."}
                                {item.is_mapped?.customer_data?.relation &&
                                  ` (${item.is_mapped?.customer_data?.relation})`}
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={calendarImg}
                                  alt="calendar"
                                  height="18px"
                                />
                                <p
                                  style={{
                                    lineHeight: "30px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {moment(item.is_mapped?.updated_at).format(
                                    "DD-MM-YYYY"
                                  ) || moment().format("DD-MM-YYYY")}
                                </p>
                              </div>
                              <Btn
                                variant="contained"
                                width="200px"
                                height="35px"
                                margin="10px 0 0 0"
                                click={() => handlebutton(item)}
                              >
                                Continue
                              </Btn>
                            </div>
                          </div>
                        </Paper>
                      </div>
                    </>
                  );
                })
              ) : (
                <>
                  <Paper
                    sx={{
                      width: "350px",
                      borderRadius: "15px",
                      margin: "10px 0",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        padding: "10px",
                        alignItems: "center",
                      }}
                    >
                      <img src={SamvednaLogo} alt="" height="80px" />
                      <div style={{ marginLeft: "10px" }}>
                        You don't have any on going assessments
                      </div>
                    </div>
                  </Paper>
                </>
              )}
            </div>
            <div>
              <p style={{ fontWeight: "500", marginBottom: "10px" }}>
                Completed
              </p>
              <div>
                {CompletedAssessments.length ? (
                  CompletedAssessments.map((item) => {
                    return (
                      <Paper
                        sx={{
                          width: "350px",
                          borderRadius: "15px",
                          margin: "10px 0",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div className={styles.progressContainerWrapper}>
                            <CircularProgress
                              sx={{ color: "#3DD365", padding: "10px" }}
                              value={100}
                              variant={"determinate"}
                              size={"120px"}
                              thickness={10}
                            />
                            <div className={styles.progressTextContainer}>
                              <p className={styles.progressValue}> {100} % </p>
                            </div>
                          </div>
                          <div>
                            <p
                              style={{ lineHeight: "30px", fontWeight: "500" }}
                            >
                              {item.assessment_name.slice(0, 25)}
                              {item.assessment_name.length > 25 && "..."}
                              {item.is_mapped?.customer_data?.relation &&
                                ` (${item.is_mapped?.customer_data?.relation})`}
                            </p>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={calendarImg}
                                alt="calendar"
                                height="18px"
                              />
                              <p
                                style={{
                                  lineHeight: "30px",
                                  marginLeft: "5px",
                                }}
                              >
                                {moment(item.is_mapped?.updated_at).format(
                                  "DD-MM-YYYY"
                                ) || moment().format("DD-MM-YYYY")}
                              </p>
                            </div>
                            <div>
                              {item.is_mapped?.retake ? (
                                <Btn
                                  variant="contained"
                                  width="110px"
                                  height="35px"
                                  margin="5px 5px 0 0"
                                  disabled={!item.is_mapped?.retake}
                                  click={() => {
                                    handlebutton(item);
                                    dispatch(setRetakeType("retake"));
                                  }}
                                >
                                  Retake
                                </Btn>
                              ) : null}
                              <Btn
                                variant="contained"
                                width={
                                  item.is_mapped?.retake ? "110px" : "220px"
                                }
                                height="35px"
                                margin="5px 5px 0 0"
                              >
                                <Link
                                  to="/patient/assessment/results"
                                  state={{
                                    data: item,
                                  }}
                                  style={{
                                    color: "white",
                                    textDecoration: "none",
                                    width: "inherit",
                                  }}
                                >
                                  See Result
                                </Link>
                              </Btn>
                            </div>
                          </div>
                        </div>
                      </Paper>
                    );
                  })
                ) : (
                  <Paper
                    sx={{
                      width: "350px",
                      borderRadius: "15px",
                      margin: "10px 0",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        padding: "10px",
                        alignItems: "center",
                      }}
                    >
                      <img src={SamvednaLogo} alt="" height="80px" />
                      <div style={{ marginLeft: "10px" }}>
                        You don't have any completed assessments
                      </div>
                    </div>
                  </Paper>
                )}
              </div>
            </div>

            {/* <div className={styles.assessmentCardConatiner}>
              {category.map((item, index) => {
                return item?.is_mapped?.status !== "completed" ? (
                  <>
                    <div className={styles.assessmentCardInside}>
                      <div
                        style={
                          item?.is_mapped?.status === "inprogress"
                            ? { visibility: "auto" }
                            : { visibility: "hidden" }
                        }
                      >
                        <span style={{ fontSize: "10px" }}>
                          {Math.floor(item?.is_mapped?.percentage)} % completed
                        </span>
                        <ProgressBar
                          progress={Math.floor(item?.is_mapped?.percentage)}
                        />
                      </div>
                      <h4>
                        {item.name} for {item.assessment_name.slice(0, 25)}
                        {item.assessment_name.length > 25 && "..."}
                      </h4>
                      <Btn
                        key={index}
                        variant="contained"
                        width="100%"
                        height="35px"
                        click={() => handlebutton(item)}
                        margin="10px 10px 0 0"
                      >
                        {item?.is_mapped?.status === "inprogress"
                          ? `Continue`
                          : `Take Assessment`}
                      </Btn>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.assessmentCardInside}>
                      <div
                        style={
                          item?.is_mapped?.status === "inprogress"
                            ? { visibility: "auto" }
                            : { visibility: "hidden" }
                        }
                      >
                        <span style={{ fontSize: "10px" }}>
                          100 % completed
                        </span>
                        <ProgressBar progress={100} />
                      </div>
                      <h4>
                        {item.name} for {item.assessment_name.slice(0, 25)}
                        {item.assessment_name.length > 25 && "..."}
                      </h4>
                      <Btn
                        key={index}
                        variant="contained"
                        width="100%"
                        height="35px"
                        margin="10px 10px 0 0"
                      >
                        <Link
                          to="/patient/assessment/results"
                          state={{
                            data: item,
                          }}
                          style={{
                            color: "white",
                            textDecoration: "none",
                            width: "inherit",
                          }}
                          onClick={handleClose}
                        >
                          See Result
                        </Link>
                      </Btn>
                    </div>
                  </>
                );
              })}
            </div> */}
          </div>
        </div>
      </div>
    </Drawer>
  );
}
