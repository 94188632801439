import React, { useState } from "react";
import styles from "./testimonial.module.css";
import { TestStarView } from "./TestimonialStar";
export const Testimonialcard = ({ item, index }) => {
  const [more, setMore] = useState(true);
  const slicedChars = window.innerWidth <= 480 ? 170 : 280;
  const isMoreReq = item?.desc?.join(" ").length > slicedChars + 10;
  return (
    <div className={styles.testCard}>
      <div className={styles.testStar}>
        <TestStarView starRating={item.rating} hover={item.rating} />
      </div>
      <div className={styles.testDesc}>
        {more && isMoreReq ? (
          <div style={{ maxHeight: "255px", overflowY: "overlay" }}>
            {" "}
            {item?.thoughts?.join(" ").slice(0, slicedChars) + "..."}
            <span
              onClick={() => setMore(!more)}
              style={{ color: "#7A51AE", cursor: "pointer" }}
            >
              ReadMore
            </span>{" "}
          </div>
        ) : (
          <div style={{ maxHeight: "255px", overflowY: "overlay" }}>
            {" "}
            {item?.thoughts + "..."}{" "}
            {isMoreReq && (
              <span
                style={{ color: "#7A51AE", cursor: "pointer" }}
                onClick={() => setMore(!more)}
              >
                ReadLess
              </span>
            )}
          </div>
        )}
      </div>
      <div
        className={`${styles.testAuth} ${
          index % 2 == 1 ? styles.testAuth_black : styles.testAuth_purple
        }`}
      >
        <h4>{item?.customer?.name}</h4>
        <h5>{item?.customer?.occupation}</h5>
      </div>
    </div>
  );
};
