import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { RiCloseLine } from "react-icons/ri";
import styles from "../../assets/css/PatientUI/servicepopup.module.css";
import { Btn } from "./Button";
import { Input, SelectWithId } from "../../Component/UI/CustomInputs";
import { CircularProgress } from "../../../node_modules/@mui/material/index";
import CountryCodes from "../../assets/common/countryCode.json";

const closeBtn = {
  cursor: "pointer",
  fontWeight: "500",
  padding: "5px 8px",
  borderRadius: "8px",
  border: "none",
  fontSize: "18px",
  color: "#2c3e50",
  background: "white",
  transition: "all 0.25s ease",
  boxShadow: "0 5px 20px 0 rgb(0 0 0 / 6%)",
  position: "relative",
  bottom: "0px",
  left: "0px",
};

export default function EnterPrisePopup({
  popupAction,
  setPopupAction,
  title,
  icon,
  name,
  handleClose,
  enterpriseMobile,
  mobileNo,
  description,
  setMobileNo,
  mobileNoSubmit,
  isLoadingUser,
  setCountryCode,
  countryCode,
  enterpriseId,
}) {
  const rgx = /^(?:[0-9+]+)?$/;
  console.log(enterpriseId, "enterpriseId");
  return (
    <div>
      <Modal open={popupAction.open}>
        <Box className={styles.popUpContainerEnterPrise}>
          {/* <div
            style={{
              width: "100%",
              background: "#7a51ae",
              top: "0px",
              left: "0px",
              position: "absolute",
              borderTopLeftRadius: "19px",
              borderTopRightRadius: "19px",
              height: "40px",
              color: "white",
              fontSize: "18px",
              fontWeight: "600",
              padding: "5px",
              textTransform: "uppercase",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                alignSelf: "center",
                width: "100%",
                textAlign: "center",
              }}
            >
              {name}
            </div>
            <button style={closeBtn} onClick={handleClose}>
              <RiCloseLine style={{ marginBottom: "-3px" }} />
            </button>
          </div> */}

          <div
            style={{
              display: "flex",
              flexDirection: "column",

              marginTop: "40px",
              margin: "auto",
            }}
          >
            {icon}
            {/* <h2>{popupAction.name}</h2> */}
            {enterpriseId ? (
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  margin: "10px 0 0 0px",
                  textAlign: "left",
                  lineHeight: "26px",
                }}
              >
                {title}
              </p>
            ) : (
              ""
            )}

            {enterpriseId ? (
              <p style={{ textAlign: "left", margin: "6px 0" }}>
                {description}
              </p>
            ) : (
              ""
            )}

            {enterpriseMobile?.mobile !== null &&
            enterpriseMobile?.mobile !== undefined &&
            enterpriseMobile?.mobile !== "null" ? (
              ""
            ) : (
              <div className={styles.mobileNo}>
                <div
                  style={{
                    marginBottom: "0",
                    lineHeight: "20px",
                    fontSize: "15px",
                    letterSpacing: "0.02857em",
                  }}
                >
                  Please provide us your phone number to be able to reach you:
                </div>
                <div className={styles.mobileCountry}>
                  <SelectWithId
                    options={CountryCodes}
                    width="180px"
                    value={countryCode}
                    onChange={(e) => {
                      setCountryCode(e.target.value);
                    }}
                  />
                  <Input
                    width={"100%"}
                    max={10}
                    placeholder="Please Enter Mobile No"
                    value={mobileNo}
                    type="number"
                    onChange={(e) => {
                      const isNum = rgx.test(e.target.value);
                      isNum && setMobileNo(e.target.value);
                    }}
                    req
                  />
                </div>
              </div>
            )}
            {isLoadingUser ? <CircularProgress size={28} /> : ""}
            {enterpriseMobile?.mobile !== null &&
            enterpriseMobile?.mobile !== undefined &&
            enterpriseMobile?.mobile !== "null" ? (
              <div style={{ textAlign: "center" }}>
                <Btn
                  bgColor="#7A51AE"
                  width="100px"
                  height="35px"
                  variant="contained"
                  click={handleClose}
                >
                  Ok
                </Btn>
              </div>
            ) : (
              <div style={{ textAlign: "right", marginTop: 10 }}>
                <Btn
                  bgColor="#7A51AE"
                  width="100px"
                  height="35px"
                  variant="contained"
                  click={mobileNoSubmit}
                >
                  Submit
                </Btn>
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
