import React from "react";

import { BiExport } from "react-icons/bi";
import Button from "@mui/material/Button";

export const Exports = ({ click, disabled }) => {
  return (
    <Button
      type="submit"
      size="large"
      disabled={disabled}
      variant="outlined"
      style={{
        textTransform: "none",
        height: "40px",
        background: "white",
        marginLeft: "0px",
        width: "100%",
        padding: "8px",
        textAlign: "center",
        cursor: "pointer",
        border: "1px solid #DADADA",
        borderRadius: "8px",
        fontWeight: 400,
        fontSize: "14px",
        color: "#323232",
      }}
      onClick={click}
    >
      Export <BiExport size={18} style={{ paddingLeft: "5px" }} />
    </Button>
  );
};
