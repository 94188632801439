import React, { useEffect } from "react";
import { Paper } from "../../../../node_modules/@mui/material/index";
import { Card } from "../../../Component/UI/Card";
import { Select } from "../../../Component/UI/CustomInputs";
import { LevelScoring } from "../../Components/LevelScoring/LevelScoring";
import styles from "./dashboard.module.css";
import { TotalAssessment } from "../../Components/Dashboard/TotalAssessment";
import { useState } from "react";
import {
  dashboardDatas,
  enterpriseAssessmentList,
} from "../../../services/enterprise/dashboard";
import { useSelector } from "react-redux";
import AssessmentsChart from "./AssessmentsChart";

const months = [
  "All",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const AssessmentOverview = () => {
  const [selectOptions, setSelectOptions] = useState([]);
  const [selected, setSelected] = useState("");
  const [isExpired, setIsExpired] = useState();

  const [dashboardData, setDashboardData] = useState({
    total: 0,
    complete: 0,
    average_time: 0,
    level_average: {
      level1: 0,
      level2: 0,
      level3: 0,
    },
  });

  const { selectedDate, year } = useSelector((i) => i.DateFilterSlice);

  const monthSelectd = months.findIndex(
    (element) => element === selectedDate?.split(" ")[0]
  );

  let getColors = (percentage) => {
    if (percentage < 30) {
      return {
        color: "#FF5757",
        value: "Poor",
      };
    } else if (percentage > 30 && percentage < 70) {
      return {
        color: "#E7B400",
        value: "Bad",
      };
    } else {
      return {
        color: "#50CD89",
        value: "Good",
      };
    }
  };

  useEffect(() => {
    getAssessmentList();
  }, []);

  useEffect(() => {
    setSelected(selectOptions[0]?.id);
  }, [selectOptions]);

  useEffect(() => {
    selected && getDashboardData();
  }, [selected, selectedDate]);

  const getDashboardData = () => {
    dashboardDatas({
      assessment_id: selected,
      month: monthSelectd === -1 ? 0 : monthSelectd,
      year: year,
    })
      .then((res) => setDashboardData(res.data.list))
      .catch((err) => console.log(err));
  };

  const getAssessmentList = () => {
    enterpriseAssessmentList()
      .then((res) => {
        // const listdata = res.data.list.filter(
        //   ({ expiryService }) => !expiryService
        // );
        const listdata = res.data.list;
        const changedList = listdata.map((item) => {
          return {
            ...item,
            value: item.name,
          };
        });
        setSelectOptions(changedList);
      })
      .catch((err) => console.log(err));
  };
  console.log(dashboardData, "dashboardData---");

  let ResultScoringVar =
    dashboardData?.level_average.level1 ||
    dashboardData?.level_average.level2 ||
    dashboardData?.level_average.level3;

  let averageTime = (dashboardData?.average_time / 60)?.toFixed(2);
  let levelAverage = dashboardData?.level_average;

  // console.log(typeof dashboardData?.average_time);
  useEffect(() => {
    const exp = selectOptions.find(
      (i) => i.id.toString() === selected.toString()
    );
    setIsExpired(exp?.expiryService);
    console.log(exp, selected, "++ex");
  }, [selected]);

  console.log(isExpired, "expired");

  return (
    <div>
      <div className={styles.heading}>Assessments</div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Select
          name="Assessment_category"
          options={selectOptions}
          width="270px"
          value={selected}
          onChange={(e) => {
            setSelected(e.target.value);
          }}
        />
        {selected && (
          <div style={{ marginLeft: 10 }}>
            {isExpired ? (
              <p style={{ color: "red" }}>Expired</p>
            ) : (
              <p style={{ color: "green" }}>Active</p>
            )}
          </div>
        )}
      </div>
      {selectOptions.length ? (
        <div className={styles.cardContainer}>
          <Card
            text="Total"
            num={dashboardData?.total}
            color="#3592FF20"
            Outerwidth={"250px"}
            icon={<TotalAssessment height={40} width={40} color="#3592FF" />}
          />
          <Card
            text="Completed"
            num={dashboardData?.complete}
            color="#18BA4B20"
            Outerwidth={"250px"}
            icon={<TotalAssessment height={40} width={40} color="#18BA4B" />}
          />

          {/* <Card
          text="In Progress"
          num={"20"}
          color="#FF8A0020"
          Outerwidth={"250px"}
          icon={<TotalAssessment height={40} width={40} color="#FF8A00" />}
        /> */}
          <Card
            text="Avg. Time/person"
            num={`${averageTime} mins`}
            color="#3592FF20"
            // numSize="15px"
            Outerwidth={"250px"}
            icon={<TotalAssessment height={40} width={40} color="#3592FF" />}
          />
        </div>
      ) : (
        <div>
          {/* <p
            style={{
              fontSize: "18px",
              margin: "15px 0",
              fontWeight: "600",
              lineHeight: "25px",
            }}
          >
            No Assessments are mapped to this enterprise.
          </p> */}
        </div>
      )}
      {ResultScoringVar ? (
        <>
          <div className={styles.resultOverview}>Result Overview</div>
          <div
            className={styles.papercontainer}
            style={{ paddingBottom: "20px" }}
          >
            <Paper elevation={1} sx={{ width: "100%", borderRadius: "15px" }}>
              <div className={styles.innerResultOverview}>
                <LevelScoring
                  // color={getColors(levelAverage.level1 * 100).color}
                  color={"#50CD89"}
                  percentage={levelAverage.level1 * 100}
                  //value={getColors(levelAverage.level1 * 100).value}
                  level={"1"}
                />
                <LevelScoring
                  //color={getColors(levelAverage.level2 * 100).color}
                  color={"#E7B400"}
                  percentage={levelAverage.level2 * 100}
                  //value={getColors(levelAverage.level2 * 100).value}
                  level={"2"}
                />
                <LevelScoring
                  //color={getColors(levelAverage.level3 * 100).color}
                  color={"#ff5a5a"}
                  percentage={levelAverage.level3 * 100}
                  //value={getColors(levelAverage.level3 * 100).value}
                  level={"3"}
                />
              </div>
            </Paper>
            <AssessmentsChart selected={selected} />
          </div>
          {/* <p>No Data Available</p> */}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
