import React from "react";
import styles from "./testimonialcard.module.css";

export const TestimonialCard = ({ paragraph, profile, link, name, role }) => {
  return (
    <div className={styles.card}>
      <p>{paragraph}</p>
      <p>{link}</p>
      <div className={styles.profile}>
        <img src={profile} alt="" width="58px" height="58px" />
        <div>
          <p className={styles.profileName}>{name}</p>

          <p>{role}</p>
        </div>
      </div>
    </div>
  );
};
