import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useState, useEffect } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { quickAskSelector } from "../../../Redux/Slices/QuickAsk";
import BasicBreadcrumbs from "../../../Component/UI/BreadCrumb/BreadCrumbs";

import { Link, useLocation } from "react-router-dom";
import instance from "../../../config/axiosConfig";
import { API } from "../../../config/constant";
import CustomizedButtons from "../../../Component/UI/Button/CustomizeButton";
import { useNavigate } from "react-router-dom";
import { fetchaddQuestionPostdata } from "../../../Redux/Slices/AdminAssessment/AddQuestion";
import { Edit } from "../../../assets/icons/Edit";
import { toast } from "../../../../node_modules/react-hot-toast/dist/index";
import { DragDropTable } from "../../../Component/UI/DragDropTable";
import { DragDropQuiskAsk } from "../../../Component/UI/DragDropTableQuickAsk";

const link = [{ path: "/Assessments", linkText: "Assessments" }];
export default function GeneralQuickAsk() {
  const location = useLocation();
  const navigate = useNavigate();
  const { cid } = location?.state;
  const { permissions } = location?.state;
  const dispatch = useDispatch();
  const fullpermissions = +permissions?.listControl?.access?.["Full Control"];
  const writePermissions = +permissions?.listControl?.access?.Write;

  const { addgeneralData, loading, hasErrors } = useSelector(quickAskSelector);
  const { status } = useSelector((state) => state.changeSequence);
  const [dragDropList, setDragDropList] = useState([]);
  useEffect(() => {
    dispatch(fetchaddQuestionPostdata(""));
  }, []);

  useEffect(() => {
    try {
      instance
        .get(`${API.GETGENERALQUESTION}?category_id=${cid}`)
        .then((res) => {
          if (res.data.status) {
            const newdata = res.data.list.questions.map((question, index) => ({
              ...question,
              serial: index + 1,
            }));

            setDragDropList(newdata);
          }
          // res.data.status === true && setDragDropList(res.data.list.questions);
        });
    } catch (error) {
      console.log(error, "error");
    }
  }, [status]);

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    const arr = [...dragDropList];

    let [removedItem] = arr.splice(e.source.index, 1);
    console.log(removedItem);
    arr.splice(e.destination.index, 0, removedItem);
    const latest = arr.map((data, i) => ({ ...data, serial: i + 1 }));
    setDragDropList(latest);
  };

  const renderSelfCare = () => {
    if (loading) return <p>Loading selfCare...</p>;
    if (hasErrors) return <p>Cannot display selfCare...</p>;
    // return selfCare[1]?.map((resp) =>{})
  };
  const AddBtnHandler = () => {
    navigate("/generalAddQuestion", {
      state: { cid: cid, id: 0 },
    });
  };

  useEffect(() => {
    return () => toast.remove();
  }, []);

  console.log(dragDropList, "dragDropList");

  const tHead = ["Q.NO. ", "Question", ...(writePermissions ? ["Action"] : [])];
  const tBodyData = dragDropList?.map(
    ({ question, serial_number, id, category_id, scores }, i) => {
      i++;
      return {
        serial_number: serial_number,
        question,

        ...(writePermissions && {
          action: (
            <div style={{ display: "flex" }}>
              <Link
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
                to="/generalAddQuestion"
                state={{
                  id: id,
                  cid: cid,
                }}
              >
                <Edit />
              </Link>
            </div>
          ),
        }),
      };
    }
  );

  return (
    <>
      <div style={{ position: "absolute", top: "19%", left: "93%" }}>
        {writePermissions ? (
          <CustomizedButtons
            ButtonLabel={"Add"}
            width={""}
            click={AddBtnHandler}
          />
        ) : null}
      </div>

      <BasicBreadcrumbs link={link} text={"General"}>
        <DragDropQuiskAsk
          tHead={tHead}
          tBody={tBodyData}
          wrapper
          dragDropList={dragDropList}
        />
        {/* <PaperWrapper> */}
        {/* <DragDropContext onDragEnd={handleDragEnd}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">:::</TableCell>
                <TableCell align="left">Q.No</TableCell>
                <TableCell align="left">Question</TableCell>
                <TableCell align="left">Action</TableCell>
              </TableRow>
            </TableHead>
            <Droppable droppableId="dragdroplist">
              {(provided, snapshot) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {dragDropList?.map(
                    (user, index) =>
                      user.category_id === cid && (
                        <Draggable
                          key={index}
                          //enable below text to start dragging
                          // draggableId={`data${index}`}
                          index={index}
                        >
                          {(provided) => (
                            <TableRow
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <TableCell align="left">:::</TableCell>
                              <TableCell
                                sx={{ cursor: "not-allowed" }}
                                align="left"
                              >
                                {user.id}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ cursor: "not-allowed" }}
                              >
                                {user.question}
                              </TableCell>
                              <TableCell align="left">
                                <Link
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                  }}
                                  to="/generalAddQuestion"
                                  state={{
                                    id: user.id,
                                    cid: cid,
                                  }}
                                >
                                  <Edit />
                                </Link>
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      )
                  )}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </Table>
        </DragDropContext> */}
        {/* </PaperWrapper> */}
      </BasicBreadcrumbs>
    </>
  );
}
