import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineRight } from "react-icons/ai";
import Stack from "@mui/material/Stack";
import classes from "../../assets/css/layout.module.css";
import { Button } from "../../../node_modules/@mui/material/index";
import CustomizedButtons from "./Button/CustomizeButton";

const SimpleBreadcrum = ({
  link,
  text,
  margin,
  noHeading,
  title,
  editStaffName,
  buttonText,
  addReports,
}) => {
  const navigate = useNavigate();

  function handleClick(event, path, cid, data) {
    event.preventDefault();
    navigate(path, {
      state: { cid: cid, data },
    });
  }

  const links = link?.map((el, id, data) => {
    return (
      <Link
        key={id}
        to={`${el.path}`}
        state={{ cid: el.cid }}
        onClick={(e) => handleClick(e, el.path, el.cid, data[1]?.data)}
        className={classes.breadcrumbsCss}
      >
        {el.linkText}
      </Link>
    );
  });
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {link.length !== 0 ? (
          <Stack
            spacing={2}
            className={classes.stackbreadcrumbsCss}
            style={{ margin: "0 10px" }}
          >
            <Breadcrumbs
              separator={<AiOutlineRight fontSize="small" />}
              aria-label="breadcrumb"
            >
              {links}
              <span>
                <Typography key={text}>
                  {editStaffName ? editStaffName : text}
                </Typography>
              </span>
            </Breadcrumbs>
          </Stack>
        ) : null}
        {!noHeading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              // marginRight: "1%",
            }}
          >
            <Typography variant="h5" key="3" className={classes.fontstyle}>
              {title || text}
            </Typography>
            {buttonText ? (
              <CustomizedButtons
                ButtonLabel={"Add Report"}
                click={addReports}
              />
            ) : (
              ""
            )}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default SimpleBreadcrum;
