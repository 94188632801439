import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { BetterAccordion } from "../../../../Component/UI/BetterAccordion/BetterAccordion";
import { ServicesCard } from "../ServicesCard";
import DeletePopup from "./DeletePopup";
import styles from "../subscriptions.module.css";
import { Btn } from "../../../../Samvenda-Patient/UI/Button";
import { deleteService } from "../../../../services/admin/subscriptions";

export const SubscriptionServices = ({
  setServicesCardData,
  servicesCardData,
  subscriptionServices,
  subscriptionData,
  amount,
  error,
  expiryDate,
  sessions,
}) => {
  const [expanded, setExpanded] = React.useState(
    `panel${servicesCardData.length - 1}`
  );

  const [popupAction, setPopupAction] = useState({ open: false });

  useEffect(() => {
    setExpanded(`panel${servicesCardData.length - 1}`);
  }, [servicesCardData.length]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  console.log(servicesCardData, "servicesCardData");

  return (
    <>
      <DeletePopup
        popupAction={popupAction}
        setPopupAction={setPopupAction}
        title="Do you want to remove this service?"
      />
      <div
        className={styles.services}
        style={{ width: "75vw", height: "380px", overflowY: "scroll" }}
      >
        {servicesCardData.map((service, i) => (
          <div style={{ margin: "8px" }}>
            <BetterAccordion
              heading={`Service ${i + 1} ${service.service_name ? "-" : ""} ${
                service.service_name || ""
              }`}
              headingStyle={{ fontWeight: 500 }}
              i={i}
              expanded={expanded}
              handleChange={handleChange}
            >
              <ServicesCard
                subscription_type={subscriptionData?.subscription_type}
                index={i}
                expiryDate={expiryDate}
                error={error[i]}
                key={service.id}
                services={subscriptionServices}
                removeVisible={servicesCardData.length > 1}
                handleDel={() => {
                  setPopupAction({
                    ...popupAction,
                    open: true,
                    action: () => {
                      setServicesCardData(
                        servicesCardData.filter(
                          (item) => item.id !== service.id
                        )
                      );
                      deleteService(servicesCardData[i]?.id).then((res) =>
                        console.log(res)
                      );
                      setPopupAction({ ...popupAction, open: false });
                    },
                  });
                }}
                data={servicesCardData[i]}
                handleChange={(e) => {
                  const { name, value } = e.target;
                  if (value < 0) return;
                  const intervalValues = {
                    0: 1,
                    1: 7,
                    2: 30,
                    3: 3.5,
                    4: 2.3,
                  };
                  const obj = {};
                  if (name === "interval_type")
                    if (
                      subscriptionData?.subscription_type === "1" &&
                      value === "5"
                    ) {
                      obj.minimum_validity = Number(
                        Math.round(subscriptionData?.validity)
                      );
                    } else {
                      obj.minimum_validity = Math.round(
                        servicesCardData[i].number_session *
                          intervalValues[value]
                      );
                    }

                  if (name === "number_session") {
                    obj.total_price = amount[i] * value;
                    obj.minimum_validity = Math.round(
                      intervalValues[servicesCardData[i].interval_type] * value
                    );
                  }

                  if (name === "service_id") {
                    const selectedService = subscriptionServices.filter(
                      ({ id }) => id.toString() === value
                    )[0];
                    const findTaxApp = selectedService?.requirement.split(",");
                    obj.unit_price = selectedService.amount;
                    obj.tax_applicable =
                      subscriptionData?.subscription_type === "0"
                        ? findTaxApp.at(1)
                        : selectedService.requirement;
                    obj.mode = selectedService.type_of_service;
                    obj.service_name = selectedService.value;
                    obj.total_price = selectedService.amount * sessions[i];
                    obj.tax_value = 0;
                  }
                  setServicesCardData(
                    servicesCardData.map((a) => {
                      if (a.id === service.id) {
                        a = { ...a, ...obj, [name]: value };
                      }
                      return a;
                    })
                  );
                }}
              />
            </BetterAccordion>
          </div>
        ))}
      </div>
    </>
  );
};
