import styles from "./empty.module.css";
import empty from "../../Components/Images/empty.svg";
export const Empty = ({ title, desc }) => {
  return (
    <div className={styles.box}>
      <img src={empty} alt="emptyIcon" />
      <h2 className={styles.heading}>{title}</h2>
      <p className={styles.text}>{desc}</p>
    </div>
  );
};
