import React from "react";

export const InfoIconLeft = ({ color }) => {
  return (
    <div>
      <svg width="34" height="32" viewBox="0 0 34 32" fill="none">
        <g opacity="0.08">
          <path
            d="M0.601563 22.9754C0.601563 22.9754 1.3464 15.3825 9.39145 10.9131C9.39145 10.9131 18.8469 5.10565 18.5252 0C18.5252 0 22.7106 6.03638 17.3944 12.2722C17.3944 12.2722 14.3998 15.9374 9.21035 16.9661C9.21035 16.966 4.16185 17.6238 0.601563 22.9754Z"
            fill={color || "#00B091"}
          />
          <path
            d="M5.4375 19.7508C5.4375 19.7508 11.97 16.1219 20.0849 20.4557C20.0849 20.4557 30.0691 25.2382 33.9997 22.0984C33.9997 22.0984 31.4883 29.0472 23.4829 28.0257C23.4829 28.0257 18.8506 27.5358 15.1087 23.6993C15.1087 23.6994 11.7461 19.775 5.4375 19.7508Z"
            fill={color || "#00B091"}
          />
          <path
            d="M11.2003 26.244C11.2003 29.4226 8.69284 31.9993 5.60001 31.9993C2.50719 31.9993 0 29.4226 0 26.244C0 23.065 2.50719 20.4883 5.60001 20.4883C8.69284 20.4883 11.2003 23.065 11.2003 26.244Z"
            fill={color || "#00B091"}
          />
        </g>
      </svg>
    </div>
  );
};
