import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { TabPanel } from "./TabPanel";
import { TabsService } from "../../DashboardPages/TabsService";
import { useState } from "react";
import styles from "../../../assets/css/PatientUI/tabview.module.css";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Skeleton } from "../../../../node_modules/@mui/material/index";
import axios from "../../../config/axiosConfig";
import { useDispatch } from "react-redux";
import {
  setCustomerRelativeData,
  setSelectOptions,
} from "../../../Redux/Slices/Services/selectOptions";
import { assessmentGet } from "../../../services/patient/assessment";
// import styles from "../../../assets/css/PatientUI/tabvieww.module.css";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const TabView2 = (props) => {
  const { state } = useLocation();

  const [select, setSelect] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    setSelect(state?.id || 0);
  }, []);

  useEffect(() => {
    axios.get("/customer/relative/list").then((res) => {
      const user = res?.data?.list;
      const data = user?.relative?.map(({ id, name, assessments }) => {
        if (assessments.length) {
          return {
            id,
            value: name,
          };
        } else return null;
      });

      assessmentGet().then((res) => {
        let dementiaYourselfData = res.filter((item) => {
          return item.id === 1;
        });
        let yourselfStatusTrue =
          dementiaYourselfData?.[0].is_mapped?.status === "completed";

        // let selectedOptionsData = yourselfStatusTrue
        //   ? [...[{ id: user?.id, value: "Yourself" }], ...data]
        //   : data;

        let selectedOptionsData = [
          ...[{ id: user?.id, value: "Yourself" }],
          ...data,
        ];

        dispatch(setSelectOptions(selectedOptionsData));
        dispatch(setCustomerRelativeData(user));
      });
    });
  }, []);

  return (
    <>
      <div>
        {/* <h1 className={styles.heading}>Services</h1> */}
        {
          props?.enterpriseId && props.selectEnterPrise === '0' &&
          <div className={styles.toggle}>
          {props.values?.length > 1 ? (
            props.values?.map(({ name, id }, i) => {
              return (
                <div
                  className={styles.slider}
                  style={{
                    background: id === select ? "#7a51ae" : null,
                    color: id === select ? "#fff" : null,
                    fontSize: id === select ? "16px" : null,
                    // width: "170px",
                  }}
                  onClick={() => setSelect(id)}
                >
                  {name}
                </div>
              );
            })
          ) : (
            <>
              <div className={styles.slider}>
                <Skeleton
                  variant="text"
                  sx={{ width: "100px", height: "20px" }}
                />
              </div>
              <div className={styles.slider}>
                <Skeleton
                  variant="text"
                  sx={{ width: "100px", height: "20px" }}
                />
              </div>
              <div className={styles.slider}>
                <Skeleton
                  variant="text"
                  sx={{ width: "100px", height: "20px" }}
                />
              </div>
            </>
          )}
        </div>

        

        }

        {
          !props?.enterpriseId &&
          <div className={styles.toggle}>
          {props.values?.length > 1 ? (
            props.values?.map(({ name, id }, i) => {
              return (
                <div
                  className={styles.slider}
                  style={{
                    background: id === select ? "#7a51ae" : null,
                    color: id === select ? "#fff" : null,
                    fontSize: id === select ? "16px" : null,
                    // width: "170px",
                  }}
                  onClick={() => setSelect(id)}
                >
                  {name}
                </div>
              );
            })
          ) : (
            <>
              <div className={styles.slider}>
                <Skeleton
                  variant="text"
                  sx={{ width: "100px", height: "20px" }}
                />
              </div>
              <div className={styles.slider}>
                <Skeleton
                  variant="text"
                  sx={{ width: "100px", height: "20px" }}
                />
              </div>
              <div className={styles.slider}>
                <Skeleton
                  variant="text"
                  sx={{ width: "100px", height: "20px" }}
                />
              </div>
            </>
          )}
        </div>
        }
        
        {props.values?.map(({ id }) => {
          return (
            select === id && (
              <TabsService
                id={id}
                assessmentData={props.values[id]}
                select={select}
                selectEnterPrise={props.selectEnterPrise}
                enterpriseId= {props?.enterpriseId}
                
              />
            )
          );
        })}
      </div>
    </>
  );
};
