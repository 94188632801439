import React from "react";

export const ServiceIcon = ({ styles }) => {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      className={styles.service_icon}
    >
      <g opacity="0.1" filter="url(#filter0_d_228_322)">
        <rect x="8" y="4" width="80" height="80" rx="20" fill="#3592FF" />
      </g>
      <path
        d="M48 26.3535L30.3529 35.177L48 44.0006L65.647 35.177L48 26.3535Z"
        fill="url(#paint0_linear_228_322)"
        stroke="#3592FF"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30.3529 52.8242L48 61.6478L65.647 52.8242"
        fill="url(#paint1_linear_228_322)"
      />
      <path
        d="M30.3529 52.8242L48 61.6478L65.647 52.8242"
        stroke="#3592FF"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30.3529 44.0007L48 52.8243L65.647 44.0007"
        fill="url(#paint2_linear_228_322)"
      />
      <path
        d="M30.3529 44.0007L48 52.8243L65.647 44.0007"
        stroke="#3592FF"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_228_322"
          x="0"
          y="0"
          width="96"
          height="96"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_228_322"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_228_322"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_228_322"
          x1="49.7217"
          y1="89.1726"
          x2="42.4758"
          y2="15.4263"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3592FF" />
          <stop offset="0.9984" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_228_322"
          x1="49.7217"
          y1="84.2338"
          x2="47.8971"
          y2="47.0943"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3592FF" />
          <stop offset="0.9984" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_228_322"
          x1="49.7217"
          y1="75.4103"
          x2="47.8971"
          y2="38.2708"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3592FF" />
          <stop offset="0.9984" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};
