import React from "react";

export const Payment = (props) => {
  return (
    <svg
      width={props.size || "16"}
      height={props.size || "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_473_12885)">
        <circle cx="8" cy="8" r="7.5" stroke={props.outline || "#2A2A2A"} />
        <path
          d="M10.5 5V4H5V5H6.75C7.401 5 7.9505 5.419 8.1575 6H5V7H8.1575C8.05486 7.29162 7.86446 7.5443 7.61244 7.72336C7.36042 7.90241 7.05915 7.99905 6.75 8H5V9.207L7.793 12H9.207L6.207 9H6.75C7.32598 8.99923 7.88408 8.79988 8.3302 8.43555C8.77631 8.07122 9.08316 7.56421 9.199 7H10.5V6H9.199C9.12236 5.6361 8.96442 5.29424 8.737 5H10.5Z"
          fill={props.outline || "#2A2A2A"}
        />
      </g>
      <defs>
        <clipPath id="clip0_473_12885">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
