import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const { ADMINDASHBOARD, ADMINDASHBOARD_APPOINTMENT, ADMINDASHBOARD_PAYMENT } =
  API;

export const getDashboard = async (from, to) => {
  return await axios
    .get(ADMINDASHBOARD + `?fromdate=${from}&todate=${to}`)
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const getDashboardAppointment = async (from, to) => {
  return await axios
    .get(ADMINDASHBOARD_APPOINTMENT + `?fromdate=${from}&todate=${to}`)
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const getDashboardpayment = async (from, to) => {
  return await axios
    .get(ADMINDASHBOARD_PAYMENT + `?fromdate=${from}&todate=${to}`)
    .then((res) => res)
    .catch((err) => console.log(err));
};
