import React from "react";
import classes from "../../assets/css/login.module.css";
import { LoginLogo } from "../../assets/icons/LoginLogo";
import LoginImage from "../../assets/icons/LoginImage.svg";
import { Login } from "./Login";
import { useContext } from "react";
import AuthContext from "../../Auth/AuthContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LOGIN_URL = "/auth";

export const LoginContainer = () => {
  const backgroundimg = <LoginImage />;
  const user = false;

  const navigate = useNavigate();
  const ctx = useContext(AuthContext);
  const is_loggedin = ctx?.loginUser?.is_loggedin;
  /* useEffect(() => { */
  /*   is_loggedin && navigate("/dashboard"); */
  /* }, []); */

  return (
    <div
      className={classes.login_container}
      style={{ background: `url(${LoginImage})` }}
    >
      <div className={classes.login_subcontainer}>
        <div style={{ textAlign: "center" }}>
          <LoginLogo />
        </div>

        <Login />
      </div>
    </div>
  );
};
