import React from "react";

export const Badge = ({ text, style }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "6px 10px",
        borderRadius: 20,
        color: "#fff",
        fontSize: 10,
        ...style,
      }}
    >
      {text}
    </div>
  );
};
