import { useDispatch, useSelector } from "react-redux";
import { setYear } from "../../../../Redux/Slices/Patient/PatienCalender";

export const DateNavigation = ({ styles, dateObj, fullYear }) => {
  const dispatch = useDispatch();
  const { year } = useSelector((i) => i.DateFilterSlice);
  return (
    <div className={styles.year}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        onClick={() => {
          dispatch(setYear(year - 1));
          // if (year < new Date().getFullYear()) {
          //   dateObj.setFullYear(year - 1);
          //   dispatch(setYear(year - 1));
          // }
        }}
      >
        {/* <g opacity={year > dateObj.getFullYear() ? 1 : 0.2}></g> */}
        <g opacity="1">
          <path
            d="M15 6L9 12L15 18"
            stroke="#2A2A2A"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
      {year}
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        onClick={() => {
          // dateObj.setFullYear(year + 1);
          if (year < new Date().getFullYear()) {
            dateObj.setFullYear(year + 1);
            dispatch(setYear(year + 1));
          }
          // dispatch(setYear(year + 1));
        }}
      >
        <path
          d="M9 18L15 12L9 6"
          stroke="#2A2A2A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
