import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { Card } from "../../../Component/UI/Card";
import { WellBeing } from "../../../assets/icons/WellBeing";
import { Enterprise } from "../../../assets/icons/Enterprise";
import { Gift } from "../../../assets/icons/Gift";
import styles from "../../../assets/css/layout.module.css";
import { Heading } from "../../../Component/UI/Heading";
import { Calender } from "../../../Component/UI/Calender";
import { OverviewAppointment } from "./Appointments";
import { OverviewPayments } from "./Payments";
import { getDashboard } from "../../../services/admin/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardStats } from "../../../Redux/Slices/AdminDashboard/dashboardStats";
import { Smile } from "../../../assets/icons/Smile";
import { useState } from "react";
import {
  setFilter,
  setFrom_date,
  setSearch_key,
  setTo_date,
} from "../../../Redux/Slices/AdminFilters/Search";

import { BsEmojiSmile, BsStar } from "react-icons/bs";
import { AiOutlineMessage } from "react-icons/ai";
import { findFunc } from "../../../config/methods";
import { usePermissions } from "../../../Hooks/usePermissions";

export const checkWidth = (len) => {
  if (len <= 6) {
    return "5%";
  } else if (len > 6 && len <= 15) {
    return "30%";
  } else if (len > 15 && len <= 30) {
    return "30%";
  } else {
    return "70%";
  }
};

const Overview = () => {
  const dispatch = useDispatch();
  const wellbeingicon1 = <WellBeing size="40" outline="#7A51AE" thickness />;
  const wellbeingicon2 = <WellBeing size="40" outline="#18BA4B" thickness />;
  const wellbeingicon3 = <WellBeing size="40" outline="#EB0000" thickness />;
  const enterpriseicon1 = <Enterprise size="30" outline="#7A51AE" thickness />;
  const enterpriseicon2 = <Enterprise size="30" outline="#18BA4B" thickness />;
  const enterpriseicon3 = <Enterprise size="30" outline="#EB0000" thickness />;

  const gifticon = <Gift size="40" outline="#7A51AE" thickness />;
  const smileicon = <BsEmojiSmile size="40" />;
  const messageIcon = <AiOutlineMessage size="40" color="#F29F20" />;
  const starIcon = <BsStar size="40" color="#7FA6FF" />;

  const { stats } = useSelector((state) => state.dashboardStats);
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    getDashboard(date.startDate, date.endDate).then((res) =>
      dispatch(getDashboardStats(res?.data?.list))
    );
  }, [date]);

  useEffect(() => {
    return () => {
      dispatch(setSearch_key(""));
      dispatch(setFrom_date(""));
      dispatch(setTo_date(""));
      dispatch(setFilter());
    };
  }, []);
  const dashboardPer = usePermissions("name", "dashboard");
  const patientsPerms = usePermissions("name", "patients");
  const enterprisesPerms = usePermissions("name", "enterprises");
  const samvednaPerms = usePermissions("name", "samvedna");
  const eventsPerms = usePermissions("name", "events");

  // const appointmentsPerms = usePermissions("name", "appointments");
  // const paymentsPerms = usePermissions("name", "payments");

  // const paymentsPerms = usePermissions("name", "payments");
  const patientsPer = findFunc(
    dashboardPer?.subCategories,
    "listName",
    "patients"
  );
  const enterprisesPer = findFunc(
    dashboardPer?.subCategories,
    "listName",
    "enterprises"
  );
  const othersPer = findFunc(dashboardPer?.subCategories, "listName", "others");
  const appointmentsPer = findFunc(
    dashboardPer?.subCategories,
    "listName",
    "appointments"
  );
  const paymentsPer = findFunc(
    dashboardPer?.subCategories,
    "listName",
    "payments"
  );

  const userFeedBack = findFunc(
    samvednaPerms?.subCategories,
    "listName",
    "user feedback"
  );

  const testimonialsPerms = findFunc(
    samvednaPerms?.subCategories,
    "listName",
    "testimonials"
  );

  const readPermissionsFeed = +userFeedBack?.access?.Read;
  const readPermissionsTest = +testimonialsPerms?.access?.Read;

  console.log(readPermissionsTest, "readPermissionsTest");
  console.log(readPermissionsFeed, "readPermissionsFeed--");

  return (
    <>
      <div className={styles.header_container}>
        <div className={styles.title}>
          <Heading title="Overview" />
        </div>
        <div className={styles.calender_box}>
          {/* <Calender setDateGap={setDate} /> */}
        </div>
      </div>
      <div className={styles.container_wrap}>
        {+patientsPer?.access?.Read ? (
          <>
            <div className={styles.sub_title}>
              <Heading title="Patients" />
            </div>
            <div className={styles.card_container}>
              <Link
                to="/Patients"
                style={{
                  textDecoration: "none",
                  color: "black",
                  pointerEvents: !patientsPerms?.status ? "none" : "",
                }}
              >
                <Card
                  icon={wellbeingicon1}
                  text="Total Patients"
                  num={stats?.patients?.total}
                  color="#f2eef7"
                  link="/Patients"
                />
              </Link>
              <Link
                to="/Patients"
                style={{
                  textDecoration: "none",
                  color: "black",
                  pointerEvents: !patientsPerms?.status ? "none" : "",
                }}
                state={{ status: "active" }}
              >
                <Card
                  icon={wellbeingicon2}
                  text="Active"
                  num={stats?.patients?.active}
                  color="#e8f8ed"
                />
              </Link>
              <Link
                to="/Patients"
                style={{
                  textDecoration: "none",
                  color: "black",
                  pointerEvents: !patientsPerms?.status ? "none" : "",
                }}
                state={{ status: "inactive" }}
              >
                <Card
                  icon={wellbeingicon3}
                  text="Inactive"
                  num={stats?.patients?.inactive}
                  color="#fde5e5"
                />
              </Link>
              <Link
                to="/Patients"
                style={{
                  textDecoration: "none",
                  color: "black",
                  pointerEvents: !patientsPerms?.status ? "none" : "",
                }}
                state={{ status: "birthday" }}
              >
                <Card
                  icon={gifticon}
                  text="Birthdays"
                  num={stats?.patients?.birthday}
                  color="#fff2eb"
                />
              </Link>
            </div>
          </>
        ) : null}

        {+enterprisesPer?.access?.Read ? (
          <>
            <div className={styles.sub_title}>
              <Heading title="Enterprises" />
            </div>
            <div className={styles.card_container}>
              <Link
                to="/Enterprises"
                style={{
                  textDecoration: "none",
                  color: "black",
                  pointerEvents: !enterprisesPerms?.status ? "none" : "",
                }}
              >
                <Card
                  icon={enterpriseicon1}
                  text="Total Enterprises"
                  num={stats?.enterprises?.total}
                  color="#f2eef7"
                />
              </Link>
              <Link
                to="/Enterprises"
                style={{
                  textDecoration: "none",
                  color: "black",
                  pointerEvents: !enterprisesPerms?.status ? "none" : "",
                }}
                state={{ status: "active" }}
              >
                <Card
                  icon={enterpriseicon2}
                  text="Active"
                  num={stats?.enterprises?.active}
                  color="#e8f8ed"
                />
              </Link>
              <Link
                to="/Enterprises"
                style={{
                  textDecoration: "none",
                  color: "black",
                  pointerEvents: !enterprisesPerms?.status ? "none" : "",
                }}
                state={{ status: "inactive" }}
              >
                <Card
                  icon={enterpriseicon3}
                  text="InActive"
                  num={stats?.enterprises?.inactive}
                  color="#fde5e5"
                />
              </Link>
            </div>
          </>
        ) : null}

        {+othersPer?.access?.Read ? (
          <>
            <div className={styles.sub_title}>
              <Heading title="Others" />
            </div>
            <div className={styles.card_container}>
              <Link
                to="/Samvedna/feedback"
                style={{
                  textDecoration: "none",
                  color: "black",
                  pointerEvents: !readPermissionsFeed ? "none" : "",
                }}
                state={{ status: "inactive" }}
              >
                <Card
                  icon={smileicon}
                  text="Feedback Received"
                  num={stats?.others?.feedback_received}
                  color="#f2eef7"
                />
              </Link>
              <Link
                to="/Samvedna/testimonials"
                style={{
                  textDecoration: "none",
                  color: "black",
                  pointerEvents: !readPermissionsTest ? "none" : "",
                }}
                state={{ status: "inactive" }}
              >
                <Card
                  icon={messageIcon}
                  text="Testimonial Received"
                  num={stats?.others?.testimonial_received}
                  color="#FEF5E6"
                />
              </Link>
              <Link
                to="/Events"
                style={{
                  textDecoration: "none",
                  color: "black",
                  pointerEvents: !eventsPerms?.status ? "none" : "",
                }}
                // state={{ status: "birthday" }}
              >
                <Card
                  icon={starIcon}
                  text="Events"
                  num={stats?.others?.events}
                  color="#ECF2FF"
                />
              </Link>
            </div>
          </>
        ) : null}

        <div className={styles.graph_div}>
          {+appointmentsPer?.access?.Read ? (
            <OverviewAppointment appointmentsPer />
          ) : null}
        </div>
        <div className={styles.graph_div}>
          {+paymentsPer?.access?.Read ? <OverviewPayments /> : null}
        </div>
      </div>
    </>
  );
};
export default Overview;
