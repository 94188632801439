import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";
import axios from "../../../../node_modules/axios/index";
import { toast } from "../../../../node_modules/react-hot-toast/dist/index";
import { Buttons } from "../../../Component/UI/Button/Button";
import { FileUpload, FileUploadXl } from "../../../Component/UI/FileUpload";
import { importExcelSheet } from "../../../services/admin/patient";

function ImportFile() {
  const [excelName, setExcelName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const acceptExtentions = ["xlsx", "csv", "xls"];

  const importExcel = (e) => {
    const f = e?.target?.files[0];
    const fileExtention = f?.name.split(".").at(-1);
    if (acceptExtentions.includes(fileExtention)) {
      setExcelName(f);
    } else {
      setExcelName("");
      toast.error("Please select valid format file");
    }
  };
  const actualBtnRef = useRef();

  console.log(excelName, "excelName");

  const submitExcel = () => {
    debugger;
    toast.remove();
    if (!excelName) {
      toast.error("Please Select File");
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", excelName);
    importExcelSheet(formData).then((res) => {
      setIsLoading(false);
      setExcelName("");
      actualBtnRef.current.value = "";
      if (res?.data?.status) {
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.error[0]);
      }
    });
  };
  return (
    <div
      style={{
        border: "1px solid #cccccc",
        background: "#ffffff",
        justifyContent: "center",
      }}
    >
      <div style={{ margin: "20px 70px 0", textAlign: "right" }}>
        <a
          style={{ color: "#7a51ae" }}
          href="https://mapapi.samvednacare.com/excel/import_bulk_user.xlsx"
          className="export-btn"
          download=""
        >
          <i class="la h4 mr-1 mb-0 la-download"></i> Download Sample
        </a>
      </div>

      <div
        style={{
          margin: "20px 70px 0px",
        }}
      >
        {/* {!isLoading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress size={30} />
            <p style={{ color: "black" }}>
              Please wait ! We are validating the details...
            </p>
          </div>
        )} */}
        <FileUploadXl
          marginTop={0}
          onChange={importExcel}
          actualBtnRef={actualBtnRef}
          fileName={excelName?.name}
        />
      </div>
      <div style={{ textAlign: "right", margin: "20px 70px" }}>
        <Buttons
          variant="contained"
          size="small"
          style={{
            textTransform: "uppercase",
            width: "200px",
            backgroundColor: "#7A51AE",
            borderRadius: "0px",
            textAlign: "center !important",
            height: "39px",
            margin: "0",
          }}
          disable={isLoading}
          click={submitExcel}
          name={isLoading ? <CircularProgress size={28} /> : "Import User Data"}
        />
      </div>
    </div>
  );
}

export default ImportFile;
