import { Toggle } from "../../Component/UI/Toggle";
import styles from "../../assets/css/MyAccount/notifications.module.css";
import Divider from "@mui/material/Divider";
import { MyAccount } from "../../HOC/MyAccount";
import { useEffect, useState } from "react";
import {
  editNotifications,
  getNotification,
} from "../../services/patient/notifications";
import toast from "react-hot-toast";

const Notifications = () => {
  const options = [
    {
      name: "All Notifications",
      value: "all",
    },
    { name: "Appointments", value: "appointments" },
    {
      name: "Reports",
      value: "reports",
    },
    {
      name: "Events",
      value: "events",
    },
    {
      name: "Activity",
      value: "activity",
    },
    // {
    //   name: "SMS",
    //   value: "sms",
    // },
    {
      name: "Email",
      value: "email",
    },
  ];

  const initialValues = {
    appointments: 0,
    reports: 0,
    events: 0,
    activity: 0,
    sms: 0,
    email: 0,
  };

  const allEnabled = {
    appointments: 1,
    reports: 1,
    events: 1,
    activity: 1,
    sms: 1,
    email: 1,
  };

  const [toggleValues, setToggleValues] = useState(initialValues);
  const [all, setAll] = useState(0);

  const checkAllEnabled = () => {
    const { appointments, reports, events, activity, sms, email } =
      toggleValues;

    if (appointments && reports && events && activity && email) {
      setAll(1);
    } else {
      setAll(0);
    }
  };

  useEffect(() => {
    getNotification()
      .then((res) => res?.data?.list && setToggleValues(res?.data?.list))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    checkAllEnabled();
  }, [toggleValues]);

  return (
    <>
      <h2>Notifications</h2>
      {options.map(({ name, value }, i) => {
        const isAll = value === "all";
        return (
          <div className={styles.notifications} key={i}>
            <div>
              <p>{name}</p>
              <Toggle
                toggleWidth="54px"
                toggleHeight="28px"
                thumbSize="20px"
                value={isAll ? all : toggleValues?.[value]}
                toggleHandler={() => {
                  if (isAll) {
                    setToggleValues(all ? initialValues : allEnabled);
                  }
                  const data = isAll
                    ? all
                      ? initialValues
                      : allEnabled
                    : {
                        ...toggleValues,
                        [value]: Number(!toggleValues[value]),
                      };
                  editNotifications(data)
                    .then((res) => toast.success(res?.data?.message))
                    .catch((err) => console.log(err));
                  setToggleValues(data);
                }}
              />
            </div>
            <Divider light />
          </div>
        );
      })}
    </>
  );
};

export const NotificationsHOC = MyAccount(Notifications);
