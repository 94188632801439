import React from "react";
import classes from "../../../../../assets/css/activity.module.css";
import trash from "../../../../../assets/icons/icon/trash.svg";
import timer from "../../../../../assets/icons/icon/timer.svg";
import { Input } from "../../../../../Component/UI/CustomInputs";

export const ActivityPerformance = () => {
  return (
    <>
      <div className={classes.activity_flex}>
        <div className={classes.activity_div}>
          <Input
            className={classes.activity_textbox}
            label="Activity"
            placeholder="Word search"
            variant="standard"
          />
        </div>
        <div className={classes.activity_div}>
          <label>Duration(Minutes)</label>
          <select className={classes.activity_select} name="">
            <option value="">15</option>
            <option value="">20</option>
          </select>
        </div>
        <div className={classes.activity_per}>
          <span className={classes.activity_span_one}>
            <img src={timer} />
          </span>

          <p>Performance/Observation History</p>
          <span className={classes.activity_span_two}>
            <img src={trash} />
          </span>
        </div>
        {/* <div className={classes.activity_trashicon}>
          <img src={trash} />
        </div> */}
      </div>
      <Input
        label="Description"
        className={classes.activity_textfield}
        variant="standard"
      />
    </>
  );
};
