import React, { useEffect, useState } from "react";

import axios from "../../../config/axiosConfig";
import { CustomTable } from "../../../Component/UI/Table";
import SwitchButton from "../../../Component/UI/SwitchButton/Switch";
import { Link } from "react-router-dom";

import { Edit } from "../../../assets/icons/Edit";
import { Cross } from "../../../assets/icons/Cross";
import Add from "../../../assets/images/add.svg";
import Reschedule from "../../../assets/images/reschedule.svg";
import OnlineImg from "../../../assets/images/appOnline.svg";
import OfflineImg from "../../../assets/images/appOffline.svg";
import HybirdImg from "../../../assets/images/appHybird.svg";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { to12Hours, YtoD } from "../../../config/formattedDate";
import {
  setFilter,
  setFrom_date,
  setSearch_key,
  setTo_date,
  setClear,
} from "../../../Redux/Slices/AdminFilters/Search";
import { useDispatch, useSelector } from "react-redux";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import { AssignModal } from "./assignPopup";
import Modal from "./Model";
import ReschedulePopup from "./ReschedulePopup";
import {
  CancelModal,
  CancelModalSuccess,
  AssignModalSuccess,
} from "./CanelAppModule";
import { toast } from "../../../../node_modules/react-hot-toast/dist/index";
import moment from "../../../../node_modules/moment/moment";
import { usePermissions } from "../../../Hooks/usePermissions";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";

export const Appointments = ({ setUpcomingData, permissions }) => {
  const fullpermissions = +permissions?.listControl?.access?.["Full Control"];
  const writePermissions = +permissions?.listControl?.access?.Write;
  const [client, setClient] = useState([]);
  const dispatch = useDispatch();
  const [assignDoctor, setAssignDoctor] = useState({ open: false });
  const [rescheduleAppointment, setRescheduleAppointment] = useState({
    open: false,
  });
  const [joinPopup, setJoinPopup] = useState({ open: false });
  const [cancelPopup, setCancelPopup] = useState({ open: false });
  const [cancelSuccess, setCancelSuccess] = useState({ open: false });
  const [assignSuccess, setAssignSuccess] = useState({ open: false });
  const [loading, setLoading] = useState(false);
  const [loadingApp, setLoadingApp] = useState(false);

  const { search_key, from_date, to_date, filter } = useSelector(
    (i) => i.SearchFilters
  );

  const tHead = [
    "Patient Name",
    "Mobile",
    "Date",
    "Appointment ID",
    "Time",
    "Staff",
    ...(writePermissions || fullpermissions ? ["Action"] : []),
  ];

  const clientStatus = async (appointment_id, status) => {
    await axios
      .post("/admin/appointment/status/change", {
        appointment_id,
        status,
      })
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
  };

  console.log(cancelPopup, "cancelPopup");

  const handleDelete = async (appointment_id, status) => {
    let status_value = status ? 0 : 1;
    console.log(appointment_id, "appointment_id");
    const params = {
      appointment_id: appointment_id,
      status: status_value,
    };
    await axios
      .post("/admin/delete/appointment", params)
      .then((res) => {
        // dispatch(fetchEvents());
        setClient(res.data.list);
        getApi();
      })
      .catch((err) => console.error(err));
  };

  const getApi = () => {
    setLoadingApp(true);
    axios
      .get(
        `/admin/get/appointmentNew?type=upcoming&search_key=${search_key}&fromdate=${from_date}&todate=${to_date}`
      )
      .then((res) => {
        setLoadingApp(false);
        setClient(res.data.list);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getApi();
    //toggle status
    // toggleStatus(2, 0);
  }, [filter]);

  useEffect(() => {
    setUpcomingData(client);
  }, [client]);

  useEffect(() => {
    return () => {
      //  dispatch(setSearch_key(""));
      dispatch(setTo_date(""));
      dispatch(setFrom_date(""));
      dispatch(setFilter());
      dispatch(setClear());
    };
  }, []);

  const eventAction = async (
    appointment_id,
    status,
    appointment_start_date
  ) => {
    setLoading(true);
    await axios
      .post("/admin/appointment/status/change", {
        appointment_id,
        status,
        appointment_start_date,
      })
      .then((res) => {
        setLoading(false);
        console.log(res);
        getApi();
        setJoinPopup({ open: false });
        setCancelPopup({ open: false });
        setCancelSuccess({ open: true, body: { data: res?.data?.msg } });
      })
      .catch((err) => console.error(err));
  };

  const tBodyData = client?.map(
    (
      {
        paitient_name,
        mobile,
        appointment_date,
        session_id,
        service_type,
        appointment_time,
        end_time,
        status,
        id,
        appointment_link,
        staff_id,
        staff,
        staff_data,
        roster_id,
        enterprise_service_type,
        services_for,
        service_name,
        specialization_id,
      },
      i
    ) => {
      const date = YtoD(appointment_date.split(" ")[0]);
      const appDate = appointment_date.split(" ")[0];
      const [start_hour, start_min] = appointment_time.split(":");
      const [end_hour, end_min] = end_time.split(":");
      const getAppointment_date = moment(appointment_date).format("YYYY-MM-DD");

      const currentDate = moment().format("YYYY-MM-DD");
      const condCheck = moment(getAppointment_date).isBefore(currentDate);
      const time =
        moment(appointment_date).format("h:mm A") +
        " - " +
        moment(end_time, "hh:mm").format("hh:mm A");
      const cancelCond =
        parseInt(enterprise_service_type) === 0 && parseInt(services_for) === 1;
      return {
        paitient_name,
        mobile,
        appointment_date: appointment_date
          ? moment(appointment_date).format("DD-MM-YYYY")
          : "",
        session: (
          <>
            <div
              id={"AppointmentID" + i}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <span>
                {" "}
                {service_type === 0 ? (
                  <img
                    style={{
                      width: "17px",
                      marginTop: "1px",
                      marginRight: "3px",
                    }}
                    src={OfflineImg}
                    alt={"Offline"}
                  />
                ) : service_type === 1 ? (
                  <img
                    style={{
                      width: "17px",
                      marginTop: "1px",
                      marginRight: "3px",
                    }}
                    src={OnlineImg}
                    alt={"Online"}
                  />
                ) : service_type === 2 ? (
                  <img
                    style={{
                      width: "17px",
                      marginTop: "1px",
                      marginRight: "3px",
                    }}
                    src={HybirdImg}
                    alt={"Hybird"}
                  />
                ) : (
                  ""
                )}
              </span>
              <span style={{ color: "#0085FF", textDecoration: "underline" }}>
                {session_id?.toUpperCase()}
              </span>
            </div>
            <ReactTooltip
              anchorId={"AppointmentID" + i}
              place="bottom"
              //variant="info"
              content={service_name}
            />
          </>
        ),
        time,
        staff,
        // status,
        // status: (
        //   <SwitchButton
        //     status={status}
        //     onClick={(value) => clientStatus(id, value)}
        //   />
        // ),

        action: (
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div
              style={{
                color: "red",
                cursor: `${condCheck ? "not-allowed" : "pointer"}`,
                marginRight: "8px",
              }}
              onClick={() =>
                condCheck
                  ? ""
                  : staff_id
                  ? setRescheduleAppointment({
                      open: true,
                      body: {
                        appointment_date: appDate,
                        time_slot: appointment_time,
                        staff_id: staff_id,
                        staff_data: staff_data,
                        appointment_link: appointment_link,
                        service_type: service_type,
                        roster_id: roster_id,
                        specialization_id: specialization_id,
                      },
                      id,
                    })
                  : setAssignDoctor({
                      open: true,
                      body: {
                        appointment_date: appointment_date,
                        appDate: appDate,
                        time_slot: appointment_time,
                        service_type: service_type,
                      },
                      id,
                    })
              }
            >
              {staff_id ? (
                writePermissions ? (
                  <img src={Reschedule} alt="Reschedule" />
                ) : null
              ) : fullpermissions ? (
                <img src={Add} alt="add" />
              ) : null}
            </div>

            {/* <Link to={`user/edit/${id}`} style={{ textDecoration: "none" }}>
              <Edit />
            </Link> */}
            {writePermissions ? (
              <Btn
                variant="contained"
                bgColor="#000"
                color="#fff"
                cursor={condCheck ? "not-allowed" : "pointer"}
                width="55px"
                height="24px"
                whiteSpace="nowrap"
                borderRadius="100px"
                fontSize="12px"
                click={() => {
                  setJoinPopup({
                    ...joinPopup,
                    open: true,
                    id,
                    link: appointment_link,
                    service_type: service_type,
                  });
                }}
                disabled={!appointment_link || condCheck}
              >
                Join
              </Btn>
            ) : null}

            {writePermissions ? (
              <div
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => {
                  // cancelCond
                  //   ? toast.error("You can't cancel this appointment")
                  //   :
                  setCancelPopup({
                    ...cancelPopup,
                    open: true,
                    body: { id: id, item: 2 },
                  });
                }}
              >
                <Cross />
              </div>
            ) : null}
          </div>
        ),
      };
    }
  );

  const joinLink = (service_type) => {
    debugger;
    if (service_type !== 0) {
      window.open(joinPopup?.link);
    }

    eventAction(joinPopup.id, 3, moment().format("YYYY-MM-DD HH:mm:ss"));
  };

  const cancelAppointment = () => {
    eventAction(cancelPopup?.body?.id, cancelPopup?.body?.item);
  };

  return (
    <>
      {!loadingApp ? (
        <CustomTable tHead={tHead} tBody={tBodyData} />
      ) : (
        <div className="loader" style={{ textAlign: "center", margin: "60px" }}>
          <CircularProgress size={28} />
        </div>
      )}

      {assignDoctor.open && (
        <AssignModal
          setAssignSuccess={setAssignSuccess}
          setAssignDoctor={setAssignDoctor}
          data={assignDoctor}
          getApi={getApi}
        />
      )}

      {assignSuccess.open && (
        <AssignModalSuccess
          assignSuccess={assignSuccess}
          setAssignSuccess={setAssignSuccess}
        />
      )}

      {rescheduleAppointment.open && (
        <ReschedulePopup
          setAssignSuccess={setAssignSuccess}
          setRescheduleAppointment={setRescheduleAppointment}
          data={rescheduleAppointment}
          getApi={getApi}
        />
      )}

      {joinPopup.open && (
        <Modal
          joinLink={joinLink}
          link={joinPopup?.link}
          service_type={joinPopup?.service_type}
          setJoinPopup={setJoinPopup}
        />
      )}

      {cancelPopup.open && (
        <CancelModal
          loading={loading}
          setLoading={setLoading}
          cancelPopup={cancelPopup}
          setOpen={setCancelPopup}
          cancelAppointmentFun={cancelAppointment}
        />
      )}
      {cancelSuccess.open && (
        <CancelModalSuccess
          cancelSuccess={cancelSuccess}
          setCancelSuccess={setCancelSuccess}
        />
      )}
    </>
  );
};
