import { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { grey } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAddSelfCare,
  selfCareSelector,
} from "../../../Redux/Slices/Assessment";
// import { TbEdit } from "react-icons/tb";
import { Edit } from "../../../assets/icons/Edit";
import { Link } from "react-router-dom";
import { toast } from "../../../../node_modules/react-hot-toast/dist/index";
import {
  setFilter,
  setFrom_date,
  setSearch_key,
  setTo_date,
  setClear,
} from "../../../Redux/Slices/AdminFilters/Search";

const SelfCareTips = ({ permissions }) => {
  const [toggle, setToggle] = useState("");
  //TODO: useDispatch & useSelector
  const dispatch = useDispatch();
  const { search_key, from_date, to_date, filter } = useSelector(
    (i) => i.SearchFilters
  );
  const [isLoading, setIsLoading] = useState(false);
  const { selfCare } = useSelector((state) => state.selfCare);
  // permission code
  const fullpermissions = +permissions?.listControl?.access?.["Full Control"];
  const writePermissions = +permissions?.listControl?.access?.Write;
  // permissions end

  useEffect(() => {
    dispatch(fetchAddSelfCare(search_key, from_date, to_date, setIsLoading));
  }, [dispatch, filter]);

  useEffect(() => {
    return () => {
      // dispatch(setSearch_key(""));
      dispatch(setTo_date(""));
      dispatch(setFrom_date(""));
      dispatch(setFilter());
      dispatch(setClear());
    };
  }, []);

  const SwitchHandler = (e, params) => {
    //console.log(e,"....................",params.id,"..............",params.row.status)
    setToggle(params.row.status);
  };

  const columns = useMemo(() => [
    {
      field: "name",
      headerName: "Category",
      flex: 1,
      renderCell: (params) => (
        <Link
          to={"/editselfcaretips"}
          state={{ from: "edit", id: params.id, value: params.value }}
          style={{
            color: "black",
            textDecoration: "none",
            pointerEvents: !writePermissions ? "none" : "",
          }}
        >
          {params.value}
        </Link>
      ),
    },
    { field: "selfcare_count", headerName: "No. Of tips", width: 200 },
    {
      field: "date_of_creation",
      headerName: "Date",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      type: "boolean",
      renderCell: (params) => (
        //console.log(params.row.status, "params.row.status"),
        <p style={{ color: params.row.status === 1 ? "green" : "grey" }}>
          {params.row.status === 1 ? "Active" : "Disabled"}
        </p>
      ),
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      flex: 1,
      hide: writePermissions === 0 ? true : false,

      renderCell: (params) => (
        <Link
          to={"/editselfcaretips"}
          state={{ from: "edit", id: params.id, value: params.row.name }}
          style={{ color: "black", textDecoration: "none" }}
        >
          <Edit style={{ width: "30px", heigth: "25px" }} />
        </Link>
      ),
    },
  ]);

  useEffect(() => {
    return () => toast.remove();
  }, []);

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
      }}
    >
      <DataGrid
        columns={columns}
        rows={selfCare}
        sx={{
          backgroundColor: "white",
          [`& .${gridClasses.row}`]: {
            bgcolor: (theme) =>
              theme.palette.mode === "light" ? "white" : grey[100],
          },
        }}
      />
    </Box>
  );
};

export default SelfCareTips;
