import React, { useEffect } from "react";
import styles from "./consent.module.css";
import { useState } from "react";
import { CheckBox } from "../../Component/UI/CustomInputs";
import { useAuth } from "../../Auth/AuthProvider";
import { useNavigate } from "../../../node_modules/react-router-dom/index";
import axios from "../../config/axiosConfig";
import {
  Toaster,
  toast,
} from "../../../node_modules/react-hot-toast/dist/index";
import { Btn } from "../UI/Button";
import { CircularProgress } from "../../../node_modules/@mui/material/index";
import {
  consentSubmitTwo,
  getConsentData,
} from "../../services/patient/appointments";
import moment from "../../../node_modules/moment/moment";

export default function ConsentTwo() {
  const [isLoading, setIsLoading] = useState(false);
  const { loginUser } = useAuth();
  const [error, setError] = useState();
  const navigate = useNavigate();
  const [isConsentUpdated, setIsConsentUpdated] = useState(false);
  const [consentData, setConsentData] = useState();
  const [isOpenConstentTwo, setIsOpenConstentTwo] = useState({
    consent: false,
  });
  const [subscriptions, setSubscriptions] = useState([]);
  const location = window?.location?.pathname;
  const uniqIdSplit = location?.split("&")?.[1];
  const uniqId = uniqIdSplit?.split("=")?.[1];
  console.log(loginUser, "consentData");
  useEffect(() => {
    getConsentDataForm();
  }, [isConsentUpdated]);
  const getConsentDataForm = () => {
    getConsentData(loginUser?.customer?.id, uniqId).then((res) => {
      if (res?.data?.status) {
        setConsentData(res?.data?.data?.consent);
        setSubscriptions(res?.data?.data?.Subscription);
      } else {
        navigate("/dashboard");
        //  toast.error("");
      }
    });
  };

  const handleSubmit = () => {
    if (!isOpenConstentTwo.consent) {
      toast.error("Please select my consent");
      return;
    }
    setIsLoading(true);
    consentSubmitTwo({
      customer_id: loginUser?.customer?.id,
      consentId: uniqId,
    }).then((res) => {
      console.log(res?.data);
      setIsLoading(false);
      if (res?.data?.status) {
        setIsConsentUpdated((prev) => !prev);
        toast.success(res?.data?.msg);
        navigate("/dashboard");
      } else {
        toast.success(res?.data?.msg);
      }
    });
  };

  return (
    <>
      <Toaster />
      <div className={styles.consentForm} style={{ marginTop: "10px" }}>
        <div className={styles.consentWrapper}>
          <div className={styles.consentHead}>Consent Form</div>
          <div className={styles.consentBody}>
            <h2 className={styles.headingName}>
              Consent & SERVICES AGREEMENT for Nursing & Care Services
            </h2>
            <div className={styles.consentBodyContent}>
              This agreement dated{" "}
              <strong>
                {moment(consentData?.generated_at).format("Do MMMM YYYY")}
              </strong>{" "}
              entered into by and between:
            </div>
            <div className={styles.consentBodyContent}>
              <strong>Samvedna Senior Care</strong>, a company serving in the
              field of home care with its business office located at G-7,
              Sushant Lok, Phase 3, Sector 57, Gurgaon, India;
            </div>
            <div className={styles.consentBodyContent}>
              <h5>AND</h5>
            </div>
            <div className={styles.consentBodyContent}>
              <strong>Mr. / Mrs.</strong> {consentData?.patient_name} resident
              of
              {/* <input
                placeholder="Address"
                name="address"
                onChange={handleChange}
                value={formData?.address}
                // className={error.address ? styles.errorClass : ""}
              /> */}{" "}
              {consentData?.patient_address + " "} who will receive services
              under the agreement (hereinafter referred to as “Patient”)
            </div>
            <div className={styles.consentBodyContent}>
              <strong>{loginUser?.customer?.name}</strong> who is a legally
              acceptable representative (hereinafter referred to “LAR”) of
              PATIENT and is authorized to sign on behalf of the PATIENT,
              contracting in his/her personal capacity as well as on behalf of
              the PATIENT.
            </div>
            <div className={styles.consentBodyContent}>
              This agreement defines the scope, terms and conditions of services
              and the contractual obligations between the aforementioned parties
              during the term of the agreement. By signing this Agreement, the
              parties agree to be bound by the provisions of this agreement.
            </div>
            <div className={styles.consentBodyContent}>
              WHEREAS <strong>Samvedna Senior Care</strong> is inter alia in the
              business of providing home care services to patients.
            </div>
            <div className={styles.consentBodyContent}>
              <strong>PATIENT/LAR</strong> is desirous of availing these
              services from <strong>Samvedna Senior Care</strong> whose scope is
              described in the table below (such services are henceforth
              referred to as “<strong>Services</strong>”) on such terms and
              conditions and on such pricing as specified in this agreement.
            </div>
            {/* <div className={styles.table}>
              <CustomTable pagination={false} tHead={tHead} tBody={tBodyData} />
            </div> */}
            <h2
              style={{ textAlign: "left", marginBottom: "0" }}
              className={styles.headingName}
            >
              Subscription Details
            </h2>
            <div className={styles.consentBodyContent}>
              {subscriptions?.length > 0 ? (
                <div className={styles.subscription}>
                  <p>
                    <b>Subscription Name - </b>
                    {subscriptions[0].name}
                  </p>
                  <p>
                    <b>Date of Creation- </b>{" "}
                    {moment(subscriptions[0]?.created_at).format("DD-MM-YYYY")}{" "}
                  </p>
                  <p>
                    <b>Validity- </b> {`${subscriptions[0]?.validity} Days`}{" "}
                  </p>
                  {subscriptions[0].subscriptionservice?.map(
                    (service, index) => {
                      return (
                        <>
                          <p>
                            <b>Service Name - </b> {service?.service_name},
                          </p>
                          <p>
                            <b>Number of Sessions -</b>{" "}
                            {service?.number_session}
                          </p>
                        </>
                      );
                    }
                  )}

                  <p>
                    <b>Total Subscription Amount - </b>
                    {subscriptions[0]?.net_price}
                  </p>
                  <p>
                    <b>INR Payment Mode - </b>
                    {subscriptions[0]?.payment_mode}
                  </p>
                </div>
              ) : (
                "No Subscription Available"
              )}
            </div>
            <div className={styles.consentBodyContent}>
              The initial term of this agreement will be for a period of{" "}
              <strong>07</strong>
              days. Thereafter, it will be automatically renewed unless any one
              of the parties specifically terminates the agreement inwriting.
            </div>
            <div className={styles.consentBodyContent}>
              The Patient/LAR declares (LAR in case of patients suffering from
              Dementia or similar neurological disorders) that he/she is more
              than 18 years of age and of sound mental health and is providing
              <strong>Samvedna Senior Care</strong> with the personal and
              medical information of the Patient voluntarily forthe purpose of
              completing the patient registration process as well as planning
              the service to be rendered.
            </div>
            <div className={styles.consentBodyContent}>
              The Patient/LAR declares that he/she understands and is fully
              aware of the risks associated with home care. They have been
              explained to him/her in detail by the Samvedna Senior Care’s
              Coordinator and has had sufficient opportunity to ask questions
            </div>
            <div className={styles.consentBodyContent}>
              The Patient/LAR acknowledges that as far{" "}
              <strong>Samvedna Senior Care</strong> is concerned, their services
              are restricted to implementation of medical treatment and advice
              as stipulated by the Patient’s treating doctor/medical consultant
            </div>
            <div className={styles.consentBodyContent}>
              The Patient/LAR also accepts that he/she will not, directly or
              indirectly, solicit services of staff deployed by{" "}
              <strong>Samvedna Senior Care</strong>
            </div>
            <div
              style={{ textDecoration: "underline" }}
              className={styles.consentBodyContent}
            >
              <strong>
                <i>
                  The Patient/LAR declares that he/she has read and understood
                  the terms & conditions as mentioned in thie Samvedna Service
                  Request & Consent Form. The Patient/LAR hereby agrees to the
                  terms and conditions of Samvedna Service Request & Consent
                  Form and authorizes Samvedna Senior Care to carry out its
                  services as per their standard procedures.
                </i>
              </strong>
            </div>
            <h2
              style={{
                textDecoration: "underline",
                marginTop: "30px",
              }}
              className={styles.headingName}
            >
              <i>TERMS & CONDITIONS</i>
            </h2>
            <div
              className={`${styles.consentBodyContent} ${styles.termAndComp}`}
            >
              <h2 style={{ textAlign: "left" }} className={styles.headingName}>
                1.{" "}
                <span style={{ textDecoration: "underline" }}>Definitions</span>
              </h2>
              <p>
                1.1.
                <strong>“Patient/ Elder Member” </strong>who will receive
                services under the agreement.
              </p>
              <p>
                1.2.
                <strong>
                  “LAR” legally acceptable representative of PATIENT.{" "}
                </strong>
              </p>
            </div>
            <div
              className={`${styles.consentBodyContent} ${styles.termAndComp}`}
            >
              <h2 style={{ textAlign: "left" }} className={styles.headingName}>
                2.{" "}
                <span style={{ textDecoration: "underline" }}>
                  General Terms
                </span>
              </h2>
              <p>
                2.1. Samvedna Senior Care services are non-transferable and only
                cover the patient named in the Patient Services Agreement.
              </p>
              <p>
                2.2. The Patient/LAR confirms that the nature and scope of
                Services have been fully explained to him/her.
              </p>
              <p>
                2.3. The Patient has provided general details, physician
                details, emergency contact details, medical history including
                pre-existing medical condition and medication information to the
                best of his/her knowledge.
              </p>
              <p>
                2.4. Samvedna Senior Care services are not a substitute for
                emergency medical services offered at hospitals. In case of a
                medical emergency, the caregiver should contact emergency
                service providers such as ambulances and/or rush to nearest
                hospital along with informing Samvedna Senior Care about it.
              </p>
            </div>
            <div
              className={`${styles.consentBodyContent} ${styles.termAndComp}`}
            >
              <h2 style={{ textAlign: "left" }} className={styles.headingName}>
                3.{" "}
                <span style={{ textDecoration: "underline" }}>
                  PATIENT/LAR Rights
                </span>
              </h2>
              <p>
                3.1. Be treated with courtesy, dignity respect & be cared for by
                suitably qualified and trained healthcare staff at all times.
              </p>
              <p>
                3.2. Have personal and medical information privacy and
                confidentiality protected as per prevailing government
                guidelines.
              </p>
              <p>
                3.3. Have complete information to make an informed decision with
                respect to the proposed Services being offered.
              </p>
              <p>
                3.4. Express concern or complain about the care or services
                provided to them or provide candid feedback on the Samvedna
                Senior Care staff.
              </p>
              <p>
                3.5. Request with supporting reasons for change of Samvedna
                Senior Care professional or any other service.
              </p>
              <p>
                3.6. LAR shall have a right to terminate this agreement with or
                without cause or in the event of PATIENT’s death.
              </p>
            </div>
            <div
              className={`${styles.consentBodyContent} ${styles.termAndComp}`}
            >
              <h2 style={{ textAlign: "left" }} className={styles.headingName}>
                4.{" "}
                <span style={{ textDecoration: "underline" }}>
                  PATIENT/LAR Obligations
                </span>
              </h2>
              <p>
                4.1. To provide full and accurate information about PATIENT’s
                health, medical history and personal care needs to the Samvedna
                Senior Care professional.
              </p>
              <p>
                4.2. To strictly adhere to the advice and instructions given by
                the treating Physician.
              </p>
              <p>
                4.3 To inform treating Physician immediately, if
                <ul>
                  <li>
                    4.3.1. changes in - patient’s health condition or care
                    needs; or
                  </li>

                  <li>4.3.2. a medical emergency develops; or</li>
                  <li>4.3.3. PATIENT’s health deteriorates abruptly; or </li>
                  <li>4.3.4. In the unfortunate event of PATIENT death.</li>
                </ul>
              </p>

              <p>
                4.4. To provide following documents for PATIENT including one
                (1) Proof of Identity and one (1) Proof of Address.
              </p>
              <p>
                4.5. To provide the address of PATIENT/family member where
                Samvedna Senior Care staff will be required to report to in
                writing and ensure that the said address is a safe and habitable
                place for Samvedna Senior Care Staff.
              </p>
              <p>
                4.6. In case the PATIENT/LAR wishes to change any scope of
                Services or Samvedna Senior Care Staff or equipment, the same
                needs to be intimated to Samvedna Senior Care in writing along
                with supporting reasons in advance. Samvedna Senior Care shall
                review and approve the change request at its sole discretion.
              </p>
              <p>
                4.7. To inform Samvedna Senior Care of any non-availability of
                PATIENT due to travel etc. at least 2 business days in advance.
                In case of failure to inform, the Services shall be deemed to
                have been rendered by Samvedna Senior Care.
              </p>
              <p>
                4.8. To treat all Samvedna Senior Care staff or representative
                involved in the Services, whether residential or not, with
                courtesy and respect at all times. Any kind of violent or
                abusive behavior including foul language, physical attack or
                assault, mental harassment, sexual assault and harassment is
                unacceptable. Any such acts shall result in immediate
                termination of contract apart from other suitable police and
                legal action by Samvedna Senior Care.
              </p>

              <p>
                4.9. Any acts of individual misdemeanor or any unlawful behavior
                on part of any Samvedna Senior Care employee/worker/staff
                provided would not bind Samvedna Senior Care into any legal
                culpability.
              </p>

              <p>
                4.10. 4.10.The PATIENT/LAR will not hire or seek to hire any
                <strong>Samvedna Senior Care</strong> staff directly or
                indirectly, or solicit, induce, recruit or encourage any of{" "}
                <strong>Samvedna Senior Care</strong>
                employees or independent contractors to leave their engagement
                with <strong>Samvedna Senior Care</strong> for any reason. Any
                such action will render PATIENT/LAR liable to pay liquidated
                damages towards loss of cost incurred for recruitment and
                training of the <strong>Samvedna Senior Care</strong> staff. The
                PATIENT/LAR agrees that the nurse, attendant or any other staff
                sent by <strong>Samvedna Senior Care</strong> to his/her home is
                for the exclusive purpose of rendering services to the PATIENT
                only and will not be asked to perform any other household
                functions or chores.
              </p>

              <p>
                4.11. The PATIENT/LAR understand that the Samvedna Senior Care
                Staff are neither authorized to accept, have custody of or have
                the use of cash, credit or debit cards, bank cards, Cheques or
                other valuables belonging to PATIENT or family members and nor
                are they to be paid any kind of cash by PATIENT/LAR.
              </p>

              <p>
                4.12.The PATIENT/LAR is responsible to protect his/her valuables
                and keep them in safe custody during period of visit by Samvedna
                Senior Care staff. Samvedna Senior Care will not be responsible
                for any such theft or misappropriation and will not pay any
                claims pertaining to such unauthorized use, theft or
                misappropriation.
              </p>
              <p>
                4.13. The PATIENT/LAR shall take good care of the equipment
                belonging to Samvedna Senior Care or its contractors which is
                placed at the residential address of the PATIENT for delivery of
                Services. In case of any loss or damage to such equipment due to
                any act or omission of the PATIENT/LAR or other occupants of the
                premises, the PATIENT/LAR shall make good such loss or damages
                to <strong>Samvedna Senior Care.</strong>
              </p>
            </div>

            <div
              className={`${styles.consentBodyContent} ${styles.termAndComp}`}
            >
              <h2 style={{ textAlign: "left" }} className={styles.headingName}>
                5.{" "}
                <span style={{ textDecoration: "underline" }}>
                  Samvedna Senior Care Rights
                </span>
              </h2>
              <p>
                5.1.Samvedna Senior Care reserves the right to demand agreed
                fees in advance and get paid by PATIENT/LAR in advance. In case
                the amount is not paid within the stipulated period, Samvedna
                Senior Care reserves the right to cease delivery of services
                without prior notice.
              </p>
              <p>
                5.2.Samvedna Senior Care will get suitably reimbursed for all
                additional or out of pocket expenses incurred by it for services
                provided to PATIENT.
              </p>

              <p>
                5.3.Samvedna Senior Care may sub-contract any or all services
                agreed under the Patient Services Agreement to third party
                partners such as Lab & Diagnostic Vendors, Emergency/Ambulance
                Services Vendors, Medical Equipment Vendors, Health Check-up
                Partners, Distributors, Chemists etc. to provide value added
                services to PATIENT.{" "}
              </p>
              <p>
                5.4.Samvedna Senior Care will record, save and store all
                personal information including health related information of the
                PATIENT electronically in a secure database. Samvedna Senior
                Care will however not be responsible for any inadvertent cyber
                data theft from its website or databases.{" "}
              </p>
              <p>
                5.5.Samvedna Senior Care shall further organize a basic medical
                assessment of the PATIENT if it so deems fit for the purpose of
                ascertaining eligibility of the PATIENT.
              </p>
            </div>

            <div
              className={`${styles.consentBodyContent} ${styles.termAndComp}`}
            >
              <h2 style={{ textAlign: "left" }} className={styles.headingName}>
                6.{" "}
                <span style={{ textDecoration: "underline" }}>
                  Samvedna Senior Care Obligations
                </span>
              </h2>
              <p>
                6.1. Provide suitably qualified and trained medical and
                paramedical staff to PATIENT.
              </p>
              <p>
                6.2. Ensure proper background checks for all staff deployed for
                services to the PATIENT
              </p>

              <p>
                6.3.Ensure proper uniform, hygiene and upkeep of all staff
                resources deployed at PATIENT’s address.
              </p>
              <p>
                6.4.Maintain PATIENT health data, reports, charts in a physical
                or electronic file for easy reference.
              </p>
              <p>
                6.5.Ensure privacy and confidentiality of all PATIENT data as
                per stipulated government guidelines and regulations subject to
                Samvedna Senior Care Privacy Policy and share the same only with
                third party medical and paramedical staff in course of the
                Services or when medically necessary.
              </p>
            </div>

            <div
              className={`${styles.consentBodyContent} ${styles.termAndComp}`}
            >
              <h2 style={{ textAlign: "left" }} className={styles.headingName}>
                7.{" "}
                <span style={{ textDecoration: "underline" }}>
                  Adherence to COVID protocols
                </span>
              </h2>
              <p>
                7.1. Samvedna will immediately inform if any of the staff
                deployed for home visits at client location has been exposed to
                COVID or has been infected with COVID.
              </p>
              <p>
                7.2. Prior to start of any home services, client will provide a
                declaration that no household member is COVID infected.
              </p>

              <p>
                7.3. The client will immediately inform Samvedna representative
                if any household member has been exposed to a person with COVID.
              </p>
            </div>

            <div
              className={`${styles.consentBodyContent} ${styles.termAndComp}`}
            >
              <h2 style={{ textAlign: "left" }} className={styles.headingName}>
                8.{" "}
                <span style={{ textDecoration: "underline" }}>
                  Payments Terms
                </span>
              </h2>
              <p>8.1. All payments are to be made 100% in advance.</p>
              <p>
                8.2. Any cancellations/rescheduling is as per{" "}
                <a
                  rel="noreferrer"
                  href="https://www.samvednacare.com/refund-policy"
                  target="_blank"
                >
                  Refund & Cancellation policy
                </a>
              </p>

              <p>
                8.3. GST is applicable on all services as per Govt. guidelines.
              </p>
            </div>

            <div
              className={`${styles.consentBodyContent} ${styles.termAndComp}`}
            >
              <h2 style={{ textAlign: "left" }} className={styles.headingName}>
                9.{" "}
                <span style={{ textDecoration: "underline" }}>
                  Indemnification and waiver of liability
                </span>
              </h2>
              <p>
                To the extent permitted by law, the patient/LAR hereby waives
                his/her right to initiate a claim, action, suit, or legal
                proceeding including Criminal Proceedings by whatever name
                called against <b>Samvedna Senior Care</b> and its directors,
                agents, employees and contractors arising from:
              </p>
              <p>9.1. Any pre-existing medical condition of the patient; or</p>
              <p>9.2. Any adverse drug reaction; or</p>
              <p>
                9.3. Medical procedure related condition that is not connected
                Samvedna Senior Care and provided during hospitalization.
              </p>
              <p>
                9.4. Any services which are not directly provided by Samvedna
                Senior Care.
              </p>
            </div>

            <div
              className={`${styles.consentBodyContent} ${styles.termAndComp}`}
            >
              <h2 style={{ textAlign: "left" }} className={styles.headingName}>
                10.{" "}
                <span style={{ textDecoration: "underline" }}>Disclaimer</span>
              </h2>

              <p>
                10.1. Samvedna Senior Care will record, save and store all
                personal information including health related information of the
                PATIENT electronically in a secure database. Samvedna Senior
                Care will however not be responsible for any inadvertent cyber
                data theft from its website or databases.{" "}
              </p>
              <p>
                10.2. All data related to patient details provided by client
                will be stored in Samvedna system for a period of up to 7 years.{" "}
              </p>
              <p>
                10.3. The information contained in the assessment reports, and
                medical prescriptions, associated e-mails and any other attached
                documents provided by Samvedna Senior Care to client is private
                & confidential and may not be used for medico-legal purposes.
              </p>
              <p>
                10.4. Client provides Samvedna Senior Care with the personal and
                medical information of the patient voluntarily for the purpose
                of completing the patient registration process as well as
                planning the service to be rendered.
              </p>
            </div>
          </div>

          <div className={styles.consentFooterPatient}>
            <div className={styles.declareForm}>
              <CheckBox
                boldness={600}
                value="I provide my consent"
                check={isOpenConstentTwo.consent}
                name="consent_declaration"
                click={(val) => {
                  setIsOpenConstentTwo((prev) => {
                    return {
                      ...prev,
                      consent: val,
                    };
                  });

                  setError("");
                }}
              />
              <span>
                <p style={{ color: "red" }}>{error}</p>
              </span>
            </div>
            <Btn
              variant="contained"
              bgColor="#7A51AE"
              color="#ffffff"
              width="120px"
              height="40px"
              whiteSpace="nowrap"
              borderRadius="100px"
              margin="15px"
              click={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={28} /> : "Submit"}
            </Btn>
          </div>
        </div>
      </div>
    </>
  );
}
