import { useNavigate } from "../../../../../../node_modules/react-router-dom/index";
import { Search } from "../../../../../assets/icons/Search";
import { Input, InputWithIcon } from "../../../../../Component/UI/CustomInputs";
import { Btn } from "../../../../../Samvenda-Patient/UI/Button";

export const Options = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "6px 0",
      }}
    >
      <h1>Email</h1>
      <div>
        <Btn
          variant="contained"
          bgColor="#00b091"
          borderRadius="20px"
          width="75px"
          click={() => navigate("add")}
        >
          Add
        </Btn>
      </div>
    </div>
  );
};
