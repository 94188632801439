import React from 'react'

const linkicon = () => {
  return (
    <svg width="40" height="50" viewBox="0 0 40 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M29.3335 6.33325H34.0002C35.2378 6.33325 36.4248 6.82492 37.3 7.70009C38.1752 8.57526 38.6668 9.76224 38.6668 10.9999V43.6666C38.6668 44.9043 38.1752 46.0912 37.3 46.9664C36.4248 47.8416 35.2378 48.3333 34.0002 48.3333H6.00016C4.76249 48.3333 3.5755 47.8416 2.70033 46.9664C1.82516 46.0912 1.3335 44.9043 1.3335 43.6666V10.9999C1.3335 9.76224 1.82516 8.57526 2.70033 7.70009C3.5755 6.82492 4.76249 6.33325 6.00016 6.33325H10.6668" stroke="#2A2A2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M26.9998 1.66675H12.9998C11.7112 1.66675 10.6665 2.71142 10.6665 4.00008V8.66675C10.6665 9.95541 11.7112 11.0001 12.9998 11.0001H26.9998C28.2885 11.0001 29.3332 9.95541 29.3332 8.66675V4.00008C29.3332 2.71142 28.2885 1.66675 26.9998 1.66675Z" stroke="#2A2A2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <g clip-path="url(#clip0_0_1)">
    <path d="M18.5371 28.786C18.8168 29.1599 19.1735 29.4692 19.5832 29.6931C19.9929 29.9169 20.446 30.05 20.9117 30.0834C21.3773 30.1167 21.8447 30.0495 22.2822 29.8864C22.7196 29.7232 23.1168 29.4678 23.4469 29.1377L25.4004 27.1842C25.9934 26.5701 26.3216 25.7477 26.3142 24.894C26.3068 24.0404 25.9644 23.2238 25.3607 22.6201C24.7571 22.0165 23.9405 21.674 23.0868 21.6666C22.2331 21.6592 21.4107 21.9874 20.7966 22.5804L19.6766 23.6939" stroke="#2A2A2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.1415 27.4836C20.8618 27.1098 20.505 26.8004 20.0953 26.5766C19.6856 26.3528 19.2326 26.2196 18.7669 26.1863C18.3012 26.1529 17.8338 26.2201 17.3964 26.3833C16.959 26.5465 16.5618 26.8018 16.2317 27.132L14.2782 29.0855C13.6851 29.6995 13.357 30.522 13.3644 31.3756C13.3718 32.2293 13.7142 33.0459 14.3179 33.6496C14.9215 34.2532 15.7381 34.5956 16.5918 34.603C17.4454 34.6105 18.2679 34.2823 18.8819 33.6892L19.9954 32.5757" stroke="#2A2A2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_0_1">
    <rect width="15.6279" height="15.6279" fill="white" transform="translate(12.0254 20.3208)"/>
    </clipPath>
    </defs>
    </svg>
    

    
  )
}

export default linkicon