import { Paper } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Input, InputWithIcon } from "../../../Component/UI/CustomInputs";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import { Eye } from "../../../assets/icons/Eye";
import { password } from "../../../services/enterprise/password";
import { useState } from "react";
import toast, {
  Toaster,
} from "../../../../node_modules/react-hot-toast/dist/index";
import { useEffect } from "react";
import { passwordValidate } from "../../../config/passwordValidate";

export const Password = () => {
  const [passwordObj, setPasswordObj] = useState({});
  const [confirmErr, setConfirmErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [err, setErr] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (passwordObj.new_password !== passwordObj.confirm_password) {
      setConfirmErr("Confirm password is not equal to the new password");
    } else setConfirmErr("");

    const check = passwordValidate(passwordObj.new_password);

    if (check !== true) {
      setPasswordErr(
        "Password should be atleast of 8 characters. Must contain small & CAPITAL letter, Special character (@,$...) & number"
      );
    } else setPasswordErr("");
  }, [passwordObj]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordObj({ ...passwordObj, [name]: value });
  };

  const handleClick = (e) => {
    e.preventDefault();
    setErr({});
    if (passwordErr === "" && confirmErr === "") {
      password(passwordObj).then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.msg);
          setPasswordObj({
            old_password: "",
            new_password: "",
            confirm_password: "",
          });
          setErr({});
        } else setErr(res?.data?.error);
      });
    } else
      setErr({
        ...err,
        new_password: passwordErr,
        confirm_password: confirmErr,
      });
  };

  return (
    <>
      <Toaster />
      <Paper
        elevation={1}
        sx={{
          borderRadius: "15px",
          padding: "20px",
          margin: "10px",
        }}
      >
        <p style={{ fontWeight: "600" }}>Update Password</p>
        <form onSubmit={handleClick}>
          <InputWithIcon
            label="Current Password"
            type="password"
            labelSize={14}
            name="old_password"
            value={passwordObj?.old_password}
            error={err.old_password}
            placeholder="Current Password "
            icon={Eye}
            errorSize="14px"
            onChange={handleChange}
            margin="20px 0px"
          />
          <InputWithIcon
            label="New Password"
            type="password"
            // error={error?.password}
            labelSize={14}
            name="new_password"
            value={passwordObj?.new_password}
            error={err.new_password}
            errorSize="14px"
            placeholder="New Password "
            icon={Eye}
            onChange={handleChange}
            margin="20px 0px"
          />
          <InputWithIcon
            label="Confirm Password"
            type="password"
            // error={error?.password}
            labelSize={14}
            name="confirm_password"
            value={passwordObj?.confirm_password}
            error={err.confirm_password}
            errorSize="14px"
            placeholder="Confirm Password "
            icon={Eye}
            onChange={handleChange}
            margin="20px 0px"
          />

          <div>
            {/* <Btn
            borderRadius="30px"
            bgColor="#ccc"
            color="#000"
            width="100px"
            margin="0 10px"
            // border="2"
            padding="5px 5px"
            click={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Btn> */}
            <Btn
              borderRadius="30px"
              bgColor="#3592FF"
              color="#fff"
              width="100px"
              margin="0 10px"
              border="2"
              padding="5px 5px"
            >
              Submit
            </Btn>
          </div>
        </form>
      </Paper>
    </>
  );
};
