export const Edit = ({ active, size }) => {
  const paths = [
    "M9.16601 3.33398H3.33268C2.89065 3.33398 2.46673 3.50958 2.15417 3.82214C1.84161 4.1347 1.66602 4.55862 1.66602 5.00065V16.6673C1.66602 17.1093 1.84161 17.5333 2.15417 17.8458C2.46673 18.1584 2.89065 18.334 3.33268 18.334H14.9993C15.4414 18.334 15.8653 18.1584 16.1779 17.8458C16.4904 17.5333 16.666 17.1093 16.666 16.6673V10.834",
    "M15.416 2.08417C15.7475 1.75265 16.1972 1.56641 16.666 1.56641C17.1349 1.56641 17.5845 1.75265 17.916 2.08417C18.2475 2.41569 18.4338 2.86533 18.4338 3.33417C18.4338 3.80301 18.2475 4.25265 17.916 4.58417L9.99935 12.5008L6.66602 13.3342L7.49935 10.0008L15.416 2.08417Z",
  ];
  return (
    <svg
      width={size || "20"}
      height={size || "20"}
      viewBox="0 0 20 20"
      fill="none"
      cursor={active ? "not-allowed" : ""}
    >
      {paths.map((d) => (
        <path
          d={d}
          stroke="#2A2A2A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      ))}
    </svg>
  );
};
