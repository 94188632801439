import React, { useState, useEffect } from "react";
import BasicBreadcrumbs from "../../../Component/UI/BreadCrumb/BreadCrumbs";
import { ADMIN_ROUTES } from "../../../Routes/routesConstants";
import axios from "../../../config/axiosConfig";
import { Link } from "react-router-dom";
import { CustomTable } from "../../../Component/UI/Table";
import ServicesData from "../../../MockData/Services.json";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetSearchFilters } from "../../../Redux/Slices/AdminFilters/Search";
import {
  enterpriseServiceListAdmin,
  enterpriseTestimonialListAdmin,
} from "../../../services/admin/enterprise";
import { usePermissions } from "../../../Hooks/usePermissions";
import moment from "../../../../node_modules/moment/moment";
import { Avatar } from "../../../Component/UI/Avatar";
import { useNavigate } from "../../../../node_modules/react-router-dom/index";
import { Rating } from "../SamvednaNew/Testimonial/TabComponents/Rating";
import { Btn } from "../../../Samvenda-Patient/UI/Button";

const EnterpriseTestimonials = () => {
  const [testimonialList, setTestimonialList] = useState([]);
  const { state } = useLocation();
  // const nlcolumns = Object.keys(AssessmentsData[0]);
  const { from_date, to_date } = useSelector((state) => state.SearchFilters);
  const dispatch = useDispatch();
  const permissions = usePermissions("name", "enterprises");
  const navigate = useNavigate();
  const tHead = ["Date", "Name", "Rating", "Description"];
  console.log(state?.data?.id, "iiii");
  useEffect(() => {
    enterpriseTestimonialListAdmin(state?.data?.id)
      .then((res) => setTestimonialList(res.data.list))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetSearchFilters());
    };
  }, []);

  const links = [
    {
      path: ADMIN_ROUTES.ENTERPRISES,
      linkText: "Enterprise",
    },
  ];

  if (state?.data) {
    links.push({
      path: ADMIN_ROUTES.ENTERPRISES_VIEWBOX,
      linkText: state?.data?.company_name,
      data: { ...state?.data, permissions },
    });
  }

  const tBodyData = testimonialList.map((item) => {
    const {
      id,
      thoughts,
      customer,
      created_at,
      rating,
      is_active,
      enterprise,
    } = item;
    const date = moment(created_at).format("DD/MM/YYYY");
    return {
      date,
      name: (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            height: 0,
          }}
        >
          <Avatar
            size="28px"
            profile={customer?.customer_image}
            border="1px solid #ccc"
          />
          <div
            style={{ cursor: "pointer", marginLeft: "8px" }}
            onClick={() => navigate("details", { state: { item } })}
          >
            {customer?.name ? customer?.name : enterprise?.name}
          </div>
        </span>
      ),
      rating: <Rating ratings={rating} />,
      thoughts: `${thoughts?.slice(0, 30)}${
        thoughts?.length > 30 ? "..." : ""
      }`,
    };
  });

  const addTestimonial = () => {
    navigate("/Enterprises/Testimonials/Add", { state: state?.data?.id });
  };

  return (
    <>
      <BasicBreadcrumbs
        link={links}
        text="Testimonials"
        isEnableCalender={false}
        isEnableAddButton={true}
        addTestimonial={addTestimonial}
      >
        <CustomTable
          tHead={tHead}
          tBody={tBodyData}
          message={"No Testimonials is available for this enterprise."}
        />
      </BasicBreadcrumbs>
    </>
  );
};
export default EnterpriseTestimonials;
