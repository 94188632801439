import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import toast from "react-hot-toast";
import axios from "../../../config/axiosConfig";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  height: 260,
  bgcolor: "background.paper",
  outline: "none",
  borderRadius: "20px",
  boxShadow: 12,
  p: 4,
};

export default function RejectPopup({ popupAction, setPopupAction, icon }) {
  const sucessNotify = (msg) => toast.success(msg);
  const errorNotify = (error) => toast.error(error);

  const handleClose = () => setPopupAction({ ...popupAction, open: false });

  const handleReject = async () => {
    /* if (popupAction.reason) { */
    await axios
      .post("/enterprise/leadaction", {
        action: "rejected",
        id: popupAction.id,
        remark: popupAction.reason,
      })
      .then((res) => {
        if (res?.data?.status) {
          sucessNotify(res.data.msg);
          setPopupAction({
            ...popupAction,
            open: false,
            update: !popupAction.update,
          });
        } else errorNotify(res?.data?.error?.remark[0]);
      })
      .catch((err) => errorNotify(err));
    /* } else { */
    /*   errorNotify("Reason can't be empty!"); */
    /* } */
  };

  return (
    <div>
      <Modal
        open={popupAction.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "100%",
              background: "#00B091",
              top: "0px",
              left: "0px",
              position: "absolute",
              borderTopLeftRadius: "19px",
              borderTopRightRadius: "19px",
              height: "40px",
              color: "white",
              fontSize: "18px",
              fontWeight: "600",
              padding: "5px",
              textTransform: "uppercase",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                alignSelf: "center",
                width: "100%",
                textAlign: "center",
              }}
            >
              Reject Lead
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: "auto",
            }}
          >
            {icon}
            <h2>{popupAction.name}</h2>
            <p
              style={{
                fontSize: "17px",
                fontWeight: "500",
                margin: "20px 12px",
                textAlign: "center",
                lineHeight: "20px",
              }}
            >
              Please enter the reason for rejection
            </p>
            <textarea
              style={{
                outline: "none",
                resize: "none",
                width: "-webkit-fill-available",
                height: "100px",
                border: "1px solid #dadada",
                borderRadius: "8px",
                marginBottom: "6px",
              }}
              onChange={(e) => {
                setPopupAction({ ...popupAction, reason: e.target.value });
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "-webkit-fill-available",
                margin: "8px 0",
              }}
            >
              <Btn
                bgColor="#fff"
                color="#000"
                width="100px"
                height="36px"
                variant="contained"
                click={handleClose}
              >
                Cancel
              </Btn>
              <Btn
                bgColor="#00B091"
                variant="contained"
                width="100px"
                height="36px"
                click={handleReject}
              >
                Reject
              </Btn>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
