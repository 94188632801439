import classes from "../../../../assets/css/AdminPatients/profile.module.css";
import {
  Input,
  Select,
  SelectWithId,
} from "../../../../Component/UI/CustomInputs";
import { useDispatch } from "react-redux";
import { addPatientData } from "../../../../Redux/Slices/addPatient";
import { UploadProfile } from "../../../../Component/UI/UploadProfile";
import { DtoY, YtoD } from "../../../../config/formattedDate";

export const PersonalDetails = ({
  err,
  data,
  writePermissions,
  CountryCodes,
}) => {
  const dispatch = useDispatch();
  const rgx = /^(?:[0-9+]+)?$/;
  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(addPatientData({ [name]: value }));
  };
  console.log(writePermissions, "writePermissions");
  return (
    <>
      <div className={classes.ill_history}>
        <div className={classes.flex_div}>
          <div className={classes.general_obv}>
            <div className={classes.size}>
              <Input
                label="Full Name"
                name="name"
                value={data?.name}
                error={err?.name}
                InputVisible="true"
                placeholder="Full Name"
                onChange={handleChange}
                req
                disabled={writePermissions === 0 ? true : false}
              />
            </div>
            <div className={classes.size}>
              <Input
                name="dob"
                label="Date of Birth"
                value={YtoD(data?.dob)}
                error={err?.dob}
                placeholder="DD/MM/YY"
                disabled={writePermissions === 0 ? true : false}
                onChange={(e) =>
                  dispatch(addPatientData({ dob: DtoY(e.target.value) }))
                }
              />
            </div>
          </div>
          <div className={classes.general_obv}>
            <div className={classes.size}>
              <Input
                type="email"
                name="email"
                value={data?.email}
                label="Email"
                error={err?.email}
                placeholder="Email"
                onChange={handleChange}
                disabled={writePermissions === 0 ? true : false}
                req
              />
            </div>

            <div
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
              className={`${classes.size}${classes.phoneSec}`}
            >
              <SelectWithId
                name="countryCode"
                options={CountryCodes}
                width="130px"
                label="Country Code"
                value={data?.countryCode}
                onChange={handleChange}
              />
              <Input
                type="text"
                name="mobile"
                label="Mobile"
                error={err?.mobile}
                value={data?.mobile}
                placeholder="Mobile"
                onChange={(e) => {
                  const isNum = rgx.test(e.target.value);
                  isNum && dispatch(addPatientData({ mobile: e.target.value }));
                }}
                disabled={writePermissions === 0 ? true : false}
              />
            </div>
          </div>
          <div className={classes.general_obv}>
            <div className={classes.size}>
              <Select
                name="marital_status"
                options={[
                  { id: 1, value: "Unmarried" },
                  { id: 2, value: "Married" },
                  { id: 3, value: "Divorced" },
                  { id: 4, value: "Separated" },
                  { id: 5, value: "Widowed" },
                ]}
                label="Marital Status"
                error={err?.marital_status}
                value={data?.marital_status}
                onChange={handleChange}
                disabled={writePermissions === 0 ? true : false}
              />
            </div>
            <div>
              <Select
                name="year_of_education"
                label="Years of Education"
                error={err?.year_of_education}
                value={data?.year_of_education}
                disabled={writePermissions === 0 ? true : false}
                options={[
                  { id: 1, value: "1-4 years" },
                  { id: 2, value: "5-8 years" },
                  { id: 3, value: "9-12 years" },
                  { id: 4, value: "<15 years" },
                  { id: 5, value: "15+ years" },
                ]}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={classes.general_obv}>
            <div className={classes.size}>
              <Select
                name="lives_with"
                options={[
                  { id: 1, value: "Parents" },
                  { id: 2, value: "Friend" },
                ]}
                label="Lives With"
                error={err?.lives_with}
                value={data?.lives_with}
                onChange={handleChange}
                disabled={writePermissions === 0 ? true : false}
              />
            </div>
          </div>
        </div>
        <UploadProfile
          name="avtar"
          disabled={writePermissions === 0 ? true : false}
          profile={data?.customer_image}
          upload={(value) => dispatch(addPatientData({ avtar: value }))}
        />
      </div>
    </>
  );
};
