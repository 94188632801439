import { useDispatch, useSelector } from "react-redux";
import {
  setDate,
  setMonthPicker,
  setToggle,
} from "../../../../Redux/Slices/Patient/PatienCalender";
import { DateNavigation } from "./DateNavigation";

export const Select = ({ styles, months, dateObj, year, month, fullYear }) => {
  const { toggle, fullDate } = useSelector((i) => i.DateFilterSlice);
  const dispatch = useDispatch();
  const curMonthInd = months?.indexOf(month);
  return (
    <div className={styles.select}>
      <DateNavigation
        styles={styles}
        dateObj={dateObj}
        fullYear={fullYear}
        // setYear={setYear}
        // year={year}
      />
      <div className={styles.months}>
        {months.map((month, id) => (
          <p
            className={`${styles.month} ${
              year !== fullYear ? "" : id <= curMonthInd ? "" : styles.disabled
            }`}
            onClick={() => {
              if (curMonthInd >= id || fullYear !== year) {
                dispatch(setToggle(!toggle));
                dispatch(setDate(`${month} ${year}`));

                const selectedMonth = months.findIndex((val) => val === month);
                const d = new Date();
                d.setDate(1);
                d.setMonth(selectedMonth);
                d.setFullYear(year);
                console.log(d, selectedMonth, "dddddddd");
                dispatch(setMonthPicker(d));
              }
            }}
            key={id}
          >
            {month}
          </p>
        ))}
      </div>
    </div>
  );
};
