import { createSlice } from "@reduxjs/toolkit";

const getAssessmentSlice = createSlice({
  name: "get asscat",
  initialState: { data: [] },
  reducers: {
    getAssCat: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { getAssCat } = getAssessmentSlice.actions;

export default getAssessmentSlice.reducer;
