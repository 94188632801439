import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import { IconShadow } from "../../../../Component/UI/IconShadow";

import styles from "../../../../assets/css/PatientDashboard/progresstrend.module.css";
import { ReactComponent as Nutrition } from "../../../../assets/icons/ProgressTrend/Nutrition.svg";

export default function BottomCard({ text, days, icon, color }) {
  return (
    <Card className={styles.cardcontainer} sx={{ userSelect: "none" }}>
      <Box className={styles.boxcontainer}>
        <div className={styles.iconShadow}>
          {/* <IconShadow size="48px" color={color} icon={icon} /> */}
          <div
            className={styles.imagebottom}
            style={{
              background: color,
            }}
          >
            {icon && (
              <img
                src={icon}
                alt="icon"
                style={{ width: "30px", height: "30px" }}
              />
            )}
          </div>
        </div>

        <CardContent>
          <Typography
            variant="caption"
            color="text.secondary"
            component="div"
            sx={{ fontSize: "13px" }}
          >
            {text}
          </Typography>
          <Typography component="div" variant="h5">
            {days}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
}

BottomCard.defaultProps = {
  days: "--",
  color: "#FF797833",
};
