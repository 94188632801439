import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

import { encryption } from "../../config/encryptDecrypt";

const {
  ASSESSMENTCATEGORYDETAIL,
  SEND_ASSESSMENT_LINK,
  REQUEST_ASSESSMENT,
  REQUEST_ASSESSMENT_CHANGE,
} = API;

export const assessmentGetEnterprise = async () => {
  return await axios
    .get(ASSESSMENTCATEGORYDETAIL)
    .then((res) => res.data.list)
    .catch((err) => console.log(err));
};

export const sendEnterpriseLink = async (data) => {
  // const encryptedAssessmentId = encodeURIComponent(
  //   encryption(`${data?.assissment_id}`)
  // );
  const encryptedAssessmentId = window.btoa(
    encryption(`${data?.assissment_id}`)
  );

  const encryptedLink = `${data.link}/${encryptedAssessmentId}`;

  return await axios
    .post(SEND_ASSESSMENT_LINK, {
      assissment_id: data.assissment_id,
      link: encryptedLink,
    })
    .then((res) => res)
    .catch((err) => err);
};

export const requestEnterpriseAssessment = async (data) => {
  return await axios
    .post(REQUEST_ASSESSMENT, data)
    .then((res) => res)
    .catch((err) => err);
};

export const requestAssessmentChange = async (data) => {
  return await axios
    .post(REQUEST_ASSESSMENT_CHANGE, data)
    .then((res) => res)
    .catch((err) => err);
};
