import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import cross from "../../../../src/assets/icon/cross.svg"
import Popup from "./AssesmentCopyAsstPopup";
import AssesmentCopyIcon from '../../../assets/icons/AssesmentCopy';
import { useEffect } from 'react';



// import MaterialButton from '../UI/MaterialButton/MaterialButton';

const AssementPopup = ({
  title,
  description,
  onClose,
  handleSubmit,
  open,
  children,
  confirmSubmit,
  modalOpen,
  isCopied,
  setIsCopied,
  loading
}) => {

  const [popupAction, setPopupAction] = React.useState({
    open: false,
    name: "",
  });
  const [popUpopen, setPopUpopen] = React.useState({
    open: false,
    name: "",
  });

  useEffect(() => {
    if (isCopied) {
      setIsCopied(false)
      setPopUpopen({
        ...popUpopen,
        open: true,
      })
    }
    else {
 
      setPopupAction({
        ...popupAction,
        open: false,
      })

    }

  }, [isCopied])

  const handleClick = () => {
    // console.log("hello world ")
    setPopupAction({
      ...popupAction,
      open: true,
    })
  }

  return (
    <div >
      <Dialog
        open={open}
        keepMounted
        fullWidth={true}
        maxWidth={"xs"}
        onClose={onClose}
        aria-describedby='alert-dialog-slide-description'
        sx={{ textAlign: 'center' }}
        PaperProps={{
          style: { borderRadius: "20px", overflow: 'inherit', width: "350px" }
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",

            marginTop: "10px"
          }}>
          <DialogTitle
            sx={{
              fontSize: '24px',
              fontWeight: 500,
              color: 'primary'
            }}
          >
            {title}

          </DialogTitle>
          <img
            src={cross}
            alt="cross"
            style={{
              width: "30px",
              justifyContent: "space-between",
              marginRight: "22px",
              cursor: "pointer"
            }} onClick={onClose} />
        </div >
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            {children}
          </DialogContentText>
          <div
            style={{
              display: 'flex',
              gap: '20px',
              justifyContent: 'flex-end',
              margin: "45px 0px 0px 0px"
            }}
          >
            <Button
              onClick={onClose}
              variant='contained' style={{
                borderRadius: 30,
                backgroundColor: "black",
                padding: "6px 18px",
              }} > Cancel </Button>
            <Button
              variant='contained'
              style={{
                borderRadius: 30,
                backgroundColor: "#00B091",
                padding: "6px 18px",
              }}
              onClick={handleClick}
            >         
            Create </Button>
          </div>
        </DialogContent>

      </Dialog>
      <Popup
        popupAction={popupAction}
        setPopupAction={setPopupAction}
        title="Do you want to copy this assessment ?"
        icon={<AssesmentCopyIcon />}

        click={() => {
          confirmSubmit()
          if (isCopied) {
            setPopUpopen({
              ...popUpopen,
              open: true,
            })
          }
          else {
            setPopupAction({
              ...popupAction,
              open: false,
            })

          }
        }}

      />
      <Popup
        popupAction={popUpopen}
        setPopupAction={setPopUpopen}
        title="Your assesments copied successfully ! "
        icon={<AssesmentCopyIcon />}
        showCancel={false}
        successText={"Close"}
        click={() => {
          setPopUpopen({ ...popUpopen, open: false })
          setPopupAction({
            ...popupAction,
            open: false,
          })
          modalOpen(false)
        }}
      />

    </div>
  );
};

export default AssementPopup;