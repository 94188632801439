import React from "react";
import styles from "./header.module.css";
import { LogoNew } from "../Components/Images/LogoNew";
import headerImage from "../Components/Images/Mask group.png";
import { Form } from "./Form";
import { ResponsiveSidebar } from "../Components/UI/ResponsiveSidebar";
import { ResponsiveLogo } from "../Components/Images/ResponsiveLogo";
import { Link } from "react-router-dom";

export const Header = ({ setPopup }) => {
  return (
    <>
      <div className={styles.responsiveHeader}>
        <div className={styles.hamburger}>
          <ResponsiveSidebar />
          <ResponsiveLogo />
        </div>
        <p className={styles.responsiveHeaderTitle}>
          Create a happier, livelier and lighter work place !
        </p>
        <img src={headerImage} alt="" />
        <div className={styles.form}>
          <Form />
        </div>
      </div>
      <div className={styles.header}>
        <div className={styles.logo}>
          <a
            href="https://www.samvednacare.com/"
            target="_blank"
            rel="noreferrer"
          >
            <LogoNew />
          </a>
          <div className={styles.links}>
            <ul>
              <li>
                <a href="#aboutLink">About</a>
              </li>
              <li>
                <a href="#assessmentsLink">Assessments</a>
              </li>
              <li>
                <a href="#servicesLink">Services</a>
              </li>
              <li>
                <a
                  href="https://www.samvednacare.com/blog/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Blog
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div
          className={styles.call}
          onClick={() => window.open("tel:+91-74280-97301", "_self")}
        >
          <a className={styles.call_link} href="tel:+91-74280-97301">
            Call : +91-74280-97301
          </a>
        </div>
      </div>
      <div className={styles.content}>
        <h2>Create a happier, livelier and lighter work place !</h2>
        <Form setPopup={setPopup} />
      </div>
    </>
  );
};
