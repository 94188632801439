import React, { useState, useEffect } from "react";
import { RiDeleteBinLine } from "react-icons/ri";

import style from "../../../assets/css/GeneralAddQuestion.module.css";
import Button from "@mui/material/Button";
import toast, { Toaster } from "react-hot-toast";
import { Input } from "../../UI/Input";
import BasicBreadcrumbs from "../../../Component/UI/BreadCrumb/BreadCrumbs";
import { useSelector } from "react-redux";
import { convertToBase64 } from "./AfterClickingAddButton/ConvertToBase64";
import { useLocation, useNavigate } from "react-router-dom";
import { API } from "../../../config/constant";
import { SelectType } from "../../../Component/UI/CustomInputs";
import AlertMessage from "../../../Component/UI/AlertMessage";
import instance from "../../../config/axiosConfig";
import { TEXT } from "../../contant/constant";
import FileUpload from "../../UI/FileUpload/FileUpload";

const option = ["Toggle"];

const TodayActivityAddQuestion = () => {
  const { responseaddQuestion } = useSelector(
    (state) => state.addQuestionSlice
  );

  const navigate = useNavigate();
  const location = useLocation();
  let { cid, id } = location?.state;

  const link = [
    { path: "/Assessments", linkText: "Assessments" },
    { path: "/Assessments", linkText: "Today's Activity" },
    {
      path: "/todayactivityquestion",
      linkText: "General",
      cid: cid,
    },
  ];

  const [questionValue, setQuestionValue] = useState("");
  const [inputFields, setInputFields] = useState([
    { option: "", id: 0, image: "", existing: false },
  ]);
  const [error, setError] = useState("");
  const [respData, setRespData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [selected, setSelected] = useState("Select");
  const [questImage, setQuestImage] = useState();
  const [quesImagePath, setQuesImagePath] = useState();
  const [avatar, setAvatar] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageName, setImageName] = useState("");

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    addDatato();
  }, [flag]);

  const getData = () => {
    const myRevOptions = {
      toggle: "Toggle",
      Single: "radio",
    };

    try {
      instance
        .get(`${API.TODAYACTIVITYADDQUESTION}?question_id=${id}`)
        .then((res) => {
          res.data.status === true && setRespData(res.data.list);
          setSelected(myRevOptions[res.data.list?.input_type]);
          setQuestionValue(res.data.list?.question);
          setFlag(!flag);
          setImageUrl(res.data.list.question_image);
          setImageName(res.data.list.avtar);
        });
    } catch (error) {
      console.log(error, "error");
    }
  };

  //Image Upload
  const ImageUploadHandler = async (e, imageid) => {
    e.preventDefault();
    const imageData = e.target.files[0];

    const base64 = await convertToBase64(imageData);
    const imgName = e.target.files[0].name;

    let data = [...inputFields];

    data[imageid]["image"] = base64;
    data[imageid]["imageName"] = imgName;
    setInputFields(data);
  };

  const selecedHandleChange = (e) => {
    setSelected(e.target.value);
  };

  const QustionInputHandler = (e) => {
    e.preventDefault();
    setError(false);
    setQuestionValue(e.target.value);
  };

  const addDatato = () => {
    respData.options?.length >= 0
      ? setInputFields(
          respData.options?.map((el, index) => {
            return {
              option: el.option,
              id: el.id,
              image: el.image,
              existing: true,
            };
          })
        )
      : addBtn();
  };

  const addBtn = () => {
    let arrLength = inputFields?.length;
    let finalId = arrLength > 0 ? inputFields[arrLength - 1]?.id : -1;

    let newField = {
      option: "",
      id: ++finalId,
      image: "",
      existing: false,
    };
    setInputFields([...inputFields, newField]);
  };

  const QuesImageUploadHandler = async (e) => {
    const acceptOnlyImage = e.target.accept.split(",").filter((result) => {
      return result === e.target.files[0].type;
    });
    acceptOnlyImage[0] === e.target.files[0].type
      ? setImage(e)
      : errorNotify("Please select image");
  };

  const setImage = async (e) => {
    setQuestImage(e.target.files[0]);
    const base64 = await convertToBase64(e.target.files[0]);
    setQuesImagePath(base64);
  };

  //Add Fields
  const AddInputhandleChange = (e, id) => {
    e.preventDefault();
    setError(false);
    let data = [...inputFields];
    data[id][e.target.name] = e.target.value;

    setInputFields(data);
  };

  const sucessNotify = (msg) => toast.success(msg);

  const errorNotify = (error) => toast.error(error);

  //SaveButton
  const SaveHandler = () => {
    console.log("questImage", questImage);
    const myOptions = {
      Toggle: "toggle",
    };

    let form_data = new FormData();
    form_data.append("category_id", cid);
    form_data.append("input_type", myOptions["Toggle"]);
    form_data.append("question", questionValue);
    form_data.append("question_image", questImage);
    form_data.append("question_id", id);
    form_data.append("avtar", avatar);
    form_data.append("options", JSON.stringify(inputFields));
    try {
      instance.post(API.POSTADDQUESTIONTODAYACTIVITY, form_data).then((res) => {
        if (res.data.status) {
          sucessNotify(res.data.msg);
          navigate("/todayactivityquestion", {
            state: { id: id, cid: cid },
          });
        } else {
          setError(res.data.error?.question[0]);
          errorNotify(res.data.error?.question[0]);
        }
      });
    } catch (error) {
      console.log(error, "error");
    }
  };

  //remove btn
  const removeApi = (id) => {
    let removepayload = {
      option_id: inputFields[id].id,
    };

    try {
      instance.post(API.DELETETODAYACTIVITY, removepayload).then((res) => {
        res.data.status === true && getData();
      });
    } catch (error) {
      console.log(error, "error");
    }
  };

  const removeLocal = (id) => {
    if (
      window.confirm(
        `Are you sure you want to delete this ${inputFields[id].option}? Please note that this cannot be undo & will be saved immediately.`
      ) === true
    ) {
      let newdata = [...inputFields];
      newdata.splice(id, 1);
      setInputFields(newdata);
    } else return null;
  };

  const removeBtn = (e, id) => {
    let existing = inputFields[id]?.existing;

    existing
      ? window.confirm(
          ` Are you sure you want to delete this ${inputFields[id].option}? Please note that this cannot be undo & will be saved immediately.`
        ) === true && removeApi(id)
      : removeLocal(id);
  };

  const CancelHandler = () => {
    navigate("/todayactivityquestion", {
      state: { id: id, cid: cid },
    });
  };

  const addQuestionImage = (e) => {
    const f = e.target.files[0];
    setAvatar(f);
    setImageUrl(URL.createObjectURL(f));
  };

  return (
    <>
      <BasicBreadcrumbs link={link} text={"Add Question"}>
        {/* <PaperWrapper> */}
        <div className={style.paperContainercss}>
          <div className={style.mainQuestions}>
            <div className={style.quesOne}>
              <SelectType
                option={option}
                value={selected}
                label="Type of Question"
                disabled={true}
                onChange={(e) => selecedHandleChange(e)}
              />
            </div>
            <div>
              <Input
                label="Question"
                type={"text"}
                name={"question"}
                onChange={(e) => QustionInputHandler(e)}
                defaultValue={questionValue}
              />
              {error && <p style={{ color: "red" }}>{error}</p>}
            </div>
            <div div className={style.choosefile}>
              <p>Question Image</p>
              <FileUpload onChange={addQuestionImage} fileName={avatar?.name} />
            </div>
            <div className={style.quesimg}>
              {quesImagePath && (
                <img
                  src={quesImagePath}
                  alt=""
                  style={{ width: "50px", heigth: "50px" }}
                />
              )}
              {imageUrl ? (
                <>
                  <img
                    src={imageUrl}
                    alt=""
                    style={{ width: "50px", heigth: "50px" }}
                  />
                  <span>{imageName}</span>{" "}
                </>
              ) : null}
              <span id="file-chosen">{questImage?.name}</span>
              <RiDeleteBinLine
                style={{
                  width: "25px",
                  height: "25px",
                  cursor: "pointer",
                  marginRight: "15px",
                  color: "green",
                }}
                onClick={() => {
                  setAvatar("");
                  setImageUrl("");
                  setImageName("");
                }}
              />
            </div>
          </div>
          <div className={style.addQuestion}>
            <h2 className={style.h2text}>Add Choices</h2>
          </div>
          {/* Option */}
          {inputFields?.map((el, index) => {
            return (
              <div className={style.optFields} key={el.id}>
                <div>
                  <Input
                    label={`Option ${index + 1}`}
                    // key={el.id}
                    name="option"
                    type="text"
                    placeholder={"Please Enter the Option"}
                    //value={el.option}
                    defaultValue={el.option}
                    onChange={(e) => AddInputhandleChange(e, index)}
                  />
                </div>
                {selected === TEXT.STOGGLE && (
                  <>
                    <div
                      style={{
                        display: "inline-flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "24px",
                      }}
                    >
                      <div>
                        <input
                          type="file"
                          id={`actual-btn${index}`}
                          name="image"
                          accept="image/x-png,image/jpeg,image/svg,image/gif,image/jpg,image/tiff"
                          //defaultValue={el.image||""}
                          hidden
                          onChange={(e) => ImageUploadHandler(e, index)}
                        />
                        <label
                          htmlFor={`actual-btn${index}`}
                          className="uploadLabel"
                        >
                          Choose File
                        </label>
                      </div>
                      <div
                        style={{
                          width: 150,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <span id="file-chosen">{el.imageName}</span>
                        {el.image !== "" &&
                          el.image !== null &&
                          el.image !== undefined && (
                            <img
                              src={el.image}
                              alt="question Images"
                              style={{ width: "25px", height: "25px" }}
                            />
                          )}
                      </div>
                    </div>

                    <RiDeleteBinLine
                      style={{
                        fontSize: 25,
                        color: "#4caf50",
                        marginTop: "31px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => removeBtn(e, index)}
                    />
                  </>
                )}
              </div>
            );
          })}
          <div className="formButton" style={{ marginTop: 20 }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#2A2A2A",
                borderRadius: 50,
                marginRight: 10,
              }}
              onClick={CancelHandler}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: "#00B091", borderRadius: 50 }}
              onClick={SaveHandler}
            >
              Save
            </Button>
          </div>
        </div>
        {responseaddQuestion.status && (
          <AlertMessage Msg={responseaddQuestion?.msg} />
        )}
        {/* </PaperWrapper> */}
      </BasicBreadcrumbs>
      <Toaster />
    </>
  );
};

export default TodayActivityAddQuestion;
