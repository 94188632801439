import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Classes from "../../../../assets/css/assessment/toggle.module.css";
import {
  selOption,
  selScore,
} from "../../../../Redux/Reducers/PatientReducers/qa_storage/qa_storage";

export const Toggle = (props) => {
  const { question, options } = props;
  const dispatch = useDispatch();
  const { selectedOption } = useSelector((state) => state.qa_storage);

  const [toggle, setToggle] = useState(false);
  const [selectedToggleValue, setSelectedToggleValue] = useState(options[1]);

  const handleChange = (e) => {
    setToggle(!toggle);
    assessmentData();
  };

  useEffect(() => {
    toggle
      ? setSelectedToggleValue(options[1])
      : setSelectedToggleValue(options[0]);
  }, [toggle]);

  // useEffect(() => {
  //   selectedOption === options[1] ? setToggle(false) : setToggle(true);
  // }, [selectedOption]);

  const assessmentData = () => {
    let indx = options.indexOf(selectedToggleValue);
    const score = question.scores.split(",")[indx];
    dispatch(selOption(selectedToggleValue));
    dispatch(selScore(score));
  };

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            width: "250px",
          }}
        >
          <span
            style={
              toggle
                ? { padding: "25px 0", fontSize: "25px" }
                : { color: "#7a51ae", padding: "25px 0", fontSize: "25px" }
            }
          >
            {options[1]}
          </span>
          <label className={Classes.switch} style={{ border: "none" }}>
            <input
              type="checkbox"
              value={toggle}
              onChange={(e) => handleChange()}
            />
            <span className={`${Classes.slider} ${Classes.round}`}></span>
          </label>
          <span
            style={
              toggle
                ? { padding: "25px 0", fontSize: "25px", color: "#7a51ae" }
                : { padding: "25px 0", fontSize: "25px" }
            }
          >
            {options[0]}
          </span>
        </div>
      </div>
    </>
  );
};
