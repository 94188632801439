import React, { useState } from "react";
import SimpleBreadcrum from "../../../Component/UI/SimpleBreadcrum";
import BasicTabs from "../../UI/Tab/Tabs";
import styles from "./form.module.css";
import {
  useLocation,
  useNavigate,
} from "../../../../node_modules/react-router-dom/index";
import ViewSubscriptionServices from "./TabComponents/ViewSubscriptionServices";
import SummaryData from "./TabComponents/SummaryData";
import moment from "../../../../node_modules/moment/moment";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import { useSelector } from "react-redux";
import { findFunc } from "../../../config/methods";
import { usePermissions } from "../../../Hooks/usePermissions";

export const ViewSubscription = () => {
  const location = useLocation();
  const customerId = location?.state?.customerId;
  const customerName = location?.state?.customerName;
  const state = customerId ? location?.state?.item : location?.state;
  const navigate = useNavigate();
  const isExpired = moment(state?.expiry_at).isBefore(new Date(), "day");
  const is_extended = state?.is_extended;
  const permissions = usePermissions("name", "patients");
  console.log(state, "state---");
  const subPermissions = state?.permissions;
  const fullpermissions =
    +subPermissions?.listControl?.access?.["Full Control"];
  const writePermissions = +subPermissions?.listControl?.access?.Write;
  console.log(subPermissions, "subPermissions---");
  const links = !customerId
    ? [
        {
          path: "/subscriptions",
          linkText: "Subscriptions",
        },
      ]
    : [
        {
          path: "/patientsProfile",
          linkText: customerName,
          data: {
            name: customerName,
            props: customerId,
          },
        },
        {
          path: "/patientsProfile/subscriptions",
          linkText: "Subscriptions",
          data: { permissions, name: customerName, props: customerId },
        },
      ];

  const [count, setCount] = useState(0);

  const convertDateTime = (t) =>
    t ? moment(new Date(t)).format("DD/MM/YYYY, hh:mmA") : "--";

  const oneMonth = 30;
  const convertValidity = (days) =>
    days >= oneMonth
      ? days / oneMonth > 1
        ? days / oneMonth + " Months"
        : days / oneMonth + " Month"
      : days + " Days";

  let nf = new Intl.NumberFormat("en-US");

  const discountType = (flat, perct, na) =>
    state?.discount_type === "1"
      ? flat
      : state?.discount_type === "2"
      ? perct
      : na;

  const subscriptionDetails = [
    {
      name: "Name",
      value: state?.name,
    },
    {
      name: "User Type",
      value: state?.subscription_type == "0" ? "General" : "Enterprise",
    },

    {
      name: `${state?.subscription_type == "0" ? "Patient" : "Enterprise"}`,
      value:
        state?.subscription_type == "0"
          ? state?.customer_name
          : state?.enterprise_name,
    },
    {
      name: "Business Segment",
      value: state?.business_name,
    },
    {
      name: "Validity",
      value: convertValidity(state?.validity),
    },
    {
      name: "Date of Creation",
      value: convertDateTime(state?.created_at),
    },
    {
      name: "Date of Start",
      value: convertDateTime(state?.approved_at),
    },
    {
      name: "Date of Expiry",
      value: convertDateTime(state?.expiry_at),
    },
  ];

  const subscriptionPricing = [
    {
      name: "Total Service",
      value: state?.total_service,
    },
    {
      name: "Sub Total",
      value: `₹${nf.format(state?.sum_price)}`,
    },
    {
      name: "Discount",
      value: discountType("Flat", "Percentage", "N/A"),
    },
    {
      name: `Discount ( ${discountType("₹", "%", "N/A")})`,
      value: `${discountType(
        "₹" + nf.format(state?.discount_percentage),
        state?.discount_percentage + "%",
        "N/A"
      )}`,
    },
    {
      name: "Net Discount",
      value: `₹${nf.format(state?.net_discount)}`,
    },
    {
      name: "Tax",
      value: `₹${nf.format(state?.tax_amount)}`,
    },
    {
      name: "Net payable Amount",
      value: `₹${nf.format(state?.net_price)}`,
    },
    {
      name: "Payment Mode",
      value: state?.payment_mode ? state?.payment_mode : "",
    },
  ];

  const extendedSubscription = [
    {
      name: "Extended Validity",
      value: `${state?.extended_validity} ${
        state?.extended_validity > 1 ? "Days" : "Day"
      } `,
    },
    {
      name: "Additional Amount",
      value: `₹${nf.format(state?.additional_amount)}`,
    },
  ];

  const tabData = [
    {
      label: "Subscription Details",
      component: <SummaryData data={subscriptionDetails} />,
    },
    {
      label: "Services",
      component: <ViewSubscriptionServices data={state?.subscriptionservice} />,
      //error: isErrorService,
    },
    {
      label: "Pricing",
      component: (
        <SummaryData
          data={subscriptionPricing}
          moreData={extendedSubscription}
          isMoreData={state?.extended_validity}
        />
      ),
    },
  ];
  return (
    <div>
      <SimpleBreadcrum text="Subscription Details" link={links} />
      <div className={styles.formContainer}>
        <form className={styles.form} style={{ padding: "8px 0" }}>
          <div style={{ width: "90%" }}>
            <BasicTabs tabcontent={tabData} value={count} setValue={setCount} />
          </div>
        </form>
        {!customerId &&
          (writePermissions ? (
            <Btn
              disabled={isExpired || is_extended === "1"}
              variant="contained"
              borderRadius="100px"
              bgColor="#00B091"
              width="60px"
              margin="0 0 0 4px"
              click={(e) => {
                navigate("/Subscriptions/edit", {
                  state: { edit: true, id: state?.id },
                });
              }}
            >
              Edit
            </Btn>
          ) : null)}
      </div>
    </div>
  );
};
