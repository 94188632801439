import React from "react";

const BackwardBtn = ({ click }) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        onClick={click}
      >
        <g>
          <path
            d="M15 6L9 12L15 18"
            stroke="#2A2A2A"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </>
  );
};

export default BackwardBtn;
