import React from "react";
import OtpInput from "react-otp-input";
import classes from "../../../assets/css/Enterprise/Login/enterpriselogin.module.css";

function VerifyOTP({ value, onChange }) {
  return (
    <div className={classes.otpBox}>
      <OtpInput
        value={value}
        onChange={onChange}
        numInputs={4}
        separator={<span>-</span>}
        inputStyle={{
          width: "90%",
          height: "150%",
          color: "rgb(0,82,156)",
          fontWeight: "bold",
        }}
        isInputSecure={false}
        isInputNum={true}
        shouldAutoFocus={true}
      />
    </div>
  );
}

export default VerifyOTP;
