import React from "react";

export const TotalAssessment = ({ color, height, width }) => {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.667 2.66602H12.0003C12.3539 2.66602 12.6931 2.80649 12.9431 3.05654C13.1932 3.30659 13.3337 3.64573 13.3337 3.99935V13.3327C13.3337 13.6863 13.1932 14.0254 12.9431 14.2755C12.6931 14.5255 12.3539 14.666 12.0003 14.666H4.00033C3.6467 14.666 3.30756 14.5255 3.05752 14.2755C2.80747 14.0254 2.66699 13.6863 2.66699 13.3327V3.99935C2.66699 3.64573 2.80747 3.30659 3.05752 3.05654C3.30756 2.80649 3.6467 2.66602 4.00033 2.66602H5.33366"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.99967 1.33398H5.99967C5.63148 1.33398 5.33301 1.63246 5.33301 2.00065V3.33398C5.33301 3.70217 5.63148 4.00065 5.99967 4.00065H9.99967C10.3679 4.00065 10.6663 3.70217 10.6663 3.33398V2.00065C10.6663 1.63246 10.3679 1.33398 9.99967 1.33398Z"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.22266 7.40961C6.36187 7.01385 6.63666 6.68014 6.99835 6.46758C7.36003 6.25501 7.78528 6.17731 8.19877 6.24823C8.61226 6.31915 8.9873 6.53413 9.25748 6.85508C9.52765 7.17602 9.67552 7.58223 9.6749 8.00176C9.6749 9.18606 7.89844 9.77821 7.89844 9.77821"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.91992 11.5605H7.92881"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};
