import React from "react";
import { Paper } from "@mui/material/index";
import BasicTab from "../../../Components/UI/BasicTabs/BasicTab";
import { Details } from "./Details";
import { Questions } from "./Question";

export const AssessmentDetails = () => {
  const ComponentName = [<Details />, <Questions />];
  const btnApi = [
    {
      details: "Details",
      questions: "Questions",
    },
  ];
  return (
    <>
      <Paper
        elevation={1}
        sx={{
          borderRadius: "15px",
          padding: "20px",
          margin: "10px",
        }}
      >
        <BasicTab ComponentName={ComponentName} btnApi={btnApi} />
      </Paper>
    </>
  );
};
