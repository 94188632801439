import React from "react";
import { Btn } from "../../../../Samvenda-Patient/UI/Button";
import styles from "./expertcard.module.css";
import BtnImg from "../Images/arrowColor.svg";
import defaultImg from "../Images/default.png";

export const ExpertCard = ({
  staff_image,
  name,
  qualification,
  openPopup,
  item,
}) => {
  return (
    <div className={styles.expertCard}>
      <div className={styles.card}>
        <div className={styles.frame}>
          <img
            src={item?.staff_image || defaultImg}
            alt={"name"}
            width="100%"
          />
        </div>
        <div className={styles.details}>
          <h3>{item?.name}</h3>
          <h4>{item?.qualification}</h4>
        </div>
      </div>
      <Btn
        className={styles.arrowBtn}
        boxShadow="none"
        variant="contained"
        bgColor="#ffffff"
        width="60px"
        height="60px"
        color="#ffffff"
        whiteSpace="nowrap"
        borderRadius="8px"
        click={() => openPopup(item)}
      >
        <img src={BtnImg} alt="btnImg" />
      </Btn>

      {/* <Btn click={onClick}>Details</Btn> */}
    </div>
  );
};
