import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  toast,
  Toaster,
} from "../../../../../node_modules/react-hot-toast/dist/index";
import { Edit } from "../../../../assets/icons/Edit";
import { Avatar } from "../../../../Component/UI/Avatar";
import SwitchToggle from "../../../../Component/UI/Switch";
import SwitchButton from "../../../../Component/UI/SwitchButton/Switch";
import { CustomTable } from "../../../../Component/UI/Table";
import { formatttedDate } from "../../../../config/formattedDate";
import Text_Seach_Filter_Export from "../../../../Hooks/Text_Seach_Filter_Export";
import {
  setFilter,
  setFrom_date,
  setSearch_key,
  setTo_date,
} from "../../../../Redux/Slices/AdminFilters/Search";
import { staffStatus, stafListAdmin } from "../../../../services/admin/staff";
import { Badges } from "../../Patients/SelfAssessment/Badges";
import { usePermissions } from "../../../../Hooks/usePermissions";
import SimpleBreadcrum from "../../../../Component/UI/SimpleBreadcrum";
import { findFunc } from "../../../../config/methods";

export const StaffManagement = () => {
  const permissions = usePermissions("name", "samvedna");
  const staffMgtPermissions = findFunc(
    permissions?.subCategories,
    "listName",
    "Staff Management"
  );

  console.log(staffMgtPermissions, "staffMgtPermissions---");
  const fullpermissions = +staffMgtPermissions?.access?.["Full Control"];
  const writePermissions = +staffMgtPermissions?.access?.Write;
  const [staffList, setStaffList] = useState([]);
  const dispatch = useDispatch();
  const { search_key, from_date, to_date, filter } = useSelector(
    (i) => i.SearchFilters
  );

  const tableHeadings = [
    "Name",
    "Role",
    "Email",
    "Mobile",
    "Joining Date",
    ...(fullpermissions ? ["Status"] : []),
    ...(writePermissions ? ["Action"] : []),
  ];

  const getStaffList = () => {
    stafListAdmin(search_key, from_date, to_date)
      .then((res) => setStaffList(res.data.list))
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getStaffList();
  }, [filter]);

  useEffect(() => {
    return () => {
      dispatch(setSearch_key(""));
      dispatch(setTo_date(""));
      dispatch(setFrom_date(""));
      dispatch(setFilter());
    };
  }, []);

  // const links = [
  //   {
  //     path: "/Samvedna",
  //     linkText: "Samvedna",
  //   },

  // ];

  const exportData = staffList?.map(
    ({ name, role_name, email, mobile, joining_date, status }) => {
      return {
        Name: name,
        Email: email,
        Mobile: mobile,
        Role: role_name.map((i) => i.name).join(", "),
        "Joining Date": joining_date ? formatttedDate(joining_date) : "N/A",
        Status: status?.toString() === "0" ? "Inactive" : "Active",
      };
    }
  );

  const tBodyData = staffList?.map((item) => {
    const {
      name,
      role,
      role_name,
      id,
      email,
      mobile,
      joining_date,
      status,
      staff_image,
    } = item;

    const rolesInitial = (r) =>
      r?.map((name) =>
        typeof name !== "number"
          ? name
              ?.split(" ")
              ?.map((i) => i?.slice(0, 1))
              ?.join("")
          : `+ ${role_name.length - 1}`
      );

    return {
      name: (
        <>
          <Link
            to="/Samvedna/staff/details"
            style={{
              color: "#000",
              textDecoration: "none",
              display: "flex",
              pointerEvents: "auto",
            }}
            state={{ item, writePermissions }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                height: 0,
              }}
            >
              {
                <Avatar
                  size="28px"
                  profile={staff_image}
                  border="1px solid #ccc"
                />
              }
              <span style={{ marginLeft: "8px" }}>{name}</span>
            </span>
          </Link>
        </>
      ),
      role: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {rolesInitial(
            role_name.length < 2
              ? role_name.map((i) => i?.name)
              : [role_name.map((i) => i?.name)[0], role_name.length]
          ).map((name) => (
            <Badges text={name} margin="0px 2px" padding="2px 12px" />
          ))}
        </div>
      ),
      email: email,
      mobile: mobile,
      joiningDate: joining_date ? formatttedDate(joining_date) : "N/A",
      ...(fullpermissions && {
        status: (
          <SwitchButton
            status={status}
            onClick={(value) =>
              staffStatus(id, value).then((res) => {
                if (res?.data?.status) {
                  toast.success(res.data.msg);
                  getStaffList();
                }
              })
            }
          />
        ),
      }),
      ...(writePermissions && {
        action: (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <Link to="edit" style={{ color: "#000" }} state={item}>
              <Edit />
            </Link>
          </div>
        ),
      }),
    };
  });

  return (
    <>
      <Toaster />
      {/* <SimpleBreadcrum link={links} text="General" noHeading /> */}
      <Text_Seach_Filter_Export
        permissions={staffMgtPermissions}
        text="Staff"
        route="add"
        ApiData={exportData}
      >
        <CustomTable tHead={tableHeadings} tBody={tBodyData} />
      </Text_Seach_Filter_Export>
    </>
  );
};
