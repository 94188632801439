import React, { useEffect, useState } from "react";
import styles from "./model.module.css";
import { RiCloseLine } from "react-icons/ri";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import Success from "../../../assets/images/success.svg";
import { Input } from "../../../Component/UI/CustomInputs";
import copy from "copy-to-clipboard";
import { toast } from "../../../../node_modules/react-hot-toast/dist/index";
import CopyUrl from "../../../assets/images/copyURL.svg";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";

const Modal = ({
  setJoinPopup,
  link,
  textHeading,
  label,
  reSendPayment,
  isLoading,
  successMsg,
}) => {
  const [text, setText] = React.useState(link);

  const copyToClipboard = () => {
    copy(text);
    toast.success("Text copied");
  };

  const labeltext =
    text?.includes("http://") || text?.includes("https://")
      ? "Appointment Link"
      : "Address";
  return (
    <>
      <div
        className={styles.darkBG}
        onClick={() =>
          setJoinPopup((prev) => {
            return { ...prev, open: false };
          })
        }
      />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.iconimg}>
            <img src={Success} alt="" />
          </div>
          <div className={styles.modalContent}>
            {successMsg ? successMsg : textHeading}
          </div>
          {successMsg ? (
            ""
          ) : (
            <div
              style={{
                color: "#000000",
                position: "relative",
                marginTop: "15px",
              }}
            >
              <Input
                height={"40px"}
                label={label}
                placeholder={text}
                defaultValue={text}
                disabled
              />
              <button
                style={{
                  position: "absolute",
                  top: "26px",
                  right: "8px",
                  //background: '#fff',
                  border: "0px",
                  cursor: "pointer",
                  color: "#00B091",
                  height: "41px",
                  width: "70px",
                }}
                onClick={copyToClipboard}
                disabled={!text}
              >
                <img src={CopyUrl} alt="copy" /> Copy
              </button>
              {/* {link} */}
            </div>
          )}

          {isLoading && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress size={30} />
              <p style={{ color: "black" }}>
                Please wait ! We are validating the details...
              </p>
            </div>
          )}
          <div className={styles.btnGroup}>
            <Btn
              variant="contained"
              bgColor="#000"
              color="#fff"
              width="120px"
              height="40px"
              whiteSpace="nowrap"
              borderRadius="100px"
              margin="15px"
              click={() =>
                setJoinPopup((prev) => {
                  return { ...prev, open: false, successMsg: "" };
                })
              }
            >
              {successMsg ? "Ok" : "Cancel"}
            </Btn>
            {successMsg ? (
              ""
            ) : (
              <Btn
                variant="contained"
                bgColor="#00B091"
                color="#ffffff"
                width="120px"
                height="40px"
                whiteSpace="nowrap"
                borderRadius="100px"
                click={() => reSendPayment()}
              >
                Resend
              </Btn>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
