import React from "react";

export const SubscriptionIcon = () => {
  return (
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none">
      <g opacity="0.4">
        <path
          d="M42 6H18C16.4087 6 14.8826 6.63214 13.7574 7.75736C12.6321 8.88258 12 10.4087 12 12V60C12 61.5913 12.6321 63.1174 13.7574 64.2426C14.8826 65.3679 16.4087 66 18 66H54C55.5913 66 57.1174 65.3679 58.2426 64.2426C59.3679 63.1174 60 61.5913 60 60V24L42 6Z"
          fill="url(#paint0_linear_531_935)"
          stroke="#3592FF"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M42 6V24H60"
          stroke="#3592FF"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23.625 36V42.75H30.375"
          stroke="#3592FF"
          stroke-width="0.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M48.375 54V47.25H41.625"
          stroke="#3592FF"
          stroke-width="0.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M45.5512 41.625C44.9807 40.0127 44.011 38.5711 42.7326 37.4349C41.4542 36.2986 39.9088 35.5048 38.2407 35.1273C36.5725 34.7499 34.8359 34.8012 33.1929 35.2764C31.5499 35.7516 30.0541 36.6353 28.845 37.845L23.625 42.75M48.375 47.25L43.155 52.155C41.9459 53.3647 40.4501 54.2484 38.8071 54.7237C37.1641 55.1989 35.4275 55.2502 33.7593 54.8727C32.0911 54.4953 30.5458 53.7014 29.2674 52.5652C27.989 51.4289 27.0193 49.9874 26.4487 48.375"
          stroke="#3592FF"
          stroke-width="0.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_531_935"
          x1="38.3415"
          y1="219.585"
          x2="-20.3048"
          y2="-19.1676"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3592FF" />
          <stop offset="0.9984" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};
