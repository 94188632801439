import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styles from "./wellnessTracker.module.css";
import styles2 from "./Charts/chart.module.css";
import { SleepHours } from "./Components/SleepHours";
import { SleepQuality } from "./Components/SleepQuality";
import { MoodScore } from "./Components/MoodScore";
import { Activity } from "./Components/Activity";
import { OverView } from "./Components/OverView";

import {
  getMActivityScoreApi,
  getMoodScoreApi,
  getSleepQuality,
  getSleepTracker,
  getWellnessTracker,
} from "../../../services/admin/wellnessTracker";
import moment from "../../../../node_modules/moment/moment";
import { Calender } from "./Components/Calender";
import { useSelector } from "react-redux";
import { MonthPicker } from "./monthPicker/MonthPicker";
import { dataPercentValue, dataSum } from "../../../config/methods";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";
export const WellnessTracker = () => {
  const sleepHourData = [
    {
      name: "Level 1",
      data: [44, 55, 41, 67, 22, 43],
    },
    {
      name: "Level 2",
      data: [13, 23, 20, 8, 13, 27],
    },
    {
      name: "Level 3",
      data: [11, 17, 15, 15, 21, 14],
    },
    {
      name: "Level 4",
      data: [21, 7, 25, 13, 22, 8],
    },
  ];

  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });

  const [overViewData, setOverViewData] = useState();
  const [getMoodScoreData, setGetMoodScoreData] = useState();
  const [getActivityScoreData, setGetActivityScoreData] = useState();
  const [getSleepQualityData, setGetSleepQualityData] = useState();
  const [getSleepTrackerData, setGetSleepTrackerData] = useState();
  const [selectAssessment, setSelectAssessment] = useState("All");
  const [isLoadingSleepHours, setIsLoadingSleepHours] = useState(false);
  const [isLoadingSleepQuality, setIsLoadingSleepQuality] = useState(false);
  const [isLoadingHours, setIsLoadingHours] = useState(false);
  const [isLoadingActivity, setIsLoadingActivity] = useState(false);
  const { selectedDate, year, fullDate } = useSelector(
    (i) => i.DateFilterSlice
  );

  const getMonth = fullDate.getMonth() + 1;

  const handleChange = (e) => {
    setSelectAssessment(e.target.value);
  };

  useEffect(() => {
    getWellnessTracker(selectAssessment, date)
      .then((res) => setOverViewData(res.data.list))
      .catch((err) => console.log(err));
  }, [selectAssessment, date]);

  useEffect(() => {
    getMoodScore();
    getActivityScore();
    getSleepQualityFun();
    sleepTrackerFun();
  }, [selectedDate]);

  const getMoodScore = () => {
    setIsLoadingHours(true);
    getMoodScoreApi(getMonth, year)
      .then((res) => {
        setIsLoadingHours(false);
        setGetMoodScoreData(res?.data?.moodavg);
      })
      .catch((err) => console.log(err));
  };

  const getActivityScore = () => {
    setIsLoadingActivity(true);
    getMActivityScoreApi(getMonth, year)
      .then((res) => {
        setIsLoadingActivity(false);
        setGetActivityScoreData(res?.data?.list);
      })
      .catch((err) => console.log(err));
  };

  const getSleepQualityFun = () => {
    setIsLoadingSleepQuality(true);
    getSleepQuality(getMonth, year)
      .then((res) => {
        setIsLoadingSleepQuality(false);
        setGetSleepQualityData(res?.data?.sleepQuality);
      })
      .catch((err) => console.log(err));
  };

  const sleepTrackerFun = () => {
    setIsLoadingSleepHours(true);
    getSleepTracker(getMonth, year)
      .then((res) => {
        setIsLoadingSleepHours(false);
        setGetSleepTrackerData(res?.data?.sleep);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {});

  const getMoodScoreFormat = [
    getMoodScoreData?.["Very Good Mood"] || 0,
    getMoodScoreData?.["Regular Mood"],
    getMoodScoreData?.["Feeling Low"],
    getMoodScoreData?.["Feeling Angry"],
    getMoodScoreData?.["Feel Like Crying"],
  ];

  const getActivityScoreFormat = [
    getActivityScoreData?.["Nutritional Wellness"],
    getActivityScoreData?.["Cognitive Wellness"],
    getActivityScoreData?.["Emotional Wellness"],
    getActivityScoreData?.["Physical Wellness"],
    getActivityScoreData?.["Social Wellness"],
  ];

  const getSleepQualityFormat = [
    getSleepQualityData?.["Poor"],
    getSleepQualityData?.["Good"],
    getSleepQualityData?.["Very Good"],
  ];

  const getSleepTrackerFormat = [
    getSleepTrackerData?.level1,
    getSleepTrackerData?.level2,
    getSleepTrackerData?.level3,
    getSleepTrackerData?.level4,
  ];

  console.log(getSleepTrackerData, "getSleepTrackerData");

  const moodScoreSum = dataSum(getMoodScoreFormat);
  const percentageValueMoodScore = dataPercentValue(
    getMoodScoreFormat,
    moodScoreSum
  );

  const ActivityScoreSum = dataSum(getActivityScoreFormat);
  const percentageValueActivityScore = dataPercentValue(
    getActivityScoreFormat,
    ActivityScoreSum
  );

  return (
    <div className={styles.chartMain}>
      <>
        <div className={styles.topHeader}>
          <h2>Assessments</h2>
          <div className={styles.calender}>
            <Calender setDateGap={setDate} init />
          </div>
        </div>
        <OverView
          handleChange={handleChange}
          overViewData={overViewData}
          styles={styles}
          selectAssessment={selectAssessment}
        />
      </>

      <div className={`${styles.topHeader} ${styles.bottomHeader}`}>
        <h2>Activities</h2>
        <div className={styles.calender}>
          <MonthPicker />
        </div>
      </div>
      <>
        <div className={styles.chartWrapper}>
          <SleepHours
            styles={styles}
            styles2={styles2}
            sleepHourData={getSleepTrackerFormat}
            isLoadingSleepHours={isLoadingSleepHours}
          />
          <SleepQuality
            styles={styles}
            styles2={styles2}
            sleepHourData={getSleepQualityFormat}
            isLoadingSleepQuality={isLoadingSleepQuality}
          />
        </div>
        <div className={styles.chartWrapper}>
          <MoodScore
            styles={styles}
            styles2={styles2}
            sleepHourData={percentageValueMoodScore}
            getSleepTrackerData={getSleepTrackerData}
            isLoadingHours={isLoadingHours}
          />
          <Activity
            styles={styles}
            styles2={styles2}
            sleepHourData={percentageValueActivityScore}
            getActivityScoreData={getActivityScoreData}
            isLoadingActivity={isLoadingActivity}
          />
        </div>
      </>
    </div>
  );
};
