import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { getEnterpriseActiveService } from "../../../services/enterprise/services";
import { ServicesCard } from "../../PreLogin/ServicesCard";
import styles from "./service.module.css";
import { ServiceDetailsPopup } from "./ServiceDetails/ServiceDetailsPopup";

import {
  setDate,
  setToggle,
  setYear,
} from "../../../Redux/Slices/Patient/PatienCalender";
import { useDispatch, useSelector } from "react-redux";
import moment from "../../../../node_modules/moment/moment";
import { Empty } from "../../Components/UI/Empty";

export const OtherServices = () => {
  const [popup, setPopup] = useState(false);
  const [serviceList, setServiceList] = useState();
  const [selectedService, setSelectedService] = useState();

  useEffect(() => {
    getEnterpriseActiveService("3").then((res) => {
      setServiceList(res.data.list);
    });
  }, []);

  const handleClick = (index) => {
    setPopup(true);
    setSelectedService(searchResult[index]);
  };

  const dispatch = useDispatch();

  const { selectedDate } = useSelector((i) => i.DateFilterSlice);

  const FinalArr = serviceList || [];

  const searchResult = FinalArr.filter((service) => {
    return selectedDate?.includes("All")
      ? service.created_at.includes(selectedDate.split(" ")?.[1])
      : service.created_at.includes(moment(selectedDate).format("YYYY-MM"));
  });

  useEffect(() => {
    return () => {
      dispatch(setDate(`All ${new Date().getFullYear()}`));
      dispatch(setToggle(false));
      dispatch(setYear(new Date().getFullYear()));
    };
  }, []);

  return (
    <>
      <div
        className={styles.container}
        style={
          searchResult?.length
            ? {}
            : { justifyContent: "center", translate: "0 50px" }
        }
      >
        <ServiceDetailsPopup
          popup={popup}
          setPopup={setPopup}
          selectedServiceData={selectedService}
        />

        {searchResult.length ? (
          searchResult?.map((item, index) => {
            return (
              <ServicesCard
                click={() => handleClick(index)}
                serviceData={item}
              />
            );
          })
        ) : (
          <Empty title="There are no service at this time." />
        )}
      </div>
    </>
  );
};
