export const confirmInputFieldIsNotEmpty = (data) => {
  const newData = data.split(",");
  const isEmpty = newData.includes("");
  if (isEmpty === newData.length) {
    return true;
  } else {
    const img = newData.filter((item) => item.length);
    return img.length === newData.length;
  }
};

export const confirmInputFieldAndImageIsNotEmpty = (data) => {
  // const newData = data.filter((el) => el.option === "");
  // console.log("newData", newData);
  const isEmpty = data.includes("");
  if (isEmpty === data.length) {
    return true;
  } else {
    const img = data.filter((item) => item.length);
    return img.length === data.length;
  }
};
