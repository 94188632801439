import React, { useContext, useEffect, useState } from "react";
import { Header } from "./Header";
import AssessmentStyles from "../../assets/css/assessment.module.css";
import { AssessmentCard } from "../../Component/UI/AssessmentCard";
import Mwb from "../../assets/icons/icon/Mental well being.svg";
import { AssessmentWrapper } from "./AssessmentWrapper";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../Auth/AuthContext";
import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

import { BsFillStarFill } from "react-icons/bs";
import { Helmet } from "react-helmet";

export const AssessmentNew = (props) => {
  const [start, setStart] = useState(false);
  const [data, setData] = useState([]);
  const [categoryInfo, setCategoryInfo] = useState({});

  const { ASSESSMENTCATEGORYDETAIL } = API;

  const navigate = useNavigate();

  const ctx = useContext(AuthContext);
  const is_loggedin = ctx?.loginUser?.is_loggedin;

  const domain = window.location.host;
  const canonicalUrl = `https://${domain}/self-assessment`;
  const queryParameters = new URLSearchParams(window.location.search);
  const assessmentId = queryParameters.get("id");
  console.log(assessmentId, "assessmentId");

  useEffect(() => {
    fetchData();
    is_loggedin && navigate("/dashboard");
  }, []);

  useEffect(() => {
    if (!assessmentId) return;
    if (!data) return;
    const filterId = data?.find((i) => i?.id === +assessmentId);
    handleStart(filterId);
  }, [data]);

  const handleStart = (e) => {
    setStart(true);
    setCategoryInfo(e);
  };

  const fetchData = async () => {
    await axios.get(ASSESSMENTCATEGORYDETAIL).then(async (res) => {
      setData(res.data.list);
    });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Self Assessment Test - Samvedna Map</title>
        <meta name="title" content="Self Assessment Test - Samvedna Map" />
        <meta
          name="description"
          content="Samvedna Care offers mental health and dementia care. Our services include assessments, online counselling, wellbeing solutions to deal with stress, anxiety, depression."
        />
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <span id="assessmentType" onClick={() => setStart(false)}></span>
      {!start ? (
        <div className={`${AssessmentStyles.bg} ${AssessmentStyles.bgPadding}`}>
          <Header show={true} />
          <div style={{ width: "85%", margin: "auto" }}>
            <div style={{ marginTop: "25px", textAlign: "left" }}>
              <h1 style={{ fontSize: "24px", fontWeight: "500" }}>
                Welcome to Samvedna MAP
              </h1>
            </div>
            <div style={{ marginTop: "20px", textAlign: "left" }}>
              <h2
                style={{
                  fontSize: "18px",
                  fontWeight: "normal",
                  color: "#555555",
                }}
              >
                A do it yourself mental health program. Our mental health
                services are based on the latest scientific literature and WHO
                guidelines.
              </h2>
            </div>
            <div className={AssessmentStyles.mainWrap}>
              {data.map((ele, i) => (
                <AssessmentCard
                  key={ele?.id}
                  index={i}
                  title={ele?.name}
                  instruction={ele?.instruction}
                  Icon={ele?.assessment_image || Mwb}
                  onClick={() => handleStart(ele)}
                  short_desc={ele?.short_description}
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <AssessmentWrapper
          start={start}
          setStart={setStart}
          data={categoryInfo}
          user={is_loggedin}
        />
      )}
    </>
  );
};
