import Checkbox from "@mui/material/Checkbox";

export const CheckBox2 = ({
  id,
  type,
  name,
  handleClick,
  isChecked,
  value,
  disabled,
}) => {
  // const [check, setCheck] = useState(props.check);
  return (
    <div>
      <Checkbox
        sx={{
          color: "#DADADA",
          "&.Mui-checked": {
            color: "#00B091",
          },
          padding: 0,
        }}
        onChange={handleClick}
        checked={isChecked}
        value={value}
        name={name}
        id={id}
        disabled={disabled}
        size={"medium"}
      />
      <label>{value}</label>
    </div>
  );
};
