import React, { useEffect, useContext } from "react";
import GoogleLogin from "react-google-login";
import {
  newSocialUserCreate,
  socialLoginAPI,
  userValidation,
} from "../../services/patient/socialLogin";
import { gapi } from "gapi-script";
import AuthContext from "../../Auth/AuthContext";
import { useNavigate } from "react-router-dom";
import Classess from "../../assets/css/Patient-login/patientlogin.module.css";
import { useDispatch, useSelector } from "react-redux";
import { StringDtoY } from "../../config/formattedDate";
import Google from "../../assets/icons/icon/google.svg";
import {
  qaStore,
  setAssessment_for,
  setNumberOfQues,
} from "../../Redux/Reducers/PatientReducers/qa_storage/qa_storage";
import toast, {
  Toaster,
} from "../../../node_modules/react-hot-toast/dist/index";
import { useState } from "react";
import { CircularProgress } from "../../../node_modules/@mui/material/index";
import PreLoginPopup from "../../Component/UI/ServicesCard/preLoginPopup";

const GoogleLoginComp = () => {
  const ctx = useContext(AuthContext);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {
    qaData,
    category_id,
    numberOfQues,
    Timetaken,
    assessment_for,
    relation,
    relationName,
  } = useSelector((state) => state.qa_storage);
  const { location } = useSelector((i) => i.location);
  const queryParameters = new URLSearchParams(window.location.search);
  const uniqId = queryParameters.get("uniqId");
  const [mobileNo, setMobileNo] = useState();
  const [countryCode, setCountryCode] = useState("+91");
  const rgx =
    /^(?!.*(\d)\1{9})(?!.*1234567890)(?!^00)(\([0-9]{3}\)\s*|[0-9]{3}[-/.]?)?[0-9]{3}[-/.]?[0-9]{4}$/;

  const feedbackSelectdOption =
    location?.split("?")?.[2]?.slice(0, 1)?.toUpperCase() +
    location?.split("?")?.[2]?.slice(1);

  const ProgressStatus =
    Number(numberOfQues) === qaData.length ? "completed" : "inprogress";

  const clientID =
    "521315067870-ff22alvdbuh5qq08egsom8d40l4u5lhg.apps.googleusercontent.com";

  const [popupAction, setPopupAction] = useState({
    open: false,
  });
  const [payloadData, setPayloadData] = useState();

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientID,
        scope: "profile",
      });
    };
    gapi.load("client:auth2", initClient);
    // setAssessmentStatus(ProgressStatus);
  }, []);

  const [disableBtn, setDisableBtn] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [newUserList, setNewUserList] = useState();
  const [newUser, setNewUser] = useState(false);
  const googleSignIn = (payload) => {
    setIsLoading(true);
    socialLoginAPI(payload, newUser)
      .then((res) => {
        setIsLoading(false);
        if (res.data.status) {
          if (res.data.data.token) {
            ctx.auth({
              ...res.data.data,
              role: "client",
              is_loggedin: true,
            });
            if (ProgressStatus === "completed") {
              navigate("/patient/assessment/results", {
                state: {
                  assessment_for,
                  popStatus: res?.data?.data?.customer?.popStatus,
                  category_id,
                },
              });
            } else if (location?.split("?")?.[1] === "feedback") {
              navigate("/patient/feedback", {
                state: {
                  feedback_type: feedbackSelectdOption,
                },
              });
            } else if (location?.split("?")?.[1] === "testimonial") {
              navigate("/patient/testimonial", {
                state: {
                  feedback_type: feedbackSelectdOption,
                },
              });
            } else if (location?.split("?")?.[1] === "Assessment") {
              navigate("/patient/Assessment", {
                state: {
                  feedback_type: feedbackSelectdOption,
                },
              });
            } else if (location?.split("?")?.[1] === "clientselfcareplan") {
              navigate("/clientselfcareplan", {
                state: {
                  feedback_type: feedbackSelectdOption,
                },
              });
            } else if (location?.split("?")?.[1] === "progresstrends") {
              navigate("/patient/progresstrends", {
                state: {
                  feedback_type: feedbackSelectdOption,
                },
              });
            } else if (location?.split("?")?.[1] === "consent") {
              navigate("/patient/consent");
            } else if (
              location?.split("?")?.[1] === `consentTwo&uniqId=${uniqId}`
            ) {
              navigate(`/patient/consentTwo&uniqId=${uniqId}`);
            } else {
              navigate("/dashboard");
            }
            dispatch(qaStore([]));
            dispatch(setAssessment_for("Myself"));
            dispatch(setNumberOfQues("number"));
          }
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err, "catch");
      })
      .finally(() => {
        setDisableBtn(true);
      });
  };

  useEffect(() => {
    setPayloadData({
      ...payloadData,
      mobile: `${countryCode}-${mobileNo}`,
    });
  }, [mobileNo]);
  const [mobileNoError, setMobilenoError] = useState();
  const [invalidNoError, setInvalidNoError] = useState();

  const submitMoNoForm = () => {
    if (!mobileNo) {
      setMobilenoError("Mobile no required");
      return;
    }
    const isNum = rgx.test(mobileNo);
    if (!isNum) {
      setMobilenoError("");
      setInvalidNoError("Invalid mobile number!");
      return;
    }

    let newUserpaLoad = {
      id: newUserList?.id,
      assessment_id: category_id,
      assessment_for,
      mobile: `${countryCode}-${mobileNo}`,
    };
    googleSignIn(newUser ? newUserpaLoad : payloadData);
  };
  const [isLoadingUser, setIsLoadingUser] = useState(false);

  const socialCustomerCreate = (data) => {
    setIsLoadingUser(true);
    newSocialUserCreate(data).then((res) => {
      setIsLoadingUser(false);
      if (res?.data?.status) {
        console.log("User created");
        setNewUserList(res?.data?.list);
      }
    });
  };

  const userCheckValidation = (resdata, e) => {
    userValidation(resdata?.email).then((res) => {
      let payload = {
        provider: "google",
        email: resdata.email,
        name: resdata.name,
        mobile: resdata.mobile,
        social_id: resdata.googleId,
        access_token: e.accessToken,
        assessment_result: qaData,
        assessment_id: category_id,
        dob: StringDtoY(new Date(1970, 0, 1)),
        status: ProgressStatus,
        percentage: (qaData.length / Number(numberOfQues)) * 100 || "",
        time_taken: Timetaken,
        assessment_for,
        relation,
        relation_name: relationName,
      };
      if (Object.keys(res?.data?.list).length) {
        setNewUser(false);
        if (
          res?.data?.list?.mobile !== undefined &&
          res?.data?.list?.mobile !== null &&
          res?.data?.list?.mobile !== "null"
        ) {
          googleSignIn(payload);
        } else {
          // socialCustomerCreate(payload);
          setPopupAction({
            ...popupAction,
            open: true,
            email: resdata?.email,
            name: resdata?.name,
          });
        }
      } else {
        setNewUser(true);
        socialCustomerCreate(payload);
        setPopupAction({
          ...popupAction,
          open: true,
          email: resdata?.email,
          name: resdata?.name,
        });
      }
    });
  };

  const successGoogle = (e) => {
    debugger;
    // console.log(e, "successGoogle");
    let resdata = e?.profileObj;
    setPayloadData({
      ...payloadData,
      provider: "google",
      email: resdata.email,
      name: resdata.name,
      social_id: resdata.googleId,
      access_token: e.accessToken,
      assessment_result: qaData,
      assessment_id: category_id,
      dob: StringDtoY(new Date(1970, 0, 1)),
      status: ProgressStatus,
      percentage: (qaData.length / Number(numberOfQues)) * 100 || "",
      time_taken: Timetaken,
      assessment_for,
      relation,
      relation_name: relationName,
    });
    setDisableBtn(false);
    userCheckValidation(resdata, e);
  };

  const errorGoogle = (e) => {
    //console.log(e, "errorGoogle");
    toast.error("Something went wrong!");
    setDisableBtn(true);
  };

  return (
    <>
      <PreLoginPopup
        invalidNoError={invalidNoError}
        mobileNoError={mobileNoError}
        popupAction={popupAction}
        setCountryCode={setCountryCode}
        setPopupAction={setPopupAction}
        countryCode={countryCode}
        heading={"Please complete your profile."}
        helpdesk={
          "Incase you have any queries please call our helpdesk- 7428097301"
        }
        mobile={mobileNo}
        setMobileNo={setMobileNo}
        payload={payloadData}
        submitMoNoForm={submitMoNoForm}
        isLoading={isLoading}
        setDisableBtn={setDisableBtn}
        isLoadingUser={isLoadingUser}
      />
      {disableBtn && (
        <GoogleLogin
          clientId={clientID}
          autoLoad={false}
          render={(renderProps) => (
            <div
              className={Classess.google}
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              <img src={Google} alt="Google" />
            </div>
          )}
          onSuccess={successGoogle}
          onFailure={errorGoogle}
          // cookiePolicy={"single_host_origin"}
        />
      )}
      {!disableBtn && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress color="inherit" />
          <p>Processing your request</p>
        </div>
      )}
      <Toaster />
    </>
  );
};

export default GoogleLoginComp;
