import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import styles from "./popup.module.css";
import { useNavigate } from "../../../../node_modules/react-router-dom/index";

export default function Popup({ popupAction, setPopupAction, title, icon }) {
  const navigate = useNavigate();
  const handleClose = () => {
    setPopupAction({ ...popupAction, open: false });
    navigate("/user/signin");
  };
  return (
    <div>
      <Modal open={popupAction.open}>
        <Box className={styles.popUpContainer}>
          <div
            style={{
              width: "100%",
              background: "#7a51ae",
              top: "0px",
              left: "0px",
              position: "absolute",
              borderTopLeftRadius: "19px",
              borderTopRightRadius: "19px",
              height: "40px",
              color: "white",
              fontSize: "18px",
              fontWeight: "600",
              padding: "5px",
              textTransform: "uppercase",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                alignSelf: "center",
                width: "100%",
                textAlign: "center",
              }}
            >
              {/* Booked */}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "40px",
            }}
          >
            {icon}
            <h2>{popupAction.name}</h2>
            <p
              style={{
                fontSize: "18px",
                fontWeight: "500",
                margin: "20px 12px",
                textAlign: "center",
                lineHeight: "26px",
              }}
            >
              {title}
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "-webkit-fill-available",
                margin: "4px 0",
              }}
            >
              <Btn
                bgColor="#404040"
                color="#fff"
                variant="contained"
                width="80px"
                height="40px"
                click={handleClose}
              >
                Close
              </Btn>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
