import React, { useEffect, useState } from "react";
import styles from "./assignPopup.module.css";
import { RiCloseLine } from "react-icons/ri";
import DoctorsImg from "../../../assets/images/doctor.svg";
import { Datepicker, Input, Select } from "../../../Component/UI/CustomInputs";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import { getRoles, getRolesApp } from "../../../services/admin/staff";
import {
  assignStaff,
  getAvailStaff,
} from "../../../services/admin/appointment";
import { toast } from "../../../../node_modules/react-hot-toast/dist/index";
import { to12Hour } from "../../../config/formattedDate";
import moment from "../../../../node_modules/moment/moment";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";

export const AssignModal = ({ setAssignDoctor, setAssignSuccess, data, modalData, getApi }) => {
  const [roles, setRoles] = useState();
  const [staff, setStaff] = useState();
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedStaffList, setSelectedStaffList] = useState();
  const [assignData, setAssignData] = useState();
  const [err, setErr] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedRosterId, setSelectedRosterId] = useState()

  const timeSlot = data?.body?.time_slot;
  const appointmentDate = data?.body?.appointment_date;
  const service_type = data?.body?.service_type;
  useEffect(() => {
    const dataFinal = {
      appointment_date: data?.body?.appDate,
      time_slot: timeSlot
    }
    getAvailStaff(dataFinal).then((res) => {
      setStaff(res?.data?.list);
    });
  }, []);



  useEffect(() => {
    getRolesApp().then((res) => {
      const list = res?.data?.list?.map(({ name, id }, i) => {
        return { id, value: name, ...(i === 0 && { selected: true }) };
      });
      setRoles(list);
      setSelectedRole(list[0]?.id);
    });
  }, []);


  useEffect(() => {
    const staffByRole = staff?.filter((item) => {
      const filteredStaff = item?.users?.role_name?.filter(
        (i) => i?.id?.toString() === selectedRole?.toString()
      );

      if (filteredStaff?.length !== 0) return item;
      else return "";
    });

    setSelectedStaffList(
      staffByRole
        ?.filter((i) => i !== "")
        .map((i) => {
          return { 
            id: i?.users?.id, 
            value: i?.users?.name,
            roster_id : i?.id
          };
        })
    );
  }, [selectedRole, staff]);



  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name === "staff_id"){
        selectedStaffList.filter(
        (i) => i?.id?.toString() === value?.toString()
        )?.map((i) => {
          return setSelectedRosterId(i?.roster_id)
        })
    }

    setAssignData({ ...assignData, [name]: value });
  };


  const handleSubmit = () => {
    setLoading(true)
    const finalData = {
      ...assignData,
      appointment_id: data?.id,
      type_of_service: service_type,
      roster_id: selectedRosterId
    };
    assignStaff(finalData).then((res) => {
      setLoading(false)
      setErr(res?.data?.error);
      if (res?.data?.status) {
        setAssignDoctor({ open: false });
        setAssignSuccess({open: true, body: { data :  res?.data?.msg},})
        
        getApi()
      }
    });
  };

  
  return (
    <>
      <div
       
        onClick={() => setAssignDoctor({ open: false })}
      />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <div className={styles.heading}>
              <div style={{ width: "100%" }}>{"Assign Doctor"} </div>
              {
                <button
                  className={styles.closeBtn}
                  onClick={() => setAssignDoctor({ open: false })}
                >
                  <RiCloseLine style={{ marginBottom: "-3px" }} />
                </button>
              }
            </div>
          </div>

          <div className={styles.modalContent}>
            <div className={styles.mainDiv}>
              <div style={{}}>
                <p style={{ color: "#000", fontSize: "15px" }}>
                  Type of Specialization
                </p>
                <div style={{ width: "222px" }} className={styles.options}>
                  <Select
                    options={roles}
                    // value={serviceFor.for}
                    // onChange={(e) =>
                    //   setServiceFor({
                    //     ...serviceFor,
                    //     for: e.target.value,
                    //   })
                    // }
                    onChange={(e) => 
                      {
                        setSelectedRosterId("")
                        setAssignData({
                          ...assignData,
                          staff_id : ''})
                        setSelectedRole(e.target.value)}
                    }
                  />
                </div>
              </div>
              <div style={{}}>
                <p style={{ color: "#000", fontSize: "15px" }}>Select Doctor</p>
                <div style={{ width: "222px" }} className={styles.options}>
                  <Select
                    name="staff_id"
                    options={selectedStaffList}
                    onChange={handleChange}
                    error={err?.staff_id}
                    value={assignData?.staff_id}
                    // value={serviceFor.for}
                    // onChange={(e) =>
                    //   setServiceFor({
                    //     ...serviceFor,
                    //     for: e.target.value,
                    //   })
                    // }
                  />
                </div>
              </div>
            </div>

            <div className={styles.mainDiv}>
              <div style={{}}>
                <p style={{ color: "#000", fontSize: "15px" }}>
                  Appointment Date
                </p>
                <div
                  style={{ width: "222px", marginTop: "8px" }}
                  className={styles.options}
                >
                  <Datepicker
                    label=""
                    name="start_date"
                    // display="flex"
                    minDate={new Date()}
                    defaultDate={data?.body?.appointment_date}
                    disabled
                    //   defaultDate={datetime?.start_date}
                    //   onChange={(e) =>
                    //     setDateTime({
                    //       ...datetime,
                    //       start_date: StringDtoY(e),
                    //     })
                    //   }
                  />
                </div>
              </div>
              <div style={{}}>
                <p style={{ color: "#000", fontSize: "15px" }}>Time Slot</p>
                <div style={{ width: "222px" }} className={styles.options}>
                  <Select
                    className={styles.timeSlot}
                    options={[
                      {
                        value: `${to12Hour(timeSlot)} - ${moment(
                          appointmentDate
                        )
                          .add(1, "hour")
                          .format("hh:mm A")}`,
                        selected: true,
                      },
                    ]}
                    disabled
                    // value={serviceFor.for}
                    // onChange={(e) =>
                    //   setServiceFor({
                    //     ...serviceFor,
                    //     for: e.target.value,
                    //   })
                    // }
                  />
                </div>
              </div>
            </div>
            <div style={{}}>
              <p style={{ color: "#000", fontSize: "15px" }}>
                {
                  service_type === 0 ? "Address" :
                  service_type === 1 ? "Appointment Link":
                  service_type === 2 ? "Appointment Link/Address" : ""
                }
              </p>
              <div
                style={{ width: "100%", marginTop: "8px" }}
                className={styles.options}
              >
                <Input
                  name="appointment_link"
                  onChange={handleChange}
                  error={err?.appointment_link}
                  value={assignData?.appointment_link}
                />
              </div>
            </div>
          </div>
          {
            loading &&
            <div style={{ textAlign: "center" }}>
          <CircularProgress size={30} />
          <p style={{ color: "black" }}>
            Please wait ! We are validating the details...
          </p>
        </div>
          }
          
          <div className={styles.btnGroup}>
            <Btn
              variant="contained"
              bgColor="#000"
              color="#ffffff"
              width="120px"
              height="40px"
              whiteSpace="nowrap"
              borderRadius="100px"
              margin="15px"
              click={() => {
                setAssignDoctor({ open: false });
              }}
            >
              Cancel
            </Btn>
            <Btn
              variant="contained"
              bgColor="#00B091"
              color="#ffffff"
              width="120px"
              height="40px"
              whiteSpace="nowrap"
              borderRadius="100px"
              click={handleSubmit}
            >
              Assign
            </Btn>
          </div>
        </div>
      </div>
    </>
  );
};


