import React, { useState } from "react";

import { AppointmentDetails } from "../../../Appointments/AppointmentDetails";
import classes from "../../../../../assets/css/sessionreport.module.css";
import { Buttons } from "../../../../Appointments/Buttons";
import { Activity } from "./Activity";
import BasicTabs from "../../../../UI/Tab/Tabs";
import { Observations } from "./Observations";
import { SessionMedia } from "./SessionMedia";
import { Heading } from "../../../../../Component/UI/Heading";

export const SessionReport = () => {
  const [count, setCount] = useState(0);
  const items = [
    {
      label: "Observations",
      component: <Observations />,
    },
    {
      label: "Activity",
      component: <Activity />,
    },
    {
      label: "Session Media",
      component: <SessionMedia />,
    },
  ];
  return (
    <div>
      {/* <AppointmentDetails /> */}
      <div className={classes.tab_con}>
        <div className={classes.sub_title}>
          <Heading title="Add Report Summary" />
        </div>
        <BasicTabs tabcontent={items} value={count} setValue={setCount} />
      </div>

      <div className={classes.session_button}>
        <Buttons />
      </div>
    </div>
  );
};
