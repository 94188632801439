import moment from "moment";

export const to12Hours = (hour, min) => {
  const prefix = (val) => (val <= 9 && val.length < 1 ? "0" + val : val);
  if (hour > 12) {
    const h = hour - 12;
    return `${prefix(h)}:${prefix(min)}PM`;
  } else {
    return `${prefix(hour)}:${prefix(min)}AM`;
  }
};

export const to12Hour = (time) => moment(time, "hh:mm").format("hh:mm A");

export const addHour = (datetime, amount) =>
  moment.add(amount, datetime).format();

export const formatttedDate = (init_date) => {
  const prefix = (val) => (val <= 9 ? "0" + val : val);
  const d = new Date(init_date);
  return `${prefix(d.getDate())}/${prefix(
    d.getMonth() + 1
  )}/${d.getFullYear()}`;
};

export const DtoY = (date) => date?.split("/").reverse().join("-");
export const YtoD = (date) => date?.split("-").reverse().join("/");

export const StringDtoY = (date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export const newDateFormat = (date) => {
  if (date) {
    const [year, month, day] = date.split("-");
    return new Date(year, month - 1, day);
  }
};

export function getAllDaysInMonth(year, month) {
  const date = new Date(year, month, 1);
  const dates = [];
  while (date.getMonth() === month) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return dates;
}

export function getMonthWiseData(year, month, monthlyData) {
  const date = new Date(year, month, 1);
  const dates = [];

  const daysPoints = moment(date).startOf("month").weekday();
  const endsPoints = moment(date).add(1, "month").startOf("month").weekday();

  const previousDays = Array(daysPoints)
    .fill()
    .map((_, i) => ({
      date: new Date(moment(date).date(-i)),
      currentMonth: false,
    }))
    .reverse();

  const nextDays =
    endsPoints == 0
      ? []
      : Array(7 - endsPoints)
          .fill()
          .map((_, i) => ({
            date: new Date(
              moment(date)
                .add(1, "month")
                .date(i + 1)
            ),

            currentMonth: false,
          }));

  while (date.getMonth() === month) {
    dates.push({
      date: new Date(date),
      status: monthlyData.find(
        (dat) =>
          moment(dat.date).format("YYYY-MM-DD") ===
          moment(new Date(date)).format("YYYY-MM-DD")
      )?.answer?.answer,
      currentMonth: true,
    });
    date.setDate(date.getDate() + 1);
  }
  return [...previousDays, ...dates, ...nextDays];
}

export const getDayDifference = (startDate, endDate) => {
  let [y1, m1, d1] = moment(startDate).format("YYYY-MM-DD").split("-");
  let [y2, m2, d2] = moment(endDate).format("YYYY-MM-DD").split("-");

  let dateOne = moment([y1, m1 - 1, d1]);
  let dateTwo = moment([y2, m2 - 1, d2]);

  return dateTwo.diff(dateOne, "days");
};

export const convertCurrency = (amt) =>
  Number(amt).toLocaleString("en-US", { style: "currency", currency: "INR" });
