import React from 'react'

const RequestSentIcon = () => {
  return (
    <svg width="40" height="50" viewBox="0 0 40 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.3333 6.33331H34C35.2377 6.33331 36.4246 6.82498 37.2998 7.70015C38.175 8.57532 38.6666 9.7623 38.6666 11V43.6666C38.6666 44.9043 38.175 46.0913 37.2998 46.9665C36.4246 47.8416 35.2377 48.3333 34 48.3333H5.99998C4.7623 48.3333 3.57532 47.8416 2.70015 46.9665C1.82498 46.0913 1.33331 44.9043 1.33331 43.6666V11C1.33331 9.7623 1.82498 8.57532 2.70015 7.70015C3.57532 6.82498 4.7623 6.33331 5.99998 6.33331H10.6666" stroke="#2A2A2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M27 1.66669H13C11.7114 1.66669 10.6667 2.71136 10.6667 4.00002V8.66669C10.6667 9.95535 11.7114 11 13 11H27C28.2887 11 29.3334 9.95535 29.3334 8.66669V4.00002C29.3334 2.71136 28.2887 1.66669 27 1.66669Z" stroke="#2A2A2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.7778 22.9321C14.265 21.547 15.2268 20.379 16.4927 19.635C17.7586 18.891 19.247 18.619 20.6942 18.8673C22.1414 19.1155 23.454 19.8679 24.3996 20.9912C25.3452 22.1146 25.8628 23.5363 25.8606 25.0046C25.8606 29.1497 19.643 31.2222 19.643 31.2222" stroke="#2A2A2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19.7205 37.4597H19.7516" stroke="#2A2A2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  )
}

export default RequestSentIcon