import React from "react";
import styles from "./permissions.module.css";
import SwitchToggle from "./Switch";
import { CheckBox2 } from "./Checkbox";
import { findFunc } from "../../../../../config/methods";
import { useDispatch } from "react-redux";
import { updateData } from "../../../../../Redux/Slices/RolesAndPermissions/updateRolesAndPerms";
import { toast } from "../../../../../../node_modules/react-hot-toast/dist/index";

export const Permissions = ({
  selectedData,
  permissions,
  rolesAndPermsSet,
}) => {
  const dispatch = useDispatch();
  const writePermissionsRol = +rolesAndPermsSet?.access?.Write;
  const disabledToggle = selectedData?.subCategories?.every((k) =>
    Boolean(k.disabled)
  );

  const changeMainToggle = (changedToggle) =>
    changedToggle?.map((j) => {
      if (j?.id === selectedData?.id) {
        const readAnyChecked =
          j?.allCheck?.Read ||
          (j?.listControl?.access?.Read ?? 0) ||
          (j?.subCategories?.some((i) =>
            i?.list?.some((v) => v?.access?.Read === 1)
          ) ??
            0) ||
          (j?.subCategories?.some((i) => i?.access?.Read === 1) ?? 0);

        return {
          ...j,
          status: readAnyChecked ? 1 : 0,
        };
      } else return j;
    });

  const checkAll = (data, key) => {
    const checkData = data.map((i) => {
      if (i.id === selectedData.id) {
        const listCtrlStatus = i?.listControl?.access?.[key];
        if (i?.subCategories) {
          const subCategoriesStatus = i.subCategories
            ?.map((i) => "list" in i)
            ?.every(Boolean)
            ? i.subCategories.every((v) =>
                v.list.every((b) => b.access?.[key] === 1)
              )
            : i.subCategories.every((v) => v.access?.[key] === 1);
          const AllCheckStatus =
            listCtrlStatus !== undefined
              ? subCategoriesStatus
                ? listCtrlStatus
                  ? 1
                  : 0
                : 0
              : subCategoriesStatus
              ? 1
              : 0;

          return { ...i, allCheck: { ...i.allCheck, [key]: AllCheckStatus } };
        } else {
          return { ...i, allCheck: { ...i.allCheck, [key]: listCtrlStatus } };
        }
      } else return i;
    });
    const lastUpdate = changeMainToggle(checkData);
    dispatch(updateData(lastUpdate));
  };

  const finalUpdatedData = (subCategories, key) => {
    const finalChangedData = permissions.map((i) => {
      if (i.id === selectedData.id) {
        return { ...i, subCategories };
      } else return i;
    });

    checkAll(finalChangedData, key);
  };

  const handleCheck = (checkData, key) => {
    const change = selectedData?.subCategories?.map((i) => {
      if (i?.id === checkData?.id) {
        return { ...i, access: { ...i.access, [key]: i.access[key] ? 0 : 1 } };
      } else {
        return i;
      }
    });

    finalUpdatedData(change, key);
  };

  const handleCheckList = (categories, subCategories, key) => {
    const changeCategories = selectedData?.subCategories.map((i) => {
      if (i?.id === categories.id) {
        const updatedList = i.list.map((l) => {
          console.log(subCategories, "lists");
          if (l.id === subCategories.id) {
            return {
              ...l,
              access: { ...l.access, [key]: l.access[key] ? 0 : 1 },
            };
          }
          return l;
        });
        const listStatus = updatedList.some((i) => Boolean(!!i.access.Read));
        return { ...i, status: listStatus ? 1 : 0, list: updatedList };
      } else return i;
    });
    finalUpdatedData(changeCategories, key);
  };

  const handleCheckMainList = (listControlData, key) => {
    const modifiedListCtrl = {
      ...listControlData,
      access: {
        ...listControlData.access,
        [key]: listControlData.access[key] ? 0 : 1,
      },
    };

    const finalChangedData = permissions.map((i) => {
      if (i.id === selectedData.id) {
        return {
          ...i,
          listControl: modifiedListCtrl,
        };
      } else return i;
    });

    checkAll(finalChangedData, key);
  };

  const changeAllToggle = (key) => {
    const generateStatus = (status, selectedObj) => {
      if (key) {
        return { [key]: selectedObj?.[key] ? 0 : 1 };
      } else {
        const obj = {};
        for (let k in selectedObj) {
          if (k === "Read" || !disabledToggle) obj[k] = status ? 0 : 1;
        }
        return obj;
      }
    };
    const changedToggle = permissions?.map((k) => {
      if (k?.id === selectedData?.id) {
        return {
          ...k,
          allCheck: {
            ...k.allCheck,
            ...generateStatus(k?.status, k?.allCheck),
          },
        };
      } else return k;
    });

    const changeSubCatStatus = changedToggle?.map((t) => {
      if (t.id === selectedData?.id) {
        const changeSubCatStatusInner = t.subCategories
          ?.map((i) => "list" in i)
          ?.every(Boolean)
          ? t.subCategories.map((j) => {
              return {
                ...j,
                status: t?.allCheck?.Read,
              };
            })
          : t?.subCategories;
        return {
          ...t,
          subCategories: changeSubCatStatusInner,
        };
      } else return t;
    });

    const updateCheckboxes = changeSubCatStatus.map((d) => {
      if (d.id === selectedData.id) {
        const listCtrlStatusChange = {
          ...d,
          ...(d?.listControl && {
            listControl: {
              ...d.listControl,
              access: {
                ...d.listControl?.access,
                ...generateStatus(selectedData?.status, selectedData?.allCheck),
              },
            },
          }),
        };

        const subCategoriesUpdate = d.subCategories
          ? d.subCategories?.map((i) => "list" in i)?.every(Boolean)
            ? {
                ...listCtrlStatusChange,
                subCategories: listCtrlStatusChange?.subCategories?.map(
                  (arr) => {
                    const list = arr.list.map((v) => {
                      return {
                        ...v,
                        access: {
                          ...v.access,
                          ...generateStatus(
                            selectedData?.status,
                            selectedData?.allCheck
                          ),
                        },
                      };
                    });
                    return { ...arr, list };
                  }
                ),
              }
            : {
                ...listCtrlStatusChange,
                subCategories: listCtrlStatusChange?.subCategories?.map((v) => {
                  return {
                    ...v,
                    access: {
                      ...v.access,
                      ...generateStatus(
                        selectedData?.status,
                        selectedData?.allCheck
                      ),
                    },
                  };
                }),
              }
          : null;

        const subCategoriesStatusChange =
          subCategoriesUpdate ?? listCtrlStatusChange;

        return subCategoriesStatusChange;
      } else return d;
    });
    const lastUpdate = changeMainToggle(updateCheckboxes);
    dispatch(updateData(lastUpdate));
  };

  const handleMainToggle = () => {
    changeAllToggle();
  };

  return (
    <div className={styles.main}>
      <div className={styles.mainHeader}>
        <div className={styles.mainHeaderLeft}>{selectedData?.name}</div>
        <div className={styles.mainHeaderLeft}>
          <SwitchToggle
            disabled={!writePermissionsRol}
            status={selectedData?.status}
            onClick={handleMainToggle}
          />
        </div>
      </div>
      <div className={styles.subHeader}>
        <div className={styles.mainHeaderLeftName}>Name</div>
        <div className={styles.mainHeaderSwitch}>
          <div>Read</div>
          <div>
            <SwitchToggle
              disabled={!writePermissionsRol}
              status={selectedData?.allCheck?.Read}
              onClick={() => {
                !selectedData?.allCheck?.Write
                  ? changeAllToggle("Read")
                  : toast.error("Please disable write first");
              }}
            />
          </div>
        </div>
        <div className={styles.mainHeaderSwitch}>
          <div>Write</div>
          <div>
            <SwitchToggle
              disabled={disabledToggle || !writePermissionsRol}
              status={selectedData?.allCheck?.Write}
              onClick={() => {
                const mainToggleCtrl = selectedData?.allCheck;
                mainToggleCtrl?.Read
                  ? !mainToggleCtrl?.["Full Control"]
                    ? changeAllToggle("Write")
                    : toast.error("Please disable Full Control first")
                  : toast.error("Please enable read first");
              }}
            />
          </div>
        </div>
        <div className={styles.mainHeaderSwitch}>
          <div>Full Control</div>
          <div>
            <SwitchToggle
              disabled={disabledToggle || !writePermissionsRol}
              status={selectedData?.allCheck?.["Full Control"]}
              onClick={() => {
                selectedData?.allCheck?.Write
                  ? changeAllToggle("Full Control")
                  : toast.error("Please enable read & write first");
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.scrollbar} id={styles.styleIdRight}>
        <div className={styles.mainWrapper}>
          <div className={styles.subCatInner}>
            <div className={styles.subCatName}>
              {!selectedData?.listControl ? null : (
                <div className={styles.block}>
                  <div className={styles.listBlock}>
                    {selectedData?.listControl?.listName}
                  </div>
                  <div className={styles.read}>
                    <CheckBox2
                      disabled={!writePermissionsRol}
                      boldness={600}
                      isChecked={selectedData?.listControl?.access?.Read}
                      handleClick={() => {
                        !selectedData?.listControl?.access?.Write
                          ? handleCheckMainList(
                              selectedData?.listControl,
                              "Read"
                            )
                          : toast.error("Please disable write first");
                      }}
                    />
                    {/* {list?.access?.Read} */}
                  </div>
                  <div className={styles.write}>
                    <CheckBox2
                      boldness={600}
                      disabled={
                        selectedData?.listControl?.disabled ||
                        !writePermissionsRol
                      }
                      isChecked={selectedData?.listControl?.access?.Write}
                      handleClick={() => {
                        const listCtrl = selectedData?.listControl?.access;
                        listCtrl?.Read
                          ? !listCtrl?.["Full Control"]
                            ? handleCheckMainList(
                                selectedData?.listControl,
                                "Write"
                              )
                            : toast.error("Please disable Full Control first")
                          : toast.error("Please enable read first");
                      }}
                    />
                  </div>
                  <div className={styles.fullControl}>
                    <CheckBox2
                      boldness={600}
                      disabled={
                        selectedData?.listControl?.disabled ||
                        !writePermissionsRol
                      }
                      isChecked={
                        selectedData?.listControl?.access?.["Full Control"]
                      }
                      handleClick={() => {
                        const listCtrl = selectedData?.listControl?.access;
                        listCtrl.Write && listCtrl.Read
                          ? handleCheckMainList(
                              selectedData?.listControl,
                              "Full Control"
                            )
                          : toast.error("Please enable read & write first");
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {selectedData?.subCatStatus ? (
            <div className={styles.subCat}>
              {selectedData?.subCategories?.map(
                (subCategory, subCategoryIndex) => {
                  return (
                    <div className={styles.subCatInner}>
                      <div className={styles.subCatName}>
                        <div className={styles.title}>{subCategory?.title}</div>
                        {subCategory?.list?.map(
                          (subCategoryList, subCategoryIndexList) => {
                            return (
                              <div className={styles.block}>
                                <div className={styles.listBlock}>
                                  {subCategoryList?.listName}
                                </div>
                                <div className={styles.read}>
                                  <CheckBox2
                                    boldness={600}
                                    disabled={!writePermissionsRol}
                                    isChecked={subCategoryList?.access?.Read}
                                    handleClick={() => {
                                      !subCategoryList?.access?.Write
                                        ? handleCheckList(
                                            subCategory,
                                            subCategoryList,
                                            "Read"
                                          )
                                        : toast.error(
                                            "Please disable write first"
                                          );
                                    }}
                                  />
                                  {/* {list?.access?.Read} */}
                                </div>
                                <div className={styles.write}>
                                  <CheckBox2
                                    boldness={600}
                                    disabled={
                                      subCategoryList?.disabled ||
                                      !writePermissionsRol
                                    }
                                    isChecked={subCategoryList?.access?.Write}
                                    handleClick={() => {
                                      const subCatCtrl =
                                        subCategoryList?.access;
                                      subCatCtrl?.Read
                                        ? !subCatCtrl?.["Full Control"]
                                          ? handleCheckList(
                                              subCategory,
                                              subCategoryList,
                                              "Write"
                                            )
                                          : toast.error(
                                              "Please disable Full Control first"
                                            )
                                        : toast.error(
                                            "Please enable read first"
                                          );
                                    }}
                                  />
                                </div>
                                <div className={styles.fullControl}>
                                  <CheckBox2
                                    boldness={600}
                                    disabled={
                                      subCategoryList?.disabled ||
                                      !writePermissionsRol
                                    }
                                    isChecked={
                                      subCategoryList?.access?.["Full Control"]
                                    }
                                    handleClick={() => {
                                      subCategoryList?.access?.Write
                                        ? handleCheckList(
                                            subCategory,
                                            subCategoryList,
                                            "Full Control"
                                          )
                                        : toast.error(
                                            "Please enable read & write first"
                                          );
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          ) : (
            <div className={styles.simpleList}>
              {selectedData?.subCategories?.map((subCategory) => {
                return (
                  <div className={styles.subCatInner}>
                    <div className={styles.subCatName}>
                      <div className={styles.block}>
                        <div className={styles.simpleListTitle}>
                          {subCategory?.listName}
                        </div>
                        <div className={styles.read}>
                          <CheckBox2
                            boldness={600}
                            isChecked={subCategory?.access?.Read}
                            disabled={!writePermissionsRol}
                            handleClick={() => {
                              !subCategory?.access?.Write
                                ? handleCheck(subCategory, "Read")
                                : toast.error("Please disable write first");
                            }}
                          />
                        </div>
                        <div className={styles.write}>
                          <CheckBox2
                            boldness={600}
                            disabled={
                              subCategory?.disabled || !writePermissionsRol
                            }
                            isChecked={subCategory?.access?.Write}
                            handleClick={() => {
                              const subCatCtrl = subCategory.access;
                              subCatCtrl?.Read
                                ? !subCatCtrl?.["Full Control"]
                                  ? handleCheck(subCategory, "Write")
                                  : toast.error(
                                      "Please disable Full Control first"
                                    )
                                : toast.error("Please enable read first");
                            }}
                          />
                        </div>
                        <div className={styles.fullControl}>
                          <CheckBox2
                            boldness={600}
                            disabled={
                              subCategory?.disabled || !writePermissionsRol
                            }
                            isChecked={subCategory?.access?.["Full Control"]}
                            handleClick={() => {
                              subCategory?.access?.Write
                                ? handleCheck(subCategory, "Full Control")
                                : toast.error(
                                    "Please enable read & write first"
                                  );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
