import { createSlice } from '@reduxjs/toolkit'
import instance from '../../config/axiosConfig';
import { API } from '../../config/constant';
//import { patientToken } from '../../assets/constants';

export const initialState = {
  loading: false,
  hasErrors: false,
  clientSelfcarePlan: [],
  clientgetQuickSuccess:[],
  clientpostQuickSuccess:[],
};

const clientSelfcarePlanSlice = createSlice({
  name: "clientSelfcarePlan",
  initialState,
  reducers: {
    getLoading: (state) => {
      state.loading = true;
    },
    getSuccess: (state, { payload }) => {
      state.clientSelfcarePlan.push(payload);
      state.loading = false;
      state.hasErrors = false;
    },
    getQuickSuccess: (state, { payload }) => {
      state.clientgetQuickSuccess = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    postQuickSuccess: (state, { payload }) => {
      state.clientgetQuickSuccess = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const { getLoading,getSuccess,getFailure,getQuickSuccess,postQuickSuccess } = clientSelfcarePlanSlice.actions

// The reducer
export default clientSelfcarePlanSlice.reducer;

//get api
export function fetchClientSelfcarePlan() {

  return async dispatch => {
    dispatch(getLoading())
    try {
      const response = await instance.get(API.CLIENTSELFCAREPLAN)
       const data = response
       console.log(data.data.list,"clientSelfcarePlan")
      dispatch(getSuccess(data.data.list))

    } catch (error) {
      dispatch(getFailure())
    }
  }
}

export function fetchClientQuickAsk() {

  return async dispatch => {
    dispatch(getLoading())
    try {
      const response = await instance.get(API.CLIENTQUICKASK)
       const data = response
       console.log(data.data.list,"CLIENTQUICKASK")
      dispatch(getQuickSuccess(data.data.list))

    } catch (error) {
      dispatch(getFailure())
    }
  }
}

export function fetchPostClientQuickAsk() {

  return async dispatch => {
    dispatch(getLoading())
    try {
      const response = await instance.get(API.CLIENTQUICKASK)
       const data = response
       console.log(data.data.list,"CLIENTQUICKASK")
      dispatch(postQuickSuccess(data.data.list))

    } catch (error) {
      dispatch(getFailure())
    }
  }
}
