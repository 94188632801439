import React from "react";

export const HowBoxes = ({ howItData, styles }) => {
  return (
    <div className={styles.howWork}>
      <ul className={styles.desktopView}>
        {howItData?.map((item, i) => {
          return (
            <li>
              <div className={styles.stepSec}>
                {item?.step} <span>{i + 1}</span>
              </div>
              <div className={styles.stepImg}>
                {<img src={item?.image} alt="step" />}
              </div>
              <div className={styles.stepTitle}>{item?.title}</div>
            </li>
          );
        })}
      </ul>
      <ul className={styles.mobilView}>
        {howItData?.map((item, i) => {
          return (
            <li>
              <div className={styles.stepImg}>
                {<img src={item?.image} alt="step" />}
              </div>
              <div className={styles.contentMobile}>
                <div className={styles.stepSec}>
                  {item?.step} <span>{i + 1}</span>
                </div>

                <div className={styles.stepTitle}>{item?.title}</div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
