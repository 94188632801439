import React from 'react'
import StepOne from './Images/stepOne.svg'
import StepTwo from './Images/stepTwo.svg'
import StepThree from './Images/stepThree.svg'
import StepFour from './Images/stepFour.svg'
import { HowBoxes } from './Components/HowBoxes'
export const HowItWorks = ({styles}) => {
    const howItData = [
        {
            step : "Step",
            image : StepOne, 
            title : "Take a self-assessment"
         },
        {
            step : "Step",
            image : StepTwo, 
            title : "Get your recommendation report"
         },
         {
            step : "Step",
            image : StepThree, 
            title : "Book your consultation slot"
         },
         {
            step : "Step",
            image : StepFour, 
            title : "Track your progress"
         },
    ]
  return (
    <div className={styles.HowItWorks}>
        <h3>How It Works</h3>
        <HowBoxes 
        howItData={howItData}
        styles={styles}
        />
    </div>
  )
}
