import React from "react";
import { useLayoutEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import toast from "react-hot-toast";

import AssessmentStyles from "../../../assets/css/assessment.module.css";
import classes from "../../../assets/css/Enterprise/Login/enterpriselogin.module.css";
import logo from "../../../assets/icons/icon/logo.svg";
import {
  createEmployee,
  employeeVerification,
  EnterpriseAssessmentDetails,
  enterpriseData,
  otpVerification,
} from "../../../services/enterprise/prelogin";
import { useNavigate } from "../../../../node_modules/react-router-dom/index";
import { Input } from "../../../Component/UI/CustomInputs";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setEmployeeEmail } from "../../../Redux/Reducers/PatientReducers/qa_storage/qa_storage";
import { decryption } from "../../../config/encryptDecrypt";
import VerifyOTP from "../../PreLogin/Assessment/VerifyOTP";
import styles from "../../PreLogin/Assessment/assessmentEnterprise.module.css";
import LoginImage from "../../../assets/icons/LoginImage.svg";
import { Toaster } from "../../../../node_modules/react-hot-toast/dist/index";

export const Enroll = (props) => {
  const [orgData, setOrgData] = useState(null);
  const [userDetail, setuserDetail] = useState({
    email: "",
  });
  const [error, setError] = useState({
    email: "",
  });
  const [verify, setVerify] = useState(false);
  const [disable, setDisable] = useState(false);

  const { orgId, assessment_id } = useParams();
  const [otpValue, setOtpValue] = useState("");
  const [verifydata, setVerifydata] = useState("");
  const [genrateOtp, setGenrateOtp] = useState();
  const [isLoading, setisLoading] = useState();

  let decryptedAssessmentId = "";

  console.log(verifydata, "orgData---");

  try {
    decryptedAssessmentId = decryption(window.atob(assessment_id));
  } catch {
    toast.remove();
    // toast.error("Link is expired, Please try again with new link !");
  }

  console.log(decryptedAssessmentId, "decryptedAssessmentId");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setuserDetail({
      ...userDetail,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    dispatch(setEmployeeEmail(userDetail.email));
  }, [userDetail]);

  useLayoutEffect(() => {
    enterpriseData(orgId).then((res) => {
      if (res.data.status) {
        setOrgData(res.data.lead);
      } else {
        toast.remove();
        toast.error("Link is expired, Please try again with new link !");
      }
    });
  }, []);

  const handleChangeOTP = (otp) => {
    setOtpValue(otp);
  };

  let emailArr = orgData?.domain?.split() || "";

  const other_Domains = orgData?.other_domains
    ? orgData?.other_domains?.split(",")
    : "";

  let finalEmailArr = [...emailArr, ...other_Domains];
  console.log(finalEmailArr, "finalEmailArr--");

  const handleVerify = (e) => {
    e.preventDefault();
    let domain = userDetail.email?.split("@")[1];
    const validationStatus = finalEmailArr?.some((email) => {
      return email.trim() === domain;
    });

    console.log(validationStatus, "validationStatus");

    if (validationStatus) {
      setDisable(true);
      createEmployee({
        email: userDetail.email,
        enterprise_id: orgData?.id,
      })
        .then((res) => {
          setVerify(true);
          setVerifydata(res?.data?.data);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setDisable(false);
        });
    } else {
      toast.remove();
      toast.error("Please use your work email for login !");
    }
  };

  const handleSubmitOtp = (e) => {
    debugger;
    setisLoading(true);
    if (!otpValue) {
      toast.error("Please enter the correct verification code");
      return;
    }
    e.preventDefault();
    otpVerification({
      id: verifydata?.id,
      email: verifydata?.email,
      code: otpValue,
    }).then((res) => {
      if (res.data.status) {
        navigate("thankyou", { state: { message: res?.data?.message } });
      }
      if (res.data.is_verified) {
        toast.success(res?.data?.message);
      } else {
        toast.error(res.data.message);
      }
      setisLoading(false);
    });
  };

  return (
    <>
      <Toaster />
      <div
        className={styles.login_container}
        style={{ background: `url(${LoginImage})` }}
      >
        <header className={AssessmentStyles.header}>
          <Link to="/">
            <img className={AssessmentStyles.logo} src={logo} alt="logo" />
          </Link>
          <div className={classes.enterPriseHead}>
            <h3>Welcome to Samvedna Employee Mental Wellbeing Portal</h3>
          </div>
        </header>
        {!verify ? (
          <>
            <form
              className={classes.login_subcontainer}
              onSubmit={(e) => handleVerify(e)}
            >
              <div className={classes.logo_div}>
                {orgData?.img_url ? (
                  <img
                    style={{
                      width: "fit-content",
                      height: "50px",
                      objectFit: "contain",
                    }}
                    src={orgData.img_url}
                    alt={"org"}
                  />
                ) : null}
              </div>
              <p className={classes.heading}>
                Enter your email id to get started
              </p>
              <div>
                <>
                  <div className={classes.input}>
                    <Input
                      type="text"
                      label="Work Email Address"
                      id="email"
                      value={userDetail?.email}
                      name="email"
                      labelSize={14}
                      onChange={(e) => handleChange(e)}
                      placeholder="Email Address"
                      style={{ outlineColor: "#7a51ae" }}
                      autoComplete="off"
                      error={error?.email}
                    />
                  </div>
                  <Btn
                    variant="contained"
                    borderRadius="4px"
                    bgColor="#3592FF"
                    color="#fff"
                    width="100%"
                    margin="10px 0px"
                    disabled={disable}
                  >
                    {disable ? <CircularProgress size={28} /> : "Submit"}
                  </Btn>
                </>
              </div>
            </form>
          </>
        ) : (
          <form
            className={classes.login_subcontainer}
            onSubmit={(e) => handleSubmitOtp(e)}
          >
            <div className={classes.logo_div}>
              {orgData?.img_url ? (
                <img
                  style={{
                    width: "fit-content",
                    height: "50px",
                    objectFit: "contain",
                  }}
                  src={orgData.img_url}
                  alt={"org"}
                />
              ) : null}
            </div>
            <p className={classes.heading}>Enter your OTP to get started</p>
            <p style={{ lineHeight: "20px", marginBottom: "10px" }}>
              Please verify your account by entering four digit OTP receive on
              your work email.
            </p>
            <p style={{ lineHeight: "20px" }}>
              You can start the assessment once your account is verified.
            </p>
            <div style={{ marginTop: "30px" }}>
              <>
                <VerifyOTP value={otpValue} onChange={handleChangeOTP} />
                <Btn
                  variant="contained"
                  borderRadius="4px"
                  bgColor="#3592FF"
                  color="#fff"
                  width="100%"
                  margin="10px 0px"
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress size={28} /> : "Verify"}
                </Btn>
                <p
                  style={{
                    lineHeight: "20px",
                    marginBottom: "10px",
                    fontSize: "12px",
                  }}
                >
                  *OTP not received? Please refresh your screen to re-generate
                  OTP
                </p>
              </>
            </div>
          </form>
        )}
      </div>
    </>
  );
};
