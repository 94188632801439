import { createSlice } from "@reduxjs/toolkit";
import { API } from "../../../config/constant";
import { token } from "../../../assets/constants";
import instance from "../../../config/axiosConfig";

export const initialState = {
  loading: false,
  hasErrors: false,
  addQuestion: [],
  responseaddQuestion: {},
  taresponseaddQuestion: {},
};

const addQuestionSlice = createSlice({
  name: "addQuestion",
  initialState,
  reducers: {
    postLoading: (state) => {
      state.loading = true;
    },
    getSuccess: (state, { payload }) => {
      state.addQuestion = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    postSuccess: (state, { payload }) => {
      state.responseaddQuestion = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    TApostSuccess: (state, { payload }) => {
      state.taresponseaddQuestion = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    postFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const {
  postLoading,
  postSuccess,
  TApostSuccess,
  postFailure,
  getSuccess,
} = addQuestionSlice.actions;

export const addQuestionSelector = (state) => state.quickAsk;

export default addQuestionSlice.reducer;

//Post Api
let isMounted = true;
const controller = new AbortController();
export function fetchaddQuestionPostdata(form_data) {
  return async (dispatch) => {
    dispatch(postLoading());
    try {
      const response = await instance.post(API.POSTADDQUESTION, form_data);
      const data = response;
      console.log(data.data, "=======errr000rrrrr======================");
      dispatch(postSuccess(data.data));
    } catch (error) {
      dispatch(postFailure());
    }
  };
}

export function fetchaTAddQuestionPostdata(form_data) {
  return async (dispatch) => {
    dispatch(postLoading());
    try {
      const response = await instance.post(
        API.POSTADDQUESTIONTODAYACTIVITY,
        form_data
      );
      const data = response;
      console.log(data.data, "=======errr000rrrrr======================");
      dispatch(postSuccess(data.data));
    } catch (error) {
      dispatch(postFailure());
    }
  };
}
// const postData={
// category_id:1,
// input_type:"Single Choice",
//  question:"Please rate your sleep quality ?",
//  options:"",
// }
//post Api
export function fetchaddQuestionGetdata() {
  return async (dispatch) => {
    dispatch(postLoading());
    try {
      const response = await instance.get(API);
      const data = response;
      console.log(data.data, "=============================");
      dispatch(getSuccess(data.data.list));
    } catch (error) {
      dispatch(postFailure());
    }
  };
}
