import React from "react";
import classes from "../../assets/css/Enterprise/Login/enterpriselogin.module.css";
import LoginImage from "../../assets/icons/LoginImage.svg";
import logo from "../../assets/icons/icon/logo.svg";
import AssessmentStyles from "../../assets/css/assessment.module.css";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { useLayoutEffect } from "react";
import { employeeVerificationPage } from "../../services/enterprise/prelogin";
import { SuccessIcon } from "../Components/Images/successIcon/SuccessIcon";
import { ErrorIcon } from "../Components/Images/errorIcon/ErrorIcon";

export const VerifyEmployee = () => {
  const { employeeId } = useParams();
  const [msg, setMsg] = useState("Verifying...");
  const [verify, setVerify] = useState(false);
  const [invalid, setInvalid] = useState(false);

  useLayoutEffect(() => {
    employeeVerificationPage(employeeId)
      .then((res) => {
        if (res.data.status) {
          setVerify(true);
          setMsg(
            // "Thanks for verifying your account. Please use the assessment link to start your assessment."
            "Thanks for verifying your account. Please check your email for the link to start the assessment."
          );
        } else {
          setMsg("Link is expired, Please try again with new link.");
          setInvalid(true);
        }
      })
      .catch((err) => {
        setMsg("Link is expired, Please try again with new link.");
        setInvalid(true);
      });
  }, []);

  return (
    <div>
      <div
        className={classes.login_container}
        style={{ background: `url(${LoginImage})` }}
      >
        <header className={AssessmentStyles.header}>
          <Link to="/">
            <img className={AssessmentStyles.logo} src={logo} alt="logo" />
          </Link>
        </header>
        <div className={classes.login_subcontainer}>
          {verify && <SuccessIcon />}
          {invalid && <ErrorIcon />}
          <p style={{ lineHeight: "20px", textAlign: "center" }}>{msg}</p>
        </div>
      </div>
    </div>
  );
};
