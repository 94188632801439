import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const { SOCIAL_LOGIN } = API;

export const socialLoginAPI = async (payload, newUser) => {
  return await axios
    .post(
      `${newUser ? "/new/customer/assessment" : "/customer/social/login"}`,
      payload
    )
    .then((res) => res)
    .catch((err) => err);
};

export const userValidation = async (email) => {
  return await axios
    .get(`/get/existing/customer?email=${email}`)
    .then((res) => res)
    .catch((err) => err);
};

export const newSocialUserCreate = async (payload) => {
  return await axios
    .post(`/create/socialCustomer`, payload)
    .then((res) => res)
    .catch((err) => err);
};

export const mobileNoSave = async (payload) => {
  return await axios
    .post(`/customer/update/number`, payload)
    .then((res) => res)
    .catch((err) => err);
};
