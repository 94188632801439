import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import PatientsProfileData from "../../../MockData/PatientProfileMock/PatientProfileMock.json";
import TextWithCard from "../../../Hooks/TextWithCard";
import SwitchButton from "../../../Component/UI/SwitchButton/Switch";
import { findFunc } from "../../../config/methods";

const generalLink = [];
const PatientsProfile = () => {
  const location = useLocation();
  const { name, data, permissions } = location.state;

  console.log(location.state, "location.state------");

  const permissionState = permissions || data?.permissions;
  console.log(permissionState, "permissionState");
  const patientsProfileGeneralPerms = findFunc(
    permissionState?.subCategories,
    "title",
    "general"
  );

  console.log(patientsProfileGeneralPerms, "patientsProfileGeneralPerms---");

  const patientsProfileAppPerms = findFunc(
    permissionState?.subCategories,
    "title",
    "appointments"
  );

  const patientsProfileAssPerms = findFunc(
    permissionState?.subCategories,
    "title",
    "assessments"
  );

  const patientsProfileOthersPerms = findFunc(
    permissionState?.subCategories,
    "title",
    "others"
  );

  console.log(PatientsProfileData, "PatientsProfileData");

  const fullpermissions =
    +permissionState?.listControl?.access?.["Full Control"];

  const general = +patientsProfileGeneralPerms?.status ? (
    <TextWithCard
      textData={"General"}
      cardData={PatientsProfileData.General}
      permissions={patientsProfileGeneralPerms}
    />
  ) : null;
  const appointments = +patientsProfileAppPerms?.status ? (
    <TextWithCard
      textData={"Appointments"}
      cardData={PatientsProfileData.Appointments}
      permissions={patientsProfileAppPerms}
    />
  ) : null;

  const assessments = +patientsProfileAssPerms?.status ? (
    <TextWithCard
      textData={"Assessments"}
      cardData={PatientsProfileData.Assessments}
      permissions={patientsProfileAssPerms}
    />
  ) : null;
  const others = +patientsProfileOthersPerms?.status ? (
    <TextWithCard
      textData={"Others"}
      cardData={PatientsProfileData.Others}
      permissions={patientsProfileOthersPerms}
    />
  ) : null;
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={0} style={{ marginTop: 25 }}>
        <Grid item md={9.5} xs={12}>
          <Typography
            variant="h5"
            component="div"
            gutterBottom
            style={{ fontWeight: 500 }}
          >
            {name || data?.name}
          </Typography>
        </Grid>

        {fullpermissions ? (
          <>
            <Grid item md={1.5} xs={6} style={{ padding: 5 }}>
              <Typography variant="h5" component="div" gutterBottom>
                Deactivate
              </Typography>
            </Grid>
            <Grid item xs={6} md={1}>
              <SwitchButton />
            </Grid>
          </>
        ) : null}

        {general}
        {appointments}
        {assessments}
        {others}
      </Grid>
    </Box>
  );
};

export default PatientsProfile;
