import { createSlice } from "@reduxjs/toolkit";

const QuickAskData = createSlice({
  name: "QuickAskData",
  initialState: {
    radio_OptionId: "",
    selectedTime: "",
  },
  reducers: {
    setRadioOption: (state, { payload }) => {
      state.radio_OptionId = payload;
    },
    setSelectedTime: (state, { payload }) => {
      state.selectedTime = payload;
    },
  },
});

export const { setRadioOption, setSelectedTime } = QuickAskData.actions;

export default QuickAskData.reducer;
