import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setToggle,
  setDate,
} from "../../../../Redux/Slices/Patient/PatienCalender";
import { AiOutlineCalendar } from "react-icons/ai";
import stylesNew from "./calander.module.css";
export const Display = ({ selectedDate, styles }) => {
  const disabled = true;

  const dispatch = useDispatch();
  const { toggle } = useSelector((i) => i.DateFilterSlice);

  useEffect(() => {
    dispatch(setDate(selectedDate));
  }, []);

  return (
    <div
      className={`${styles.display} ${stylesNew.displaySec}`}
      style={{ background: disabled ? "#F9F9F9" : "#fff" }}
      onClick={() => dispatch(setToggle(!toggle))}
    >
      <p
        className={stylesNew.textSec}
        style={{ color: disabled ? "#686868" : "#000" }}
      >
        {selectedDate}
      </p>
      <AiOutlineCalendar color="#00B091" />
      {/* <Calander disabled={disabled} styles={styles} /> */}
    </div>
  );
};
