import React from "react";

import Modal from "@mui/material/Modal";
import { Box } from "@mui/material/index";
import { DementiaScreening } from "../../../Components/Images/DementiaScreening";
import { Cross } from "../../../../assets/icons/Cross";
import { Helpcircle } from "../../../../assets/icons/icon/Helpcircle";
import styles from "./servicedetail.module.css";
import { Btn } from "../../../../Samvenda-Patient/UI/Button";
import moment from "../../../../../node_modules/moment/moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "20px",
  outline: "none",
  boxShadow: 12,
  p: 4,
  overflow: "auto",
};

export const ServiceDetailsPopup = ({
  popup,
  setPopup,
  selectedServiceData,
}) => {
  const getType = (type) => {
    switch (type) {
      case "0":
        return "Offline";
      case "1":
        return "Online";
      case "2":
        return "Hybrid";
      default:
        return "N/A";
    }
  };
  console.log(selectedServiceData, "Servicedata");
  return (
    <>
      <Modal open={popup}>
        <Box sx={style} className={styles.servicemodal}>
          <div className={styles.heading_container}>
            <h3>Service Details</h3>
            <div
              onClick={() => {
                setPopup(false);
              }}
            >
              <Cross color="black" size="30" />
            </div>
          </div>
          <div>
            <div className={styles.rowContainer}>
              <div className={styles.colContainer}>
                <p>Name</p>
                <p className={styles.valueData}>{selectedServiceData?.name}</p>
              </div>
              <div className={styles.colContainer}>
                <p>Amount</p>
                <p className={styles.valueData}>
                  {selectedServiceData?.amount}
                </p>
              </div>
            </div>
            <div className={styles.rowContainer}>
              <div className={styles.colContainer}>
                <p>Business Segment</p>
                <p className={styles.valueData}>
                  {selectedServiceData?.business_segment}
                </p>
              </div>
              <div className={styles.colContainer}>
                <p>Type of Service</p>
                <p className={styles.valueData}>
                  {getType(selectedServiceData?.type_of_service)}
                </p>
              </div>
            </div>

            <div className={styles.rowContainer}>
              <div className={styles.colContainer}>
                <p>Validity</p>
                <p className={styles.valueData}>
                  {selectedServiceData?.validity}
                </p>
              </div>
            </div>

            <div className={styles.rowContainer}>
              <div className={styles.colContainer}>
                <p>Service Type (Assessments / Sessions)</p>
                <p className={styles.valueData}>
                  {selectedServiceData?.enterprise_service_type?.toString() ===
                  "0"
                    ? "Session"
                    : "Assessment"}
                </p>
              </div>
              <div className={styles.colContainer}>
                <p>Number of Assessments / Sessions</p>
                <p className={styles.valueData}>
                  {selectedServiceData?.number_assessment
                    ? selectedServiceData?.number_assessment
                    : 0}
                </p>
              </div>
            </div>

            {selectedServiceData?.enterprise_service_type === "1" && (
              <div className={styles.rowContainer}>
                <div className={styles.colContainer}>
                  <p>Assessment Name</p>
                  <p className={styles.valueData}>
                    {selectedServiceData?.assessment_name}
                  </p>
                </div>
              </div>
            )}
            <div className={styles.rowContainer}>
              <div className={styles.colContainer}>
                <p>Tax Applicable</p>
                <p className={styles.valueData}>
                  {selectedServiceData?.tax_applicable ? "Yes" : "No"}
                </p>
              </div>
              {selectedServiceData?.expiry_date ? (
                <div className={styles.colContainer}>
                  <p>Expiry Date</p>
                  <p className={styles.valueData}>
                    {moment(selectedServiceData?.expiry_date).format(
                      "DD/MM/YYYY"
                    )}
                  </p>
                </div>
              ) : null}
              {/*  */}
            </div>

            <div className={styles.rowContainer}>
              <div>
                <p style={{ margin: "10px 0" }}>Description</p>
                <p className={styles.valueData}>
                  {selectedServiceData?.description}
                </p>
              </div>
            </div>
            {/* <Btn
              variant="contained"
              borderRadius="8px"
              bgColor="#3592FF"
              width="100px"
              height="36px"
              click={() => {
                alert("requested");
              }}
              margin="10px 0px"
            >
              Request
            </Btn> */}
          </div>
        </Box>
      </Modal>
    </>
  );
};
