import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "../../../../../node_modules/react-router-dom/index";
import SimpleBreadcrum from "../../../../Component/UI/SimpleBreadcrum";
import BasicTabs from "../../../UI/Tab/Tabs";
import { AcceptedTestimonial } from "../../SamvednaNew/Testimonial/TabComponents/AcceptedTestimonial";
import { NewTestimonial } from "../../SamvednaNew/Testimonial/TabComponents/NewTestimonial";
import { RejectedTestimonial } from "../../SamvednaNew/Testimonial/TabComponents/RejectedTestimonial";
import { usePermissions } from "../../../../Hooks/usePermissions";

export const PatientTestimonials = () => {
  const { newTestimonials } = useSelector((i) => i.adminTestimonial);
  const [count, setCount] = useState(0);
  const { state } = useLocation();
  const permissions = usePermissions("name", "patients");

  const tabData = [
    {
      label: `New Testimonials (${newTestimonials.length})`,
      component: <NewTestimonial pFeedback={true} customerId={state?.props} />,
    },
    {
      label: "Accepted",
      component: (
        <AcceptedTestimonial pFeedback={true} customerId={state?.props} />
      ),
    },
    {
      label: "Rejected",
      component: (
        <RejectedTestimonial pFeedback={true} customerId={state?.props} />
      ),
    },
  ];

  const links = [
    {
      path: "/Patients",
      linkText: "Patients",
    },
    {
      path: "/patientsProfile",
      linkText: state?.name,
      data: { name: state?.name, props: state?.props, permissions },
    },
  ];

  return (
    <div>
      <SimpleBreadcrum text="Others" title="Testimonials" link={links} />
      <BasicTabs
        tabcontent={tabData}
        value={count}
        setValue={setCount}
        fullWidth
      />
    </div>
  );
};
