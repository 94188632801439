import { createSlice } from "@reduxjs/toolkit";

const typeAppointmentsSlice = createSlice({
  name: "get appointment",
  initialState: { upcoming: [], completed: [], cancelled: [] },
  reducers: {
    upcomingAppointments: (state, action) => {
      state.upcoming = action.payload;
    },

    completedAppointments: (state, action) => {
      state.completed = action.payload;
    },

    cancelledAppointments: (state, action) => {
      state.cancelled = action.payload;
    },

    cancelledAppointmentRed: (state, action) => {
      state.cancelled = action.payload;
    },

    
  },
});

export const {
  upcomingAppointments,
  cancelledAppointmentRed,
  completedAppointments,
  cancelledAppointments,
} = typeAppointmentsSlice.actions;

export default typeAppointmentsSlice.reducer;
