import React from "react";
import { useState } from "react";
import Assessments from "./Assessments";
import { SubHeader } from "../../UI/SubHeader";
import styles from "../../../assets/css/layout/layout.module.css";
import Plans from "./Plans";

const AssessmentDashboard = () => {
  const items = [
    {
      label: "Assessments",
      component: <Assessments />,
    },
    {
      label: "Enterprise Assessments",
      component: <Plans />,
    },
  ];
  return (
    <div className={styles.assesmentmain}>
      <>
        <SubHeader tab={items} calendar={true} />
      </>
    </div>
  );
};

export default AssessmentDashboard;
