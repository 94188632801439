import React from "react";
import { ChangePassword } from "./ChangePassword";
import { Toaster } from "../../../../node_modules/react-hot-toast/dist/index";
import AllTabs from "../../../Component/UI/Tabs/AllTabs";
import ButtonName from "../../../MockData/ButtonName.json";
import { useState } from "react";
import { Profile } from "./Profile";

export const MyAccount = () => {
  const btnApi = ButtonName.MyAccount;
  let defaultTab = Object.keys(btnApi[0]);
  const [tabID, selectedTabID] = useState(defaultTab[0]);

  const ComponentName = [<Profile />, <ChangePassword />];
  return (
    <>
      <Toaster />
      <div>
        {/* <ChangePassword /> */}
        <AllTabs
          ComponentName={ComponentName}
          btnApi={btnApi}
          selectedTabID={selectedTabID}
        />
      </div>
    </>
  );
};
