import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Btn } from "../../../../Samvenda-Patient/UI/Button";
import { Input } from "../../../UI/Input";
import { toast } from "../../../../../node_modules/react-hot-toast/dist/index";
import CopyUrl from '../../../../assets/images/copyURL.svg'
import copy from "copy-to-clipboard";  
import { useState } from "react";
import { CircularProgress } from "../../../../../node_modules/@mui/material/index";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 440,
  height: 'auto',
  bgcolor: "background.paper",
  outline: "none",
  borderRadius: "20px",
  boxShadow: 12,
  p: '15px',
};

const AppointmentIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none">
    <path
      d="M8 26.667H56"
      stroke="#2A2A2A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M50.6667 10.667H13.3333C10.3878 10.667 8 13.0548 8 16.0003V53.3337C8 56.2792 10.3878 58.667 13.3333 58.667H50.6667C53.6122 58.667 56 56.2792 56 53.3337V16.0003C56 13.0548 53.6122 10.667 50.6667 10.667Z"
      stroke="#2A2A2A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M42.6666 5.33301V15.9997"
      stroke="#2A2A2A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.3334 5.33301V15.9997"
      stroke="#2A2A2A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export function CompletePopup({
  popupAction,
  setPopupAction,
  title,
  details,
  styles,
  btnMargin,
  loading
}) {
  const handleClose = () => setPopupAction({ ...popupAction, open: false });
  const currentUrl = window.location.origin.toString()
  const [text, setText] = React.useState(`${currentUrl}/user/signin/?feedback?counsellor`);


  const copyToClipboard = () => {
    copy(text);
    toast.success('Text copied');
 }

  console.log(currentUrl, 'currentUrl')

  return (
    <div>
      <Modal
        open={popupAction.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{
            display: "flex",
          
            justifyContent: "center",
            ...styles,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between"
             
            }}
          >
            <div style={{textAlign: "center"}}>
             <AppointmentIcon />
            </div>
            {/* <h2 style={{ width: "-webkit-fill-available", marginLeft: "12px" }}>
              {popupAction.name}
            </h2> */}
            <p
              style={{
                fontSize: "19px",
                fontWeight: "500",
                margin: "0 0 5px 0",
                textAlign: "left",
                lineHeight: "24px",
              }}
            >
              {title}
            </p>
            <p
            style={{
              fontSize: "13px",
              lineHeight: "20px",
              marginBottom: "10px"
            }}
            >{details}</p>

            <div  style={{ color: "#000000", position: "relative", marginBottom: "20px" }}>
            <Input
               height={"40px"}
              
               label="Feedback URL"
               placeholder = {text}
               defaultValue={text}
               disabled
              
             
            />
            <button 
              style={{
                position: "absolute",
                top: "40px",
                right: "5px",
                background: '#fff',
                border: '0px',
                cursor: 'pointer',
                color: "#00B091",
                height: "23px",
                width: "70px"

              }}
              onClick={copyToClipboard} disabled={!text}><img src= {CopyUrl} alt="copy"/> Copy</button>
             
            {/* {link} */}
            </div>

            {
            loading &&
            <div style={{ textAlign: "center" }}>
          <CircularProgress size={30} />
          <p style={{ color: "black" }}>
            Please wait ! We are validating the details...
          </p>
        </div>
        }
            <div
              style={{
               textAlign: "right",
                margin: "4px 0",
              }}
            >
              <Btn
                bgColor="#2A2A2A"
                color="#FFF"
                width="100px"
                height="40px"
                variant="contained"
                fontWeight="400"
                borderRadius="100px"
                margin={btnMargin}
                marginRight= '10px'
                click={handleClose}
              >
                Cancel
              </Btn>
              <Btn
                bgColor="#00B091"
                variant="contained"
                width="100px"
                fontWeight="400"
                height="40px"
                borderRadius="100px"
                margin={btnMargin}
                click={popupAction.action}
              >
                Yes
              </Btn>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
