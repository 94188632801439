import styles from "../../assets/css/MyAccount/health.module.css";
import { Input, InputWithText, Select } from "../../Component/UI/CustomInputs";
import { Btn } from "../UI/Button";
import { MyAccount } from "../../HOC/MyAccount";
import { useEffect, useState } from "react";
import { editHealth, getHealth } from "../../services/patient/health";
import toast from "react-hot-toast";

const Health = () => {
  const inputData = [
    { name: "height", label: "Height (in foot & inches)" },
    { name: "weight", label: "Weight" },
    { name: "bmi", label: "BMI" },
    { name: "amount_of_execise", label: "Amount of Exercise (in a week)" },
  ];

  const [health, setHealth] = useState({
    height: "",
    weight: "",
    bmi: "",
    amount_of_execise: "",
  });

  const [bmiColor, setBmiColor] = useState("");
  const [bmiText, setBmiText] = useState("");

  useEffect(() => {
    if (health.bmi < 18.5999) {
      setBmiText("Under weight");
      setBmiColor("#87b1d9");
    } else if (health.bmi >= 18.5999 && health.bmi <= 24.9999) {
      setBmiText("Normal");
      setBmiColor("#3dd365");
    } else if (health.bmi >= 25 && health.bmi <= 29.9999) {
      setBmiText("Over weight");
      setBmiColor("#eee133");
    } else if (health.bmi >= 30 && health.bmi <= 34.9999) {
      setBmiText("Morbidly Obese");
      setBmiColor("#fd802e");
    } else if (health.bmi >= 35) {
      setBmiText("Severely Obese");
      setBmiColor("#f95353");
    }
  }, [health.bmi]);

  useEffect(() => {
    const finalHeightInch =
      Number(health.height?.split(".")?.[0] * 12) +
      Number(health.height?.split(".")?.[1] || 0);
    const finalHeight = finalHeightInch * 2.54;
    const BmiCalc = health.weight / (finalHeight * 0.01) ** 2;

    setHealth({ ...health, bmi: BmiCalc.toFixed(2) });
  }, [health.height, health.weight]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setHealth({ ...health, [name]: value });
  };

  const handleClick = () => {
    editHealth(health)
      .then((res) => {
        // console.log(res);
        if (res?.data?.status) {
          toast.success(res?.data?.message);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getHealth()
      .then((res) => setHealth(res?.data?.list))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className={styles.health}>
      <div>
        <h2>Health Profile</h2>
        {/* <div>
          {inputData.map(({ label, name }) => {
            return label === "BMI" ? (
              <InputWithText
                name={name}
                type="number"
                value={health?.[name]}
                dot={bmiColor}
                bmiText={bmiText}
                label={label}
                onChange={handleChange}
              />
            ) : (
              <Input
                name={name}
                value={health?.[name]}
                label={label}
                onChange={handleChange}
              />
            );
          })}
        </div> */}
        <div>
          <Input
            name={"height"}
            value={health?.["height"]}
            label={"Height (in foot & inches)"}
            onChange={handleChange}
          />
          <Input
            name={"weight"}
            value={health?.["weight"]}
            label={"Weight (in Kg's)"}
            onChange={handleChange}
          />
          <InputWithText
            name={"bmi"}
            type="number"
            value={health?.["bmi"]}
            dot={bmiColor}
            bmiText={bmiText}
            label={"BMI"}
            onChange={handleChange}
          />
          <Select
            margin="8px 10px"
            name="amount_of_execise"
            options={[
              { id: 1, value: "Less than once" },
              { id: 2, value: "At least 3 times a week" },
              { id: 3, value: "At least 5 days a week" },
            ]}
            label="Amount of Exercise (in a week)"
            value={health?.["amount_of_execise"]}
            onChange={handleChange}
            // req
          />
          {/* <Input
            name={"amount_of_execise"}
            value={health?.["amount_of_execise"]}
            label={"Amount of Exercise (in a week)"}
            onChange={handleChange}
          /> */}
        </div>
      </div>
      <div className={styles.btn}>
        <Btn
          variant="contained"
          width="160px"
          height="48px"
          click={handleClick}
        >
          Update
        </Btn>
      </div>
    </div>
  );
};

export const HealthHOC = MyAccount(Health);
