import classes from "./paientSearch.module.css";
import { AiOutlineSearch } from "react-icons/ai";
import {
  setSearchText,
  setToggleSearch,
} from "../../Redux/Slices/patientSearch";
import { useDispatch, useSelector } from "react-redux";
import OutsideAlerter from "../../Hooks/useOutsideAlerter";
import { useRef } from "react";

export const PatientSearch = () => {
  const dispatch = useDispatch();
  const { searchText, toggleSearch } = useSelector((i) => i.patientSearch);
  const pateintRef = useRef(null);

  const outsideClick = () => {
    dispatch(setToggleSearch(false));
  };

  return (
    <>
      <OutsideAlerter customfunc={outsideClick} wrapperRef={pateintRef}>
        <div
          className={[classes.search_bar, classes.search_bar_height].join(" ")}
        >
          <AiOutlineSearch />
          <input
            type="text"
            placeholder="Search"
            value={searchText}
            onChange={(e) => dispatch(setSearchText(e.target.value))}
          />
        </div>
      </OutsideAlerter>
    </>
  );
};
