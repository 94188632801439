import { useEffect, useState } from "react";
import { CustomTable } from "../../../../Component/UI/Table";
import { getSelfAssessment } from "../../../../services/admin/patient";
import { Avatar } from "../../../../Component/UI/Avatar";
import { formatttedDate } from "../../../../config/formattedDate";
import styles from "./selfassessment.module.css";

export const Ongoing = ({ id }) => {
  const [ongoing, setOngoing] = useState([]);
  const tHead = ["Assessment Date", "Assessment Name", "Completion Status"];
  useEffect(() => {
    getSelfAssessment(id, "inprogress").then((res) =>
      setOngoing(res?.data?.list)
    );
  }, []);

  const tBodyData = ongoing?.map(({ name, assessment_image, customer }) => {
    if (!customer) return {};
    return {
      date: (
        <p className={styles.tableData}>
          {formatttedDate(customer?.updated_at)}
        </p>
      ),
      name: (
        <span className={styles.avatar}>
          {<Avatar size="28px" profile={assessment_image} />}
          <span className={styles.tableData} style={{ marginLeft: "8px" }}>
            {name}
          </span>
        </span>
      ),
      status: (
        <p className={styles.tableData} style={{ color: "#00B091" }}>
          {Math.trunc(customer?.percentage)}%
        </p>
      ),
    };
  });

  return (
    <CustomTable
      tHead={tHead}
      tBody={tBodyData}
      message={"This patient has no ongoing assessment."}
    />
  );
};
