import React from "react";
import CustomizedAccordions from "./Components/SubscriptionAccordian";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
export const ViewPackageSubscription = ({ styles }) => {
  const data = [
    {
      Title: "Counselling",
      tHead: [
        {
          planeName: "Plan name",
          month: {
            oneMonth: "1 Month",
            threeMonth: "3 month",
            sixMonth: "6 Month",
            twelveMonth: "12 Month",
          },
        },
      ],
      content: [
        {
          planName: "Supportive Counselling",
          oneMonth: {
            price: "6000",
            session: "4",
            month : '1 Month'
          },
          threeMonth: {
            price: "18000",
            session: "12",
            month : '3 Month'
          },
          sixMonth: {
            price: "34200",
            session: "24",
            month : '6 Month'
          },
          twelveMonth: {
            price: "64800",
            session: "48",
            month : '12 Month'
          },
        },
        {
          planName: "Relationship Counselling",
          oneMonth: {
            price: "6000",
            session: "4",
            month : '1 Month'
          },
          threeMonth: {
            price: "18000",
            session: "12",
            month : '3 Month'
          },
          sixMonth: {
            price: "34200",
            session: "24",
            month : '6 Month'
          },
          twelveMonth: {
            price: "64800",
            session: "48",
            month : '12 Month'
          },
        },

        {
          planName: "Caregiver Counselling",
          oneMonth: {
            price: "6000",
            session: "4",
            month : '1 Month'
          },
          threeMonth: {
            price: "18000",
            session: "12",
            month : '3'
          },
          sixMonth: {
            price: "34200",
            session: "24",
            month : '6 Month'
          },
          twelveMonth: {
            price: "64800",
            session: "48",
            month : '12 Month'
          },
        },
        {
          planName: "Cancer and Long Term illness",
          oneMonth: {
            price: "6000",
            session: "4",
            month : '1 Month'
          },
          threeMonth: {
            price: "18000",
            session: "12",
            month : '3 Month'
          },
          sixMonth: {
            price: "34200",
            session: "24",
            month : '6 Month'
          },
          twelveMonth: {
            price: "64800",
            session: "48",
            month : '12 Month'
          },
        },
        {
          planName: "Lifestyle Counselling",
          oneMonth: {
            price: "6000",
            session: "4",
            month : '1 Month'
          },
          threeMonth: {
            price: "18000",
            session: "12",
            month : '3 Month'
          },
          sixMonth: {
            price: "34200",
            session: "24",
            month : '6 Month'
          },
          twelveMonth: {
            price: "64800",
            session: "48",
            month : '12 Month'
          },
        },
        {
          planName: "Family Counselling",
          oneMonth: {
            price: "6000",
            session: "4",
            month : '1 Month'
          },
          threeMonth: {
            price: "18000",
            session: "12",
            month : '3 Month'
          },
          sixMonth: {
            price: "34200",
            session: "24",
            month : '6 Month'
          },
          twelveMonth: {
            price: "64800",
            session: "48",
            month : '12 Month'
          },
        },
      ],
    },
    {
      Title: "Wellbeing Care",
      tHead: [
        {
          planeName: "Plan name",
          month: {
            oneMonth: "1 Month",
            threeMonth: "3 month",
            sixMonth: "6 Month",
            twelveMonth: "12 Month",
          },
        },
      ],
      content: [
        {
          planName: "Mental Wellbeing Self care",
          oneMonth: {
            price: "1500",
            session: "1",
            month : '1 Month'
          },
          threeMonth: {
            price: "4500",
            session: "3",
            month : '3 Month'
          },
          sixMonth: {
            price: "8550",
            session: "6",
            month : '6 Month'
          },
          twelveMonth: {
            price: "16200",
            session: "12",
            month : '12 Month'
          },
        },
        {
          planName: "Caregiver Self Care",
          oneMonth: {
            price: "8000",
            session: "4",
            month : '1 Month'
          },
          threeMonth: {
            price: "24000",
            session: "12",
            month : '3 Month'
          },
          sixMonth: {
            price: "45600",
            session: "24",
            month : '6 Month'
          },
          twelveMonth: {
            price: "86400",
            session: "48",
            month : '12 Month'
          },
        },
        {
          planName: "Psychotherapy",
          oneMonth: {
            price: "8000",
            session: "4",
            month : '1 Month'
          },
          threeMonth: {
            price: "24000",
            session: "12",
            month : '3 Month'
          },
          sixMonth: {
            price: "45600",
            session: "24",
            month : '6 Month'
          },
          twelveMonth: {
            price: "86400",
            session: "48",
            month : '12 Month'
          },
        },
        {
          planName: "Dementia Care Plans",
          oneMonth: {
            price: "8000",
            session: "4",
            month : '1 Month'
          },
          threeMonth: {
            price: "24000",
            session: "12",
            month : '3 Month'
          },
          sixMonth: {
            price: "45600",
            session: "24",
            month : '6 Month'
          },
          twelveMonth: {
            price: "86400",
            session: "48",
            month : '12 Month'
          },
        },
      ],
    },

    {
      Title: "Caregiver Services",
      tHead: [
        {
          planeName: "Plan name",
          month: {
            oneMonth: "7 days",
            threeMonth: "15 days",
            sixMonth: "30 Days",
          },
        },
      ],
      content: [
        {
          planName: "Attendant Care (12 Hours)",
          oneMonth: {
            price: "7000",
            session: "",
            month : '7 Days'
          },
          threeMonth: {
            price: "13500",
            session: "",
            month : '15 Days'
          },
          sixMonth: {
            price: "24000",
            session: "",
            month : '30 days'
          },
          twelveMonth: {
            price: "",
            session: "",
            month : ''
          },
        },
        {
          planName: "Attendant Care (24 Hours)",
          oneMonth: {
            price: "8400",
            session: "",
            month : '7 Days'
          },
          threeMonth: {
            price: "16500",
            session: "",
            month : '15 Days'
          },
          sixMonth: {
            price: "33000",
            session: "",
            month : '30 Days'
          },
          twelveMonth: {
            price: "",
            session: "",
            month : ''
          },
        },
        {
          planName: "Nursing Services (12 Hours)",
          oneMonth: {
            price: "12600",
            session: "",
            month : '7 Days'
          },
          threeMonth: {
            price: "24000",
            session: "",
            month : '15 Days'
          },
          sixMonth: {
            price: "50000",
            session: "",
            month : '30 Days'
          },
          twelveMonth: {
            price: "",
            session: "",
            month : ''
          },
        },
        {
          planName: "Nursing Services (24 Hours)",
          oneMonth: {
            price: "17500",
            session: "",
            month : '7 Days'
          },
          threeMonth: {
            price: "35000",
            session: "",
            month : '15 Days'
          },
          sixMonth: {
            price: "70000",
            session: "",
            month : ' Days'
          },
          twelveMonth: {
            price: "",
            session: "",
            month : ''
          },
        },
      ],
    },
  ];

  return (
    <div className={styles.viewPackageSubscription}>
      <h3>View Packages/Subscriptions</h3>
      <CustomizedAccordions styles={styles} data={data} />
    </div>
  );
};
