import React, { Component } from "react";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import PaperWrapper from "../PaperWrapper/PaperWrapper";
import Text_Seach_Filter_Export from "../../../Hooks/Text_Seach_Filter_Export";
import AllTabs from "./AllTabs";

export default function CustomizedTabs({ ComponentName, btnApi, text, route }) {
  return (
    <Text_Seach_Filter_Export text={text} route={route}>
      <AllTabs ComponentName={ComponentName} btnApi={btnApi} />
    </Text_Seach_Filter_Export>
  );
}
