import React, { useEffect, useState } from "react";
// import Classes from '../../../assets/css/assessment/checkbox.module.css';
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  selectMultiOption,
  selectMultiScore,
} from "../../../../Redux/Reducers/PatientReducers/qa_storage/qa_storage";

const Label = { inputProps: { "aria-label": "Checkbox demo" } };

export const CheckBox = (props) => {
  const { label, id, question } = props;
  const dispatch = useDispatch();
  const { multiOption, multiScore } = useSelector((state) => state.qa_storage);

  const [check, setCheck] = useState(false);

  const checkboxCSS = {
    border: check ? "2px solid #7A51AE" : "2px solid #DADADA",
    background: check ? "#7a51ae50" : "",
    borderRadius: "8px",
    width: "fit-content",
    minWidth: "220px",
    height: "48px",
    color: "#2a2a2a",
    marginTop: "8px",
  };

  useEffect(() => {
    props.default === id ? setCheck(true) : setCheck(false);
  }, [props.default]);

  const handleClick = (id) => {
    setCheck(!check);
    let QuestionOptions = question.options.split(",");
    let ind = QuestionOptions.indexOf(id);
    const score = question.scores.split(",")[ind];
    let a = [...multiOption];
    let b = [...multiScore];

    if (id.toLowerCase().includes("none") && !a.includes(id)) {
      a = [id];
      b = ["0"];
      dispatch(selectMultiOption(a));
      dispatch(selectMultiScore(b));
      //console.log({ a, b });
    } else {
      if (a.includes(id)) {
        const indx = a.indexOf(id);
        a.splice(indx, 1);
        b.splice(indx, 1);
        dispatch(selectMultiOption(a));
        dispatch(selectMultiScore(b));
      } else {
        a.push(id);
        b.push(score);
        if (a[0].toLowerCase().includes("none")) {
          a.shift();
          b.shift();
        }
        dispatch(selectMultiOption(a));
        dispatch(selectMultiScore(b));
      }
    }
  };

  return (
    <>
      <div id={id} style={checkboxCSS} onClick={() => handleClick(id)}>
        <Checkbox
          {...Label}
          checked={check}
          sx={{
            color: "#DADADA",
            "&.Mui-checked": {
              color: "#7A51AE",
            },
          }}
        />
        <label>{label}</label>
      </div>
    </>
  );
};
