import React from "react";

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Avatar from '@mui/material/Avatar';
import {AiOutlineStar} from 'react-icons/ai';
import {BsCheckLg} from 'react-icons/bs';
import {GrClose} from 'react-icons/gr';
import {TbEdit} from 'react-icons/tb'

import classes from "../assets/css/table/Table.module.css";
import SwitchButton from "../Component/UI/SwitchButton/Switch";
import { Link } from "react-router-dom";

const TableRows = ({ rowData, columnData }) => {
  const pathname = window.location.pathname;
  const Patients = pathname === '/Patients'
  const assesment = pathname === '/Assessments'
  const testimonials = pathname === "/testimonials"
  const experts = pathname === "/experts"
  const ContentTag = Patients || assesment ? Link : 'div';

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 35,
        height: 35,
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  //Toggle Part
  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <TableRow
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      {columnData.map((item,id) => {
      return <> 
        <TableCell align="left" key={id}>
          <ContentTag className={classes.tbcell} to={item === "Patient_Name" ? "/patientProfile" : (item === 'Category' && assesment) && '/addselfcaretips'} state={{
          from:rowData.Patient_Name,
          key:id
        }}
        style={{ textDecoration:"none", color:'black'}}>

            {(item === "Organisation_Name" ||item === "client"||item === "Patient_Name" || item === "Assessement_Name" || item === "")&& <Avatar alt="Remy Sharp" {...stringAvatar("Hello World")}/>}

            {item === "Rating" &&<><AiOutlineStar/><AiOutlineStar/><AiOutlineStar/><AiOutlineStar/></> }

            {(testimonials && item==="action") &&<BsCheckLg/>}

            {(item === "status"|| item==="expert") && <SwitchButton/>}

            {(item === "action" && experts) && <GrClose/>}

            {item === "action" && <TbEdit className={classes.edit}/>}

            {rowData[item]}
          </ContentTag>
        </TableCell>
      {/* </ContentTag> */}
    </>
    })}
    </TableRow>
  );
};

export default TableRows;
