import { createSlice } from "@reduxjs/toolkit";

const bookedAppointmentsSlice = createSlice({
  name: "get client",
  initialState: { appointments: [] },
  reducers: {
    bookedAppointments: (state, action) => {
      state.appointments = action.payload;
    },
  },
});

export const { bookedAppointments } = bookedAppointmentsSlice.actions;

export default bookedAppointmentsSlice.reducer;
