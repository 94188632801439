import React from "react";
import { LinkedInEmbed } from "react-social-media-embed";
import { InstagramEmbed } from "../../../../node_modules/react-social-media-embed/dist/index";

export const SocialMedia = () => {
  return (
    <div>
      <div style={{ display: "flex", gap: "30px", margin: "0 20px" }}>
        <LinkedInEmbed
          height="400px"
          borderRadius="0"
          url="https://www.linkedin.com/embed/feed/update/urn:li:share:6898694772484112384"
        />
        <InstagramEmbed
          overflow-y="scroll"
          height="400px"
          borderRadius="0"
          url="https://www.instagram.com/p/CxhbH-LvOx2/"
        />
      </div>
    </div>
  );
};
