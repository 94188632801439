import React, { Fragment, useState } from "react";
import { CircularProgress } from "../../../../../../node_modules/@mui/material/index";
import { Avatar } from "../../../../../Component/UI/Avatar";
import { Input } from "../../../../../Component/UI/CustomInputs";
import styles from "./tab.module.css";
import { Permissions } from "../Components/Permissions";
import { Select } from "../../../../../Component/UI/CustomInputs";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  initialLoadData,
  updateData,
} from "../../../../../Redux/Slices/RolesAndPermissions/updateRolesAndPerms";

export const Tab = ({
  setSelectedCategoriesId,
  selectedCategoriesId,
  rolesNames,
  setSelectedRoles,
  selectedRoles,
  isLoadingSave,
  isLoading,
  rolesAndPermsSet,
}) => {
  const dispatch = useDispatch();
  const rolesNameData = rolesNames?.map((i) => {
    return {
      id: i?.id,
      value: i?.name,
    };
  });

  useEffect(() => {
    const selectedRolesData = rolesNames?.find((k) => k?.id === +selectedRoles);
    const rolesAndPerms = selectedRolesData?.permission ?? "[]";
    const rolesAndPermsParse = JSON.parse(rolesAndPerms);
    dispatch(updateData(rolesAndPermsParse?.categories));
  }, [selectedRoles]);
  const { data: permissions } = useSelector(
    (state) => state.updateRolesAndPerms
  );

  const selectedData = permissions?.find(
    (e) => e?.id === +selectedCategoriesId
  );

  return (
    <div className={styles.mainTab}>
      <div className={styles.staffListLeft}>
        <div className={styles.staffSearch}>
          <div
            style={{
              marginBottom: "5px",
              lineHeight: "16px",
              fontSize: "14px",
              letterSpacing: "0.02857em",
            }}
          >
            Roles
          </div>
          <Select
            name="roles"
            width="100%"
            fontSize={15}
            marginTop={0}
            labelSize="14px"
            // error={err?.staff_id}
            options={rolesNameData}
            onChange={(e) => {
              var selectedId = e.target.value;
              setSelectedRoles(selectedId);
            }}
            value={selectedRoles}
            req
          />
        </div>
        {permissions?.length ? (
          <div className={styles.staffListdata}>
            <div className={styles.toggle}>
              {/* {isLoading && (
              <div style={{ textAlign: "center" }}>
                <CircularProgress size={30} />
              </div>
            )} */}
              <div
                style={{
                  marginBottom: "5px",
                  lineHeight: "16px",
                  fontSize: "14px",
                  letterSpacing: "0.02857em",
                  padding: "5px 24px",
                  marginTop: "5px",
                }}
              >
                Category
              </div>
              <div className={styles.scrollbar} id={styles.styleId}>
                {permissions?.map((item, i) => {
                  return (
                    <Fragment key={i}>
                      <div
                        className={`${
                          selectedCategoriesId === item?.id
                            ? styles.selected
                            : null
                        }  ${styles.slider}`}
                        onClick={() => {
                          setSelectedCategoriesId(item?.id);
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: 0,
                          }}
                        >
                          {/* {
                          <Avatar
                            size="28px"
                            profile={item?.staff_image}
                            border="1px solid #ccc"
                          />
                        } */}
                          <span className={styles.categoryName}>
                            {item?.name}
                          </span>
                        </span>
                      </div>
                    </Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.noDataFound}>No data found</div>
        )}
      </div>

      <div className={styles.staffListRight}>
        {permissions?.length ? (
          <>
            {isLoadingSave || isLoading ? (
              <div style={{ textAlign: "center", padding: "10px 0" }}>
                <CircularProgress size={30} />
                <p style={{ color: "black" }}>
                  Please wait ! We are validating the details...
                </p>
              </div>
            ) : null}
            <Permissions
              rolesAndPermsSet={rolesAndPermsSet}
              selectedData={selectedData}
              permissions={permissions}
            />
          </>
        ) : (
          <div className={styles.noDataFound}>No data found</div>
        )}
      </div>
    </div>
  );
};
