import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

export const Buttons = (props) => {
  return (
    <Button
      onClick={props.click}
      variant="contained"
      size="small"
      backgroundColor={props.background}
      disabled={props.disabled}
      style={{ textTransform: "none", ...props.style }}
    >
      {props.name}
    </Button>
  );
};
