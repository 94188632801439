import React, { useContext, useEffect } from "react";
import { Datepicker, Input, Select } from "../../../Component/UI/CustomInputs";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import logo from "../../../assets/icon/logo.svg";
import { Paper } from "@mui/material/index";
import { UploadProfile } from "../../../Component/UI/UploadProfile";
import { newDateFormat, StringDtoY } from "../../../config/formattedDate";
import styles from "./account.module.css";
import { useState } from "react";
import {
  getEnterpriseDesignation,
  getEnterpriseindustry,
  getEnterpriseProfile,
  updateEnterpriseProfile,
} from "../../../services/enterprise/account";
import {
  toast,
  Toaster,
} from "../../../../node_modules/react-hot-toast/dist/index";
import AuthContext from "../../../Auth/AuthContext";
import { useAuth } from "../../../Auth/AuthProvider";

export const General = () => {
  const [userProfile, setUserProfile] = useState();
  const [err, setErr] = useState();
  const [industryOptions, setIndustryOptions] = useState();
  const [designationOptions, setDesignationOptions] = useState();
  const rgx = /^(?:[0-9+]+)?$/;

  const ctx = useContext(AuthContext);
  const getCtxData = useAuth();

  const getProfileApi = () =>
    getEnterpriseProfile()
      .then((res) => {
        if (res.data.status) {
          setUserProfile(res.data.list);
          ctx.auth({
            ...getCtxData.loginUser,
            enterprise: {
              ...getCtxData.loginUser.enterprise,
              lead: {
                ...getCtxData.loginUser.enterprise.lead,
                img_url: res.data.list.img_url,
                company_name: res.data.list.company_name,
              },
            },
          });
        }
      })
      .catch((err) => console.log(err));

  useEffect(() => {
    getProfileApi();

    getEnterpriseindustry().then((res) => {
      let result = res.data.list.map((item) => {
        return {
          ...item,
          value: item.name,
        };
      });

      setIndustryOptions(result);
    });

    getEnterpriseDesignation().then((res) => {
      let result = res.data.list.map((item) => {
        return {
          ...item,
          value: item.name,
        };
      });
      setDesignationOptions(result);
    });

    return () => {
      toast.remove();
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserProfile({ ...userProfile, [name]: value });
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    setErr({});
    const formData = new FormData();
    for (let key in userProfile) formData.append(key, userProfile[key]);

    updateEnterpriseProfile(formData)
      .then((res) => {
        let response = res.data;
        if (response.status) {
          toast.success(response.msg);
          setErr({});
          getProfileApi();
        } else {
          setErr(response.error);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Toaster />
      <Paper elevation={1} className={styles.paper}>
        <form onSubmit={handlesubmit}>
          <div className={styles.mainContainer}>
            <div className={styles.inputContainer}>
              <div className={styles.groupContainer}>
                <Select
                  label="Industry"
                  name="industry"
                  labelSize="14px"
                  margin="10px 50px 10px 10px"
                  width="300px"
                  options={industryOptions}
                  error={err?.industry}
                  value={userProfile?.industry}
                  onChange={handleChange}
                  req
                />
                <Input
                  label="Company Name"
                  labelSize="14px"
                  margin="10px 50px 10px 10px"
                  width="300px"
                  name="company_name"
                  value={userProfile?.company_name}
                  error={err?.company_name}
                  onChange={handleChange}
                  req
                />
              </div>
              <div className={styles.groupContainer}>
                <Input
                  label="Your Name"
                  name="name"
                  labelSize="14px"
                  margin="10px 50px 10px 10px"
                  width="300px"
                  value={userProfile?.name}
                  error={err?.name}
                  onChange={handleChange}
                  req
                />
                <Select
                  label="Designation"
                  name="designation"
                  labelSize="14px"
                  margin="10px 50px 10px 10px"
                  width="300px"
                  options={designationOptions}
                  error={err?.designation}
                  value={userProfile?.designation}
                  onChange={handleChange}
                  req
                />
              </div>
              <div className={styles.groupContainer}>
                <Input
                  label="Work Email"
                  name="email"
                  labelSize="14px"
                  margin="10px 50px 10px 10px"
                  width="300px"
                  value={userProfile?.email}
                  error={err?.email}
                  onChange={handleChange}
                  req
                />
                <Input
                  type="text"
                  label="Mobile Number"
                  name="mobile"
                  labelSize="14px"
                  margin="10px 50px 10px 10px"
                  width="300px"
                  value={userProfile?.mobile}
                  error={err?.mobile}
                  onChange={(e) => {
                    const isNum = rgx.test(e.target.value);
                    isNum &&
                      setUserProfile({
                        ...userProfile,
                        mobile: e.target.value,
                      });
                  }}
                  // onChange={handleChange}
                  req
                />
              </div>
              <div className={styles.groupContainer}>
                <Input
                  type="number"
                  label="Total no. of Employees"
                  name="total_employee"
                  labelSize="14px"
                  margin="10px 50px 10px 10px"
                  width="300px"
                  value={userProfile?.total_employee}
                  error={err?.total_employee}
                  onChange={handleChange}
                />

                <Datepicker
                  label="Joined on"
                  name="joined_on"
                  labelSize="14px"
                  margin="10px 50px 10px 10px"
                  width="300px"
                  enableLabel
                  disabled
                  defaultDate={newDateFormat(
                    userProfile?.joined_on.split(" ")[0]
                  )}
                  error={err?.joined_on}
                  onChange={(value) =>
                    setUserProfile({
                      ...userProfile,
                      joined_on: StringDtoY(value),
                    })
                  }
                  editable
                />
              </div>
              <p style={{ margin: "10px" }}>Employees Summary (Optional)</p>
              <div className={styles.groupContainer}>
                <Input
                  label="Leadership"
                  name="leadership"
                  labelSize="14px"
                  margin="10px 50px 10px 10px"
                  width="300px"
                  value={userProfile?.leadership}
                  error={err?.leadership}
                  onChange={handleChange}
                />
                <Input
                  label="Senior Management"
                  name="senior_management"
                  labelSize="14px"
                  margin="10px 50px 10px 10px"
                  width="300px"
                  value={userProfile?.senior_management}
                  error={err?.senior_management}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={styles.uploadContainer}>
              <UploadProfile
                name="logo"
                color="#3592FF"
                profile={userProfile?.img_url}
                upload={(value) => {
                  setUserProfile({ ...userProfile, logo: value });
                }}
                size="170px"
              />
              {/* <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  // setUserProfile({ ...userProfile, avtar: "" });
                }}
              >
                Remove Picture
              </p> */}
            </div>
          </div>
          <Btn
            borderRadius="30px"
            bgColor="#3592FF"
            color="#fff"
            width="100px"
            margin="0 10px"
            border="2"
            padding="5px 5px"
          >
            Update
          </Btn>
        </form>
      </Paper>
    </>
  );
};
