import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import  PrevIcon from "../Images/arrow-left.svg";
import ForwardIcon  from "../Images/arrow-right.svg";
import "./carousel.css";

const PrevArrow = ({ className, style, onClick }) => (
  <button
    className={className}
    style={{ ...style, display: "block" }}
    onClick={onClick}
  >
    <img src={PrevIcon} alt="left" />
  </button>
);
const NextArrow = ({ className, style, onClick }) => (
  <button
    className={className}
    style={{ ...style, display: "block" }}
    onClick={onClick}
  >
    <img src={ForwardIcon} alt="ForwardIcon" />
  </button>
);

export const AssessmentCarousel = ({
  data,
  Component,
  customProps,
  slides,
  indexItem,
  handleStart
}) => {
  const settings = {
    infinite: true,
    slidesToShow: slides || 1,
    slidesToScroll: 1,
    lazyLoad: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      // {
      //   breakpoint: 1024,
      //   settings: {
      //     slidesToShow: 3,
      //     slidesToScroll: 3,
      //     infinite: true,
      //   },
      // },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {data &&
        data?.map((ele, i) => {
          return (
            <Component
              {...ele}
              {...customProps}
              // indexItem={indexItem}
              key={ele?.id}
            index={i}
            title={ele?.name}
            instruction={ele?.instruction}
            Icon={ele?.assessment_image}
            onClick={() => handleStart(ele)}
            short_desc={ele?.short_description}
            homePage = {true}
            />
          );
        })}
    </Slider>
  );
};
