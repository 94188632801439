import styles from "../../../assets/css/AdminDashboard/services.module.css";
import {
  Input2,
  Select2,
  CheckBox,
  Select,
} from "../../../Component/UI/CustomInputs";
import {
  resetServiceDetails,
  serviceDetails,
} from "../../../Redux/Slices/serviceDetails";
import { useDispatch, useSelector } from "react-redux";
import { SelectCard } from "../../UI/SelectCard";
import { useState } from "react";
import CustomizedButtons from "../../../Component/UI/Button/CustomizeButton";
import { assessmentData } from "../../../Redux/Slices/AssessmentTableReducer";
import axios from "../../../config/axiosConfig";
import { useEffect } from "react";
import {
  toast,
  Toaster,
} from "../../../../node_modules/react-hot-toast/dist/index";
import { AiOutlineInfoCircle } from "react-icons/ai";

export const ServiceForm = ({ formdata, flag, error, checkedstate, from }) => {
  const requirements = [
    {
      index: 1,
      value: "Session",
    },
    {
      index: 2,
      value: "Tax Applicable",
    },
  ];

  const availability = [
    {
      index: 1,
      value: "In Mobile App",
    },
    {
      index: 2,
      value: "In Web",
    },
  ];
  const interval = [
    {
      id: 1,
      name: "Weekly",
    },
    {
      id: 2,
      name: "Monthly",
    },
    {
      id: 3,
      name: "Yearly",
    },
  ];
  const [isChecked, setIsChecked] = useState(
    new Array(requirements.length).fill(0)
  );

  const [isDisabled, setIsDisabled] = useState(isChecked[0]);
  useEffect(() => {
    setIsDisabled(isChecked[0]);
  }, [isChecked]);

  const [enterpriseOptions, setEnterpriseOptions] = useState([
    { id: 0, value: "None" },
  ]);

  const [buisnessOptions, setBuisnessOptions] = useState();
  const [assessmentList, setAssessmentList] = useState();

  useEffect(() => {
    return () => {
      dispatch(resetServiceDetails());
    };
  }, []);

  useEffect(() => {
    axios.get("/admin/fetch/enterprise").then((res) => {
      if (res?.data?.status) {
        setEnterpriseOptions([
          ...enterpriseOptions,
          ...res.data?.list.map(({ company_name, id }) => {
            return { id, value: company_name };
          }),
        ]);
      }
    });

    axios.post("/admin/businessSegment").then((res) => {
      if (res?.data?.status) {
        setBuisnessOptions(
          res.data?.list.map(({ name, id }) => {
            return { id, value: name };
          })
        );
      }
    });
  }, []);

  useEffect(() => {
    axios
      .post("/admin/assessmentList", { enterprise_id: formdata?.enterprise_id })
      .then((res) => {
        if (res?.data?.status) {
          const options =
            from === "edit"
              ? res?.data?.list
              : res.data?.list.filter((items) => {
                  return (
                    items.serviceAssessment === undefined ||
                    items.serviceAssessment === true
                  );
                });
          setAssessmentList(
            options.map(({ name, id }) => {
              return { id, value: name };
            })
          );
        }
      });
  }, [formdata?.enterprise_id]);

  // useEffect(() => {
  //   if (formdata?.enterprise_service_type === "1") {
  //     dispatch(serviceDetails({ assessment_id: assessmentList?.[0]?.id }));
  //   }
  // }, [assessmentList]);

  const [allowRender, setAllowRender] = useState(checkedstate || false);
  const [isavailableChecked, setIsAvailableChecked] = useState(
    checkedstate || false
  );

  const [availableChecked, setAvailableChecked] = useState(
    new Array(availability.length).fill(0)
  );
  const [disableButton, setDisableButton] = useState(false);
  // useEffect(() => {
  //   const requirementValues = formdata?.requirement?.split(",");
  //   setIsChecked(requirementValues);
  // }, [formdata]);

  useEffect(() => {
    getTable();
  }, []);
  const getTable = async () => {
    try {
      // const response = await axios.get("/admin/assessment/category"); changed api for general
      const response = await axios.post("/admin/generalAssessmentList");
      dispatch(assessmentData(response.data.list));
    } catch (error) {
      console.log(error);
    }
  };
  const { data } = useSelector((i) => i.assessmentData);
  const dispatch = useDispatch();
  console.log(data, "dataNew---");

  useEffect(() => {
    const requirementValues = formdata?.requirement?.split(",");
    if (requirementValues) {
      const num = requirementValues.map((i) => Number(i));
      setIsChecked(num);
      setAllowRender(true);
    }
    const availabilityValues = formdata?.show_availability?.split(",");
    if (availabilityValues) {
      const num = availabilityValues.map((i) => Number(i));
      setAvailableChecked(num);
      setIsAvailableChecked(true);
    }
  }, [formdata]);

  const handleOnChange = (position, value) => {
    const updatedCheckedState = isChecked.map((item, index) =>
      index === position ? Number(!item) : item
    );
    const stringChecked = updatedCheckedState.toString();
    setIsChecked(updatedCheckedState);

    if (isChecked.at(0) === 0) {
      dispatch(serviceDetails({ no_of_session: "1" }));
    } else {
      dispatch(serviceDetails({ no_of_session: "" }));
    }

    // dispatch(
    //   serviceDetails({
    //     no_of_session: formdata.no_of_session ? formdata.no_of_session : "0",
    //     duration: formdata.duration ? formdata.duration : "0",
    //     session_per_month: formdata.session_per_month
    //       ? formdata.session_per_month
    //       : "0",
    //     session_per_week: formdata.session_per_week
    //       ? formdata.session_per_week
    //       : "0",
    //   })
    // );

    // setDisableButton(true);
    dispatch(serviceDetails({ requirement: stringChecked }));
  };

  const availablehandleOnChange = (position, value) => {
    const updatedCheckedState = availableChecked.map((item, index) =>
      index === position ? Number(!item) : item
    );
    const stringChecked = updatedCheckedState.toString();
    setAvailableChecked(updatedCheckedState);
    // setDisableButton(true);
    dispatch(serviceDetails({ show_availability: stringChecked }));
  };

  console.log(formdata, "formdata");

  return (
    <>
      <Toaster />
      <div>
        <div className={styles.inputs}>
          <div style={{ marginLeft: "12px" }}>
            <p>
              Service for <span style={{ color: "#D3232F" }}>*</span>
            </p>
            <div style={{ display: "flex", marginTop: "20px" }}>
              <input
                type="radio"
                id="general"
                name="services_for"
                value={formdata.services_for}
                style={{ outline: "none", width: "auto", height: "auto" }}
                onClick={(e) => dispatch(serviceDetails({ services_for: "0" }))}
                checked={formdata?.services_for === "0"}
                disabled={from === "edit"}
              />
               <label for="general">General</label>
              <span style={{ marginLeft: "20px" }}>
                <input
                  type="radio"
                  id="enterprise"
                  name="services_for"
                  value={formdata.services_for}
                  style={{ outline: "none", width: "auto", height: "auto" }}
                  onClick={(e) =>
                    dispatch(
                      serviceDetails({
                        services_for: "1",
                        tax_applicable: "0",
                        enterprise_service_type: "1",
                      })
                    )
                  }
                  checked={formdata?.services_for === "1"}
                  disabled={from === "edit"}
                />
                <label style={{ marginLeft: "6px" }} for="enterprise">
                  Enterprise
                </label>
              </span>
            </div>
          </div>

          {formdata?.services_for === "1" && (
            <Select
              name="enterprise_id"
              label="Enterprise Name"
              width="288px"
              options={enterpriseOptions}
              value={formdata.enterprise_id}
              margin="0 10px 12px 0"
              disabled={
                from === "edit" || formdata?.enterprise_service_type === "0"
              }
              error={error?.enterprise_id?.[0]}
              onChange={(e) => {
                dispatch(
                  serviceDetails({
                    enterprise_id: e.target.value,
                    assessment_id: "",
                  })
                );

                if (e.target.value === "0") {
                  dispatch(
                    serviceDetails({ validity: "0", number_assessment: "1" })
                  );
                }
              }}
            />
          )}
          <Input2
            label="Name"
            type="text"
            name="name"
            value={formdata?.name}
            error={error?.name?.[0]}
            required
            onChange={(e) => dispatch(serviceDetails({ name: e.target.value }))}
          />
          <Input2
            label="Amount"
            type="number"
            name="amount"
            error={error?.amount?.[0]}
            value={formdata?.amount}
            onChange={(e) =>
              dispatch(serviceDetails({ amount: e.target.value }))
            }
          />
          {formdata?.services_for === "0" && (
            <Select2
              label="Category"
              name="category"
              error={error?.category?.[0]}
              value={formdata?.category}
              options={data}
              onChange={(e) =>
                dispatch(serviceDetails({ category: e.target.value }))
              }
            />
          )}
          <SelectCard
            label="Type of Service"
            options={
              formdata?.services_for === "1"
                ? ["Offline", "Online", "Hybrid"]
                : ["Offline", "Online", "Hybrid"]
            }
            error={error?.type_of_service?.[0]}
            value={formdata?.type_of_service}
            name="type_of_service"
            onChange={(e) =>
              dispatch(serviceDetails({ type_of_service: e.target.value }))
            }
            flag={flag}
          />
          {/* <Select
          label="State"
          name="state"
          onChange={(e) => dispatch(serviceDetails({ state: e.target.value }))}
          options={state}
        />
        <Select
          label="City"
          name="city"
          onChange={(e) => dispatch(serviceDetails({ city: e.target.value }))}
          options={city}
        /> */}

          {formdata?.services_for === "1" && (
            <Select
              name="business_segment"
              label="Buisness Segment"
              width="288px"
              options={buisnessOptions}
              value={formdata?.business_segment}
              error={error?.business_segment?.[0]}
              margin="0 10px 12px 0"
              disabled={from === "edit"}
              onChange={(e) =>
                dispatch(serviceDetails({ business_segment: e.target.value }))
              }
            />
          )}
          {/* <button onClick={() => console.log(buisnessOptions)}>Test</button> */}
          {formdata?.services_for === "0" && (
            <Select2
              label="Interval Type"
              name="interval_type"
              error={error?.interval_type?.[0]}
              options={interval}
              value={formdata?.interval_type}
              onChange={(e) =>
                dispatch(serviceDetails({ interval_type: e.target.value }))
              }
            />
          )}

          <Input2
            type="number"
            label="Validity(Days)"
            name="validity"
            error={error?.validity?.[0]}
            value={formdata?.validity}
            disabled={formdata?.enterprise_id == "0"}
            onChange={(e) =>
              dispatch(serviceDetails({ validity: e.target.value }))
            }
          />

          {formdata?.services_for === "1" && (
            <>
              <div style={{ marginLeft: "12px" }}>
                <p>
                  Choose one <span style={{ color: "#D3232F" }}>*</span>
                </p>
                <div style={{ display: "flex", marginTop: "20px" }}>
                  <input
                    type="radio"
                    id="session"
                    name="enterprise_service_type"
                    value={formdata.enterprise_service_type}
                    style={{ outline: "none", width: "auto", height: "auto" }}
                    onClick={(e) => {
                      dispatch(
                        serviceDetails({
                          enterprise_service_type: "0",
                          assessment_id: "",
                          enterprise_id: "0",
                        })
                      );
                      toast(
                        "Service as a session can't mapped to the specific enterprise",
                        {
                          icon: (
                            <AiOutlineInfoCircle size={28} color="#EFAD03" />
                          ),
                        }
                      );
                    }}
                    checked={formdata?.enterprise_service_type === "0"}
                    disabled={from === "edit"}
                  />
                   <label for="session">Session</label>
                  <span style={{ marginLeft: "20px" }}>
                    <input
                      type="radio"
                      id="assessment"
                      name="enterprise_service_type"
                      value={formdata.enterprise_service_type}
                      style={{ outline: "none", width: "auto", height: "auto" }}
                      onClick={(e) =>
                        dispatch(
                          serviceDetails({
                            enterprise_service_type: "1",
                          })
                        )
                      }
                      checked={formdata?.enterprise_service_type === "1"}
                      disabled={from === "edit"}
                    />
                    <label style={{ marginLeft: "6px" }} for="assessment">
                      Assessment
                    </label>
                  </span>
                </div>
                <p style={{ margin: "8px 0 0 -6px", color: "red" }}>
                  {error?.enterprise_service_type?.[0]}
                </p>
              </div>

              {formdata.services_for === "1" && (
                <>
                  <Select
                    name="assessment_id"
                    label="Assessment Name"
                    width="288px"
                    options={assessmentList}
                    error={error?.assessment_id?.[0]}
                    margin="0 10px 12px 0"
                    disabled={
                      from === "edit" ||
                      formdata.enterprise_service_type === "0" ||
                      !formdata.enterprise_id
                    }
                    value={formdata?.assessment_id}
                    onChange={(e) =>
                      dispatch(
                        serviceDetails({ assessment_id: e.target.value })
                      )
                    }
                  />
                  <Input2
                    label="Number of Assessments / Sessions"
                    type="number"
                    name="number_assessment"
                    value={formdata?.number_assessment}
                    error={error?.number_assessment?.[0]}
                    disabled={formdata?.enterprise_id == "0"}
                    onChange={(e) =>
                      dispatch(
                        serviceDetails({ number_assessment: e.target.value })
                      )
                    }
                  />
                </>
              )}
            </>
          )}

          {formdata?.services_for === "0" && (
            <>
              <Input2
                type="number"
                label="No. of Sessions"
                name="no_of_session"
                hidden={!isDisabled}
                error={error?.no_of_session?.[0]}
                value={formdata?.no_of_session}
                onChange={(e) =>
                  dispatch(serviceDetails({ no_of_session: e.target.value }))
                }
                disabled={!isDisabled}
              />
              <Input2
                type="number"
                label="Duration (Minutes)"
                placeholder="Duration"
                name="duration"
                hidden={!isDisabled}
                error={error?.duration?.[0]}
                value={formdata?.duration}
                onChange={(e) =>
                  dispatch(serviceDetails({ duration: e.target.value }))
                }
                disabled={!isDisabled}
              />
              {/* <Input2
                type="number"
                label="Session per Month"
                name="session_per_month"
                hidden={!isDisabled}
                error={error?.session_per_month?.[0]}
                value={formdata?.session_per_month}
                onChange={(e) =>
                  dispatch(
                    serviceDetails({ session_per_month: e.target.value })
                  )
                }
                disabled={!isDisabled}
              /> */}
              {/* <Input2
                type="number"
                label="Session per Week"
                name="session_per_week"
                hidden={!isDisabled}
                error={error?.session_per_week?.[0]}
                value={formdata?.session_per_week}
                onChange={(e) =>
                  dispatch(serviceDetails({ session_per_week: e.target.value }))
                }
                disabled={!isDisabled}
              /> */}
            </>
          )}
        </div>

        <div className={styles.checkBoxWrapper}>
          {formdata?.services_for === "0" && (
            <div className={styles.checkboxes}>
              <label>Select Requirement</label>
              <div>
                {allowRender &&
                  requirements.map(({ value }, i) => (
                    <CheckBox
                      boldness={600}
                      value={value}
                      check={isChecked[i]}
                      name={value}
                      click={(val) => handleOnChange(i, val)}
                      // onChange={(e) =>
                      //   dispatch(serviceDetails({ requirement: e.target.value }))
                      // }
                    />
                  ))}
              </div>
              <span>
                <p style={{ color: "red" }}>{error?.requirement?.[0]}</p>
              </span>
            </div>
          )}
          {formdata?.services_for === "1" && (
            <div className={styles.checkboxes}>
              <label>Select Requirement</label>
              <div>
                <CheckBox
                  boldness={600}
                  value="Tax Applicable"
                  check={formdata.tax_applicable === "1"}
                  name="tax_applicable"
                  click={(val) =>
                    dispatch(
                      serviceDetails({ tax_applicable: String(Number(val)) })
                    )
                  }
                  // onChange={(e) =>
                  //   dispatch(serviceDetails({ requirement: e.target.value }))
                  // }
                />
              </div>
              <span>
                <p style={{ color: "red" }}>{error?.tax_applicable?.[0]}</p>
              </span>
            </div>
          )}

          <div className={styles.checkboxes}>
            <label>Show Availability</label>
            <div>
              {isavailableChecked &&
                availability.map(({ value, index }, i) => (
                  <CheckBox
                    boldness={600}
                    value={value}
                    name={value}
                    check={availableChecked[i]}
                    click={(val) => availablehandleOnChange(i, val)}
                    // onChange={(e) =>
                    //   dispatch(
                    //     serviceDetails({ show_availability: e.target.value })
                    //   )
                    // }
                  />
                ))}
            </div>
            <span>
              <p style={{ color: "red" }}>{error?.show_availability?.[0]}</p>
            </span>
          </div>
          <div className={styles.checkboxes}>
            <label>Join A Community</label>
            <div>
              <CheckBox
                boldness={600}
                value="Join A Community"
                check={formdata.is_community?.toString() === "1"}
                name="is_community"
                click={(val) =>
                  dispatch(
                    serviceDetails({ is_community: String(Number(val)) })
                  )
                }
                // onChange={(e) =>
                //   dispatch(serviceDetails({ requirement: e.target.value }))
                // }
              />
            </div>
            <span>
              <p style={{ color: "red" }}>{error?.is_community?.[0]}</p>
            </span>
          </div>
        </div>
        <div className={styles.description}>
          <label>Description</label>
          <textarea
            placeholder="Write Here"
            name="description"
            value={formdata?.description}
            onChange={(e) =>
              dispatch(serviceDetails({ description: e.target.value }))
            }
          ></textarea>
          <p style={{ color: "red" }}>{error?.description?.[0]}</p>
        </div>
      </div>
    </>
  );
};
