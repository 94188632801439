import React from "react";
import { TabView } from "../../../UI/TabView/TabView";
import { Communication } from "./Communication";
import { Interest } from "./Interest";
import { Enjoyment } from "./Enjoyment";

export const PatientLevel = () => {
  const items = [
    {
      label: "Communication",
      component: <Communication />,
    },
    {
      label: "Interest",
      component: <Interest />,
    },
    {
      label: "Enjoyment",
      component: <Enjoyment />,
    },
  ];
  return (
    <div>
      <TabView items={items} border="none" float="right" />
    </div>
  );
};
