import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import style from "../../../assets/css/GeneralAddQuestion.module.css";
import Button from "@mui/material/Button";
import { RiDeleteBinLine } from "react-icons/ri";

import { Input } from "../../UI/Input";
import BasicBreadcrumbs from "../../../Component/UI/BreadCrumb/BreadCrumbs";

import { useSelector } from "react-redux";
import { convertToBase64 } from "./AfterClickingAddButton/ConvertToBase64";
import { useLocation, useNavigate } from "react-router-dom";

import { API } from "../../../config/constant";
import { SelectType } from "../../../Component/UI/CustomInputs";
import AlertMessage from "../../../Component/UI/AlertMessage";
import instance from "../../../config/axiosConfig";
import { TEXT } from "../../contant/constant";
import { optionFieldwithImageValidate } from "./AfterClickingAddButton/OptionFieldValidation";

const option = ["Select", "Single Choice", "Time"];

const GeneralAddQuestion = () => {
  const { responseaddQuestion } = useSelector(
    (state) => state.addQuestionSlice
  );

  const navigate = useNavigate();
  const location = useLocation();
  let { cid, id } = location?.state;

  const link = [
    { path: "/Assessments", linkText: "Assessments" },
    { path: "/Assessments", linkText: "Quick Asks" },
    {
      path: "/generalQuickAsks",
      linkText: "General",
      cid: cid,
    },
  ];

  const [questionValue, setQuestionValue] = useState("");
  const [inputFields, setInputFields] = useState([
    { option: "", id: 0, image: "", existing: false },
  ]);
  const [error, setError] = useState(false);
  const [respData, setRespData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [selected, setSelected] = useState();

  const [prevSelected, setPrevSelected] = useState();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    addDatato();
  }, [flag]);

  const sucessNotify = (msg) => toast.success(msg);

  const errorNotify = (error) => toast.error(error);

  const getData = () => {
    try {
      instance
        .get(`${API.GETGENERALQUESTIONWITHQUES}?question_id=${id}`)
        .then((res) => {
          res.data.status === true && setRespData(res.data.list);
          setQuestionValue(res.data.list?.question);
          setSelected(res.data.list?.input_type);
          setPrevSelected(res.data.list?.input_type);
          setFlag(!flag);
        });
    } catch (error) {
      console.log(error, "error");
    }
  };

  //Image Upload
  const ImageUploadHandler = async (e, imageid) => {
    e.preventDefault();

    const acceptOnlyImage = e.target.accept.split(",").filter((result) => {
      return result === e.target.files[0].type;
    });

    const imageData =
      acceptOnlyImage[0] === e.target.files[0].type
        ? e.target.files[0]
        : errorNotify("Please select image");

    const base64 = await convertToBase64(imageData);
    const imgName = e.target.files[0].name;
    console.log("imgName", imageData);
    let data = [...inputFields];

    data[imageid]["image"] = base64;
    data[imageid]["imageName"] = imgName;
    setInputFields(data);
  };

  const ResetBtn = (e) => {
    const result = inputFields.filter((el) => el.option === "");
    setInputFields(result);
  };

  const selecedHandleChange = (e) => {
    console.log(e.target.value, "event");
    setSelected(e.target.value);
    addDatato(prevSelected?.toLowerCase() !== e.target.value?.toLowerCase());
  };

  const QustionInputHandler = (e) => {
    e.preventDefault();
    setError(false);
    setQuestionValue(e.target.value);
  };

  const addDatato = (flag) => {
    if (flag) {
      addBtn();
      ResetBtn();
    } else {
      respData.options?.length >= 0 &&
        setInputFields(
          respData.options?.map((el, index) => {
            return {
              option: el.option,
              id: el.id,
              image: el.image,
              existing: true,
            };
          })
        );
    }

    // }
  };

  const addBtn = () => {
    let arrLength = inputFields?.length;
    let finalId = arrLength > 0 ? inputFields[arrLength - 1]?.id : -1;

    let newField = {
      option: "",
      id: ++finalId,
      image: "",
      existing: false,
    };
    setInputFields([...inputFields, newField]);
  };

  //Add Fields
  const AddInputhandleChange = (e, id) => {
    e.preventDefault();
    setError(false);
    let data = [...inputFields];
    data[id][e.target.name] = e.target.value;
    setInputFields(data);
  };

  //SaveButton
  const SaveHandler = () => {
    let form_data = new FormData();
    form_data.append("category_id", cid);
    form_data.append("input_type", selected);
    form_data.append("question", questionValue);
    form_data.append("question_id", id);
    form_data.append("options", JSON.stringify(inputFields));

    console.log(selected, "indiseSave", inputFields);
    const optfieldvalidate =
      selected !== TEXT.TIME
        ? optionFieldwithImageValidate(
            inputFields.length,
            inputFields
            // inputFields
          )
        : true;

    if (
      selected !== "undefined" &&
      selected !== undefined &&
      selected !== "Select"
    ) {
      if (optfieldvalidate) {
        try {
          instance.post(API.POSTADDQUESTION, form_data).then((res) => {
            if (res.data.status) {
              sucessNotify(res.data.msg);
              navigate("/generalQuickAsks", {
                state: { id: id, cid: cid },
              });
            } else {
              setError(res.data.error?.question[0]);
              errorNotify(res.data.error?.question[0]);
            }
          });
        } catch (error) {
          console.log(error, "error");
        }
      }
    } else {
      errorNotify("Please Select Assessment");
    }
  };

  //remove btn
  const removeApi = (id) => {
    let removepayload = {
      option_id: inputFields[id].id,
    };

    try {
      instance.post(API.DELETEQUICKASK, removepayload).then((res) => {
        res.data.status === true && getData();
      });
    } catch (error) {
      console.log(error, "error");
    }
  };

  const removeLocal = (id) => {
    if (
      window.confirm(
        `Are you sure you want to delete this ${inputFields[id].option}? Please note that this cannot be undo & will be saved immediately.`
      ) === true
    ) {
      let newdata = [...inputFields];
      newdata.splice(id, 1);
      setInputFields(newdata);
    } else return null;
  };

  const removeBtn = (e, id) => {
    console.log(id, "id");
    let existing = inputFields[id]?.existing;

    existing
      ? window.confirm(
          ` Are you sure you want to delete this ${inputFields[id].option}? Please note that this cannot be undo & will be saved immediately.`
        ) === true && removeApi(id)
      : removeLocal(id);
  };

  const CancelHandler = () => {
    navigate("/generalQuickAsks", {
      state: { id: id, cid: cid },
    });
  };

  return (
    <>
      <BasicBreadcrumbs link={link} text={"Add Question"}>
        <div className={style.paperContainercss}>
          <div className={style.mainQuestions}>
            <div className={style.quesOne}>
              <SelectType
                option={option}
                value={selected}
                label="Select Assessment"
                onChange={(e) => selecedHandleChange(e)}
              />
            </div>
            <div>
              <Input
                label="Question"
                type={"text"}
                name={"question"}
                width="350px"
                onChange={(e) => QustionInputHandler(e)}
                defaultValue={selected === prevSelected ? questionValue : ""}
              />
              <p style={{ color: "red" }}>
                {error && responseaddQuestion.error?.question[0]}
              </p>
            </div>
          </div>
          {selected?.toLowerCase() !== TEXT.TIME.toLowerCase() && (
            <div className={style.addQuestion}>
              <h2 className={style.h2text}>Add Choices</h2>
              <div className="addButtton">
                <Button
                  variant="contained"
                  size="large"
                  disabled={selected === TEXT.TIME}
                  style={{
                    backgroundColor: selected === TEXT.TIME ? "grey" : "black",
                    borderRadius: 25,
                    padding: "10px 35px",
                    cursor: selected === TEXT.TIME ? "not-allowed" : "",
                  }}
                  onClick={addBtn}
                >
                  Add
                </Button>
              </div>
            </div>
          )}
          {/* Option */}
          {selected?.toLowerCase() !== TEXT.TIME.toLowerCase() &&
            inputFields?.map((el, index) => {
              return (
                <div className={style.optFields} key={el.id}>
                  <div>
                    <Input
                      label={`Option ${index + 1}`}
                      name="option"
                      type="text"
                      id={index}
                      placeholder={"Please Enter the Option"}
                      //value={el.option}
                      defaultValue={el.option}
                      onChange={(e) => AddInputhandleChange(e, index)}
                    />
                  </div>
                  <div
                    style={{
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "24px",
                    }}
                  >
                    <div>
                      <input
                        type="file"
                        id={`actual-btn${index}`}
                        name="image"
                        accept="image/png,image/jpeg,image/svg,image/gif,image/jpg,image/tiff"
                        //defaultValue={el.image||""}
                        hidden
                        onChange={(e) => ImageUploadHandler(e, index)}
                      />
                      <label
                        htmlFor={`actual-btn${index}`}
                        className="uploadLabel"
                      >
                        Choose File
                      </label>
                    </div>
                    <div
                      style={{
                        width: 150,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span id="file-chosen">{el.imageName}</span>
                      {el.image !== "" &&
                        el.image !== null &&
                        el.image !== undefined && (
                          <img
                            src={el.image}
                            alt="option"
                            style={{ width: "25px", height: "25px" }}
                          />
                        )}
                    </div>
                  </div>

                  <RiDeleteBinLine
                    style={{
                      fontSize: 25,
                      color: "#4caf50",
                      marginTop: "35px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => removeBtn(e, index)}
                  />
                </div>
              );
            })}
          <div className="formButton" style={{ marginTop: 20 }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#2A2A2A",
                borderRadius: 50,
                marginRight: 10,
              }}
              onClick={CancelHandler}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: "#00B091", borderRadius: 50 }}
              onClick={SaveHandler}
            >
              Save
            </Button>
          </div>
        </div>
        {responseaddQuestion.status && (
          <AlertMessage Msg={responseaddQuestion?.msg} />
        )}
        {/* </PaperWrapper> */}
      </BasicBreadcrumbs>
      <Toaster />
    </>
  );
};

export default GeneralAddQuestion;
