import { Avatar } from "../../../Component/UI/Avatar";
import styles from "../../../assets/css/PatientDashboard/articleCard.module.css";
import moment from "../../../../node_modules/moment/moment";

export const ArticleCard = ({ data }) => {
  return (
    <a
      href={data.link}
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: "none", color: "#000" }}
    >
      <div className={styles.articleCard}>
        <img
          src={data?.yoast_head_json?.og_image?.[0]?.url}
          alt="article_image"
        />
        <div>
          <p>{moment(data.date).format("MMMM DD ,YYYY")}</p>
          <h2>{data.title.rendered}</h2>
          {/* <p>{data.summary}</p>
          <div className={styles.author}>
            <Avatar size="24px" />
            <p>{data.author}</p>
          </div> */}
        </div>
      </div>
    </a>
  );
};
