import React from "react";
import DrImg from "./Images/drImg.svg";
export const OurDoctors = ({ styles }) => {
  return (
    <div className={styles.ourDoctors}>
      <h3>What Our Doctors Have To Say</h3>
      <div className={styles.ourDrSec}>
        <div className={styles.ourDrContSec}>
          <h4>Dr. Kislaya Mishra</h4>
          <h6>Consultant Psychiatrist, Samvedna Care</h6>
          <p>
            In this video, Dr Kislay Mishra talks about how meditation and yoga
            can help people with feelings of isolation and depression among all
            age groups. Did you know that isolation and depression can affect
            anyone, regardless of age? These mental health conditions can be
            challenging, but Dr Mishra explains how meditation and yoga can be
            effective tools for all age barriers in treating symptoms of
            isolation and depression. Watch the video to get insights on how to
            care for your mental health and take action accordingly! Watch our
            webinar on "Ask us anything about depression" to learn more.
          </p>
        </div>
        <div className={styles.ourDrSecImg}>
          <iframe
            src="https://www.youtube.com/embed/shrqWSDKVK8"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          {/* https://www.youtube.com/embed/uvYxvr2RcfM */}
        </div>
      </div>
    </div>
  );
};
