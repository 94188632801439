import React from "react";
import styles from "../../../../assets/css/UI/summaryCard.module.css";

function SummaryData({ data, moreData, isMoreData }) {
  return (
    <div className={styles.summaryCard}>
      <div
        className={styles.data}
        // style={{ gridTemplateColumns: `repeat(${props.rows}, 1fr)` }}
      >
        {data.map(({ name, value }, id) => {
          return (
            <>
              {name && (
                <div key={id}>
                  <p className={styles.name}>{name}</p>
                  <p className={styles.value}>{value}</p>
                </div>
              )}
            </>
          );
        })}
      </div>
      {isMoreData || isMoreData?.toString() === "0" ? (
        <>
          <p className={styles.heading}>Extended Subscription</p>
          <div className={styles.data}>
            {moreData?.map(({ name, value }, id) => {
              return (
                <>
                  {name && (
                    <div key={id}>
                      <p className={styles.name}>{name}</p>
                      <p className={styles.value}>{value}</p>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </>
      ) : null}
    </div>
  );
}

export default SummaryData;
