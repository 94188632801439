import React from "react";
import { Helmet } from "react-helmet";
import { PatientTestimonial } from "../../../Component/UI/Testimonial/Testimonial";

const TestimonialIndex = () => {
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Patient Testimonial - Samvedna Map</title>
        <meta name="title" content="Patient Testimonial - Samvedna Map" />
        <link rel="canonical" href="https://map.samvednacare.com/patient/testimonial" />
        <meta
          name="description"
          content="Share your journey with Samvedna MAP by sharing your valuable thoughts. Your testimonials will help other users to relate to their situation and make a decision."
        />
        <meta name="robots" content="index,follow" />
      </Helmet>
      <PatientTestimonial />
    </>
  );
};

export default TestimonialIndex;
