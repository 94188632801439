import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import moment from "moment";
import {
  getAppointmentDetail,
  updateAppointment,
} from "../../../services/admin/appointment";
import BasicBreadcrumbs from "../../../Component/UI/BreadCrumb/BreadCrumbs";
import PaperWrapper from "../../../Component/UI/PaperWrapper/PaperWrapper";
import styles from "./appointment.module.css";
import toast, { Toaster } from "react-hot-toast";

export const EditUser = () => {
  const [formErrors, setFormErrors] = useState({});

  let navigate = useNavigate();
  const { id } = useParams();

  const [user, setUser] = useState({
    paitient_name: "",
    mobile: "",
    address: "",
    staff: "",
    appointment_time: "",
    appointment_date: "",
    started_at: "",
    end_time: "",
  });

  const { appointment_date, end_time } = user;

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const currentDate = moment(new Date()).format("YYYY-MM-DD");

    const selectedStartTime = moment(
      currentDate + " " + user.appointment_time,
      "YYYY-MM-DD hh:mm"
    );
    const selectedEndTime = moment(
      currentDate + " " + user.end_time,
      "YYYY-MM-DD hh:mm"
    );

    const appointmentDateWithTime = moment(
      appointment_date + " " + moment().format("hh:mm")
    ).format("YYYY-MM-DD hh:mm");

    const endDiff = selectedEndTime.diff(moment(), "minutes");
    const startDiff = selectedStartTime.diff(moment(), "minutes");

    const eventDateDiff = moment().diff(appointmentDateWithTime, "hours");

    const diffMin = selectedEndTime.diff(selectedStartTime, "seconds");

    const obj = {
      appointment_id: id,
      paitient_name: user.paitient_name,
      mobile: user.mobile,
      address: user.address,
      staff: user.staff,
      appointment_time: user.appointment_time.substring(0, 5),
      appointment_date: user.appointment_date,
      started_at: user.started_at,
      end_time: user.end_time.substring(0, 5),
    };

    if (diffMin > 0) {
      if (endDiff < 0 && eventDateDiff >= 0) {
        toast.error("End time should be more than current time!");
      } else if (startDiff < 0 && eventDateDiff >= 0) {
        toast.error("Start time should be more than current time!");
      } else {
        updateAppointment(obj).then((res) => {
          if (res?.data?.status) {
            navigate("/Appointments");
          }
          setFormErrors(res?.data?.error);
        });
      }
    } else {
      toast.error("End time should be more than start time!");
    }
  };

  const loadUser = async () => {
    getAppointmentDetail(id).then((res) => {
      const data = res?.data?.list;
      setUser({
        ...data,
        appointment_date: data?.appointment_date?.split(" ")[0],
      });
    });
  };

  useEffect(() => {
    loadUser();
  }, []);

  const link = [{ path: "/Appointments", linkText: "Appointments" }];

  return (
    <div className={styles.appointment}>
      <Toaster />
      <BasicBreadcrumbs link={link} text="Edit Appointment">
        <form onSubmit={handleSubmit}>
          <div className="form-group"></div>

          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{ display: "flex", textAlign: "left" }}
          >
            <Grid item xs={6} md={6} lg={6}>
              <Grid
                style={{
                  marginLeft: "5px",
                  fontSize: "14px",

                  fontWeight: "400",
                }}
              >
                <p style={{ fontSize: "1rem" }}>Paitient Name</p>
              </Grid>
              <Grid style={{}}>
                {" "}
                <input
                  type="text"
                  name="paitient_name"
                  placeholder="Name"
                  value={user.paitient_name}
                  onChange={(e) => onInputChange(e)}
                  style={{
                    width: "320px",
                    borderRadius: "8px",
                    height: "48px",
                    outline: "none",
                    border: "1px solid #dadada",
                    fontSize: "16px",
                    marginTop: "8px",
                    paddingLeft: "8px",
                    marginLeft: "5px",
                  }}
                />
              </Grid>
              <p
                style={{
                  color: "red",
                  marginLeft: "6px",
                  fontSize: "1rem",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                {formErrors?.paitient_name}
              </p>
            </Grid>

            <Grid item xs={6} md={6} lg={6}>
              <Grid
                style={{
                  marginLeft: "110px",
                  fontSize: "14px",
                  marginTop: "5px",
                  fontWeight: "400",
                }}
              >
                <p style={{ fontSize: "1rem" }}>Mobile</p>
              </Grid>

              <Grid
                style={{
                  marginLeft: "110px",
                  fontSize: "16px",
                  marginTop: "5px",
                  fontWeight: "600",
                }}
              >
                <input
                  type="number"
                  name="mobile"
                  placeholder="Mobile"
                  value={user.mobile}
                  onChange={(e) => onInputChange(e)}
                  onWheel={(e) => e.target.blur()}
                  style={{
                    width: "320px",

                    borderRadius: "8px",
                    height: "48px",
                    outline: "none",
                    border: "1px solid #dadada",
                    fontSize: "16px",
                    marginTop: "8px",
                    paddingLeft: "8px",
                  }}
                />
              </Grid>
              <p
                style={{
                  color: "red",
                  marginLeft: "110px",
                  fontSize: "1rem",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                {formErrors?.mobile}
              </p>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{ display: "flex", textAlign: "left" }}
          >
            <Grid item xs={6} md={6} lg={6}>
              <Grid
                style={{
                  marginLeft: "5px",
                  fontSize: "14px",
                  marginTop: "5px",
                  fontWeight: "400",
                }}
              >
                <p style={{ fontSize: "1rem" }}>Address</p>
              </Grid>
              <Grid
                style={{
                  marginLeft: "5px",
                  fontSize: "16px",
                  marginTop: "5px",
                  fontWeight: "600",
                }}
              >
                {" "}
                <input
                  type="text"
                  name="address"
                  placeholder="Address"
                  value={user.address}
                  onChange={(e) => onInputChange(e)}
                  style={{
                    width: "320px",

                    borderRadius: "8px",
                    height: "48px",
                    outline: "none",
                    border: "1px solid #dadada",
                    fontSize: "16px",
                    marginTop: "8px",
                    paddingLeft: "8px",
                  }}
                />
              </Grid>
              <p
                style={{
                  color: "red",
                  marginLeft: "6px",
                  fontSize: "1rem",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                {formErrors?.address}
              </p>
            </Grid>

            <Grid item xs={6} md={6} lg={6}>
              <Grid
                style={{
                  marginLeft: "110px",
                  fontSize: "14px",
                  marginTop: "5px",
                  fontWeight: "400",
                }}
              >
                <p style={{ fontSize: "1rem" }}>Staff</p>
              </Grid>

              <Grid
                style={{
                  marginLeft: "110px",
                  fontSize: "16px",
                  marginTop: "5px",
                  fontWeight: "600",
                }}
              >
                <input
                  type="text"
                  name="staff"
                  placeholder="Staff"
                  value={user.staff}
                  onChange={(e) => onInputChange(e)}
                  style={{
                    width: "320px",

                    borderRadius: "8px",
                    height: "48px",
                    outline: "none",
                    border: "1px solid #dadada",
                    fontSize: "16px",
                    marginTop: "8px",
                    paddingLeft: "8px",
                  }}
                />
              </Grid>

              <p
                style={{
                  color: "red",
                  marginLeft: "110px",
                  fontSize: "1rem",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                {formErrors?.staff}
              </p>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{ display: "flex", textAlign: "left" }}
          >
            <Grid item xs={6} md={6} lg={6}>
              <Grid
                style={{
                  marginLeft: "5px",
                  fontSize: "14px",
                  marginTop: "5px",
                  fontWeight: "400",
                }}
              >
                <p style={{ fontSize: "1rem" }}>Appointment Time</p>
              </Grid>
              <Grid
                style={{
                  marginLeft: "5px",
                  fontSize: "16px",
                  marginTop: "5px",
                  fontWeight: "600",
                }}
              >
                <input
                  type="time"
                  name="appointment_time"
                  value={user.appointment_time}
                  onChange={(e) => onInputChange(e)}
                  min="09:00"
                  max="19:00"
                  style={{
                    width: "320px",

                    borderRadius: "8px",
                    height: "48px",
                    outline: "none",
                    border: "1px solid #dadada",
                    fontSize: "16px",
                    marginTop: "8px",
                    paddingLeft: "8px",
                  }}
                />
              </Grid>
              <p
                style={{
                  color: "red",
                  marginLeft: "6px",
                  fontSize: "1rem",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                {formErrors?.appointment_time}
              </p>
            </Grid>

            <Grid item xs={6} md={6} lg={6}>
              <Grid
                style={{
                  marginLeft: "110px",
                  fontSize: "14px",
                  marginTop: "5px",
                  fontWeight: "400",
                }}
              >
                <p style={{ fontSize: "1rem" }}>Appointment Date</p>
              </Grid>
              <Grid
                style={{
                  marginLeft: "110px",
                  fontSize: "16px",
                  marginTop: "5px",
                  fontWeight: "600",
                }}
              >
                <input
                  type="date"
                  name="appointment_date"
                  value={appointment_date}
                  onChange={(e) => onInputChange(e)}
                  min={moment().format("YYYY-MM-DD")}
                  style={{
                    width: "320px",
                    borderRadius: "8px",
                    height: "48px",
                    outline: "none",
                    border: "1px solid #dadada",
                    fontSize: "16px",
                    marginTop: "8px",
                    paddingLeft: "8px",
                  }}
                />
              </Grid>
              <p
                style={{
                  color: "red",
                  marginLeft: "110px",
                  fontSize: "1rem",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                {formErrors?.appointment_date}
              </p>
            </Grid>
          </Grid>

          <Grid item xs={6} md={6} lg={6}>
            <Grid
              style={{
                marginLeft: "5px",
                fontSize: "14px",
                marginTop: "5px",
                fontWeight: "400",
              }}
            >
              <p style={{ fontSize: "1rem" }}>End Time</p>
            </Grid>
            <Grid
              style={{
                marginLeft: "5px",
                fontSize: "16px",
                marginTop: "5px",
                fontWeight: "600",
              }}
            >
              <input
                type="time"
                name="end_time"
                value={end_time}
                onChange={(e) => onInputChange(e)}
                min="09:00"
                max="19:00"
                style={{
                  width: "320px",
                  borderRadius: "8px",
                  height: "48px",
                  outline: "none",
                  border: "1px solid #dadada",
                  fontSize: "16px",
                  marginTop: "8px",
                  paddingLeft: "8px",
                }}
              />
            </Grid>{" "}
            <p
              style={{
                color: "red",
                marginLeft: "6px",
                fontSize: "1rem",
                marginTop: "5px",
                marginBottom: "5px",
              }}
            >
              {formErrors?.end_time}
            </p>
          </Grid>

          <button
            style={{
              backgroundColor: "#00B091",
              borderRadius: "50px",
              margin: "10px",
              padding: "10px",
              border: "none",
              color: "white",
              paddingLeft: "15px",
              paddingRight: "15px",
              fontSize: "1rem",
              cursor: "pointer",
            }}
          >
            Update
          </button>
        </form>
      </BasicBreadcrumbs>
    </div>
  );
};
