import { SummaryCard } from "../../../Component/UI/SummaryCard";
import Button from "@mui/material/Button";
import styles from "../../../assets/css/AdminDashboard/services.module.css";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteServicesRecommendation,
  getServiceDetailed,
  getServiceDetails,
} from "../../../services/admin/services";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  resetServiceDetails,
  serviceDetails,
  statusValue,
} from "../../../Redux/Slices/serviceDetails";
import SwitchButton from "../../../Component/UI/SwitchButton/Switch";
import { serviceStatus } from "../../../services/admin/services";
import { RecommendService } from "./RecommendService";
import { assessmentData } from "../../../Redux/Slices/AssessmentTableReducer";
import axios from "../../../config/axiosConfig";
import { deleteServices } from "../../../services/admin/services";
import toast, {
  Toaster,
} from "../../../../node_modules/react-hot-toast/dist/index";
import SwitchToggle from "../../../Component/UI/Switch";
import ConfirmPopup from "./ConfirmPopup";
import moment from "../../../../node_modules/moment/moment";
import SimpleBreadcrum from "../../../Component/UI/SimpleBreadcrum";

export const ServiceDetail = () => {
  const dispatch = useDispatch();
  const [interval, setInterval] = useState();
  const [typeService, setTypeService] = useState();
  const [requirement, setRequirement] = useState();
  const [availability, setAvailability] = useState();
  const [category, setCategory] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [statusUpdate, setStatusUpdate] = useState(false);
  const [statusDisabled, setStatusDisable] = useState(false);
  const [popupAction, setPopupAction] = useState({
    open: false,
    action: "",
    name: "",
  });

  const navigate = useNavigate();

  const { data } = useSelector((i) => i.serviceDetails);
  const { status } = useSelector((i) => i.serviceDetails);
  const { state } = useLocation();
  const permissions = state?.permissions;
  const fullpermissions = +permissions?.listControl?.access?.["Full Control"];
  const writePermissions = +permissions?.listControl?.access?.Write;
  const id = state?.props;
  const level = state?.level;

  useEffect(() => {
    // getServiceDetails(id).then((i) => console.log(i));
    getServiceDetailed(id).then((i) => {
      dispatch(serviceDetails(i));
    });

    return () => {
      dispatch(resetServiceDetails());
    };
  }, []);

  useEffect(() => {
    getServiceDetailed(id).then((i) => {
      dispatch(statusValue(i?.status));
    });
  }, [statusUpdate]);

  // useEffect(() => {
  //   getCat();
  // }, []);

  // const getCat = async () => {
  //   try {
  //     const response = await axios.get("/admin/assessment/category");
  //     dispatch(assessmentData(response.data.list));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // const session = useSelector((i) => i.assessmentData);

  // useEffect(() => {
  //   session.data
  //     ?.filter((category) => category.id === data.category)
  //     .map((cat) => setCategory(cat.name));
  // }, [session]);

  function createData(name, value) {
    return { name, value };
  }
  useEffect(() => {
    if (data.interval_type == 1) {
      setInterval("Weekly");
    } else if (data.interval_type == 2) {
      setInterval("Monthly");
    } else if (data.interval_type == 3) {
      setInterval("Yearly");
    }
  }, [data]);
  useEffect(() => {
    if (data.type_of_service == 0) {
      setTypeService("Offline");
    } else if (data.type_of_service == 1) {
      setTypeService("Online");
    } else if (data.type_of_service == 2) {
      setTypeService("Hybrid");
    }
  }, [data]);
  useEffect(() => {
    if (data.requirement === "0,1") {
      setRequirement("Tax Applicable");
    } else if (data.requirement === "1,0") {
      setRequirement("Session");
    } else if (data.requirement === "1,1") {
      setRequirement("Session, Tax Applicable");
    } else if (data.requirement === "0,0") {
      setRequirement("-");
    }
  }, [data]);

  useEffect(() => {
    if (data.show_availability === "0,1") {
      setAvailability("In Web");
    } else if (data.show_availability === "1,0") {
      setAvailability("In Mobile App");
    } else if (data.show_availability === "1,1") {
      setAvailability("In Mobile App, In Web");
    } else if (data.show_availability === "0,0") {
      setAvailability("-");
    }
  }, [data]);
  const values =
    data?.services_for == "0"
      ? [
          ["Service For", data?.services_for == "0" ? "General" : "Enterprise"],
          ["Name", data?.name],
          ["Amount", data?.amount],
          ["Category", data?.category_name],
          ["Type of Service", typeService],
          ["Interval Type", interval],
          ["Validity (Days)", data?.validity],
          requirement?.includes("Session")
            ? ["No. of Sessions", data?.no_of_session]
            : [],
          requirement?.includes("Session")
            ? ["Duration (Minutes)", data?.duration]
            : [],
          requirement?.includes("Session")
            ? ["Session per Month", data?.session_per_month]
            : [],
          requirement?.includes("Session")
            ? ["Session per Week", data?.session_per_week]
            : [],
          ["Select Requirement", requirement],
          ["Show Availability", availability],
          data?.is_community?.toString() === "1"
            ? [
                "Join A Community ",
                data?.is_community?.toString() === "1" ? "Yes" : "No",
              ]
            : [],
        ]
      : [
          ["Service For", data?.services_for == "0" ? "General" : "Enterprise"],
          ["Service Name", data?.name],
          [
            "Enterprise Name",
            data?.enterprise_id ? data?.enterprise_id : "None",
          ],
          ["Amount", data?.amount],
          data?.category ? ["Category", data?.category] : [],
          data?.business_segment
            ? ["Buisness Segment", data?.business_segment]
            : [],
          ["Type of Service", typeService],
          ["Validity (Days)", data?.validity],
          [
            "Choose One",
            data.enterprise_service_type == "1" ? "Assessment" : "Session",
          ],
          data?.assessment_name
            ? ["Assessment Name", data?.assessment_name]
            : [],
          ["Number of Assessments / Sessions", data?.number_assessment],
          ["Tax Applicable", data?.tax_applicable == "1" ? "Yes" : "No"],
          ["Show Availability", availability],
          [
            "Expiry Date",
            state?.expiry_date
              ? moment(state?.expiry_date).format("DD-MM-YYYY")
              : "N/A",
          ],
          data?.is_community?.toString() === "1"
            ? [
                "Join A Community ",
                data?.is_community?.toString() === "1" ? "Yes" : "No",
              ]
            : [],
        ];

  const datas = values.map((i) => createData(...i));

  const desc = data?.description;

  const removeRecommendation = (id) => {
    deleteServicesRecommendation(id)
      .then((res) => {
        toast.success("Service recommendation is removed !");
        navigate("/Services");
      })
      .catch((err) => console.log(err));
  };

  const handleStatusToggle = (popup, setToggle) => {
    if (statusDisabled) return;
    if (popup) {
      setPopupAction({
        ...popupAction,
        open: true,
        action: () => {
          setStatusDisable(true);
          serviceStatus(data?.id, !status).then((res) => {
            if (res?.data?.status) {
              setStatusDisable(false);
              setToggle(!status);
              setStatusUpdate(!statusUpdate);
              setPopupAction({
                ...popupAction,
                open: false,
              });
            }
          });
        },
      });
    } else {
      setStatusDisable(true);
      setToggle(!status);
      serviceStatus(data?.id, !status).then((res) => {
        if (res?.data?.status) {
          setStatusDisable(false);
          setStatusUpdate(!statusUpdate);
        }
      });
    }
  };

  const links = [
    {
      path: "/Services",
      linkText: "Services",
    },
  ];

  return (
    <>
      <Toaster />
      <ConfirmPopup
        popupAction={popupAction}
        setPopupAction={setPopupAction}
        title="Are you sure you want to enable this service? once it's enabled an expiry date will be set according to the validity"
        heading="Service Status"
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <SimpleBreadcrum text="Service Details" link={links} />
        <div className={styles.detailsCard_container}>
          {fullpermissions ? (
            <span className={styles.status_btn}>
              Status
              {/* <SwitchButton
            style="1px"
            status={data?.status}
            onClick={(value) => serviceStatus(data?.id, value)}
          /> */}
              <span key={data?.id}>
                <SwitchToggle
                  disabled={true}
                  status={data?.status}
                  onClick={(setToggle) =>
                    state?.services_for === 1
                      ? state?.expiry_date
                        ? handleStatusToggle(false, setToggle)
                        : handleStatusToggle(true, setToggle)
                      : handleStatusToggle(false, setToggle)
                  }
                />
              </span>
            </span>
          ) : null}

          {state.services_for == "0" &&
            (writePermissions ? (
              <>
                <Button
                  variant="contained"
                  sx={{
                    width: "auto",
                    background: "#ffffff",
                    borderRadius: "100px",
                    color: "#00B091",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#00B091",
                      color: "#ffffff",
                    },
                    marginRight: "10px",
                  }}
                  onClick={() => setIsOpen(true)}
                >
                  Add to Recommendation
                </Button>
                {/* for deleting the recommendation */}
                {level && (
                  <Button
                    variant="contained"
                    sx={{
                      width: "auto",
                      background: "#ffffff",
                      borderRadius: "100px",
                      color: "#00B091",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#00000090",
                        color: "#ffffff",
                      },
                      marginRight: "10px",
                    }}
                    onClick={() => removeRecommendation(data?.id)}
                  >
                    Delete Recommendation
                  </Button>
                )}
              </>
            ) : null)}
          {/* for deleting the service */}
          {/* <Button
          variant="contained"
          sx={{
            width: "78px",
            background: "#00B091",
            borderRadius: "100px",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#00B091",
            },
          }}
          onClick={() => deleteServices(data?.id)}
        >
          Delete
        </Button> */}
        </div>
      </div>
      <div className={styles.detailsCard}>
        <SummaryCard data={datas} description={desc} />
        {/* <Button
          variant="contained"
          sx={{
            width: "78px",
            background: "#00B091",
            borderRadius: "100px",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#00B091",
            },
          }}
        >
          Edit
        </Button> */}
        {isOpen && (
          <RecommendService
            setIsOpen={setIsOpen}
            modalData={data}
            // option={session?.data}
          />
        )}
      </div>
    </>
  );
};
