import React from "react";
import { TabViewEnterprise } from "../../../Samvedna-Enterprise/Components/UI/TabViewEnterprise/TabViewEnterprise";
import { ConnectWithUs } from "./ConnectWithUs";
import { Faq } from "./Faq";
import styles from "./help.module.css";
import { TabView } from "../../UI/TabView";

export const HelpSupport = () => {
  const items = [
    {
      label: "Connect with us",
      component: <ConnectWithUs />,
    },
    // {
    //   label: "Counsellors",
    //   component: <Counselors />,
    // },
  ];
  return (
    <>
      <div className={styles.HelpSupportSec}>
        <TabView calendar values={items} />
      </div>
    </>
  );
};
