import React, { useContext } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import {IoTimeOutline} from "react-icons/";
// import {FiBarChart2} from 'react-icons/fi'

import { ReactComponent as Dashboard } from "../../assets/icons/icon/dashboard.svg";
import { ReactComponent as Patients } from "../../assets/icons/icon/patients.svg";
import { ReactComponent as Enterprises } from "../../assets/icons/icon/enterprises.svg";
import { ReactComponent as Assesment } from "../../assets/icons/icon/assesment.svg";
import { ReactComponent as Appointments } from "../../assets/icons/icon/appointment.svg";
import { ReactComponent as Payments } from "../../assets/icons/icon/payment.svg";
import { ReactComponent as Services } from "../../assets/icons/icon/services.svg";
import { ReactComponent as Subscriptions } from "../../assets/icons/icon/subscriptions.svg";
import { ReactComponent as Events } from "../../assets/icons/icon/events.svg";
import { ReactComponent as Activities } from "../../assets/icons/icon/activity.svg";
import { ReactComponent as Samvedna } from "../../assets/icons/icon/samvedna.svg";
import { ReactComponent as Progress } from "../../assets/icons/icon/progress.svg";
import { ReactComponent as Report } from "../../assets/icons/icon/report.svg";
import { ReactComponent as Logout } from "../../assets/icons/icon/log-out.svg";
import { ReactComponent as SubscriptionEnterprise } from "../../assets/icons/icon/SubscriptionEnterprise.svg";
import { ReactComponent as Account } from "../../assets/icons/icon/user.svg";
import { FiHelpCircle } from "react-icons/fi";
import { ReactComponent as PatientSubScription } from "../../assets/icons/icon/SubscriptionEnterprise.svg";
import { ReactComponent as Doctors } from "../../assets/icons/icon/doctors.svg";
import { ReactComponent as HelpCircle } from "../../assets/icons/icon/help-circle.svg";

import { ReactComponent as WellnessTracker } from "../../assets/icon/wellnessTracker.svg";

import classes from "../../assets/css/sidebarLink.module.css";
import AuthContext from "../../Auth/AuthContext";
import { toast } from "react-hot-toast";
import {
  setFilter,
  setFrom_date,
  setSearch_key,
  setTo_date,
  setClear,
} from "../../Redux/Slices/AdminFilters/Search";
import { useDispatch } from "react-redux";
function SidebarLink({
  text,
  id,
  routesname,
  activeRole,
  setDrawer,
  disableId,
  logout,
  role,
  selected,
  setSelected,
}) {
  const navigate = useNavigate();
  const ctx = useContext(AuthContext);

  // function SidebarLink({ text, id, link, patient }) {
  const Icons = () => {
    // eslint-disable-next-line default-case
    switch (id) {
      case 1:
        return activeRole ? <Dashboard /> : <Dashboard />;
      case 2:
        return activeRole ? <Patients /> : <Assesment />;
      case 3:
        return activeRole ? <Enterprises /> : <Subscriptions />;
      case 4:
        return activeRole ? <Assesment /> : ""; //<FiBarChart2/>
      case 5:
        return activeRole ? <Appointments /> : <Appointments />;
      case 6:
        return activeRole ? <Payments /> : <Events />;
      case 7:
        return activeRole ? <Services /> : ""; //<IoTimeOutline/>
      case 8:
        return activeRole ? <Subscriptions /> : <Services />;
      case 9:
        return activeRole ? <Events /> : "";
      case 10:
        return activeRole ? <Activities /> : "";
      case 11:
        return activeRole ? <Samvedna /> : "";
      case 33:
        return activeRole ? <WellnessTracker /> : "";
      case 12:
        return <Dashboard />;
      case 13:
        return <Assesment />;
      case 14:
        return <Subscriptions />;
      case 15:
        return <Progress />;
      case 16:
        return <Appointments />;
      case 17:
        return <Services />;
      case 18:
        return <PatientSubScription />;
      case 19:
        return <Events />;
      case 20:
        return <Report />;
      case 21:
        return <Doctors />;
      case 22:
        return <Events />;
      case 23:
        return <HelpCircle />;
      case 35:
        return activeRole ? <Report /> : "";
      case 51:
        return <Dashboard />;
      case 52:
        return <Assesment />;
      case 53:
        return <Services />;
      case 54:
        return <SubscriptionEnterprise />;
      case 55:
        return <Account />;
      case 56:
        return <Subscriptions />;
      case 57:
        return <FiHelpCircle size="20" color="#00000095" />;
      case 58:
        return <Logout />;
    }
  };
  const dispatch = useDispatch();
  const setStateValue = () => {
    dispatch(setSearch_key(""));
    toast.remove();
    // setTitle(text);
    setDrawer((prev) => !prev);
    if (logout) {
      ctx.logout();
    }
    navigate("/" + routesname);
    // setSelected(routesname);
  };

  let roleBasedClass = {
    "Admin!$23": classes.link,
    client: classes.patientLink,
    "enterprise!$23": classes.enterpriseLink,
  };

  return (
    <div
      id={disableId}
      className={`${classes.sidebarlink}  ${
        selected === routesname ? classes.patientActive : null
      }`}
      onClick={setStateValue}
    >
      {/* <Link className={classes.sidebarlink} to={`/${link}`}> */}
      <div className={roleBasedClass[role]}>
        <List>
          <ListItem>
            <ListItemIcon style={{ minWidth: "30px" }}>{Icons()}</ListItemIcon>
            <ListItemText>{text}</ListItemText>
          </ListItem>
        </List>
      </div>
    </div>
  );
}
export default SidebarLink;
