import React, { useEffect, useState } from "react";

import ButtonName from "../../../MockData/ButtonName.json";

import Text_Seach_Filter_Export from "../../../Hooks/Text_Seach_Filter_Export";

import { assessmentData } from "../../../Redux/Slices/AssessmentTableReducer";
import axios from "../../../config/axiosConfig";

import AllTabs from "../../../Component/UI/Tabs/AllTabs";

import { quickAskSelector } from "../../../Redux/Slices/QuickAsk";
import { todayActivitySelector } from "../../../Redux/Slices/TodayActivitySlice";
import { setPaymentData } from "../../../Redux/Slices/Payments/PaymentSlices";
import { Pending } from "./Pending";
import { Completed } from "./Completed";
import { Cancelled } from "./Cancelled";
import { Failed } from "./Failed";
import { useSelector } from "react-redux";
import SimpleBreadcrum from "../../../Component/UI/SimpleBreadcrum";
import { ADMIN_ROUTES } from "../../../Routes/routesConstants";
import { useLocation } from "../../../../node_modules/react-router-dom/index";
import { Expired } from "./Expired";
import { findFunc } from "../../../config/methods";
import { usePermissions } from "../../../Hooks/usePermissions";

export default function AdminAssessment() {
  const btnApi = ButtonName.Payments;
  const permissions = usePermissions("name", "patients");
  // permission code
  const payPermissions = usePermissions("name", "payments");
  console.log(payPermissions, "payPermissions");
  // permission end
  const { paymentData } = useSelector((state) => state.PaymentSlices);
  //   const [id, name, date_of_creation] = data;

  let defaultTab = Object.keys(btnApi[0]);
  const [tabID, selectedTabID] = useState(defaultTab[0]);
  const { state } = useLocation();

  const Routes = {
    Assessment: "/AddAssessment",
    Quick: "/addcategoryquickask",
    TActivity: "/addcategorytodayactivity",
  };

  const ComponentName = [
    <Pending permissions={payPermissions} />,
    <Completed permissions={payPermissions} />,
    <Cancelled permissions={payPermissions} />,
    <Failed permissions={payPermissions} />,
    <Expired permissions={payPermissions} />,
  ];

  const links = [
    {
      path: ADMIN_ROUTES.PATIENTS,
      linkText: "Patients",
    },
    {
      path: ADMIN_ROUTES.PATIENT_PROFILE,
      linkText: state?.name,
      data: { ...state, permissions },
    },
  ];

  return (
    <>
      {state?.props ? (
        <SimpleBreadcrum link={links} text="General" noHeading />
      ) : null}
      <Text_Seach_Filter_Export
        resetMargin
        text={"Payments"}
        ApiData={paymentData}
        searchText="Search for payment"
        permissions={payPermissions?.listControl}
      >
        <AllTabs
          ComponentName={ComponentName}
          btnApi={btnApi}
          selectedTabID={selectedTabID}
        />
      </Text_Seach_Filter_Export>
    </>
  );
}
