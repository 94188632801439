import React from "react";
import { useLocation } from "react-router-dom";
import moment from "../../../node_modules/moment/moment";
import classes from "../../assets/css/appointmentdetails.module.css";
import BasicBreadcrumbs from "../../Component/UI/BreadCrumb/BreadCrumbs";
import { Heading } from "../../Component/UI/Heading";
import { to12Hour } from "../../config/formattedDate";

export const AppointmentDetails = () => {
  const links = [
    {
      path: "/Appointments",
      linkText: "Appointments",
    },
  ];

  const { state } = useLocation();

  const user = state.data;

  console.log({ user }, "userrrr");

  return (
    <BasicBreadcrumbs link={links} text="Appointments Details ">
      <div className={classes.app_container}>
        <div className={classes.app_box}>
          <p className={classes.sec_id}>Session ID</p>
          <p className={classes.sec_det}>{user.session_id.toUpperCase()}</p>
        </div>
        <div className={classes.app_box}>
          <p className={classes.sec_id}>Date</p>
          <p className={classes.sec_det}>
            {moment(user.appointment_date).format("DD/MM/YYYY")}
          </p>
        </div>
        {/* <div className={classes.app_box}>
          <p className={classes.sec_id}>Service Name</p>
          <p className={classes.sec_det}>Dementia</p>
        </div> */}
        <div className={classes.app_box}>
          <p className={classes.sec_id}>Session Time</p>
          <p className={classes.sec_det}>{to12Hour(user.appointment_time)}</p>
        </div>
        <div className={classes.app_box}>
          <p className={classes.sec_id}>Session taken by</p>
          <p className={classes.sec_det}>{user.staff || "N/A"}</p>
        </div>
        <div className={classes.app_box}>
          <p className={classes.sec_id}>Patient</p>
          <p className={classes.sec_det}>{user.paitient_name}</p>
        </div>

        <div className={classes.app_box}>
          <p className={classes.sec_id}>Address</p>
          <p className={classes.sec_det}>{user.address}</p>
        </div>
        {/* <div className={classes.app_box}>
          <p className={classes.sec_id}>Mode</p>
          <p className={classes.sec_det} style={{ color: "#18BA4B" }}>
            Online
          </p>
        </div> */}
      </div>
    </BasicBreadcrumbs>
  );
};
