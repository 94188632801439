import React from "react";
import {
  Duration,
  Input,
  RadioCard,
  RadioChoice,
  Select,
  SelectCard,
  Time,
  expiryDate,
} from "../../../Component/UI/CustomInputs";
import { RiDeleteBinLine } from "react-icons/ri";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import moment from "../../../../node_modules/moment/moment";

export const ServicesCard = ({
  handleChange,
  data,
  services,
  handleDel,
  removeVisible,
  index,
  error,
  expiryDate,
  subscription_type,
}) => {
  const isExpired = moment(expiryDate).isBefore(new Date(), "day");
  const radioOptions = [
    {
      name: "Yes",
      checked: data?.taxApp === "1",
    },
    {
      name: "No",
      checked: data?.taxApp === "0" || data?.taxApp === "",
    },
  ];

  const intervalOptions = [
    {
      id: 0,
      value: "Daily",
    },
    {
      id: 1,
      value: "Weekly",
    },
    {
      id: 2,
      value: "Monthly",
    },
    {
      id: 3,
      value: "Twice a week",
    },
    {
      id: 4,
      value: "Thrice a week",
    },
    subscription_type === "1"
      ? {
          id: 5,
          value: "Hourly",
        }
      : undefined,
  ].filter((option) => option !== undefined);

  console.log(data, "data");

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Select
            name="service_id"
            label="Service"
            value={data?.service_id}
            width="260px"
            fontSize={15}
            margin="0 4px"
            labelSize="14px"
            error={error?.service_id}
            options={services}
            onChange={handleChange}
            disabled={expiryDate}
            req
          />
          <Input
            name="unit_price"
            label="Service Price"
            width="260px"
            fontSize={15}
            margin="0 4px"
            labelSize="14px"
            value={data?.unit_price}
            disabled
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: error?.tax_value?.toString() ? 22 : 0,
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Input
                name="tax_applicable"
                label="Tax Applicable (%)"
                width="121px"
                fontSize={15}
                value={data?.tax_applicable === "1" ? "Yes" : "No"}
                margin="0 4px"
                labelSize="14px"
                color="#000"
                disabled
              />
              <Input
                name="tax_value"
                type="number"
                width="121px"
                placeholder="Percentage"
                fontSize={15}
                margin="0 4px"
                marginTop="22px"
                value={data?.tax_value}
                // error={error?.tax_value}
                labelSize="14px"
                onChange={handleChange}
                disabled={data?.tax_applicable !== "1" || expiryDate}
              />
            </div>
            <p
              style={{
                color: "red",
                margin: "5px",
              }}
            >
              {error?.tax_value?.toString()}
            </p>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Input
            name="number_session"
            type="number"
            label="No. of sessions"
            width="260px"
            margin="0 4px"
            labelSize="14px"
            fontSize={15}
            error={error?.number_session}
            value={data?.number_session || ""}
            onChange={handleChange}
            disabled={expiryDate}
            req
          />
          <Select
            name="interval_type"
            label="Interval type"
            width="260px"
            margin="0 4px"
            labelSize="14px"
            fontSize={15}
            error={error?.interval_type}
            options={intervalOptions}
            value={data?.interval_type}
            onChange={handleChange}
            disabled={expiryDate}
            req
          />
          <Duration
            width="260px"
            label="Duration (HH:MM)"
            labelSize="14px"
            error={error?.duration}
            value={data?.duration || "00:00"}
            onChange={(value) => {
              const e = {
                target: {
                  name: "duration",
                  value,
                },
              };
              handleChange(e);
            }}
            disabled={expiryDate}
            req
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Input
            name="minimum_validity"
            label="Minimum Validity (Days)"
            placeholder="No. of Days"
            width="260px"
            margin="0 4px"
            labelSize="14px"
            fontSize={15}
            value={data?.minimum_validity || ""}
            onChange={handleChange}
            disabled
          />
          <Input
            name="total_price"
            value={data?.total_price}
            label="Sub Total"
            width="260px"
            margin="0 4px"
            labelSize="14px"
            fontSize={15}
            onChange={handleChange}
            disabled
          />
          <RadioCard
            label="Mode"
            width={260}
            titleMargin="0 0 10px 0"
            options={["Offline", "Online", "Hybrid"]}
            value={data?.mode}
            name="mode"
            disabled
          />
        </div>
        {removeVisible && !expiryDate ? (
          <div
            style={{
              width: 260,
              height: 58,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "end",
              alignSelf: "end",
            }}
          >
            <Btn
              variant="contained"
              borderRadius="50px"
              width={100}
              height={40}
              bgColor="#00B091"
              click={handleDel}
            >
              Delete
            </Btn>
          </div>
        ) : null}
        {/* <Input
          name="duration"
          label="Duration (Mins)"
          width="122px"
          margin="0 4px"
          labelSize="14px"
          fontSize={15}
          onChange={handleChange}
        />
        <Select
          name="interval"
          label="Interval type"
          width="122px"
          margin="0 4px"
          labelSize="14px"
          fontSize={15}
          options={intervalOptions}
          onChange={handleChange}
        />
        <Input
          name="session"
          label="No. of sessions"
          width="122px"
          margin="0 4px"
          labelSize="14px"
          fontSize={15}
          onChange={handleChange}
        />
        <RadioChoice
          label="Tax applicable"
          options={radioOptions}
          inputName={"taxApp"}
          labelSize="14px"
          index={index}
          disabled
        />
        <Input
          name="tax_percent"
          label="Tax %"
          width="122px"
          margin="0 4px"
          labelSize="14px"
          fontSize={15}
          onChange={handleChange}
          disabled={data?.taxApp === "0"}
        /> */}
      </div>
    </div>
  );
};
