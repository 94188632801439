import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "../../../../../../node_modules/moment/moment";
import { Trash } from "../../../../../assets/icons/Trash";
import { CustomTable } from "../../../../../Component/UI/Table";
import {
  listTestimonial,
  updateStatusTestimonial,
  updateTestimonial,
} from "../../../../../services/admin/testimonial";
import { getAcceptedTestimonials } from "../../../../../Redux/Slices/adminTestimonial";
import { Rating } from "./Rating";
import { useState } from "react";
import Popup from "../../../../../Component/UI/Popup/Popup";
import { useNavigate } from "../../../../../../node_modules/react-router-dom/index";
import { Avatar } from "../../../../../Component/UI/Avatar";
import {
  setFilter,
  setFrom_date,
  setSearch_key,
  setTo_date,
} from "../../../../../Redux/Slices/AdminFilters/Search";
import SwitchToggle from "../../../../../Component/UI/Switch";
import { toast } from "../../../../../../node_modules/react-hot-toast/dist/index";

export const AcceptedTestimonial = ({
  customerId,
  testimonialsPermissions,
  pFeedback,
}) => {
  const fullpermissions = pFeedback
    ? 1
    : +testimonialsPermissions?.access?.["Full Control"];
  const { acceptedTestimonials } = useSelector((i) => i.adminTestimonial);
  const dispatch = useDispatch();
  const [popupAction, setPopupAction] = useState({ open: false });
  const [popupActionStatus, setPopupActionStatus] = useState({ open: false });

  const tableHeadings = [
    "Date",
    "Name",
    "Type",
    "Rating",
    "Description",
    ...(fullpermissions ? ["Status"] : []),
    ...(fullpermissions ? ["Action"] : []),
  ];
  const navigate = useNavigate();

  const { search_key, from_date, to_date, filter } = useSelector(
    (i) => i.SearchFilters
  );

  const getAcceptedTestimonialList = () =>
    listTestimonial(2, customerId, search_key, from_date, to_date).then(
      (res) => {
        const data = res?.data;
        if (data?.status) dispatch(getAcceptedTestimonials(data?.list));
      }
    );

  useEffect(() => {
    getAcceptedTestimonialList();
  }, [filter]);

  useEffect(() => {
    return () => {
      dispatch(setSearch_key(""));
      dispatch(setTo_date(""));
      dispatch(setFrom_date(""));
      dispatch(setFilter());
    };
  }, []);

  const handleDelete = (id, status) => {
    setPopupAction({
      ...popupAction,
      open: true,
      action: () => {
        updateTestimonial(id, status).then((res) => {
          const data = res?.data;
          if (data?.status) {
            getAcceptedTestimonialList();
            setPopupAction({ ...popupAction, open: false });
          }
        });
      },
    });
  };

  const handleStatusToggle = (id, status) => {
    setPopupActionStatus({
      ...popupActionStatus,
      open: true,
      action: () => {
        const data = {
          id: id,
          status: !status,
        };
        updateStatusTestimonial(data).then((res) => {
          const data = res?.data;
          if (data?.status) {
            getAcceptedTestimonialList();
            setPopupActionStatus({ ...popupActionStatus, open: false });
            toast.success(data?.msg);
          } else {
            toast.success(data?.msg);
          }
        });
      },
    });
  };

  const tBodyData = acceptedTestimonials.map((item) => {
    const {
      id,
      thoughts,
      customer,
      created_at,
      rating,
      is_active,
      enterprise,
      enterprise_id,
    } = item;
    const date = moment(created_at).format("DD/MM/YYYY");

    return {
      date,
      name: (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            height: 0,
          }}
        >
          <Avatar
            size="28px"
            profile={customer?.customer_image}
            border="1px solid #ccc"
          />
          <div
            style={{ cursor: "pointer", marginLeft: "8px" }}
            onClick={() => navigate("details", { state: { item, customerId } })}
          >
            {customer?.name ? customer?.name : enterprise?.name}
          </div>
        </span>
      ),
      type: enterprise_id != null ? "Enterprise" : "General",
      rating: <Rating ratings={rating} />,
      thoughts: `${thoughts.slice(0, 30)}${thoughts.length > 30 ? "..." : ""}`,
      ...(fullpermissions && {
        status: (
          <div key={id}>
            <SwitchToggle
              status={is_active}
              // disabled={!isExpired && expiry_at !== null}
              onClick={() => handleStatusToggle(id, is_active)}
              // disabled
            />
          </div>
        ),
      }),
      ...(fullpermissions && {
        action: (
          <div style={{ display: "flex" }}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleDelete(id, 0)}
            >
              <Trash />
            </div>
          </div>
        ),
      }),
    };
  });

  return (
    <>
      <Popup
        popupAction={popupAction}
        setPopupAction={setPopupAction}
        heading="Delete Testimonial"
        title="Are you sure you want to delete this testimonial?"
        styles={{ width: 400, height: 230 }}
        btnMargin="0 10px"
      />
      <Popup
        popupAction={popupActionStatus}
        setPopupAction={setPopupActionStatus}
        heading="Update Status"
        title="Are you sure you want to update status this testimonial?"
        styles={{ width: 400, height: 230 }}
        btnMargin="0 10px"
      />
      <CustomTable tHead={tableHeadings} tBody={tBodyData} />
    </>
  );
};
