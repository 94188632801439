import React from "react";
import ButtonName from "../../../../MockData/ButtonName.json";
import AllTabs from "../../../../Component/UI/Tabs/AllTabs";
import { Ongoing } from "./Ongoing";
import { Completed } from "./Completed";
import { useLocation } from "react-router-dom";
import { SubHeader } from "../../../../Hooks/SubHeader";
import SimpleBreadcrum from "../../../../Component/UI/SimpleBreadcrum";
import { ADMIN_ROUTES } from "../../../../Routes/routesConstants";
import { usePermissions } from "../../../../Hooks/usePermissions";

export const SelfAssessment = () => {
  const { state } = useLocation();
  const id = state?.props;
  const btnApi = ButtonName.SelfAssessment;

  const ComponentName = [<Ongoing id={id} />, <Completed id={id} />];
  const permissions = usePermissions("name", "patients");

  const links = [
    {
      path: ADMIN_ROUTES.PATIENTS,
      linkText: "Patients",
    },
    {
      path: ADMIN_ROUTES.PATIENT_PROFILE,
      linkText: state?.name,
      data: { ...state, permissions },
    },
  ];
  console.log(permissions, "permissions---");

  return (
    <>
      <SimpleBreadcrum link={links} text="Assessments" noHeading />
      <SubHeader text="Self Assessments" basic noPaddingTop>
        <AllTabs ComponentName={ComponentName} btnApi={btnApi} />
      </SubHeader>
    </>
  );
};
