import { Display } from "./Display";
import { Select } from "./Select";
import styles from "../../../assets/css/PatientUI/monthpicker.module.css";
import { useDispatch, useSelector } from "react-redux";
import OutsideAlerter from "../../../Hooks/useOutsideAlerter";
import { setToggle } from "../../../Redux/Slices/Patient/PatienCalender";
import { useEffect, useRef } from "react";

export const MonthPicker = () => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date = new Date();
  const fullYear = date.getFullYear();
  const month = months[date.getMonth()];

  const { selectedDate, toggle, year } = useSelector((i) => i.DateFilterSlice);

  const dispatch = useDispatch();
  const calenderRef = useRef(null);
  const outsideClick = () => {
    dispatch(setToggle(false));
  };

  // const [year, setYear] = useState(fullYear);
  // const [toggle, setToggle] = useState(false);
  // const [displayDate, setDisplayDate] = useState(`${month} ${fullYear}`);

  // useEffect(() => {
  //   if (displayDate.includes("All")) {
  //     dispatch(setDate(""));
  //   } else {
  //     dispatch(setDate(displayDate));
  //   }
  // }, [toggle]);

  // useEffect(() => {

  // },[selectedDate])

  return (
    <OutsideAlerter customfunc={outsideClick} wrapperRef={calenderRef}>
      <div className={styles.monthpicker}>
        <Display
          selectedDate={selectedDate || `${month} ${year}`}
          dateObj={date}
          styles={styles}
        />
        {toggle && (
          <Select
            styles={styles}
            // months={["All", ...months]}
            months={months}
            dateObj={date}
            year={year}
            month={month}
            fullYear={fullYear}
            // setYear={setYear}
            // setSelectedDate={setDisplayDate}
          />
        )}
      </div>
    </OutsideAlerter>
  );
};
