import React, { useEffect, useState } from "react";
import { ActivityCard } from "../../ActivityCard/ActivityCard";
import instance from "../../../config/axiosConfig";
import { Wellness_Tracker_Activity } from "../../../services/patient/wellnessTracker";
import { useDispatch, useSelector } from "react-redux";
import { setWeekly } from "../../../Redux/Slices/WellnessTracker/ActivityList";

const WeeklyCard = ({ week, from, to }) => {
  const { month, year } = useSelector((state) => state.MonthChangerSlice);
  const { Weekly } = useSelector((state) => state.wellnessActivitySlice);
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    Wellness_Tracker_Activity({ month, year, from, to })
      .then((res) => {
        if (res.status) {
          dispatch(setWeekly(res?.list));
        }
      })
      .finally(() => setLoaded(true));

    return () => {
      dispatch(setWeekly([]));
    };
  }, [month, year]);

  let FinalData = Weekly.filter((item) => {
    return item.answer;
  });

  return (
    <>
      {FinalData?.length ? (
        FinalData?.map((item) => {
          return (
            <div style={{ marginBottom: "10px" }}>
              <ActivityCard
                disabled={true}
                activityData={item.answer}
                question={item.answer.question.question}
                toggle={item.answer.answer}
                activityDate={item.date}
                activityAnswer={item.answer}
                icon={item?.answer?.question?.question_image}
                loaded={loaded}
              />
            </div>
          );
        })
      ) : (
        <div style={{ margin: "30px auto", textAlign: "center" }}>
          <p>No Activity Available </p>
        </div>
      )}
    </>
  );
};

export default WeeklyCard;
