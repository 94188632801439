import React, { useEffect } from "react";
import styles from "../../assets/css/MyAccount/profile.module.css";
import { MyAccount } from "../../HOC/MyAccount";
import { UploadProfile } from "../../Component/UI/UploadProfile";
import {
  Datepicker,
  Input,
  Select,
  SelectWithId,
} from "../../Component/UI/CustomInputs";
import { editProfile, getProfile } from "../../services/patient/profile";
import { Btn } from "../UI/Button";
import { useState } from "react";
import { formattedLocations } from "../../config/formattedLocations";
import {
  listCity,
  listCountries,
  listState,
} from "../../services/patient/locations";
import { newDateFormat, StringDtoY } from "../../config/formattedDate";
import toast from "react-hot-toast";
import { useAuth } from "../../Auth/AuthProvider";
import CountryCodes from "../../assets/common/countryCode.json";
import { CircularProgress } from "../../../node_modules/@mui/material/index";
const Profile = () => {
  const rgx =
    /^(?!.*(\d)\1{9})(?!.*1234567890)(?!^00)(\([0-9]{3}\)\s*|[0-9]{3}[-/.]?)?[0-9]{3}[-/.]?[0-9]{4}$/;
  const [userProfile, setUserProfile] = React.useState({
    country: "101",
    countryCode: "+91",
  });
  console.log(userProfile, "userProfile");
  const [countries, setCountries] = useState();
  const [states, setStates] = useState();
  const [cities, setCities] = useState([]);
  const [err, setErr] = useState();
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const ctx = useAuth();

  useEffect(() => {
    getProfile().then((i) => {
      const splitMono = i?.mobile?.split("-");
      const mobileNumber = splitMono[1] ? splitMono[1] : i?.mobile;
      const countryCode = splitMono[0] ? splitMono[0] : "+91";

      const data = {
        ...i,
        avtar: "",
        country: "101",
        dob: i.dob === "" ? "1970-1-1" : i?.dob,
        mobile: mobileNumber ? mobileNumber : i?.mobile,
        countryCode: countryCode?.length < 5 ? countryCode : "+91",
      };
      setUserProfile(data);
      ctx.auth({
        ...ctx.loginUser,
        customer: { ...ctx.loginUser.customer, ...data },
      });
    });
  }, [isDataUpdated]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserProfile({ ...userProfile, [name]: value });
  };

  useEffect(() => {
    listCountries().then((res) => {
      const formattedCountries = formattedLocations(res, "name");
      setCountries(formattedCountries);
    });

    userProfile?.country &&
      listState(userProfile?.country).then((res) => {
        const formattedStates = formattedLocations(res, "name");
        setStates(formattedStates);
      });

    userProfile?.state &&
      listCity(userProfile?.state).then((res) => {
        const formattedCities = formattedLocations(res, "city");
        setCities(formattedCities);
      });
  }, [userProfile.country, userProfile.state]);

  const handleClick = () => {
    setIsLoading(true);
    toast.remove();
    const isNum = rgx.test(userProfile?.mobile);
    if (!isNum) {
      setIsLoading(false);
      toast.error("Invalid mobile number!");
      return;
    }
    const payload = {
      ...userProfile,
      mobile: `${userProfile?.countryCode}-${userProfile?.mobile}`,
    };
    const formData = new FormData();
    for (let key in payload) formData.append(key, payload[key]);

    editProfile(formData).then((res) => {
      setIsLoading(false);
      const data = res?.data;
      if (data?.status) {
        toast.success("Profile Updated Successfully!");
        setErr();
        setIsDataUpdated(!isDataUpdated);
      }
      if (data?.error) {
        if (data?.error?.avtar) toast.error(data?.error?.avtar);
        else toast.error("Please fill all required data.");
        setErr(data?.error);
      }
    });
  };

  return (
    <>
      <div className={styles.profile}>
        <div className={styles.avatar}>
          <UploadProfile
            name="avtar"
            profile={userProfile?.customer_image}
            upload={(value) => {
              setUserProfile({ ...userProfile, avtar: value });
            }}
          />
        </div>
        <div className={styles.form}>
          <div className={styles.personal_details}>
            <h2>Personal Details</h2>
            <div>
              <div>
                <Input
                  name="name"
                  label="Full Name"
                  value={userProfile?.name}
                  error={err?.name}
                  onChange={handleChange}
                  margin="8px 10px"
                  req
                />
                <Input
                  name="email"
                  type="email"
                  error={err?.email}
                  value={userProfile?.email}
                  label="Email Address"
                  onChange={handleChange}
                  margin="8px 10px"
                  req
                />
              </div>
              <div>
                <SelectWithId
                  margin="8px 10px"
                  options={CountryCodes}
                  width="400px"
                  label="Country Code"
                  value={userProfile?.countryCode}
                  onChange={(e) => {
                    setUserProfile({
                      ...userProfile,
                      countryCode: e.target.value,
                    });
                  }}
                />
                <Input
                  name="mobile"
                  type="text"
                  value={userProfile?.mobile}
                  error={err?.mobile}
                  label="Mobile Number"
                  placeholder="Mobile"
                  onChange={(e) => {
                    setUserProfile({
                      ...userProfile,
                      mobile: e.target.value,
                    });
                  }}
                  margin="8px 10px"
                  // req
                />
                <Select
                  name="year_of_education"
                  error={err?.year_of_education}
                  value={userProfile?.year_of_education}
                  options={[
                    { id: 1, value: "1-4 years" },
                    { id: 2, value: "5-8 years" },
                    { id: 3, value: "9-12 years" },
                    { id: 4, value: "<15 years" },
                    { id: 5, value: "15+ years" },
                  ]}
                  label="Years of Education"
                  onChange={handleChange}
                  margin="8px 10px"
                  // req
                />
              </div>
              <div>
                <div>
                  <Datepicker
                    name="dob"
                    label="Date of Birth"
                    defaultDate={newDateFormat(userProfile?.dob)}
                    error={err?.dob}
                    enableLabel
                    onChange={(value) =>
                      setUserProfile({ ...userProfile, dob: StringDtoY(value) })
                    }
                    margin="8px 10px"
                    editable
                    // req
                  />
                </div>
                <div>
                  <Select
                    margin="8px 10px"
                    name="marital_status"
                    options={[
                      { id: 1, value: "Unmarried" },
                      { id: 2, value: "Married" },
                      { id: 3, value: "Divorced" },
                      { id: 4, value: "Separated" },
                      { id: 5, value: "Widowed" },
                    ]}
                    label="Marital Status"
                    error={err?.marital_status}
                    value={userProfile?.marital_status}
                    onChange={handleChange}
                    // req
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.address}>
            <h2 style={{ marginLeft: "5px" }}>Address</h2>
            <div>
              <div>
                <Input
                  name="address_line1"
                  label="Address 1"
                  error={err?.address_line1}
                  value={userProfile?.address_line1}
                  onChange={handleChange}
                  margin="8px 10px"
                  // req
                />
                <Input
                  name="address_line2"
                  label="Address 2"
                  error={err?.address_line2}
                  value={userProfile?.address_line2}
                  onChange={handleChange}
                  margin="8px 10px"
                  // req
                />
              </div>
              <div>
                <div>
                  <Select
                    name="country"
                    value={userProfile?.country}
                    label="Country"
                    error={err?.country}
                    options={countries}
                    onChange={handleChange}
                    margin="8px 10px"
                    // req
                  />
                </div>
                <div>
                  <Select
                    name="state"
                    value={userProfile?.state}
                    label="State"
                    error={err?.state}
                    options={states}
                    onChange={handleChange}
                    margin="8px 10px"
                    // req
                  />
                </div>
              </div>
              <div>
                <div>
                  <Select
                    name="city"
                    value={userProfile?.city}
                    label="City"
                    error={err?.city}
                    options={cities}
                    onChange={handleChange}
                    margin="8px 10px"
                    // req
                  />
                </div>
                <div>
                  <Input
                    name="zipcode"
                    value={userProfile?.zipcode}
                    label="Zipcode"
                    error={err?.zipcode}
                    onChange={handleChange}
                    margin="8px 10px"
                    // req
                  />
                </div>
              </div>
            </div>
            <div className={styles.button}>
              <Btn
                disabled={isLoading}
                variant="contained"
                click={handleClick}
                width="160px"
                height="48px"
              >
                {isLoading ? <CircularProgress size={28} /> : "Update"}
              </Btn>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ProfileHOC = MyAccount(Profile);
