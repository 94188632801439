import styles from "./patientHeader.module.css";
import { UserPanel } from "../../Samvenda-Patient/UserPanel";
import { Notification } from "../UI/Notification";
import { Search } from "../../assets/icons/Search";
import { MdMenu } from "react-icons/md";
import { useState } from "react";
import { PatientSearch } from "./PatientSearch";
import { Cross } from "../../assets/icons/Cross";
import { useDispatch, useSelector } from "react-redux";
import {
  setSearchText,
  setToggleSearch,
} from "../../Redux/Slices/patientSearch";
import { useEffect } from "react";
import { useAuth } from "../../Auth/AuthProvider";
import { useNavigate } from "../../../node_modules/react-router-dom/index";
import EnterPrisePopup from "../../Samvenda-Patient/UI/EnterprisePopup";
import AuthContext from "../../Auth/AuthContext";
import { useContext } from "react";
import toast from "../../../node_modules/react-hot-toast/dist/index";
import { mobileNoSave } from "../../services/patient/socialLogin";

export const PatientHeader = ({ setDrawer, drawer, title, currentPath }) => {
  const Toggle = () => {
    // const getState = localStorage.getItem('sideBar');
    // localStorage.setItem('sideBar', true);
  };

  const rgx =
    /^(?!.*(\d)\1{9})(?!.*1234567890)(?!^00)(\([0-9]{3}\)\s*|[0-9]{3}[-/.]?)?[0-9]{3}[-/.]?[0-9]{4}$/;

  const ctx = useContext(AuthContext);
  const [mobileNo, setMobileNo] = useState();
  const [countryCode, setCountryCode] = useState("+91");
  const enterpriseId = ctx.loginUser.customer.enterprise_id;
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [customerAssessment, setCustomerAssessment] = useState();

  //mobile popup
  const [popupAction, setPopupAction] = useState({
    open: false,
    // open: true,
    name: "",
    title:
      "Please note assessments rolled out by your organization are available in Plans tab under Assessment & Plans menu option. Your organization assessment reports can be viewed in Reports menu, Enterprise tab.      ",
  });

  useEffect(() => {
    debugger;
    if (!!enterpriseId) {
      if (
        ctx?.loginUser?.customer?.enterprise_id !== null &&
        ctx?.loginUser?.showPopup
      ) {
        setPopupAction({
          ...popupAction,
          open: true,
        });
      }
    } else if (ctx?.loginUser?.customer?.mobile === null) {
      setPopupAction({
        ...popupAction,
        open: true,
      });
    }
  }, [enterpriseId, customerAssessment]);

  const handleClose = () => {
    ctx.auth({
      ...ctx?.loginUser,
      showPopup: false,
    });
    setPopupAction({ ...popupAction, open: false });
  };

  const mobileNoSubmit = () => {
    toast.remove();
    toast.remove();
    if (!mobileNo) {
      toast.error("Mobile no is required");
      return;
    }
    const isNum = rgx.test(mobileNo);
    if (!isNum) {
      toast.error("Invalid mobile number!");
      return;
    }
    let newUserpaLoad = {
      mobile: `${countryCode}-${mobileNo}`,
    };
    submitMobileNo(newUserpaLoad);
  };

  const submitMobileNo = (data) => {
    setIsLoadingUser(true);
    mobileNoSave(data).then((res) => {
      setIsLoadingUser(false);
      if (res?.data?.status) {
        toast.success(res?.data?.msg);
      }
      ctx.auth({
        ...ctx?.loginUser,
        showPopup: false,
      });
      setPopupAction({ ...popupAction, open: false });
    });
  };

  const { toggleSearch } = useSelector((state) => state.patientSearch);

  // const [toggleSearch, setToggleSearch] = useState(false);
  const dispatch = useDispatch();
  const searchVisiible = ["Events", "Services", "Appointments"].includes(title);

  // const ctx = useAuth();
  // useEffect(() => {
  //   !JSON.parse(localStorage.getItem("mydata"))?.is_loggedin && ctx.logout();
  // }, [currentPath]);

  return (
    <>
      <EnterPrisePopup
        popupAction={popupAction}
        setPopupAction={setPopupAction}
        title={popupAction.title}
        description={popupAction.description}
        handleClose={handleClose}
        enterpriseMobile={ctx?.loginUser?.customer}
        mobileNo={mobileNo}
        setMobileNo={setMobileNo}
        mobileNoSubmit={mobileNoSubmit}
        isLoadingUser={isLoadingUser}
        setCountryCode={setCountryCode}
        countryCode={countryCode}
        enterpriseId={enterpriseId}
      />

      <header className={styles.header}>
        <div className={styles.enableHeader}>
          <div className={styles.MenuEnable} onClick={() => setDrawer(!drawer)}>
            <MdMenu size={32} />
          </div>
          <h1 className={styles.title}>{title}</h1>
        </div>
        <div
          className={styles.options}
          // style={{ width: searchVisiible ? "300" : "50" }}
        >
          {searchVisiible && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 6px",
              }}
            >
              <div style={{ visibility: toggleSearch ? "visible" : "hidden" }}>
                <PatientSearch />
              </div>
              <div
                style={{ cursor: "pointer", width: "50px" }}
                onClick={() => dispatch(setToggleSearch(!toggleSearch))}
              >
                {!toggleSearch ? (
                  <Search />
                ) : (
                  <div onClick={() => dispatch(setSearchText(""))}>
                    <Cross color="#000" size={25} />
                  </div>
                )}
              </div>
            </div>
          )}
          {/* <div style={{ width: "40px" }}>
          <Notification dot />
        </div> */}

          <div style={{ width: "50px" }}>
            <UserPanel />
          </div>
        </div>
      </header>
    </>
  );
};
