import React, { useState, useEffect } from "react";
import BasicBreadcrumbs from "../../../Component/UI/BreadCrumb/BreadCrumbs";
import { ADMIN_ROUTES } from "../../../Routes/routesConstants";

import { CustomTable } from "../../../Component/UI/Table";
import ReportData from "../../../MockData/Report.json";
import { AiOutlineDownload } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetSearchFilters } from "../../../Redux/Slices/AdminFilters/Search";
import { enterpriseAssessmentListAdmin } from "../../../services/admin/enterprise";
import Avatar from "@mui/material/Avatar";
import defaultImage from "../../../assets/icons/icon/default_profile.svg";
import moment from "../../../../node_modules/moment/moment";
import { utils, writeFile } from "xlsx";
import { getReports } from "../../../services/admin/AssessmentReport";
import {
  toast,
  Toaster,
} from "../../../../node_modules/react-hot-toast/dist/index";

const EnterpriseExcelReports = () => {
  const [assessments, setAssessments] = useState([]);
  const [assessmentData, setAssessmentData] = useState();
  const { state } = useLocation();

  const { from_date, to_date } = useSelector((state) => state.SearchFilters);
  const dispatch = useDispatch();

  useEffect(() => {
    if (to_date !== "Invalid date") {
      enterpriseAssessmentListAdmin({
        enterprise_id: state?.data?.id,
        from_date,
        to_date,
      })
        .then((res) => setAssessments(res.data.list))
        .catch((err) => console.log(err));
    }
  }, [from_date, to_date]);

  useEffect(() => {
    return () => {
      dispatch(resetSearchFilters());
    };
  }, []);

  const links = [
    {
      path: ADMIN_ROUTES.ENTERPRISES,
      linkText: "Enterprise",
    },
  ];
  if (state?.data) {
    links.push({
      path: ADMIN_ROUTES.ENTERPRISES_VIEWBOX,
      linkText: state?.data?.company_name,
      data: state?.data,
    });
  }

  const handledownload = (callback, assessmentId) => {
    getReports(assessmentId)
      .then((res) => {
        callback(res);
      })
      .catch((err) => {});
  };

  const convertExcel = (assessmentResult) => {
    const fileName = `${assessmentData.name}_Report.xlsx`;
    const ws = utils.json_to_sheet(assessmentResult.myself);
    const ws2 = utils.json_to_sheet(assessmentResult.someone_else);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Myself");
    assessmentResult.someone_else.length &&
      utils.book_append_sheet(wb, ws2, "Someone Else");

    assessmentResult.someone_else.length || assessmentResult.myself.length
      ? writeFile(wb, fileName)
      : toast.error("No data Available !");
  };

  const tHead = ["date", "id", "product_name", "action"];
  const tBodyData = assessments?.map((item) => {
    const { id, date_of_creation, name, assessment_image } = item;

    const assessments_name = (
      <div style={{ display: "inline-flex", alignItems: "center", gap: "5px" }}>
        <Avatar
          style={{
            width: "28px",
            height: "28px",
            border: "1px solid #DADADA",
          }}
          src={assessment_image || defaultImage}
        />
        <p>{name}</p>
      </div>
    );
    const date = moment(date_of_creation).format("DD/MM/YYYY");

    return {
      date,
      id,
      assessments_name,
      excelReprt: (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            handledownload(convertExcel, id);
          }}
        >
          <AiOutlineDownload size={24} />
        </div>
      ),
    };
  });

  return (
    <>
      <Toaster />
      <BasicBreadcrumbs
        link={links}
        text="Excel Reports"
        isEnableCalender={true}
      >
        <CustomTable tHead={tHead} tBody={tBodyData} />
      </BasicBreadcrumbs>
    </>
  );
};
export default EnterpriseExcelReports;
