import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editClient, getClientDetails } from "../../../services/admin/client";
import ClientDetailsForm from "./ClientDetailsForm";
import { useLocation } from "react-router-dom";
import {
  clearClient,
  clientDetails,
} from "../../../Redux/Slices/clientDetails";
import { useNavigate } from "react-router-dom";
import BasicBreadcrumbs from "../../../Component/UI/BreadCrumb/BreadCrumbs";

export const EditClient = () => {
  const { data } = useSelector((i) => i.clientDetails);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const id = state?.props;
  const [response, setResponse] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    getClientDetails(id).then((i) => {
      const splitMono = i?.mobile?.split("-");
      const mobileNumber = splitMono ? ([1] ? splitMono[1] : i?.mobile) : "";
      const countryCode = splitMono[0] ? splitMono[0] : "+91";
      const data = {
        ...i,
        avtar: "",
        mobile: mobileNumber ? mobileNumber : i?.mobile,
        countryCode: countryCode?.length < 5 ? countryCode : "+91",
      };
      dispatch(clientDetails(data));
    });
    return () => dispatch(clearClient());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    const payload = {
      ...data,
      mobile: `${data?.countryCode}-${data?.mobile}`,
    };
    const formData = new FormData();
    for (let key in payload) formData.append(key, payload[key]);
    formData.append("client_id", id);

    editClient(formData).then((i) => {
      setResponse(i?.data);
      if (i?.data?.status) {
        navigate("/patients");
      }
    });
  };

  const links = [
    {
      path: "/patients/",
      linkText: "Clients",
    },
  ];
  return (
    <BasicBreadcrumbs link={links} text="Edit Profile">
      <ClientDetailsForm err={response?.error} click={handleClick} />
    </BasicBreadcrumbs>
  );
};
