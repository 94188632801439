import React, { useEffect, useState } from "react";
import BmiCSS from "../../../assets/css/assessment/bmi.module.css";
import Age from "../../../assets/icons/icon/age.svg";
import Height from "../../../assets/icons/icon/height.svg";
import Weight from "../../../assets/icons/icon/weight.svg";
import CircularProgressWithLabel from "../../../Component/UI/CircularProgressBar/CircularProgressWithLabel";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { SetbmiCalculate } from "../../../Redux/Reducers/PatientReducers/qa_storage/qa_storage";
import { useSelector } from "react-redux";

export const BMICalc = () => {
  const dispatch = useDispatch();

  const { weight, height, age } = useSelector((state) => state.qa_storage);
  const [bmi, setbmi] = useState();
  const [bmiColor, setbmicolor] = useState();
  const [bmiDisease, setbmidisease] = useState();

  const bmiCalculatation = weight / (height * 0.01) ** 2;

  useEffect(() => {
    setbmi(bmiCalculatation);
  }, [weight, height, bmiCalculatation]);

  useEffect(() => {
    dispatch(SetbmiCalculate(bmi));
  }, [bmi]);

  useEffect(() => {
    if (bmi < 18.5999) {
      setbmidisease("Under weight");
      setbmicolor("#87b1d9");
    } else if (bmi >= 18.5999 && bmi <= 24.9999) {
      setbmidisease("Normal");
      setbmicolor("#3dd365");
    } else if (bmi >= 25 && bmi <= 29.9999) {
      setbmidisease("Over weight");
      setbmicolor("#eee133");
    } else if (bmi >= 30 && bmi <= 34.9999) {
      setbmidisease("Morbidly Obese");
      setbmicolor("#fd802e");
    } else if (bmi >= 35) {
      setbmidisease("Severely Obese");
      setbmicolor("#f95353");
    }
  }, [bmi]);

  return (
    <>
      <div className={BmiCSS.wrap}>
        <div className={BmiCSS.leftbox}>
          <ul>
            <li>
              <div className={BmiCSS.list_wrapper}>
                <img src={Age} alt="age" />
                <div>
                  <p>Age</p>
                  <h1>{age}</h1>
                </div>
              </div>
            </li>
            <li>
              <div className={BmiCSS.list_wrapper}>
                <img src={Height} alt="age" />
                <div>
                  <p>Height</p>
                  <h1>{height}</h1>
                </div>
              </div>
            </li>
            <li>
              <div className={BmiCSS.list_wrapper}>
                <img src={Weight} alt="age" />
                <div>
                  <p>Weight</p>
                  <h1>{weight}</h1>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className={BmiCSS.middlebox}>
          {/* <img src={CircularProgressBar} alt="" /> */}
          <CircularProgressWithLabel
            value={bmi}
            style={{ width: "240px", height: "240px", color: bmiColor }}
          />
        </div>
        <div className={BmiCSS.rightbox}>
          <ul>
            <li>
              <div className={BmiCSS.list_wrapper}>
                <div
                  className={BmiCSS.indicators}
                  style={{ backgroundColor: "#87B1D9" }}
                ></div>
                <div>
                  <p>Under weight</p>
                  <h2>{"<18.5"}</h2>
                </div>
              </div>
            </li>
            <li>
              <div className={BmiCSS.list_wrapper}>
                <div
                  className={BmiCSS.indicators}
                  style={{ backgroundColor: "#3DD365" }}
                ></div>
                <div>
                  <p>Normal</p>
                  <h2>{"18.5 - 24.9"}</h2>
                </div>
              </div>
            </li>
            <li>
              <div className={BmiCSS.list_wrapper}>
                <div
                  className={BmiCSS.indicators}
                  style={{ backgroundColor: "#EEE133" }}
                ></div>
                <div>
                  <p>Overweight</p>
                  <h2>{"25 - 29.9"}</h2>
                </div>
              </div>
            </li>
            <li>
              <div className={BmiCSS.list_wrapper}>
                <div
                  className={BmiCSS.indicators}
                  style={{ backgroundColor: "#FD802E" }}
                ></div>
                <div>
                  <p>Morbidly Obese</p>
                  <h2>{"30 - 34.9"}</h2>
                </div>
              </div>
            </li>
            <li>
              <div className={BmiCSS.list_wrapper}>
                <div
                  className={BmiCSS.indicators}
                  style={{ backgroundColor: "#F95353" }}
                ></div>
                <div>
                  <p>Severely Obese</p>
                  <h2>{">35"}</h2>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
