import React from "react";
import { RadioBtn } from "./elements/RadioBtn";
import { Range } from "./elements/Range";

import AssessmentOwnerCSS from "../../../assets/css/assessmentOwner.module.css";
import { CheckBox } from "./elements/Checkbox";
import { Toggle } from "./elements/Toggle";
import { BMICalc } from "./BMI_Calc";
import { useSelector } from "react-redux";
import InputText from "./elements/InputText";

const QuestionContent = ({ question, category, subquestion }) => {
  const options = question?.options?.split(",");
  const option_images = question?.option_images
    ? question?.option_images?.split(",")
    : [];

  const { multiOption } = useSelector((state) => state.qa_storage);

  return (
    <>
      {/* radio */}
      {question?.input_type.toLowerCase() === "radio" && (
        <>
          <div className={AssessmentOwnerCSS.tabbox}>
            {question.ques_image && (
              <>
                <img
                  className={AssessmentOwnerCSS.questionImagesLeft}
                  src={question?.ques_image}
                  alt=""
                />
                <img
                  className={AssessmentOwnerCSS.questionImagesRight}
                  src={question?.ques_image}
                  alt=""
                />
              </>
            )}
            <RadioBtn question={question} />
          </div>
        </>
      )}
      {/* text */}
      {question?.input_type.toLowerCase() === "range" && (
        <>
          <div className={AssessmentOwnerCSS.tabbox}>
            {question.ques_image && (
              <>
                <img
                  className={AssessmentOwnerCSS.questionImagesLeft}
                  src={question?.ques_image}
                  alt=""
                />
                <img
                  className={AssessmentOwnerCSS.questionImagesRight}
                  src={question?.ques_image}
                  alt=""
                />
              </>
            )}
            <Range question={question} subquestion={subquestion} />
          </div>
        </>
      )}
      {/* checkbox */}
      {question?.input_type.toLowerCase() === "checkbox" && (
        <>
          <div className={AssessmentOwnerCSS.tabbox}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
                flexDirection: "column",
              }}
            >
              {question.ques_image && (
                <>
                  <img
                    className={AssessmentOwnerCSS.questionImagesLeft}
                    src={question?.ques_image}
                    alt=""
                  />
                  <img
                    className={AssessmentOwnerCSS.questionImagesRight}
                    src={question?.ques_image}
                    alt=""
                  />
                </>
              )}

              {options?.map((option, index) => {
                return (
                  <>
                    <CheckBox
                      key={index}
                      label={option}
                      id={option}
                      question={question}
                      default={multiOption?.[multiOption?.indexOf(option)]}
                    />
                  </>
                );
              })}
            </div>
          </div>
        </>
      )}

      {/* toggle */}
      {question?.input_type.toLowerCase() === "toggle" && (
        <>
          <div className={AssessmentOwnerCSS.tabbox}>
            {question?.question_image && (
              <>
                <img
                  style={{ position: "absolute", left: "65%", top: "40%" }}
                  src={question?.question_image[0]}
                  alt=""
                />
                <img
                  style={{ position: "absolute", left: "10%", top: "15%" }}
                  src={question?.question_image[1]}
                  alt=""
                />
              </>
            )}
            <Toggle options={options} question={question} />
          </div>
        </>
      )}

      {question?.input_type.toLowerCase() === "text" && (
        <>
          <div className={AssessmentOwnerCSS.tabbox}>
            {question?.question_image && (
              <>
                <img
                  style={{ position: "absolute", left: "65%", top: "40%" }}
                  src={question?.question_image[0]}
                  alt=""
                />
                <img
                  style={{ position: "absolute", left: "10%", top: "15%" }}
                  src={question?.question_image[1]}
                  alt=""
                />
              </>
            )}
            <InputText question={question} />
          </div>
        </>
      )}

      {/* BMI */}

      {question?.input_type.toLowerCase() === "bmi" && (
        <>
          <BMICalc type="bmi" />
        </>
      )}
    </>
  );
};

export default QuestionContent;
