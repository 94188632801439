import React, { useEffect, useState } from "react";
import { Edit } from "../../../assets/icons/Edit";
import { Trash } from "../../../assets/icons/Trash";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import { Select, SelectCard } from "../CustomInputs";
import styles from "./feedback.module.css";
import { getAppointmentsTabData } from "../../../services/patient/appointments";
import {
  addFeedback,
  deleteFeedback,
  editFeedback,
  getFeedback,
} from "../../../services/patient/feedback";
import FeedbackPopup from "./FeedbackPopup";
import {
  toast,
  Toaster,
} from "../../../../node_modules/react-hot-toast/dist/index";
import { Readmore } from "./Readmore";
import { FeedbackStarRating, FeedbackStarView } from "./FeedbackRating";
import { useLocation } from "../../../../node_modules/react-router-dom/index";
import { Helmet } from "react-helmet";

export const FeedbackPage = () => {
  const [servicesList, setServicesList] = useState([]);
  const [feedbackList, setFeedbackList] = useState([]);
  const { state } = useLocation();
  const [data, setData] = useState({});
  const [err, setErr] = useState({});
  const [popupAction, setPopupAction] = useState({ open: false });
  const [ratingStars, setRatingStars] = useState();
  const [hover, setHover] = useState(0);
  const fetchServicesList = () => {
    getAppointmentsTabData("completed").then((res) => {
      setServicesList(
        res.map(({ service_id, service }) => {
          return { id: service_id, value: service.name };
        })
      );
    });
  };

  const feedbacksList = () => {
    getFeedback().then((res) => {
      if (res?.data?.status) {
        setFeedbackList(res.data.list);
      }
    });
  };

  useEffect(() => {
    fetchServicesList();
    feedbacksList();
  }, []);
  useEffect(() => {
    setData({ ...data, rating: ratingStars });
  }, [ratingStars]);

  useEffect(() => {
    setData({ ...data, feedback_type: state?.feedback_type });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "feedback_type") {
      setData({
        ...data,
        [name]:
          value === "0"
            ? "Service"
            : value === "1"
            ? "Assessment"
            : value === "2"
            ? "Counsellor"
            : value,
      });
      return;
    }
    setData({ ...data, [name]: value });
  };

  console.log(data, "data");

  const handleSubmit = (e) => {
    toast.remove();
    e.preventDefault();
    const API = data?.feedback_id ? editFeedback : addFeedback;
    API(data).then((res) => {
      setErr(res?.data?.error);
      if (res?.data?.status) {
        setRatingStars("");
        setHover(0);
        setData({
          ...data,
          feedback: "",
          feedback_type: "",
          feedback_id: "",
          rating: "",
        });
        toast.success(res?.data?.message);
        feedbacksList();
      }
    });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Patient Feedback - Samvedna Map</title>
        <meta name="title" content="Patient Feedback - Samvedna Map" />
        <link
          rel="canonical"
          href="https://map.samvednacare.com/patient/feedback"
        />
        <meta
          name="description"
          content="Share your feedback about Samvedna Map services like our self-assessments, counselling and therapy sessions. We are listening to our customers and want to ensure that we continues to improve ourselves."
        />
        <meta name="robots" content="index,follow" />
      </Helmet>
      <Toaster />
      <FeedbackPopup
        popupAction={popupAction}
        setPopupAction={setPopupAction}
        title=""
        icon=""
      />
      <div className={styles.feedback}>
        <div className={styles.give_feedback}>
          <form onSubmit={handleSubmit}>
            <h2 className={styles.heading}>Give a Feedback</h2>
            <div style={{ marginBottom: "20px" }}>
              <SelectCard
                label="Feedback Type"
                labelstyle={{ width: 140 }}
                titleMargin="0 0 10px 0"
                options={["Service", "Self Assessment", "Counsellor"]}
                error={err?.feedback_type}
                value={
                  data?.feedback_type === "Service"
                    ? "0"
                    : data?.feedback_type === "Assessment"
                    ? "1"
                    : data?.feedback_type === "Counsellor"
                    ? "2"
                    : data?.feedback_type
                }
                name="feedback_type"
                onChange={handleChange}
              />
            </div>
            <div style={{ marginBottom: "20px" }}>
              <h3
                style={{
                  marginBottom: "5px",
                  fontSize: "16px",
                  fontWeight: 400,
                }}
              >
                Rating
              </h3>
              <FeedbackStarRating
                setRatingStars={setRatingStars}
                ratingStars={ratingStars}
                hover={hover}
                setHover={setHover}
              />
              <p style={{ fontSize: "16px", margin: "5px", color: "red" }}>
                {err?.rating}
              </p>
            </div>
            <h3 style={{ fontSize: "16px", fontWeight: 400 }}>
              Write Feedback
            </h3>
            <textarea
              placeholder="Write Here"
              name="feedback"
              value={data?.feedback}
              onChange={handleChange}
            ></textarea>
            <p style={{ fontSize: "16px", margin: "5px", color: "red" }}>
              {err?.feedback}
            </p>

            <Btn variant="contained" width="160px" height="48px" click>
              Submit
            </Btn>
          </form>
        </div>

        <div className={styles.my_feedbacks}>
          <h2 className={styles.heading}>My Feedbacks</h2>
          <div className={styles.cards}>
            {feedbackList?.map((item, i) => {
              const serviceName = servicesList.filter(
                ({ id }) => id === item.service_id
              )?.[0]?.value;

              const feedBackText = (...c) => item.feedback.slice(...c);

              return (
                <div className={styles.feedback_card}>
                  <div>
                    <div>
                      <p className={styles.sub_heading}>{item.feedback_type}</p>
                      <div className={styles.icons}>
                        <span
                          onClick={() => {
                            setRatingStars(item.rating);
                            setHover(item.rating);
                            setData({
                              ...data,
                              feedback_id: item.id,
                              feedback_type: item.feedback_type,
                              rating: item.rating,
                              feedback: item.feedback,
                            });
                            window.scrollTo({ top: 0, behavior: "smooth" });
                          }}
                        >
                          <Edit size="16" />
                        </span>
                        <span
                          onClick={() =>
                            setPopupAction({
                              ...popupAction,
                              open: true,
                              action: () => {
                                deleteFeedback({ feedback_id: item.id }).then(
                                  (res) => {
                                    if (res?.data?.status) {
                                      toast.success(res?.data?.message);
                                      feedbacksList();
                                      setRatingStars("");
                                      setHover("");
                                      setData({
                                        ...data,
                                        feedback_id: "",
                                        feedback_type: "",
                                        rating: "",
                                        feedback: "",
                                      });
                                      setPopupAction({
                                        ...popupAction,
                                        open: false,
                                      });
                                    }
                                  }
                                );
                              },
                            })
                          }
                        >
                          <Trash />
                        </span>
                      </div>
                    </div>
                    {/* <p className={styles.text}>{serviceName}</p> */}
                  </div>
                  <div>
                    <p className={styles.text}>
                      <FeedbackStarView
                        starRating={item.rating}
                        hover={item.rating}
                      />
                    </p>
                    <p className={styles.sub_heading}>Feedback</p>
                    <p className={styles.text}>
                      {feedBackText(0, 130)}
                      {feedBackText(0).length > 140 ? (
                        <Readmore text={feedBackText(130)} />
                      ) : null}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
