import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const { GETCOUNTRY, GETSTATE, GETCITY } = API;

export const listCountries = async () => {
  return await axios
    .get(GETCOUNTRY)
    .then((res) => res.data.list)
    .catch((err) => console.log(err));
};

export const listState = async (country_id) => {
  return await axios
    .get(GETSTATE + country_id)
    .then((res) => res.data.list)
    .catch((err) => console.log(err));
};

export const listCity = async (state_id) => {
  return await axios
    .get(GETCITY + state_id)
    .then((res) => res.data.list)
    .catch((err) => console.log(err));
};
