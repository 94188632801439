import React from "react";
import styles from "../../PreLogin/landing.module.css";

export const PrevIcon = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      className={styles.prevIcon}
    >
      <path
        d="M32 5.33317C17.2724 5.33317 5.3333 17.2722 5.3333 31.9998C5.3333 46.7274 17.2724 58.6665 32 58.6665C46.7276 58.6665 58.6666 46.7274 58.6666 31.9998C58.6666 17.2722 46.7276 5.33317 32 5.33317Z"
        fill="white"
        stroke="#242424"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M36 21.3332L25.3333 31.9998L36 42.6665"
        stroke="#242424"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
