import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { AssessmentWrapper } from "../../../Samvenda-Patient/AssessmentNew/AssessmentWrapper";
import logo from "../../../assets/icons/icon/logo.svg";
import LoginImage from "../../../assets/icons/LoginImage.svg";
import styles from "./assessmentEnterprise.module.css";

// import categoryInfo from "./mockData.json";
import { LoginEnterprise } from "./LoginEnterprise";
import { Toaster } from "react-hot-toast";
const AssessmentEnterprise = () => {
  const [start, setStart] = useState(false);

  const [categoryInfo, setCategoryInfo] = useState();

  const { orgId, assessment_id } = useParams();

  const CloseAssessment = () => {
    setStart(false);
  };
  console.log(categoryInfo, "categoryInfo====");

  return (
    <>
      <Toaster />
      <div
        className={styles.login_container}
        style={{ background: `url(${LoginImage})` }}
      >
        {!start ? (
          <LoginEnterprise
            setStart={setStart}
            setCategoryInfo={setCategoryInfo}
          />
        ) : (
          <AssessmentWrapper
            start={start}
            data={categoryInfo}
            user={false}
            setStart={CloseAssessment}
            // setCategoryList={setCategoryList}
            // getRelativeData={getRelativeData}
            enterprise
          />
        )}
      </div>
    </>
  );
};

export default AssessmentEnterprise;
