import * as React from "react";

import Card from "@mui/material/Card";
import { Buttons } from "../Button/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import StarRating from "./StarRating";
import { useState } from "react";
import { addTestimonial } from "../../../services/patient/testimonial";
import styles from "./testimonial.module.css";
import {
  toast,
  Toaster,
} from "../../../../node_modules/react-hot-toast/dist/index";

export const PatientTestimonial = () => {
  const [ratingStars, setRatingStars] = useState();
  const [testimonialDesc, setTestimoialDesc] = useState("");
  const [err, setErr] = useState();
  const [hover, setHover] = useState(0);
  const handleSubmit = () => {
    if (!ratingStars) {
    }
    addTestimonial({ rating: ratingStars, thoughts: testimonialDesc }).then(
      (res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.message);
          setTestimoialDesc("");
          setRatingStars("");
          setHover("");
        }
        setErr(res?.data?.error);
      }
    );
  };
  console.log(ratingStars, "ratingStars");

  return (
    <>
      <Toaster />
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={11} md={11} lg={11} style={{ marginTop: "19px" }}>
          <Box>
            <Card
              style={{
                padding: "20px",
                borderRadius: "20px",
                paddingBottom: "100px",
              }}
            >
              <Grid
                container
                spacing={1}
                style={{ marginTop: "5px", marginLeft: "5px" }}
              >
                <Grid item xs={12} style={{ padding: "0" }}>
                  <h2
                    style={{
                      textAlign: "left",
                      fontSize: "20px",
                      marginBottom: "20px",
                      // marginLeft: "5px",
                    }}
                  >
                    Write a Testimonial
                  </h2>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: "left",
                    fontSize: "15px",
                    padding: "0",
                    // marginLeft: "5px",
                  }}
                >
                  <p style={{ fontWeight: "400", lineHeight: "19px" }}>
                    Share your journey with us by writing us your valuable
                    thoughts
                  </p>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Grid container spacing={1} style={{ marginTop: "5px" }}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "left",
                      }}
                    >
                      <StarRating
                        setRatingStars={setRatingStars}
                        ratingStars={ratingStars}
                        setHover={setHover}
                        hover={hover}
                      />

                      <p className={styles.errorText}>{err?.rating}</p>
                    </Grid>
                  </Grid>

                  <Grid container spacing={1} style={{ marginTop: "5px" }}>
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", textAlign: "left" }}
                    >
                      <Grid
                        style={{
                          // marginLeft: "5px",
                          fontSize: "14px",
                          marginTop: "5px",
                          fontWeight: "400",
                        }}
                      >
                        Please take a moment to share your thoughts
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", textAlign: "left" }}
                    >
                      <Grid
                        style={{
                          fontSize: "15px",
                        }}
                        xs={12}
                      >
                        <textarea
                          name="message"
                          id="message"
                          value={testimonialDesc}
                          placeholder="Write Here"
                          className={styles.thoughts}
                          onChange={(e) => setTestimoialDesc(e.target.value)}
                        />
                        <p className={styles.errorText}>{err?.thoughts}</p>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={1} style={{ marginTop: "5px" }}>
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", textAlign: "left" }}
                    >
                      <Grid
                        style={{
                          marginLeft: "5px",
                          fontSize: "15px",
                          marginTop: "0px",
                        }}
                      >
                        <Buttons
                          name="Submit"
                          style={{
                            backgroundColor: "#7A51AE",
                            marginTop: "0px",
                            padding: "10px 60px 10px 60px",
                            borderRadius: "8px",
                          }}
                          click={handleSubmit}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
