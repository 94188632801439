import { useDispatch, useSelector } from "react-redux";
import Calander from "../../../assets/icons/Calander";
import { setToggle } from "../../../Redux/Slices/Patient/PatienCalender";

export const Display = ({ selectedDate, styles }) => {
  const disabled = false;

  const dispatch = useDispatch();
  const { toggle } = useSelector((i) => i.DateFilterSlice);

  return (
    <div
      className={styles.display}
      style={{ background: disabled ? "#F9F9F9" : "#fff" }}
      onClick={() => dispatch(setToggle(!toggle))}
    >
      <p
        className={styles.text}
        style={{ color: disabled ? "#686868" : "#000" }}
      >
        {selectedDate}
      </p>
      <Calander disabled={disabled} styles={styles} />
    </div>
  );
};
