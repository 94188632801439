import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch } from "react-redux";
import { Edit } from "../../../assets/icons/Edit";

import BasicBreadcrumbs from "../../../Component/UI/BreadCrumb/BreadCrumbs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import instance from "../../../config/axiosConfig";
import { API } from "../../../config/constant";
import CustomizedButtons from "../../../Component/UI/Button/CustomizeButton";

import { fetchaTAddQuestionPostdata } from "../../../Redux/Slices/AdminAssessment/AddQuestion";
import { TEXT } from "../../contant/constant";
import { toast } from "../../../../node_modules/react-hot-toast/dist/index";

const link = [{ path: "/Assessments", linkText: "Assessments" }];

export default function TodayActivityQuestion() {
  const location = useLocation();
  const navigate = useNavigate();
  const { cid } = location?.state;
  const { permissions } = location?.state;
  const fullpermissions = +permissions?.listControl?.access?.["Full Control"];
  const writePermissions = +permissions?.listControl?.access?.Write;
  const dispatch = useDispatch();
  const [catId] = useState(cid);

  const [dragDropList, setDragDropList] = useState([]);
  console.log(cid, "cid");
  useEffect(() => {
    dispatch(fetchaTAddQuestionPostdata(""));
  }, []);

  useEffect(() => {
    try {
      instance
        .get(`${API.TODAYACTIVITYQUESTION}?category_id=${cid}`)
        .then((res) => {
          res.data.status === true && setDragDropList(res.data.list.questions);
        });
    } catch (error) {
      console.log(error, "error");
    }
  }, []);

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    const arr = [...dragDropList];
    console.log(e);
    let [removedItem] = arr.splice(e.source.index, 1);
    arr.splice(e.destination.index, 0, removedItem);
    setDragDropList(arr);
  };

  const AddBtnHandler = () => {
    navigate("/todayactivityaddquestion", {
      state: { cid: cid, id: 0, apiflag: TEXT.DIRECTCOMING },
    });
  };

  useEffect(() => {
    return () => toast.remove();
  }, []);

  console.log(dragDropList, "dragDropList");
  return (
    <>
      <div style={{ position: "absolute", top: "19%", left: "93%" }}>
        {writePermissions ? (
          <CustomizedButtons
            ButtonLabel={"Add"}
            width={""}
            click={AddBtnHandler}
          />
        ) : null}
      </div>
      <BasicBreadcrumbs link={link} text={"General"}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left"></TableCell>
                <TableCell align="left">Q.No</TableCell>
                <TableCell align="left">Question</TableCell>
                {writePermissions ? (
                  <TableCell align="left">Action</TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <Droppable droppableId="dragdroplist">
              {(provided, snapshot) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {dragDropList?.map(
                    (user, index) =>
                      user.category_id === cid && (
                        <Draggable
                          key={index}
                          //enable below text to start dragging
                          // draggableId={`${index}`}
                          index={index}
                        >
                          {(provided) => (
                            <TableRow
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <TableCell align="left">:::</TableCell>
                              <TableCell align="left">{user.id}</TableCell>
                              <TableCell align="left">
                                {user.question}
                              </TableCell>
                              {writePermissions ? (
                                <TableCell align="left">
                                  <Link
                                    style={{
                                      textDecoration: "none",
                                      color: "black",
                                    }}
                                    to="/todayactivityaddquestion"
                                    state={{
                                      id: user.id,
                                      cid: cid,
                                      apiflag: TEXT.COMEINGTOEDIT,
                                    }}
                                  >
                                    <Edit />
                                  </Link>
                                </TableCell>
                              ) : null}
                            </TableRow>
                          )}
                        </Draggable>
                      )
                  )}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </Table>
        </DragDropContext>
      </BasicBreadcrumbs>
    </>
  );
}
