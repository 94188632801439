import React from "react";
import { PatientLevel } from "../PatientLevel/PatientLevel";
import { Alertness } from "./Alertness";
import { Anxiety } from "./Anxiety";
import { BurdenScore } from "./BurdenScore";
import { HMSEScore } from "./HMSEScore";
import { Mood } from "./Mood";

import styles from "../../../../assets/css/PatientDashboard/progresstrend.module.css";

const Dementia = () => {
  return (
    <div className={styles.progress_trend_div}>
      <div className={styles.mood_card}>
        <Mood />
      </div>
      <div className={styles.mood_card}>
        <Alertness />
      </div>
      <div className={styles.mood_card}>
        <Anxiety />
      </div>
      <div className={styles.patient_card}>
        <PatientLevel />
      </div>
      <div className={styles.score_card_div}>
        <div className={styles.score_card}>
          <HMSEScore />
        </div>
        <div className={styles.score_card}>
          <BurdenScore />
        </div>
      </div>
    </div>
  );
};

export default Dementia;
