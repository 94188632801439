import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomTable } from "../../../Component/UI/Table";
import styles from "../../../../src/assets/css/assessment/Assessmenttabale.module.css";
import { NavLink, Link } from "react-router-dom";
import { TbEdit } from "react-icons/tb";
import { Edit } from "../../../assets/icons/Edit";
import { useEffect, useState } from "react";
import SwitchButton from "../../../Component/UI/SwitchButton/Switch";
import { assessmentData } from "../../../Redux/Slices/AssessmentTableReducer";
import axios from "../../../config/axiosConfig";
import { assessmentStatus } from "../../../services/admin/assessment";
import Copy from "../../../assets/icons/Copy";
import AssessmentPopup from "./AssesmentPopupPage";
import MultiselectOption from "../../../Component/UI/MultiSelect/Multiselect";
import MySelect from "../../../Component/UI/MultiSelect/SelectMultiSelect";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import { id } from "date-fns/locale";
import toast, {
  Toaster,
} from "../../../../node_modules/react-hot-toast/dist/index";
import {
  setFilter,
  setFrom_date,
  setSearch_key,
  setTo_date,
  setClear,
} from "../../../Redux/Slices/AdminFilters/Search";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";
// import { CircularProgress } from "../../../node_modules/@mui/material/index";
// import Multiselect from "../../../../node_modules/multiselect-react-dropdown/dist/index";

export const AssessmentTable = ({ permissions }) => {
  const dispatch = useDispatch();

  const { data } = useSelector((i) => i.assessmentData);
  const fullpermissions = +permissions?.listControl?.access?.["Full Control"];
  const writePermissions = +permissions?.listControl?.access?.Write;
  const handleOpen = (id) => {
    const filterData = data.find((item) => item.id === id);
    setSelectedData(filterData);
    setOpenModal(true);
  };

  const tHead = [
    // "",
    "Assessment Name",
    "Number of questions",
    "Enterprise Name",
    // "Score",
    "Date",
    ...(fullpermissions ? ["Status"] : []),
    ...(writePermissions ? ["Action"] : []),
  ];

  const [isCopied, setIsCopied] = React.useState(false);
  const [active, setActive] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  // const [selectedOptionsData, setSelectedOptionData] = useState(["OrangeMantra", "Oracle", "Ey", "Pepsi", "IkEA", "options4", "options3"])
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedData, setSelectedData] = useState();
  const [enterpriseListdata, setEnterpriseListData] = useState();
  const [error, setError] = useState();
  const [assessmentFor, setAssessmentFor] = useState("0");
  const [isLoading, setIsLoading] = useState(false);
  const { search_key, from_date, to_date, filter } = useSelector(
    (i) => i.SearchFilters
  );
  const [loading, setLoading] = useState(false);

  const Option = (props) => {
    return (
      <div style={{ textAlign: "left !important" }}>
        <components.Option {...props}>
          <input
            required={true}
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "neutral0" : null,
        color: "black",
      };
    },
  };
  const animatedComponents = makeAnimated();
  const handleChange = (selected) => {
    setSelectedValue(selected);
  };

  const handleClose = () => {
    setSelectedValue("");
    setOpenModal(false);
  };

  const getEnterpriseList = async () => {
    try {
      const response = await axios.get("admin/fetch/enterprise");
      const enterpriseList = response.data.list;
      setEnterpriseListData(enterpriseList);
    } catch (error) {
      console.log(error);
    }
  };
  //
  const enterpriselistoption = enterpriseListdata?.length
    ? enterpriseListdata.map((i) => ({ value: i.id, label: i.company_name }))
    : [];

  const handleSubmit = () => {
    // if (!selectedValue.length) {
    //   toast.error("Select a list of Enterprise");
    //   return;
    //  } else {
    const payload = {
      id: selectedData.id,
      enterprise_ids: selectedValue.length
        ? selectedValue?.map((i) => i.value).join(",")
        : 0,
      assessment_for: assessmentFor,
    };
    // setLoading(true);
    axios
      .post("/admin/assessment/copy", payload)
      .then(
        (response) => {
          setIsCopied(true);
        },
        (error) => {
          // console.log(error);
        }
      )
      .finally(() => {
        setLoading(false);
      });
    setSelectedValue([]);
    //}
  };
  // console.log(error, "errorgetinggg")
  useEffect(() => {
    getTable();
    getEnterpriseList();
  }, [filter, isCopied]);

  const getTable = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `/admin/assessment/category?search_key=${search_key}&from_date=${from_date}&to_date=${to_date}`
      );
      setIsLoading(false);
      dispatch(assessmentData(response.data.list));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    return () => {
      // dispatch(setSearch_key(""));
      dispatch(setTo_date(""));
      dispatch(setFrom_date(""));
      dispatch(setFilter());
      dispatch(setClear());
    };
  }, []);

  useEffect(() => {
    return () => toast.remove();
  }, []);

  const tBodyData = data?.map(
    ({
      // assessment_image,
      name,
      no_of_question,
      enterprise_name,
      // score,
      date_of_creation,
      created_for,
      status,
      id,
      serviceAssessment,
    }) => {
      return {
        // assessment_image: (
        //   <img
        //     src={assessment_image}
        //     style={{ height: "50px", width: "50px" }}
        //   />
        // ),
        data: [name],
        no_of_question,
        enterprise: enterprise_name
          ? enterprise_name
          : created_for
          ? "Not Mapped"
          : "General",
        // score,
        date_of_creation,
        ...(fullpermissions && {
          status: (
            <>
              <SwitchButton
                status={status}
                disabled={serviceAssessment}
                onClick={(value) => assessmentStatus(id, value)}
              />
            </>
          ),
        }),

        ...(writePermissions && {
          action: (
            <div className={styles.tableActions}>
              {id === 1 ? (
                <span style={{ cursor: "not-allowed" }}>
                  <Copy />
                </span>
              ) : (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOpen(id)}
                >
                  <Copy />
                </span>
              )}

              <NavLink
                to="/EditAssessment"
                state={{ from: "edit", ass_id: id }}
                style={{ textDecoration: "none" }}
              >
                <Edit />
              </NavLink>
            </div>
          ),
        }),
        // action: (
        //   <div className={styles.tableActions}>
        //     {id === 1 ? (
        //       <span style={{ cursor: "not-allowed" }}>
        //         <Copy />
        //       </span>
        //     ) : (
        //       <span
        //         style={{ cursor: "pointer" }}
        //         onClick={() => handleOpen(id)}
        //       >
        //         <Copy />
        //       </span>
        //     )}

        //     <NavLink
        //       to="/EditAssessment"
        //       state={{ from: "edit", ass_id: id }}
        //       style={{ textDecoration: "none" }}
        //     >
        //       <Edit />
        //     </NavLink>
        //   </div>
        // ),
      };
    }
  );

  return (
    <div>
      {!isLoading ? (
        <CustomTable tHead={tHead} tBody={tBodyData} />
      ) : (
        <div className="loader" style={{ textAlign: "center", margin: "60px" }}>
          <CircularProgress size={28} />
        </div>
      )}

      <AssessmentPopup
        open={openModal}
        isCopied={isCopied}
        loading={loading}
        setIsCopied={setIsCopied}
        confirmSubmit={handleSubmit}
        title="Copy Assesment"
        onClose={handleClose}
        modalOpen={setOpenModal}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "flex-start",
            height: "200px",
          }}
        >
          <p className={styles.asstName}>Assesment Name</p>
          <p className={styles.asstMentalName}> {selectedData?.name} </p>
          <p style={{ color: "#000", fontSize: "14px" }}>Assessment for</p>
          <div>
            <input
              type="radio"
              id="user"
              name="created_for"
              value="0"
              style={{ outline: "none" }}
              checked={assessmentFor.toString() === "0"}
              onChange={(e) => setAssessmentFor(e.target.value)}
            />
             <label for="general">General</label>
            <span style={{ marginLeft: "20px" }}>
              <input
                type="radio"
                id="enterprise"
                name="created_for"
                value="1"
                style={{ outline: "none" }}
                checked={assessmentFor.toString() === "1"}
                onChange={(e) => setAssessmentFor(e.target.value)}
              />
              <label style={{ marginLeft: "6px" }} for="enterprise">
                Enterprise
              </label>
            </span>
          </div>
          <p className={styles.EnterPrise}>Enterprise Name</p>
          {/* <MultiselectOption options={selectedOptionsData}  selectionLimit={5} /> */}
          <MySelect
            isMulti
            maxMenuHeight={230}
            controlShouldRenderValue={false}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option, animatedComponents }}
            options={
              selectedValue?.length > 4 ? selectedValue : enterpriselistoption
            }
            onChange={handleChange}
            isDisabled={assessmentFor.toString() !== "1"}
            value={selectedValue}
            isSearchable={false}
            placeholder={
              selectedValue.length
                ? `${selectedValue.length} Selected`
                : "Select"
            }
            className={styles.Select}
            isClearable={false}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "lightgrey",
              },
            })}
            styles={colourStyles}
          />
          <p className={styles.asstName}>
            (You can select upto 5 enterprises )
          </p>
          {/* { error.length > 0 && (<p>{error}</p>)} */}
        </div>
      </AssessmentPopup>
    </div>
  );
};
