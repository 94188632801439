import React from "react";

const ForwardBtn = ({ click }) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        onClick={click}
      >
        <path
          d="M9 18L15 12L9 6"
          stroke="#2A2A2A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default ForwardBtn;
