import React from "react";

export const Print = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M5 7.5013V1.66797H15V7.5013"
        stroke="#2A2A2A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.99935 15H3.33268C2.89065 15 2.46673 14.8244 2.15417 14.5118C1.84161 14.1993 1.66602 13.7754 1.66602 13.3333V9.16667C1.66602 8.72464 1.84161 8.30072 2.15417 7.98816C2.46673 7.6756 2.89065 7.5 3.33268 7.5H16.666C17.108 7.5 17.532 7.6756 17.8445 7.98816C18.1571 8.30072 18.3327 8.72464 18.3327 9.16667V13.3333C18.3327 13.7754 18.1571 14.1993 17.8445 14.5118C17.532 14.8244 17.108 15 16.666 15H14.9993"
        stroke="#2A2A2A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 11.668H5V18.3346H15V11.668Z"
        stroke="#2A2A2A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
