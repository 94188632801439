const Calander = ({ disabled, styles }) => {
  const paths = [
    "M3 10H21",
    "M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z",
    "M16 2V6",
    "M8 2V6",
  ];

  return (
    <svg
      className={styles.appointment}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      {paths.map((d, id) => {
        return (
          <path
            key={id}
            d={d}
            stroke={disabled ? "#686868" : "#333333"}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        );
      })}
    </svg>
  );
};

export default Calander;
