import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActions } from "@mui/material";
import { Btn } from "../../Samvenda-Patient/UI/Button";
import { utils, writeFile } from "xlsx";
import { getReports } from "../../services/admin/AssessmentReport";
import { useState } from "react";
import { CircularProgress } from "../../../node_modules/@mui/material/index";
import toast from "../../../node_modules/react-hot-toast/dist/index";
import styles from "./DownloadReportCard.module.css";
export default function DownloadReportCard({ assessmentData, date }) {
  const [disable, setDisable] = useState(false);
  const handledownload = (callback) => {
    setDisable(true);
    getReports(assessmentData.id, date)
      .then((res) => {
        callback(res);
      })
      .catch((err) => {
        setDisable(false);
      });
  };

  const convertExcel = (assessmentResult) => {
    toast.remove();
    const fileName = `${assessmentData.name}_Report.xlsx`;
    const ws = utils.json_to_sheet(assessmentResult.myself);
    const ws2 = utils.json_to_sheet(assessmentResult.someone_else);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Myself");
    assessmentResult.someone_else.length &&
      utils.book_append_sheet(wb, ws2, "Someone Else");

    assessmentResult.someone_else.length || assessmentResult.myself.length
      ? writeFile(wb, fileName)
      : toast.error("No data Available !");
    setDisable(false);
  };

  return (
    <>
      <div className={styles.reportCard}>
        <Card
          sx={{
            maxWidth: 300,
            minWidth: 300,
            minHeight: 180,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <CardContent sx={{ minHeight: 100 }}>
            <Typography gutterBottom variant="h5" component="div">
              {assessmentData.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Click Below to download the {assessmentData.name} Report
            </Typography>
          </CardContent>
          <CardActions>
            <Btn
              bgColor="#00B091"
              variant="outlined"
              color="white"
              disabled={disable}
              click={() => {
                handledownload(convertExcel);
              }}
              width="150px"
            >
              {disable ? (
                <CircularProgress
                  sx={{
                    height: "25px!important",
                    width: "25px!important",
                    color: "white",
                  }}
                />
              ) : (
                "Download Report"
              )}
            </Btn>
          </CardActions>
        </Card>
      </div>
    </>
  );
}
