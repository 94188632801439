import styles from "../../../assets/css/PatientUI/assesmentResult.module.css";
import { Level } from "./Level";
import { Content } from "./Content";
import { Card } from "@mui/material";

export const AssesmentResult = ({ data, large, seeResults }) => {
  const headingStyle = large ? { fontSize: "24px", fontWeight: "500" } : null;
  const lvl = data?.is_mapped?.level;

  // console.log(seeResults);

  // const assessmentforName = +;

  return (
    <div className={styles.assesmentResult}>
      <h1 style={headingStyle}>
        {data?.name} Results{" "}
        {data?.assessment_name && (
          <>
            for
            <span>
              {" "}
              {seeResults && data?.assessment_name.slice(0, 25)}
              {large
                ? data?.assessment_name
                : data?.assessment_name.length > 25 && "..."}
            </span>
          </>
        )}
      </h1>
      <Level level={lvl} large={large} />
      <Content
        large={large}
        headingStyle={headingStyle}
        seeResults={seeResults}
        data={data}
        level={lvl}
      />
    </div>
  );
};
