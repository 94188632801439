import React from "react";

import styles from "./consent.module.css";
import { useAuth } from "../../../../Auth/AuthProvider";
import moment from "../../../../../node_modules/moment/moment";
import { ADMIN_ROUTES } from "../../../../Routes/routesConstants";
import {
  useLocation,
  useNavigate,
} from "../../../../../node_modules/react-router-dom/index";
import { usePermissions } from "../../../../Hooks/usePermissions";
import SimpleBreadcrum from "../../../../Component/UI/SimpleBreadcrum";
import { Btn } from "../../../../Samvenda-Patient/UI/Button";
import { useState } from "react";
import axios from "../../../../config/axiosConfig";
import {
  Toaster,
  toast,
} from "../../../../../node_modules/react-hot-toast/dist/index";
import { CircularProgress } from "../../../../../node_modules/@mui/material/index";

export default function ConsentOne() {
  const domain = window.location.host;
  const location = useLocation();
  const { props, name, is_consent, consent_at, email, mobile } = location.state;
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const permissions = usePermissions("name", "patients");
  const navigate = useNavigate();

  const genrateLink = () => {
    setIsLoading(true);
    axios
      .post("/admin/sendConsentLink", {
        id: props,
        link: domain + "/user/signin/?consent",
      })
      .then((res) => {
        setIsLoading(false);
        if (res.data.status) {
          toast.success(res.data.msg);
          navigate("/Patients");
        }
        console.log(res);
      })
      .catch((err) => console.error(err));
  };

  const { loginUser } = useAuth();
  const isConsent = Number(is_consent);
  return (
    <>
      <div className={styles.consentForm} style={{ marginTop: "10px" }}>
        <div className={styles.consentWrapper}>
          <div className={styles.consentHead}>
            Consent Form
            <span
              className={isConsent === 1 ? styles.approved : styles.pending}
            >
              {isConsent === 1 ? "Approved" : "Pending"}
            </span>
            <span
              style={{
                fontSize: "14px",
                marginLeft: "10px",
              }}
            >
              {" "}
              {consent_at
                ? moment(consent_at).format("DD-MM-YYYY hh:mm A")
                : ""}
            </span>
          </div>
          <div className={styles.consentBody}>
            <div className={styles.custName}>
              <span>
                {" "}
                <strong>Name: </strong>
                {name}
              </span>
              <span>
                {" "}
                <strong>Email: </strong>
                {email}
              </span>
              <span>
                {" "}
                <strong>Phone: </strong>
                {mobile}
              </span>
            </div>
            <div className={styles.consentBodyContent}>
              Informed consent for video/audio/in-person consultation with
              Clinical Psychologist for psychotherapy/ psychological assessment
              and psychological interventions.
            </div>
            <div className={styles.consentBodyTitle}>
              General Information provided to me about
            </div>
            <div className={styles.consentBodyContent}>
              psychotherapy/ psychological assessment/ psychological
              interventions: Psychological assessments help understand the
              nature of difficulties and challenges being faced. Psychotherapy
              is a way to help people experiencing significant emotional
              distress that is coming in the way of them being physically well,
              enjoying personal relationships or working productively.
              Psychotherapy begins with the therapist understanding the
              background of the person seeking help and the concerns that led
              them to seek help. Following this, the client and psychotherapist
              come to an agreement about the goals sessions.
            </div>
            <div className={styles.consentBodyContent}>
              Tele-psychotherapy refers to the provision of psychotherapy
              services using telecommunication technologies including email,
              text messaging, video conferencing, online chat, messaging, or
              internet phone. I understand that tele-psychotherapy services are
              by appointment only and that these consultations may not suitable
              for help during an emergency when I may be advised to obtain
              treatment at the nearest available mental health facility/hospital
              or emergency service.
            </div>
            <div className={styles.consentBodyTitle}>
              Confidentiality and Recording
            </div>
            <div className={styles.consentBodyContent}>
              I understand that this audio/ video consultation is strictly
              confidential. I agree to use a secure line/connection for these
              consultations, in a relatively quiet and private space. I
              understand that my psychotherapist will not audio or video record
              the session (either on mobile, using an app or online) and will
              not share the proceedings of this consultation with any other
              individual or agency unless there is a risk of self-harm or harm
              to others in which case I provide consent for the therapist to
              share this information with the concerned organization/my family
              as agreed upon during my sessions to safeguard my interests. I
              understand that the proceedings of these consultations are not to
              be recorded, shared or disseminated by me or my relatives / other
              contacts to any third person or through social media, or be used
              for medico-legal purposes. However, despite safety measures taken,
              there are chances for breach in security of technology. In such
              instances, both client and psychotherapist will not hold the other
              responsible for the breach.
            </div>

            <div className={styles.consentBodyContent}>
              I understand that my consent expressed online would suffice for me
              to receive the above services.
            </div>
            <div className={styles.consentBodyContent}>
              <strong>Consent:</strong> I hereby provide my informed consent for
              video/audio consultations for psychotherapy/ psychological
              assessment.
            </div>

            {/* <div className={styles.custName}>
               <span> <strong>Signature: </strong>-</span>
             
               
           </div> */}
          </div>
          {isConsent === 1 ? (
            ""
          ) : (
            <div className={styles.genrateLink}>
              <Btn
                borderRadius="100px"
                bgColor="#00B091"
                disabled={isLoading}
                click={genrateLink}
              >
                {isLoading ? <CircularProgress size={28} /> : "Generate Link"}
              </Btn>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
