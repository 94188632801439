import React from 'react'
import { useNavigate } from "react-router-dom";
import { Btn } from '../../../../Samvenda-Patient/UI/Button';

export const ButtonGroup = ({styles}) => {

  const navigate = useNavigate();

  return (
    <div className={styles.btnGrp}>
          <Btn
            variant="contained"
            bgColor="#ffffff"
            width = "236px"
            height="48px"
            color="#000000"
            whiteSpace="nowrap"
            borderRadius="8px"
            click={() => navigate("/self-assessment")}
            // click={() => {
            //   setPopup(true);
            // }}
          >
            {"Take a Self-Assessment"}
          </Btn>
          <Btn
            variant="contained"
            bgColor="#000000"
            color="#ffffff"
            width = "236px"
            height="48px"
            whiteSpace="nowrap"
            borderRadius="8px"
            click={() => navigate("/user/signin")}
            // click={() => {
            //   setPopup(true);
            // }}
          >
            {"Sign in"}
          </Btn>
    </div>
  )
}
