import React from "react";
import styles from "./healthfactscard.module.css";
export const HealthFactsCard = ({ perct, description }) => {
  return (
    <div className={styles.card}>
      <p className={styles.cardTitle}>{perct}%</p>
      <p className={styles.cardDesc}>{description}</p>
    </div>
  );
};
