import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BackwardBtn from "../../../../../assets/icons/BackwardBtn";
import ForwardBtn from "../../../../../assets/icons/ForwardBtn";
import {
  setMonth,
  setYear,
} from "../../../../../Redux/Slices/WellnessTracker/monthChangerSlice";

const MonthChanger = () => {
  const { month, year, disable } = useSelector(
    (state) => state.MonthChangerSlice
  );
  const dispatch = useDispatch();

  const [state, setState] = React.useState("pointer");
  let currentMonth = new Date().getMonth();
  let currentYear = new Date().getFullYear();

  useEffect(() => {
    setState(
      month === currentMonth && year === currentYear ? "auto" : "pointer"
    );
  }, [month, year]);

  const Months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const handleMonthNext = () => {
    if (month === currentMonth && year === currentYear) {
    } else {
      if (month < 11) {
        dispatch(setMonth(month + 1));
      } else if (month >= 11) {
        dispatch(setMonth(0));
        dispatch(setYear(year + 1));
      }
    }
  };

  const handleMonthBack = () => {
    setState("pointer");
    if (month > 0) {
      dispatch(setMonth(month - 1));
    } else if (month <= 0) {
      dispatch(setMonth(11));
      dispatch(setYear(year - 1));
    }
  };

  return (
    <>
      {
        <div
          style={{ display: disable ? "none" : "flex", alignItems: "center" }}
        >
          <div style={{ cursor: "pointer" }}>
            <BackwardBtn click={handleMonthBack} />
          </div>
          <div style={{ userSelect: "none" }}>
            {Months[month]}, {year}
          </div>
          <div style={{ cursor: state }}>
            <ForwardBtn click={handleMonthNext} />
          </div>
        </div>
      }
    </>
  );
};

export default MonthChanger;
