import React from "react";
import axios from "../../config/axiosConfig";
import { useState, useEffect } from "react";
import { ServicesCards } from "../../Component/UI/ServicesCard/ServicesCard";
import classes from "../../assets/css/PatientUI/servicecard.module.css";
import NoServices from "./Services/NoServices";
import { useDispatch, useSelector } from "react-redux";
import {
  setSearchText,
  setToggleSearch,
} from "../../Redux/Slices/patientSearch";
import AuthContext from "../../Auth/AuthContext";
import { assessmentLevelRelative, getServiceEnterprise } from "../../services/patient/assessment";
import toast from "../../../node_modules/react-hot-toast/dist/index";
import { CircularProgress } from "../../../node_modules/@mui/material/index";
import { useAuth } from "../../Auth/AuthProvider";
import { useContext } from "react";

export const TabsService = ({ id, assessmentData, select, selectEnterPrise, enterpriseId }) => {
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const { searchText } = useSelector((i) => i.patientSearch);
  const [isLoading, setIsLoading] = useState(false)

  const [relativeData, setRelativeData] = useState([]);
  const ctx = useContext(AuthContext);
  const customer_id = ctx?.loginUser?.customer?.id;

  let serviceUrl = `/customer/services?assessment_id=${id}`;
  let recommendedUrl = `/customer/recommednded/service`;

  let finalUrl = id === 0 ? recommendedUrl : serviceUrl;
 
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true)
    setAllData([])
    setData([])
    if(enterpriseId && selectEnterPrise !== '0') {
      getServiceEnterprise(selectEnterPrise, customer_id).then((res) => {
        setData(res);
        setAllData(res);
        setIsLoading(false)
      })
      .catch((err) => console.log(err, "error"));
    }else {
      axios
      .get(finalUrl)
      .then((res) => {
        setData(res.data.list);
        setAllData(res.data.list);
        setIsLoading(false)
      })
      .catch((err) => console.log(err, "error"));
    }
    setTimeout(() => {
      setIsLoading(false)
    },2000)

    return () => {
    
      dispatch(setToggleSearch(false));
      dispatch(setSearchText(""));
    };
  }, [selectEnterPrise]);

  useEffect(() => {
    const searchResult = allData?.filter((service) =>
      service.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setData(searchResult);
  }, [searchText]);

  useEffect(() => {
    return () => toast.remove();
  }, []);

  useEffect(() => {
    assessmentLevelRelative().then((res) => setRelativeData(res));
  }, []);

  const assessmentStatus = assessmentData?.is_mapped?.status;

  const relativeAssessmentData = relativeData?.some((item) => {
    return item?.customer?.status === "completed";
  });

  const relativeAssessmentStatus = relativeAssessmentData
    ? "completed"
    : "pending";

  const finalAssessmentStatus =
    select === 1
      ? relativeAssessmentStatus === "completed" ||
        assessmentStatus === "completed"
        ? "completed"
        : "pending"
      : assessmentStatus;

      
      // const duplicateData = [
      //   ...new Set(data?.map((i) => i.id)),
      // ];

  
      // console.log(duplicateData, 'duplicateData--')
  return (
    <>
      {isLoading ? (
              <div style={{ textAlign: "center", marginTop: '40px' }}>
                <CircularProgress size={30} />
                <p style={{ color: "black" }}>
                  Please wait ! We are validating the details...
                </p>
              </div>
            ) : 
            <div className={classes.service_card}>
            {data?.length ? (
              data?.map((i) => {
                
                return (
                  <ServicesCards
                    data={i}
                    allDataList = {data}
                    status={finalAssessmentStatus}
                    select={select}
                    enterpriseId={enterpriseId}
                    selectEnterPrise={selectEnterPrise}
                   
                  />
                );
              })
            ) : (
              <NoServices />
            )}
            </div>
          
          }
         
    </>
   
  );
};
