import * as React from "react";
import classes from "./radioBtn.module.css";

import { setSelectedTime } from "../../Redux/Slices/QuickAsk/index";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

export default function Timer({ label, getTime, question }) {
  const currentTime = `${new Date().getHours()}:${new Date().getMinutes()}`;

  let defaultTime = currentTime;

  if (question?.question?.includes("sleep")) {
    defaultTime = "22:00";
  } else if (question?.question?.includes("wake")) {
    defaultTime = "06:00";
  } else {
    defaultTime = currentTime;
  }

  const [time, setTime] = React.useState(defaultTime);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setSelectedTime(time));
  }, [time]);

  return (
    <>
      <div className={classes.outerTimePicker}>
        <input
          type="time"
          name="start_time"
          value={time}
          required
          onChange={(e) => setTime(e.target.value)}
          className={classes.timePicker}
        ></input>
      </div>
    </>
  );
}
