import styles from "../../assets/css/avatar.module.css";
//import profilePic from "../../assets/icons/icon/default_profile.svg";
import profilePic from "../../assets/icons/icon/Profile_icon.png";
export const Avatar = (props) => {
  return (
    <div
      className={`${styles.avatar} ${props.border ? styles.border : null}`}
      style={{
        backgroundImage: `url(${props.profile || profilePic})`,
        width: props.size,
        height: props.size,
        backgroundSize: props.bgSize,
        border: props.border,
      }}
    ></div>
  );
};
