import React from "react";
import "./erroricon.css";

export const ErrorIcon = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div class="sa">
        <div class="sa-warning">
          <div class="sa-warning-body"></div>
          <div class="sa-warning-dot"></div>
        </div>
      </div>
    </div>
  );
};
