import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useEffect } from "react";

export default function CircularProgressWithLabel(props) {
  const { value } = props;

  const [bmiColor, setbmicolor] = React.useState();
  const [bmiDisease, setbmidisease] = React.useState();

  useEffect(() => {
    if (value < 18.5999) {
      setbmidisease("Under weight");
      setbmicolor("#87b1d9");
    } else if (value >= 18.5999 && value <= 24.9999) {
      setbmidisease("Normal");
      setbmicolor("#3dd365");
    } else if (value >= 25 && value <= 29.9999) {
      setbmidisease("Over weight");
      setbmicolor("#eee133");
    } else if (value >= 30 && value <= 34.9999) {
      setbmidisease("Morbidly Obese");
      setbmicolor("#fd802e");
    } else if (value >= 35) {
      setbmidisease("Severely Obese");
      setbmicolor("#f95353");
    }
  }, [props]);

  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        style={{ ...props.style }}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          <div>
            <h1>{typeof value === "number" && `${value?.toFixed(1)}`}</h1>
          </div>
          <div>
            <h1>{bmiDisease}</h1>
          </div>
        </Typography>
      </Box>
    </Box>
  );
}
