import { createSlice } from "@reduxjs/toolkit";

const rolesAndPermissionsSlice = createSlice({
  name: "roles and permissions",
  initialState: {data:[]  },
  reducers: {
    loadData: (state, action) => {
      state.data = action.payload
    }
  },
});

export const { loadData } =
rolesAndPermissionsSlice.actions;

export default rolesAndPermissionsSlice.reducer;
