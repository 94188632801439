import React from "react";
import Chart from "../Charts/Charts";
import {
  SkeletonLoader,
  SkeletonLoaderNew,
} from "../../../../Component/Common/SkeletonLoader";

export const SleepHours = ({
  styles,
  styles2,
  sleepHourData,
  sleepHourDataCat,
  isLoadingSleepHours,
}) => {
  return (
    <div className={styles.cardChart}>
      <div className={styles.chartHead}>Sleep Hours</div>

      <div className={styles2.mainChartWrapper}>
        {!isLoadingSleepHours ? (
          <>
            <Chart
              type={"bar"}
              labels={["Level 1", "Level 2", "Level 3", "Level 4"]}
              colors={["#EE4925", "#AAC514", "#23C363", "#FBAA34"]}
              sleepHourData={sleepHourData}
              sleepHourDataCat={sleepHourDataCat}
            />
            <div className={styles2.bottomLabel}>
              <div className={styles2.bottomlabelText}>
                <span className={styles2.LevelOne}></span>
                <div className={styles2.bottomText}>
                  <p className={styles2.levelSec}>Level 1</p>
                  <p className={styles2.hourSec}>{"<5 hours"}</p>
                </div>
              </div>
              <div className={styles2.bottomlabelText}>
                <span className={styles2.LevelTwo}></span>
                <div className={styles2.bottomText}>
                  <p className={styles2.levelSec}>Level 2</p>
                  <p className={styles2.hourSec}>{"5 - 7 hours"}</p>
                </div>
              </div>
              <div className={styles2.bottomlabelText}>
                <span className={styles2.LevelThree}></span>
                <div className={styles2.bottomText}>
                  <p className={styles2.levelSec}>Level 3</p>
                  <p className={styles2.hourSec}>{"7 - 10 hours"}</p>
                </div>
              </div>
              <div className={styles2.bottomlabelText}>
                <span className={styles2.LevelFour}></span>
                <div className={styles2.bottomText}>
                  <p className={styles2.levelSec}>Level 4</p>
                  <p className={styles2.hourSec}>{"10< hours"}</p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <SkeletonLoaderNew />
        )}
      </div>

      {/* <img src={SleepHour} alt="Sleep hour" /> */}
    </div>
  );
};
