import styles from "../../../assets/css/AdminDashboard/services.module.css";
import { useSelector } from "react-redux";
import CustomizedButtons from "../../../Component/UI/Button/CustomizeButton";
import { ServiceForm } from "./ServiceForm";
import {
  addServices,
  getServiceDetails,
  editService,
} from "../../../services/admin/services";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BasicBreadcrumbs from "../../../Component/UI/BreadCrumb/BreadCrumbs";

export const AddService = () => {
  const [response, setResponse] = useState();
  const { data } = useSelector((i) => i.serviceDetails);
  const [error, setError] = useState([]);
  const navigate = useNavigate();

  const addService = () => {
    addServices(data).then((i) => {
      setResponse(i?.data);
      if (i?.data?.status) {
        navigate("/Services");
      } else {
        setError(i?.data?.error);
      }
    });
  };
  console.log(data, "data+++");
  const cancelService = () => {
    navigate("/Services");
  };

  const links = [
    {
      path: "/services",
      linkText: "Services",
    },
  ];

  return (
    <BasicBreadcrumbs link={links} text="Add Service">
      <div>
        <ServiceForm
          response={response}
          error={error}
          checkedstate={true}
          formdata={data}
          flag
        />
        <div
          className="styles.buttons_flex"
          style={{ display: "flex", marginTop: "20px", gap: "20px" }}
        >
          <CustomizedButtons
            btncoloractive={"active"}
            ButtonLabel={"Cancel"}
            click={cancelService}
          />

          <CustomizedButtons ButtonLabel={"Save"} click={addService} />
        </div>
      </div>
    </BasicBreadcrumbs>
  );
};
