import React from "react";

import { Box, Paper } from "@mui/material";

const PaperWrapper = ({ flag, style, children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        "& > :not(style)": {
          m: 1,
          width: flag ? flag : "90vw",
          height: "auto",
        },
      }}
    >
      <Paper elevation={1} width="90vw" sx={{ ...style }}>
        {children}
      </Paper>
    </Box>
  );
};

export default PaperWrapper;
