import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const {
  GET_ENTERPRISE_PROFILE,
  GET_INDUSTRY_LIST,
  GET_DESIGNATION_LIST,
  UPDATE_ENTERPRISE_PROFILE,
  SEND_ONBOARD_LINK,
} = API;

export const getEnterpriseindustry = async () => {
  return await axios
    .get(GET_INDUSTRY_LIST)
    .then((res) => res)
    .catch((err) => err);
};

export const getEnterpriseDesignation = async () => {
  return await axios
    .get(GET_DESIGNATION_LIST)
    .then((res) => res)
    .catch((err) => err);
};

export const getEnterpriseProfile = async () => {
  return await axios
    .post(GET_ENTERPRISE_PROFILE)
    .then((res) => res)
    .catch((err) => err);
};

export const updateEnterpriseProfile = async (data) => {
  return await axios
    .post(UPDATE_ENTERPRISE_PROFILE, data)
    .then((res) => res)
    .catch((err) => err);
};

export const sendOnboardLink = async (data) => {
  return await axios
    .post(SEND_ONBOARD_LINK, data)
    .then((res) => res)
    .catch((err) => err);
};
