import React from 'react'

export const Card = ({styles, item}) => {
  return (
    <div className={styles.cardBox}>
        <img src={item?.image} alt="box"/>
        <h3>{item?.title}</h3>
    </div>
  )
}
