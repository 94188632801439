import React, { useState } from "react";
import styles from "./RolesAndPermissions.module.css";

import { Tab } from "./Tab/Tab";

export const RolesList = ({
  permissions,
  setSelectedCategoriesId,
  selectedCategoriesId,
  rolesNames,
  setSelectedRoles,
  selectedRoles,
  rosterPermissions,
  isLoadingSave,
  isLoading,
  rolesAndPermsSet,
}) => {
  return (
    <div className={styles.staffList}>
      <div className={styles.stafftab}>
        <Tab
          setSelectedCategoriesId={setSelectedCategoriesId}
          selectedCategoriesId={selectedCategoriesId}
          rolesNames={rolesNames}
          setSelectedRoles={setSelectedRoles}
          selectedRoles={selectedRoles}
          rosterPermissions={rosterPermissions}
          isLoadingSave={isLoadingSave}
          isLoading={isLoading}
          rolesAndPermsSet={rolesAndPermsSet}
        />
      </div>
    </div>
  );
};
