import React, { useEffect, useState } from "react";

import axios from "../../../config/axiosConfig";
import { CustomTable } from "../../../Component/UI/Table";
import SwitchButton from "../../../Component/UI/SwitchButton/Switch";
import { useDispatch, useSelector } from "react-redux";
import {
  setFilter,
  setFrom_date,
  setSearch_key,
  setTo_date,
  setClear,
} from "../../../Redux/Slices/AdminFilters/Search";
import { CompletePopup } from "./Elements/CompletePopup";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import OnlineImg from "../../../assets/images/appOnline.svg";
import OfflineImg from "../../../assets/images/appOffline.svg";
import HybirdImg from "../../../assets/images/appHybird.svg";
import moment from "moment";
import { to12Hours } from "../../../config/formattedDate";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";
export const OnGoingAppointment = ({ setOnGoingData, permissions }) => {
  const [client, setClient] = useState([]);
  const [popupAction, setPopupAction] = useState({ open: false });
  const [loading, setLoading] = useState(false);
  const fullpermissions = +permissions?.listControl?.access?.["Full Control"];
  const writePermissions = +permissions?.listControl?.access?.Write;
  const [loadingApp, setLoadingApp] = useState(false);

  const dispatch = useDispatch();
  const { search_key, from_date, to_date, filter } = useSelector(
    (i) => i.SearchFilters
  );

  const tHead = [
    "Patient Name",
    "Mobile",
    "Appointment ID",
    "Time",
    "Started At",
    "Staff",
    ...(writePermissions ? ["Action"] : []),
  ];
  const clientStatus = async (appointment_id, status, appointment_end_date) => {
    setLoading(true);
    await axios
      .post("/admin/appointment/status/change", {
        appointment_id,
        status,
        appointment_end_date,
      })
      .then((res) => {
        setLoading(false);
        if (res?.data?.status) {
          setPopupAction({ ...popupAction, open: false });
          onGoingData();
        }
      })
      .catch((err) => console.error(err));
  };

  const onGoingData = () => {
    setLoadingApp(true);
    axios
      .get(
        `/admin/get/appointmentNew?type=ongoing&search_key=${search_key}&fromdate=${from_date}&todate=${to_date}`
      )
      .then((res) => {
        setLoadingApp(false);
        setClient(res.data.list);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    onGoingData();
  }, [filter]);

  useEffect(() => {
    setOnGoingData(client);
  }, [client]);

  useEffect(() => {
    return () => {
      // dispatch(setSearch_key(""));
      dispatch(setTo_date(""));
      dispatch(setFrom_date(""));
      dispatch(setFilter());
      dispatch(setClear());
    };
  }, []);

  const tBodyData = client?.map(
    (
      {
        paitient_name,
        mobile,
        created_at,
        session_id,
        service_type,
        appointment_time,
        appointment_date,
        status,
        id,
        staff,
        started_at,
        end_time,
        appointment_link,
        appointment_start_date,
        service_name,
      },
      i
    ) => {
      const d = new Date(created_at);
      const prefix = (val) => (val <= 9 ? "0" + val : val);
      const date = `${prefix(d.getDate())}/${prefix(
        d.getMonth()
      )}/${d.getFullYear()}`;
      const realTime = started_at?.substring(11, 20);
      const [start_hour, start_min] = appointment_time.split(":");
      const [end_hour, end_min] = end_time.split(":");
      const time =
        moment(appointment_date).format("h:mm A") +
        " - " +
        moment(end_time, "hh:mm").format("hh:mm A");

      return {
        paitient_name,
        mobile,
        session: (
          <>
            <div
              id={"AppointmentID" + i}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <span>
                {" "}
                {service_type === 0 ? (
                  <img
                    style={{
                      width: "17px",
                      marginTop: "1px",
                      marginRight: "3px",
                    }}
                    src={OfflineImg}
                    alt={"Offline"}
                  />
                ) : service_type === 1 ? (
                  <img
                    style={{
                      width: "17px",
                      marginTop: "1px",
                      marginRight: "3px",
                    }}
                    src={OnlineImg}
                    alt={"Online"}
                  />
                ) : service_type === 2 ? (
                  <img
                    style={{
                      width: "17px",
                      marginTop: "1px",
                      marginRight: "3px",
                    }}
                    src={HybirdImg}
                    alt={"Hybird"}
                  />
                ) : (
                  ""
                )}
              </span>
              <span style={{ color: "#0085FF", textDecoration: "underline" }}>
                {session_id?.toUpperCase()}
              </span>
            </div>

            <ReactTooltip
              anchorId={"AppointmentID" + i}
              place="bottom"
              //variant="info"
              content={service_name}
            />
          </>
        ),
        time,
        appointment_start_date: appointment_start_date
          ? moment(appointment_start_date).format("h:mm:ss A")
          : "",
        staff,
        ...(writePermissions && {
          action: (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Btn
                bgColor="#2A2A2A"
                width="69px"
                height="24px"
                fontSize="12px"
                borderRadius="100px"
                margin="0 4px"
                click={() => window.open(appointment_link)}
              >
                Rejoin
              </Btn>
              <Btn
                bgColor="#00B091"
                width="89px"
                height="24px"
                fontSize="12px"
                borderRadius="100px"
                margin="0 4px"
                click={() =>
                  setPopupAction({
                    ...popupAction,
                    open: true,
                    action: () =>
                      clientStatus(
                        id,
                        4,
                        moment().format("YYYY-MM-DD HH:mm:ss")
                      ),
                  })
                }
              >
                Complete
              </Btn>
            </div>
          ),
        }),
      };
    }
  );

  return (
    <>
      <CompletePopup
        popupAction={popupAction}
        loading={loading}
        setPopupAction={setPopupAction}
        title="Do you want to complete this appointment?"
        details="Before completing the session please copy the below URL and ask user to provide the feedback regarding the services."
      />
      {!loadingApp ? (
        <CustomTable tHead={tHead} tBody={tBodyData} />
      ) : (
        <div className="loader" style={{ textAlign: "center", margin: "60px" }}>
          <CircularProgress size={28} />
        </div>
      )}
    </>
  );
};
