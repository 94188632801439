import React, { useState, useRef, useEffect } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import styles from "./CustomSelect.module.css";

function CustomSelect({
    popupAction, 
    handleChange,
    selectedOption,
    setSelectedOption,
    isExpanded,
    setIsExpanded,
    formData
}) {


 console.log(popupAction, 'popupAction---')
//   const [selectedOption, setSelectedOption] = useState();
  
  const selectRef = useRef();

  console.log(selectedOption, 'selectedOption')

  const handleToggleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleDocumentClick = (e) => {
    if (isExpanded && !selectRef.current.contains(e.target)) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isExpanded]);

  console.log(popupAction.find((option) => option?.value === formData?.price)?.session, 'rajeev')

  return (
    <div className={styles.customSelect} ref={selectRef}>
      <button className={styles.selectToggle} onClick={handleToggleClick}>
        <div>
          <div className={styles.selectedValue}>
            {
                 formData?.price ?
                 <label className={styles.formControl}>
         
                 <input type="radio" checked />{' '}
                 <div className={styles.dropDownData}>
                 <p className={styles.monthText}>
                 {`${popupAction.find((option) => option?.value === formData?.price)?.month}`}
                 </p>
                 <p className={styles.sessionName}> 
                 {`₹${popupAction.find((option) => option?.value === formData?.price)?.value}`}{' '}
                 {popupAction.find((option) => option?.value === formData?.price)?.session ?
                 `(${popupAction.find((option) => option?.value === formData?.price)?.session}  ${popupAction.find((option) => option?.value === formData?.price)?.session > 1 ? "Sessions" : "Session"})` : ''}</p>
                 </div>
                 </label> : 'Please Select Package'
            }
         
          </div>
          
        </div>
        <FiChevronDown />
      </button>
      {isExpanded && (
        <ul className={styles.selectOptions}>
          {popupAction?.map((option) => (
            <li key={option.value}>
                {
                    option?.month &&
                    <label className={styles.formControl}>
                    <input
                      type="radio"
                      name={"price"}
                      value={option?.value}
                      checked={formData?.price === option?.value}
                      onClick={handleChange}
                    /> {' '}
                    <div className={styles.dropDownData}>
                        <p className={styles.monthText}>{`${option?.month}`}</p>
                        <p className={styles.sessionName}>{option?.session ? `₹${option?.value} (${option?.session} ${option?.session > 1 ? "Sessions" : "Session"})` : ''}</p>
                    </div>
                  </label>
                }
             
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default CustomSelect;
