import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const { GETNOTIFICATION, UPDATENOTIFICATION } = API;

export const getNotification = async () => {
  return await axios
    .get(GETNOTIFICATION)
    .then((res) => res)
    .catch((err) => err);
};

export const editNotifications = async (data) => {
  return await axios
    .post(UPDATENOTIFICATION, data)
    .then((res) => res)
    .catch((err) => err);
};
