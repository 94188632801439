import React from "react";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";

import styles from "./levelscoring.module.css";

export const LevelScoring = ({ color, percentage, value, level }) => {
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <div className={styles.progressContainerWrapper}>
          {percentage ? (
            <>
              <CircularProgress
                variant={"determinate"}
                sx={{
                  color: { color },
                  padding: "15px",
                  gap: 20,
                }}
                size={"150px"}
                thickness={3}
                value={percentage}
              />

              <div className={styles.progressTextContainer}>
                <p className={styles.progressValue}>
                  {Math.floor(percentage)} %
                </p>
                <p style={{ margin: "10px" }}>{value}</p>
              </div>
            </>
          ) : (
            <>
              {/* <div style={{ width: "150px", height: "150px" }}> */}
              <CircularProgress
                variant={"determinate"}
                sx={{
                  color: "#F7F8FA",
                  padding: "15px",
                  gap: 20,
                }}
                size={"150px"}
                thickness={3}
                value={100}
              />
              <div className={styles.progressTextContainer}>
                <p className={styles.progressValue}></p>
                <p>No Data</p>
              </div>
              {/* </div> */}
            </>
          )}
        </div>
        <h4>Level {level}</h4>
      </div>
    </>
  );
};
