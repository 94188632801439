import React from "react";
import classes from "../../assets/css/assessmentneed.module.css";

export const AssessNeedCard = (props) => {
  return (
    <>
      <div className={classes.need_container_title}>
        <p>{props.title}</p>
      </div>
      <div className={classes.need_container_desc}>
        <p>{props.description}</p>
      </div>
    </>
  );
};
