import React from "react";
import AssessmentCardStyles from "../../assets/css/assessmentCard.module.css";
import Button from "@mui/material/Button";
// import {Buttons} from './Button/Button';
import Appointment from "../../assets/icons/icon/calenderIcon.svg";
import { ProgressBar } from "./ProgressBar";
import { Helpcircle } from "../../assets/icons/icon/Helpcircle";
import { Link } from "react-router-dom";
import { formatttedDate } from "../../config/formattedDate";
import { useAuth } from "../../Auth/AuthProvider";
import { setRetakeType } from "../../Redux/Reducers/PatientReducers/qa_storage/qa_storage";
import { useDispatch } from "react-redux";

export const AssessmentCard = (props) => {
  const {
    title,
    Icon,
    user,
    result,
    date,
    category,
    short_desc,
    setPopupAction,
    popupAction,
    categoryId,
    width,
    homePage,
  } = props;

  const handleClick = () => {
    props.onClick?.();
  };

  let progressBar = Math.trunc(result?.percentage);

  const ctx = useAuth();
  const isParent = ctx?.loginUser?.customer?.is_parent;

  const dispatch = useDispatch();

  return (
    <>
      <div
        className={`${
          user
            ? AssessmentCardStyles.card_dashboard
            : AssessmentCardStyles.card_assessment
        } ${homePage ? AssessmentCardStyles.homeCard : ""}`}
        style={{ width }}
      >
        <div
          className={`${AssessmentCardStyles.progress_bar_label_div} ${
            result?.status === "inprogress" && categoryId !== 1
              ? AssessmentCardStyles.visible
              : AssessmentCardStyles.inVisible
          }`}
        >
          <span className={AssessmentCardStyles.progress_bar_label}>
            {progressBar}% completed
          </span>
          <ProgressBar progress={progressBar} />
        </div>

        <div
          className={AssessmentCardStyles.flipCard}
          style={user ? { marginBottom: "auto" } : { marginBottom: "70px" }}
        >
          <div className={AssessmentCardStyles.flipCard_inner}>
            <div className={AssessmentCardStyles.flipCard_front}>
              <div className={AssessmentCardStyles.img_icon_div}>
                <img
                  className={AssessmentCardStyles.img_icon}
                  src={Icon}
                  alt={title}
                  style={
                    user
                      ? { width: "90px", height: "90px" }
                      : { width: "100px", height: "100px" }
                  }
                />
              </div>
              <div className={AssessmentCardStyles.card_title_box}>
                {/* <h1 className={AssessmentCardStyles.card_title}>{title}</h1> */}
                <p className={AssessmentCardStyles.card_title}>{title}</p>
              </div>
            </div>
            <div className={AssessmentCardStyles.flipCard_back}>
              <div className={AssessmentCardStyles.card_title_box}>
                <p
                  className={AssessmentCardStyles.card_title}
                  style={
                    user
                      ? { fontSize: "18px", textAlign: "left" }
                      : { fontSize: "24px", textAlign: "left" }
                  }
                >
                  {title}
                </p>
              </div>
              <br />

              <div
                className={AssessmentCardStyles.instruction_card}
                dangerouslySetInnerHTML={{
                  __html: short_desc,
                }}
              />
            </div>
          </div>
        </div>

        <div className={AssessmentCardStyles.card_bottom_info}>
          {/* <div className={AssessmentCardStyles.card_title_box}>
            <h1
              className={AssessmentCardStyles.card_title}
              style={user ? { fontSize: "18px" } : { fontSize: "18px" }}
            >
              {title}
            </h1>
          </div> */}
          {user ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "16px",
              }}
            >
              <div>
                {/* <span> */}
                {/*   <Helpcircle width="14" height="14" color="#7A51AE" /> */}
                {/* </span> */}
                {/* <span style={{ fontSize: "12px", color: "#2a2a2a" }}> */}
                {/*   &nbsp; {number_of_Question} Questions */}
                {/* </span> */}
              </div>
              &nbsp;&nbsp;&nbsp;
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* {categoryId === 1 ? (
                  ""
                ) : ( */}
                <>
                  <span style={{ marginRight: "5px" }}>
                    <img src={Appointment} alt="calender" />
                  </span>
                  <span style={{ fontSize: "12px", color: "#2a2a2a" }}>
                    {formatttedDate(
                      result?.updated_at || new Date()
                    ).replaceAll("/", "-")}
                  </span>
                </>
                {/* )} */}
              </div>
            </div>
          ) : null}
          <div className={AssessmentCardStyles.card_btn_box}>
            {/* result =inprogress, completed, undefined */}

            {categoryId === 1 && isParent ? (
              <Button
                variant="contained"
                size="small"
                style={{
                  textTransform: "none",
                  width: "100%",
                  backgroundColor: "#7A51AE",
                  borderRadius: "8px",
                  textAlign: "center !important",
                }}
                onClick={() => {
                  setPopupAction({ right: true });
                }}
              >
                Dementia Assessments
              </Button>
            ) : result?.status !== "completed" ? (
              <Button
                variant="contained"
                size="small"
                style={{
                  textTransform: "none",
                  width: user ? "100%" : "100px",
                  backgroundColor: "#7A51AE",
                  borderRadius: "8px",
                  textAlign: "center !important",
                }}
                onClick={handleClick}
              >
                {!user
                  ? "Start"
                  : result?.status === "inprogress"
                  ? "Continue"
                  : "Take Assessment"}
              </Button>
            ) : (
              <>
                {category.is_mapped?.retake ? (
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      background: "#7A51AE",
                      "&:hover": {
                        backgroundColor: "#7A51AE",
                      },
                    }}
                    style={{
                      textTransform: "none",
                      width: "100%",
                      borderRadius: "8px",
                      textAlign: "center !important",
                      margin: "0 10px",
                      // color: "white",
                    }}
                    disabled={!category.is_mapped?.retake}
                    onClick={() => {
                      handleClick();
                      dispatch(setRetakeType("retake"));
                    }}
                  >
                    Retake
                  </Button>
                ) : null}
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    textTransform: "none",
                    width: "100%",
                    backgroundColor: "#7A51AE",
                    borderRadius: "8px",
                    textAlign: "center !important",
                  }}
                >
                  <Link
                    to="/patient/assessment/results"
                    state={{ data: category }}
                    style={{
                      color: "white",
                      textDecoration: "none",
                      width: "inherit",
                    }}
                  >
                    See Results
                  </Link>
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
