import React from "react";
import styles from "./chart.module.css";
import ReactApexChart from "react-apexcharts";
class SleepHoursChart extends React.Component {
  constructor(props) {
    super(props);
    const d = props.getSleepTracker;
    console.log(d, "sleepTracker");
    const apiData = [d?.level1, d?.level2, d?.level3, d?.level4];
    this.state = {
      series: [
        {
          data: apiData,
        },
      ],
      noData: {
        text: "No data available",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "14px",
        },
      },
      options: {
        chart: {
          height: 250,
          type: "bar",
          events: {
            click: function (chart, w, e) {
              // console.log(chart, w, e)
            },
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        colors: ["#EE4925", "#AAC514", "#23C363", "#FBAA34"],
        plotOptions: {
          bar: {
            columnWidth: "15%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: [["Level 1"], ["Level 2"], ["Level 3"], ["Level 4"]],
          labels: {
            style: {
              // colors: colors,
              fontSize: "12px",
            },
          },
        },
        yaxis: {
          max: d?.customerCount,
          forceNiceScale: true,
          labels: {
            formatter: function (value) {
              if (Number.isInteger(value)) {
                return value;
              }
            },
          },
          title: {
            text: "No. of employees",
            style: {
              color: "#2A2A2A",
              fontSize: "10px",
              fontFamily: "Public Sans",
              fontWeight: 300,
            },
          },
        },
      },
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.getSleepTracker !== this.props.getSleepTracker) {
      const apiDa = [
        {
          data: [
            this.props.getSleepTracker?.["level1"],
            this.props.getSleepTracker?.["level2"],
            this.props.getSleepTracker?.["level3"],
            this.props.getSleepTracker?.["level4"],
          ],
        },
      ];
      this.setState({
        ...this.state,
        series: apiDa,
        options: {
          ...this.state.options,
          yaxis: {
            ...this.state.options.yaxis,
            max: this.props.getSleepTracker["customerCount"],
          },
        },
      });
    }
  }

  render() {
    return (
      <div className={styles.mainChartWrapper}>
        <div className={styles.wrapper} id="chart">
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="bar"
            height={250}
            width={"100%"}
          />
        </div>
        <div className={styles.bottomLabel}>
          <div className={styles.bottomlabelText}>
            <span className={styles.LevelOne}></span>
            <div className={styles.bottomText}>
              <p className={styles.levelSec}>Level 1</p>
              <p className={styles.hourSec}>{"<5 hours"}</p>
            </div>
          </div>
          <div className={styles.bottomlabelText}>
            <span className={styles.LevelTwo}></span>
            <div className={styles.bottomText}>
              <p className={styles.levelSec}>Level 2</p>
              <p className={styles.hourSec}>{"5 - 7 hours"}</p>
            </div>
          </div>
          <div className={styles.bottomlabelText}>
            <span className={styles.LevelThree}></span>
            <div className={styles.bottomText}>
              <p className={styles.levelSec}>Level 3</p>
              <p className={styles.hourSec}>{"7 - 10 hours"}</p>
            </div>
          </div>
          <div className={styles.bottomlabelText}>
            <span className={styles.LevelFour}></span>
            <div className={styles.bottomText}>
              <p className={styles.levelSec}>Level 4</p>
              <p className={styles.hourSec}>{"10< hours"}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SleepHoursChart;
