import React from "react";
import { useState } from "react";
import { useLayoutEffect } from "react";
import { useEffect } from "react";
import moment from "../../../../../node_modules/moment/moment";
import { useLocation } from "../../../../../node_modules/react-router-dom/index";
import { Helpcircle } from "../../../../assets/icons/icon/Helpcircle";
import axios from "../../../../config/axiosConfig";
import { formatttedDate } from "../../../../config/formattedDate";
import { DementiaScreening } from "../../../Components/Images/DementiaScreening";
import { CalenderIcon } from "../../../Components/Report/CalenderIcon";
import Logo from "../../../../assets/icons/icon/default_profile.svg";

import styles from "./assessment.module.css";

export const Details = () => {
  const { state } = useLocation();

  const [details, setDetails] = useState({
    ...state.assessmentData,
  });

  useLayoutEffect(() => {
    axios
      .post("/enterprise/getAssessmentDetails", { assissement_id: state?.id })
      .then((res) => {
        setDetails(res?.data?.list);
      });
  }, []);

  // const endDate = moment(details?.expiry).add(1, "M").format("DD/MM/YYYY");

  return (
    <>
      {/* <div className={styles.headingContainer}> */}
      <div className={styles.container}>
        <img
          src={details?.assessment_image}
          alt=""
          width="150px"
          height="150px"
          style={{ objectFit: "contain" }}
        />

        <div>
          <div className={styles.detailHeadingContainer}>
            <h2>{details?.name}</h2>
            <p style={{ lineHeight: "22px" }}>{details?.short_description}</p>
          </div>
          <div className={styles.iconsContainer}>
            <div className={styles.innerIconsContainer}>
              <Helpcircle color="#000" width="42" height="42" />
              <div className={styles.iconText}>
                <p>No. of questions</p>
                <p>{details?.no_of_question}</p>
              </div>
            </div>
            <div className={styles.innerIconsContainer}>
              <CalenderIcon width="42" height="42" />
              <div className={styles.iconText}>
                <p>Start Date</p>
                <p>{formatttedDate(details.date_of_creation)}</p>
              </div>
            </div>
            <div className={styles.innerIconsContainer}>
              <CalenderIcon width="42" height="42" />
              <div className={styles.iconText}>
                <p>End Date</p>
                <p>{formatttedDate(details.expiry)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.divider}></div>
      <div>
        <h3>Instructions</h3>
        <div
          className={styles.listContainer}
          dangerouslySetInnerHTML={{
            __html: details?.instruction,
          }}
        />
      </div>
    </>
  );
};
