import { SummaryCard } from "../../../Component/UI/SummaryCard";
import styles from "../../../assets/css/PatientDashboard/appointments.module.css";
import { useLocation } from "react-router-dom";
import SimpleBreadcrum from "../../../Component/UI/SimpleBreadcrum";
import { Btn } from "../../UI/Button";
import Modal from "./Mpdel";
import { useState } from "react";
import { cancelAppointment } from "../../../services/patient/appointments";
import { useDispatch } from "react-redux";
import { cancelledAppointmentRed } from "../../../Redux/Slices/UserAppointments/typeAppointments";
import {
  toast,
  Toaster,
} from "../../../../node_modules/react-hot-toast/dist/index";
import moment from "../../../../node_modules/moment/moment";
import RescheduleModal from "./RescheduleModel";
import { useEffect } from "react";
import axios from "../../../config/axiosConfig";
import { StringDtoY, to12Hour } from "../../../config/formattedDate";
import Doctor from "../../../assets/images/doctor.png";
import NoDoctor from "../../../assets/images/noDr.svg";

import { useNavigate } from "../../../../node_modules/react-router-dom/index";
import pic from "../../../assets/images/doctor.png";
import { Readmore } from "./ReadMore";
export const Details = () => {
  function createData(name, value) {
    return { name, value };
  }
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [timeSlot, setTimeSlot] = useState();

  const [open, setOpen] = useState(false);
  const [openReschedule, setOpenReschedule] = useState({ open: false });
  const [cancelApp, setCancelApp] = useState();
  const location = useLocation();
  const state = location.state.data;
  const locationPage = location.state.appPage;
  const [loading, setLoading] = useState(false);

  console.log(state, 'state===')

  const joinLink = () => {
    window.open(
      state && state?.appointments && state?.appointments[0]?.appointment_link
    );
  };
  const values = [
    [
      "Session Id",
      <span style={{ textTransform: "uppercase" }}>
        {state && state?.appointments && state?.appointments[0]?.session_id}
      </span>,
    ],
    ["Service Name", state?.service?.name],
    [
      "Session Date",
      state?.appointments?.[0].appointment_date?.split(" ")?.[0],
    ],
    ["Appointment Time", state?.appointments?.[0].appointment_time],
    [
      "Session Type",
      state?.service?.type_of_service === "0"
        ? "Offline"
        : state?.service?.type_of_service === "1"
        ? "Online"
        : state?.service?.type_of_service === "2"
        ? "Hybrid"
        : "",
    ],

    [
      locationPage === "completed" || locationPage === "cancelled"
        ? ""
        :  state?.service?.type_of_service?.toString() === "0" ? "Address" :
        state?.service?.type_of_service?.toString() === "1" ? "Appointment Link":
        state?.service?.type_of_service?.toString() === "2" ? "Appointment Link/Address" : "",




        
      state &&
      state?.appointments &&
      state?.appointments[0]?.appointment_link ? (
        (state &&
          state?.appointments &&
          state?.appointments[0]?.appointment_link?.includes("http://")) ||
        state?.appointments[0]?.appointment_link?.includes("https://") ? (
          <Btn
            variant="contained"
            bgColor="#fff"
            color="#000"
            boxShadow="none"
            width="auto"
            height="auto"
            whiteSpace="nowrap"
            borderRadius="100px"
            marginRight="10px"
            click={joinLink}
          >
            Join here
          </Btn>
        ) : (
          state &&
          state?.appointments &&
          state?.appointments[0]?.appointment_link
        )
      ) : (
        "-"
      ),
    ],
  ];

  console.log(state, '------')

  const links = [
    {
      path: "/patient/Appointments",
      linkText: "Appointments",
    },
  ];

  const data = values.map((i) => createData(...i));

  const cancleAppointments = () => {
    setLoading(true);
    cancelAppointment({
      appointment_id: state.appointment_id,
      cancel_by: "patient",
    }).then((res) => {
      setLoading(true);
      if (res?.data?.status) {
        toast.success(res?.data?.message);
        setOpen(false);
        navigate("/patient/Appointments");
      }
    });
  };

  const appStartDate = moment(
    state?.appointments[0].appointment_date,
    "YYYY-MM-DD HH:mm:ss"
  ).format("YYYY-MM-DD HH:mm:ss");

  const currentDateTime = moment().format("YYYY-MM-DD HH:mm:ss");

  const diffHours = (currentDate, startDate) => {
    var diff = (currentDate.getTime() - startDate.getTime()) / 1000;
    diff /= 60 * 60;
    return Math.abs(Math.round(diff));
  };

  var dt1 = new Date(currentDateTime);
  var dt2 = new Date(appStartDate);

  const totalHours = diffHours(dt2, dt1);


  // const CustomToastWithLink = () => (
  //   <a href="mailto:helpdesk@samvednacare.com">helpdesk@samvednacare.com</a>
  
  // );



  const feedBackText = (...c) =>
    state?.staff_description ? state?.staff_description.slice(...c) : "-";

  return (
    <>
      <Toaster />
      <SimpleBreadcrum noHeading text="Appointments Details" link={links} />
      <div className={styles.details}>
       
        <div className={styles.doctorDox}>
        <div className={styles.doctorBoxRight}>
        <h2 style={{ marginBottom: "20px" }}>{"Appointment Details"}</h2>
            <SummaryCard
              data={data}
              // description="Dr. Jayashree Dasgupta is a Co-Founder and Project Director at Samvedna Senior Care. A clinical psychologist by training with a specialization in neuropsychology, Dr Dasgupta holds an MPhil and PhD from the National Institute of Mental Health and Neurosciences (NIMHANS), Bangalore. She has over 15 years of clinical and research experience. She has worked with both adults and children and presented her work at severa... international forums as well as published in international peer reviewed journals."
              rows={2}
            />

            <div className={styles.btnGroup}>
              {locationPage === "completed" || locationPage === "cancelled" || locationPage === "ongoing" ? (
                ""
              ) : (
                <Btn
                  variant="contained"
                  bgColor="#000000"
                  width="175px"
                  height="34px"
                  whiteSpace="nowrap"
                  borderRadius="100px"
                  marginRight="10px"
                  click={() => {
                    if(parseInt(state?.service?.enterprise_service_type) === 0 && parseInt(state?.service?.services_for) === 1){
                      toast.error(`Please contact helpdesk@samvednacare.com to cancel the appointment. You have option to reschedule your appointment.`);
                    }else {
                      if (totalHours > 48) {
                        setOpen(true);
                      } else {
                        toast.error("You can not cancel this Appointment");
                      }
                    }
                    
                  }}
                >
                  Cancel Appointment
                </Btn>
              )}

              {locationPage === "upcoming" ? (
                <Btn
                  variant="contained"
                  bgColor="#7A51AE"
                  width="175px"
                  height="34px"
                  whiteSpace="nowrap"
                  borderRadius="100px"
                  //disabled = {!state?.staff_name }
                  click={() =>
                    setOpenReschedule({
                      open: true,
                      body: { appId: state?.appointment_id, 
                        appointment_date : state?.appointments?.[0].appointment_date,
                        appointment_time : state?.appointments?.[0].appointment_time,
                        staff_id : state?.appointments?.[0].staff_id,
                        staffName : state?.staff_name,
                        roster_id:state?.roster_id
                      },
                    })
                  }
                >
                  Reschedule
                </Btn>
              ) : (
                ""
              )}

              {openReschedule.open && (
                <RescheduleModal
                  setOpenReschedule={setOpenReschedule}
                  openReschedule={openReschedule}
                />
              )}

              {open && (
                <Modal
                  loading={loading}
                  cancleAppointments={cancleAppointments}
                  setOpen={setOpen}
                />
              )}
            </div>
          </div>
          <div className={styles.doctorBoxLeft}>
          <h2 style={{ marginBottom: "20px" }}>{"Expert Details"}</h2>
            {
              state?.staff_name ? 
              <>
                <div className={styles.leftTopBox}>
              <div className={styles.doctorImg}>
                <img
                  src={state?.staff_logo ? state?.staff_logo : Doctor}
                  alt=""
                />
              </div>
              <div className={styles.doctorText}>
                <p className={styles.drName}>
                  {state?.staff_name ? state?.staff_name : "-"}
                </p>
                <p className={styles.name}>{"Qualification"}</p>
                <p className={styles.value}>
                  {state?.staff_qualification
                    ? state?.staff_qualification
                    : "-"}
                </p>
                <p className={styles.name}>{"Speciality"}</p>
                <p className={styles.value}>
                  {state?.staff_specialization
                    ? state?.staff_specialization
                    : "-"}
                </p>
              </div>
            </div>
            <div className={styles.bottomTex}>
              <p className={styles.name}>{"Profile"}</p>
              <div className={styles.drdetail}
              dangerouslySetInnerHTML={{
              __html: state?.staff_description,
            }}
          />
              {/* <p className={styles.aboutUs}>
                {feedBackText(0, 140)}
                {feedBackText(0).length > 150 ? (
                  <Readmore text={feedBackText(140)} />
                ) : null}
              </p> */}
            </div>
              </>
              : <div style={{display: 'flex', alignItems: 'center', flexDirection: "column", justifyContent: "space-between"}}>
                  <p><img src={NoDoctor} alt={"No Dr"} /></p>
                  <p className={styles.noDrtext}> You will be assigned a Doctor/Expert soon!</p>
                  
                </div>
            }
            
          </div>
          
        </div>
      </div>
    </>
  );
};
