import React, { useState } from "react";
import { useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../Auth/AuthContext";
import classes from "../../assets/css/Enterprise/Login/enterpriselogin.module.css";
import LoginImage from "../../assets/icons/LoginImage.svg";
import { Input, InputWithIcon } from "../../Component/UI/CustomInputs";
import logo from "../../assets/icons/icon/logo.svg";
import AssessmentStyles from "../../assets/css/assessment.module.css";
import { useLayoutEffect } from "react";
import {
  enterpriseData,
  forgotEnterprisePassword,
} from "../../services/enterprise/prelogin";
import toast, { Toaster } from "react-hot-toast";
import { Btn } from "../../Samvenda-Patient/UI/Button";
import { CircularProgress } from "../../../node_modules/@mui/material/index";

export const ForgotPasswordEnterprise = () => {
  const ctx = useContext(AuthContext);
  const navigate = useNavigate();

  const { orgId } = useParams();

  const [userDetail, setuserDetail] = useState({
    email: "",
  });
  const [error, setError] = useState({
    email: "",
  });
  const [message, setMessage] = useState();

  const [orgData, setOrgData] = useState(null);

  const [disable, setDisable] = useState(false);

  useLayoutEffect(() => {
    enterpriseData(orgId).then((res) => {
      if (res.data.status) {
        setOrgData(res.data.lead);
      } else {
        toast.remove();
        toast.error("Not registerd in the system");
        setTimeout(() => {
          navigate("/enterprise/mental-health-in-the-workplace");
        }, 3000);
      }
    });
  }, []);

  const handleChange = (event) => {
    setuserDetail({
      ...userDetail,
      [event.target.name]: event.target.value,
    });
  };

  let emailArr = orgData?.domain?.split() || "";

  const other_Domains = orgData?.other_domains
    ? orgData?.other_domains?.split(",")
    : "";

  let finalEmailArr = [...emailArr, ...other_Domains];

  const handleForgotPassword = (e) => {
    e.preventDefault();

    let domain = userDetail.email?.split("@")[1];
    const validationStatus = finalEmailArr?.some((email) => {
      return email.trim() === domain;
    });

    if (validationStatus) {
      setDisable(true);
      forgotEnterprisePassword({
        email: userDetail.email,
      })
        .then((res) => {
          if (res.data.status) {
            toast.remove();
            toast.success(res.data.msg);
          } else {
            toast.remove();
            toast.error(res.data.msg);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setDisable(false));
    } else {
      toast.remove();
      toast.error("Please use your work email !");
    }
  };
  return (
    <>
      <Toaster />
      <div
        className={classes.login_container}
        style={{ background: `url(${LoginImage})` }}
      >
        <header className={AssessmentStyles.header}>
          <Link to="/">
            <img className={AssessmentStyles.logo} src={logo} alt="logo" />
          </Link>
        </header>
        <form
          className={classes.login_subcontainer}
          onSubmit={(e) => handleForgotPassword(e)}
        >
          <div>
            <div className={classes.logo_div}>
              {orgData?.img_url ? (
                <img
                  style={{ width: "60%" }}
                  src={orgData?.img_url}
                  alt={"org"}
                />
              ) : null}
            </div>
            <p className={classes.heading}>Forgot Password</p>
            <p style={{ fontSize: "14px" }}>
              Please enter the registered email address on which you will get
              the reset password link.
            </p>
            <div className={classes.input}>
              <p style={{ marginTop: "20px" }}>Email Address</p>
              <Input
                type="text"
                id="email"
                value={userDetail?.email}
                name="email"
                labelSize={14}
                onChange={(e) => handleChange(e)}
                placeholder="Email Address"
                style={{ outlineColor: "#7a51ae" }}
                autoComplete="off"
                error={error?.email}
              />
            </div>
            <div>
              <p style={{ color: "red", float: "left", marginBottom: "25px" }}>
                {message !== "" ? message : null}
              </p>
            </div>
          </div>

          <div className={classes.submit}>
            <Btn
              variant="contained"
              borderRadius="4px"
              bgColor="#3592FF"
              color="#fff"
              fontSize="16px"
              width="100%"
              height="48px"
              margin="10px 0px"
              click={() => handleForgotPassword()}
              disabled={disable}
            >
              {disable ? <CircularProgress /> : "Continue"}
            </Btn>
          </div>
          <div className={classes.enterprise_poweredby}>
            <p>Already have access to your account?</p>
            <div className={classes.forgot_text}>
              <Link to={`/enterprise/${orgId}/login`}>
                <span style={{ textAlign: "center", color: "#3592FF" }}>
                  Sign in
                </span>
              </Link>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
