import React from "react";
import Chart from "../Charts/Charts";
import Nutrition from "../Charts/images/mdi_nutrition.svg";
import Cognition from "../Charts/images/cognitve.svg";
import SelfCare from "../Charts/images/selfCare.svg";
import Exercise from "../Charts/images/Exercise.svg";
import { Link } from "react-router-dom";

import Socialization from "../Charts/images/social.svg";
import { SkeletonLoaderNew } from "../../../../Component/Common/SkeletonLoader";
export const Activity = ({
  styles,
  styles2,
  sleepHourData,
  isLoadingActivity,
  getActivityScoreData,
}) => {
  console.log(sleepHourData, "sleepHourData----");
  return (
    <div className={styles.cardChart}>
      <div className={styles.chartHead}>Activity</div>
      {!isLoadingActivity ? (
        <div className={styles2.mainChart}>
          <>
            <Chart
              type={"pie"}
              labels={[
                "Nutrition",
                "Cognition",
                "Self Care",
                "Exercise",
                "Socialization",
              ]}
              colors={["#50CD89", "#00CCF1", "#FF7978", "#FFC070", "#7A51AE"]}
              sleepHourData={sleepHourData}
              width="230"
            />
            <div className={styles2.customLabel}>
              <div className={styles2.firstRow}>
                <div className={styles2.chartImg}>
                  <img src={Nutrition} alt="Nutrition" />
                  <div className={styles2.chartText}>
                    Nutrition
                    {
                      <span>
                        {" "}
                        {sleepHourData[0] ? sleepHourData[0] + "%" : 0 + "%"}
                      </span>
                    }
                  </div>
                </div>
                <div className={styles2.chartImg}>
                  <img src={Cognition} alt="Cognition" />
                  <div className={styles2.chartText}>
                    Cognition
                    {
                      <span>
                        {" "}
                        {sleepHourData[1] ? sleepHourData[1] + "%" : 0 + "%"}
                      </span>
                    }
                  </div>
                </div>
              </div>
              <div className={styles2.secondRow}>
                <div className={styles2.chartImg}>
                  <img src={SelfCare} alt="SelfCare" />
                  <div className={styles2.chartText}>
                    Self Care
                    {
                      <span>
                        {" "}
                        {sleepHourData[2] ? sleepHourData[2] + "%" : 0 + "%"}
                      </span>
                    }
                  </div>
                </div>

                <div className={styles2.chartImg}>
                  <img src={Exercise} alt="Exercise" />
                  <div className={styles2.chartText}>
                    Physical
                    {
                      <span>
                        {" "}
                        {sleepHourData[3] ? sleepHourData[3] + "%" : 0 + "%"}
                      </span>
                    }
                  </div>
                </div>
              </div>
              <div className={styles2.thirdRow}>
                <div className={styles2.chartImg}>
                  <img src={Socialization} alt="Socialization" />
                  <div className={styles2.chartText}>
                    Socialization
                    {
                      <span>
                        {" "}
                        {sleepHourData[4] ? sleepHourData[4] + "%" : 0 + "%"}
                      </span>
                    }
                  </div>
                </div>

                <div></div>
              </div>
            </div>
          </>
        </div>
      ) : (
        <SkeletonLoaderNew />
      )}

      <div className={styles.bottomChart}>
        <div className={styles.bottomText}>
          <p>
            <Link
              to="/Patients"
              style={{
                textDecoration: "none",
                color: "#7a51ae",
              }}
              state={{
                data: getActivityScoreData?.customer_Data_id,
                wellness: true,
              }}
            >
              #Users tracking progress: {getActivityScoreData?.attemptedPerson}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
