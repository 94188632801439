import "./FileUpload.css";

export const FileUploadPdf = ({
  marginTop,
  icon,
  actualBtnRef,
  onChange,
  fileName,
}) => {
  return (
    <>
      <div className="fileUpload">
        <div className="fileSelect">
          <div className="fileSelectButton" id="fileName">
            {"Choose File"}
          </div>
          <div className="fileSelectName" id="noFile">
            {fileName ? fileName : "No file chosen..."}
          </div>
          <input
            type="file"
            ref={actualBtnRef}
            name="chooseFile"
            id="chooseFile"
            accept=".pdf"
            onChange={onChange}
          />
        </div>
      </div>
    </>
  );
};

export const FileUploadBanner = ({
  marginTop,
  icon,
  actualBtnRef,
  onChange,
  fileName,
}) => {
  return (
    <>
      <div className="fileUpload">
        <div className="fileSelect">
          <div className="fileSelectButton" id="fileName">
            {"Choose File"}
          </div>
          <div className="fileSelectName" id="noFile">
            {fileName ? fileName : "No file chosen..."}
          </div>
          <input
            type="file"
            ref={actualBtnRef}
            name="chooseFile"
            id="chooseFile"
            accept=".png,.jpg"
            onChange={onChange}
          />
        </div>
      </div>
    </>
  );
};
