import React, { useState } from "react";
import axios from "../../../config/axiosConfig";
import { useSelector } from "react-redux";
import { Grid } from "../../../../node_modules/@mui/material/index";
import {
  useLocation,
  useNavigate,
} from "../../../../node_modules/react-router-dom/index";
import { Info } from "../../UI/Info";
import styles from "../SamvednaNew/samvedna.module.css";
import BasicBreadcrumbs from "../../../Component/UI/BreadCrumb/BreadCrumbs";
import { ADMIN_ROUTES } from "../../../Routes/routesConstants";
import { EnterpriserGeneralProfileForm } from "./GeneralProfileForm";
import CustomizedButtons from "../../../Component/UI/Button/CustomizeButton";
import toast, { Toaster } from "react-hot-toast";
import { TEXT } from "../../contant/constant";
import { usePermissions } from "../../../Hooks/usePermissions";

const EnterpriseGeneralProfile = () => {
  const [response, setResponse] = useState();
  const { data } = useSelector((i) => i.serviceDetails);
  const [error, setError] = useState([]);
  const navigate = useNavigate();
  const [datas, setData] = useState();
  const [formErrors, setformErrors] = useState({});
  const [imageSize, setImageSize] = useState();
  const permissions = usePermissions("name", "enterprises");

  const { state } = useLocation();

  const sucessNotify = (msg) => toast.success(msg);

  const addProfile = () => {
    toast.remove();
    // addServices(data).then((i) => {
    //   setResponse(i?.data);
    //   if (i?.data?.status) {
    //     navigate("/Services");
    //   } else {
    //     setError(i?.data?.error);
    //   }
    // });
    if (imageSize > TEXT.MAX_FILE_SIZE) {
      toast.error("Image size is too large!");
    }

    let formdata = new FormData();
    Object.keys(datas).map((key) => {
      formdata.append(key, datas[key]);
    });

    if (state?.id) {
      formdata.append("id", state.id);
    }

    const API =
      state?.from === "edit"
        ? "/admin/enterprise/edit"
        : "admin/enterprise/add";

    axios.post(API, formdata).then((res) => {
      if (res?.data?.status) {
        sucessNotify(res.data.message);
        navigate("/enterprises");
      }
      if (res.data?.error) {
        setformErrors(res.data.error);
      }
    });

    // //.catch((err) =>{errorNotify(err.data.error.email)
    // });
  };
  console.log(formErrors, "errorss");
  const cancelService = () => {
    navigate("/Services");
  };
  const links = [
    {
      path: ADMIN_ROUTES.ENTERPRISES,
      linkText: "Enterprise",
    },
  ];

  if (state?.data) {
    links.push({
      path: ADMIN_ROUTES.ENTERPRISES_VIEWBOX,
      linkText: state?.data?.company_name,
      data: { ...state?.data, permissions },
    });
  }

  return (
    <BasicBreadcrumbs link={links} text="General Information">
      <div>
        <EnterpriserGeneralProfileForm
          response={response}
          error={formErrors}
          checkedstate={true}
          formdata={data}
          setData={setData}
          state={state}
          setImageSize={setImageSize}
          flag
        />
        <div
          className="styles.buttons_flex"
          style={{ display: "flex", marginTop: "20px", gap: "20px" }}
        >
          <CustomizedButtons
            btncoloractive={"active"}
            ButtonLabel={"Save"}
            click={addProfile}
          />
        </div>
      </div>
      <Toaster />
    </BasicBreadcrumbs>
  );
};

export default EnterpriseGeneralProfile;
