import React from "react";
import styles from "./assessmentsChart.module.css";
import SleepHour from "../../../assets/images/sleepHour.svg";
import SleepQue from "../../../assets/images/sleepQul.svg";
import MoodScore from "../../../assets/images/moodScore.svg";
import Activity from "../../../assets/images/activity.svg";
import PieChart from "./Charts/PieChart/PieChart";
import { useEffect } from "react";
import { useState } from "react";
import {
  getDailyActivity,
  getMoodActivityData,
  getSleepQualityData,
  getSleepTrackerData,
} from "../../../services/enterprise/dashboard";
import { useAuth } from "../../../Auth/AuthProvider";
import MoodScoreChart from "./Charts/PieChart/DonutChart";
import SleepHoursChart from "./Charts/PieChart/SleepHoursChart";
import SleepQualityChart from "./Charts/PieChart/SleepQuality";
function AssessmentsChart({ selected }) {
  const [getActivity, setGetActivity] = useState();
  const [getMoodActivity, setMoodGetActivity] = useState();
  const [getSleepTracker, setGetSleepTracker] = useState();
  const [getSleepQuality, setGetSleepQuality] = useState();
  console.log(getSleepTracker, "getSleepTracker");

  const ctx = useAuth();

  const fetchSleepQuality = () => {
    getSleepQualityData({ enterprise_id: ctx?.loginUser?.enterprise?.id }).then(
      (res) => {
        setGetSleepQuality(res?.data?.sleepQuality);
      }
    );
  };

  useEffect(() => {
    const data = {
      assessment_id: selected,
      enterprise_id: ctx?.loginUser?.enterprise?.id,
    };
    getDailyActivity(data)
      .then((res) => {
        setGetActivity(res?.data?.list);
      })
      .catch((err) => console.log(err));
    // sleep quality
    fetchSleepQuality();
  }, [selected]);

  useEffect(() => {
    const data = {
      assessment_id: selected,
      enterprise_id: ctx?.loginUser?.enterprise?.id,
    };
    getMoodActivityData(data)
      .then((res) => {
        setMoodGetActivity(res?.data?.moodavg);
      })
      .catch((err) => console.log(err));
  }, [selected]);

  useEffect(() => {
    const data = {
      assessment_id: selected,
      enterprise_id: ctx?.loginUser?.enterprise?.id,
    };
    getSleepTrackerData(data)
      .then((res) => {
        setGetSleepTracker(res?.data?.sleep);
      })
      .catch((err) => console.log(err));
  }, [selected]);
  return (
    <div className={styles.chaetmain}>
      <div className={styles.chartWrapper}>
        <div className={styles.cardChart}>
          <div className={styles.chartHead}>Sleep Hours</div>
          <SleepHoursChart getSleepTracker={getSleepTracker} />
          {/* <img src={SleepHour} alt="Sleep hour" /> */}
        </div>
        <div className={styles.cardChart}>
          <div className={styles.chartHead}>Sleep Quality</div>
          <SleepQualityChart getSleepQuality={getSleepQuality} />
          {/* <img src={SleepQue} alt="Sleep Quality" /> */}
        </div>
      </div>
      <div className={styles.chartWrapper}>
        <div className={styles.cardChart}>
          <div className={styles.chartHead}>Mood Score</div>
          <MoodScoreChart
            getMoodActivity={getMoodActivity}
            attemptedPerson={getSleepTracker?.attemptedPerson}
          />
          <div className={styles.bottomText}>
            <p>
              #Employees Tracking Progress: {getSleepTracker?.attemptedPerson}
            </p>
          </div>
          {/* <img src={MoodScore} alt="Mood Score" /> */}
        </div>
        <div className={styles.cardChart}>
          <div className={styles.chartHead}>Activity</div>
          <PieChart getActivity={getActivity} />
          <div className={styles.bottomText}>
            <p>#Employees Tracking Progress: {getActivity?.attemptedPerson}</p>
          </div>
          {/* <img src={Activity} alt="Activity" /> */}
        </div>
      </div>
    </div>
  );
}

export default AssessmentsChart;
