import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { completedEvents } from "../../../Redux/Slices/PatientEvents";
import { getEvents } from "../../../services/patient/events";
import { Cards } from "./Cards";
import { searchAndDateFilter } from "./All";
import {
  setSearchText,
  setToggleSearch,
} from "../../../Redux/Slices/patientSearch";
import {
  setDate,
  setToggle,
  setYear,
} from "../../../Redux/Slices/Patient/PatienCalender";

export const Completed = () => {
  const dispatch = useDispatch();
  const { completed, message } = useSelector((i) => i.PatientEvents);
  const { searchText } = useSelector((i) => i.patientSearch);
  const { selectedDate } = useSelector((i) => i.DateFilterSlice);
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    getEvents("completed").then((i) => {
      dispatch(completedEvents(i));
      setAllData(i);
    });
    return () => {
      dispatch(setToggleSearch(false));
      dispatch(setSearchText(""));
      dispatch(setDate(`All ${new Date().getFullYear()}`));
      dispatch(setToggle(false));
      dispatch(setYear(new Date().getFullYear()));
    };
  }, [message]);

  useEffect(() => {
    const searchResult = searchAndDateFilter(allData, searchText, selectedDate);
    dispatch(completedEvents(searchResult));
  }, [searchText, selectedDate, allData]);

  return <Cards data={completed} />;
};
