import React from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "../../../../../node_modules/react-router-dom/index";
import SimpleBreadcrum from "../../../../Component/UI/SimpleBreadcrum";
import { SummaryCard } from "../../../../Component/UI/SummaryCard";
import { UploadProfile } from "../../../../Component/UI/UploadProfile";
import { formatttedDate } from "../../../../config/formattedDate";
import { Btn } from "../../../../Samvenda-Patient/UI/Button";
import styles from "./staff.module.css";

export const ViewStaff = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state?.item;
  const writePermissions = location.state?.writePermissions;
  console.log(state, "state---");

  const modeOfWorking = ["Remote", "Hybrid", "In office"];
  const role = state?.role_name?.map((i) => i?.name)?.join(", ");

  const links = [
    {
      path: "/Samvedna/staff",
      linkText: "Staff",
    },
  ];
  const subscriptionDetails = [
    {
      name: "Full Name",
      value: state?.name || "--",
    },
    {
      name: "Date of Birth",
      value: state?.dob || "--",
    },
    {
      name: "Email",
      value: state?.email || "--",
    },
    {
      name: "Mobile",
      value: state?.mobile || "--",
    },
    {
      name: "Role",
      value: role || "--",
    },
    {
      name: "Mode of Working",
      value: modeOfWorking[state?.mode - 1] || "--",
    },
    {
      name: "Date of Joining",
      value: state?.joining_date
        ? formatttedDate(state?.joining_date)
        : "N/A" || "--",
    },
    {
      name: "Qualification",
      value: state?.qualification || "--",
    },
    {
      name: "Current Address",
      value: state?.current_address || "--",
    },
    {
      name: "Permanent Address",
      value: state?.permanent_address || "--",
    },
  ];
  return (
    <div>
      <SimpleBreadcrum text="Staff Details" link={links} />
      <div className={styles.viewCard}>
        <div>
          <SummaryCard data={subscriptionDetails} />
          <p style={{ fontSize: 14, marginBottom: 8 }}>About Employee</p>
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: state?.description || "--",
            }}
          />
          {/* <p className={styles.description}>{state?.description || "--"}</p> */}
          <div style={{ marginTop: 24 }}>
            <Btn
              bgColor="#00B091"
              disabled={!writePermissions}
              borderRadius="100px"
              click={() => navigate("/Samvedna/staff/edit", { state })}
            >
              Edit
            </Btn>
          </div>
        </div>
        <UploadProfile
          profile={state?.staff_image}
          size={130}
          color="#00B091"
          right
          disabled
        />
      </div>
    </div>
  );
};
