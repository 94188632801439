import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const { SHARE_REPORT_EMAIL, GET_REPORT } = API;

export const share_Report_Email = async ({ assessment_id, level, type }) => {
  return await axios
    .get(
      `${SHARE_REPORT_EMAIL}?assessment_id=${assessment_id}&level=${level}&type=${type}`
    )
    .then((res) => res.data.list)
    .catch((err) => console.log(err));
};

export const get_Report = async ({
  assessment_id,
  level,
  type,
  customer_id,
}) => {
  return await axios
    .post(GET_REPORT, { assessment_id, customer_id, level, type })
    .then((res) => res)
    .catch((err) => console.log(err));
};
