import React, { useEffect, useState } from "react";
import styles from "./doctorPopup.module.css";
import { RiCloseLine } from "react-icons/ri";
import pic from "../../../assets/images/doctor.png";

const Modal = ({ setPopupAction, modalData }) => {
  return (
    <>
      <div
        className={styles.darkBG}
        onClick={() =>
          setPopupAction((prev) => {
            return { ...prev, open: false };
          })
        }
      />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <div className={styles.heading}>
              <div style={{ width: "100%" }}>Expert Details</div>
              {
                <button
                  className={styles.closeBtn}
                  onClick={() =>
                    setPopupAction((prev) => {
                      return { ...prev, open: false };
                    })
                  }
                >
                  <RiCloseLine style={{ marginBottom: "-3px" }} />
                </button>
              }
            </div>
          </div>

          <div className={styles.modalContent}>
            <div className={styles.modelTopContent}>
              <div className={styles.doctorImg}>
                <img alt={modalData?.name} src={modalData?.staff_image ? modalData?.staff_image : pic} />
              </div>
              <div className={styles.doctorData}>
                <div className={styles.modelheaderInner}>
                  <p className={styles.modelDescription}>{modalData?.name ? modalData?.name : '-'}</p>
                  <p className={styles.titleHead}>Qualification</p>
                  <p className={styles.modelDescription}>
                    {modalData?.qualification ? modalData?.qualification : '-'}
                  </p>
                  <p className={styles.titleHead}>Speciality</p>
                  <p className={styles.modelDescription}>
                    {modalData?.specialization ? modalData?.specialization : '-'}
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.modelBottom}>
              <p className={styles.titleHead}>Profile</p>
              <div className={styles.modelDescriptionAbout}
              dangerouslySetInnerHTML={{
              __html: modalData?.description,
            }}
          />
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
