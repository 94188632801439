import { useEffect } from "react";
import { useRef, useState } from "react";
import "./FileUpload.css";

const FileUpload = ({ onChange, ref, fileName, upload, name }) => {
  // const actualBtnRef = useRef(null);
  // const [fileName, setFileName] = useState("No file chosen");
  // useEffect(()=> {
  //   console.log(actualBtnRef.current);
  // },[])
  return (
    <>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          marginTop: "22px",
          width: "100%",
        }}
      >
        <div>
          <input
            // name={name}
            type="file"
            id="actual-btn"
            hidden
            accept="image/png,image/jpeg,image/svg,image/gif,image/jpg,image/tiff,image/svg+xml"
            onChange={onChange}
            // onChange={(e) => {
            //   upload(e.target.files[0]);
            //   onChange();
            // }}
          />
          <label htmlFor="actual-btn" className="uploadLabel">
            Choose File
          </label>
        </div>
        <div
          style={{
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        ></div>
      </div>
    </>
  );
};

export default FileUpload;
