import React, { useState } from "react";
import Data from "./Data.json";
import {
  Toaster,
  toast,
} from "../../../../../node_modules/react-hot-toast/dist/index";
import { SimpleHeader } from "../../../../Hooks/SimpleHeader";
import styles from "./RolesAndPermissions.module.css";
import { RolesList } from "./RolesList";
import { useSelector } from "react-redux";
import { usePermissions } from "../../../../Hooks/usePermissions";
import { findFunc } from "../../../../config/methods";
import { useEffect } from "react";
import { getRoles } from "../../../../services/admin/staff";
import { CircularProgress } from "../../../../../node_modules/@mui/material/index";
import { saveRolesAndPermissions } from "../../../../services/admin/rolesAndPermissions";
const RolesAndPermissions = () => {
  const samvednaPerms = usePermissions("name", "samvedna");
  const rolesAndPerms = findFunc(
    samvednaPerms?.subCategories,
    "listName",
    "settings"
  );

  console.log(rolesAndPerms, "samvednaPerms");

  const links = [
    {
      path: "/Samvedna",
      linkText: "Samvedna",
    },
  ];
  const [rolesData, setRolesData] = useState();
  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    setIsLoading(true);
    getRoles().then((res) => {
      setIsLoading(false);
      setRolesData(res?.data?.list);
    });
  }, []);
  const [selectedCategoriesId, setSelectedCategoriesId] = useState(1);
  const [selectedRoles, setSelectedRoles] = useState(1);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const { data } = useSelector((state) => state.updateRolesAndPerms);

  const saveRolesAndPerms = () => {
    setIsLoadingSave(true);
    saveRolesAndPermissions({
      id: selectedRoles,
      permission: JSON.stringify({ categories: data }),
    }).then((res) => {
      setIsLoadingSave(false);
      if (res?.data?.status) {
        toast.success(res?.data?.msg);
      } else {
        toast.error("Something went wrong. Please try again");
      }
    });
  };

  return (
    <>
      <Toaster />
      <SimpleHeader
        text="Roles and Permissions"
        btnText={"Save"}
        roles={true}
        links={links}
        basic
        isLoadingSave={isLoadingSave}
        saveRolesAndPerms={saveRolesAndPerms}
        noPaddingTop
        styles={styles}
        rolesAndPerms={rolesAndPerms}
        //select={selectStaffId}
      >
        {isLoading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress size={30} />
            <p style={{ color: "black" }}>
              Please wait ! We are validating the details...
            </p>
          </div>
        ) : (
          <div className={styles.staffListMain}>
            <RolesList
              setSelectedCategoriesId={setSelectedCategoriesId}
              selectedCategoriesId={selectedCategoriesId}
              rolesNames={rolesData}
              setSelectedRoles={setSelectedRoles}
              selectedRoles={selectedRoles}
              isLoadingSave={isLoadingSave}
              isLoading={isLoading}
              rolesAndPermsSet={rolesAndPerms}
            />
          </div>
        )}

        {/* <AllTabs ComponentName={ComponentName} btnApi={btnApi} /> */}
      </SimpleHeader>
    </>
  );
};
export default RolesAndPermissions;
