export const weekDays = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];


export const getDay = (date, fullDate) => {
    const d = new Date(fullDate);
    d.setDate(date);
    return weekDays[d.getDay()];
  };


  export const convertedMonthArr = (month, disabled, currentMonth, currentDate) => month?.map((date, i) => {
    return currentMonth ? {
      date,
      disabled : currentDate > i+1,
    } : {  
      date,
      disabled
    }
  })

  export const filterWeek = (data, selectWeek) => {
    switch (selectWeek) {
      case "1":
        return data.slice(0, 7);
      case "2":
        return data.slice(7, 14);
      case "3":
        return data.slice(14, 21);
      case "4":
        return data.slice(21, 28);
        case "5":
          return data.slice(28, 35);
      default:
        return data.slice(35);
    }
  };


  export const selectedMonthArr = (days) => Array.from(
    { length: days },
    (_, i) => i + 1
  );


 export const  getDropDownlist = (finalMonthArr) => {
    const selectDropDown = [
        {
          id: 1,
          value: "Week 1",
        },
        {
          id: 2,
          value: "Week 2",
        },
        {
          id: 3,
          value: "Week 3",
        },
        {
          id: 4,
          value: "Week 4",
        },
        {
          id: 5,
          value: "Week 5",
        }
      ];

      if(finalMonthArr?.length > 35) {
        selectDropDown.push({
          id: 6,
          value: "Week 6",
        })
      }
    
            return selectDropDown
 }



 export const availability = () => {
  const subscriptionForOptions = [
    {
      name: "Full Day",
      value: 0,
    },
    {
      name: "First Half",
      value: 1,
    },
    {
      name: "Second Half",
      value: 2,
    },
    {
      name: "Custom",
      value: 3,
    },
  ];
  return subscriptionForOptions
 }
  