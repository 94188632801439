import React from "react";
import { useState } from "react";

export const Readmore = ({ text }) => {
  const [more, setMore] = useState(false);
  return (
    <span
      onClick={() => setMore(!more)}
      style={{
        fontWeight: 300,
        fontSize: "14px",
        lineHeight: "20px",
        wordWrap: "break-word",
        color: more ? "#000" : "#7a51ae",
        cursor: more ? "auto" : "pointer",
      }}
    >
      {more ? text : "..Read more"}
    </span>
  );
};
