import React from "react";
import { Paper } from "@mui/material/index";
import { IconShadow } from "../../../Component/UI/IconShadow";
import { InfoIconLeft } from "../../../assets/icons/InfoIconLeft";
import { InfoIconRight } from "../../../assets/icons/InfoIconRight";
import { GoLinkExternal } from "react-icons/go";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";

export const AccountCard = ({ title, isLink, isLoading }) => {
  return (
    <>
      <Paper
        elevation={1}
        sx={{
          width: "250px",
          borderRadius: "15px",
          padding: "10px",
          margin: "10px",
          background: isLoading ? "#EBEBE4" : "",
        }}
      >
        <div
          style={
            isLink
              ? { display: "flex", justifyContent: "space-between" }
              : { textAlign: "right" }
          }
        >
          {isLink && <GoLinkExternal />}
          <InfoIconRight color="#3592FF" />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <div>
            <IconShadow size="50px" color={"#ccc"} />
          </div> */}
          <div style={{ fontWeight: "500", fontSize: "18px" }}>
            {isLoading ? <CircularProgress size={18} /> : title}
          </div>
        </div>
        <InfoIconLeft color="#3592FF" />
      </Paper>
    </>
  );
};
