import { combineReducers } from "redux";
import addAssesmentReducer from "../Reducers/AssessmentReducers/addAssessment";

import selfCareReducer from "../Slices/Assessment";
import assessmentDetailReducer from "../Slices/assessmentDetailsReducer";
import quickAskReducer from "../Slices/QuickAsk";
import assessmentDataReducer from "../Slices/AssessmentTableReducer";
import getPatients from "../Slices/getPatients";
import getClients from "../Slices/getClients";
import Events from "../Slices/Events/Events";
import clientDetails from "../Slices/clientDetails";
import qa_storage from "../Reducers/PatientReducers/qa_storage/qa_storage";
import addPatient from "../Slices/addPatient";
import PatientEvents from "../Slices/PatientEvents";
import serviceDetails from "../Slices/serviceDetails";
import getServices from "../Slices/getServices";
import getResults from "../Slices/getResults";
import resultDetails from "../Slices/resultDetails";
import assCat from "../Slices/CustomerAssessment/getAssessment";
import addQuestionSliceReducer from "../Slices/AdminAssessment/AddQuestion";
import clientSelfcarePlanSliceReducer from "../Slices/ClientSelfcarePlan";
import updateUserProfile from "../Slices/UserProfile/updateUserProfile";
import bookedAppointments from "../Slices/UserAppointments/bookedAppointments";
import enrolledEvents from "../Slices/UserEvents/enrolledEvents";
import assesmentQuestions from "../Slices/assesmentQuestions";
import assessmentId from "../Slices/assessmentId";
import typeAppointments from "../Slices/UserAppointments/typeAppointments";
import DashboardAssessment from "../Slices/DashboardAssessment";
import todayActivityReducer from "../Slices/TodayActivitySlice";
import quickAskNextBtnReducer from "../Slices/QuickAskNextBtnSlice";
import dashboardStats from "../Slices/AdminDashboard/dashboardStats";
import QuickAskData from "../Slices/QuickAsk/index";
import SearchFilters from "../Slices/AdminFilters/Search";
import MonthChangerSlice from "../Slices/WellnessTracker/monthChangerSlice";
import wellnessActivitySlice from "../Slices/WellnessTracker/ActivityList";
import DateFilterSlice from "../Slices/Patient/PatienCalender";
import PaymentSlices from "../Slices/Payments/PaymentSlices";
import patientSearch from "../Slices/patientSearch";
import ProgressTrendSlice from "../Slices/Patient/ProgressTrend";
import selectOptions from "../Slices/Services/selectOptions";
import EmailTemplateForm from "../Slices/AdminSamvedna/EmailTemplateForm";
import changeSequence from "../Slices/AdminAssessment/changeSequence";
import adminFeedback from "../Slices/adminFeedback";
import adminTestimonial from "../Slices/adminTestimonial";
import addStaff from "../Slices/addStaff";
import location from "../Slices/location";
import WeekFilterSlice from "../Slices/AdminRosterManegment/RosterManagement";
import rolesAndPermissions from "../Slices/RolesAndPermissions/rolesAndPermissions";
import updateRolesAndPerms from "../Slices/RolesAndPermissions/updateRolesAndPerms";

const rootReducer = combineReducers({
  selfCare: selfCareReducer,
  addAssesment: addAssesmentReducer,
  // assessment: assessmentSliceReducer,
  quickAsk: quickAskReducer,
  assessmentDetails: assessmentDetailReducer,
  assessmentData: assessmentDataReducer,
  getPatients,
  getClients,
  addPatient,
  Events,
  clientDetails,
  qa_storage,
  PatientEvents,
  getServices: getServices,
  serviceDetails: serviceDetails,
  getResults: getResults,
  resultDetails: resultDetails,
  assCat: assCat,
  updateUserProfile,
  bookedAppointments,
  enrolledEvents,
  assesmentQuestions,
  addQuestionSlice: addQuestionSliceReducer,
  clientSelfcarePlan: clientSelfcarePlanSliceReducer,
  assessmentId: assessmentId,
  typeAppointments: typeAppointments,
  DashboardAssessment,
  todayActivity: todayActivityReducer,
  quickAskNextBtn: quickAskNextBtnReducer,
  dashboardStats,
  QuickAskData,
  SearchFilters,
  MonthChangerSlice,
  wellnessActivitySlice,
  PaymentSlices,
  DateFilterSlice,
  patientSearch,
  ProgressTrendSlice,
  selectOptions,
  EmailTemplateForm,
  changeSequence,
  adminFeedback,
  adminTestimonial,
  addStaff,
  location,
  WeekFilterSlice,
  rolesAndPermissions,
  updateRolesAndPerms,
});
export default rootReducer;
