import { createSlice } from "@reduxjs/toolkit";

const MonthChangerSlice = createSlice({
  name: "MonthChangerSlice",
  initialState: {
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
    from_date: "",
    to_date: "",
    disable: false,
  },
  reducers: {
    setMonth: (state, { payload }) => {
      state.month = payload;
    },
    setYear: (state, { payload }) => {
      state.year = payload;
    },
    setFrom_date: (state, { payload }) => {
      state.from_date = payload;
    },
    setTo_date: (state, { payload }) => {
      state.to_date = payload;
    },
    setDisable: (state, { payload }) => {
      state.disable = payload;
    },
  },
});

export const { setMonth, setYear, setFrom_date, setTo_date, setDisable } =
  MonthChangerSlice.actions;

export default MonthChangerSlice.reducer;
