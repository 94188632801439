import React from "react";
import {
  getAllDaysInMonth,
  getDayDifference,
  getMonthWiseData,
  newDateFormat,
  StringDtoY,
} from "../../../config/formattedDate";
import styles from "./monthly.module.css";
import { CircularProgress } from "@mui/material";
import { ActivityCard } from "../../ActivityCard/ActivityCard";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Wellness_Tracker_Activity } from "../../../services/patient/wellnessTracker";
import { setMonthly } from "../../../Redux/Slices/WellnessTracker/ActivityList";
import { useState } from "react";
import moment from "../../../../node_modules/moment/moment";

const Monthly = () => {
  const { month, year } = useSelector((state) => state.MonthChangerSlice);
  const { Monthly } = useSelector((state) => state.wellnessActivitySlice);

  const [dateSelected, setDateSelected] = useState(new Date());
  const [percentage, setPercentage] = useState(0);

  const dispatch = useDispatch();

  let dateArr = getMonthWiseData(year, month, Monthly);
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const [activityData, setActivityData] = React.useState(null);
  const [toggle, setToggle] = React.useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (month === new Date().getMonth() && year === new Date().getFullYear()) {
      setDateSelected(new Date());
    } else {
      setDateSelected(new Date(year, month, 1));
    }
  }, [month, year]);

  useEffect(() => {
    getActivityDatabyDate();
    getMonthlyActivityData();
  }, [dateSelected, toggle, month]);

  useEffect(() => {
    calculatePercentage();
  }, [Monthly]);

  const getMonthlyActivityData = () => {
    Wellness_Tracker_Activity({ month, year, from: "", to: "" }).then((res) => {
      if (res.status) {
        dispatch(setMonthly(res?.list));
      } else {
        dispatch(setMonthly([]));
      }
    });
  };

  const getActivityDatabyDate = () => {
    Wellness_Tracker_Activity({
      month,
      year,
      from: dateSelected.getDate(),
      to: dateSelected.getDate(),
    })
      .then((res) => {
        if (res.status) {
          setActivityData(res.list?.[0]);
          setToggle(res?.list?.[0]?.answer?.answer);
        } else {
          setActivityData({});
          setToggle(0);
          // toast.error("No data available for this date");
        }
      })
      .finally(() => {
        setLoaded(true);
      });
  };

  const dateFormat = (gDate) => {
    return moment(new Date(gDate)).format("YYYY-MM-DD");
  };

  const calculatePercentage = () => {
    let submitttedActivity = Monthly.filter((item) => {
      return item?.answer?.answer === 1;
    });
    let FirstDay = Monthly?.find((item) => item.answer)?.date;
    let LastDay = getAllDaysInMonth(year, month).at(-1);
    let TotalDays = getDayDifference(FirstDay, LastDay) + 1;

    setPercentage(Math.trunc((submitttedActivity.length / TotalDays) * 100));
  };

  return (
    <>
      <div className={styles.Container}>
        <div className={styles.progressContainer}>
          <div className={styles.progressContainerWrapper}>
            <CircularProgress
              sx={{ color: "#50CD89" }}
              value={percentage || 0}
              variant={"determinate"}
              size={"200px"}
            />
            <div className={styles.progressTextContainer}>
              <p className={styles.progressValue}> {percentage || 0} % </p>
              <p>Completed</p>
            </div>
          </div>

          <p className={styles.progressTextQuestion}>
            {percentage > 50 ? "Hey, you're doing great!" : ""}
          </p>
        </div>
        <div className={styles.dateContainer}>
          {Array.from(".......").map((item, index) => {
            return <div>{days[index]}</div>;
          })}

          {dateArr.map((item, index) => {
            return (
              <div
                key={index}
                className={styles.rounded}
                onClick={() =>
                  item.status !== undefined && setDateSelected(item.date)
                }
                style={{
                  opacity: item.currentMonth ? "100%" : "40%",
                  backgroundColor:
                    item.status === 1
                      ? "lightgreen"
                      : item.status === 0
                      ? "#ff000050"
                      : "",
                  color:
                    item.status === 1
                      ? "green"
                      : item.status === 0
                      ? "#ff0000"
                      : "#00000070",
                  border:
                    dateFormat(item.date) === dateFormat(dateSelected)
                      ? "2px solid gray"
                      : "",
                }}
              >
                {item.date.getDate()}
              </div>
            );
          })}
        </div>
      </div>
      {activityData?.answer ? (
        <div style={{ padding: "15px" }}>
          <h6 className={styles.mainHeading}>
            <span>
              {StringDtoY(new Date(year, month, dateSelected.getDate())) ===
              StringDtoY(new Date())
                ? "Today's "
                : `${moment(
                    new Date(year, month, dateSelected.getDate())
                  ).format("DD MMM")}'s `}
              Activity
            </span>
          </h6>

          <ActivityCard
            question={activityData.answer.question.question}
            toggle={toggle}
            activityDate={activityData.date}
            activityAnswer={activityData.answer}
            disabled={
              StringDtoY(new Date(year, month, dateSelected.getDate())) !==
              StringDtoY(new Date())
            }
            getActivityData={getActivityDatabyDate}
            icon={activityData?.answer?.question?.question_image}
            loaded={loaded}
          />
        </div>
      ) : (
        <div style={{ padding: "15px" }}></div>
      )}
    </>
  );
};

export default Monthly;
