import React from "react";
import { CustomTable } from "../../../../Component/UI/Table";
import { NavLink, Link } from "react-router-dom";
import { TbEdit } from "react-icons/tb";
import SwitchButton from "../../../../Component/UI/SwitchButton/Switch";
import appointment from "../../../../MockData/appoinment.json";
import { Cross } from "../../../../assets/icons/Cross";

export const PatientUpcoming = () => {
  const tHead = [
    // "",
    "Date",
    "Session ID",
    "Service Name",
    "Time",
    "Mode",
    "Status",
    "Action",
  ];

  const tBodyData = appointment?.map(
    ({
      // assessment_image,
      date,
      Session_Id,
      Service_Name,
      Time,
      Mode,
      status,
      Action,
    }) => {
      return {
        date,
        Session_Id,
        Service_Name,
        Time,
        Mode,
        status: (
          <SwitchButton
          // status={status}
          // onClick={(value) => assessmentStatus(id, value)}
          />
        ),
        action: (
          //   <NavLink
          //     to="/AddAssessment"
          //     state={{ from: "edit", ass_id: id }}
          //     style={{ textDecoration: "none" }}
          //   >
          <>
            <TbEdit />
            <Cross />
          </>
        ),
      };
    }
  );
  return (
    <div>
      <CustomTable tHead={tHead} tBody={tBodyData} />
    </div>
  );
};
