import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const { GETCLIENTS, GETCLIENTDETAILS, CLIENTSTATUS, EDITCLIENT, ADDCLIENT } =
  API;

export const clientGet = async (searchKey, from, to) => {
  return await axios
    .get(GETCLIENTS + `?search_key=${searchKey}&fromdate=${from}&todate=${to}`)
    .then((res) => res.data.list)
    .catch((err) => console.log(err));
};

export const getClientDetails = async (id) => {
  return await axios
    .get(GETCLIENTDETAILS + id)
    .then((res) => {
      return res.data.list;
    })
    .catch((err) => console.log(err));
};

export const clientStatus = async (client_id, status) => {
  await axios
    .post(CLIENTSTATUS, {
      client_id,
      status,
    })
    .then((res) => console.log(res))
    .catch((err) => console.error(err));
};

export const editClient = async (data) => {
  return await axios
    .post(EDITCLIENT, data)
    .then((res) => res)
    .catch((err) => err);
};

export const addClient = async (data) => {
  return await axios
    .post(ADDCLIENT, data)
    .then((res) => res)
    .catch((err) => err);
};
