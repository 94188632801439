import { createSlice } from "@reduxjs/toolkit";
const addAssesmentSlice = createSlice({
  name: "instruction",
  initialState: {},
  reducers: {
    addAssesment: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
  },
});
export const { addAssesment } = addAssesmentSlice.actions;
export default addAssesmentSlice.reducer;