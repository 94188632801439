import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import AssessmentStyles from "../../assets/css/assessment.module.css";
import logo from "../../assets/icons/icon/logo.svg";
import Close from "../../assets/icons/icon/x.svg";
import { Buttons } from "../../Component/UI/Button/Button";

import {
  qaStore,
  setHandleNext,
  setAssessment_for,
  setCategoryId,
  setTimetaken,
  setNumberOfQues,
  setRetakeType,
} from "../../Redux/Reducers/PatientReducers/qa_storage/qa_storage";

export const Header = (props) => {
  const { show = false } = props;
  const signIn_btb = {
    marginRight: "20px",
    width: "92px",
    height: "48px",
    fontSize: "18px",
    lineHeight: "20px",
    backgroundColor: "#7A51AE",
    borderRadius: "8px",
    textDecoration: "none",
  };
  const close_btb = {
    marginRight: "20px",
    borderRadius: "8px",
    cursor: "pointer",
  };

  const dispatch = useDispatch();

  const handleClose = () => {
    // document.getElementById("assessmentType").click();
    props.setStart(false);
    dispatch(qaStore([]));
    dispatch(setHandleNext(true));
    dispatch(setAssessment_for("Myself"));
    dispatch(setCategoryId(""));
    dispatch(setTimetaken(""));
    dispatch(setNumberOfQues("number"));
    dispatch(setRetakeType("first"));
  };

  return (
    <header className={AssessmentStyles.header}>
      <Link to="/">
        <img className={AssessmentStyles.logo} src={logo} alt="logo" />
      </Link>
      {show ? (
        <Link to="/user/signin" style={{ textDecoration: "none" }}>
          <Buttons name="Sign in" style={signIn_btb} />
        </Link>
      ) : !props.enterprise ? (
        <img src={Close} alt="close" style={close_btb} onClick={handleClose} />
      ) : null}
    </header>
  );
};
