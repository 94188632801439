import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { TabPanel } from "./TabPanel";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const TabView = (props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderRadius: "20px",
          border: props.border || "1px solid #DADADA",
          float: props.float || "",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          textColor="#000"
          indicatorColor="secondary"
          sx={{
            "& button.Mui-selected": {
              color: " #E69A8D ",
            },
          }}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#E69A8D",
            },
          }}
        >
          {props.items?.map(({ label }, i) => (
            <Tab key={i} label={label} {...a11yProps(i)} />
          ))}
        </Tabs>
      </Box>
      {props.items?.map(({ component }, i) => {
        return (
          <TabPanel key={i} value={value} index={i}>
            {component}
          </TabPanel>
        );
      })}
    </Box>
  );
};
