export const Eye = ({ change, setChange }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      onClick={() => setChange(!change)}
    >
      <g>
        <path
          d="M9.41366 9.41319C9.23056 9.60969 9.00976 9.76729 8.76443 9.8766C8.5191 9.98591 8.25426 10.0447 7.98572 10.0494C7.71718 10.0542 7.45043 10.0048 7.2014 9.90418C6.95236 9.80359 6.72614 9.65387 6.53622 9.46396C6.34631 9.27404 6.19659 9.04782 6.096 8.79878C5.99541 8.54975 5.94601 8.283 5.95075 8.01446C5.95549 7.74592 6.01426 7.48108 6.12358 7.23575C6.23289 6.99042 6.39049 6.76962 6.58699 6.58652M11.9603 11.9599C10.8207 12.8285 9.43306 13.3098 8.00033 13.3332C3.33366 13.3332 0.666992 7.99985 0.666992 7.99985C1.49625 6.45445 2.64642 5.10426 4.04033 4.03985L11.9603 11.9599ZM6.60032 2.82652C7.05921 2.71911 7.52903 2.66541 8.00033 2.66652C12.667 2.66652 15.3337 7.99985 15.3337 7.99985C14.929 8.75693 14.4464 9.46968 13.8937 10.1265L6.60032 2.82652Z"
          stroke="#2A2A2A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.666992 0.666504L15.3337 15.3332"
          stroke={change ? "transparent" : "#2A2A2A"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath>
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
