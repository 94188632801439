import React, { useState } from "react";
import { useContext } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../Auth/AuthContext";
import classes from "../../assets/css/Enterprise/Login/enterpriselogin.module.css";
import LoginImage from "../../assets/icons/LoginImage.svg";
import { Input, InputWithIcon } from "../../Component/UI/CustomInputs";
import { Eye } from "../../assets/icons/Eye";
import logo from "../../assets/icons/icon/logo.svg";
import AssessmentStyles from "../../assets/css/assessment.module.css";
import { BsDot } from "react-icons/bs";
import { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import {
  enterpriseData,
  loginEnterprise,
} from "../../services/enterprise/prelogin";
import { useLayoutEffect } from "react";

export const LoginEnterprise = () => {
  const ctx = useContext(AuthContext);
  const { orgId } = useParams();
  const location = useLocation();

  const navigate = useNavigate();
  const pathname = window?.location?.pathname;
  const slugName = pathname?.split("/")[2];
  const [userDetail, setuserDetail] = useState({
    email: "",
    password: "",
    company_slug: slugName,
  });
  const [error, setError] = useState({
    email: "",
    password: "",
  });
  const [message, setMessage] = useState();
  const [orgData, setOrgData] = useState(null);
  useLayoutEffect(() => {
    enterpriseData(orgId).then((res) => {
      if (res.data.status) {
        setOrgData(res.data.lead);
      } else {
        toast.error("Please register first before login !");
        setTimeout(() => {
          navigate("/enterprise/mental-health-in-the-workplace");
        }, 3000);
      }
    });
  }, []);

  const handleChange = (event) => {
    setuserDetail({
      ...userDetail,
      [event.target.name]: event.target.value,
    });
  };

  let emailArr = orgData?.domain?.split() || "";

  const other_Domains = orgData?.other_domains
    ? orgData?.other_domains?.split(",")
    : "";

  let finalEmailArr = [...emailArr, ...other_Domains];
  const handleLogin = (e) => {
    e.preventDefault();
    let domain = userDetail.email?.split("@")[1];
    const validationStatus = finalEmailArr?.some((email) => {
      return email.trim() === domain;
    });

    if (validationStatus) {
      loginEnterprise(userDetail)
        .then((res) => {
          if (res.data.status) {
            ctx.auth({
              ...res.data.data,
              role: "enterprise!$23",
              is_loggedin: true,
            });
            sessionStorage.setItem("login_url", location.pathname);
            navigate("/dashboard");
          } else {
            setError(res?.data?.error);
            res.data?.msg && toast.error(res.data?.msg);
          }
        })
        .catch((err) => console.log(err));
    } else {
      toast.error("Please use your work email for login !");
    }
  };
  return (
    <>
      <Toaster />
      <div
        className={classes.login_container}
        style={{ background: `url(${LoginImage})` }}
      >
        <header className={AssessmentStyles.header}>
          <Link to="/">
            <img className={AssessmentStyles.logo} src={logo} alt="logo" />
          </Link>
        </header>
        <form
          className={classes.login_subcontainer}
          onSubmit={(e) => handleLogin(e)}
        >
          <div>
            <div className={classes.logo_div}>
              {orgData?.img_url ? (
                <img
                  className={classes.enterpriseLogo}
                  src={orgData.img_url}
                  alt={"org"}
                />
              ) : null}
            </div>
            <p className={classes.heading}>Sign in to your account</p>
            <div className={classes.input}>
              <Input
                type="text"
                label="Email Address"
                id="email"
                value={userDetail?.email}
                name="email"
                labelSize={14}
                onChange={(e) => handleChange(e)}
                placeholder="Email Address"
                style={{ outlineColor: "#7a51ae" }}
                autoComplete="off"
                error={error?.email}
              />
            </div>
            <div className={classes.input}>
              <InputWithIcon
                label="Password"
                type="password"
                error={error?.password}
                labelSize={14}
                placeholder="Enter Password"
                icon={Eye}
                onChange={(e) =>
                  setuserDetail({ ...userDetail, password: e.target.value })
                }
              />
            </div>

            <div>
              <p style={{ color: "red", float: "left", marginBottom: "25px" }}>
                {message !== "" ? message : null}
              </p>
            </div>
            <div className={classes.forgot_text}>
              <Link to={`/enterprise/${orgId}/forgot`}>
                <p>Forgot&nbsp;Password?</p>
              </Link>
            </div>
          </div>

          <div className={classes.submit}>
            <input
              type="submit"
              value="Sign in"
              // onClick={(e) => handleLogin(e)}
              style={{
                background: "#3592FF",
                cursor: "pointer",
                fontSize: "16px",
                height: "50px",
                borderRadius: "8px",
                border: "none",
              }}
            />
          </div>
          <div className={classes.enterprise_poweredby}>
            {/* <h5>Powered by</h5>
            <img src={logo} alt="logo" /> */}
            <p>In case of any help, Please write us at </p>
            <p style={{ fontWeight: "500" }}>info@samvednacare.com</p>
          </div>
        </form>
      </div>
      <div className={classes.footer_container}>
        <div>
          <p>©2014-2022 Samvedna Care Pvt. Ltd.</p>
        </div>
        <div style={{ display: "flex" }}>
          <Link
            to="/privacy"
            style={{ textDecoration: "none", color: "black" }}
          >
            <p>Privacy Policy</p>
          </Link>
          <BsDot />
          <Link to="/terms" style={{ textDecoration: "none", color: "black" }}>
            <p>Terms of Service</p>
          </Link>
        </div>
      </div>
    </>
  );
};
