import { createSlice } from "@reduxjs/toolkit";
// import axios from 'axios'
import { API } from "../../config/constant";
import { token } from "../../assets/constants";
import instance from "../../config/axiosConfig";

export const initialState = {
  loading: false,
  hasErrors: false,
  todayActivity: [],
  addgeneralData: [],
  GQWQ: [],
};

const todayActivitySlice = createSlice({
  name: "todayActivity",
  initialState,
  reducers: {
    getLoading: (state) => {
      state.loading = true;
    },
    getSuccess: (state, { payload }) => {
      state.todayActivity = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    postSuccess: (state, { payload }) => {
      state.addgeneralData = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getSuccessGQWQ: (state, { payload }) => {
      state.GQWQ = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const {
  getLoading,
  getSuccess,
  getFailure,
  postSuccess,
  getSuccessGQWQ,
} = todayActivitySlice.actions;

export const todayActivitySelector = (state) => state.todayActivity;

export default todayActivitySlice.reducer;

//GetApi
let isMounted = true;
const controller = new AbortController();
export function fetchQuickAsk(search_key, from_date, to_date) {
  return async (dispatch) => {
    dispatch(getLoading());
    try {
      const response = await instance.get(
        `${API.TODAYACTIVITYGETCATEGORY}?search_key=${search_key}&fromdate=${from_date}&todate=${to_date}`
      );
      const data = response;

      dispatch(getSuccess(data.data.list));
    } catch (error) {
      dispatch(getFailure());
      // isMounted = false;
      // controller.abort();
    }
  };
}

//GetApi-GeneralQuestion
export function fetchGeneralQuestion(value) {
  return async (dispatch) => {
    dispatch(getLoading());
    try {
      const response = instance.get(
        `${API.GETGENERALQUESTION}?category_id=${value}`
      );
      const data = response;

      dispatch(postSuccess(data.data.list));
    } catch (error) {
      dispatch(getFailure());
    }
  };
}

//GetApi-GeneralQuestionWITHQuestion
export function fetchGeneralQuestionWithQuestion(value) {
  return async (dispatch) => {
    dispatch(getLoading());
    try {
      const response = await instance.get(
        `${API.GETGENERALQUESTIONWITHQUES}?question_id=${value}`
      );
      const data = response;

      dispatch(getSuccessGQWQ(data.data));
    } catch (error) {
      dispatch(getFailure());
    }
  };
}
