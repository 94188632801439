import * as React from 'react';
import { styled } from '@mui/material/styles';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import { CardActionArea } from '@mui/material';
import img1 from '../assets/images/card.png';
import img6 from '../assets/images/edit.svg'

import { Buttons } from '../Component/UI/Button/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import appointments from '../assets/images/Appointments.svg';
import {BsFilter} from "react-icons/bs";
import { CustomizedAccordions } from '../Component/UI/Accordion/CustomizedAccordions';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const Faq=()=> {
  return (
    <div style={{marginLeft:'0px',position:'absolute',top:'20%',left:'20%',transform:'translate(-0%,-0%)'}}>
    <Grid item xs={12} style={{display:'flex',textAlign:"left"}}>
            <Grid  style={{marginLeft:'5px',fontSize:'15px',marginTop:'0px'}}>
            <select style={{padding:'10px 100px 10px 10px',borderRadius:'8px',fontSize:'16px',border:'1px solid #dadada'}}>
    <option value="" disabled="disabled" selected="selected" >Dementia</option>
    <option value="1">Mental Wellbeing</option>
   
</select>
            </Grid>

           </Grid>


           <Grid item xs={12} style={{display:'flex',textAlign:"left",marginTop:'30px',marginLeft:'5px',width:'1000px'}}>
           <CustomizedAccordions  heading="1. What is Dementia" content="Dementia is a syndrome, usually of a chronic or progressive nature that leads to deterioration in cognitive function (i.e. the ability to process thought)."/>

           </Grid>
          
    </div>
  );
}
