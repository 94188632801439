import { ReportsCard } from "../../UI/ReportsCard";
import classes from "./reports.module.css";
import { Btn } from "../../UI/Button";
import { useEffect } from "react";
import { useState } from "react";
import { selfAssessmentReport } from "../../../services/patient/reports";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";
import { EmptyReports } from "../../UI/EmptyReports";

export const SelfAssessments = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [enterPriseList, setEnterPriseList] = useState();
  useEffect(() => {
    getSelfAssessments();
  }, []);
  const getSelfAssessments = () => {
    setIsLoading(true);
    selfAssessmentReport().then((res) => {
      console.log(res, "res---");
      setIsLoading(false);
      setEnterPriseList(res);
    });
  };
  console.log(enterPriseList, "enterPriseList");
  return (
    <>
      {!isLoading ? (
        <div className={classes.session}>
          {enterPriseList?.myself.length > 0 ||
          enterPriseList?.someOneElse.length > 0 ? (
            <>
              {enterPriseList?.myself?.map((item) => (
                <ReportsCard
                  status={item?.status}
                  name={item?.assessment_category?.name}
                  date={item?.assessment_category?.date_of_creation}
                  pdfLink={item?.customer_pdf}
                  pdf_name={item.pdf_name}
                />
              ))}
              {enterPriseList?.someOneElse?.map((item) => (
                <ReportsCard
                  status={item?.status}
                  name={item?.assessment_category?.name}
                  date={item?.assessment_category?.date_of_creation}
                  pdfLink={item?.customer_pdf}
                  pdf_name={item.pdf_name}
                  customerName={item?.customer_data?.name}
                  relation={item?.customer_data?.relation}
                />
              ))}
            </>
          ) : (
            <EmptyReports />
          )}
        </div>
      ) : (
        <CircularProgress size={28} />
      )}
    </>
  );
};
