import { useEffect, useState } from 'react';
import { useNavigate, Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../Auth/AuthProvider';

const Protected = ({ role }) => {
  const ctx = useAuth();
  const isLoggedIn = ctx?.loginUser?.is_loggedin;

  return isLoggedIn ? <Outlet /> : <Navigate to='/' />;
};

export default Protected;
