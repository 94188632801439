import axios from "../../config/axiosConfig";

export const addServices = async (data) => {
  const ser = { ...data, state: "1", city: "1" };
  console.log(ser);
  return await axios
    .post(`/admin/add/service`, ser)
    .then((res) => res)
    .catch((err) => console.log(err));
};
export const servicesGet = async (index, search, from_date, to_date) => {
  return await axios
    .get(
      `/admin/get/service?search_key=${search}&from_date=${from_date}&to_date=${to_date}`
    )
    .then((res) => {
      const data = res.data.list;
      return index !== undefined ? data?.[index] : data;
    })
    .catch((err) => console.log(err));
};

export const getServiceDetails = async (id) => {
  return await axios
    .get(`/admin/detail/service?service_id=${id}`)
    .then((res) => {
      console.log(res);
      return res.data.list;
    })
    .catch((err) => console.log(err));
};

export const getServiceDetailed = async (id) => {
  return await axios
    .get(`/admin/detail/service/view?service_id=${id}`)
    .then((res) => {
      console.log(res);
      return res.data.list;
    })
    .catch((err) => console.log(err));
};

export const serviceStatus = async (service_id, status) => {
 return await axios
    .post("/admin/service/status/change", {
      service_id,
      status,
    })
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const editServices = async (data) => {
  return await axios
    .post(`/admin/update/service`, data)
    .then((res) => res)
    .catch((err) => err);
};

export const deleteServices = async (id) => {
  return await axios
    .post(`/admin/delete/service?service_id=${id}`)
    .then((res) => res)
    .catch((err) => err);
};

export const deleteServicesRecommendation = async (id) => {
  return await axios
    .post(`/admin/remove/assessment?service_id=${id}`)
    .then((res) => res)
    .catch((err) => err);
};

export const deleteServiceById = async (id) => {
  return await axios
    .post("/admin/delete/service", {
      service_id: id,
    })
    .then((res) => res)
    .catch((err) => err);
};
