import React from "react";
import { Toaster } from "../../../../node_modules/react-hot-toast/dist/index";
import DoctorsCard from "./DoctorsCard";
import DoctorsImg from "../../../assets/images/doctor.svg";
import Modal from "./DoctorPopup";
import { useState } from "react";
import styles from "./doctorPopup.module.css";

import { DoctorCard } from "./DoctorCard";
import { useEffect } from "react";
import { getDoctor } from "../../../services/patient/doctor";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";
import { Helmet } from "react-helmet";

function Doctors({ isLoading, doctorList, Counselors }) {
  const [popupAction, setPopupAction] = useState({ open: false });

  const openPopup = (data) => {
    setPopupAction({ ...popupAction, open: true, data });
  };
  return (
    <>
      <div>
        <Toaster />
        {/* <DoctorsCard openPopup={openPopup} data={data} /> */}
        {isLoading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress size={30} />
          </div>
        )}
        <div className={styles.doctorCard}>
          {doctorList?.length > 0 ? (
            doctorList?.map((item) => (
              <DoctorCard data={item} onClick={() => openPopup(item)} />
            ))
          ) : isLoading ? (
            ""
          ) : (
            <div className={styles.notFound}>{Counselors}</div>
          )}
        </div>
        {popupAction.open && (
          <Modal setPopupAction={setPopupAction} modalData={popupAction.data} />
        )}
      </div>
    </>
  );
}

export default Doctors;
