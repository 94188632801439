import React from "react";
import { Paper } from "../../../../../node_modules/@mui/material/index";
import { LevelScoring } from "./LevelScoring";
import { AllSelect, Select } from "../../../../Component/UI/CustomInputs";
import { useEffect } from "react";
import { getAssessmentDropDown } from "../../../../services/admin/wellnessTracker";
import { useState } from "react";
import { Link } from "react-router-dom";
export const OverView = ({
  styles,
  overViewData,
  handleChange,
  selectAssessment,
}) => {
  const [assessmentDropDown, setAssessmentDropDown] = useState();

  useEffect(() => {
    getAssessmentDropDown()
      .then((res) => setAssessmentDropDown(res.data.list))
      .catch((err) => console.log(err));
  }, []);

  const assessmentDropDownData = assessmentDropDown?.map(({ id, name }) => {
    return {
      id: id,
      value: name,
    };
  });

  const timeSec = overViewData?.average_time?.toString()?.split(".");

  return (
    <div className={styles.topBoxes}>
      <div className={styles.selectDrop}>
        <AllSelect
          value={selectAssessment}
          width="250px"
          // error={errors?.mode}
          options={assessmentDropDownData}
          onChange={handleChange}
        />
      </div>

      <div className={styles.overVieBoxes}>
        <div className={`${styles.overViewBox} ${styles.overViewSec}`}>
          <h3>Overview</h3>
          <div className={styles.mainBox}>
            <Link
              to="/Patients"
              style={{
                textDecoration: "none",
                color: "black",
              }}
              state={{ data: overViewData?.customer_Data_id, wellness: true }}
            >
              <div className={styles.ovBox}>
                <svg
                  width="68"
                  height="68"
                  viewBox="0 0 68 68"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle opacity="0.1" cx="34" cy="34" r="34" fill="#18BA4B" />
                  <path
                    d="M40 22H43C43.7956 22 44.5587 22.3161 45.1213 22.8787C45.6839 23.4413 46 24.2044 46 25V46C46 46.7956 45.6839 47.5587 45.1213 48.1213C44.5587 48.6839 43.7956 49 43 49H25C24.2044 49 23.4413 48.6839 22.8787 48.1213C22.3161 47.5587 22 46.7956 22 46V25C22 24.2044 22.3161 23.4413 22.8787 22.8787C23.4413 22.3161 24.2044 22 25 22H28"
                    stroke="#18BA4B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M38.5 19H29.5C28.6716 19 28 19.6716 28 20.5V23.5C28 24.3284 28.6716 25 29.5 25H38.5C39.3284 25 40 24.3284 40 23.5V20.5C40 19.6716 39.3284 19 38.5 19Z"
                    stroke="#18BA4B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M30 32.6706C30.3132 31.7802 30.9315 31.0293 31.7453 30.5511C32.5591 30.0728 33.5159 29.898 34.4463 30.0575C35.3766 30.2171 36.2204 30.7008 36.8283 31.4229C37.4362 32.1451 37.7689 33.059 37.7675 34.003C37.7675 36.6677 33.7705 38 33.7705 38"
                    stroke="#18BA4B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M33.8203 42.0098H33.8403"
                    stroke="#18BA4B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div className={styles.boxName}>
                  <h4>Completed</h4>
                  <h2>{overViewData?.completed_count}</h2>
                </div>
              </div>
            </Link>
            <div className={styles.ovBox}>
              <svg
                width="68"
                height="68"
                viewBox="0 0 68 68"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle opacity="0.1" cx="34" cy="34" r="34" fill="#3592FF" />
                <path
                  d="M40 22H43C43.7956 22 44.5587 22.3161 45.1213 22.8787C45.6839 23.4413 46 24.2044 46 25V46C46 46.7956 45.6839 47.5587 45.1213 48.1213C44.5587 48.6839 43.7956 49 43 49H25C24.2044 49 23.4413 48.6839 22.8787 48.1213C22.3161 47.5587 22 46.7956 22 46V25C22 24.2044 22.3161 23.4413 22.8787 22.8787C23.4413 22.3161 24.2044 22 25 22H28"
                  stroke="#3592FF"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M38.5 19H29.5C28.6716 19 28 19.6716 28 20.5V23.5C28 24.3284 28.6716 25 29.5 25H38.5C39.3284 25 40 24.3284 40 23.5V20.5C40 19.6716 39.3284 19 38.5 19Z"
                  stroke="#3592FF"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <g clip-path="url(#clip0_3729_6606)">
                  <path
                    d="M34 42.6666C37.6819 42.6666 40.6667 39.6819 40.6667 36C40.6667 32.3181 37.6819 29.3333 34 29.3333C30.3181 29.3333 27.3334 32.3181 27.3334 36C27.3334 39.6819 30.3181 42.6666 34 42.6666Z"
                    stroke="#3592FF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M34 32V36L36.6667 37.3333"
                    stroke="#3592FF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3729_6606">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(26 28)"
                    />
                  </clipPath>
                </defs>
              </svg>

              <div className={styles.boxName}>
                <h4>Avg. Time/Person</h4>
                <h2>{`${timeSec?.[0] ? timeSec?.[0] : 0} Min ${
                  timeSec?.[1] ? timeSec?.[1] : 0
                } Sec`}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.overViewBox}>
          <h3>Results</h3>
          <div className={styles.papercontainer}>
            <Paper
              elevation={1}
              sx={{
                width: "100%",
                borderRadius: "8px",
                height: "100px",
                border: "1px solid #EEEFF4",
                boxShadow:
                  "0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08)",
              }}
            >
              <div className={styles.innerResultOverview}>
                <LevelScoring
                  color={"#50CD89"}
                  percentage={overViewData?.level?.level1}
                  level={"1"}
                  width={"95px"}
                />
                <LevelScoring
                  color={"#E7B400"}
                  percentage={overViewData?.level?.level2}
                  level={"2"}
                  width={"95px"}
                />
                <LevelScoring
                  color={"#ff5a5a"}
                  percentage={overViewData?.level?.level3}
                  level={"3"}
                  width={"95px"}
                />
              </div>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
};
