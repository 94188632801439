import React from "react";
import PaperWrapper from "../../Component/UI/PaperWrapper/PaperWrapper";
import Grid from "@mui/material/Grid";
import { ActivityCard } from "../ActivityCard/ActivityCard";
import { SelfCareCards } from "../../Component/UI/SelfCareCards/SelfCareCards";
import { MoodCard } from "../MoodCard/MoodCard";
import { useEffect } from "react";
import { API } from "../../config/constant";
import instance from "../../config/axiosConfig";
import { Link } from "react-router-dom";
import classes from "./ClientSelfCarePlan.module.css";
import { Dates } from "../UI/Dates";
import { TabView } from "../UI/TabView";
import Weekly from "./wellnessTracker/Weekly";
import Daily from "./wellnessTracker/Daily";
import Monthly from "./wellnessTracker/Monthly";
import { Toaster } from "../../../node_modules/react-hot-toast/dist/index";
import MonthChanger from "../UI/wellnessTracker/MonthChanger";
import { useDispatch } from "react-redux";
import {
  setMonth,
  setYear,
} from "../../Redux/Slices/WellnessTracker/monthChangerSlice";
import { Helmet } from "react-helmet";

const ClientSelfcarePlan = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setMonth(new Date().getMonth()));
      dispatch(setYear(new Date().getFullYear()));
    };
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Self Care Plan - Samvedna Map</title>
        <link
          rel="canonical"
          href="https://map.samvednacare.com/clientselfcareplan"
        />
        <meta name="title" content="Self Care Plan - Samvedna Map" />
        <meta
          name="description"
          content="A self-care plan comprises of wellness activities that promote overall well-being on different dimensions. We encourage you to start your journey."
        />
        <meta name="robots" content="index,follow" />
      </Helmet>
      <Toaster />
      <div className={classes.selfcare_plan}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} sm={12} lg={7} sx={{ height: "100%" }}>
            <PaperWrapper flag="100%" style={{ borderRadius: "10px" }}>
              <div className={classes.monthContainer}>
                <h3
                  style={{
                    margin: "30px 0",
                    fontWeight: 500,
                    fontSize: "24px",
                    padding: "0 15px",
                  }}
                >
                  Wellness Tracker
                </h3>
                <MonthChanger />
              </div>
              <div style={{ padding: "0 15px" }}>
                <TabView
                  values={[
                    { label: "Daily", component: <Daily /> },
                    { label: "Weekly", component: <Weekly /> },
                    { label: "Monthly", component: <Monthly /> },
                  ]}
                  calendar
                />
              </div>
            </PaperWrapper>
          </Grid>
          <Grid item xs={12} md={12} sm={12} lg={5}>
            <SelfCareCards />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ClientSelfcarePlan;
