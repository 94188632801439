import React from "react";
import { Grid } from "../../../../node_modules/@mui/material/index";
import { useNavigate } from "../../../../node_modules/react-router-dom/index";
import { Info } from "../../UI/Info";
import styles from "./samvedna.module.css";
import { usePermissions } from "../../../Hooks/usePermissions";
import { findFunc } from "../../../config/methods";

const Samvedna = () => {
  const samvednaPerms = usePermissions("name", "samvedna");
  // const staffMgt = findFunc(
  //   samvednaPerms?.subCategories,
  //   "listName",
  //   "Staff Management"
  // );
  const CardsData = [
    {
      id: 1,
      name: "Staff Management",
      link: "/Samvedna/staff",
    },
    {
      id: 2,
      name: "Content Management",
      link: "announcement",
    },
    {
      id: 3,
      name: "Settings",
      link: "/Samvedna/roles-and-permissions",
    },
    {
      id: 4,
      name: "Notification Management",
      link: "notificationmanagement",
    },
    {
      id: 5,
      name: "User Feedback",
      link: "feedback",
    },
    {
      id: 6,
      name: "Testimonials",
      link: "testimonials",
    },
    {
      id: 7,
      name: "Roster Management",
      link: "roster",
    },
  ];
  const navigate = useNavigate();

  const CardHandler = (linkText) => {
    linkText !== "disabled" && navigate(linkText);
  };

  return (
    <>
      <h1>Samvedna</h1>
      <div className={styles.cards}>
        {CardsData.map((card) => {
          const checkText = findFunc(
            samvednaPerms?.subCategories,
            "listName",
            card?.name
          );
          const writePermissions = +checkText?.access?.Read;
          return writePermissions ? (
            <Grid
              item
              xs={3}
              style={{
                marginBottom: "10px",
              }}
              key={card.id}
              columns={{ xs: 1, sm: 1, md: 12 }}
            >
              <Info
                key={card.id}
                text={card.name}
                link={card.link}
                onClick={() => CardHandler(card.link)}
              />
            </Grid>
          ) : null;
        })}
      </div>
    </>
  );
};

export default Samvedna;
