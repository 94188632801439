const patient = "/patient";
const admin = "/admin";

const enterprise = "/enterprise";

export const CLIENT_ROUTES = {
  MAIN: "/",
  TESTIMONIALS: "/patient/testimonial",
  FAQ: "/faq",
  FEEDBACK: "/patient/feedback",
  CONNECT_US: "/patient/contactus",
  ASSESSMENT_RESULTS: patient + "/assessment/results",
  ASSESSMENT: patient + "/assessment",
  CLIENT_SELF_CARE_PLAN: "/clientselfcareplan",
  PATIENT_DASHBOARD: "/dashboard",
  MY_ACCOUNT: patient + "/myaccount",
  // RANDOM: "/random",

  EVENTS: patient + "/events",
  REPORTS: patient + "/reports",
  ASSESSMENTS: patient + "/Assessments",
  APPOINTMENTS: patient + "/Appointments",
  APPOINTMENTS_DETAILS: patient + "/Appointments/details",
  SUBSCRIPTIONS: patient + "/Subscriptions",
  SUBSCRIPTIONS_DETAILS: patient + "/subscriptions/details",
  REPORTS_SUMMARY: patient + "/reports/summary",
  SERVICES: patient + "/services",
  DOCTORS_PANEL: patient + "/Doctors",
  HELP_SUPPORT: patient + "/help-support",
  PROGRESS_TRENDS: patient + "/progresstrends",
  CONSENT: patient + "/consent",
  PATIENT_SOCIAL: patient + "/social-media",
  JOINSUPPORTGROUP: patient + "/joinCommunity",

  CONSENT_TWO: patient + "/consentTwo&:uniqId",
  // USER_EDIT: "/Appointments/user/edit/:id",
};

export const ADMIN_ROUTES = {
  MAIN: "/",
  DASHBOARD: "/dashboard",
  OVERVIEW: "/overview",
  PATIENTS: "/Patients",
  SELF_ASSESSMENT: "/Patients/selfassessment",
  SELF_ASSESSMENT_QUESTION: "/Patients/selfassessment/questions",
  SELF_CARE_PLAN_ADMIN: "/Patients/selfcareplan",
  PROGRESS_TRENDS_ADMIN: "/Patients/progresstrends",
  DEMENTIA_SCREENING: "/dementiascreening",
  PATIENT_PROFILE: "/patientsProfile",
  PATIENT_USER_PROFILE: "patients/patientProfile",
  ENTERPRISES: "/Enterprises",
  ENTERPRISES_VIEWBOX: "/Enterprises/view",
  ENTERPRISES_TESTIMONIALS: "/Enterprises/Testimonials",
  ENTERPRISES_TESTIMONIALS_ADD: "/Enterprises/Testimonials/Add",

  ENTERPRISE_GENERAL_PROFILE_ADD: "/Enterprises/addprofile",
  ENTERPRISE_GENERAL_PROFILE_EDIT: "/Enterprises/editprofile",
  ENTERPRISES_ASSESSMENT_LIST: "/Enterprises/assessmentList",
  ENTERPRISE_SERVICE_LIST: "/Enterprise/serviceList",
  ENTERPRISE_REPORT_LIST: "/Enterprise/reportList",
  ENTERPRISE_SUBSCRIPTION_LIST: "/Enterprise/subscriptionList",
  ENTERPRISE_SUBSCRIPTION_DETAILS:
    "/Enterprise/Subscriptions/SubscriptionDetail",
  ENTERPRISE_EXCEL_REPORT_LIST: "/Enterprise/excelreports",
  SAMVEDNA: "/samvedna",
  WELLNESS_TRACKER: "/wellnessTracker",
  ASSESSMENTS: "/Assessments",
  GENERAL_QUICK_ASK: "/generalQuickAsks",
  GENERAL_ADD_QUESTION: "/generalAddQuestion",
  ADD_ASSESSMENT: "/AddAssessment",
  EDIT_ASSESSMENT: "EditAssessment",
  ASSESSMENT_QUESTION_EDIT: "smv-admin/assessment/questions/edit",
  ADD_SELF_CARE_TIPS: "/addselfcaretips",
  EDIT_SELF_CARE_TIPS: "/editselfcaretips",
  EVENTS: "/events",
  ADD_EVENTS: "/addevents",
  EDIT_EVENTS: "/editevents",
  EDIT_DETAILS: "/eventdetails",
  ADD_ASSESSMENT_QUESTION: "/smv-admin/assessment/questions/edit",
  ADD_SERVICE: "/addService",
  EDIT_SERVICE: "/editService",
  ADD_APPOINTMENTS: "/add_appointments",
  APPOINTMENTS: "/Appointments",
  SUBSCRIPTIONS: "/Subscriptions",
  MY_ACCOUNT: "/myaccount",
  ADD_SUBSCRIPTIONS: "/Subscriptions/add",
  EDIT_SUBSCRIPTIONS: "/Subscriptions/edit",
  DETAILS_SUBSCRIPTIONS: "/Subscriptions/SubscriptionDetail",

  SERVICES: "/Services",
  SERVICE_DETAILS: "/Services/ServiceDetail",
  APPOINTMENTS_DETAILS: "/Appointments/user/appointments",
  APPOINTMENT_USERDATA: "/appointmentUserData",
  Payment_Page: "/payments",
  EDIT_EMAIL_TEMPLATE: "/samvedna/notificationmanagement/email/edit",

  //need to identify these routes
  NOTIFICATION_MANAGEMENT: "/NotficationManagement",
  NOTIFICATION_EMAIL: "/notificationEmail",
  ADD_EMAIL_TEMPLATE: "/addEmailTemplate",
  ADD_NOTIFICATION: "/addNotification",
  NOTIFICATION: "/notification",
  NOTIFICATION_DETAILS: "/notificationDetails",
  NOTIFICATION_SMS: "/notificationSms",
  ADD_TEMPLATE: "/addSmsTemplate",
  PATIENT_ADD: "/patients/add",
  PATIENT_EDIT: "/patients/edit",
  SERVICES_STATUS: "/ServicesStatus",
  TESTIMONIALS: "/Samvedna/testimonials",
  FEEDBACK: "/Samvedna/feedback",
  ABOUT: "/about",
  EXPERT: "/experts",
  SETTINGS: "/settings",
  CONTACT_US: "/contactus",
  SAMVEDNA_SERVICE: "/samvednaservices",
  PASSWORD: "/password",
  PATIENT_APPOINTMENTS: "/patientappointments",
  ASSESSMENTS_SUMMARY: "/assessmentsummary",
  EXPERT_CARE_PLAN: "/expertcareplan",
  SESSION_REPORT: "/sessionreport",
  ASSESSMENT_REPORTS: admin + "/AssessmentReports",
  ASSESSMENT_USER_EDIT: "/Appointments/user/edit/:id",
  TODAY_ACTIVITY_QUESTION: "/todayactivityquestion",
  ACTIVITY_ADD_QUESTION: "/todayactivityaddquestion",
  ADD_CATEGORY_QUICKASK: "/addcategoryquickask",
  ADD_CATEGORY_TODAY_ASK: "/addcategorytodayactivity",
  PATIENT_TESTIMONIALS: "/patientsProfile/testimonials",
  PATIENT_FEEDBACK: "/patientsProfile/feedback",
  FEEDBACK_DETAILS: "/Samvedna/feedback/details",
  TESTIMONIAL_DETAILS: "/Samvedna/testimonials/details",
  PATIENT_TESTIMONIAL_DETAILS: "/patientsProfile/testimonials/details",
  PATIENT_FEEDBACK_DETAILS: "/patientsProfile/feedback/details",
  STAFF_MANAGEMENT: "/Samvedna/staff",
  PATIENT_SUBSCRIPTIONS: "/patientsProfile/subscriptions",
  PATIENT_SUBSCRIPTION_VIEW: "/patientsProfile/subscriptions/details",
  PATIENT_SERVICES: "/patientsProfile/services",

  PATIENT_CONSENT: "/patientsProfile/consent",
  ADMIN_REPORTS: "/patientsProfile/adminReports",
  ADD_REPORTS: "/patientsProfile/adminReports/addReport",
};

export const ENTERPRISE_ROUTES = {
  LANGING_PAGE: `${enterprise}/landing`,
  DASHBOARD: "/dashboard",
  ASSESSMENTS: `${enterprise}/assessments`,
  ASSESSMENTDETAILS: `${enterprise}/assessments/details`,
  SERVICES: `${enterprise}/services`,
  ACCOUNT: `${enterprise}/account`,
  ACCOUNTGENERAL: `${enterprise}/account/general`,
  ACCOUNTPASSWORD: `${enterprise}/account/password`,
  TESTIMONIALS: `${enterprise}/account/testimonial`,
  REPORTS: `${enterprise}/reports`,
  HELP: `${enterprise}/help-support`,
  SUBSCRIPTIONS: `${enterprise}/subscriptions`,
  SUBSCRIPTIONS_DETAILS: `${enterprise}/subscriptions/details`,
};
