export const Badges = ({ text, padding, margin }) => {
  return (
    <div
      style={{
        background: "#D8F2EC",
        color: "#00B091",
        borderRadius: "20px",
        width: "max-content",
        padding: padding || "3px 18px",
        margin: margin || "8px 8px 0 0",
        fontWeight: "500",
      }}
    >
      {text}
    </div>
  );
};
