import React, { useEffect, useState } from "react";

import Tables from "../../../Hooks/Table";
import EnterpricesData from "../../../MockData/Enterprise.json";
import ButtonName from "../../../MockData/ButtonName.json";
import RejectedData from "../../../MockData/EntRejected.json";
import CustomizedTabs from "../../../Component/UI/Tabs/CustomizedTabs";
import BasicBreadcrumbs from "../../../Component/UI/BreadCrumb/BreadCrumbs";

import { Rejected } from "./Rejected";

import Text_Seach_Filter_Export from "../../../Hooks/Text_Seach_Filter_Export";
import AllTabs from "../../../Component/UI/Tabs/AllTabs";
import { ADMIN_ROUTES } from "../../../Routes/routesConstants";
import { toast } from "react-hot-toast";
import EnterpriseReports from "./EnterpriseReports";
import { Toaster } from "../../../../node_modules/react-hot-toast/dist/index";
import { useLocation } from "react-router-dom";
import SubscriptionReports from "./SubscriptionReports";
import { usePermissions } from "../../../Hooks/usePermissions";

export default function EnterpriseReportsTab() {
  const btnApi = ButtonName.EnterpriseReports;
  let defaultTab = Object.keys(btnApi[0]);
  const [tabID, selectedTabID] = useState(defaultTab[0]);
  const [enterpriseData, setEnterpriseData] = useState();
  const [rejectedData, setRejectedData] = useState();
  const [newLeadsData, setNewLeadsData] = useState();
  const [exportData, setExportData] = useState([]);
  const { state } = useLocation();
  const permissions = usePermissions("name", "enterprises");

  const links = [
    {
      path: ADMIN_ROUTES.ENTERPRISES,
      linkText: "Enterprise",
    },
  ];
  if (state?.data) {
    links.push({
      path: ADMIN_ROUTES.ENTERPRISES_VIEWBOX,
      linkText: state?.data?.company_name,
      data: { ...state?.data, permissions },
    });
  }

  //   btnApi[0].Leads = newLeadsData
  //     ? "New Leads (" + newLeadsData.length + ")"
  //     : btnApi[0].Leads;
  const ComponentName = [<EnterpriseReports />, <SubscriptionReports />];

  const obj = {
    Upcoming: enterpriseData,
    onGoing: rejectedData,
    Cancelled: newLeadsData,
  };

  useEffect(() => {
    return () => toast.remove();
  }, []);

  return (
    <>
      <Toaster />
      <BasicBreadcrumbs link={links} text="Reports" isEnableCalender={true}>
        <AllTabs
          ComponentName={ComponentName}
          btnApi={btnApi}
          selectedTabID={selectedTabID}
        />
      </BasicBreadcrumbs>
    </>
  );
}
