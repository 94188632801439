import React from "react";
import { ServiceIcon } from "../../Components/Images/ServiceIcon";
import styles from "./assessmentcard.module.css";

export const AssessmentCard = ({
  name,
  assessment_image,
  short_description,
}) => {
  return (
    <div className={styles.assessment_card}>
      <div className={styles.icon}>
        {/* <ServiceIcon styles={styles} /> */}
        <img src={assessment_image} alt="" width={90} height={90} />
      </div>
      <div className={styles.content}>
        <p className={styles.title}>{name}</p>
        {/* <p className={styles.amount}>₹2500</p> */}
        <p className={styles.description}>{short_description}</p>
      </div>
    </div>
  );
};
