import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  CircularProgress,
  Pagination,
} from "../../../node_modules/@mui/material/index";
import { useState } from "react";
import { useEffect } from "react";

export const CustomTable = (props) => {
  const FinalTableData = props?.tBody?.filter(
    (value) => Object.keys(value).length !== 0
  );

  const dataCount = 10;
  const [pageCount, setPageCount] = useState(1);

  const handlePageChange = (_, page) => {
    setPageCount(page);
  };

  const pageData = FinalTableData
    ? FinalTableData.slice((pageCount - 1) * dataCount, dataCount * pageCount)
    : [];

  function capitalizeFirstLetter(string) {
    string = string.split("_").join(" ");
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <>
      <TableContainer
        component={!props.wrapper ? Paper : null}
        style={{ height: props.tableHeight }}
      >
        {/* <TableContainer> */}
        {props.isLoadingData ? (
          <div style={{ textAlign: "center", margin: "40px" }}>
            <CircularProgress size={30} />
          </div>
        ) : (
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                {props.tHead?.map((head, index) => (
                  <TableCell
                    key={index}
                    sx={{ fontWeight: 400, fontSize: 12, padding: "10px" }}
                  >
                    {capitalizeFirstLetter(head)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {pageData?.map((rows, index) => {
              const a = Object.values(rows);
              return (
                <TableBody key={index}>
                  <TableRow>
                    {a?.map((d, i) => (
                      <TableCell
                        key={i}
                        sx={{
                          whiteSpace: "nowrap",
                          fontSize: 13,
                          paddingTop: 1,
                          paddingBottom: 1,
                          padding: "10px",
                          color:
                            (d === "Completed" && "#00dc00") ||
                            (d === "Failed" && "red") ||
                            (d === "Pending" && "red") ||
                            (d === "Approved" && "#33ff33") ||
                            (d === "Cancelled" && "red") ||
                            "black",

                          // textAlign: props.tHead[index] === "Number of questions" ? "center" : "",
                        }}
                      >
                        {d}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              );
            })}
          </Table>
        )}

        {pageData?.length === 0 && (
          <div
            style={{
              padding: "16px",
              textAlign: "center",
              display: props.message ? "block" : "none",
            }}
          >
            {props.message}
          </div>
        )}
      </TableContainer>
      <div
        style={{
          marginTop: "20px",
          marginBottom: "20px",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        {FinalTableData?.length > 10 && (
          <Pagination
            color="primary"
            variant="outlined"
            count={Math.ceil(FinalTableData && FinalTableData?.length / 10)}
            // page={page}
            onChange={handlePageChange}
          />
        )}
      </div>
    </>
  );
};
