import * as React from "react";
import { styled } from "@mui/material/styles";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";

import { Buttons } from "../Button/Button";

import sudoku from "../../../assets/images/sudoku.svg";
import sleep from "../../../assets/images/sleep.svg";
import health from "../../../assets/images/health.svg";
import iconpark from "../../../assets/images/icon-park.svg";
import { BsFilter } from "react-icons/bs";

import { fetchClientSelfcarePlan } from "../../../Redux/Slices/ClientSelfcarePlan";
import instance from "../../../config/axiosConfig";
import { API } from "../../../config/constant";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const SelfCareCards = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cardData, setCardData] = React.useState([]);
  const [visible, setVisible] = React.useState(4);
  const { clientSelfcarePlan } = useSelector(
    (state) => state.clientSelfcarePlan
  );

  const allCarData = () => {
    // dispatch(fetchClientSelfcarePlan());
    try {
      const response = instance.get(API.CLIENTSELFCAREPLAN).then((res) => {
        res.data.status === true && setCardData(res.data.list);
        // setFlag(true);
      });
    } catch (error) {
      console.log(error, "error");
    }
  };

  const loadNext = () => {
    setVisible(visible + cardData?.length);
  };

  const loadBack = () => {
    setVisible(4);
  };

  React.useEffect(() => {
    allCarData();
  }, []);

  // const Clickhandler = () => {
  //   navigate("/patient/Assessment");
  // };

  const NullComp = () => {
    return (
      <h3 style={{ margin: "10%" }}>
        You haven't taken any Assessment,{" "}
        <Link to="/patient/Assessment">Click Here</Link> to take Assessment
      </h3>
    );
  };

  const renderCard = (data, index) => {
    return (
      <Grid container xs={12} key={index}>
        <Grid item xs={12}>
          <Card style={{ borderRadius: "10px", margin: "10px" }}>
            <CardActionArea>
              <CardContent>
                <Grid container spacing={1} style={{ marginTop: "5px" }}>
                  <Grid item xs={2}>
                    <img
                      style={{ height: "30px", width: "30px" }}
                      src={data.icon || iconpark}
                      alt="SelfCare"
                    />
                  </Grid>

                  <Grid
                    item
                    xs={8}
                    style={{ display: "flex", textAlign: "left" }}
                  >
                    <Grid
                      style={{
                        marginLeft: "5px",
                        fontSize: "15px",
                        marginTop: "3px",
                      }}
                    >
                      <h4>{data.title}</h4>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1} style={{ marginTop: "5px" }}>
                  <Grid item xs={2}></Grid>
                  <Grid
                    item
                    xs={8}
                    style={{ display: "flex", textAlign: "left" }}
                  >
                    <Grid
                      style={{
                        marginLeft: "5px",
                        fontSize: "15px",
                        marginTop: "3px",
                        paddingBottom: "10px",
                      }}
                    >
                      <p style={{ lineHeight: "20px" }}>{data.description}</p>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box sx={{ margin: "9px" }}>
      <Card style={{ padding: "20px", borderRadius: "10px" }}>
        <Grid container spacing={1} style={{ marginTop: "5px" }}>
          <Grid item xs={6}>
            <h3
              style={{
                textAlign: "left",
                fontSize: "20px",
                marginBottom: "20px",
              }}
            >
              Self Care Tips
            </h3>
          </Grid>

          {5 > cardData?.length ? (
            <></>
          ) : visible < cardData?.length ? (
            <Grid
              item
              xs={6}
              style={{
                textAlign: "right",
                fontSize: "18px",
                color: "#85219C",
                cursor: "pointer",
              }}
              onClick={loadNext}
            >
              {cardData?.length > 0 ? "View All" : ""}
            </Grid>
          ) : (
            <Grid
              item
              xs={6}
              style={{
                textAlign: "right",
                fontSize: "18px",
                color: "#85219C",
                cursor: "pointer",
              }}
              onClick={loadBack}
            >
              {cardData?.length > 0 ? "View less" : ""}
            </Grid>
          )}
        </Grid>

        {cardData?.length > 0 ? (
          cardData?.slice(0, visible).map(renderCard)
        ) : (
          <NullComp />
        )}
      </Card>
    </Box>
  );
};
