import React from "react";
import ReactApexChart from "react-apexcharts";
import styles from "../../../../assets/css/PatientDashboard/progresstrend.module.css";

export const Communication = () => {
  const series = [
    {
      type: "bar", //render a line chart for this data
      name: "Guests",
      //   data: [25, 20, 25, 10, 25, 20],
      data: [],
    },
  ];
  const options = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    animations: {
      enabled: false, //no animations
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
      colors: "#EFAD03",
    },
    title: {
      text: "Patient's Level",
      align: "left",
      style: {
        fontSize: "24px",
        fontWeight: 500,
        fontFamily: "Public Sans",
        marginTop: "10px",
      },
    },
    grid: {
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    },
    tooltip: {
      enabled: false,
    },

    yaxis: {
      show: true,

      labels: {
        formatter: function (value) {
          return value;
          // return value <= 8
          //   ? "😭"
          //   : value <= 16
          //   ? "😡"
          //   : value <= 24
          //   ? "😞"
          //   : value <= 32
          //   ? "☺"
          //   : "😃";
        },
      },
      axisBorder: {
        show: true,
        color: "#F0F0F0",
        offsetX: 0,
        offsetY: 0,
      },
      min: 8,
      max: 40,
      tickAmount: 4,
    },
    noData: {
      text: "No data is available to plot the graph",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
  };

  return (
    <div className={styles.mood_div_patient}>
      <ReactApexChart
        className={styles.chart_div_patient}
        options={options}
        type="line"
        series={series}
        height={300}
        width="100%"
      />
    </div>
  );
};
