import React from "react";
import { useSelector } from "react-redux";
import { findFunc } from "../config/methods";
import PermissionData from "../Samvenda-Admin/DashboardPages/SamvednaNew/Roles and Permissions/Data.json";

export const usePermissions = (select, value) => {
  const { data } = useSelector((state) => state.rolesAndPermissions);
  const rolesAndPerms = data?.access_role?.permission ?? "[]";
  const rolesAndPermsParse = JSON.parse(rolesAndPerms);
  const ifRoleNameBlank = rolesAndPermsParse;

  const patientsPerms = findFunc(ifRoleNameBlank?.categories, select, value);
  return patientsPerms;
};
