import { Btn } from "./Button";
import styles from "../../assets/css/PatientUI/reportsCard.module.css";
import { AiOutlineCalendar } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import moment from "../../../node_modules/moment/moment";

export const ReportsCard = ({
  status,
  name,
  date,
  pdfLink,
  pdf_name,
  customerName,
  relation,
}) => {
  return (
    <div className={styles.reportsCard}>
      <p>{status}</p>
      {customerName ? (
        <div className={styles.customerName}>
          <h2>
            <b>Customer Name:</b> {customerName}
          </h2>
        </div>
      ) : (
        ""
      )}

      <h2>{name}</h2>

      {pdf_name ? (
        <div>
          <p>{pdf_name}.pdf</p>
        </div>
      ) : (
        ""
      )}
      <div>
        <AiOutlineCalendar color="#7A51AE" />
        <p>{moment(date).format("DD/MM/YYYY")}</p>
      </div>
      <div className={styles.buttons}>
        {/* <NavLink to="summary" style={{ textDecoration: "none" }}>
          <Btn
            width="160px"
            height="36px"
            color="#7A51AE"
            bgColor="transparent"
            variant="outlined"
          >
            View
          </Btn>
        </NavLink> */}
        <Btn width="160px" height="36px" variant="contained">
          <a
            href={pdfLink}
            target="_blank"
            style={{ color: "#ffffff" }}
            rel="noreferrer"
          >
            Download
          </a>
        </Btn>
      </div>
    </div>
  );
};
