import React from "react";

import { ADMIN_ROUTES } from "../../../../Routes/routesConstants";
import { useLocation } from "../../../../../node_modules/react-router-dom/index";
import { usePermissions } from "../../../../Hooks/usePermissions";
import SimpleBreadcrum from "../../../../Component/UI/SimpleBreadcrum";

import { Toaster } from "../../../../../node_modules/react-hot-toast/dist/index";
import ConsentOne from "./ConsentOne";
import ConsentTwo from "./ConsentTwo";
import ButtonName from "../../../../MockData/ButtonName.json";

import AllTabs from "../../../../Component/UI/Tabs/AllTabs";
import { useState } from "react";
import { ConsentList } from "./ConsentList";

export default function Consent() {
  const btnApi = ButtonName.ConsentTabs;
  let defaultTab = Object.keys(btnApi[0]);
  const [tabID, selectedTabID] = useState(defaultTab[0]);
  const { state } = useLocation();
  const permissions = usePermissions("name", "patients");
  const links = [
    {
      path: ADMIN_ROUTES.PATIENTS,
      linkText: "Patients",
    },
    {
      path: ADMIN_ROUTES.PATIENT_PROFILE,
      linkText: state?.name,
      data: { ...state, permissions },
    },
  ];

  const ComponentName = [<ConsentOne />, <ConsentTwo />, <ConsentList />];

  return (
    <>
      <Toaster />
      <SimpleBreadcrum link={links} text="Consent" noHeading />
      <AllTabs
        ComponentName={ComponentName}
        btnApi={btnApi}
        selectedTabID={selectedTabID}
      />
    </>
  );
}
