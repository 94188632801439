import { createSlice } from "@reduxjs/toolkit";
import axios from "../../../config/axiosConfig";

export const initialState = {
  loading: false,
  hasErrors: false,
  data: {
    upcoming: [],
    cancelled: [],
    completed: [],
  },
};

const EventSlice = createSlice({
  name: "Events",
  initialState,
  reducers: {
    getEventsLoading: (state) => {
      state.loading = true;
    },
    getUpcomingEvents: (state, action) => {
      state.data.upcoming = action.payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getCancelledEvents: (state, action) => {
      state.data.cancelled = action.payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getCompletedEvents: (state, action) => {
      state.data.completed = action.payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getEventsFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const {
  getEventsLoading,
  getUpcomingEvents,
  getCancelledEvents,
  getCompletedEvents,
  getEventsFailure,
} = EventSlice.actions;

export default EventSlice.reducer;

export function fetchUpcomingEvents(search_key, fromdate, todate) {
  return async function fetchUpcomingEventsThunk(dispatch, getState) {
    dispatch(getEventsLoading());
    try {
      const response = await axios.get("/admin/get/events", {
        params: {
          type: "upcoming",
          search_key,
          fromdate,
          todate
        },
      });
      console.log(response.data);
      dispatch(getUpcomingEvents(response.data.list));
    } catch (err) {
      console.log(err);
      dispatch(getEventsFailure());
    }
  };
}

export function fetchCompletedEvents(search_key, fromdate, todate) {
  return async function fetchCompletedEventsThunk(dispatch, getState) {
    dispatch(getEventsLoading());
    try {
      const response = await axios.get("/admin/get/events", {
        params: {
          type: "completed",
          search_key,
          fromdate,
          todate
        },
      });
      dispatch(getCompletedEvents(response.data.list));
    } catch (err) {
      console.log(err);
      dispatch(getEventsFailure());
    }
  };
}

export function fetchCancelledEvents(search_key, fromdate, todate) {
  return async function fetchCancelledEventsThunk(dispatch, getState) {
    dispatch(getEventsLoading());
    try {
      const response = await axios.get("/admin/get/events", {
        params: {
          type: "cancelled",
          search_key,
          fromdate,
          todate
        },
      });
      dispatch(getCancelledEvents(response.data.list));
    } catch (err) {
      console.log(err);
      dispatch(getEventsFailure());
    }
  };
}
