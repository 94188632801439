import React, { useState } from "react";

import AssessmentOwnerCSS from "../../../assets/css/assessmentOwner.module.css";
import { Helpcircle } from "../../../assets/icons/icon/Helpcircle";
import { ProgressBar } from "../../../Component/UI/ProgressBar";
import Clock from "../../../assets/icons/icon/clock.svg";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import classess from "../../../assets/css/assessment/range.module.css";

import {
  setAssessment_for,
  qaStore,
  selRange,
  selOption,
  selScore,
  selectMultiOption,
  selectMultiScore,
  setHandleNext,
  setTimetaken,
  setSubquestion,
} from "../../../Redux/Reducers/PatientReducers/qa_storage/qa_storage";
import { useEffect } from "react";

const QuestionAssessmentFor = (props) => {
  const { question, category, show, handleClick } = props;
  const { qaData, assessment_for, selectedOption, selectedScore, handleNext } =
    useSelector((state) => state.qa_storage);
  const [clicked, setClicked] = useState(null);
  const [progress, setProgress] = useState(0);

  const options = question.options.split(",");
  const option_img = question.option_img || [];

  const dispatch = useDispatch();

  const total = props.totalSteps;
  const currentStep = props?.currentStep;

  // const progress = (question?.serial_number / total) * 100;

  useEffect(() => {
    setProgress((currentStep / total) * 100);
  }, [currentStep]);

  const [sec, setSec] = useState(0);
  const [min, setMin] = useState(0);

  const Timer = () => {
    const clock = setInterval(() => {
      if (sec < 59) setSec(sec + 1);
      else setSec(0);

      if (sec === 59) {
        setMin(min + 1);
      }

      clearInterval(clock);
    }, 1000);

    return (
      <p>
        {min > 9 ? min : `0${min}`}:{sec < 10 ? `0${sec}` : sec}
      </p>
    );
  };

  const handlePrev = () => {
    if (props.currentStep === 1) {
      handleClick(show);
    } else {
      props.previousStep();
    }
  };

  const handleNextState = () => {
    const arr = [...qaData];
    // const preSelectedOption = arr.filter(function (e) {
    //   return e.serial_number === props.currentStep + 1;
    // });

    const preSelectedOption = arr[props.currentStep];

    //console.log(preSelectedOption, "preSelectedOption");

    if (preSelectedOption.input_type.toLowerCase() === "radio") {
      dispatch(selOption(preSelectedOption.selectedOption));
      dispatch(selScore(preSelectedOption.selectedOptionScore));

      if (preSelectedOption.subquestion) {
        dispatch(setSubquestion(preSelectedOption.subquestionSelectedOption));
      }
    } else if (preSelectedOption.input_type.toLowerCase() === "range") {
      dispatch(selRange(preSelectedOption.selectedOption));
    } else if (preSelectedOption.input_type.toLowerCase() === "checkbox") {
      dispatch(selectMultiOption(preSelectedOption.selectedOption));
      dispatch(selectMultiScore(preSelectedOption.selectedOptionScore));
    } else {
      dispatch(selOption(preSelectedOption.selectedOption));
      dispatch(selScore(preSelectedOption.selectedOptionScore));
    }
  };

  const handleNextButton = () => {
    const arr = [...qaData];
    props.nextStep();
    AddSelectedDatatoqaData();

    if (arr.length <= props.currentStep) {
      dispatch(selRange(""));
      dispatch(selOption(""));
      dispatch(selScore(""));
      dispatch(selectMultiOption([]));
      dispatch(selectMultiScore([]));
    } else {
      handleNextState();
    }

    let Finaltimetaken = Timer();
    dispatch(setTimetaken(Finaltimetaken?.props?.children.join("")));
  };

  const AddSelectedDatatoqaData = () => {
    let arr = [...qaData];
    const obj = {
      ...question,
      selectedOption: selectedOption,
      selectedOptionScore: selectedScore,
    };
    const exists = arr.findIndex((ele) => ele.id === obj.id);
    if (exists >= 0) {
      arr[currentStep - 1] = obj;
      dispatch(qaStore(arr));
    } else {
      arr.push(obj);
      dispatch(qaStore(arr));
    }
  };

  const setAssessment = (id) => {
    dispatch(qaStore([]));
    if (id === "Myself") {
      dispatch(setAssessment_for("Myself"));
      assessmentData(id);
    } else {
      dispatch(setAssessment_for("someone_else"));
      assessmentData(id);
    }
    setClicked(id);
    dispatch(setHandleNext(false));
  };

  const assessmentData = (id) => {
    let indx = options.indexOf(id);
    const score = question.scores.split(",")[indx];
    dispatch(selOption(id));
    dispatch(selScore(score));
  };

  const w = window.innerWidth;
  const display = w > 600 ? "flex" : "none";
  const height =
    w < 600 && option_img.length !== 0
      ? "110px"
      : w >= 600 && option_img.length !== 0
      ? "176px"
      : "fit-content";

  return (
    <>
      <div className={AssessmentOwnerCSS.outer}>
        {/* Header Component */}
        <div>
          <span id="bmiQuestion"></span>
          <div>
            <ProgressBar progress={progress} />
          </div>
          <div className={AssessmentOwnerCSS.assessmentName}>
            <div
              className={AssessmentOwnerCSS.q_no}
              style={{ display: display }}
            >
              {/* <span style={{display:display}}>
                <Helpcircle width="23" height="23" color="#7a51ae" />
              </span> */}
              {/* <span>
                {question?.serial_number > 9
                  ? `${props.currentStep} of ${props.totalSteps - 1}`
                  : `0${props.currentStep} of ${props.totalSteps - 1}`}
              </span> */}
            </div>
            <div className={AssessmentOwnerCSS.category}>
              <h2>{category.name}</h2>
            </div>
            <div className={AssessmentOwnerCSS.timer}>
              <img src={Clock} alt="clock" />
              &nbsp;<span id="timer">{Timer()}</span>
            </div>
          </div>
          <div className={AssessmentOwnerCSS.question}>
            <h1>{question?.question}</h1>
          </div>
        </div>
        {/* Header Component Close */}

        {/* Content */}
        <div className={AssessmentOwnerCSS.tabbox}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {options.map((option, index) => {
              if (option === "") {
                return null;
              }
              return (
                <>
                  <div
                    key={index}
                    id={option}
                    onClick={() => setAssessment(option)}
                    className={classess.radio}
                    style={{
                      width: "200px",
                      height: height,
                      minHeight: "50px",
                      border: "2px solid #b6b6b6",
                      margin: "20px 20px",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      borderColor:
                        clicked === `${option}` ? "#7a51ae" : "#b6b6b6",
                      background: clicked === `${option}` ? "#7a51ae50" : "",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      {option_img[index] && (
                        <img src={option_img[index]} width="75" alt={option} />
                      )}
                      <h6
                        style={{
                          fontSize: "20px",
                          lineHeight: "24px",
                          fontWeight: "400",
                        }}
                      >
                        {option}
                      </h6>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>

        {/* Content CLose  */}
        {/* footer */}
        <div className={AssessmentOwnerCSS.buttons}>
          <Button
            variant="contained"
            size="small"
            id="back_btn"
            style={{
              textTransform: "none",
              margin: "0 12px",
              width: "160px",
              height: "48px",
              backgroundColor: "#fff",
              color: "#111",
            }}
            onClick={handlePrev}
          >
            Back
          </Button>

          <Button
            variant="contained"
            size="small"
            disabled={handleNext}
            style={{
              textTransform: "none",
              margin: "0 12px",
              width: "160px",
              height: "48px",
              backgroundColor: "#7A51AE",
            }}
            onClick={handleNextButton}
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
};

export default QuestionAssessmentFor;
