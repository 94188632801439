import styles from "../../assets/css/UI/summaryCard.module.css";

export const SummaryCard = (props) => {
  return (
    <div className={styles.summaryCard}>
      <div
        className={styles.data}
        style={{ gridTemplateColumns: `repeat(${props.rows}, 1fr)` }}
      >
        {props.data.map(({ name, value }, id) => {
          return (
            <>
              {name && (
                <div key={id}>
                  <p className={styles.name}>{name ? name : '-'}</p>
                  <p className={styles.value}>{value ? value : '-'}</p>
                </div>
              )}
            </>
          );
        })}
      </div>
      {props.description && (
        <>
          <p>About Dr.</p>
          <p className={styles.description}>{props.description ? props.description : '-'}</p>
        </>
      )}
    </div>
  );
};
