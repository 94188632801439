import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Classess from "../../assets/css/Patient-login/patientlogin.module.css";
import classes from "../../assets/css/login.module.css";
import Fb from "../../assets/icons/icon/facebook.svg";
import LoginImage from "../../assets/icons/LoginImage.svg";
import { Input, InputWithIcon } from "../../Component/UI/CustomInputs";
import { Eye } from "../../assets/icons/Eye";
import AssessmentStyles from "../../assets/css/assessment.module.css";
import logo from "../../assets/icons/icon/logo.svg";
import AuthContext from "../../Auth/AuthContext";

import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";
import GoogleLoginComp from "./GoogleLogin";
import FacebookLoginComp from "./FacebookLoginComp";
import { useDispatch, useSelector } from "react-redux";
import {
  qaStore,
  setAssessment_for,
  setNumberOfQues,
} from "../../Redux/Reducers/PatientReducers/qa_storage/qa_storage";
import { Btn } from "../UI/Button";
import { CircularProgress } from "../../../node_modules/@mui/material/index";
import { Helmet } from "react-helmet";
import { setLocation } from "../../Redux/Slices/location";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useLocation } from "react-router-dom";

export const PatientLogin = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locations = useLocation();
  const { location } = useSelector((i) => i.location);
  const queryParameters = new URLSearchParams(window.location.search);
  const uniqId = queryParameters.get("uniqId");
  const feedbackSelectdOption =
    location?.split("?")?.[2]?.slice(0, 1)?.toUpperCase() +
    location?.split("?")?.[2]?.slice(1);

  useEffect(() => {
    dispatch(setLocation(window.location.search));
  }, []);

  // const ctx = useContext(AuthContext);
  const [userDetail, setuserDetail] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState({
    email: "",
    password: "",
  });
  const [message, setMessage] = useState();
  const [disableBtn, setDisableBtn] = useState(false);

  const { LOGINCUSTOMER } = API;

  const ctx = useContext(AuthContext);
  const is_loggedin = ctx?.loginUser?.is_loggedin;
  /* useEffect(() => { */
  /*   is_loggedin && navigate("/dashboard"); */
  /* }, []); */

  useEffect(() => {
    dispatch(setLocation(window.location.search));
  }, []);

  const handleChange = (event) => {
    setuserDetail({
      ...userDetail,
      [event.target.name]: event.target.value,
    });
  };

  const {
    qaData,
    category_id,
    numberOfQues,
    Timetaken,
    assessment_for,
    relation,
    relationName,
  } = useSelector((state) => state.qa_storage);

  const ProgressStatus =
    Number(numberOfQues) === qaData.length ? "completed" : "inprogress";

  const onSubmit = (e) => {
    e.preventDefault();

    setError({});
    setMessage();
    setDisableBtn(true);
    try {
      const obj = {
        email: userDetail?.email,
        password: userDetail?.password,
        assessment_result: qaData,
        assessment_id: category_id,
        status: ProgressStatus,
        percentage: (qaData.length / Number(numberOfQues)) * 100 || "",
        time_taken: Timetaken,
        assessment_for,
        relation,
        relation_name: relationName,
      };
      axios
        .post(LOGINCUSTOMER, obj)
        .then((res) => {
          setDisableBtn(false);
          if (res?.status === 200) {
            if (res.data.data.token) {
              ctx.auth({
                ...res.data.data,
                role: "client",
                is_loggedin: true,
                expireDate: new Date().getTime() + 24 * 60 * 60 * 1000,
                showPopup: true,
              });
              if (ProgressStatus === "completed") {
                navigate("/patient/assessment/results", {
                  state: {
                    assessment_for,
                    popStatus: res?.data?.data?.customer?.popStatus,
                    category_id,
                  },
                });
              } else if (location?.split("?")?.[1] === "feedback") {
                navigate("/patient/feedback", {
                  state: {
                    feedback_type: feedbackSelectdOption,
                  },
                });
              } else if (location?.split("?")?.[1] === "testimonial") {
                navigate("/patient/testimonial", {
                  state: {
                    feedback_type: feedbackSelectdOption,
                  },
                });
              } else if (location?.split("?")?.[1] === "Assessment") {
                navigate("/patient/Assessment", {
                  state: {
                    feedback_type: feedbackSelectdOption,
                  },
                });
              } else if (location?.split("?")?.[1] === "clientselfcareplan") {
                navigate("/clientselfcareplan", {
                  state: {
                    feedback_type: feedbackSelectdOption,
                  },
                });
              } else if (location?.split("?")?.[1] === "progresstrends") {
                navigate("/patient/progresstrends", {
                  state: {
                    feedback_type: feedbackSelectdOption,
                  },
                });
              } else if (location?.split("?")?.[1] === "consent") {
                navigate("/patient/consent");
              } else if (
                location?.split("?")?.[1] === `consentTwo&uniqId=${uniqId}`
              ) {
                navigate(`/patient/consentTwo&uniqId=${uniqId}`);
              } else {
                navigate("/dashboard");
              }
              dispatch(qaStore([]));
              dispatch(setAssessment_for("Myself"));
              dispatch(setNumberOfQues("number"));
            }
          }
        })
        .catch((err) => {
          setDisableBtn(false);
          const ErrRes = err?.response?.data;
          if (ErrRes?.message) {
            setMessage(ErrRes?.message);
          } else if (ErrRes?.error) {
            setError(ErrRes?.error);
          }
        });
    } catch (error) {
      // console.log("Error", error);
    }
  };

  const domain = window.location.host;
  const canonicalUrl = `https://${domain}/user/signin`;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>User - Signin - Samvedna Map</title>
        <meta name="title" content="User - Signin - Samvedna Map" />
        <meta
          name="description"
          content="Don't have an account yet ? Feel Free to Signup or Register Login with Google or Facebook. Our services include assessments, and online counselling."
        />
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href={canonicalUrl} />
        <script
          src="https://in.fw-cdn.com/30138877/88436.js"
          chat="false"
        ></script>
      </Helmet>
      <div
        className={classes.login_container}
        style={{ background: `url(${LoginImage})` }}
      >
        <header className={AssessmentStyles.header}>
          <Link to="/">
            <img className={AssessmentStyles.logo} src={logo} alt="logo" />
          </Link>
        </header>
        <div
          style={{
            position: "absolute",
            width: "100px",
            zIndex: 1,
          }}
          className={classes.back_btn}
        >
          <p
            style={{
              color: "#7A51AE",
              cursor: "pointer",
              fontSize: "15px",
              display: "flex",
              alignItems: "center",
              textDecoration: "underline",
            }}
            onClick={() =>
              // locations?.pathname == "user/signin"
              //   ? navigate("/MapLanding")
              //   : navigate(-1)
              navigate("/")
            }
          >
            <AiOutlineArrowLeft size={15} />
            {" Back"}
          </p>
        </div>
        <form
          className={classes.login_subcontainer}
          /*style={{ top: "55%" }}*/ onSubmit={onSubmit}
        >
          <div>
            <h1 className={classes.heading}>Sign in to your account</h1>

            <div className={classes.input}>
              <Input
                type="text"
                label="Email Address"
                id="email"
                value={userDetail?.email}
                name="email"
                labelSize={14}
                onChange={(e) => handleChange(e)}
                placeholder="Email Address"
                style={{ outlineColor: "#7a51ae" }}
                autoComplete="off"
                error={error?.email}
              />
            </div>
            <br />
            {/* <label>Password:</label> */}
            <div className={classes.input}>
              <InputWithIcon
                type="password"
                label="Password:"
                error={error?.password}
                labelSize={14}
                placeholder="Enter Password"
                icon={Eye}
                onChange={(e) =>
                  setuserDetail({ ...userDetail, password: e.target.value })
                }
              />
            </div>

            <div>
              <p style={{ color: "red", float: "left", marginBottom: "25px" }}>
                {message !== "" ? message : null}
              </p>
            </div>
            <div className={classes.forgot_text}>
              <Link to="/user/forgot">
                <p style={{ color: "#7a51ae", fontSize: "12px" }}>
                  Forgot&nbsp;Password?
                </p>
              </Link>
            </div>

            <div className={classes.submit}>
              <Btn
                width={"100%"}
                height={48}
                fontSize={18}
                fontWeight={400}
                variant="contained"
                disabled={disableBtn}
                click={() => onSubmit()}
                disableRipple
              >
                {disableBtn ? <CircularProgress color="inherit" /> : "Sign in"}
              </Btn>
              {/* <input
                type="submit"
                value="Sign in"
                onClick={() => onSubmit()}
                style={{
                  background: "#7A51AE",
                  cursor: "pointer",
                  fontSize: "16px",
                  height: "40px",
                }}
              /> */}
            </div>
          </div>
          <div>
            <div>
              <div className={Classess.horizonLineDiv}>
                <div className={Classess.horizonLine}></div>
                <span className={Classess.or}>OR</span>
                <div className={Classess.horizonLine}></div>
              </div>
              <GoogleLoginComp />
              {/* <FacebookLoginComp /> */}
              <div className={Classess.signup}>
                <p>
                  Don't have an account?
                  <span style={{ color: "#7a51ae" }}>
                    <Link to="/user/signup">Sign up</Link>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
