import { Btn } from "../../Samvenda-Patient/UI/Button";
import styles from "./prelogin.module.css";
import serviceImg from "../../../src/assets/icon/services_icon.svg";

export const ServicesCard = ({ serviceData, btnbg, click }) => {
  return (
    <div className={styles.servicesCard}>
      <div className={styles.servicesCardHeader}></div>
      <div className={styles.img} style={{ position: "absolute" }}>
        <img src={serviceImg} alt="" />
      </div>
      <div className={styles.content}>
        <div>
          <p className={styles.title}>{serviceData.name}</p>
          <p className={styles.amount}>₹{serviceData.amount} </p>
        </div>
        <Btn
          variant="contained"
          borderRadius="8px"
          bgColor={btnbg || "#3592FF"}
          width="200px"
          height="36px"
          click={click}
        >
          Details
        </Btn>
      </div>
    </div>
  );
};
