import React from "react";
import { Testimonialcard } from "./Components/Testimonialcard";
import PrevIcon from "../MapLanding/Images/arrow-left.svg";
import ForwardIcon from "../MapLanding/Images/arrow-right.svg";
import Slider from "react-slick";
import { useEffect } from "react";
import { getTestimonials } from "../../../services/enterprise/prelogin";
import { useState } from "react";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

const PrevArrow = ({ className, style, onClick }) => (
  <button
    className={className}
    style={{ ...style, display: "block" }}
    onClick={onClick}
  >
    <img src={PrevIcon} alt="left" />
  </button>
);
const NextArrow = ({ className, style, onClick }) => (
  <button
    className={className}
    style={{ ...style, display: "block" }}
    onClick={onClick}
  >
    <img src={ForwardIcon} alt="ForwardIcon" />
  </button>
);

export const Testimonials = ({ styles }) => {
  const [testimonials, setTestimonials] = useState();
  useEffect(() => {
    getTestimonials()
      .then((res) => {
        res.data.list.length && setTestimonials(res.data.list);
      })
      .catch((err) => console.log(err));
  }, []);

  const settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    lazyLoad: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  console.log(testimonials, "testimonials---");

  return (
    <div className={styles.testimonialSec}>
      <h3>What people have to say about our services?</h3>
      {/* <div className={styles.testimonialsCards}> */}
      <div>
        {testimonials?.length > 1 ? (
          <Slider {...settings}>
            {testimonials?.map((item, i) => (
              <Testimonialcard item={item} index={i} />
            ))}
          </Slider>
        ) : (
          testimonials?.map((item, i) => (
            <Testimonialcard item={item} index={i} />
          ))
        )}
      </div>
      {/* </div> */}
    </div>
  );
};
