import React, { useEffect, useState } from "react";

import axios from "../../../config/axiosConfig";
import { CustomTable } from "../../../Component/UI/Table";

import { to12Hours, YtoD } from "../../../config/formattedDate";
import { useDispatch, useSelector } from "react-redux";
import {
  setFilter,
  setFrom_date,
  setSearch_key,
  setTo_date,
  setClear,
} from "../../../Redux/Slices/AdminFilters/Search";
import OnlineImg from "../../../assets/images/appOnline.svg";
import OfflineImg from "../../../assets/images/appOffline.svg";
import HybirdImg from "../../../assets/images/appHybird.svg";
import moment from "../../../../node_modules/moment/moment";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";
export const CancelledAppointment = ({ setCancelledData }) => {
  const [client, setClient] = useState([]);
  const dispatch = useDispatch();
  const [loadingApp, setLoadingApp] = useState(false);

  const { search_key, from_date, to_date, filter } = useSelector(
    (i) => i.SearchFilters
  );

  const tHead = [
    "Patient Name",
    "Mobile",
    "Date",
    "Appointment ID",
    "Time",
    "Staff",
    "Status",
  ];

  const clientStatus = async (appointment_id, status) => {
    await axios
      .post("/admin/appointment/status/change", {
        appointment_id,
        status,
      })
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
  };

  const handleDelete = async (appointment_id) => {
    await axios
      .post("/admin/delete/appointment", {
        params: {
          appointment_id,
        },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    setLoadingApp(true);
    axios
      .get(
        `/admin/get/appointmentNew?type=cancelled&search_key=${search_key}&fromdate=${from_date}&todate=${to_date}`
      )
      .then((res) => {
        setLoadingApp(false);
        setClient(res.data.list);
      })
      .catch((err) => console.log(err));
  }, [filter]);

  useEffect(() => {
    setCancelledData(client);
  }, [client]);

  useEffect(() => {
    return () => {
      // dispatch(setSearch_key(""));
      dispatch(setTo_date(""));
      dispatch(setFrom_date(""));
      dispatch(setFilter());
      dispatch(setClear());
    };
  }, []);

  console.log(client, "cancelled");

  const tBodyData = client.map(
    (
      {
        paitient_name,
        mobile,
        appointment_date,
        session_id,
        service_type,
        appointment_time,
        status,
        id,
        staff,
        end_time,
        cancel_by,
        service_name,
      },
      i
    ) => {
      const appointmentDate = appointment_date.split("-").reverse();
      const appointment = [
        appointmentDate[0],
        appointmentDate[1],
        appointmentDate[2],
      ].join("-");
      const appointmentDate1 = appointment;
      console.log("rr", appointmentDate1);

      const [start_hour, start_min] = appointment_time.split(":");
      const [end_hour, end_min] = end_time.split(":");
      const time =
        moment(appointment_date).format("h:mm A") +
        " - " +
        moment(end_time, "hh:mm").format("hh:mm A");

      const date = YtoD(appointment_date.split(" ")[0]);

      return {
        paitient_name,
        mobile,
        appointment_date: appointment_date
          ? moment(appointment_date).format("DD-MM-YYYY")
          : "",
        session: (
          <>
            <div
              id={"AppointmentID" + i}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <span>
                {" "}
                {service_type === 0 ? (
                  <img
                    style={{
                      width: "17px",
                      marginTop: "1px",
                      marginRight: "3px",
                    }}
                    src={OfflineImg}
                    alt={"Offline"}
                  />
                ) : service_type === 1 ? (
                  <img
                    style={{
                      width: "17px",
                      marginTop: "1px",
                      marginRight: "3px",
                    }}
                    src={OnlineImg}
                    alt={"Online"}
                  />
                ) : service_type === 2 ? (
                  <img
                    style={{
                      width: "17px",
                      marginTop: "1px",
                      marginRight: "3px",
                    }}
                    src={HybirdImg}
                    alt={"Hybird"}
                  />
                ) : (
                  ""
                )}
              </span>
              <span style={{ color: "#0085FF", textDecoration: "underline" }}>
                {session_id?.toUpperCase()}
              </span>
            </div>
            <ReactTooltip
              anchorId={"AppointmentID" + i}
              place="bottom"
              //variant="info"
              content={service_name}
            />
          </>
        ),
        time,
        staff,
        status: Number(status) === 2 && (
          <p style={{ color: "#EB0000", fontSize: "16px" }}>
            Cancelled {cancel_by ? `by ${cancel_by}` : ""}
          </p>
        ),
      };
    }
  );

  return !loadingApp ? (
    <CustomTable tHead={tHead} tBody={tBodyData} />
  ) : (
    <div className="loader" style={{ textAlign: "center", margin: "60px" }}>
      <CircularProgress size={28} />
    </div>
  );
};
