import React from "react";

const NoData = () => {
  return (
    <div>
      <svg
        width="180"
        height="180"
        viewBox="0 0 180 180"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M56.5142 21.5C57.7929 18.2956 59.974 15.5245 62.7998 13.5274C65.8197 11.3932 69.4271 10.2482 73.125 10.25L56.5142 21.5ZM56.5142 21.5H50.625C45.8843 21.5 41.3377 23.3833 37.9855 26.7355C34.6333 30.0877 32.75 34.6343 32.75 39.375V151.875C32.75 156.616 34.6333 161.162 37.9855 164.515C41.3377 167.867 45.8843 169.75 50.625 169.75H129.375C134.116 169.75 138.662 167.867 142.015 164.515C145.367 161.162 147.25 156.616 147.25 151.875V39.375C147.25 34.6343 145.367 30.0877 142.015 26.7355C138.662 23.3833 134.116 21.5 129.375 21.5H123.486C122.207 18.2956 120.026 15.5245 117.2 13.5274C114.18 11.3932 110.573 10.2482 106.875 10.25C106.875 10.25 106.875 10.25 106.875 10.25H73.1253L56.5142 21.5Z"
          fill="white"
          stroke="#7A51AE"
          stroke-width="2"
        />
        <path
          opacity="0.2"
          d="M73.125 22C71.5005 22 69.9426 22.6453 68.794 23.794C67.6453 24.9426 67 26.5005 67 28.125C67 29.7495 67.6453 31.3074 68.794 32.456C69.9426 33.6047 71.5005 34.25 73.125 34.25H106.875C108.499 34.25 110.057 33.6047 111.206 32.456C112.355 31.3074 113 29.7494 113 28.125C113 26.5005 112.355 24.9426 111.206 23.794C110.057 22.6453 108.499 22 106.875 22H73.125Z"
          fill="#E69A8D"
          stroke="#7A51AE"
        />
        <path
          d="M64.393 72H50.607C49.9159 71.9992 49.2532 71.7268 48.7645 71.2424C48.2757 70.7581 48.0008 70.1014 48 69.4164V57.5836C48.0008 56.8986 48.2757 56.2419 48.7645 55.7576C49.2532 55.2732 49.9159 55.0008 50.607 55H64.393C65.0841 55.0008 65.7468 55.2732 66.2355 55.7576C66.7243 56.2419 66.9992 56.8986 67 57.5836V69.4164C66.9992 70.1014 66.7243 70.7581 66.2355 71.2424C65.7468 71.7268 65.0842 71.9992 64.393 72Z"
          fill="url(#paint0_linear_179_8419)"
        />
        <path
          d="M64.393 106H50.607C49.9159 105.999 49.2532 105.727 48.7645 105.242C48.2757 104.758 48.0008 104.101 48 103.416V91.5836C48.0008 90.8986 48.2757 90.2419 48.7645 89.7576C49.2532 89.2732 49.9159 89.0008 50.607 89H64.393C65.0841 89.0008 65.7468 89.2732 66.2355 89.7576C66.7243 90.2419 66.9992 90.8986 67 91.5836V103.416C66.9992 104.101 66.7243 104.758 66.2355 105.242C65.7468 105.727 65.0842 105.999 64.393 106Z"
          fill="url(#paint1_linear_179_8419)"
        />
        <path
          d="M64.393 140H50.607C49.9159 139.999 49.2532 139.727 48.7645 139.242C48.2757 138.758 48.0008 138.101 48 137.416V125.584C48.0008 124.899 48.2757 124.242 48.7645 123.758C49.2532 123.273 49.9159 123.001 50.607 123H64.393C65.0841 123.001 65.7468 123.273 66.2355 123.758C66.7243 124.242 66.9992 124.899 67 125.584V137.416C66.9992 138.101 66.7243 138.758 66.2355 139.242C65.7468 139.727 65.0842 139.999 64.393 140Z"
          fill="url(#paint2_linear_179_8419)"
        />
        <path
          opacity="0.3"
          d="M118.873 62H73.1269C72.828 62 72.5414 61.8946 72.3301 61.7071C72.1187 61.5196 72 61.2652 72 61C72 60.7348 72.1187 60.4804 72.3301 60.2929C72.5414 60.1054 72.828 60 73.1269 60H118.873C119.172 60 119.459 60.1054 119.67 60.2929C119.881 60.4804 120 60.7348 120 61C120 61.2652 119.881 61.5196 119.67 61.7071C119.459 61.8946 119.172 62 118.873 62Z"
          fill="#E69A8D"
        />
        <path
          opacity="0.3"
          d="M89.9072 67H73.0907C72.8014 67 72.524 66.8946 72.3194 66.7071C72.1149 66.5196 72 66.2652 72 66C72 65.7348 72.1149 65.4804 72.3194 65.2929C72.524 65.1054 72.8014 65 73.0907 65H89.9072C90.0506 64.9997 90.1927 65.0254 90.3252 65.0756C90.4578 65.1257 90.5783 65.1993 90.6798 65.2922C90.7813 65.3851 90.8618 65.4954 90.9168 65.6169C90.9717 65.7383 91 65.8685 91 66C91 66.1315 90.9717 66.2617 90.9168 66.3831C90.8618 66.5046 90.7813 66.6149 90.6798 66.7078C90.5783 66.8007 90.4578 66.8743 90.3252 66.9244C90.1927 66.9746 90.0506 67.0003 89.9072 67Z"
          fill="#E69A8D"
        />
        <path
          opacity="0.3"
          d="M118.873 96H73.1269C72.828 96 72.5414 95.8946 72.3301 95.7071C72.1187 95.5196 72 95.2652 72 95C72 94.7348 72.1187 94.4804 72.3301 94.2929C72.5414 94.1054 72.828 94 73.1269 94H118.873C119.172 94 119.459 94.1054 119.67 94.2929C119.881 94.4804 120 94.7348 120 95C120 95.2652 119.881 95.5196 119.67 95.7071C119.459 95.8946 119.172 96 118.873 96Z"
          fill="#E69A8D"
        />
        <path
          opacity="0.3"
          d="M89.9072 101H73.0907C72.8014 101 72.524 100.895 72.3194 100.707C72.1149 100.52 72 100.265 72 100C72 99.7348 72.1149 99.4804 72.3194 99.2929C72.524 99.1053 72.8014 99 73.0907 99H89.9072C90.0506 98.9997 90.1927 99.0254 90.3252 99.0756C90.4578 99.1257 90.5783 99.1993 90.6798 99.2922C90.7813 99.3851 90.8618 99.4954 90.9168 99.6169C90.9717 99.7383 91 99.8685 91 100C91 100.131 90.9717 100.262 90.9168 100.383C90.8618 100.505 90.7813 100.615 90.6798 100.708C90.5783 100.801 90.4578 100.874 90.3252 100.924C90.1927 100.975 90.0506 101 89.9072 101Z"
          fill="#E69A8D"
        />
        <path
          opacity="0.3"
          d="M118.873 130H73.1269C72.828 130 72.5414 129.895 72.3301 129.707C72.1187 129.52 72 129.265 72 129C72 128.735 72.1187 128.48 72.3301 128.293C72.5414 128.105 72.828 128 73.1269 128H118.873C119.172 128 119.459 128.105 119.67 128.293C119.881 128.48 120 128.735 120 129C120 129.265 119.881 129.52 119.67 129.707C119.459 129.895 119.172 130 118.873 130Z"
          fill="#E69A8D"
        />
        <path
          opacity="0.3"
          d="M89.9072 136H73.0907C72.8014 136 72.524 135.842 72.3194 135.561C72.1149 135.279 72 134.898 72 134.5C72 134.102 72.1149 133.721 72.3194 133.439C72.524 133.158 72.8014 133 73.0907 133H89.9072C90.0506 133 90.1927 133.038 90.3252 133.113C90.4578 133.189 90.5783 133.299 90.6798 133.438C90.7813 133.578 90.8618 133.743 90.9168 133.925C90.9717 134.107 91 134.303 91 134.5C91 134.697 90.9717 134.893 90.9168 135.075C90.8618 135.257 90.7813 135.422 90.6798 135.562C90.5783 135.701 90.4578 135.811 90.3252 135.887C90.1927 135.962 90.0506 136 89.9072 136Z"
          fill="#E69A8D"
        />
        <defs>
          <linearGradient
            id="paint0_linear_179_8419"
            x1="58.4268"
            y1="115.516"
            x2="46.1935"
            y2="45.9385"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#5E397E" />
            <stop offset="0.9984" stop-color="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_179_8419"
            x1="58.4268"
            y1="149.516"
            x2="46.1935"
            y2="79.9385"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#5E397E" />
            <stop offset="0.9984" stop-color="white" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_179_8419"
            x1="58.4268"
            y1="183.516"
            x2="46.1935"
            y2="113.938"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#5E397E" />
            <stop offset="0.9984" stop-color="white" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default NoData;
