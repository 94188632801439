import { createSlice } from "@reduxjs/toolkit";

const getResultsSlice = createSlice({
  name: "get results",
  initialState: { data: [] },
  reducers: {
    getResults: (state, action) => {
      console.log(action.payload);
      state.data = action.payload;
    },
  },
});

export const { getResults } = getResultsSlice.actions;

export default getResultsSlice.reducer;
