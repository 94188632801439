import React, { Fragment, useState } from "react";
import { CircularProgress } from "../../../../../../node_modules/@mui/material/index";
import { Avatar } from "../../../../../Component/UI/Avatar";
import { Input } from "../../../../../Component/UI/CustomInputs";
import styles from "./tab.module.css";
export const Tab = ({
  Component,
  selectDropDown,
  tHead,
  staffListData,
  isLoading,
  setSelectStaffId,
  selectStaffId,
  isLoadingData,
  timeSlotsBooked,
  setSearchStaff,
  searchStaff,
  tHeadAdd,
  staffTimeSlotdata,
  rosterPermissions,
}) => {
  const [select, setSelect] = useState();
  const handleChangeSearch = (event) => {
    setSearchStaff(event.target.value);
  };

  return (
    <div className={styles.mainTab}>
      <div className={styles.staffListLeft}>
        <div className={styles.staffSearch}>
          <Input
            label="Search Staff"
            name="Search"
            value={searchStaff}
            onChange={(e) => handleChangeSearch(e)}
            width="248px"
          />
        </div>
        <div className={styles.staffListdata}>
          <div className={styles.toggle}>
            {isLoading && (
              <div style={{ textAlign: "center" }}>
                <CircularProgress size={30} />
              </div>
            )}
            <div className={styles.scrollbar} id={styles.styleId}>
              {staffListData?.map((item, i) => {
                return (
                  <Fragment key={i}>
                    <div
                      className={`${
                        select === item?.id ? styles.selected : null
                      }  ${styles.slider}`}
                      onClick={() => {
                        setSelectStaffId(item?.id);
                        setSelect(item?.id);
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: 0,
                        }}
                      >
                        {
                          <Avatar
                            size="28px"
                            profile={item?.staff_image}
                            border="1px solid #ccc"
                          />
                        }
                        <span style={{ marginLeft: "8px" }}>{item?.name}</span>
                      </span>
                    </div>
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.staffListRight}>
        <Fragment>
          <Component
            selectDropDown={selectDropDown}
            tHead={tHead}
            select={selectStaffId}
            staffListData={staffListData}
            isLoadingData={isLoadingData}
            timeSlotsBooked={timeSlotsBooked}
            tHeadAdd={tHeadAdd}
            staffTimeSlotdata={staffTimeSlotdata}
            rosterPermissions={rosterPermissions}
          />
        </Fragment>
      </div>
    </div>
  );
};
