import React, { useState, useEffect } from "react";
import BasicBreadcrumbs from "../../../Component/UI/BreadCrumb/BreadCrumbs";
import { ADMIN_ROUTES } from "../../../Routes/routesConstants";

import { CustomTable } from "../../../Component/UI/Table";
import { AiOutlineDownload } from "react-icons/ai";
import { FaFileExcel, FaFilePdf } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetSearchFilters } from "../../../Redux/Slices/AdminFilters/Search";
import {
  enterpriseAssessmentListAdmin,
  enterpriseSubscriptionsListAdmin,
} from "../../../services/admin/enterprise";
import Avatar from "@mui/material/Avatar";
import defaultImage from "../../../assets/icons/icon/default_profile.svg";
import moment from "../../../../node_modules/moment/moment";
import { utils, writeFile } from "xlsx";
import {
  getEnterprisePdfReport,
  getReports,
  getSubscriptionReports,
} from "../../../services/admin/AssessmentReport";
import { toast, Toaster } from "react-hot-toast";

const SubscriptionReports = () => {
  const [subscriptionData, setSubscriptionData] = useState([]);
  // const [assessmentData, setAssessmentData] = useState();
  const { state } = useLocation();

  const { from_date, to_date } = useSelector((state) => state.SearchFilters);
  const dispatch = useDispatch();

  const convertDate = (t) => moment(t).format("DD/MM/YYYY");
  const oneMonth = 30;
  const convertValidity = (days) =>
    days >= oneMonth
      ? days / oneMonth > 1
        ? days / oneMonth + " Months"
        : days / oneMonth + " Month"
      : days + " Days";

  const intervalTypes = {
    0: "Daily",
    1: "Weekly",
    2: "Monthly",
    3: "Twice a week",
    4: "Thrice a week"
  };

  useEffect(() => {
    if (to_date !== "Invalid date") {
      enterpriseSubscriptionsListAdmin({
        enterprise_id: state?.data?.id,
        from_date,
        to_date,
      })
        .then((res) => setSubscriptionData(res?.data?.list))
        .catch((err) => console.log(err));
    }
  }, [from_date, to_date]);

  useEffect(() => {
    return () => {
      dispatch(resetSearchFilters());
    };
  }, []);

  const handleExport = (assessmentResult, item) => {
    const exportSubsData = assessmentResult?.subscriptionServiceData?.flatMap(
      (response) => {
        return response?.patient?.length
          ? response?.patient?.map((patient, i) => {
              return {
                Id: assessmentResult?.listData?.id,
                "Subscription Name": assessmentResult?.listData?.name,
                "User Type":
                  assessmentResult?.listData?.subscription_type === "0"
                    ? "General"
                    : assessmentResult?.listData?.enterprise_name,
                Status:
                  assessmentResult?.listData?.status === 1
                    ? "Active"
                    : "InActive",
                "Total Amount": assessmentResult?.listData?.net_price,
                "Discount Type":
                  assessmentResult?.listData?.discount_type === "0"
                    ? "No Discount"
                    : assessmentResult?.listData?.discount_type === "1"
                    ? "Flat"
                    : assessmentResult?.listData?.discount_type === "2"
                    ? "Percentage"
                    : "",
                "Discount Value":
                  assessmentResult?.listData?.discount_percentage,
                "Tax Amount": assessmentResult?.listData?.tax_amount,

                "Created Date": assessmentResult?.listData?.created_at
                  ? convertDate(assessmentResult?.listData?.created_at)
                  : "",
                Validity: convertValidity(assessmentResult?.listData?.validity),
                "Started Date": assessmentResult?.listData?.approved_at
                  ? convertDate(assessmentResult?.listData?.approved_at)
                  : "",
                "Expiry Date": assessmentResult?.listData?.expiry_at
                  ? convertDate(assessmentResult?.listData?.expiry_at)
                  : "",
                "Extended validity": assessmentResult?.listData
                  ?.extended_validity
                  ? convertValidity(
                      assessmentResult?.listData?.extended_validity
                    )
                  : "",

                "Additional Amount":
                  assessmentResult?.listData?.additional_amount,

                "Service Id": response?.service?.service_id,
                "Service Name": response?.service?.service_name,
                "Duration (HH:MM)": response?.service?.duration,
                "Number of Session": response?.service?.number_session,
                "Service Unit Price": response?.service?.unit_price,
                "Service Total Price": response?.service?.total_price,
                "Tax Applicable": `${
                  response?.service?.tax_applicable.toString() !== "1"
                    ? "No"
                    : `Yes`
                }`,
                "Tax value": response?.service?.tax_value,
                "Min Validity": convertValidity(
                  response?.service?.minimum_validity
                ),
                "Interval Type":
                  intervalTypes[response?.service?.interval_type],
                Mode: ["Offline", "Online", "Hybrid"][response?.service?.mode],
                "Consumed Count": response?.service?.consume
                  ? response?.service?.consume
                  : "",
                "Patient Name": patient?.paitient_name,
                "Patient email": patient?.paitient_email,
                "Appointment Date": patient?.appointment_date
                  ? moment(patient?.appointment_date).format("DD-MM-YYYY")
                  : "",
                "Appointment Time": patient?.appointment_time
                  ? moment(patient?.appointment_time, "hh:mm").format("hh:mm A")
                  : "",
              };
            })
          : {
              Id: assessmentResult?.listData?.id,
              "Subscription Name": assessmentResult?.listData?.name,
              "User Type":
                assessmentResult?.listData?.subscription_type === "0"
                  ? "General"
                  : assessmentResult?.listData?.enterprise_name,
              Status:
                assessmentResult?.listData?.status === 1
                  ? "Active"
                  : "InActive",
              "Total Amount": assessmentResult?.listData?.net_price,
              "Discount Type":
                assessmentResult?.listData?.discount_type === "0"
                  ? "No Discount"
                  : assessmentResult?.listData?.discount_type === "1"
                  ? "Flat"
                  : assessmentResult?.listData?.discount_type === "2"
                  ? "Percentage"
                  : "",
              "Discount Value": assessmentResult?.listData?.discount_percentage,
              "Tax Amount": assessmentResult?.listData?.tax_amount,

              "Created Date": assessmentResult?.listData?.created_at
                ? convertDate(assessmentResult?.listData?.created_at)
                : "",
              Validity: convertValidity(assessmentResult?.listData?.validity),
              "Started Date": assessmentResult?.listData?.approved_at
                ? convertDate(assessmentResult?.listData?.approved_at)
                : "",
              "Expiry Date": assessmentResult?.listData?.expiry_at
                ? convertDate(assessmentResult?.listData?.expiry_at)
                : "",
              "Extended validity": assessmentResult?.listData?.extended_validity
                ? convertValidity(assessmentResult?.listData?.extended_validity)
                : "",

              "Additional Amount":
                assessmentResult?.listData?.additional_amount,

              "Service Id": response?.service?.service_id,
              "Service Name": response?.service?.service_name,
              "Duration (HH:MM)": response?.service?.duration,
              "Number of Session": response?.service?.number_session,
              "Service Unit Price": response?.service?.unit_price,
              "Service Total Price": response?.service?.total_price,
              "Tax Applicable": `${
                response?.service?.tax_applicable.toString() !== "1"
                  ? "No"
                  : `Yes`
              }`,
              "Tax value": response?.service?.tax_value,
              "Min Validity": convertValidity(
                response?.service?.minimum_validity
              ),
              "Interval Type": intervalTypes[response?.service?.interval_type],
              Mode: ["Offline", "Online", "Hybrid"][response?.service?.mode],
              "Consumed Count": response?.service?.consume
                ? response?.service?.consume
                : "",
                "Patient Name":"",
                "Patient email":"",
                "Appointment Date": "",                  
                "Appointment Time": "",
            };
      }
    );

    const wb = utils.book_new();
    const ws = utils.json_to_sheet(exportSubsData);
    utils.book_append_sheet(wb, ws, "Report");
    writeFile(wb, `${item.name}_Subscription_Report.xlsx`);
  };

  // const links = [
  //   {
  //     path: ADMIN_ROUTES.ENTERPRISES,
  //     linkText: "Enterprise",
  //   },
  // ];
  // if (state?.data) {
  //   links.push({
  //     path: ADMIN_ROUTES.ENTERPRISES_VIEWBOX,
  //     linkText: state?.data?.company_name,
  //     data: state?.data,
  //   });
  // }

  const handledownload = (callback, item) => {
    getSubscriptionReports(item.id)
      .then((res) => {
        console.log(res, "res----");
        callback(res?.at(0), item);
      })
      .catch((err) => {});
  };

  const handlePdfDownload = (item) => {
    getEnterprisePdfReport({
      assessment_id: item.id,
      enterprise_id: state?.data?.id,
    })
      .then((res) => window.open(res.data.url))
      .catch((err) => console.log(err));
  };

  const convertExcel = (assessmentResult, item) => {
    debugger;
    const fileName = `${item.name}_Report.xlsx`;
    const ws = utils.json_to_sheet(assessmentResult.myself);
    const ws2 = utils.json_to_sheet(assessmentResult.someone_else);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Myself");
    assessmentResult.someone_else.length &&
      utils.book_append_sheet(wb, ws2, "Someone Else");

    assessmentResult.someone_else.length || assessmentResult.myself.length
      ? writeFile(wb, fileName)
      : toast.error("No data Available !");
  };

  const tHead = ["date", "Subscription Name", "PDF Report", "Excel Report"];
  const tBodyData = subscriptionData?.map((item) => {
    const { created_at, name, assessment_image } = item;

    const assessments_name = (
      <div style={{ display: "inline-flex", alignItems: "center", gap: "5px" }}>
        <Avatar
          style={{
            width: "28px",
            height: "28px",
            border: "1px solid #DADADA",
          }}
          src={assessment_image || defaultImage}
        />
        <p>{name}</p>
      </div>
    );
    const date = moment(created_at).format("DD/MM/YYYY");

    return {
      date,
      // id,
      assessments_name,
      actionPdf: (
        <div
          style={{ cursor: "not-allowed", display: "inherit", opacity: "0.5" }}
          onClick={() => {
            handlePdfDownload(item);
          }}
        >
          <FaFilePdf color="#dd2121cc" size={24} />
        </div>
      ),
      actionExcel: (
        <div
          style={{ cursor: "pointer", display: "contents" }}
          onClick={() => {
            handledownload(handleExport, item);
          }}
        >
          <FaFileExcel color="green" size={24} />
        </div>
      ),
    };
  });

  return (
    <>
      <CustomTable
        tHead={tHead}
        tBody={tBodyData}
        message={"No Reports is available for this enterprise."}
      />
    </>
  );
};
export default SubscriptionReports;
