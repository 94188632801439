import React, { createRef, useEffect, useState } from "react";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { FiUploadCloud } from "react-icons/fi";
import classes from "../../assets/css/Fields.module.css";

const DragNDrop = ({
  TypographyLabel,
  placeholder,
  getImage,
  imagelogo,
  setImageSize,
}) => {
  const [image, setImage] = useState(null);

  const dropzoneRef = createRef();
  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  useEffect(() => {
    setImage(imagelogo);
    // getImage(imagelogo);
  }, [imagelogo]);

  const onDrop = useCallback((acceptedFiles) => {
    setImage(acceptedFiles[0].path);
    getImage(acceptedFiles[0]);
    setImageSize && setImageSize(acceptedFiles[0].size);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png", ".jpg", ".jpeg", ".svg", "svg+xml", "tiff"],
    },
  });

  return (
    <>
      <Typography marginLeft="20px" variant="h9" gutterBottom component="div">
        {TypographyLabel}
      </Typography>
      <div
        className={classes.FilesDragAndDropArea}
        style={{ textAlign: "-webkit-center" }}
      >
        <div className="container">
          <div {...getRootProps({ className: "dropzone" })}>
            {/* <input onChange={() => getInputProps()} /> */}
            <input {...getInputProps()} />
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              style={{ textAlign: "center" }}
            >
              <FiUploadCloud style={{ color: "#00b091" }} />
              Drag & drop your file here
            </Typography>
            <Typography
              variant="button"
              display="block"
              gutterBottom
              style={{ textAlign: "center" }}
            >
              OR
            </Typography>

            <Button
              variant="outlined"
              color="success"
              onClick={openDialog}
              style={{ marginLeft: "0px" }}
            >
              Choose File
            </Button>
          </div>
          <aside>
            <ul style={{ listStyle: "none" }}>
              {/* {imagelogo ? (
                <li>
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#000",
                      textAlign: "center",
                    }}
                  >
                    {imagelogo || image}
                  </p>
                </li>
              ) : ( */}

              {image && (
                <li key={image?.path}>
                  <p
                    style={{
                      fontSize: "12px",
                      color: "#000",
                      textAlign: "center",
                    }}
                  >
                    {image}
                  </p>
                </li>
              )}
            </ul>
          </aside>
        </div>
      </div>
    </>
  );
};

export default DragNDrop;
