import React, { useEffect, useState } from "react";
import Facebook from "./Images/facebookIcon.svg";
import Instagram from "./Images/instagramIcon.svg";
import Twitter from "./Images/twitterIcon.svg";
import Youtube from "./Images/youtubeIcon.svg";
import Linkedin from "./Images/linkedinIcon.svg";
export const Footer = ({ styles }) => {
  const [windowSize, setWindowSIze] = useState(false);
  useEffect(() => {
    window.addEventListener("resize", resize);
  });
  const resize = () => {
    if (window.innerWidth <= 760) {
      setWindowSIze(true);
    } else {
      setWindowSIze(false);
    }
  };
  console.log("styles", windowSize);
  return (
    <>
      <div className={styles.footerMain}>
        {windowSize || window.innerWidth <= 760 ? (
          <div style={{ textAlign: "center" }}>
            <div className={styles.footerSec}>
              <div className={styles.footerOne}>
                <h4>
                  <a href="https://www.samvednacare.com/service/dementia-care">
                    DEMENTIA CARE
                  </a>
                </h4>
              </div>
              <div className={styles.footerOne}>
                <h4>
                  <a href="https://www.samvednacare.com/counselling">
                    COUNSELLING
                  </a>
                </h4>
              </div>
              <div className={styles.footerOne}>
                <h4>
                  <a href="https://www.samvednacare.com/book-a-psychological-assessment">
                    PSYCHOLOGICAL ASSESSMENTS
                  </a>
                </h4>
              </div>
              <div className={styles.footerOne}>
                <h4>
                  <a href="https://www.samvednacare.com/community/mental-health">
                    COMMUNITY
                  </a>
                </h4>
              </div>
              <div className={styles.line}></div>
              <div
                style={{ marginTop: "20px" }}
                className={`${styles.footerOne} ${styles.footerTwo}`}
              >
                <h4 style={{ fontWeight: "normal" }}>
                  <a href="https://www.samvednacare.com/about">About Us</a>
                </h4>
              </div>
              <div className={`${styles.footerOne} ${styles.footerTwo}`}>
                <h4 style={{ fontWeight: "normal" }}>
                  <a href="https://www.samvednacare.com/contact-us">
                    Contact Us{" "}
                  </a>
                </h4>
              </div>
              <div className={`${styles.footerOne} ${styles.footerTwo}`}>
                <h4 style={{ fontWeight: "normal" }}>
                  <a href="https://www.samvednacare.com/press-release">
                    Press Releases
                  </a>
                </h4>
              </div>
              <div
                style={{ marginBottom: "30px" }}
                className={`${styles.footerOne} ${styles.footerTwo}`}
              >
                <h4 style={{ fontWeight: "normal" }}>
                  <a href="https://www.samvednacare.com/blog">Blog</a>
                </h4>
              </div>
            </div>

            {/* <div className={styles.footerOne}>
          <h4><a href="https://www.samvednacare.com/team">Team </a></h4>
          <h4><a href="https://www.samvednacare.com/consult-a-doctor">Consult A Doctor</a></h4>
          <h4><a href="https://www.samvednacare.com/career">Career </a></h4>                   
					<h4><a href="https://www.samvednacare.com/gallery">Gallery</a></h4>
					<h4><a href="https://www.samvednacare.com/press-release">Press Releases</a></h4>
					<h4><a href="https://www.samvednacare.org/donate-now" target="_blank">Donate Now</a></h4>
					<h4><a href="https://www.samvednacare.com/contact-us">Contact Us </a></h4>
					<h4><a href="https://www.samvednacare.com/blog">Blog</a></h4>
          </div> */}
          </div>
        ) : (
          <div className={styles.footerSec}>
            <div className={styles.footerOne}>
              <h4>Counselling</h4>
              <ul>
                <li>
                  <a href="https://www.samvednacare.com/counselling/supportive-counselling">
                    Supportive Counselling
                  </a>
                </li>
                <li>
                  <a href="https://www.samvednacare.com/counselling/caregiver-counselling">
                    Caregiver Counselling
                  </a>
                </li>
                <li>
                  <a href="https://www.samvednacare.com/counselling/cancer-and-long-term-illness">
                    Cancer and Long Term Illness
                  </a>
                </li>
                <li>
                  <a href="https://www.samvednacare.com/counselling/relationship-counselling">
                    Relationship Counselling
                  </a>{" "}
                </li>
                <li>
                  <a href="https://www.samvednacare.com/counselling/lifestyle-counselling">
                    Lifestyle Counselling
                  </a>
                </li>
                <li>
                  <a href="https://www.samvednacare.com/counselling/family-counselling">
                    Family Counselling
                  </a>
                </li>
              </ul>
            </div>
            <div className={styles.footerOne}>
              <h4>Dementia Care</h4>
              <ul>
                <li>
                  <a href="https://www.samvednacare.com/service/dementia-care/book-a-dementia-assessment">
                    Book a Dementia Assessment
                  </a>
                </li>
                <li>
                  <a href="https://www.samvednacare.com/service/dementia-care/find-a-dementia-caregiver">
                    Find a Dementia Caregiver
                  </a>
                </li>
                <li>
                  <a href="https://www.samvednacare.com/service/dementia-care/caregiver-enablement">
                    Caregiver Enablement
                  </a>
                </li>
                <li>
                  <a href="https://www.samvednacare.com/service/dementia-care/join-a-support-group">
                    Join a Support Group
                  </a>
                </li>
                <li>
                  <a href="https://www.samvednacare.com/service/dementia-care/dementia-therapies">
                    Dementia Therapies
                  </a>{" "}
                </li>
                <li>
                  <a href="https://www.samvednacare.com/service/dementia-care/medical-consultation">
                    Medical Consultation
                  </a>
                </li>
              </ul>
            </div>
            <div className={styles.footerOne}>
              <h4>About us</h4>
              <ul>
                <li>
                  <a href="https://www.samvednacare.com/about">Overview</a>
                </li>
                <li>
                  <a href="https://www.samvednacare.com/mission-vision">
                    Mission Vision
                  </a>
                </li>
                <li>
                  <a href="https://www.samvednacare.com/testimonials">
                    Testimonials
                  </a>
                </li>
              </ul>
            </div>
            <div className={styles.footerOne}>
              <h4>Our Packages</h4>
              <ul>
                <li>
                  <a href="https://www.samvednacare.com/packages/bliss">
                    Bliss
                  </a>
                </li>
                <li>
                  <a href="https://www.samvednacare.com/packages/health">
                    Health Care
                  </a>
                </li>
                <li>
                  <a href="https://www.samvednacare.com/packages/physiotherapy">
                    Physiotherapy
                  </a>
                </li>
              </ul>
            </div>
            <div className={styles.footerOne}>
              <h4>
                <a href="https://www.samvednacare.com/team">Team </a>
              </h4>
              <h4>
                <a href="https://www.samvednacare.com/consult-a-doctor">
                  Consult A Doctor
                </a>
              </h4>
              <h4>
                <a href="https://www.samvednacare.com/career">Career </a>
              </h4>
              <h4>
                <a href="https://www.samvednacare.com/gallery">Gallery</a>
              </h4>
              <h4>
                <a href="https://www.samvednacare.com/press-release">
                  Press Releases
                </a>
              </h4>
              <h4>
                <a
                  href="https://www.samvednacare.org/donate-now"
                  target="_blank"
                  rel="noreferrer"
                >
                  Donate Now
                </a>
              </h4>
              <h4>
                <a href="https://www.samvednacare.com/contact-us">
                  Contact Us{" "}
                </a>
              </h4>
              <h4>
                <a href="https://www.samvednacare.com/blog">Blog</a>
              </h4>
            </div>
          </div>
        )}

        <div className={styles.followUs}>
          <div className={styles.followUsSec}>
            <h3>Follow us</h3>
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/samvednacare"
                  alt="Facebook"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Facebook} alt="Facebook" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/samvednacare"
                  alt="Instagram"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Instagram} alt="Instagram" />
                </a>
              </li>
              <li>
                {" "}
                <a
                  href="https://twitter.com/SamvednaSeniorC"
                  alt="Twitter"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Twitter} alt="Twitter" />
                </a>
              </li>
              <li>
                {" "}
                <a
                  href="https://www.youtube.com/channel/UC2aT18t9dQ1ZrjXfx_NvqoA"
                  alt="Youtube"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Youtube} alt="Youtube" />
                </a>
              </li>
              <li>
                {" "}
                <a
                  href="https://www.linkedin.com/company/samvedna-senior-care-private-limited"
                  alt="Linkedin"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Linkedin} alt="Linkedin" />
                </a>
              </li>
            </ul>

            <h4>© 2023 Samvedna Care, All Rights Reserved</h4>
          </div>
        </div>
      </div>
      <div className={styles.footerBottom}>
        <p
          style={{
            color: "#ffffff",
            lineHeight: "22px",
            padding: "0 5px",
            textAlign: "left",
          }}
        >
          <b>Disclaimer:</b> Samvedna Care is not a mental health emergency
          service. In case of severe distress or suicidal thoughts, we strongly
          advise you to contact the National Helpline Numbers for Mental Health
          Support at 08046110007.
        </p>
      </div>
    </>
  );
};
