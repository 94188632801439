import { useEffect, useState } from "react";
import { CustomTable } from "../../../../Component/UI/Table";
import { getSelfAssessment } from "../../../../services/admin/patient";
import { Link } from "react-router-dom";
import { Avatar } from "../../../../Component/UI/Avatar";
import { formatttedDate } from "../../../../config/formattedDate";
import styles from "./selfassessment.module.css";

export const Completed = ({ id }) => {
  const [completed, setCompleted] = useState([]);

  const tHead = [
    "Assessment Date",
    "Assessment Name",
    "Total Score",
    "Time Taken",
  ];

  useEffect(() => {
    getSelfAssessment(id, "completed").then((res) =>
      setCompleted(res?.data?.list)
    );
  }, []);

  const color = (level) => {
    switch (level) {
      case 1:
        return "#18BA4B";
      case 2:
        return "#2A2A2A";
      case 3:
        return "#EB0000";
      default:
        return "#2A2A2A";
    }
  };

  const tBodyData = completed?.map(({ name, assessment_image, customer }) => {
    if (!customer) return {};
    const assessmentData = JSON.parse(customer?.assessment_data);
    return {
      date: (
        <p className={styles.tableData}>
          {formatttedDate(customer?.updated_at)}
        </p>
      ),
      name: (
        <Link
          to="questions"
          style={{ color: "#2A2A2A", textDecoration: "none" }}
          state={{ name, data: assessmentData, bmi: customer?.bmi_score }}
        >
          <span className={styles.avatar}>
            {<Avatar size="28px" profile={assessment_image} />}
            <span className={styles.tableData} style={{ marginLeft: "8px" }}>
              {name}
            </span>
          </span>
        </Link>
      ),
      score: <p style={{ color: color(customer?.level) }}>{customer?.score}</p>,
      time: <p className={styles.tableData}>{customer?.time_taken}</p>,
    };
  });

  return (
    <CustomTable
      tHead={tHead}
      tBody={tBodyData}
      message={"This patient has not completed any assessment yet."}
    />
  );
};
