import React, { useEffect } from "react";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";
import { ServicesCards } from "../../../Component/UI/ServicesCard/ServicesCard";
import { ServicesCard } from "./ServicesCard";
import { useState } from "react";
import classes from "./join.module.css";
import NoServices from "./NoJoinSupport";
import NoJoinSupport from "./NoJoinSupport";
import axios from "../../../config/axiosConfig";
import {
  ToastBar,
  Toaster,
} from "../../../../node_modules/react-hot-toast/dist/index";

export const JoinSupport = () => {
  const [data, setData] = useState();
  const [isLoading, setLsLoading] = useState(false);
  const [allData, setAllData] = useState();
  useEffect(() => {
    setLsLoading(true);
    axios
      .get("/customer/get/joinCommudity/service")
      .then((res) => {
        setData(res.data.list);
        setAllData(res.data.list);
        setLsLoading(false);
      })
      .catch((err) => {
        setLsLoading(false);
        console.log(err, "error");
      });
  }, []);
  return (
    <>
      <Toaster />
      {isLoading ? (
        <div style={{ textAlign: "center", marginTop: "40px" }}>
          <CircularProgress size={30} />
          <p style={{ color: "black" }}>
            Please wait ! We are validating the details...
          </p>
        </div>
      ) : (
        <div className={classes.service_card}>
          {allData?.length ? (
            allData?.map((i) => {
              return (
                <ServicesCard
                  data={i}
                  allDataList={allData}
                  // status={finalAssessmentStatus}
                  // select={select}
                  // enterpriseId={enterpriseId}
                  // selectEnterPrise={selectEnterPrise}
                />
              );
            })
          ) : (
            <NoJoinSupport />
          )}
        </div>
      )}
    </>
  );
};
