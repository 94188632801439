import React from "react";
import {
  Input,
  SelectCard,
  RadioChoice,
  Select,
} from "../../../../Component/UI/CustomInputs";
// import { SelectCard } from "../../../UI/SelectCard";
import styles from "./subscription.module.css";
import ReactSelect from "react-select";
import { useEffect } from "react";
import { getPatientList } from "../../../../services/admin/subscriptions";
import { useState } from "react";
import { id } from "date-fns/locale";

export const SubscriptionDetails = ({
  handleChange,
  enterpriseOptions,
  subscriptionData,
  buisnessOptions,
  flag,
  error,
  edit,
  expiryDate,
  patientEmail,
  setPatientEmail,
  setEmailError,
  emailError,
  selectedPatient,
  patientsList,
  setSelectedPatient,
}) => {
  return (
    <div className={styles.details}>
      <div>
        <Input
          name="name"
          label="Name"
          placeholder="Name"
          width="250px"
          error={error?.name}
          onChange={handleChange}
          value={subscriptionData?.name}
          disabled={expiryDate}
          req
        />

        <SelectCard
          label="User Type"
          labelstyle={{ width: 120 }}
          titleMargin="0 0 10px 0"
          options={["General", "Enterprise"]}
          error={error?.subscription_type?.[0]}
          value={subscriptionData?.subscription_type}
          name="subscription_type"
          onChange={(e) => {
            setSelectedPatient({});
            setPatientEmail("");
            handleChange(e);
          }}
          flag={flag}
          disabled={edit}
        />
      </div>
      <div>
        {subscriptionData?.subscription_type !== "0" ? (
          <Select
            name="enterprise_id"
            label="Enterprise Name"
            width="250px"
            req={subscriptionData?.subscription_type !== "0"}
            options={enterpriseOptions}
            onChange={handleChange}
            error={error?.enterprise_id}
            value={subscriptionData?.enterprise_id}
            disabled={expiryDate || subscriptionData?.subscription_type === "0"}
          />
        ) : (
          <div>
            <label>
              Patient Name<span style={{ color: "#D3232F" }}>*</span>
            </label>
            <ReactSelect
              className="basic-single"
              classNamePrefix="select"
              name="customer_id"
              value={selectedPatient}
              options={patientsList}
              isDisabled={expiryDate}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  marginTop: 5,
                  width: 250,
                  height: 40,
                }),
              }}
              onChange={(data) => {
                const e = {
                  target: {
                    name: "customer_id",
                    value: data.value,
                  },
                };
                setSelectedPatient(data);
                handleChange(e);
                setPatientEmail(data?.email ? data?.email : "");
              }}
            />
            <p style={{ color: "red", margin: "5px", width: 250 }}>
              {error?.customer_id?.toString()}
            </p>
          </div>
        )}
        <Select
          name="business_segment"
          label="Business Segment"
          width="250px"
          onChange={handleChange}
          error={error?.business_segment}
          options={buisnessOptions}
          value={subscriptionData?.business_segment}
          disabled={expiryDate}
          req
        />
      </div>
      <div>
        <Select
          name="validity"
          label="Validity"
          width="250px"
          error={error?.validity}
          onChange={handleChange}
          value={subscriptionData?.validity}
          options={[
            { id: 7, value: "7 Days" },
            { id: 15, value: "15 Days" },
            { id: 30, value: "1 Month" },
            { id: 60, value: "2 Months" },
            { id: 90, value: "3 Months" },
            { id: 180, value: "6 Months" },
            { id: 270, value: "9 Months" },
            { id: 360, value: "12 Months" },
          ]}
          disabled={expiryDate}
          req
        />
        {subscriptionData?.subscription_type === "0" ? (
          <Input
            error={error?.email}
            label="Patient Email"
            width="250px"
            value={patientEmail}
            disabled
          />
        ) : null}
      </div>
    </div>
  );
};
