import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { Search } from "../Component/UI/Search";
import { Calender } from "../Component/UI/Calender";
import { Filter } from "../Component/UI/Filter/Filter";
import { Exports } from "../Component/UI/Export/Export";
import CustomizedButtons from "../Component/UI/Button/CustomizeButton";

import { Link } from "react-router-dom";
import { TEXT } from "../Samvenda-Admin/contant/constant";

export const SubHeader = ({
  text,
  children,
  route = "",
  basic,
  noPaddingTop,
}) => {
  return (
    <Grid container spacing={2} style={{ margin: "15px 0px", width: "100%" }}>
      <Grid
        item
        xs={basic ? 6 : 2.7}
        style={noPaddingTop ? { paddingTop: 0 } : null}
      >
        <Typography variant="h4" component="div" gutterBottom>
          {text}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        {!basic && <Search value={"Search"} />}
      </Grid>
      <Grid item xs={3}>
        <Calender />
      </Grid>
      {!basic && (
        <>
          <Grid item xs={1}>
            <Filter />
          </Grid>
          <Grid item xs={1}>
            <div>
              <Exports />
            </div>
          </Grid>
        </>
      )}
      {text !== TEXT.SELFCARE && (
        <Grid
          item
          xs={1}
          style={{ marginLeft: "1%", display: basic ? "none" : "block" }}
        >
          <Link
            to={route}
            style={{ textDecoration: "none" }}
            state={{ id: 0, value: "flag", cid: 0 }}
          >
            <CustomizedButtons ButtonLabel={"Add"} />
          </Link>
        </Grid>
      )}
      {children}
    </Grid>
  );
};
