import { useEffect, useMemo } from "react";
import { Box } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { grey } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import SwitchButton from "../../../Component/UI/SwitchButton/Switch";
import { Link } from "react-router-dom";
import {
  fetchQuickAsk,
  todayActivitySelector,
} from "../../../Redux/Slices/TodayActivitySlice";
import { API } from "../../../config/constant";
import instance from "../../../config/axiosConfig";
import { Edit } from "../../../assets/icons/Edit";
import {
  setFilter,
  setFrom_date,
  setSearch_key,
  setTo_date,
  setClear,
} from "../../../Redux/Slices/AdminFilters/Search";

const TodayActivity = ({ permissions }) => {
  const dispatch = useDispatch();
  const { search_key, from_date, to_date, filter } = useSelector(
    (i) => i.SearchFilters
  );

  const { todayActivity } = useSelector(todayActivitySelector);
  const fullpermissions = +permissions?.listControl?.access?.["Full Control"];
  const writePermissions = +permissions?.listControl?.access?.Write;

  useEffect(() => {
    dispatch(fetchQuickAsk(search_key, from_date, to_date));
  }, [dispatch, filter]);

  useEffect(() => {
    return () => {
      // dispatch(setSearch_key(""));
      dispatch(setTo_date(""));
      dispatch(setFrom_date(""));
      dispatch(setFilter());
      dispatch(setClear());
    };
  }, []);

  const SwitchHandler = (e, params) => {
    const on = params.row.status === 1 ? 0 : 1;
    try {
      instance
        .get(
          `${API.STATUSTODAYACTIVITY}?category_id=${params.row.id}&status=${on}`
        )
        .then((res) => {
          res.data.status === true && dispatch(fetchQuickAsk());
        });
    } catch (error) {
      console.log(error, "error");
    }
  };

  const columns = useMemo(() => [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        <Link
          to={"/todayactivityquestion"}
          state={{ value: params.row.value, cid: params.row.id, permissions }}
          style={{ color: "black", textDecoration: "none" }}
        >
          {params.value}
        </Link>
      ),
    },
    { field: "num_of_questions", headerName: "Questions", flex: 1 },
    {
      field: "category_date",
      headerName: "Date",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      type: "boolean",
      hide: fullpermissions === 0 ? true : false,
      renderCell: (params) => (
        <SwitchButton
          onClick={(e) => SwitchHandler(e, params)}
          status={params.row.status === 1 ? true : false}
        />
      ),
    },
    {
      field: "is_deleted",
      headerName: "Actions",
      type: "actions",
      flex: 1,
      hide: writePermissions === 0 ? true : false,
      renderCell: (params) => (
        <Link
          to={"/todayactivityquestion"}
          state={{ value: params.row.value, cid: params.row.id }}
          style={{ color: "black", textDecoration: "none" }}
        >
          <Edit style={{ width: "25px", heigth: "25px" }} />
        </Link>
      ),
    },
  ]);

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
      }}
    >
      <DataGrid
        columns={columns}
        rows={todayActivity}
        sx={{
          backgroundColor: "#fff",

          [`& .${gridClasses.row}`]: {
            bgcolor: (theme) =>
              theme.palette.mode === "light" ? "#fff" : grey[100],
          },
        }}
      />
    </Box>
  );
};

export default TodayActivity;
