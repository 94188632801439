import React, { useEffect, useState } from "react";
import { CustomTable } from "../../../Component/UI/Table";
import SwitchButton from "../../../Component/UI/SwitchButton/Switch";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { YtoD } from "../../../config/formattedDate";
import { Edit } from "../../../assets/icons/Edit";
import { patientGet, patientStatus } from "../../../services/admin/patient";
import { getPatients } from "../../../Redux/Slices/getPatients";
import { Avatar } from "../../../Component/UI/Avatar";
import { useLocation } from "react-router-dom";
import moment from "moment";
import {
  setFilter,
  setFrom_date,
  setSearch_key,
  setTo_date,
  setClear,
} from "../../../Redux/Slices/AdminFilters/Search";
import { findFunc } from "../../../config/methods";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";

const PatientTable = ({ permissions }) => {
  const { data } = useSelector((i) => i.getPatients);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const activeStatus = state?.status;
  const [isLoading, setIsloading] = useState(false);
  const fullpermissions = +permissions?.listControl?.access?.["Full Control"];
  const writePermissions = +permissions?.listControl?.access?.Write;
  const tHead = [
    "ID",
    "Client Name",
    "Mobile",
    "Email",
    "Enterprise Name",
    "Date of Birth",
    "Onboarding Date",
    ...(fullpermissions ? ["Status"] : []),
    ...(writePermissions ? ["Action"] : []),
  ];

  const patientsProfileGeneralPerms = findFunc(
    permissions?.subCategories,
    "title",
    "general"
  );

  const findName = findFunc(
    patientsProfileGeneralPerms?.list,
    "listName",
    "Patient Profile"
  );

  const { filter, search_key, from_date, to_date } = useSelector(
    (state) => state.SearchFilters
  );

  useEffect(() => {
    setIsloading(true);
    patientGet(search_key, from_date, to_date).then(
      (i) => (setIsloading(false), dispatch(getPatients(i)))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    return () => {
      // dispatch(setSearch_key(""));
      dispatch(setFrom_date(""));
      dispatch(setTo_date(""));
      dispatch(setFilter());
      dispatch(setClear());
    };
  }, []);

  const allowLinkClick = permissions?.subCategories?.some((k) =>
    Boolean(!!k.status)
  );
  const tBodyData = data?.map(
    (
      {
        name,
        email,
        mobile,
        customer_image,
        dob,
        id,
        status,
        customer_number,
        created_at,
        enterprise,
        consent_at,
        is_consent,
      },
      i
    ) => {
      const currentDate = moment().format("MM");
      if (activeStatus === "active" && status !== 1) return {};
      if (activeStatus === "inactive" && status !== 0) return {};
      if (state?.wellness && !state?.data.includes(id)) return {};
      if (
        activeStatus === "birthday" &&
        moment(dob).format("MM") !== currentDate
      )
        return {};
      const date = YtoD(dob);
      const onboarding = created_at
        ? moment(created_at).format("DD/MM/YYYY")
        : "Not Available";
      return {
        id: `SC${id}`,
        name: (
          <Link
            to="/patientsProfile"
            style={{
              color: "#000",
              textDecoration: "none",
              pointerEvents: allowLinkClick ? "" : "none",
            }}
            state={{
              props: id,
              name: name,
              permissions,
              consent_at,
              email,
              mobile,
              is_consent,
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                height: 0,
              }}
            >
              {
                <Avatar
                  size="28px"
                  profile={customer_image}
                  border="1px solid #ccc"
                />
              }
              <span style={{ marginLeft: "8px" }}>{name}</span>
            </span>
          </Link>
        ),
        mobile,
        email,
        enterprise: enterprise?.company_name,
        date,
        onboarding,
        ...(fullpermissions && {
          status: (
            <>
              <SwitchButton
                disabled={!fullpermissions}
                status={status}
                onClick={(value) => patientStatus(id, value)}
              />
            </>
          ),
        }),
        ...(writePermissions && {
          action: (
            <>
              <Link
                to="patientProfile"
                style={{
                  color: "#000",
                  pointerEvents: !writePermissions ? "none" : "",
                }}
                state={{ props: id, permissions: findName }}
              >
                <Edit />
              </Link>
            </>
          ),
        }),
      };
    }
  );

  return !isLoading ? (
    <CustomTable tHead={tHead} tBody={tBodyData} />
  ) : (
    <div className="loader" style={{ textAlign: "center", margin: "60px" }}>
      <CircularProgress size={28} />
    </div>
  );
};

export default PatientTable;
