import React from "react";
import AssessmentCardStyles from "../../../assets/css/assessmentCard.module.css";
import Button from "@mui/material/Button";
import Appointment from "../../../assets/icons/icon/calenderIcon.svg";
import { formatttedDate } from "../../../config/formattedDate";

const PlansCard = (props) => {
  const { disableButton, handleClick, data, isLoadingAss } = props;
  console.log(disableButton, "disableButton0000");
  return (
    <>
      <div className={`${AssessmentCardStyles.card_assessment_plan}`}>
        <div
          className={AssessmentCardStyles.flipCard_plan}
          style={{ marginBottom: "70px" }}
        >
          <div className={AssessmentCardStyles.flipCard_inner}>
            <div className={AssessmentCardStyles.flipCard_front}>
              <div className={AssessmentCardStyles.img_icon_div}>
                <img
                  className={AssessmentCardStyles.img_icon}
                  src={data?.assessments?.assessment_image}
                  alt={data?.assessments?.name}
                  style={{ width: "100px", height: "100px" }}
                />
              </div>
              <div className={AssessmentCardStyles.card_title_box}>
                <p className={AssessmentCardStyles.card_title}>
                  {data?.assessments?.name}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "16px",
          }}
        >
          &nbsp;&nbsp;&nbsp;
          <div style={{ display: "flex", alignItems: "center" }}>
            <>
              <span style={{ marginRight: "5px" }}>
                <img src={Appointment} alt="calender" />
              </span>
              <span style={{ fontSize: "12px", color: "#2a2a2a" }}>
                {formatttedDate(
                  data?.assessments?.updated_at || new Date()
                ).replaceAll("/", "-")}
              </span>
            </>
          </div>
        </div>

        <div className={AssessmentCardStyles.card_bottom_info}>
          <div className={AssessmentCardStyles.card_btn_box}>
            {
              <Button
                disabled={disableButton}
                variant="contained"
                size="small"
                style={{
                  textTransform: "none",
                  width: "100%",
                  backgroundColor: disableButton ? "#bbb7b7" : "#7A51AE",
                  borderRadius: "8px",
                  textAlign: "center !important",
                }}
                onClick={handleClick}
              >
                {disableButton ? "Assessment Taken" : "Take Assessment"}
              </Button>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default PlansCard;
