import React, { useEffect, useState } from 'react'
import { BetterAccordion } from '../../../../Component/UI/BetterAccordion/BetterAccordion';
import styles from "../subscriptions.module.css";
import SummaryService from './SummaryService';

function ViewSubscriptionServices({data}) {
    const [expanded, setExpanded] = React.useState(
        `panel${data.length - 1}`
      );
    
    console.log(data, '000+++')
    
      useEffect(() => {
        setExpanded(`panel${data.length - 1}`);
      }, [data.length]);
    
      const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
      };
  return (
    <>
     <div
        className={styles.services}
        style={{ width: "75vw", height: "380px", overflowY: "scroll" }}
      >
        {data.map((service, i) => (
        
          <div style={{ margin: "8px" }}>
            <BetterAccordion
              heading={`Service ${i + 1} ${service.service_name ? "-" : ""} ${
                service.service_name || ""
              }`}
              headingStyle={{ fontWeight: 500 }}
              i={i}
              expanded={expanded}
              handleChange={handleChange}
            >
            <SummaryService data = {data[i]}/>
            </BetterAccordion>
          </div>
        ))}
      </div>
    </>
  )
}

export default ViewSubscriptionServices