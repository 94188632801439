import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { Search } from "../Component/UI/Search";
import { Calender, CalenderApp } from "../Component/UI/Calender";
import { Filter } from "../Component/UI/Filter/Filter";
import { Exports } from "../Component/UI/Export/Export";
import CustomizedButtons from "../Component/UI/Button/CustomizeButton";
import styles from "./Text_Seach_Filter.module.css";

import { Link } from "react-router-dom";
import { TEXT } from "../Samvenda-Admin/contant/constant";
import { read, utils, writeFile } from "xlsx";
import toast, { Toaster } from "react-hot-toast";

const Text_Seach_Filter_Export = ({
  text,
  children,
  route = "",
  ApiData,
  searchText,
  resetMargin,
  permissions,
  appointment,
}) => {
  const [apiData, setApiData] = useState();
  useEffect(() => {
    setApiData(ApiData);
  }, [ApiData]);

  const handleExport = () => {
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(apiData);
    // utils.sheet_add_aoa(ws, headings);
    // utils.sheet_add_json(ws, ApiData, { origin: "A2", skipHeader: true });
    utils.book_append_sheet(wb, ws, "Report");
    writeFile(wb, `${text}.xlsx`);
  };

  const disappearAddBtn =
    text !== TEXT.SELFCARE &&
    text !== TEXT.QUICK &&
    text !== TEXT.TODAYACT &&
    text !== "Payments" &&
    text !== "Feedbacks" &&
    text !== "Testimonials";

  const permission = permissions?.access;
  const permissionCheck = +permission?.Write;
  const fullControlCheck = +permission?.["Full Control"];
  const disableWrite = permissionCheck === 0 || "";
  const enableWrite = permissionCheck === 1 || "";
  const enableFullControl = fullControlCheck === 1 || "";
  const visibilityStyle = appointment
    ? enableFullControl
      ? ""
      : "hidden"
    : disableWrite
    ? "hidden"
    : "";

  return (
    <>
      <Toaster />
      <Grid
        container
        spacing={2}
        style={{
          margin: resetMargin ? "0" : "15px 0px",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Grid
          item
          sm={6}
          md={!disappearAddBtn ? 4 : 2.5}
          className={styles.typography}
          style={{ margin: "10px 0" }}
        >
          <Typography
            variant="h4"
            component="div"
            gutterBottom
            style={{ fontSize: "24px", fontWeight: "500", lineHeight: "24px" }}
          >
            {text}
          </Typography>
        </Grid>
        <Grid item className={styles.search} sm={12} md={2.5}>
          <Search value={searchText || "Search"} />
        </Grid>
        <Grid item className={styles.typography} sm={3} md={2.5}>
          {text === "Appointments" ? (
            <CalenderApp width="100%" />
          ) : (
            <Calender width="100%" />
          )}
        </Grid>
        <Grid item className={styles.filter} sm={3} md={1.5}>
          <Filter />
        </Grid>

        <Grid
          className={styles.exports}
          style={{ visibility: visibilityStyle ? "hidden" : "" }}
          item
          sm={3}
          md={1.5}
        >
          <Exports disabled={visibilityStyle} click={handleExport} />
        </Grid>

        {route && (
          <Grid
            className={styles.addButton}
            item
            xs={3}
            md={1}
            style={{
              marginLeft: "1%",
              visibility: visibilityStyle,
            }}
          >
            <Link
              to={route}
              style={{
                visibility: visibilityStyle,
                pointerEvents: visibilityStyle ? "none" : "",
                textDecoration: "none",
              }}
              state={{ id: 0, value: "flag", cid: 0 }}
            >
              <CustomizedButtons
                disabled={visibilityStyle}
                width="100px"
                height="36px"
                ButtonLabel={"Add"}
              />
            </Link>
          </Grid>
        )}
        {children}
      </Grid>
    </>
  );
};

export default Text_Seach_Filter_Export;
