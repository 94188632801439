import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const {
  REGISTER_ENTERPRISE,
  ENTERPRISE_LOGIN_DATA,
  LOGIN_ENTERPRISE,
  EMPLOYEE_VERIFICATION,
  OTP_VERIFICATION,
  FORGOT_PASSWORD_ENTERPRISE,
  VERIFY_ACCOUNT_PAGE,
  GET_ASSESSMENT_DETAILS,
  ENTERPRISE_ASSESSMENT_SAVE,
  GET_UNMAPPED_ASSESSMENTS,
  GET_UNMAPPED_SERVICES,
  GET_UNMAPPED_TESTIMONIALS,
  CREATE_ENTERPRISE_EMPLOYEE,
} = API;

export const registerEnterprise = async (data) => {
  return await axios
    .post(REGISTER_ENTERPRISE, data)
    .then((res) => res)
    .catch((err) => err);
};

export const enterpriseData = async (data) => {
  return await axios
    .get(ENTERPRISE_LOGIN_DATA + data)
    .then((res) => res)
    .catch((err) => err);
};

export const loginEnterprise = async (data) => {
  return await axios
    .post(LOGIN_ENTERPRISE, data)
    .then((res) => res)
    .catch((err) => err);
};

export const forgotEnterprisePassword = async (data) => {
  return await axios
    .post(FORGOT_PASSWORD_ENTERPRISE, data)
    .then((res) => res)
    .catch((err) => err);
};

export const employeeVerification = async (data) => {
  return await axios
    .post(EMPLOYEE_VERIFICATION, data)
    .then((res) => res)
    .catch((err) => err);
};

export const createEmployee = async (data) => {
  return await axios
    .post(CREATE_ENTERPRISE_EMPLOYEE, data)
    .then((res) => res)
    .catch((err) => err);
};

export const otpVerification = async (data) => {
  return await axios
    .post(OTP_VERIFICATION, data)
    .then((res) => res)
    .catch((err) => err);
};

export const employeeVerificationPage = async (id) => {
  return await axios
    .get(`${VERIFY_ACCOUNT_PAGE}/${id}`)
    .then((res) => res)
    .catch((err) => err);
};

export const EnterpriseAssessmentDetails = async (data) => {
  return await axios
    .post(GET_ASSESSMENT_DETAILS, data)
    .then((res) => res)
    .catch((err) => err);
};

export const EnterpriseAssessmentSave = async (data) => {
  return await axios
    .post(ENTERPRISE_ASSESSMENT_SAVE, data)
    .then((res) => res)
    .catch((err) => err);
};

export const getUnmappedAssessments = async () => {
  return await axios
    .get(GET_UNMAPPED_ASSESSMENTS)
    .then((res) => res)
    .catch((err) => err);
};

export const getUnmappedServices = async () => {
  return await axios
    .get(GET_UNMAPPED_SERVICES)
    .then((res) => res)
    .catch((err) => err);
};

export const getUnmappedTestimonials = async () => {
  return await axios
    .get(GET_UNMAPPED_TESTIMONIALS)
    .then((res) => res)
    .catch((err) => err);
};

export const getGeneralService = async () => {
  return await axios
    .get(`/customer/general/service`)
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const getTestimonials = async () => {
  return await axios
    .get(`/customer/testimonial?status=2`)
    .then((res) => res)
    .catch((err) => console.log(err));
};
