import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function ControlledOpenSelect({
  inputLabel,
  width,
  placeholder,
  data,
  selected,
  option,
}) {
  console.log(selected, "selected");
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState(option);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        setOptions([...data]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <>
      <Typography variant="h9" gutterBottom component="div">
        {inputLabel}
      </Typography>
      <Autocomplete
        id="asynchronous-demo"
        sx={{
          width: `${width}`,
          "& input": {
            height: "20px",
          },
        }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.text === value.text}
        getOptionLabel={(option) => option.text}
        options={options || selected}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder || selected}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress
                      color="inherit"
                      size={20}
                      sx={{ height: "30px" }}
                    />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
}
