import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import { Info } from "../Samvenda-Admin/UI/Info";
import { TEXT } from "../Samvenda-Admin/contant/constant";
import { findFunc } from "../config/methods";

const TextWithCard = ({ textData, cardData, permissions }) => {
  const navigate = useNavigate();
  const path = `/Patients/patientProfile`;
  const location = useLocation();
  const { props, name, data, consent_at, is_consent, mobile, email } =
    location.state;

  const CardHandler = (linkText, findName) => {
    linkText !== TEXT.PATIENTPROFILECARDDISABLED &&
      navigate(linkText, {
        state: {
          props: props || data?.props,
          name: name || data?.name,
          permissions: findName,
          consent_at: consent_at,
          is_consent: is_consent,
          email: email,
          mobile: mobile,
        },
      });
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} style={{ marginTop: 25 }}>
        <Typography
          variant="h6"
          component="div"
          gutterBottom
          style={{ marginBottom: "-0.55em" }}
        >
          {textData}
        </Typography>
      </Grid>
      {cardData?.map((resp, id) => {
        const findName = findFunc(permissions?.list, "listName", resp?.label);
        const findReadPerms = findName?.access?.Read;
        return (
          <>
            {+findReadPerms ? (
              <Grid
                item
                xs={4}
                md={3}
                style={{
                  marginBottom: "10px",
                }}
                key={id}
                columns={{ xs: 1, sm: 1, md: 12 }}
              >
                <Info
                  key={resp}
                  text={resp.label}
                  link={resp.link}
                  onClick={() => CardHandler(resp.link, findName)}
                />
              </Grid>
            ) : null}
          </>
        );
      })}
    </Grid>
  );
};

export default TextWithCard;
