import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomTable } from "../../../Component/UI/Table";
import { NavLink, Link } from "react-router-dom";
import { TbEdit } from "react-icons/tb";
import { AiOutlinePrinter, AiOutlineDownload } from "react-icons/ai";
import { Edit } from "../../../assets/icons/Edit";
import { useEffect, useState } from "react";
import { assessmentData } from "../../../Redux/Slices/AssessmentTableReducer";
import axios from "../../../config/axiosConfig";
import moment from "moment";
import {
  setFilter,
  setFrom_date,
  setSearch_key,
  setTo_date,
  setClear,
} from "../../../Redux/Slices/AdminFilters/Search";
import { setPaymentData } from "../../../Redux/Slices/Payments/PaymentSlices";
import { Download } from "../../../assets/icons/Download";
import { Print } from "../../../assets/icons/Print";
import { useLocation } from "../../../../node_modules/react-router-dom/index";

export const Failed = ({ permissions }) => {
  const fullpermissions = +permissions?.listControl?.access?.["Full Control"];
  const writePermissions = +permissions?.listControl?.access?.Write;
  const dispatch = useDispatch();
  const { search_key, from_date, to_date, filter } = useSelector(
    (i) => i.SearchFilters
  );
  const { paymentData } = useSelector((state) => state.PaymentSlices);
  const { state } = useLocation();
  const customerId = state?.props || "";
  const [isLoadingData, setIsLoadingData] = useState(false);

  const getTableData = async () => {
    setIsLoadingData(true);
    try {
      const response = await axios.get(
        `/admin/payment?status=failed&customer_id=${customerId}&search_key=${search_key}&fromdate=${from_date}&todate=${to_date}`
      );
      if (response.data.status) {
        setIsLoadingData(false);
        dispatch(setPaymentData(response.data.list));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTableData();
  }, [filter]);

  const tHead = [
    "Transaction ID",
    "Date",
    "Amount",
    "Patient Name",
    "Mode",
    ...(writePermissions ? ["Action"] : []),
  ];
  const [active, setActive] = useState(false);
  useEffect(() => {
    getTable();
  }, []);
  const getTable = async () => {
    try {
      const response = await axios.get("/admin/assessment/category");
      dispatch(assessmentData(response.data.list));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setSearch_key(""));
      dispatch(setTo_date(""));
      dispatch(setFrom_date(""));
      dispatch(setFilter());
      dispatch(setClear());
    };
  }, []);

  const tBodyData = paymentData?.map(
    ({
      // assessment_image,
      payment_id,
      created_at,
      amount,
      customer,
      mode,
      payment_done,
    }) => {
      return {
        payment_id,
        created_at: moment(created_at).format("DD-MM-YYYY"),
        amount,
        customer: customer ? customer?.name : "No Name",
        mode: <span>{"failed"}</span>,
        ...(writePermissions && {
          action: (
            <div style={{ cursor: "pointer" }}>
              <Download color="#2A2A2A" size={20} />
              <Print />
            </div>
          ),
        }),
      };
    }
  );

  console.log({ tBodyData });

  return (
    <div>
      <CustomTable
        isLoadingData={isLoadingData}
        tHead={tHead}
        tBody={tBodyData}
      />
    </div>
  );
};
