import React, { useContext } from "react";
import AssessmentStyles from "../../assets/css/assessment.module.css";
import AssessmentTypeStyles from "../../assets/css/assessmentType.module.css";

import { Header } from "./Header";
import { Instructions } from "./Instruction";
import Close from "../../assets/icons/icon/x.svg";
import axios from "../../config/axiosConfig";
import { useDispatch, useSelector } from "react-redux";
import {
  qaStore,
  setAssessment_for,
  setCategoryId,
  setHandleNext,
  setTimetaken,
  setNumberOfQues,
  AddData,
  resetQaStorage,
  selOption,
  selScore,
  setRetakeType,
} from "../../Redux/Reducers/PatientReducers/qa_storage/qa_storage";
import { useNavigate } from "react-router-dom";

import { API } from "../../config/constant";
import { useEffect } from "react";
import AuthContext from "../../Auth/AuthContext";

export const AssessmentWrapper = (props) => {
  const {
    start,
    data,
    user,
    setStart,
    setCategoryList,
    getRelativeData,
    enterprise,
  } = props;

  const {
    category_id,
    qaData,
    numberOfQues,
    Timetaken,
    assessment_for,
    relation,
    relationName,
    relation_id,
    retakeType,
  } = useSelector((state) => state.qa_storage);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { UPDATEASSESSMENTRESULT, ALLASSESSMENT } = API;

  const fetchCategories = async () => {
    await axios
      .get(ALLASSESSMENT)
      .then((res) => {
        setCategoryList(res.data.list);
      })
      .catch((err) => console.error(err));
  };

  const handleClose = (value) => {
    if (numberOfQues === "number") {
      setStart(false);
      dispatch(setHandleNext(true));
    } else {
      if (assessment_for === "someone_else") {
        qaData.length >= 2 ? SubmitAssessment() : dispatch(resetQaStorage());
      } else {
        SubmitAssessment();
      }
      setStart(false);
      dispatch(setHandleNext(true));
    }
  };

  const SubmitAssessment = () => {
    axios
      .post(UPDATEASSESSMENTRESULT, {
        assessment_id: category_id,
        result: qaData,
        status: "inprogress",
        percentage: (qaData.length / Number(numberOfQues)) * 100 || "",
        time_taken: Timetaken,
        assessment_for,
        relation,
        relation_name: relationName,
        relation_id,
        type: retakeType,
      })
      .then((res) => {
        if (res.data.status) {
          navigate("/patient/Assessment");
          // dispatch(qaStore([]));
          // dispatch(setAssessment_for("Myself"));
          // dispatch(setCategoryId(""));
          // dispatch(setTimetaken(""));
          // dispatch(setNumberOfQues("number"));
          // dispatch(selOption(""));
          // dispatch(selScore(""));
          fetchCategories();
          getRelativeData();
        }
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(resetQaStorage()));
  };
  const ctx = useContext(AuthContext);

  useEffect(() => {
    return () => {
      if (ctx?.loginUser?.is_loggedin && ctx?.loginUser?.role === "client") {
        dispatch(qaStore([]));
        dispatch(setAssessment_for("Myself"));
        dispatch(setCategoryId(""));
        dispatch(setTimetaken(""));
        dispatch(setNumberOfQues("number"));
        dispatch(AddData([]));
        dispatch(setRetakeType("first"));
      }
    };
  }, []);

  return (
    <>
      <div
        className={`${AssessmentStyles.bg} ${AssessmentTypeStyles.bgPadding}`}
      >
        {!user ? (
          <Header setStart={setStart} enterprise={enterprise} />
        ) : (
          <div style={{ display: "flex", justifyContent: "right" }}>
            <img
              src={Close}
              alt="close"
              style={{
                marginRight: "20px",
                borderRadius: "8px",
                width: "40px",
                cursor: "pointer",
              }}
              onClick={() => handleClose(false)}
            />
          </div>
        )}
        <div className={AssessmentTypeStyles.outer}>
          <div className={AssessmentTypeStyles.card}>
            <Instructions
              categoryData={data}
              render={start}
              handleClose={handleClose}
              setStart={setStart}
              enterprise={enterprise}
            />
          </div>
          {/* <div
            className={`${AssessmentTypeStyles.bgcard1} ${AssessmentTypeStyles.bgcard}`}
          ></div>
          <div
            className={`${AssessmentTypeStyles.bgcard2} ${AssessmentTypeStyles.bgcard}`}
          ></div> */}
        </div>
      </div>
    </>
  );
};
