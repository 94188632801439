import React, { useState } from "react";
import styles from "../landing.module.css";
import { ServiceCard } from "./ServiceCard";
import { CustomSlider } from "../../Components/UI/Slider";
import CarouselSimple from "react-simply-carousel";
import { ResponsiveCarousel } from "../../Components/UI/ResponsiveCarousel";
import { Carousel } from "../../Components/UI/Carousel";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import { useEffect } from "react";
import { getUnmappedServices } from "../../../services/enterprise/prelogin";

export const Services = () => {
  const servicesMock = [
    {
      id: 1,
      name: "Self-Care Programs",
      description:
        "Mental well-being is an integral part of mental health. It means one can cope with the daily stressors, have healthy relationships, and one is optimistic about the future. Samvedna Self-Care Programs provide skills required for better mental health and emotional well-being of employees. The program focuses on social, psychological and emotional well-being. The program will help improve overall well-being decrease stress and anxiety and will improve energy levels of employees.",
    },
    {
      id: 2,
      name: "Wellness Coaching Program",
      description:
        "Corporate companies value employee wellness and usually include a wellness plan for their employees to help them stay mentally and physically healthy. Through our wellness coaching program we provide 1:1 counselling or wellness coaching for employees struggling with their mental health and well-being. These sessions will help employees manage their workday better and alleviate stress.",
    },
    {
      id: 3,
      name: "Thematic group workshops",
      description:
        "Our group workshops are designed based on organization wide mental health needs in consultation with the relevant stakeholders. The themes are picked based on assessments to identify the pain points. We provide programmatic mental wellness interventions at group/team level for dealing with stress management, programs on parental management, women’s health, communication skills, conflict management and many other topics.",
    },
    {
      id: 4,
      name: "Counselling Helpline/ Chat Services",
      description:
        "Our counselling helpline/ chat services provide seamless, confidential access to employees to deal with their situations and problems. The helpline is managed by specialized and experienced psychologists. We can help employees to navigate the daily stressors of life or with more specific issues related to mental health concerns.",
    },
    {
      id: 5,
      name: "Custom Podcast Solutions",
      description:
        "We provide curated podcasts by our expert team of psychologists and wellness coaches on various topics like mental health support, family care, work life balance, lifestyle management, healthy ageing, creating success and other related topics.",
    },
    {
      id: 6,
      name: "Custom assessments for mental well-being",
      description:
        "Mental well-being is important to living a healthy and fulfilling life. When one is emotionally happy and equipped to manage and cope with distress it can enhance productivity at work and help to create a positive work environment. Our customized assessments can help employers understand their employees better and provide useful insight into how to support employee wellbeing.",
    },
  ];

  const [services, setServices] = useState(servicesMock);

  useEffect(() => {
    getUnmappedServices()
      .then((res) => {
        res.data.list.length && setServices(res.data.list);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className={styles.services}>
      <h2 className={styles.servicesHeading}>Our Services</h2>
      <p className={styles.servicetext}>
        Thus, through our programmatic mental wellness interventions, we assist
        groups and teams in managing their stress levels and{" "}
        <strong>
          <a
            style={{ textDecoration: "none", fontWeight: 700 }}
            href="https://map.samvednacare.com/"
            target="_blank"
            rel="noreferrer"
          >
            improving mental health in the workplace
          </a>
          .
        </strong>{" "}
        Our services include Wellness Coaching Programs, Counselling Helpline,
        thematic group workshops to help improve
        <strong> employee mental health.</strong>{" "}
      </p>
      {/* Big Screens */}
      <div className={styles.slider}>
        {/* <CustomSlider data={services} Component={ServiceCard} slides={2} /> */}
        <div
          style={{
            display: services.length > 2 ? "block" : "flex",
            justifyContent: "center",
          }}
        >
          {services.length > 2 ? (
            <Carousel data={services} Component={ServiceCard} slides={2} />
          ) : (
            services?.map((item) => <ServiceCard {...item} item={item} />)
          )}
        </div>
      </div>
      {/* Mobile Responsive */}
      <div className={styles.responsive_carousel}>
        <Carousel data={services} Component={ServiceCard} slides={2} />
        {/* <ResponsiveCarousel data={services} Component={ServiceCard} /> */}
      </div>
      <div className={styles.btn}>
        <Btn
          bgColor="#3592FF"
          color="#fff"
          margin="10px 0"
          fontFamily="Urbanist"
          className={styles.button}
          click={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          Request a Call-back
        </Btn>
      </div>
    </div>
  );
};
