import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const { GETPROFILE, UPDATEPROFILE, CHANGEPASSWORD, SOCIALPASSWORDCHANGE } = API;

export const getProfile = async () => {
  return await axios
    .get(GETPROFILE)
    .then((res) => res.data.list)
    .catch((err) => console.log(err));
};

export const editProfile = async (data) => {
  return await axios
    .post(UPDATEPROFILE, data)
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const updatePassword = async (data, isPass) => {
  const updateAPI = isPass ? SOCIALPASSWORDCHANGE : CHANGEPASSWORD;
  return await axios
    .post(updateAPI, data)
    .then((res) => res)
    .catch((err) => err);
};
