import { PatientUpcoming } from "./PatientUpcoming";
import { PatientOnGoing } from "./PatientOnGoing";
import { PatientCancelled } from "./PatientCancelled";
import { PatientCompleted } from "./PatientCompleted";
import BasicTabs from "../../../UI/Tab/Tabs";
import { useState } from "react";

export const PatientAppointments = () => {
  const [count, setCount] = useState(0);

  const items = [
    {
      label: "Upcoming",
      component: <PatientUpcoming />,
    },
    {
      label: "OnGoing",
      component: <PatientOnGoing />,
    },
    {
      label: "Cancelled",
      component: <PatientCancelled />,
    },
    {
      label: "Completed",
      component: <PatientCompleted />,
    },
  ];
  return (
    <div>
      <BasicTabs tabcontent={items} setValue={setCount} value={count} />

      {/* <Details /> */}
    </div>
  );
};
