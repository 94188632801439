import React from 'react'

const AssesmentCopy = () => {
  return (
    <svg width="46" height="56" viewBox="0 0 46 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M33.6665 6.66602H38.9998C40.4143 6.66602 41.7709 7.22792 42.7711 8.22811C43.7713 9.22831 44.3332 10.5849 44.3332 11.9993V49.3327C44.3332 50.7472 43.7713 52.1037 42.7711 53.1039C41.7709 54.1041 40.4143 54.666 38.9998 54.666H6.99984C5.58535 54.666 4.22879 54.1041 3.2286 53.1039C2.22841 52.1037 1.6665 50.7472 1.6665 49.3327V11.9993C1.6665 10.5849 2.22841 9.22831 3.2286 8.22811C4.22879 7.22792 5.58535 6.66602 6.99984 6.66602H12.3332" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M31.0002 1.33398H15.0002C13.5274 1.33398 12.3335 2.52789 12.3335 4.00065V9.33398C12.3335 10.8067 13.5274 12.0007 15.0002 12.0007H31.0002C32.4729 12.0007 33.6668 10.8067 33.6668 9.33398V4.00065C33.6668 2.52789 32.4729 1.33398 31.0002 1.33398Z" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.8887 25.6365C16.4455 24.0535 17.5447 22.7186 18.9914 21.8683C20.4382 21.0181 22.1392 20.7073 23.7931 20.991C25.4471 21.2747 26.9472 22.1346 28.0279 23.4183C29.1086 24.7021 29.7001 26.327 29.6976 28.0051C29.6976 32.7423 22.5918 35.1109 22.5918 35.1109" stroke="#2A2A2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.6807 42.2402H22.7162" stroke="#2A2A2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    
  )
}

export default AssesmentCopy