import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";

import { convertToBase64 } from "./ConvertToBase64";
import { Input } from "../../../UI/Input";
import {
  fetchSelfCare,
  postSelfCareSuccess,
} from "../../../../Redux/Slices/Assessment";
import Grid from "@mui/material/Grid";
import CustomizedButtons from "../../../../Component/UI/Button/CustomizeButton";
import { AiFillDelete } from "react-icons/ai";
import { API } from "../../../../config/constant";
import instance from "../../../../config/axiosConfig";

const LabelSelfCare = ({ selected, level, qid }) => {
  const [inputFields, setInputFields] = useState([
    {
      title: "",
      description: "",
      id: 0,
      icon: "",
      level: level,
      existing: false,
      lid: 0,
    },
  ]);
  const [inputid, setInputid] = useState();

  const navigate = useNavigate();
  const [error, setError] = useState({ title: false, description: false });
  const [flag, setFlag] = useState(false);
  const [respData, setRespData] = useState([]);

  const dispatch = useDispatch();

  const { postdata } = useSelector((state) => state.selfCare);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    addApiDatatoField();
  }, [flag]);

  useEffect(() => {
    postdata?.status && sucessNotify(postdata?.msg);
    return () => {
      dispatch(postSelfCareSuccess({}));
    };
  }, [postdata?.status]);

  const getData = () => {
    try {
      instance.get(API.GETSELFCARETIPS).then((res) => {
        res.data.status === true && setRespData(res.data.list);
        setFlag(!flag);
      });
    } catch (error) {
      console.log(error, "error");
    }
  };

  const selectedCategory = respData.filter((res) => {
    return res.id === qid;
  });

  const levelFilter =
    selectedCategory[0]?.selfcare?.filter((res) => {
      return res.level === `${level}`;
    }) || [];

  const addApiDatatoField = () => {
    setInputFields(
      levelFilter?.map((el, index) => {
        return {
          title: el.title,
          description: el.description,
          id: el.id,
          icon: el.icon,
          level: el.level,
          existing: true,
          lid: index,
        };
      })
    );
  };

  //Image Upload
  const ImageUploadHandler = async (e, imageid) => {
    e.preventDefault();
    const acceptOnlyImage = e.target.accept.split(",").filter((result) => {
      return result === e.target.files[0].type;
    });

    const imageData =
      acceptOnlyImage[0] === e.target.files[0].type
        ? e.target.files[0]
        : errorNotify("Please select image");

    const imgName = e.target.files[0].name;
    const base64 = await convertToBase64(imageData);

    let data = [...inputFields];

    data[imageid]["icon"] = base64;
    data[imageid]["imageName"] = imgName;
    setInputFields(data);
  };

  //AddFieldButton
  const addBtn = () => {
    let id = inputFields[inputFields.length - 1]?.lid || -1;
    let newField = {
      title: "",
      description: "",
      id: 0,
      icon: "",
      level: level,
      existing: false,
      lid: ++id,
    };
    setInputFields([...inputFields, newField]);
  };

  //Reset Button
  const ResetBtn = (e) => {
    e.preventDefault();

    const result = inputFields.filter(
      (el) => el.title !== "" && el.description !== ""
    );

    setInputFields(result);
  };

  //Remove Button

  const removeApi = (id) => {
    let removepayload = {
      category_id: qid,
      question_id: inputFields[id].id,
    };

    try {
      instance.post(API.DELETESELFCARETIPS, removepayload).then((res) => {
        res.data.status === true && window.location.reload();
      });
    } catch (error) {
      console.log(error, "error");
    }
  };

  const removeLocal = (id) => {
    if (
      window.confirm(
        ` Are you sure you want to delete this ${inputFields[id].title}? Please note that this cannot be undo & will be saved immediately.`
      ) === true
    ) {
      let data = [...inputFields];
      data.splice(inputFields[id].lid, 1);
      setInputFields(data);
    }
  };

  const removeBtn = (e, id) => {
    let existing = inputFields[id].existing;

    existing
      ? window.confirm(
          ` Are you sure you want to delete this ${inputFields[id].title}? Please note that this cannot be undo & will be saved immediately.`
        ) === true && removeApi(id)
      : removeLocal(id);
  };

  //Add Fields
  const AddInputhandleChange = (e, id) => {
    e.preventDefault();
    setError({
      title: false,
      description: false,
    });
    let data = [...inputFields];
    data[id][e.target.name] = e.target.value;
    setInputFields(data);
    setInputid(id);
  };

  const sucessNotify = (msg) => toast.success(msg);

  const errorNotify = (error) => toast.error(error);

  //SaveButton

  let isError;
  const onSaveBtnClick = () => {
    inputFields.length < 1 && errorNotify("Please add atleast one options");
    const errorMsg = inputFields.map((list, key) => {
      if (!list.title) {
        errorNotify("Title filed are required");
        isError = false;
      } else if (!list.description) {
        errorNotify("Description field are required");
        isError = false;
      } else if (!list.icon) {
        errorNotify("Option Images are required");
        isError = false;
      } else {
        isError = true;
      }
      return isError;
    });

    let form_data = new FormData();
    form_data.append("assessment_id", qid);
    form_data.append("data", JSON.stringify(inputFields));

    isError && dispatch(fetchSelfCare(form_data));
  };

  return (
    <>
      <Toaster />
      <div style={{ position: "absolute", top: "36%", left: "91%" }}>
        <CustomizedButtons ButtonLabel={"Add"} click={(e) => addBtn(e)} />
      </div>
      <div style={{ position: "absolute", top: "36%", left: "85%" }}>
        <CustomizedButtons
          ButtonLabel={"Reset"}
          btncoloractive="true"
          click={(e) => ResetBtn(e)}
        />
      </div>

      <Grid container spacing={0} style={{ margin: "20px" }}>
        {inputFields?.map((el, index) => {
          return (
            <Grid
              container
              spacing={1}
              style={{ margin: "5px 0" }}
              key={el.lid}
            >
              <Grid item xs={4}>
                <Input
                  label="Title"
                  name="title"
                  type="text"
                  width="100%"
                  placeholder={"Please Enter the Title"}
                  id={index}
                  defaultValue={el.title}
                  onChange={(e) => AddInputhandleChange(e, index)}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  label="Description"
                  name="description"
                  type="text"
                  width="100%"
                  placeholder={"Please Enter the Description"}
                  id={index}
                  defaultValue={el.description}
                  onChange={(e) => AddInputhandleChange(e, index)}
                />
              </Grid>

              <Grid
                item
                xs={4}
                style={{
                  display: "flex",
                  with: "100%",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <div>
                    <input
                      type="file"
                      id={`actual-btn${index}`}
                      name="image"
                      accept="image/png,image/jpeg,image/svg,image/gif,image/jpg,image/tiff"
                      hidden
                      onChange={(e) => ImageUploadHandler(e, index)}
                    />
                    <label
                      htmlFor={`actual-btn${index}`}
                      className="uploadLabel"
                    >
                      Choose File
                    </label>
                  </div>
                </div>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "15px",
                    marginLeft: "15px",
                  }}
                >
                  <AiFillDelete
                    style={{
                      width: "25px",
                      height: "25px",
                      cursor: "pointer",
                      marginRight: "15px",
                    }}
                    onClick={(e) => {
                      removeBtn(e, index);
                    }}
                  />
                </span>
              </Grid>
              <div
                style={{
                  gap: "10px",
                  alignItems: "center",
                  textOverflow: "ellipsis",
                  display: "flex",
                  justifyContent: "end",
                  width: "96%",
                }}
              >
                <span id="file-chosen">{el.imageName}</span>
                {el.icon !== "" &&
                  el.icon !== null &&
                  el.icon !== undefined && (
                    <img
                      src={el.icon}
                      alt={"images"}
                      style={{ width: "25px", height: "25px" }}
                    />
                  )}
              </div>
            </Grid>
          );
        })}

        <Button
          variant="contained"
          style={{
            backgroundColor: "#00B091",
            borderRadius: 50,
            width: 100,
            height: 40,
            margin: "15px",
          }}
          onClick={onSaveBtnClick}
        >
          {" "}
          Save{" "}
        </Button>
      </Grid>
    </>
  );
};

export default LabelSelfCare;
