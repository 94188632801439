import { createSlice } from "@reduxjs/toolkit";

const initialState = { email: { description: "test" } };

const emailTemplateSlice = createSlice({
  name: "email template",
  initialState,
  reducers: {
    setEmailTemplate: (state, action) => {
      state.email = {
        ...state.email,
        ...action.payload,
      };
    },
    clearEmailTemplate: () => initialState,
  },
});

export const { setEmailTemplate, clearEmailTemplate } =
  emailTemplateSlice.actions;

export default emailTemplateSlice.reducer;
