import React from "react";
import { SamvednaChart } from "../../Components/Images/SamvednaChart";
import styles from "../landing.module.css";
import nari from "../../Components/Images/Samvedna Care/nari.jpg";
import change from "../../Components/Images/Samvedna Care/change.jpg";
import yes from "../../Components/Images/Samvedna Care/yes.png";
import ardsi from "../../Components/Images/Samvedna Care/ardsi.jpg";
import tiss from "../../Components/Images/Samvedna Care/tiss.jpg";
import alzheimer from "../../Components/Images/Samvedna Care/alzheimer.jpg";
import universitat from "../../Components/Images/Samvedna Care/universitat.jpg";
import ibro from "../../Components/Images/Samvedna Care/ibro.jpg";
import brain from "../../Components/Images/Samvedna Care/brain.jpg";
import { Btn } from "../../../Samvenda-Patient/UI/Button";

export const OverBrand = () => {
  return (
<div className={styles.OverBrand}>
        <div className={styles.OverBrandDetails}>
        <div className={styles.imgSec}>
        <h2 className={styles.OurBrandHeading}>
        Our Associations
        </h2>
        <marquee width="100%" direction="left" height="100px">
          <img src={ardsi} alt="" width="80px" height="80px" />
          <img src={tiss} alt="" width="80px" height="80px" />
          <img src={nari} alt="" width="80px" height="80px" />
          <img src={change} alt="" width="80px" height="80px" />
          <img src={universitat} alt="" width="120px" height="80px" />
          <img src={alzheimer} alt="" width="80px" height="80px" />
          <img src={ibro} alt="" width="120px" height="80px" />
          <img src={brain} alt="" width="120px" height="80px" />
          <img src={yes} className={styles.yes} alt="" />
            
        </marquee>
        
        </div>
          {/* Big Screens */}
        
          {/* Mobile Responsive */}
      
        </div>
      </div>
  );
};
