import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const { ASSESSMENTLEVEL, Assessment_relative } = API;

export const getCustomerAllTakenAssessment = async () => {
  return await axios
    .get(`/customer/getCustomerAllTakenAssessment`)
    .then((res) => {
      // console.log(res);
      return res.data.list;
    })
    .catch((err) => console.log(err));
};

export const assessmentGet = async () => {
  return await axios
    .get(`/customer/all/assessment`)
    .then((res) => {
      // console.log(res);
      return res.data.list;
    })
    .catch((err) => console.log(err));
};

export const assessmentLevel = async () => {
  return await axios
    .get(ASSESSMENTLEVEL)
    .then((res) => res.data.list)
    .catch((err) => console.log(err));
};

export const assessmentListRelative = async () => {
  return await axios
    .get(Assessment_relative)
    .then((res) => res.data.list)
    .catch((err) => console.log(err));
};

export const assessmentLevelRelative = async () => {
  return await axios
    .get("/customer/relative/assessments")
    .then((res) => res.data.list)
    .catch((err) => console.log(err));
};

export const getServiceEnterprise = async (data, customer_id) => {
  return await axios
    .get(`/customer/enterprise/services?id=${data}&customer_id=${customer_id}`)
    .then((res) => res.data.list)
    .catch((err) => console.log(err));
};

export const getEnterpriseAssessment = async (enterprise_id) => {
  return await axios
    .get(`/customer/get/enterprise/assessment?enterprise_id=${enterprise_id}`)
    .then((res) => res.data.list)
    .catch((err) => console.log(err));
};
