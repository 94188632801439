import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { Search } from "../Component/UI/Search";
import { Calender } from "../Component/UI/Calender";
import { Filter } from "../Component/UI/Filter/Filter";
import { Exports } from "../Component/UI/Export/Export";
import CustomizedButtons from "../Component/UI/Button/CustomizeButton";

import { Link } from "react-router-dom";
import { TEXT } from "../Samvenda-Admin/contant/constant";
import SimpleBreadcrum from "../Component/UI/SimpleBreadcrum";
import { MonthPicker } from "../Samvenda-Admin/UI/monthPicker/MonthPicker";
import { Btn } from "../Samvenda-Patient/UI/Button";
import { useNavigate } from "../../node_modules/react-router-dom/index";
import { CircularProgress } from "../../node_modules/@mui/material/index";

export const SimpleHeader = ({
  text,
  children,
  route = "",
  basic,
  noPaddingTop,
  links,
  btnText,
  select,
  editStaffName,
  roles,
  saveRolesAndPerms,
  rosterPermissions,
  isLoadingSave,
  rolesAndPerms,
  styles,
}) => {
  const navigate = useNavigate();
  const writePermissions = +rosterPermissions?.access?.Write;
  const writePermissionsRol = +rolesAndPerms?.access?.Write;

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{
          margin: "15px 0px",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <SimpleBreadcrum
          link={links}
          editStaffName={editStaffName}
          text={text}
        />

        <Grid style={{ display: "flex", gap: "20px", alignItems: "center" }}>
          {!roles && (
            <Grid item xs={12} md={9}>
              <MonthPicker />
            </Grid>
          )}

          <Grid item xs={12} md={3}>
            {btnText === "Add" ? (
              writePermissions ? (
                <Btn
                  bgColor="#00B091"
                  width="79px"
                  height="40px"
                  variant="contained"
                  borderRadius="100px"
                  // className={styles.btn}
                  click={() =>
                    navigate(
                      "add"
                      // {state : {select}}
                    )
                  }
                >
                  {btnText}
                </Btn>
              ) : null
            ) : (
              roles &&
              (writePermissionsRol ? (
                <Btn
                  bgColor="#00B091"
                  width="120px"
                  height="40px"
                  variant="contained"
                  borderRadius="100px"
                  disabled={isLoadingSave}
                  // className={styles.btn}
                  click={saveRolesAndPerms}
                >
                  {btnText}
                </Btn>
              ) : null)
            )}
          </Grid>
        </Grid>
      </Grid>
      {children}
    </>
  );
};
