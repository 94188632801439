import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const { GET_FEEDBACK, ADD_FEEDBACK, EDIT_FEEDBACK, DELETE_FEEDBACK } = API;

export const getFeedback = async () => {
    return await axios
      .get(GET_FEEDBACK)
      .then((res) => res)
      .catch((err) => err);
  };

  export const addFeedback = async (data) => {
    return await axios
      .post(ADD_FEEDBACK, data)
      .then((res) => res)
      .catch((err) => err);
  };
  
  export const editFeedback = async (data) => {
    return await axios
      .post(EDIT_FEEDBACK, data)
      .then((res) => res)
      .catch((err) => err);
  };
  
  export const deleteFeedback = async (data) => {
    return await axios
      .post(DELETE_FEEDBACK, data)
      .then((res) => res)
      .catch((err) => err);
  };
  