import React, { useState, useEffect } from "react";
import styles from "../../assets/css/MyAccount/password.module.css";
import { InputWithIcon } from "../../Component/UI/CustomInputs";
import { MyAccount } from "../../HOC/MyAccount";
import { CheckBox } from "../../Component/UI/CustomInputs";
import { Btn } from "../UI/Button";
import { Eye } from "../../assets/icons/Eye";
import { updatePassword } from "../../services/patient/profile";
import { passwordValidate } from "../../config/passwordValidate";
import toast from "../../../node_modules/react-hot-toast/dist/index";
import { useAuth } from "../../Auth/AuthProvider";

const Password = () => {
  const [passwords, setPasswords] = useState({});
  const [passwordErr, setPasswordErr] = useState("");
  const [confirmErr, setConfirmErr] = useState("");
  const [err, setErr] = useState({});
  const ctx = useAuth();
  const isPass = ctx?.loginUser?.customer?.is_pass;

  let fields = [
    { name: "password", label: "New Password" },
    { name: "password_confirmation", label: "Confirm Password" },
  ];

  fields = isPass
    ? [...fields]
    : [{ name: "current_password", label: "Current Password" }, ...fields];

  useEffect(() => {
    if (passwords.password !== passwords.password_confirmation) {
      setConfirmErr("Confirmation password is not equal to the new password");
    } else setConfirmErr("");
  }, [passwords]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswords({ ...passwords, [name]: value });

    const check = passwordValidate(passwords.password);

    if (check !== true) {
      setPasswordErr(
        "Password should be atleast of 8 characters. Must contain CAPITAL letter, Special character (@,$...) & number"
      );
    } else setPasswordErr("");
  };

  const handleClick = () => {
    if (passwordErr === "" && confirmErr === "") {
      updatePassword(passwords, isPass)
        .then((res) => {
          if (res?.data?.status) toast.success("Password Changed Successfully");
          setErr(res?.data?.error);
        })
        .catch((err) => {
          setErr(err?.data?.error);
        });
    } else
      setErr({
        ...err,
        password: passwordErr,
        password_confirmation: confirmErr,
      });
  };

  return (
    <div className={styles.password}>
      <h2>Update Password</h2>
      {fields.map(({ label, name }, i) => {
        return (
          <InputWithIcon
            key={i}
            type="password"
            name={name}
            error={err?.[name]}
            placeholder="Enter password"
            label={label}
            width="400px"
            icon={Eye}
            onChange={handleChange}
            req
          />
        );
      })}
      {/* <CheckBox
        value="Please log me out from all the other devices"
        boldness={300}
        fontSize="14px"
      /> */}
      <div className={styles.btn}>
        <Btn
          variant="contained"
          width="160px"
          height="48px"
          click={handleClick}
        >
          Update
        </Btn>
      </div>
    </div>
  );
};

export const PasswordHOC = MyAccount(Password);
