import { Header } from "../Header/Header";
import SideBar from "./SideBar";
import classes from "../../assets/css/layout.module.css";
import { Box, Paper, Grid } from "@mui/material";
import "./sideRes.css";
import { PatientHeader } from "../Header/PatientHeader";
import { useAuth } from "../../Auth/AuthProvider";
import { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { LogoutBox } from "../UI/Logout";
import Popover from "@mui/material/Popover";
import titles from "./titles.json";
import { EnterpriseHeader } from "../Header/EnterpriseHeader";

const Layout = () => {
  const ctx = useAuth();
  const role = ctx?.loginUser?.role;
  const location = useLocation();

  const [drawer, setDrawer] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const currentTitle = titles.filter((t) => location.pathname === t.path)[0];

  const currentPath = location.pathname;

  const link = [];

  if (["Profile", "Password", "Testimonial"].includes(currentTitle?.value)) {
    link.push({
      path: "/enterprise/account",
      linkText: "Account",
    });
  }

  if (["Subscription Details"].includes(currentTitle?.value)) {
    link.push({
      path: "/enterprise/subscriptions",
      linkText: "Subscriptions",
    });
  }

  if (["Details"].includes(currentTitle?.value)) {
    link.push({
      path: "/enterprise/assessments",
      linkText: "Assessments",
    });
  }

  const adminHeaderStyle = {
    position: "fixed",
    top: 0,
    zIndex: 3,
    height: "90px",
  };

  return (
    <>
      <Box>
        {role === "Admin!$23" && (
          <Grid container spacing={2} sx={adminHeaderStyle}>
            <Grid item xs={12}>
              <Paper width="100vw" elevation={5}>
                <div>
                  {role === "Admin!$23" && (
                    <Header
                      setDrawer={setDrawer}
                      drawer={drawer}
                      currentPath={currentPath}
                      setLogout={(e) => setAnchorEl(e.currentTarget)}
                    />
                  )}
                </div>
              </Paper>
            </Grid>
          </Grid>
        )}
        <div container sx={{ backgroundColor: "#FAFAFA" }}>
          <Grid item className={classes.leftmenu}>
            {drawer && (
              <div className="backdrop" onClick={() => setDrawer(false)}></div>
            )}
            <div
              className={`fullSidebar ${
                role === "Admin!$23" ? "adminSideBar" : ""
              }`}
              style={{ left: drawer ? "0" : "" }}
            >
              <Paper
                elevation={5}
                sx={{
                  height: "100vh",
                  width: "220px",
                }}
              >
                <SideBar setDrawer={setDrawer} admin={false} />
              </Paper>
            </div>
          </Grid>
          <div className={"OutletContainer"}>
            {role === "client" && (
              <PatientHeader
                setDrawer={setDrawer}
                drawer={drawer}
                title={currentTitle?.value}
                currentPath={currentPath}
              />
            )}
            {role === "enterprise!$23" && (
              <EnterpriseHeader
                title={currentTitle?.value}
                setDrawer={setDrawer}
                drawer={drawer}
                hidden={[
                  "Account",
                  "Profile",
                  "Password",
                  "Testimonial",
                  "Details",
                  "Subscription Details",
                  "Help & Support",
                ].includes(currentTitle?.value)}
                link={link}
                btnText={
                  ["Details"].includes(currentTitle?.value) ? "Feedback" : null
                }
              />
            )}
            {/* <Component {...props} /> */}
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              sx={{
                backgroundColor: "#00000080",
              }}
            >
              <LogoutBox setAnchorEl={setAnchorEl} />
            </Popover>
            <div className={role === "Admin!$23" ? "ContentContainer" : ""}>
              <div
                className={
                  role === "enterprise!$23" ? "enterPriseContainer" : ""
                }
              >
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

export default Layout;
