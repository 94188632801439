import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { AiOutlineCalendar } from "react-icons/ai";
import "react-datepicker/dist/react-datepicker.css";
import classes from "../../assets/css/calender.module.css";
import { getDayDifference } from "../../config/formattedDate";
import { useEffect } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";

export const PatientCalender = ({ dateGap, setDateGap }) => {
  const [startDate, setStartDate] = useState(moment().startOf("month")._d);
  const [endDate, setEndDate] = useState(new Date());
  const dispatch = useDispatch();

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div className={classes.customDate_div} onClick={props.onClick}>
        <div className={classes.calender_input}>
          {props.value || props.placeholder}
        </div>

        <div className={classes.calender_icon}>
          <AiOutlineCalendar color="#7A51AE" />
        </div>
      </div>
    );
  });

  const onChange = (dates) => {
    debugger;
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    // console.log(getDayDifference(startDate, endDate));
    if (startDate && endDate) {
      console.log({ startDate, endDate });
      setDateGap?.({
        startDate: moment(startDate).format("DD-MM-YYYY"),
        endDate: moment(endDate).format("DD-MM-YYYY"),
      });
    }
  }, [startDate, endDate]);

  return (
    <div className={classes.datepicker_div}>
      <DatePicker
        style={{ width: "10px" }}
        dateFormat="dd MMM yyyy"
        selectsRange
        startDate={startDate}
        minDate={
          new Date(
            `${new Date().getFullYear() - 1}-${new Date().getMonth() + 1}-01`
          )
        }
        maxDate={new Date()}
        endDate={endDate}
        onChange={onChange}
        popperClassName="date-picker-reports"
        customInput={<CustomInput />}
      />
    </div>
  );
};
