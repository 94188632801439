import classes from "../../assets/css/accordionOption.module.css";

export const SelectCard2 = ({
  options,
  label,
  style,
  labelstyle,
  getSelectedOption,
  value,
  error,
}) => {
  const handleChange = (event) => {
    getSelectedOption(event.target.value);
  };

  return (
    <div className={classes.accordion_opt}>
      <div className={classes.accordion_title}>{label}</div>
      <div className={classes.accordion_flex} style={style}>
        {options?.map((i) => (
          <>
            <label className={classes.labl} style={labelstyle}>
              <input
                type="radio"
                name={i}
                value={i}
                checked={value === i}
                onChange={handleChange}
              />
              <div>{i}</div>
            </label>
          </>
        ))}
      </div>
      <p style={{ color: "red", marginTop: "5px" }}>{error?.toString()}</p>
    </div>
  );
};
