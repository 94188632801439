import React from "react";
import classes from "../../../../assets/css/sessionreport.module.css";
import Button from "@mui/material/Button";

export const Buttons = () => {
  return (
    <div className={classes.asses_buttons}>
      <Button
        className={classes.textField}
        style={{
          backgroundColor: "#2A2A2A",
          color: "#fff",
          borderRadius: "100px",
          padding: "8px 20px 8px 24px",
        }}
      >
        Cancel
      </Button>

      <Button
        className={classes.textField}
        style={{
          backgroundColor: "#00B091",
          color: "#fff",
          borderRadius: "100px",
          padding: "8px 20px 8px 24px",
        }}
      >
        Update
      </Button>
    </div>
  );
};
