import {
  confirmInputFieldIsNotEmpty,
  confirmInputFieldAndImageIsNotEmpty,
} from "./ConfirmInputFieldIsNotEmpty";
import toast, { Toaster } from "react-hot-toast";
import { TEXT } from "../../../contant/constant";

const errorNotify = (error) => toast.error(error);

let optionFielderrorMsg;

export const optionFieldValidate = (select, inputlength, option, score) => {
  if (inputlength >= 2) {
    if (confirmInputFieldIsNotEmpty(option) === false) {
      errorNotify("Option Field are Required");
      optionFielderrorMsg = false;
    } else if (confirmInputFieldIsNotEmpty(score) === false) {
      errorNotify("Score Field are Required");
      optionFielderrorMsg = false;
    } else {
      optionFielderrorMsg = true;
    }
    return optionFielderrorMsg;
  } else {
    errorNotify("Please add atleast two options");
  }
};

export const optionFieldwithImageValidate = (inputlength, option, img) => {
  const newOptionData = option.filter((el) => el.option === "");
  // const newImageData = img.filter((el) => el.image === "");

  if (inputlength >= 2) {
    if (confirmInputFieldAndImageIsNotEmpty(newOptionData) === false) {
      errorNotify("Option Field is Required");
      optionFielderrorMsg = false;
      // } else if (confirmInputFieldAndImageIsNotEmpty(newImageData) === false) {
      //   errorNotify("Image Field is Required");
      //   optionFielderrorMsg = false;
    } else {
      optionFielderrorMsg = true;
    }
    return optionFielderrorMsg;
  } else {
    errorNotify("Please add atleast two options");
  }
};

let errorMsg;
export const Validate = (selectValue, min, max, tag) => {
  if (selectValue === TEXT.SRANGE) {
    if (min === "" || min === undefined || min === null) {
      errorNotify("Minimum Field is Required");
      errorMsg = false;
    } else if (max === "" || max === undefined || max === null) {
      errorNotify("Maximum Field is Required");
      errorMsg = false;
    } else if (tag === "" || tag === undefined || tag === null) {
      errorNotify("Tag Field is Required");
      errorMsg = false;
    } else {
      errorMsg = true;
    }
    return errorMsg;
  }
};
