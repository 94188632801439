import React from "react";
import { SimpleHeader } from "../../../../Hooks/SimpleHeader";
import toast, {
  Toaster,
} from "../../../../../node_modules/react-hot-toast/dist/index";
import styles from "./Announcement.module.css";
import { Box, Grid } from "../../../../../node_modules/@mui/material/index";
import { Select } from "../../../../Component/UI/CustomInputs";
import { useState } from "react";
import {
  FileUploadBanner,
  FileUploadPdf,
} from "../../../../Component/UI/PdfUpload";
import { FileUpload } from "../../../../Component/UI/FileUpload";
import { useRef } from "react";
import { addAnnouncement } from "../../../../services/admin/patient";
import { useNavigate } from "../../../../../node_modules/react-router-dom/index";
export const AddAnnouncement = () => {
  const [excelName, setExcelName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const actualBtnRef = useRef();
  const navigate = useNavigate();
  const links = [
    {
      path: "/Samvedna/announcement",
      linkText: "Content Management",
    },
  ];
  const dropDownData = [
    {
      id: "0",
      value: "Desktop Banner",
    },
    {
      id: "1",
      value: "Mobile Banner",
    },
  ];

  const bannerUpload = (e) => {
    const f = e?.target?.files[0];
    setExcelName(f);
  };

  const [dataReports, setDataReports] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataReports({ ...dataReports, [name]: value });
  };

  const submitAnnouncement = () => {
    toast.remove();
    if (!dataReports?.banner_type) {
      toast.error("Please Select Banner Type");
      return;
    }

    if (!excelName) {
      toast.error("Please Select banner Image File");
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("banner_image", excelName);
    formData.append("banner_type", dataReports?.banner_type);
    addAnnouncement(formData).then((res) => {
      setIsLoading(false);
      setExcelName("");
      actualBtnRef.current.value = "";
      if (res?.data?.status) {
        toast.success(res?.data?.msg);
        navigate("/Samvedna/announcement");
      } else {
        toast.error(res?.data?.error[0]);
      }
    });
  };
  console.log(dataReports, "dataReports");

  return (
    <div>
      <Toaster />
      <SimpleHeader
        text={"Add Content"}
        links={links}
        basic
        noPaddingTop
        roles={true}
      />
      <div className={styles.addAnnouncement}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Select
                name="banner_type"
                label="Banner Type"
                options={dropDownData}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <div>
                <label style={{ marginBottom: "9px", display: "block" }}>
                  Select Banner
                </label>
                <FileUploadBanner
                  marginTop={0}
                  onChange={bannerUpload}
                  actualBtnRef={actualBtnRef}
                  fileName={excelName?.name}
                />
              </div>

              {dataReports?.banner_type === "0" ? (
                <p
                  style={{
                    marginTop: "10px",
                    color: "#1a1a1a",
                    fontSize: "14px",
                  }}
                >
                  BRAND IMAGE* - Export in jpg | Less than 100KB | 1170 (width)
                  x 150 (height) in pixels
                </p>
              ) : (
                <p
                  style={{
                    marginTop: "10px",
                    color: "#1a1a1a",
                    fontSize: "14px",
                  }}
                >
                  BRAND IMAGE* - Export in jpg | Less than 100KB | 300 (width) x
                  250 (height) in pixels
                </p>
              )}
            </Grid>

            <Grid item xs={8}></Grid>

            <Grid item xs={8}>
              <div style={{ textAlign: "right" }}>
                <button
                  onClick={submitAnnouncement}
                  className={styles.addButtuon}
                  disabled={isLoading}
                >
                  ADD
                </button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};
