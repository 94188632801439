import React, { useState } from "react";
import { Input } from "../../../UI/Input";
import Grid from "@mui/material/Grid";

const AssessmentMinMax = ({
  minValue,
  maxValue,
  tagsValue,
  MinHandler,
  MaxHandler,
  TagsHandler,
}) => {
  return (
    <>
      <Grid item xs={3}>
        <Input
          placeholder="Min"
          direction="row"
          center="center"
          display="inline-flex"
          gap="5px"
          label="Minimum"
          type={"number"}
          name={"minimum"}
          width="70px"
          defaultValue={minValue}
          onChange={MinHandler}
        />
      </Grid>
      <Grid item xs={3}>
        <Input
          placeholder="Max"
          direction="row"
          center="center"
          display="inline-flex"
          gap="5px"
          label="Maximum"
          type={"number"}
          name={"maximum"}
          width="70px"
          defaultValue={maxValue}
          onChange={MaxHandler}
        />
      </Grid>
      <Grid item xs={3}>
        <Input
          direction="row"
          center="center"
          display="inline-flex"
          gap="5px"
          label="Tags"
          type={"text"}
          name={"tags"}
          width="200px"
          defaultValue={tagsValue}
          onChange={TagsHandler}
        />
      </Grid>
    </>
  );
};

export default AssessmentMinMax;
