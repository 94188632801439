import React, { useEffect } from "react";

import Text_Seach_Filter_Export from "../../../Hooks/Text_Seach_Filter_Export";
import { useDispatch, useSelector } from "react-redux";

import {
  deleteServiceById,
  servicesGet,
} from "../../../services/admin/services";
import { getServices } from "../../../Redux/Slices/getServices";
import { Link } from "react-router-dom";
import SwitchButton from "../../../Component/UI/SwitchButton/Switch";
import { Edit } from "../../../assets/icons/Edit";
import { CustomTable } from "../../../Component/UI/Table";
import { serviceStatus } from "../../../services/admin/services";
import { Cross } from "../../../assets/icons/Cross";
import toast from "react-hot-toast";
import Popup from "../../../Component/UI/Popup/Popup";
import {
  setFilter,
  setFrom_date,
  setSearch_key,
  setTo_date,
} from "../../../Redux/Slices/AdminFilters/Search";
import ConfirmPopup from "./ConfirmPopup";
import SwitchToggle from "../../../Component/UI/Switch";
import { useState } from "react";
import { ADMIN_ROUTES } from "../../../Routes/routesConstants";
import moment from "../../../../node_modules/moment/moment";
import { usePermissions } from "../../../Hooks/usePermissions";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";

const Services = () => {
  // const columns = Object.keys(ServicesData[0]);

  const { data } = useSelector((i) => i.getServices);
  const [statusUpdate, setStatusUpdate] = useState(false);
  const [statusDisabled, setStatusDisable] = useState(false);
  const permissions = usePermissions("name", "services");
  const fullpermissions = +permissions?.listControl?.access?.["Full Control"];
  const writePermissions = +permissions?.listControl?.access?.Write;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const [popupAction, setPopupAction] = React.useState({
    open: false,
    action: "",
    name: "",
  });

  const [popupActionDel, setPopupActionDel] = React.useState({
    open: false,
    action: "",
    name: "",
  });

  const { search_key, from_date, to_date, filter } = useSelector(
    (i) => i.SearchFilters
  );

  useEffect(() => {
    getServicesdata();
    console.log("+++___");
  }, [filter, statusUpdate]);

  const getServicesdata = () => {
    setIsLoading(true);
    servicesGet(undefined, search_key, from_date, to_date).then(
      (i) => (setIsLoading(false), dispatch(getServices(i)))
    );
  };

  useEffect(() => {
    return () => {
      //dispatch(setSearch_key(""));
      dispatch(setTo_date(""));
      dispatch(setFrom_date(""));
      dispatch(setFilter());
    };
  }, []);

  const deleteService = (id) => {
    setPopupActionDel({ ...popupActionDel, open: false });
    deleteServiceById(id).then((res) => {
      res.data.status && getServicesdata();
      res.data.status && toast.success("Service deleted succsessfully !");
    });
  };

  const handleStatusToggle = (id, status, popup, setToggle) => {
    console.log(status, "outside __++");
    if (statusDisabled) return;
    if (popup) {
      setPopupAction({
        ...popupAction,
        open: true,
        action: () => {
          setStatusDisable(true);
          serviceStatus(id, !status).then((res) => {
            if (res?.data?.status) {
              setStatusDisable(false);
              // setToggle(!status);
              setStatusUpdate(!statusUpdate);
              setPopupAction({
                ...popupAction,
                open: false,
              });
            }
          });
        },
      });
    } else {
      setStatusDisable(true);
      setToggle(!status);
      serviceStatus(id, !status).then((res) => {
        if (res?.data?.status) {
          setStatusDisable(false);
          setStatusUpdate(!statusUpdate);
        }
      });
    }
  };

  const tHead = [
    "Service Name",
    "Enterprise Name",
    "Amount",
    "Type",
    // "No of Sessions",
    ...(fullpermissions ? ["Status"] : []),
    ...(writePermissions ? ["Action"] : []),
  ];

  const getTypeOfService = (val) => {
    switch (val) {
      case "0":
        return "Offline";
      case "1":
        return "Online";
      case "2":
        return "Hybrid";
      default:
        return "NA";
    }
  };

  const tBodyData = data?.map(
    ({
      name,
      amount,
      type_of_service,
      no_of_session,
      id,
      status,
      level,
      expiryService,
      expiry_date,
      serviceDelete,
      services_for,
      enterprise_name,
      subscription_expiry,
    }) => {
      // const numberOfSessions = no_of_session ? no_of_session : "N/A";
      const subIsExpired = subscription_expiry
        ? moment(subscription_expiry).isBefore(new Date(), "day")
        : subscription_expiry === 0
        ? true
        : false;

      return {
        name: (
          <>
            <Link
              to={ADMIN_ROUTES.SERVICE_DETAILS}
              style={{ color: "#000", textDecoration: "none", display: "flex" }}
              state={{
                flag: "true",
                props: id,
                level,
                services_for,
                expiry_date,
                permissions,
              }}
            >
              {name}
              {level && (
                <div
                  style={{
                    background: "#7A51AE",
                    borderRadius: "20px",
                    width: "24px",
                    height: "24px",
                    color: "#ffffff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0 4px",
                  }}
                >
                  R
                </div>
              )}
            </Link>
          </>
        ),
        serviceFor:
          services_for.toString() === "0"
            ? "General"
            : enterprise_name || "All Enterprise",
        amount,
        type_of_service: getTypeOfService(type_of_service),
        // numberOfSessions,
        ...(fullpermissions && {
          status: (
            <div key={id}>
              <SwitchToggle
                disabled={!subIsExpired}
                status={status}
                onClick={(setToggle) =>
                  services_for.toString() === "1"
                    ? expiry_date
                      ? handleStatusToggle(id, status, false, setToggle)
                      : handleStatusToggle(id, status, true)
                    : handleStatusToggle(id, status, false, setToggle)
                }
              />
            </div>
          ),
        }),
        ...(writePermissions && {
          action: (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              {!expiryService ? (
                <Link
                  to="/EditService"
                  style={{ color: "#000" }}
                  state={{ from: "edit", flag: "true", props: id }}
                >
                  <Edit />
                </Link>
              ) : (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    toast.remove();
                    toast.error("Service is expired ! You can't edit it.");
                  }}
                >
                  <Edit />
                </div>
              )}
              <div
                style={{ color: "red", cursor: "pointer" }}
                // onClick={() => deleteService(id)}
                onClick={() => {
                  toast.remove();
                  // if (!expiryService) {
                  //   if (!expiry_date)
                  //     setPopupActionDel({
                  //       ...popupActionDel,
                  //       open: true,
                  //       action: () => deleteService(id),
                  //       name,
                  //     });
                  //   else toast.error("You can't delete the active service");
                  // } else toast.error("You can't delete the active service");
                  serviceDelete || services_for === 0
                    ? subIsExpired
                      ? setPopupActionDel({
                          ...popupActionDel,
                          open: true,
                          action: () => deleteService(id),
                          name,
                        })
                      : toast.error(
                          "You can't delete the service mapped to the subscription!"
                        )
                    : toast.error("You can't delete the active service");
                }}
              >
                <Cross />
              </div>
            </div>
          ),
        }),
      };
    }
  );

  const FinalServiceData = data?.map((response) => ({
    id: response.id,
    "Service Name": response.name,
    "Enterprise Name":
      response.services_for.toString() === "0"
        ? "General"
        : response.enterprise_name || "All Enterprise",
    description: response.description,
    Amount: response.amount,
    "Type of Service": getTypeOfService(response?.type_of_service),
    Recommendations: response.level
      ?.split(",")
      .filter((i) => i !== "")
      .map((value) => `Level ${value}`)
      .join(),
    Status: response.status === 1 ? "Active" : "InActive",
  }));

  return (
    <Text_Seach_Filter_Export
      text={"Services"}
      route={"/AddService"}
      ApiData={FinalServiceData}
      searchText="Search for service"
      permissions={permissions?.listControl}
    >
      <Popup
        popupAction={popupActionDel}
        setPopupAction={setPopupActionDel}
        title="Are you sure you want to delete this service?"
        heading="Service Delete"
        // icon={<MdMail size={40} />}
      />
      <ConfirmPopup
        popupAction={popupAction}
        setPopupAction={setPopupAction}
        title="Are you sure you want to enable this service? once it's enabled an expiry date will be set according to the validity"
        heading="Service Status"
      />
      {!isLoading ? (
        <CustomTable tHead={tHead} tBody={tBodyData} />
      ) : (
        <div
          className="loader"
          style={{ textAlign: "center", margin: "60px", width: "100%" }}
        >
          <CircularProgress size={28} />
        </div>
      )}
    </Text_Seach_Filter_Export>
  );
};

export default Services;
