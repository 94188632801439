import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const { BOOKEDAPPOINMENTS } = API;

// not used for now

export const getEnterPriseReports = async (type) => {
  return await axios
    .get(`/customer/get/enterprise/report`)
    .then((res) => res.data.list)
    .catch((err) => console.log(err));
};

export const getUploadedReports = async (type) => {
  return await axios
    .get(`/customer/get/uploaded/report`)
    .then((res) => res.data.list)
    .catch((err) => console.log(err));
};

export const selfAssessmentReport = async (type) => {
  return await axios
    .get(`/customer/get/selfAssessment/report`)
    .then((res) => res.data.list)
    .catch((err) => console.log(err));
};

// export const consentSubmitTwo = async (data) => {
//   return await axios
//     .post(`customer/consentTwoValueUpdate`, data)
//     .then((res) => res)
//     .catch((err) => err);
// };
