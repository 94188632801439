import axios from '../../config/axiosConfig';
import { API } from '../../config/constant';

const { FORGOTPASSWORD, RESET_PASSWORD } = API;

export const forgotPassword = async (email) => {
  return await axios
    .get(FORGOTPASSWORD + email)
    .then((res) => res)
    .catch((err) => err);
};

export const resetPassword = async (formData) => {
  return await axios
    .post(RESET_PASSWORD, formData)
    .then((res) => res)
    .catch((err) => err);
};
