import axios from "../../config/axiosConfig";

export const getWellnessTracker = async (id, date) => {
    return await axios
      .get(
        `admin/wellness/dashboard?category_id=${id}&fromdate=${date?.startDate}&todate=${date?.endDate}`
      )
      .then((res) => res)
      .catch((err) => err);
  };

  export const getAssessmentDropDown = async () => {
    return await axios
      .post(
        `admin/generalAssessmentList`
      )
      .then((res) => res)
      .catch((err) => err);
  };

  export const getMoodScoreApi = async (getMonth, year) => {
    return await axios
      .get(
        `admin/wellness/mood?month=${getMonth}&year=${year}`
      )
      .then((res) => res)
      .catch((err) => err);
  };

  export const getMActivityScoreApi = async (getMonth, year) => {
    return await axios
      .get(
        `admin/wellness/activity?month=${getMonth}&year=${year}`
      )
      .then((res) => res)
      .catch((err) => err);
  };

  export const getSleepQuality = async (getMonth, year) => {
    return await axios
      .get(
        `admin/wellness/sleepQuality?month=${getMonth}&year=${year}`
      )
      .then((res) => res)
      .catch((err) => err);
  };

  export const getSleepTracker = async (getMonth, year) => {
    return await axios
      .get(
        `admin/wellness/sleepTracker?month=${getMonth}&year=${year}`
      )
      .then((res) => res)
      .catch((err) => err);
  };
  
  