import React, { useEffect, useState } from "react";

import axios from "../../../config/axiosConfig";
import { CustomTable } from "../../../Component/UI/Table";
import SwitchButton from "../../../Component/UI/SwitchButton/Switch";
import { Link } from "react-router-dom";
import clientData from "../../../MockData/Clients.json";
import UserCheck from "../../../assets/icons/icon/usercheck.svg";
import UserX from "../../../assets/icons/icon/userx.svg";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  setFilter,
  setFrom_date,
  setSearch_key,
  setTo_date,
  setClear,
} from "../../../Redux/Slices/AdminFilters/Search";
import ApprovePopup from "../../UI/Enterprise/ApprovePopup";
import RejectPopup from "../../UI/Enterprise/RejectPopup";
import { Avatar } from "../../../Component/UI/Avatar";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";

const user_btb = {
  marginRight: "20px",
  borderRadius: "8px",
};
export const NewLeads = ({ setNewLeadsData, setExportData, permissions }) => {
  const [client, setClient] = useState([]);
  const nlcolumns = Object.keys(clientData[0]);
  const fullpermissions = +permissions?.listControl?.access?.["Full Control"];
  const writePermissions = +permissions?.listControl?.access?.Write;
  const [isLoading, setIsLoading] = useState(false);

  // const tHead = nlcolumns;
  const tHead = [
    "date",
    "organisation_name",
    "contact_person",
    "mobile",
    "email",
    ...(writePermissions ? ["Action"] : []),
  ];

  const sucessNotify = (msg) => toast.success(msg);
  const errorNotify = (error) => toast.error(error);
  const dispatch = useDispatch();
  const [popupAction, setPopupAction] = useState({
    open: false,
    companyName: "",
    update: false,
  });

  const [popupActionRej, setPopupActionRej] = useState({
    open: false,
    reason: "",
    update: false,
  });

  const { search_key, from_date, to_date, filter } = useSelector(
    (i) => i.SearchFilters
  );

  const getApi = () => {
    setIsLoading(true);
    axios
      .post(`/admin/enterprise/filter`, {
        type: "newLead",
        search_key,
        from_date,
        to_date,
      })
      .then((res) => {
        setIsLoading(false);
        setClient(res.data.list);
        setExportData(
          res.data.list.map((i) => {
            return {
              Company: i.company_name,
              Designation: i.designation,
              Email: i.email,
              Domain: i.domain,
              Name: i.name,
              Mobile: i.mobile,
              "Total Employee": i.total_employee,
              "Other Domains": i.other_domains,
              Industry: i.industry,
              // Status: i.status === 1 ? "active" : "inactive",
            };
          })
        );
        console.log("res", client);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getApi();
    //toggle status
    // toggleStatus(2, 0);
  }, [filter, popupAction.update, popupActionRej.update]);

  useEffect(() => {
    setNewLeadsData(client);
  }, [client]);

  useEffect(() => {
    return () => {
      //   dispatch(setSearch_key(""));
      dispatch(setTo_date(""));
      dispatch(setFrom_date(""));
      dispatch(setFilter());
      dispatch(setClear());
    };
  }, []);

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 35,
        height: 35,
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  // const handleApproved = (id) => {
  //   toast.promise(
  //     axios
  //       .get("/enterprise/leadaction?action=approved&id=" + id)
  //       .then((res) => {
  //         // sucessNotify(res.data.msg);
  //         getApi();
  //       }),
  //     // .catch((err) => errorNotify(err)),
  //     {
  //       loading: "Saving...",
  //       success: "Enterprise has been approved !",
  //       error: "Something went wrong !",
  //     }
  //   );
  // };

  // const handleReject = (id) => {
  //   toast.promise(
  //     axios
  //       .get("/enterprise/leadaction?action=rejected&id=" + id)
  //       .then((res) => {
  //         // sucessNotify(res.data.msg);
  //         getApi();
  //       }),
  //     // .catch((err) => errorNotify(err));
  //     {
  //       loading: "Saving...",
  //       success: "Enterprise has been rejected !",
  //       error: "Something went wrong !",
  //     }
  //   );
  // };

  const tBodyData = client?.map(
    ({
      id,
      created_at,
      company_name,
      name,
      mobile,
      email,
      action,
      img_url,
    }) => {
      const dateShow = created_at.split("T")[0];
      const companyname = (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            height: 0,
          }}
        >
          <Avatar size="28px" profile={img_url} border="1px solid #ccc" />{" "}
          <span style={{ marginLeft: "8px" }}> {company_name} </span>
        </span>
      );

      return {
        dateShow,
        companyname,
        name,
        mobile,
        email,
        ...(writePermissions && {
          action: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                pointerEvents: !writePermissions ? "none" : "",
              }}
            >
              <span
                // onClick={() => handleApproved(id)}
                onClick={() =>
                  setPopupAction({
                    ...popupAction,
                    open: true,
                    id,
                    companyName: company_name,
                  })
                }
                style={{ color: "red", cursor: "pointer" }}
              >
                <img src={UserCheck} alt="usercheck" style={user_btb} />
              </span>
              <span
                onClick={() => {
                  // handleReject(id);
                  setPopupActionRej({
                    ...popupActionRej,
                    open: true,
                    id,
                  });
                }}
                style={{
                  color: "red",
                  cursor: "pointer",
                  pointerEvents: !writePermissions ? "none" : "",
                }}
              >
                <img src={UserX} alt="userx" style={user_btb} />
              </span>
            </div>
          ),
        }),
        // action: (
        //   <div
        //     style={{
        //       display: "flex",
        //       justifyContent: "space-evenly",
        //       pointerEvents: !updatePerms ? "none" : "",
        //     }}
        //   >
        //     <span
        //       // onClick={() => handleApproved(id)}
        //       onClick={() =>
        //         setPopupAction({
        //           ...popupAction,
        //           open: true,
        //           id,
        //           companyName: company_name,
        //         })
        //       }
        //       style={{ color: "red", cursor: "pointer" }}
        //     >
        //       <img src={UserCheck} alt="usercheck" style={user_btb} />
        //     </span>
        //     <span
        //       onClick={() => {
        //         // handleReject(id);
        //         setPopupActionRej({
        //           ...popupActionRej,
        //           open: true,
        //           id,
        //         });
        //       }}
        //       style={{
        //         color: "red",
        //         cursor: "pointer",
        //         pointerEvents: !updatePerms ? "none" : "",
        //       }}
        //     >
        //       <img src={UserX} alt="userx" style={user_btb} />
        //     </span>
        //   </div>
        // ),
      };
    }
  );

  return (
    <>
      <ApprovePopup popupAction={popupAction} setPopupAction={setPopupAction} />
      <RejectPopup
        popupAction={popupActionRej}
        setPopupAction={setPopupActionRej}
      />
      {!isLoading ? (
        <CustomTable tHead={tHead} tBody={tBodyData} />
      ) : (
        <div className="loader" style={{ textAlign: "center", margin: "60px" }}>
          <CircularProgress size={28} />
        </div>
      )}
      <Toaster />
    </>
  );
};
