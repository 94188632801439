import React from "react";
import {
  Paper,
  Typography,
} from "../../../../../node_modules/@mui/material/index";
import Nutrition from "../../../../assets/icons/ProgressTrend/Nutrition.svg";
import Cognitve from "../../../../assets/icons/ProgressTrend/Cognitve.svg";
import Selfcare from "../../../../assets/icons/ProgressTrend/Selfcare.svg";
import Social from "../../../../assets/icons/ProgressTrend/Social.svg";
import Exercise from "../../../../assets/icons/ProgressTrend/Exercise.svg";
import Sleep from "../../../../assets/icons/ProgressTrend/Sleep";
import SleepTime from "../../../../assets/icons/ProgressTrend/SleepTime";
import styles from "./style.module.css";

import CircularBar from "./CircularBar";
import { Btn } from "../../../../Samvenda-Patient/UI/Button";
import ProgressBar from "./ProgressBar";
export const DailyActivityTracker = ({ dailyTracker }) => {
  return (
    <>
      <div className={styles.secondPaperContainer}>
        <Paper elevation={3} className={styles.circularcss}>
          <div className={styles.DailyTrack}>
            <Typography variant="h6" gutterBottom>
              Daily Activity Tracker
            </Typography>
            <Typography>No. of Activities</Typography>
            <Typography variant="h4">
              {dailyTracker?.question?.answered}/{dailyTracker?.question?.total}
            </Typography>
            <ProgressBar
              bgcolor={"#E69A8D"}
              completed={Math.floor(
                (dailyTracker?.question?.answered /
                  dailyTracker?.question?.total) *
                  100
              )}
              // completed={10}
            />
            <div className={styles.btncss}>
              <Btn
                color={"white"}
                bgColor="#00B091"
                width={"100%"}
                height={"40px"}
                padding="10px"
                fontSize={"13px"}

                //click={onCheckTodayActivityHandler}
              >
                Check today activity
              </Btn>
            </div>
          </div>

          {/* {dailyTracker?.map((item) => {
            return (
              <CircularBar
                Name={item.title}
                percentage={item.value}
                barcolor={'#50cd89'}
                icon={Nutrition}
              />
            );
          })} */}

          {Object.keys(dailyTracker).length ? (
            <>
              <CircularBar
                Name={"Nutrition"}
                percentage={Math.trunc(
                  dailyTracker["Nutritional Wellness"] * 100
                )}
                barcolor={"#50cd89"}
                icon={Nutrition}
              />
              <CircularBar
                Name={"Cognition"}
                percentage={Math.trunc(
                  dailyTracker["Cognitive Wellness"] * 100
                )}
                barcolor={"#00ccf1"}
                icon={Cognitve}
              />

              <CircularBar
                Name={"Self-Care"}
                percentage={Math.trunc(
                  dailyTracker["Emotional Wellness"] * 100
                )}
                barcolor={"#ff5a5a"}
                icon={Selfcare}
              />
              <CircularBar
                Name={"Exercise"}
                percentage={Math.trunc(dailyTracker["Physical Wellness"] * 100)}
                barcolor={"#ffc070"}
                icon={Exercise}
              />
              <CircularBar
                Name={"Socialisation"}
                percentage={Math.trunc(dailyTracker["Social Wellness"] * 100)}
                barcolor={"#7a51ae"}
                icon={Social}
              />
            </>
          ) : null}
        </Paper>
      </div>
      {/* <BottomCardContainer
          dailyTracker={dailyTracker}
          streakData={streakData}
        /> */}
    </>
  );
};
