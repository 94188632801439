import React from "react";

export const Check = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      style={{ cursor: "pointer" }}
    >
      <path
        d="M16.6673 5L7.50065 14.1667L3.33398 10"
        stroke="#0BAE36"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
