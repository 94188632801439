import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Btn } from "../../../Samvenda-Patient/UI/Button";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  height: 268,
  bgcolor: "background.paper",
  outline: "none",
  borderRadius: "20px",
  boxShadow: 12,
  p: 4,
};

export default function Popup({ popupAction, setPopupAction, title, icon,click,showCancel=true,successText="Yes" }) {
  const handleClose = () => setPopupAction({ ...popupAction, open: false });
  return (
    <div>
      <Modal
        open={popupAction.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "100%",
              //background: "#00B091",
              top: "0px",
              left: "0px",
              position: "absolute",
              borderTopLeftRadius: "19px",
              borderTopRightRadius: "19px",
              height: "40px",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {icon}
            <h2>{popupAction.name}</h2>
            <p
              style={{
                fontSize: "22px",
                fontWeight: "500",
                margin: "20px 10px",
                textAlign: "center",
                 lineHeight: "20px",
                whiteSpace: "nowrap",
              }}
            >
              {title}
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "-webkit-fill-available",
                margin: "4px 0",
              }}
            >
             { showCancel?<Btn
                bgColor="black"
                color="#fffff"
                width="160px"
                fontSize="16px"
                height="48px"
                variant="contained"
                click={handleClose}
              >
                No
              </Btn>:null}
              <Btn
                bgColor="#00B091"
                variant="contained"
                width="160px"
                fontSize="16px"
                height="48px"
                click={click}
                
                
              >
                {successText}
              </Btn>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
