import React, { useState } from "react";
// import { AssessmentCard } from "../../Components/Assessments/AssessmentCard";
import { CaregiverBurden } from "../../Components/Images/CaregiverBurden";
import styles from "../landing.module.css";
import leftArrow from "../../Components/Images/Stress/leftArrow.svg";
import { MentalWellBeing } from "../../Components/Images/MentalWellBeing";
import { DementiaScreening } from "../../Components/Images/DementiaScreening";
import { useEffect } from "react";
import { getUnmappedAssessments } from "../../../services/enterprise/prelogin";
import { CustomSlider } from "../../Components/UI/Slider";
import { ResponsiveCarousel } from "../../Components/UI/ResponsiveCarousel";
import { Carousel } from "../../Components/UI/Carousel";
import mentalWellBeing from "../../Components/Images/AssessmentImages/MentalWellBeing.svg";
import dementiaScreening from "../../Components/Images/AssessmentImages/DementiaScreening.svg";
import caregiverBurden from "../../Components/Images/AssessmentImages/CaregiverBurden.svg";
import { AssessmentDetailPopup } from "../../DashboardPages/Assessment/AssessmentDetails/AssessmentDetailPopup";
import { AssessmentCard } from "./AssessmentCard";

export const Assesssment = () => {
  const [popup, setPopup] = useState();
  const oldData = [
    {
      name: "Discover Yourself",
      assessment_image: mentalWellBeing,
      no_of_question: "10 Questions",
      short_description:
        "This self-awareness assessment is for employees who want to understand themselves better. Getting to know oneself is really important and begins with being more aware of your thoughts and emotions. Greater self-awareness is also related to better mental health and well-being. Employees may benefit from a deeper understanding of their traits and behaviours or knowing what behaviour patterns may be holding them back from their true potential.",
    },
    {
      name: "Mental Wellbeing Index",
      assessment_image: dementiaScreening,
      no_of_question: "10",
      short_description:
        "Every employer desires to create a work environment for its employees where they feel valued, trusted and enriched. It is important to understand how confident, optimistic and good your employees feel about themselves. How do you ensure that your employees are self-motivated, in high self-esteem and goal oriented ? A mental health index assessment can provide you a birds eye view of your organization’s mental health index and help you design custom solutions for employee wellbeing.",
    },
    {
      name: "Mental Health Awareness",
      assessment_image: caregiverBurden,
      no_of_question: "10",
      short_description:
        "This assessment is a great way to identify how well your employees understand mental health and are aware of mental health issues. Did you know that the WHO defines health as a state of complete physical, mental and social well-being and not merely the absence of disease? Mental health is an important part of overall health and well-being. Employees love this fun assessment to debunk myths about mental health because there are many they may not know.",
    },
  ];

  const [data, setData] = useState(oldData);
  const [clickData, setClickData] = useState({});

  useEffect(() => {
    getUnmappedAssessments().then(
      (res) => res?.data?.list.length && setData(res?.data?.list)
    );
  }, []);

  console.log(data);

  const customProps = {
    btntext: "Details",
    height: "276px",
    btnbg: "#2A2A2A",
    handleBtn: (data) => {
      setPopup(true);
      setClickData(data);
    },
  };

  return (
    <>
      <AssessmentDetailPopup
        popup={popup}
        setPopup={setPopup}
        assessmentData={clickData}
        noquest
      />
      <div className={styles.assessments}>
        <img src={leftArrow} alt="" className={styles.leftArrow} />
        <h2>Assessments for Employee</h2>
        <div className={styles.assessmentsDetails}>
          <h1 className={styles.assessmentHeading}>
            Assessments for Employee Mental Health & Wellbeing
          </h1>
          <p>
            Every employer wants to ensure their employee's <strong>mental well-being at
            the workplace</strong> and make sure they feel valued. Our custom
            self-assessments are tailored to organization needs and is an
            excellent tool to enhance the well-being of your team and support
            <strong> employee mental health in the workplace.</strong>{" "}
          </p>
          {/* Big Screens */}
          <div
            className={styles.slider}
            style={{
              display: data.length > 2 ? "block" : "flex",
              justifyContent: "center",
            }}
          >
            {/* <CustomSlider
            data={data}
            customProps={customProps}
            Component={AssessmentCard}
            slides={3}
          /> */}
            {data.length > 2 ? (
              <Carousel
                data={data}
                customProps={customProps}
                Component={AssessmentCard}
                // indexItem={setClickData}
                // slides={data.length < 4 ? data.length - 1 : 2}
                slides={2}
              />
            ) : (
              data?.map((item) => (
                <AssessmentCard {...item} {...customProps} item={item} />
              ))
            )}
          </div>
          {/* Mobile Responsive */}
          <div className={styles.responsive_carousel}>
            {data ? (
              <Carousel data={data} Component={AssessmentCard} slides={2} />
            ) : // <ResponsiveCarousel
            //   data={data}
            //   customProps={customProps}
            //   Component={AssessmentCard}
            // />
            null}
          </div>
        </div>
      </div>
    </>
  );
};
