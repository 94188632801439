export const TEXT = {
  TEXT: "Text",
  BMI: "BMI",
  RANGE: "Range",
  SRANGE: "range",
  MYSELF: "Myself",
  TIME: "Time",
  AUTOFILLEDBMI: "Your Calculated BMI",
  FLAG: "flag",
  TOGGLE: "Toggle",
  STOGGLE: "toggle",
  MULTI: "Multi Select",
  SELFCARE: "Selfcare",
  COMEINGTOEDIT: "comingToEdit",
  DIRECTCOMING: "directComing",
  QIDNO: 93,
  SINGLECH: "Single Choice",
  SCORE: "scores",
  TODAYOPTIONLENGTH: 2,
  QUICK: "Quick Asks",
  TODAYACT: "Today Activity",
  NAMEREQUIRED: "Name is Required",
  DESCREQUIRED: "Description is Required",
  DATEREUIRED: "Date is Required",
  EMAILREQUIRED: "Email Template is Required",
  QUESTIONREQUIRED: "Question is Required",
  LOGOREQUIRED: "Logo is Required",
  PATIENTPROFILECARDDISABLED: "disabled",
  STATUSTRUE: "true",
  SPEAKTOCOUNSELLOR: "Speak to a Counsellor",
  COUNSELLOR: "Counsellor",
  MAX_FILE_SIZE: 2000000,
};
