import React from "react";

import "./successicon.css";

export const SuccessIcon = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div class="sa">
        <div class="sa-success">
          <div class="sa-success-tip"></div>
          <div class="sa-success-long"></div>
          <div class="sa-success-placeholder"></div>
          <div class="sa-success-fix"></div>
        </div>
      </div>
    </div>
  );
};
