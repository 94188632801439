import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "../../../../node_modules/moment/moment";
import { useNavigate } from "../../../../node_modules/react-router-dom/index";
import {
  setDate,
  setToggle,
  setYear,
} from "../../../Redux/Slices/Patient/PatienCalender";
import { getPatientSubscriptions } from "../../../services/patient/subscriptions";
import { EmptySubscriptions } from "../../UI/EmptySubscriptions";
import { SubscriptionCard } from "./SubscriptionCard";
import styles from "./subscriptions.module.css";

export const Expired = () => {
  const [expiredSubscriptions, setExpiredSubscriptions] = useState([]);
  const { selectedDate } = useSelector((i) => i.DateFilterSlice);
  const navigate = useNavigate();

  const FinalArr = expiredSubscriptions || [];
  const dispatch = useDispatch();

  const finalData = FinalArr.filter((service) => {
    return selectedDate?.includes("All")
      ? service.created_at.includes(selectedDate.split(" ")?.[1])
      : service.created_at.includes(moment(selectedDate).format("YYYY-MM"));
  });

  useEffect(() => {
    return () => {
      dispatch(setDate(`All ${new Date().getFullYear()}`));
      dispatch(setToggle(false));
      dispatch(setYear(new Date().getFullYear()));
    };
  }, []);

  useEffect(() => {
    getPatientSubscriptions(3).then((res) =>
    setExpiredSubscriptions(res?.data?.list)
    );
  }, []);

  return (
    <div
      className={styles.container}
      style={
        finalData?.length
          ? {}
          : { justifyContent: "center", translate: "0 50px" }
      }
    >
      {finalData.length ? (
        finalData?.map((item) => {
          return (
            <SubscriptionCard
              name={item.name}
              validity={item.validity}
              extended={item.extended_validity}
              amount={item.net_price}
              onClick={() => navigate("details", { state: item })}
            />
          );
        })
      ) : (
        
     <EmptySubscriptions title="There are no subscription at this time." />
      )}
    </div>
  );
};
