import React from "react";
import classes from "../../assets/css/sessionreport.module.css";
import Button from "@mui/material/Button";

export const Buttons = () => {
  return (
    <>
      <Button
        className={classes.textField}
        style={{
          backgroundColor: "#2A2A2A",
          color: "#fff",
          borderRadius: "100px",
          padding: "8px 20px 8px 24px",
        }}
      >
        Cancel
      </Button>
      <Button
        className={classes.textField}
        style={{
          color: "#00B091",
          border: "1px solid #00B091",
          borderRadius: "100px",
          padding: "8px 20px 8px 24px",
        }}
      >
        Save Draft
      </Button>
      <Button
        className={classes.textField}
        style={{
          backgroundColor: "#00B091",
          color: "#fff",
          borderRadius: "100px",
          padding: "8px 20px 8px 24px",
        }}
      >
        Submit
      </Button>
    </>
  );
};
