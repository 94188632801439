import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { CustomTable } from "./CustomTable";
import { useEffect } from "react";
import BookNowModel from "./BookNowPopup";
import { useState } from "react";
import Popup from "../Popup";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

const ArrowIcon = ({ values }) => {
  console.log(values, "props");
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none">
      <path
        d="M18 2.24109e-06C8.05888 1.37201e-06 6.25739e-06 8.05887 5.38831e-06 18C4.51923e-06 27.9411 8.05888 36 18 36C27.9411 36 36 27.9411 36 18C36 8.05888 27.9411 3.11017e-06 18 2.24109e-06Z"
        fill="#2A2A2A"
      />
      <path
        d="M11.7783 17.9998H24.2228"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.0007 11.7773L24.223 17.9996L18.0007 24.2218"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<BsFillArrowRightCircleFill size={30} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  color: "#000",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    color: "#7A51AE",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CustomizedAccordions({ data, tHead, styles }) {
  const [expanded, setExpanded] = React.useState("panel1");
  const [selectedValue, setSelectedValue] = React.useState();
  const [popupAction, setPopupAction] = React.useState({
    open: false,
  });
  const [successPopupAction, setSuccessPopupAction] = React.useState({
    open: false,
  });
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    console.log(selectedValue, "selectedValue---");
  }, [selectedValue]);

  console.log(data, 'data----')

  return (
    <div className={styles.planAccordion}>
      <Popup
        popupAction={successPopupAction}
        setPopupAction={setSuccessPopupAction}
        title="Request has been submitted"
        // icon={icon}
      />
      {data?.map((item, i) => {
        const tBodyData = item?.content?.map(
          ({ planName, oneMonth, threeMonth, sixMonth, twelveMonth }) => {
            const selectOptions = [
              {
                id: oneMonth,
                value: oneMonth?.price,
                session: oneMonth?.session,
                month: oneMonth?.month
              },
              {
                id: threeMonth,
                value: threeMonth?.price,
                session: threeMonth?.session,
                month: threeMonth?.month
              },
              {
                id: sixMonth,
                value: sixMonth?.price,
                session: sixMonth?.session,
                month: sixMonth?.month
              },
              {
                id: twelveMonth,
                value: twelveMonth?.price,
                session: twelveMonth?.session,
                month: twelveMonth?.month
              },
            ];
            return {
              planName: planName,
              radioGroup: [
                {
                  label: `${oneMonth?.price}`,
                  subLabel: oneMonth?.session,
                  value: oneMonth?.price,
                },
                {
                  label: `${threeMonth?.price}`,
                  subLabel: threeMonth?.session,
                  value: threeMonth?.price,
                },
                {
                  label: `${sixMonth?.price}`,
                  subLabel: sixMonth?.session,
                  value: sixMonth?.price,
                },
                {
                  label: `${twelveMonth?.price}`,
                  subLabel: twelveMonth?.session,
                  value: twelveMonth?.price,
                },
              ],
              bookNowBtn: {
                btnText: "Book Now",
                disabled: ![
                  oneMonth?.price,
                  threeMonth?.price,
                  sixMonth?.price,
                  twelveMonth?.price,
                ].includes(selectedValue),
                click: () =>
                  setPopupAction({
                    open: true,
                    data: {
                      selectOptions,
                      planName,
                      selectedValue,
                    },
                  }),
              },
            };
          }
        );

        const tHeadData = item?.tHead?.map(({ planeName, month }) => {
          return {
            planeName: planeName,
            month: month,
          };
        });
        return (
          <Accordion
            expanded={expanded === `${"panel" + i}`}
            onChange={handleChange(`${"panel" + i}`)}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Typography>{item?.Title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CustomTable
                tHead={tHead}
                tBody={tBodyData}
                tHeadData={tHeadData}
                styles={styles}
                setSelectedValue={setSelectedValue}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
      {popupAction.open && (
        <BookNowModel
          stylesNew={styles}
          popupAction={popupAction}
          setPopupAction={setPopupAction}
          selectedValue={selectedValue}
          setSuccessPopupAction={setSuccessPopupAction}
        />
      )}
    </div>
  );
}
