import React from "react";
import styles from "./chart.module.css";
import ReactApexChart from "react-apexcharts";
import Nutrition from "./images/mdi_nutrition.svg";
import Cognition from "./images/cognitve.svg";
import SelfCare from "./images/selfCare.svg";
import Exercise from "./images/Exercise.svg";
import Socialization from "./images/social.svg";

class PieChart extends React.Component {
  constructor(props) {
    super(props);
    const d = props.getActivity;
    const apiData = [
      d?.["Nutritional Wellness"],
      d?.["Cognitive Wellness"],
      d?.["Emotional Wellness"],
      d?.["Physical Wellness"],
      d?.["Social Wellness"],
    ];
    const sum = apiData.reduce((i, a) => i + a);
    const percentageValue = apiData.map((i) => {
      const perctValue = (i / sum) * 100;
      return Math.round(perctValue);
    });
    this.state = {
      series: percentageValue,
      noData: {
        text: "No data available",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "14px",
        },
      },
      options: {
        chart: {
          width: 250,
          type: "pie",
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (value) {
              return value + "%";
            },
          },
        },
        colors: ["#50CD89", "#00CCF1", "#FF7978", "#FFC070", "#7A51AE"],
        labels: [
          "Nutrition",
          "Cognition",
          "Self Care",
          "Exercise",
          "Socialization",
        ],
        //labels: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.getActivity !== this.props.getActivity) {
      const apiDa = [
        this.props.getActivity?.["Nutritional Wellness"],
        this.props.getActivity?.["Cognitive Wellness"],
        this.props.getActivity?.["Emotional Wellness"],
        this.props.getActivity?.["Physical Wellness"],
        this.props.getActivity?.["Social Wellness"],
      ];
      const sum = apiDa.reduce((i, a) => i + a);
      const percentageValue = apiDa.map((i) => {
        const perctValue = (i / sum) * 100;
        return Math.round(perctValue);
      });
      this.setState({ ...this.state, series: percentageValue });
    }
  }
  render() {
    return (
      <div className={styles.mainChart} id="chart">
        {this.props.getActivity?.attemptedPerson ? (
          <>
            <ReactApexChart
              options={this.state.options}
              series={this.state.series}
              type="pie"
              width={"100%"}
            />
            <div className={styles.customLabel}>
              <div className={styles.firstRow}>
                <div>
                  <img src={Nutrition} alt="Nutrition" /> Nutrition
                  <br />
                  {this.state.series[0] + "%"}
                </div>
                <div>
                  <img src={Cognition} alt="Cognition" /> Cognition
                  <br />
                  {this.state.series[1] + "%"}
                </div>
              </div>
              <div className={styles.secondRow}>
                <div>
                  <img src={SelfCare} alt="SelfCare" /> Self Care
                  <br />
                  {this.state.series[2] + "%"}
                </div>
                <div>
                  <img src={Exercise} alt="Exercise" /> Physical
                  <br />
                  {this.state.series[3] + "%"}
                </div>
              </div>
              <div className={styles.thirdRow}>
                <div>
                  <img src={Socialization} alt="Socialization" /> Socialization
                  <br />
                  {this.state.series[4] + "%"}
                </div>
                <div></div>
              </div>
            </div>
          </>
        ) : (
          <div
            style={{
              height: 220,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>No Data Available</p>
          </div>
        )}
      </div>
    );
  }
}

export default PieChart;
