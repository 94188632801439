import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Btn } from "../../../Samvenda-Patient/UI/Button";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  height: 242,
  bgcolor: "background.paper",
  outline: "none",
  borderRadius: "10px",
  boxShadow: 8,
  p: 4,
};

export default function Popup({ popupAction, setPopupAction, title, icon, children }) {
  const handleClose = () => setPopupAction({ ...popupAction, open: false });
  return (
    <div>
      <Modal
        open={popupAction.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            
              alignItems: "center",
            }}
          >
           
            {icon}
            <div>
              {children}
            {/* <h2 >{heading}</h2>      
            <p
              style={{
                fontSize: "18px",
                fontWeight: "500",
               // margin: "20px 12px",
                textAlign: "center",
               // lineHeight: "20px",
              }}
            >
              {title}
            </p> */}
            </div>

            <Btn
              bgColor="#3592FF"
              color="#fffff"
              fontSize="16px"
              width="100px"
              height="40px"
              borderRadius="10px"
              variant="contained"
              click={handleClose}
            >
              Close
            </Btn>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
