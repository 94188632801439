export const Logout = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M6 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H6"
        stroke="#2A2A2A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.666 11.3327L13.9993 7.99935L10.666 4.66602"
        stroke="#2A2A2A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 8H6"
        stroke="#2A2A2A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
