import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Protected from "./Protected";
import LayoutNew from "../Component/Layout/Layoutnew";

import Patients from "../Samvenda-Admin/DashboardPages/Patients/Patients";
import Enterprise from "../Samvenda-Admin/DashboardPages/Enterprise/Enterprise";
import EnterpriseCardView from "../Samvenda-Admin/DashboardPages/Enterprise/EnterpriseCardView";
import EnterpriseGeneralProfile from "../Samvenda-Admin/DashboardPages/Enterprise/EnterpriseGeneralProfile";
import EnterpriseAssessmentList from "../Samvenda-Admin/DashboardPages/Enterprise/EnterpriseAssessments";
import EnterpriseServices from "../Samvenda-Admin/DashboardPages/Enterprise/EnterpriseServices";
import EnterpriseReports from "../Samvenda-Admin/DashboardPages/Enterprise/EnterpriseReports";

import Assessment from "../Samvenda-Admin/DashboardPages/Assessment/Assessment";
import GeneralQuickAsk from "../Samvenda-Admin/DashboardPages/Assessment/GeneralQuickAsk";
import GeneralAddQuestion from "../Samvenda-Admin/DashboardPages/Assessment/GeneralAddQuestion";
import Services from "../Samvenda-Admin/DashboardPages/Service/Services";
import AddAssessment from "../Samvenda-Admin/DashboardPages/Assessment/AfterClickingAddButton/AddAssessment";
import Subscriptions from "../Samvenda-Admin/DashboardPages/Subscriptions/Subscriptions";
import ServiceStatus from "../Samvenda-Admin/DashboardPages/Service/ServiceStatus";
import DementiaScreening from "../Samvenda-Admin/DashboardPages/Patients/DementiaScreening";
import About from "../Samvenda-Admin/DashboardPages/Samvedna/About";
import Experts from "../Samvenda-Admin/DashboardPages/Samvedna/Experts";
import Settings from "../Samvenda-Admin/DashboardPages/Samvedna/Settings";
import ContactUs from "../Samvenda-Admin/DashboardPages/Samvedna/ContactUs";
import SamvednaServices from "../Samvenda-Admin/DashboardPages/Samvedna/SamvednaService";
import Password from "../Samvenda-Admin/DashboardPages/Samvedna/Password";
import PatientProfile from "../Samvenda-Admin/DashboardPages/Patients/PatientProfile/PatientProfile";
import PatientsProfile from "../Samvenda-Admin/DashboardPages/Patients/PatientsProfile";
import { AppointmentDetails } from "../Samvenda-Admin/Appointments/AppointmentDetails";
import AppointmentUserData from "../Samvenda-Admin/Appointments/AppointmentUserData";
import NotficationManagement from "../Samvenda-Admin/DashboardPages/NotificationManagement/NotficationManagement";
import NotificationEmail from "../Samvenda-Admin/DashboardPages/NotificationManagement/Email/NotificationEmail";
import AddEmailTemplate from "../Samvenda-Admin/DashboardPages/NotificationManagement/Email/AddEmailTemplate";
import AddNotification from "../Samvenda-Admin/DashboardPages/NotificationManagement/Notification/AddNotification";
import Notification from "../Samvenda-Admin/DashboardPages/NotificationManagement/Notification/Notification";
import NotificationDetails from "../Samvenda-Admin/DashboardPages/NotificationManagement/Notification/NotificationDetails";
import NotificationSms from "../Samvenda-Admin/DashboardPages/NotificationManagement/Sms/NotificationSms";
import AddSmsTemplate from "../Samvenda-Admin/DashboardPages/NotificationManagement/Sms/AddSmsTemplate";
import { SessionReport } from "../Samvenda-Admin/DashboardPages/Patients/Report/SessionReport/SessionReport";
import { AssessmentSummary } from "../Samvenda-Admin/DashboardPages/Patients/AssessmentSummary/AssessmentSummary";
import Samvedna from "../Samvenda-Admin/DashboardPages/Samvedna/Samvedna";

import Overview from "../Samvenda-Admin/DashboardPages/Overview/Overview";
import { AddAppointments } from "../Samvenda-Admin/DashboardPages/Appointments/AddAppointments";
import { AddEvent } from "../Samvenda-Admin/DashboardPages/Events/AddEvent";
import { EditEvent } from "../Samvenda-Admin/DashboardPages/Events/EditEvent";
import { EventDetail } from "../Samvenda-Admin/DashboardPages/Events/EventDetail";
import { AdminEvents } from "../Samvenda-Admin/DashboardPages/Events/Events";
import { AddService } from "../Samvenda-Admin/DashboardPages/Service/AddService";
import { EditService } from "../Samvenda-Admin/DashboardPages/Service/EditService";
import { EditClient } from "../Samvenda-Admin/DashboardPages/Patients/EditClient";
import { AddClient } from "../Samvenda-Admin/DashboardPages/Patients/AddClient";
import AddSelfCareTips from "../Samvenda-Admin/DashboardPages/Assessment/AfterClickingAddButton/AddSelfCareTips";
import { EditUser } from "../Samvenda-Admin/DashboardPages/Appointments/EditUser";
import { Appointmentss } from "../Samvenda-Admin/DashboardPages/Appointments/Appointmentss";
import AssesmentQuestion from "../Samvenda-Admin/DashboardPages/Assessment/AfterClickingAddButton/AssesmentQuestion";
import TodayActivityQuestion from "../Samvenda-Admin/DashboardPages/Assessment/TodayActivityQuestion";
import TodayActivityAddQuestion from "../Samvenda-Admin/DashboardPages/Assessment/TodayActivityAddQuestion";
import QuickAddCategory from "../Samvenda-Admin/DashboardPages/Assessment/AfterClickingAddButton/QuickAddCategory";
import TodayActivityAddCategory from "../Samvenda-Admin/DashboardPages/Assessment/AfterClickingAddButton/TodayActivityAddCategory";
import AssessmentReport from "../Samvenda-Admin/DashboardPages/AssessmentReports/index";
import { PatientAppointments } from "../Samvenda-Admin/DashboardPages/Patients/Appointments/PatientAppointments";
import { ExpertCarePlan } from "../Samvenda-Admin/DashboardPages/Patients/ExpertCarePlan/Plan";
import { ServiceDetail } from "../Samvenda-Admin/DashboardPages/Service/ServiceDetail";
import { SelfAssessment } from "../Samvenda-Admin/DashboardPages/Patients/SelfAssessment/SelfAssessment";
import { AssessmentData } from "../Samvenda-Admin/DashboardPages/Patients/SelfAssessment/AssessmentData";

import { FAQ } from "../Samvenda-Admin/DashboardPages/Samvedna/Content/FAQ";
import { SocialLinks } from "../Samvenda-Admin/DashboardPages/Samvedna/Content/SocialLinks/SocialLinks";
import { ContentManagement } from "../Samvenda-Admin/DashboardPages/Samvedna/Content/ContentManagement";
import { AdminReport } from "../Samvenda-Admin/DashboardPages/Patients/Report/Report";
import { LoginContainer } from "../Samvenda-Admin/Login/LoginContainer";
import { AddPlan } from "../Samvenda-Admin/DashboardPages/Patients/ExpertCarePlan/AddPlan/AddPlan";
import { Activities } from "../Samvenda-Admin/DashboardPages/Activities/Activities";
import { Appointments } from "../Samvenda-Admin/DashboardPages/Appointments/Appointments";

import Payments from "../Samvenda-Admin/DashboardPages/Payments/Payments";

// samvedna page route imports
import { NotificationManagement } from "../Samvenda-Admin/DashboardPages/SamvednaNew/Notification/NotificationManagement";
import { Email } from "../Samvenda-Admin/DashboardPages/SamvednaNew/Notification/Email/Email";
import SamvednaNew from "../Samvenda-Admin/DashboardPages/SamvednaNew";

//import AddSelfCareTips from "../Samvenda-Admin/DashboardPages/Assessment/AddSelfCareTips";
//import SelfCareTips from "../Samvenda-Admin/DashboardPages/Assessment/SelfCareTips";
//import QuickAsks from "../Samvenda-Admin/DashboardPages/Assessment/QuickAsks";
import { ADMIN_ROUTES } from "./routesConstants";
import NotExist from "../Component/Common/NotExist";
import EditEmailTemplate from "../Samvenda-Admin/DashboardPages/NotificationManagement/Email/EditEmailTemplate";
import EnterpriseExcelReports from "../Samvenda-Admin/DashboardPages/Enterprise/EnterpriseExcelReports";
import { SubscriptionsTable } from "../Samvenda-Admin/DashboardPages/Subscriptions/SubscriptionsTable";
import { AddSubscription } from "../Samvenda-Admin/DashboardPages/Subscriptions/AddSubscription";
import { UpdateSubscription } from "../Samvenda-Admin/DashboardPages/Subscriptions/UpdateSubscription";
import { ViewSubscription } from "../Samvenda-Admin/DashboardPages/Subscriptions/ViewSubscriptions";
import { Feedbacks } from "../Samvenda-Admin/DashboardPages/SamvednaNew/Feedback/Feedbacks";
import { Testimonials } from "../Samvenda-Admin/DashboardPages/SamvednaNew/Testimonial/Testimonials";
import { PatientTestimonials } from "../Samvenda-Admin/DashboardPages/Patients/Others/PatientTestimonials";
import { PatientFeedback } from "../Samvenda-Admin/DashboardPages/Patients/Others/PatientFeedback";
import { ViewFeedback } from "../Samvenda-Admin/DashboardPages/SamvednaNew/Feedback/TabComponents/ViewFeedback";
import { ViewTestimonial } from "../Samvenda-Admin/DashboardPages/SamvednaNew/Testimonial/TabComponents/ViewTestimonial";
import { SubscriptionsPatient } from "../Samvenda-Admin/DashboardPages/Patients/General/Subscriptions/Subscriptions";
import { EnterpriseSubscription } from "../Samvenda-Admin/DashboardPages/Enterprise/EnterpriseSubscription";
import { EnterpriseSubscriptionView } from "../Samvenda-Admin/DashboardPages/Enterprise/EnterpriseSubscriptionView";
import { StaffManagement } from "../Samvenda-Admin/DashboardPages/SamvednaNew/Staff Management/Staff";
import { AddStaff } from "../Samvenda-Admin/DashboardPages/SamvednaNew/Staff Management/AddStaff";
import { ViewStaff } from "../Samvenda-Admin/DashboardPages/SamvednaNew/Staff Management/ViewStaff";
import { EditStaff } from "../Samvenda-Admin/DashboardPages/SamvednaNew/Staff Management/EditStaff";
import RosterManagement from "../Samvenda-Admin/DashboardPages/SamvednaNew/Roster Management/Index";
import { AddRoster } from "../Samvenda-Admin/DashboardPages/SamvednaNew/Roster Management/AddRoster";
import { ServicesPatient } from "../Samvenda-Admin/DashboardPages/Patients/General/Services/Services";
import EnterpriseReportsTab from "../Samvenda-Admin/DashboardPages/Enterprise/EnterpriseReportsTab";
import RolesAndPermissions from "../Samvenda-Admin/DashboardPages/SamvednaNew/Roles and Permissions/Index.jsx";
import { MyAccount } from "../Samvenda-Admin/DashboardPages/MyAccount/MyAccount";
import { WellnessTracker } from "../Samvenda-Admin/DashboardPages/WellnessTracker/Index";
import { SelfCarePlan } from "../Samvenda-Admin/DashboardPages/Patients/SelfCarePlan/SelfCarePlan";
import { MyProgressTrends } from "../Samvenda-Admin/DashboardPages/Patients/MyProgressTrends/MyProgressTrends";
import Consent from "../Samvenda-Admin/DashboardPages/Patients/Consent/Consent";
import EnterpriseTestimonials from "../Samvenda-Admin/DashboardPages/Enterprise/Testimonials";
import { AddTestimonial } from "../Samvenda-Admin/DashboardPages/Enterprise/Addtestimonials";
import { AdminReports } from "../Samvenda-Admin/DashboardPages/Patients/AdminReports/AdminReports";
import { AddReports } from "../Samvenda-Admin/DashboardPages/Patients/AdminReports/AddReports";
import { SocialMedia } from "../Samvenda-Patient/DashboardPages/Socials_Media/Index";
import { AnnouncementMgt } from "../Samvenda-Admin/DashboardPages/SamvednaNew/Announcement/Index";
import { AddAnnouncement } from "../Samvenda-Admin/DashboardPages/SamvednaNew/Announcement/AddAnnouncement";
const ROLES = {
  Client: "client",
  Admin: "Admin!$23",
};

const admin = "smv-admin";

const AdminRoutes = () => {
  return (
    <Routes>
      <Route element={<Protected role={ROLES.Admin} />}>
        <Route element={<LayoutNew />}>
          <Route path={ADMIN_ROUTES.DASHBOARD} element={<Overview />} />
          <Route path={ADMIN_ROUTES.PATIENTS} element={<Patients />} />
          <Route
            path={ADMIN_ROUTES.SELF_ASSESSMENT}
            element={<SelfAssessment />}
          />
          <Route
            path={ADMIN_ROUTES.SELF_ASSESSMENT_QUESTION}
            element={<AssessmentData />}
          />

          <Route
            path={ADMIN_ROUTES.SELF_CARE_PLAN_ADMIN}
            element={<SelfCarePlan />}
          />

          <Route
            path={ADMIN_ROUTES.PROGRESS_TRENDS_ADMIN}
            element={<MyProgressTrends />}
          />

          {/* <Route path={`${patient}/signup`} element={<PatientSignup />} /> */}
          <Route
            path={ADMIN_ROUTES.DEMENTIA_SCREENING}
            element={<DementiaScreening />}
          />
          <Route
            path={ADMIN_ROUTES.PATIENT_PROFILE}
            element={<PatientsProfile />}
          />
          {/* //need clarafication why 2 routes*/}
          <Route
            path={ADMIN_ROUTES.PATIENT_USER_PROFILE}
            element={<PatientProfile />}
          />
          <Route
            path={ADMIN_ROUTES.PATIENT_SUBSCRIPTIONS}
            element={<SubscriptionsPatient />}
          />
          <Route
            path={ADMIN_ROUTES.PATIENT_SERVICES}
            element={<ServicesPatient />}
          />

          <Route path={ADMIN_ROUTES.PATIENT_CONSENT} element={<Consent />} />
          {/* Enterprises */}
          <Route path={ADMIN_ROUTES.ENTERPRISES} element={<Enterprise />} />
          <Route
            path={ADMIN_ROUTES.ENTERPRISES_VIEWBOX}
            element={<EnterpriseCardView />}
          />
          <Route
            path={ADMIN_ROUTES.ENTERPRISES_TESTIMONIALS}
            element={<EnterpriseTestimonials />}
          />
          <Route
            path={ADMIN_ROUTES.ENTERPRISES_TESTIMONIALS_ADD}
            element={<AddTestimonial />}
          />

          <Route
            path={ADMIN_ROUTES.ENTERPRISE_GENERAL_PROFILE_ADD}
            element={<EnterpriseGeneralProfile />}
          />
          <Route
            path={ADMIN_ROUTES.ENTERPRISE_GENERAL_PROFILE_EDIT}
            element={<EnterpriseGeneralProfile />}
          />
          <Route
            path={ADMIN_ROUTES.ENTERPRISES_ASSESSMENT_LIST}
            element={<EnterpriseAssessmentList />}
          />
          <Route
            path={ADMIN_ROUTES.ENTERPRISE_SERVICE_LIST}
            element={<EnterpriseServices />}
          />
          <Route
            path={ADMIN_ROUTES.ENTERPRISE_REPORT_LIST}
            element={<EnterpriseReportsTab />}
          />
          <Route
            path={ADMIN_ROUTES.ENTERPRISE_SUBSCRIPTION_LIST}
            element={<EnterpriseSubscription />}
          />
          <Route
            path={ADMIN_ROUTES.ENTERPRISE_SUBSCRIPTION_DETAILS}
            element={<EnterpriseSubscriptionView />}
          />
          <Route
            path={ADMIN_ROUTES.ENTERPRISE_EXCEL_REPORT_LIST}
            element={<EnterpriseExcelReports />}
          />

          <Route path={ADMIN_ROUTES.SAMVEDNA} element={<SamvednaNew />} />
          {/* Assessment */}
          <Route path={ADMIN_ROUTES.ASSESSMENTS} element={<Assessment />} />
          {/* <Route path="/selfCareTips" element={<SelfCareTipsComponent />} />
      <Route path="/quickAsks" element={<QuickAsksComponent />} /> */}
          <Route
            path={ADMIN_ROUTES.GENERAL_QUICK_ASK}
            element={<GeneralQuickAsk />}
          />
          <Route
            path={ADMIN_ROUTES.GENERAL_ADD_QUESTION}
            element={<GeneralAddQuestion />}
          />
          {/* <Route path="/details" element={<DetailsComponent />} /> */}
          {/* Assessment */}
          <Route
            path={ADMIN_ROUTES.ADD_ASSESSMENT}
            element={<AddAssessment />}
          />
          <Route
            path={ADMIN_ROUTES.EDIT_ASSESSMENT}
            element={<AddAssessment />}
          />
          <Route
            path={ADMIN_ROUTES.ASSESSMENT_QUESTION_EDIT}
            element={<AssesmentQuestion />}
          />
          <Route
            path={ADMIN_ROUTES.ADD_SELF_CARE_TIPS}
            element={<AddSelfCareTips />}
          />
          <Route
            path={ADMIN_ROUTES.EDIT_SELF_CARE_TIPS}
            element={<AddSelfCareTips />}
          />
          {/* Events */}
          <Route path={ADMIN_ROUTES.EVENTS} element={<AdminEvents />} />
          <Route path={ADMIN_ROUTES.ADD_EVENTS} element={<AddEvent />} />
          <Route path={ADMIN_ROUTES.EDIT_EVENTS} element={<EditEvent />} />
          <Route path={ADMIN_ROUTES.EDIT_DETAILS} element={<EventDetail />} />
          <Route
            path={ADMIN_ROUTES.ADD_ASSESSMENT_QUESTION}
            element={<AssesmentQuestion />}
          />
          <Route path={ADMIN_ROUTES.ADD_SERVICE} element={<AddService />} />
          <Route path={ADMIN_ROUTES.EDIT_SERVICE} element={<EditService />} />
          {/* Appointments */}
          <Route
            path={ADMIN_ROUTES.ADD_APPOINTMENTS}
            element={<AddAppointments />}
          />
          <Route path={ADMIN_ROUTES.APPOINTMENTS} element={<Appointmentss />} />
          {/* profile sec start */}
          <Route path={ADMIN_ROUTES.MY_ACCOUNT} element={<MyAccount />} />
          {/* Subscriptions */}
          <Route
            path={ADMIN_ROUTES.SUBSCRIPTIONS}
            element={<SubscriptionsTable />}
          />
          <Route
            path={ADMIN_ROUTES.ADD_SUBSCRIPTIONS}
            element={<AddSubscription />}
          />
          <Route
            path={ADMIN_ROUTES.WELLNESS_TRACKER}
            element={<WellnessTracker />}
          />

          <Route
            path={ADMIN_ROUTES.EDIT_SUBSCRIPTIONS}
            element={<UpdateSubscription />}
          />
          <Route
            path={ADMIN_ROUTES.DETAILS_SUBSCRIPTIONS}
            element={<ViewSubscription />}
          />
          <Route
            path={ADMIN_ROUTES.PATIENT_SUBSCRIPTION_VIEW}
            element={<ViewSubscription />}
          />
          {/* Services */}
          <Route path={ADMIN_ROUTES.SERVICES} element={<Services />} />
          <Route
            path={ADMIN_ROUTES.SERVICE_DETAILS}
            element={<ServiceDetail />}
          />
          {/* Appointment */}
          <Route
            path={ADMIN_ROUTES.APPOINTMENTS_DETAILS}
            element={<AppointmentDetails />}
          />
          <Route
            path={ADMIN_ROUTES.APPOINTMENT_USERDATA}
            element={<AppointmentUserData />}
          />
          {/* Notification Management - Email  */}
          <Route
            path={ADMIN_ROUTES.NOTIFICATION_MANAGEMENT}
            element={<NotficationManagement />}
          />
          <Route
            path={ADMIN_ROUTES.NOTIFICATION_EMAIL}
            element={<NotificationEmail />}
          />
          <Route
            path={ADMIN_ROUTES.ADD_EMAIL_TEMPLATE}
            element={<AddEmailTemplate />}
          />
          {/* Notification Management  - Notification*/}
          <Route
            path={ADMIN_ROUTES.ADD_NOTIFICATION}
            element={<AddNotification />}
          />
          <Route path={ADMIN_ROUTES.NOTIFICATION} element={<Notification />} />
          <Route
            path={ADMIN_ROUTES.NOTIFICATION_DETAILS}
            element={<NotificationDetails />}
          />
          {/* Notification Management  - SMS */}
          <Route
            path={ADMIN_ROUTES.NOTIFICATION_SMS}
            element={<NotificationSms />}
          />
          <Route
            path={ADMIN_ROUTES.ADD_TEMPLATE}
            element={<AddSmsTemplate />}
          />
          <Route
            path={ADMIN_ROUTES.EDIT_EMAIL_TEMPLATE}
            element={<EditEmailTemplate />}
          />
          {/* Staff Management */}
          <Route
            path={ADMIN_ROUTES.STAFF_MANAGEMENT}
            element={<StaffManagement />}
          />
          <Route path={"/Samvedna/staff/add"} element={<AddStaff />} />
          <Route path={"/Samvedna/staff/edit"} element={<EditStaff />} />
          <Route path={"/Samvedna/staff/details"} element={<ViewStaff />} />

          {/* Roster Management Route */}

          <Route path={"/Samvedna/roster"} element={<RosterManagement />} />
          <Route path={"/Samvedna/roster/add"} element={<AddRoster />} />
          <Route path={"/Samvedna/roster/edit"} element={<AddRoster />} />

          {/* announcement Route */}
          <Route
            path={"/Samvedna/announcement"}
            element={<AnnouncementMgt />}
          />
          <Route
            path={"/Samvedna/announcement/add"}
            element={<AddAnnouncement />}
          />

          {/* Roles and Permissions Route */}
          <Route
            path={"/Samvedna/roles-and-permissions"}
            element={<RolesAndPermissions />}
          />
          {/* Roles and Permissions End */}
          {/* Profile Route */}
          {/* <Route path="/myaccount" element={<MyAccountComponent />} /> */}
          <Route path={ADMIN_ROUTES.PATIENT_ADD} element={<AddClient />} />
          <Route path={ADMIN_ROUTES.PATIENT_EDIT} element={<EditClient />} />
          {/* <Route path="/myaccount" element={<MyAccountComponent />} /> */}
          <Route
            path={ADMIN_ROUTES.SERVICES_STATUS}
            element={<ServiceStatus />}
          />
          {/*Testimonials*/}
          <Route path={ADMIN_ROUTES.TESTIMONIALS} element={<Testimonials />} />
          <Route
            path={ADMIN_ROUTES.PATIENT_TESTIMONIALS}
            element={<PatientTestimonials />}
          />
          <Route
            path={ADMIN_ROUTES.TESTIMONIAL_DETAILS}
            element={<ViewTestimonial />}
          />
          <Route
            path={ADMIN_ROUTES.PATIENT_TESTIMONIAL_DETAILS}
            element={<ViewTestimonial />}
          />
          {/* FeedbackComponent */}
          <Route path={ADMIN_ROUTES.FEEDBACK} element={<Feedbacks />} />
          <Route
            path={ADMIN_ROUTES.PATIENT_FEEDBACK}
            element={<PatientFeedback />}
          />
          <Route
            path={ADMIN_ROUTES.FEEDBACK_DETAILS}
            element={<ViewFeedback />}
          />
          <Route
            path={ADMIN_ROUTES.PATIENT_FEEDBACK_DETAILS}
            element={<ViewFeedback />}
          />

          <Route path={ADMIN_ROUTES.ADMIN_REPORTS} element={<AdminReports />} />
          <Route path={ADMIN_ROUTES.ADD_REPORTS} element={<AddReports />} />

          {/* Samvedna */}
          <Route path={ADMIN_ROUTES.ABOUT} element={<About />} />
          <Route path={ADMIN_ROUTES.EXPERT} element={<Experts />} />
          <Route path={ADMIN_ROUTES.SETTINGS} element={<Settings />} />
          <Route path={ADMIN_ROUTES.CONTACT_US} element={<ContactUs />} />
          <Route
            path={ADMIN_ROUTES.SAMVEDNA_SERVICE}
            element={<SamvednaServices />}
          />
          <Route path={ADMIN_ROUTES.PASSWORD} element={<Password />} />
          <Route
            path={ADMIN_ROUTES.PATIENT_APPOINTMENTS}
            element={<PatientAppointments />}
          />
          <Route
            path={ADMIN_ROUTES.ASSESSMENTS_SUMMARY}
            element={<AssessmentSummary />}
          />
          <Route
            path={ADMIN_ROUTES.EXPERT_CARE_PLAN}
            element={<ExpertCarePlan />}
          />
          {/* <Route
          path="/addplan"
          element={
             
              <AddPlanComponent />
              
          }
        /> */}
          <Route
            path={ADMIN_ROUTES.SESSION_REPORT}
            element={<SessionReport />}
          />
          <Route
            path={ADMIN_ROUTES.ASSESSMENT_REPORTS}
            element={<AssessmentReport />}
          />
          <Route
            path={ADMIN_ROUTES.ASSESSMENT_USER_EDIT}
            element={<EditUser />}
          />
          <Route
            path={ADMIN_ROUTES.TODAY_ACTIVITY_QUESTION}
            element={<TodayActivityQuestion />}
          />
          <Route
            path={ADMIN_ROUTES.ACTIVITY_ADD_QUESTION}
            element={<TodayActivityAddQuestion />}
          />
          <Route
            path={ADMIN_ROUTES.ADD_CATEGORY_QUICKASK}
            element={<QuickAddCategory />}
          />
          <Route
            path={ADMIN_ROUTES.ADD_CATEGORY_TODAY_ASK}
            element={<TodayActivityAddCategory />}
          />
          <Route path={ADMIN_ROUTES.Payment_Page} element={<Payments />} />
          {/* Samvedna */}
          <Route
            path="/samvedna/notificationmanagement"
            element={<NotificationManagement />}
          />
          <Route
            path="/samvedna/notificationmanagement/email"
            element={<Email />}
          />
          <Route
            path="/samvedna/notificationmanagement/email/add"
            element={<AddEmailTemplate />}
          />
          {/*    <Route path="/appointments/upcoming" element={<AppointmentCards />} /> */}
          {/* <Route path="/reports/session" element={<SessionReports />} /> */}
          {/* <Route path="/reports/patienttest" element={<PatientTestReports />} /> */}
          {/* <Route path="/services/dementia" element={<ServicesDementia />} /> */}
          {/* <Route path="/myaccount/health-profile" element={<Health />} /> */}
        </Route>
      </Route>
      <Route path="*" element={<NotExist />} />
    </Routes>
  );
};

export default AdminRoutes;
