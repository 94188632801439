import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Btn } from "../../UI/Button";
import styles from "./doctor.module.css";

function DoctorsCard({ data, openPopup }) {
  return (
    <div>
      <Box sx={{ margin: "10px" }}>
        <Grid container spacing={1}>
          {data?.map((item) => (
            <Grid
              item
              xs={3}
              className={styles.gridcard}
              style={{ padding: "0px" }}
            >
              <Card
                style={{
                  height: "198px",
                  borderRadius: "20px",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
                }}
              >
                <CardMedia
                  sx={{ height: 140 }}
                  image={data.image}
                  title="doctor"
                  className={styles.cardMedia}
                />
                <CardContent
                  style={{ paddingBottom: "16px" }}
                  className={styles.CardContent}
                >
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "19px",
                      display: "flex",
                      color: "#2A2A2A",
                      fontFamily: "Public Sans",
                      marginBottom: "10px",
                    }}
                  >
                    {item.name}
                  </Typography>

                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    style={{
                      fontWeight: "600",
                      fontSize: "12px",
                      lineHeight: "16px",
                      display: "flex",
                      color: "#2A2A2A",
                      fontFamily: "Public Sans",
                      marginBottom: "15px",
                    }}
                  >
                    {item.qualification}
                  </Typography>

                  <Btn
                    click={openPopup}
                    width={"100%"}
                    height={36}
                    variant="contained"
                  >
                    Details
                  </Btn>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}

export default DoctorsCard;
