import React from "react";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import Tables from "../../Hooks/Table";
import Patientsdata from "../../MockData/Patientsdata.json";
import clientData from "../../MockData/Clients.json";
import CustomizedTabs from "../../Component/UI/Tabs/CustomizedTabs"
import ButtonName from "../../MockData/ButtonName.json";
// import PaperWrapper from "../../Component/UI/PaperWrapper/PaperWrapper";
// import Text_Seach_Filter_Export from "../../Hooks/Text_Seach_Filter_Export";

export default function AppointmentUserData() {
  const btnApi = ButtonName.Patients;
  const pcolumns = Object.keys(Patientsdata[0]);
  const ccolumns = Object.keys(clientData[0]);

  //TODO:patients
  const patients = <Tables theadData={pcolumns} tbodyData={Patientsdata} />;

  //TODO:clients
  const clients = <Tables theadData={ccolumns} tbodyData={clientData} />;
  const ComponentName = [patients,clients]
  return (
    <CustomizedTabs ComponentName={ComponentName} btnApi={btnApi} text={"Patients"}/>
  );
}
