import React from "react";
import styles from "../Assessment_Plans/plans.module.css";
import NoPlans from "../../../assets/icons/NoPlans";

const NoServices = () => {
  return (
    <>
      <div>
        <div className={styles.plansContainer}>
          <div>
            <NoPlans />
          </div>
          <p>No active services in this Category.</p>
        </div>
      </div>
    </>
  );
};

export default NoServices;
