import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "../../../../../node_modules/moment/moment";
import { Toaster } from "../../../../../node_modules/react-hot-toast/dist/index";
import Text_Seach_Filter_Export from "../../../../Hooks/Text_Seach_Filter_Export";
import {
  getAcceptedFeedbacks,
  getNewFeedbacks,
  getRejectedFeedbacks,
} from "../../../../Redux/Slices/adminFeedback";
import BasicTabs from "../../../UI/Tab/Tabs";
import { AcceptedFeedback } from "./TabComponents/AcceptedFeedback";
import { NewFeedback } from "./TabComponents/NewFeedback";
import { RejectedFeedbacks } from "./TabComponents/RejectedFeedbacks";
import { usePermissions } from "../../../../Hooks/usePermissions";
import { findFunc } from "../../../../config/methods";

export const Feedbacks = () => {
  const permissions = usePermissions("name", "samvedna");
  const feedbackPermissions = findFunc(
    permissions?.subCategories,
    "listName",
    "User Feedback"
  );
  const [count, setCount] = useState(0);
  const { newFeedbacks, rejectedFeedbacks, acceptedFeedbacks } = useSelector(
    (i) => i.adminFeedback
  );

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(getNewFeedbacks([]));
      dispatch(getAcceptedFeedbacks([]));
      dispatch(getRejectedFeedbacks([]));
    };
  }, []);

  const tabData = [
    {
      label: `New Feedback(${newFeedbacks.length})`,
      component: <NewFeedback feedbackPermissions={feedbackPermissions} />,
    },
    {
      label: "Accepted",
      component: <AcceptedFeedback feedbackPermissions={feedbackPermissions} />,
    },
    {
      label: "Rejected",
      component: (
        <RejectedFeedbacks feedbackPermissions={feedbackPermissions} />
      ),
    },
  ];

  const exportData = newFeedbacks.length
    ? newFeedbacks
    : rejectedFeedbacks.length
    ? rejectedFeedbacks
    : acceptedFeedbacks;

  const exportFeedbackData = exportData.map(
    ({ id, feedback, customer, created_at, feedback_type, rating }) => {
      const date = moment(created_at).format("DD/MM/YYYY");
      return {
        ID: id,
        Name: customer.name,
        Date: date,
        "Feedback Type": feedback_type,
        Rating: rating,
        Description: feedback,
      };
    }
  );

  return (
    <>
      <Toaster />
      <Text_Seach_Filter_Export
        permissions={feedbackPermissions}
        ApiData={exportFeedbackData}
        text="Feedbacks"
      >
        <BasicTabs
          tabcontent={tabData}
          value={count}
          setValue={setCount}
          fullWidth
        />
      </Text_Seach_Filter_Export>
    </>
  );
};
