import React, { useState } from "react";
import styles from "../landing.module.css";
import CarouselSimple from "react-simply-carousel";
import { TestimonialCard } from "../../Components/UI/TestimonialCard";
import PractusLogo from "../../Components/Images/PractusLogo.png";
import Successpact from "../../Components/Images/Successpact.png";
import teamPumpkin from "../../Components/Images/team_pumpkin.jpg";
import MamyPoko from "../../Components/Images/MamyPoko.jpg";
import UserImg from "../../../assets/icon/user.svg";
import leftArrow from "../../Components/Images/Testmonial/leftArrow.svg";
import rightArrow from "../../Components/Images/Testmonial/rightArrow.svg";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { getUnmappedTestimonials } from "../../../services/enterprise/prelogin";

export const Testimonial = () => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [testimonials, setTestimonials] = useState();
  useEffect(() => {
    getUnmappedTestimonials()
      .then((res) => {
        res.data.list.length && setTestimonials(res.data.list);
      })
      .catch((err) => console.log(err));
  }, []);

  const testimonialsListdata = testimonials?.map((item) => (
    <TestimonialCard
      paragraph={item?.thoughts}
      name={item?.lead?.name}
      role={(item?.lead?.designation_name, item?.lead?.company_name)}
      profile={item?.lead?.img_url}
    />
  ));

  console.log(
    <TestimonialCard
      paragraph="uuu"
      name="Rachana Narayanan"
      role="DGM, Head HR, Cadila Pharmaceuticals Limited"
      profile={UserImg}
    />,
    testimonialsListdata,
    "testimonials-"
  );
  return (
    <div className={styles.testimonial}>
      <img src={leftArrow} alt="" className={styles.leftArrow} />
      <p className={styles.testimonialHeading}>Testimonials</p>
      <div>
        {testimonials && (
          <CarouselSimple
            activeSlideIndex={activeSlideIndex}
            onRequestChange={setActiveSlideIndex}
            containerProps={{
              style: {
                borderRadius: "0 50px",
              },
            }}
            activeSlideProps={{
              style: {
                borderRadius: "0 50px",
              },
            }}
            itemsToShow={1}
            itemsToScroll={1}
            forwardBtnProps={{
              style: {
                display: "none",
              },
            }}
            backwardBtnProps={{
              style: {
                display: "none",
              },
            }}
            dotsNav={{
              show: true,
              itemBtnProps: {
                style: {
                  height: 12,
                  width: 12,
                  borderRadius: "50%",
                  border: 12,
                  margin: "10px",
                  position: "relative",
                  bottom: "-40px",
                },
              },
              activeItemBtnProps: {
                style: {
                  height: 12,
                  width: 12,
                  borderRadius: "50%",
                  border: 0,
                  background: "#FFE600",
                  margin: "10px",
                  position: "relative",
                  bottom: "-40px",
                },
              },
            }}
            responsiveProps={[
              {
                itemsToShow: 1,
                itemsToScroll: 1,
                minWidth: "auto",
              },
            ]}
            speed={300}
            easing="linear"
          >
            {testimonials?.map((item) => (
              <TestimonialCard
                paragraph={item?.thoughts}
                name={item?.lead?.name}
                role={`${
                  item?.lead?.testimonial_by != null
                    ? item?.lead?.testimonial_by
                    : ""
                }, ${item?.lead?.company_name}`}
                profile={item?.lead?.img_url}
              />
            ))}
          </CarouselSimple>
        )}
      </div>
      <img src={rightArrow} alt="" className={styles.rightArrow} />
    </div>
  );
};
