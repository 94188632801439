import React from "react";
import DragNDrop from "../../../../../Hooks/Drag&Drop/DragNDrop";

export const SessionMedia = () => {
  return (
    <div>
      <DragNDrop />
    </div>
  );
};
