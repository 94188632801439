import { ReportsCard } from "../../UI/ReportsCard";
import styles from "../../../assets/css/PatientDashboard/reports.modules.css";
import { Btn } from "../../UI/Button";
import { useState } from "react";
import { useEffect } from "react";
import { getEnterPriseReports } from "../../../services/patient/reports";
import moment from "../../../../node_modules/moment/moment";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";
import { EmptyReports } from "../../UI/EmptyReports";

export const EnterprisesAssessments = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [enterPriseList, setEnterPriseList] = useState();
  useEffect(() => {
    getSelfAssessments();
  }, []);
  const getSelfAssessments = () => {
    setIsLoading(true);
    getEnterPriseReports().then((res) => {
      console.log(res, "res---");
      setIsLoading(false);
      setEnterPriseList(res);
    });
  };
  console.log(enterPriseList, "enterPriseList");
  return (
    <>
      {!isLoading ? (
        <div
          className={styles.service_card}
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            columnGap: "20px",
          }}
        >
          {enterPriseList?.length ? (
            enterPriseList?.map((item) => (
              <ReportsCard
                status={item?.status}
                name={item?.assessment_category?.name}
                date={item?.assessment_category?.date_of_creation}
                pdfLink={item?.customer_pdf}
              />
            ))
          ) : (
            <EmptyReports />
          )}
        </div>
      ) : (
        <CircularProgress size={28} />
      )}
    </>
  );
};
