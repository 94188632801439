import styles from "../../../assets/css/PatientDashboard/reports.modules.css";
import { SessionInfoCard } from "../../../Component/UI/SessionInfoCard";
import { CustomizedAccordions } from "../../../Component/UI/Accordion/CustomizedAccordions";
import Tables from "../../../Hooks/Table";
import { Btn } from "../../UI/Button";

export const ReportsSummary = () => {
  const columns = ["Appearance", "Next"];
  const clientData = [];
  const table = <Tables theadData={columns} tbodyData={clientData} />;

  function createData(heading, content) {
    return { heading, content };
  }

  const values = [
    ["General Observations", table],
    ["mood", table],
    ["Cognition", table],
    ["Perceptions"],
    ["Thoughts"],
    ["Others"],
  ];

  const data = values.map((i) => createData(...i));

  return (
    <div className={styles.generatedReports}>
      <SessionInfoCard />
      {data.map(({ heading, content }) => (
        <div style={{ margin: "15px 0" }}>
          <CustomizedAccordions heading={heading} content={content} />
        </div>
      ))}
      <Btn variant="contained" width="160px" height="48px">
        Download Report
      </Btn>
    </div>
  );
};
