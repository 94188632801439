import { useEffect, useState } from "react";
import moment from "../../../../../../node_modules/moment/moment";
import { Link } from "../../../../../../node_modules/react-router-dom/index";
import { Edit } from "../../../../../assets/icons/Edit";
import { CustomTable } from "../../../../../Component/UI/Table";
import { getEmailTemplate } from "../../../../../services/admin/emailTemplate";
import { Options } from "./Options";

export const Email = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    getEmailTemplate()
      .then((res) => setData(res.data.list))
      .catch();
  }, []);

  const tBodyData = data.map(
    ({ temaplate_name, description, updated_at, subject }) => {
      const date = moment(updated_at).format("DD/MM/YYYY");
      return {
        temaplate_name,
        description,
        date,
        action: (
          <Link to="edit" state={{ temaplate_name, description, subject }}>
            <Edit />
          </Link>
        ),
      };
    }
  );

  console.log(data);

  return (
    <>
      <Options />
      <CustomTable
        tHead={["Name", "Description", "Updated on ", "Action"]}
        tBody={tBodyData}
      />
    </>
  );
};
