import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Edit } from "../../../../assets/icons/Edit";
import { CustomTable } from "../../../../Component/UI/Table";
import axios from "../../../../config/axiosConfig";
import { getAssessmentQues } from "../../../../Redux/Slices/assesmentQuestions";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomizedButtons from "../../../../Component/UI/Button/CustomizeButton";
import { TEXT } from "../../../contant/constant";
import { Cross } from "../../../../assets/icons/Cross";
import { delAssessmentQuest } from "../../../../services/admin/assessmentQuestions";
import { Toaster } from "react-hot-toast";
import toast from "react-hot-toast";
import { DragDropTable } from "../../../../Component/UI/DragDropTable";

export const Questions = ({ id, ass_id, setCount }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  // const { cid } = location?.state;
  const navigate = useNavigate();
  const { quest } = useSelector((i) => i.assesmentQuestions);

  const { cat_id } = useSelector((state) => state.assessmentId);
  const { status } = useSelector((state) => state.changeSequence);

  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    axios
      .get(`/admin/assessment/questions/2?category_id=${id}`)
      .then((res) => {
        if (res.status) {
          dispatch(getAssessmentQues(res.data.question));
        }
      })
      .catch((err) => console.log(err));
    setIsDeleted(false);
  }, [isDeleted, status]);

  const deleteAssessment = (id) => {
    const response = window.confirm("Do you want to delete this Question?");
    if (response)
      delAssessmentQuest(id)
        .then(({ data }) => {
          if (data?.status) {
            setIsDeleted(data?.status);
            toast.success(data?.msg);
          }
        })
        .catch((_) => {
          toast.error("Something went wrong!");
        });
  };

  useEffect(() => {
    return () => {
      toast.remove();
      dispatch(getAssessmentQues([]));
    };
  }, []);

  const tBodyData = quest?.map(
    ({ question, serial_number, id, category_id, scores }, i) => {
      i++;
      return {
        serial_number: serial_number.toString(),
        question,

        action: (
          <div style={{ display: "flex" }}>
            {id === TEXT.QIDNO ? (
              <Edit active={true} />
            ) : (
              <Link
                to={"/smv-admin/assessment/questions/edit"}
                state={{
                  qid: id,
                  sno: serial_number,
                  cid: category_id,
                  apiflag: TEXT.COMEINGTOEDIT,
                }}
              >
                <Edit />
              </Link>
            )}
            {id === TEXT.QIDNO ? (
              <span style={{ marginLeft: "6px" }}>
                <Cross active={true} />
              </span>
            ) : (
              <span
                style={{ marginLeft: "6px", cursor: "pointer" }}
                onClick={() => deleteAssessment(id)}
              >
                <Cross />
              </span>
            )}
          </div>
        ),
      };
    }
  );

  const catid = ass_id === "" || ass_id === undefined ? cat_id : ass_id;

  const AddBtnHandler = () => {
    navigate("/smv-admin/assessment/questions/edit", {
      state: { cid: id, sno: 1, qid: 0, apiflag: TEXT.DIRECTCOMING },
    });
  };

  const tHead = ["Q.NO. ", "Question", "Action"];
  return (
    <>
      <Toaster />
      <div style={{ position: "absolute", top: "15%", left: "93%" }}>
        <CustomizedButtons
          ButtonLabel={"Add"}
          width={""}
          click={AddBtnHandler}
        />
      </div>
      <DragDropTable tHead={tHead} tBody={tBodyData} id={id} wrapper />
      {/* <CustomTable tHead={tHead} tBody={tBodyData} wrapper /> */}
    </>
  );
};
