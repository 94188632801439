import React from "react";
import styles from "./chart.module.css";
import ReactApexChart from "react-apexcharts";
import Excellent from "./images/Excellent.svg";
import Happy from "./images/Happy.svg";
import Upset from "./images/Upset.svg";
import Angry from "./images/Angry.svg";
import Sad from "./images/Sad.svg";

class MoodScoreChart extends React.Component {
  constructor(props) {
    super(props);
    const d = props.getMoodActivity;
    const apiData = [
      d?.["Very Good Mood"],
      d?.["Regular Mood"],
      d?.["Feeling Low"],
      d?.["Feeling Angry"],
      d?.["Feel Like Crying"],
    ];
    const sum = apiData.reduce((i, a) => i + a);
    const percentageValue = apiData.map((i) => {
      const perctValue = (i / sum) * 100;
      return Math.round(perctValue);
    });
    this.state = {
      series: percentageValue,
      noData: {
        text: "No data available",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "14px",
        },
      },
      options: {
        plotOptions: {
          pie: {
            donut: {
              size: "45%",
            },
          },
        },
        chart: {
          width: 250,
          type: "pie",
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (value) {
              return value + "%";
            },
          },
        },
        colors: ["#66CC39", "#AAC514", "#FFD936", "#FBAA34", "#F77B33"],
        labels: ["Excellent", "Happy", "Upset", "Angry", "Sad"],
        //labels: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.getMoodActivity !== this.props.getMoodActivity) {
      const apiDa = [
        this.props.getMoodActivity?.["Very Good Mood"],
        this.props.getMoodActivity?.["Regular Mood"],
        this.props.getMoodActivity?.["Feeling Low"],
        this.props.getMoodActivity?.["Feeling Angry"],
        this.props.getMoodActivity?.["Feel Like Crying"],
      ];
      const sum = apiDa.reduce((i, a) => i + a);
      const percentageValue = apiDa.map((i) => {
        const perctValue = (i / sum) * 100;
        return Math.round(perctValue);
      });
      this.setState({ ...this.state, series: percentageValue });
    }
  }
  render() {
    return (
      <div className={styles.mainChart} id="chart">
        {this.props.attemptedPerson ? (
          <>
            <ReactApexChart
              options={this.state.options}
              series={this.state.series}
              type="donut"
              width={"100%"}
            />
            <div className={styles.customLabel}>
              <div className={styles.firstRow}>
                <div>
                  <img src={Excellent} alt="Excellent" /> Excellent
                  <br />
                  {this.state.series[0] + "%"}
                </div>
                <div>
                  <img src={Happy} alt="Happy" /> Happy
                  <br />
                  {this.state.series[1] + "%"}
                </div>
              </div>
              <div className={styles.secondRow}>
                <div>
                  <img src={Upset} alt="Upset" /> Upset
                  <br />
                  {this.state.series[2] + "%"}
                </div>
                <div>
                  <img src={Angry} alt="Angry" /> Angry
                  <br />
                  {this.state.series[3] + "%"}
                </div>
              </div>
              <div className={styles.thirdRow}>
                <div>
                  <img src={Sad} alt="Sad" /> Sad
                  <br />
                  {this.state.series[4] + "%"}
                </div>
                <div></div>
              </div>
            </div>
          </>
        ) : (
          <div
            style={{
              height: 220,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>No Data Available</p>
          </div>
        )}
      </div>
    );
  }
}

export default MoodScoreChart;
