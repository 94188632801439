import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "../../../../../../node_modules/moment/moment";
import { useNavigate } from "../../../../../../node_modules/react-router-dom/index";
import { Check } from "../../../../../assets/icons/Check";
import { Cross } from "../../../../../assets/icons/Cross";
import { Avatar } from "../../../../../Component/UI/Avatar";
import Popup from "../../../../../Component/UI/Popup/Popup";
import Popup2 from "../../../../../Component/UI/Popup/Popup2";
import { CustomTable } from "../../../../../Component/UI/Table";
import { getNewFeedbacks } from "../../../../../Redux/Slices/adminFeedback";
import {
  setFilter,
  setFrom_date,
  setSearch_key,
  setTo_date,
} from "../../../../../Redux/Slices/AdminFilters/Search";
import {
  listFeedback,
  updateFeedback,
} from "../../../../../services/admin/feedback";
import { Rating } from "../../Testimonial/TabComponents/Rating";

export const NewFeedback = ({ customerId, feedbackPermissions, pFeedback }) => {
  const { newFeedbacks } = useSelector((i) => i.adminFeedback);
  const [popupAction, setPopupAction] = useState({ open: false });
  const [popupActionRej, setPopupActionRej] = useState({ open: false });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fullpermissions = pFeedback
    ? 1
    : +feedbackPermissions?.access?.["Full Control"];
  const writePermissions = pFeedback ? 1 : +feedbackPermissions?.access?.Write;
  console.log(writePermissions, "writePermissions");
  const tableHeadings = [
    "Date",
    "Name",
    "Rating",
    "Service Name",
    "Description",
    ...(writePermissions ? ["Action"] : []),
  ];

  // ...(fullpermissions ? ["Status"] : []),
  // ...(writePermissions ? ["Action"] : []),

  const { search_key, from_date, to_date, filter } = useSelector(
    (i) => i.SearchFilters
  );

  const getNewFeedbackList = () =>
    listFeedback(1, customerId, search_key, from_date, to_date).then((res) => {
      const data = res?.data;
      if (data?.status) dispatch(getNewFeedbacks(data?.list));
    });

  useEffect(() => {
    getNewFeedbackList();
  }, [filter]);

  useEffect(() => {
    return () => {
      dispatch(setSearch_key(""));
      dispatch(setTo_date(""));
      dispatch(setFrom_date(""));
      dispatch(setFilter());
    };
  }, []);

  const handleApprove = (id, status) => {
    setPopupAction({
      ...popupAction,
      open: true,
      action: () => {
        updateFeedback(id, status).then((res) => {
          const data = res?.data;
          if (data?.status) {
            getNewFeedbackList();
            setPopupAction({ ...popupAction, open: false });
          }
        });
      },
    });
  };

  const handleReject = (id, status) => {
    setPopupActionRej({
      ...popupActionRej,
      open: true,
      action: () => {
        updateFeedback(id, status).then((res) => {
          const data = res?.data;
          if (data?.status) {
            getNewFeedbackList();
            setPopupActionRej({ ...popupActionRej, open: false });
          }
        });
      },
    });
  };

  const tBodyData = newFeedbacks.map((item) => {
    const { id, feedback, customer, created_at, feedback_type, rating } = item;
    const date = moment(created_at).format("DD/MM/YYYY");
    return {
      date,
      name: (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            height: 0,
          }}
        >
          <Avatar
            size="28px"
            profile={customer?.customer_image}
            border="1px solid #ccc"
          />
          <div
            style={{ cursor: "pointer", marginLeft: "8px" }}
            onClick={() => navigate("details", { state: { item, customerId } })}
          >
            {customer?.name}
          </div>
        </span>
      ),
      rating: <Rating ratings={rating} />,
      feedback_type,
      feedback: `${feedback.slice(0, 30)}${feedback.length > 30 ? "..." : ""}`,
      ...(writePermissions && {
        action: (
          <div style={{ display: "flex" }}>
            <div onClick={() => handleApprove(id, 2)}>
              <Check />
            </div>
            <div onClick={() => handleReject(id, 3)}>
              <Cross />
            </div>
          </div>
        ),
      }),
    };
  });

  return (
    <>
      <Popup
        popupAction={popupAction}
        setPopupAction={setPopupAction}
        heading="Accept Feedback"
        title="Are you sure you want to approve this feedback?"
        styles={{ width: 400, height: 230 }}
        btnMargin="0 10px"
      />
      <Popup2
        popupAction={popupActionRej}
        setPopupAction={setPopupActionRej}
        heading="Reject Feedback"
        title="Are you sure you want to reject this feedback?"
        styles={{ width: 400, height: 230 }}
        btnMargin="0 10px"
      />
      <CustomTable tHead={tableHeadings} tBody={tBodyData} />
    </>
  );
};
