import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";
import { StringDtoY } from "../../config/formattedDate";

const { WELLNESS_TRACKER_ACTIVITY_MONTH } = API;

export const Wellness_Tracker_Activity = async ({
  month,
  year,
  from,
  to,
  id,
}) => {
  const from_date = from ? StringDtoY(new Date(year, month, from)) : "";
  const to_date = to ? StringDtoY(new Date(year, month, to)) : "";

  console.log(month, year);

  return await axios
    .get(
      `${WELLNESS_TRACKER_ACTIVITY_MONTH}?activity_month=${++month}&activity_year=${year}&from_date=${from_date}&to_date=${to_date}&patient_id=${id}`
    )
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
