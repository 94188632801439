import React from "react";
import styles from "./myaccount.module.css";
import { useAuth } from "../../../Auth/AuthProvider";

export const Profile = () => {
  const ctx = useAuth();
  console.log(ctx, "ctx----");
  const profileData = ctx?.loginUser?.admin;
  // const accessName = profileData?.role_name.find(
  //   (i) => i?.id?.tostring() === profileData?.access_role
  // );
  // console.log(accessName, "accessName");
  return (
    <div className={styles.mainProfile}>
      <div className={styles.wrapper}>
        <h2>Personal Details</h2>
        <div className={styles.mainProfileSec}>
          <div>
            <p className={styles.name}>{"Name"}</p>
            <p className={styles.value}>
              {profileData?.name ? profileData?.name : "-"}
            </p>
          </div>
          <div>
            <p className={styles.name}>{"Date of Birth"}</p>
            <p className={styles.value}>
              {profileData?.dob ? profileData?.dob : "-"}
            </p>
          </div>
          <div>
            <p className={styles.name}>{"Email Address"}</p>
            <p className={styles.value}>
              {profileData?.email ? profileData?.email : "-"}
            </p>
          </div>
          <div>
            <p className={styles.name}>{"Mobile Number"}</p>
            <p className={styles.value}>
              {profileData?.mobile ? profileData?.mobile : "-"}
            </p>
          </div>
          <div>
            <p className={styles.name}>{"Role"}</p>
            <p className={styles.value}>
              {profileData?.access_role_name
                ? profileData?.access_role_name
                : "-"}
            </p>
          </div>
          <div>
            <p className={styles.name}>{"Qualification"}</p>
            <p className={styles.value}>
              {profileData?.qualification ? profileData?.qualification : "-"}
            </p>
          </div>
          <div>
            <p className={styles.name}>{"Specialization"}</p>
            <p className={styles.value}>
              {profileData?.specialization ? profileData?.specialization : "-"}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <h2>Address</h2>
        <div className={styles.mainProfileSec}>
          <div>
            <p className={styles.name}>{"Current Address"}</p>
            <p className={styles.value}>
              {profileData?.current_address
                ? profileData?.current_address
                : "-"}
            </p>
          </div>
          <div>
            <p className={styles.name}>{"Permanent Address"}</p>
            <p className={styles.value}>
              {profileData?.permanent_address
                ? profileData?.permanent_address
                : "-"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
