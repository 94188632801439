import React, { useEffect, useState } from "react";
import classess from "../../../../assets/css/assessment/range.module.css";
import User from "../../../../assets/icon/user.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  selOption,
  selScore,
  setRelation,
} from "../../../../Redux/Reducers/PatientReducers/qa_storage/qa_storage";

export const RadioBtn = (props) => {
  const dispatch = useDispatch();

  const { selectedOption } = useSelector((state) => state.qa_storage);

  const { question } = props;
  const options = question.options?.split(",");
  // const option_img = question.option_img || [];
  const option_img = question?.option_images?.split("&&") || [];

  let ClickedbyUser = `${selectedOption}_${question.serial_number}`;

  const [clicked, setClicked] = useState(null);

  const handleClick = (mutid) => {
    let id = mutid.split("_");
    assessmentData(id[0]);
    setClicked(mutid);
  };

  useEffect(() => {
    setClicked(ClickedbyUser);
  }, [ClickedbyUser]);

  const assessmentData = (id) => {
    let indx = options.indexOf(id);
    const score = question.scores.split(",")[indx];
    dispatch(selOption(id));
    dispatch(selScore(score));
    if (question?.question.toLowerCase().includes("their relation")) {
      dispatch(setRelation(id));
    }
  };
  const w = window.innerWidth;
  const h = window.innerHeight;

  const minWidth = w < 600 ? "200px" : "240px";
  const fontSize = w < 600 ? "15px" : "20px";

  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignContent: "flex-start",
          flexDirection:
            option_img[0] !== ""
              ? "row"
              : options.length <= 3
              ? "column"
              : "row",
          alignItems: "center",
          height: "auto",
        }}
      >
        {options?.map((option, index) => {
          if (option === "") {
            return null;
          }
          return (
            <>
              <div
                key={index}
                id={`${option}_${question.serial_number}`}
                onClick={() =>
                  handleClick(`${option}_${question.serial_number}`)
                }
                className={classess.radio}
                style={{
                  width: "fit-content",
                  minWidth: minWidth,
                  boxSizing: "border-box",
                  // height: option_img[index] ? "100%" : "auto",
                  minHeight: "50px",
                  border: "2px solid rgb(182, 182, 182)",
                  margin: "5px 20px",
                  // marginBottom:marginBottomResponsive,
                  borderRadius: "8px",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: option_img[index] ? "30px 30px" : "10px 30px",
                  borderColor:
                    clicked === `${option}_${question.serial_number}`
                      ? "#7a51ae"
                      : "#b6b6b6",
                  background:
                    clicked === `${option}_${question.serial_number}`
                      ? "#7a51ae50"
                      : "",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                  }}
                  className={classess.ra}
                >
                  {option_img[index] && (
                    <img
                      src={option_img[index]}
                      width="75"
                      height="75"
                      alt={option}
                      style={{ objectFit: "contain" }}
                    />
                  )}
                  <h6
                    style={{
                      fontSize: fontSize,
                      lineHeight: "24px",
                      fontWeight: "400",
                    }}
                  >
                    {option}
                  </h6>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};
