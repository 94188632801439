import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import { RiCloseLine } from "react-icons/ri";
import styles from "../../../assets/css/PatientUI/servicepopup.module.css";
import { Input, SelectWithId } from "../CustomInputs";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";
import logo from "../../../assets/icons/icon/logo.svg";
import { useNavigate } from "../../../../node_modules/react-router-dom/index";
import CountryCodes from "../../../assets/common/countryCode.json";
const closeBtn = {
  cursor: "pointer",
  fontWeight: "500",
  padding: "5px 8px",
  borderRadius: "8px",
  border: "none",
  fontSize: "18px",
  color: "#2c3e50",
  background: "white",
  transition: "all 0.25s ease",
  boxShadow: "0 5px 20px 0 rgb(0 0 0 / 6%)",
  position: "relative",
  bottom: "0px",
  left: "0px",
};

export default function PreLoginPopup({
  popupAction,
  setPopupAction,
  heading,
  mobileNo,
  setMobileNo,
  payload,
  submitMoNoForm,
  isLoading,
  setDisableBtn,
  helpdesk,
  isLoadingUser,
  mobileNoError,
  invalidNoError,
  setCountryCode,
  countryCode,
}) {
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("/user/signin");
    setDisableBtn(true);
    setPopupAction({ ...popupAction, open: false });
    window.location.reload(false);
  };
  console.log(mobileNoError, "mobileNoError--");
  return (
    <div>
      {isLoadingUser ? (
        ""
      ) : (
        <Modal open={popupAction.open}>
          <Box className={styles.loginPopup}>
            <div
              className={styles.mainHeader}
              style={{
                width: "100%",
                borderTopLeftRadius: "19px",
                borderTopRightRadius: "19px",
                color: "white",
                fontSize: "18px",
                fontWeight: "600",
                padding: "5px",
                textTransform: "uppercase",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <div className={styles.mainLogo}>
                <img className={styles.logo} src={logo} alt="logo" />
              </div>
              <div
                style={{
                  alignSelf: "center",
                  width: "100%",
                  color: "#000000",
                  textTransform: "initial",
                }}
              >
                {heading}{" "}
              </div>
              <button style={closeBtn} onClick={handleClose}>
                <RiCloseLine style={{ marginBottom: "-3px" }} />
              </button>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ marginBottom: "20px" }}>
                <div
                  style={{
                    marginBottom: "0",
                    lineHeight: "15px",
                    fontSize: "15px",
                    letterSpacing: "0.02857em",
                  }}
                >
                  Name:
                </div>
                <Input
                  value={popupAction?.name}
                  // width="260px"
                  // error={serviceFor.err}
                />
              </div>
              <div style={{ marginBottom: "20px" }}>
                <div
                  style={{
                    marginBottom: "0",
                    lineHeight: "15px",
                    fontSize: "15px",
                    letterSpacing: "0.02857em",
                  }}
                >
                  Email:
                </div>
                <Input
                  value={popupAction?.email}
                  // width="260px"
                  // error={serviceFor.err}
                />
              </div>
              <div className={styles.moNoSec}>
                <div
                  className={styles.countryCode}
                  style={{ marginBottom: "5px" }}
                >
                  <div
                    style={{
                      marginBottom: "0",
                      lineHeight: "15px",
                      fontSize: "15px",
                      letterSpacing: "0.02857em",
                    }}
                  >
                    Country Code:
                  </div>
                  <SelectWithId
                    width="140px"
                    options={CountryCodes}
                    value={countryCode}
                    onChange={(e) => {
                      debugger;
                      setCountryCode(e.target.value);
                    }}
                  />
                </div>
                <div
                  className={styles.mobileNo}
                  style={{ marginBottom: "5px" }}
                >
                  <div
                    style={{
                      marginBottom: "0",
                      lineHeight: "15px",
                      fontSize: "15px",
                      letterSpacing: "0.02857em",
                    }}
                  >
                    Mobile No:
                  </div>
                  <Input
                    max={10}
                    placeholder="Please Enter Mobile No"
                    value={mobileNo}
                    type="number"
                    onChange={(e) => {
                      setMobileNo(e.target.value);
                    }}
                    req
                  />
                  {mobileNoError ? (
                    <p style={{ color: "red" }}>{mobileNoError}</p>
                  ) : (
                    ""
                  )}
                  {invalidNoError ? (
                    <p style={{ color: "red" }}>{invalidNoError}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div style={{ marginBottom: "10px" }}>
                Incase you have any queries please call our helpdesk-{" "}
                <b>7428097301</b>
              </div>
              <div style={{ textAlign: "center" }}>
                {isLoading ? <CircularProgress size={28} /> : ""}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "-webkit-fill-available",
                  margin: "4px 0",
                  gap: "20px",
                }}
              >
                <Btn
                  bgColor="#7A51AE"
                  width="160px"
                  height="40px"
                  variant="contained"
                  disabled={isLoading}
                  click={submitMoNoForm}
                >
                  Sign In
                </Btn>
                <Btn
                  bgColor="#FFF"
                  color="#000"
                  variant="contained"
                  width="160px"
                  height="40px"
                  click={handleClose}
                >
                  Close
                </Btn>
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
}
