import styles from "../../../../../assets/css/UI/customInputs.module.css";

export const SelectCardRoster = ({
  options,
  label,
  style,
  labelstyle,
  onChange,
  name,
  value,
  flag,
  error,
  titleMargin,
  width,
  optionStyle,
  disabled,
}) => {
  return (
    <div className={styles.accordion_opt} style={{ width }}>
      <div className={styles.accordion_flex} style={style}>
        {options?.map((option, i) => (
          <>
            <label className={styles.label} style={labelstyle}>
              <input
                type="radio"
                name={name}
                value={i}
                checked={value === i.toString()}
                onChange={onChange}
                disabled={disabled}
              />
              <div style={optionStyle} key={i}>
                {option}
              </div>
            </label>
          </>
        ))}
      </div>
      <p style={{ color: "red", margin: "5px" }}>{error?.toString()}</p>
    </div>
  );
};
