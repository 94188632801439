import React, { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import ControlledOpenSelect from "../../../../Component/UI/ControlledOpenSelect/ControlledOpenSelect";
import InputField from "../../../../Component/UI/InputField/InputField";
import ResultsScoreData from "../../../../MockData/ResultsScoring.json";
import { RiDeleteBinLine } from "react-icons/ri";
import CustomizedButtons from "../../../../Component/UI/Button/CustomizeButton";
import { useDispatch, useSelector } from "react-redux";
import { getResults } from "../../../../Redux/Slices/getResults";
import { resultGet } from "../../../../services/admin/assessment";
import { Select2 } from "../../../../Component/UI/CustomInputs";
import { resultDetails } from "../../../../Redux/Slices/resultDetails";
import Button from "@mui/material/Button";
import axios from "../../../../config/axiosConfig";
import { token } from "../../../../assets/constants";
import { ResultScoringWrapper } from "./ResultScoringWrapper";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  toast,
  Toaster,
} from "../../../../../node_modules/react-hot-toast/dist/index";

// const Initial_Object = [
//   {
//     level: "level 1",
//     range1: "",
//     range2: "",
//     title: "",
//     emaillabel:"Email template level 1",
//     template_value: "Email template level 1",
//     description: "",

//   },
//   {
//     level: "level 2",
//     range1: "",
//     range2: "",
//     title: "",
//     description: "",
//     emaillabel:"Email template level 2",
//     template_value:"Email template level 2"
//   },
//   {
//     level: "level 3",
//     range1: "",
//     range2: "",
//     title: "",
//     description: "",
//     emaillabel:"Email template level 3",
//     template_value:"Email template level 3"
//   },
// ];
// console.log(Initial_Object,"intial object")
export const ResultandScoring = ({ flag, id, setCount }) => {
  const [inputFields, setInputFields] = useState([
    {
      level: "level 1",
      range1: "",
      range2: "",
      title: "",
      emaillabel: "Email template for level 1",
      template_value: "Email template for level 1",
      description: "",
      enterpriseDescription:
        "Employees at Level 1 have reported healthy lifestyle practices. In order to foster a positive and supportive work environment, it is important to maintain a work-life balance and take care of mental wellbeing. Employees at this level are demonstrating good practices. We recommend continuing to use Samvedna’s self-care tool.",
    },
    {
      level: "level 2",
      range1: "",
      range2: "",
      title: "",
      description: "",
      enterpriseDescription:
        "Employees at Level 2 have reported making efforts to prioritize their mental well-being. They display an awareness of the importance of mental health. While they may have a room for improvement, they have a satisfactory level of well-being. These employees are likely to seek support when required. As a best practice, organizations should provide tools and resources to support their mental well-being.",
      emaillabel: "Email template for level 2",
      template_value: "Email template for level 2",
    },
    {
      level: "level 3",
      range1: "",
      range2: "",
      title: "",
      description: "",
      enterpriseDescription:
        "Employees at Level 3 have self-reported concerns about their mental well-being. The individuals in this group may be experiencing difficulty in maintaining their mental and emotional well-being. As a best practice, organizations should provide individualized interventions and resources to help employees at this level effectively manage their mental health and well-being.",
      emaillabel: "Email template for level 3",
      template_value: "Email template for level 3",
    },
  ]);
  const [respData, setRespData] = useState([]);
  const [counter, setCounter] = useState(3);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { ass_id } = location?.state;
  const { cat_id } = useSelector((i) => i.assessmentId);
  const catID = cat_id || id || ass_id;
  //console.log(ass_id, "--ass_id", id, "--id", catID, "catID");

  //console.log(cat_id, "id", flag, " flag");
  useEffect(() => {
    if (id) {
      try {
        axios
          .get(`/admin/get/assessment/scoring?assessment_id=${id}`)
          .then((res) => {
            if (res?.data?.list?.scoring_list?.length >= 3) {
              const result = res?.data?.list?.scoring_list;
              console.log(result, "result");
              setInputFields(
                result.map((i, index) => {
                  // const [range1, range2] = i.range.split("-");
                  // delete i.range;
                  // i.range1 = range1;
                  // i.range2 = range2;
                  // return i;

                  const [range1, range2] = i.range.split("-");
                  const template_value = i.template_value || "";
                  const emaillabel =
                    i.emaillabel || `Email template for level ${index + 1}`;
                  delete i.range;
                  i.range1 = range1;
                  i.range2 = range2;
                  i.template_value = template_value;
                  i.emaillabel = emaillabel;
                  return i;
                })
              );
            }
            // setFlag(true);
          });
      } catch (error) {
        console.log(error, "error");
      }
    }
  }, []);

  // console.log(inputFields, "___get");

  const addScoring = () => {
    const Data = new FormData();
    Data.append("assessment_id", catID);

    const a = inputFields.map((i) => {
      if (i.range1 || i.range2) {
        return `${i.range1 || "0"}-${i.range2 || "0"}`;
      } else return "";
    });

    setInputFields(
      inputFields.map((e, i) => {
        delete e.range1;
        delete e.range2;
        e.range = a[i];
        return e;
      })
    );

    Data.append("scoring", JSON.stringify(inputFields));

    let validationCheck = inputFields.every((item) => {
      return item.template_value !== "";
    });

    if (validationCheck) {
      try {
        axios.post("/admin/add/assessment/scoring", Data).then((res) => {
          //   setInputFields(res.data.list);
          //   setFlag(true);
          navigate("/Assessments");
        });
      } catch (error) {
        console.log(error, "error");
      }
    } else {
      toast.remove();
      toast.error("Email template value is required");
    }
  };

  // console.log(inputFields, "__");

  const setData = (e) => {
    let test_value = e.value;
    //console.log(inputFields);
    // if (inputFields[e.key]?.range == "") {
    //   inputFields[e.key].range = "0-0";
    // }
    // if (e.name == "range1") {
    //   e.name = "range";
    //   test_value = e.value + "-" + inputFields[e.key]?.range?.split("-")?.[1];
    // } else if (e.name == "range2") {
    //   e.name = "range";
    //   test_value = inputFields[e.key]?.range?.split("-")?.[0] + "-" + e.value;
    // }
    //console.log("test_value: ", test_value);
    // inputFields[e.key][e.name] = test_value;
    // setInputFields(inputFields);

    setInputFields(
      inputFields.map((val, i) => {
        if (e.key === i) {
          return { ...val, [e.name]: e.value };
        }
        return val;
      })
    );
    setCounter(test_value);
  };

  const CancelHandler = () => {
    navigate("/Assessments");
    // setCount((count) => count - 1);
  };

  return (
    <>
      <Toaster />
      {/* <Button
        variant="contained"
        size="large"
        style={{
          backgroundColor: "black",
          borderRadius: 25,
          padding: "10px 35px",
          float: "right",
        }}
        onClick={handleClick}
      >
        Add
      </Button> */}
      <Grid
        container
        rowSpacing={4}
        marginBottom="10px"
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        {inputFields?.map((data, index) => (
          <ResultScoringWrapper IndexKey={index} set={setData} data={data} />
        ))}
        <Grid item xs={2}>
          <CustomizedButtons
            ButtonLabel={"Cancel"}
            btncoloractive="true"
            width="100px"
            click={CancelHandler}
          />
        </Grid>
        <Grid item xs={3} marginLeft="-5%">
          <CustomizedButtons
            ButtonLabel={"Save"}
            click={addScoring}
            width="100px"
          />
        </Grid>
      </Grid>
    </>
  );
};
