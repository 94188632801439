import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Toaster,
  toast,
} from "../../../../../node_modules/react-hot-toast/dist/index";
import { useNavigate } from "../../../../../node_modules/react-router-dom/index";
import SimpleBreadcrum from "../../../../Component/UI/SimpleBreadcrum";
import { setErrors, setStaff } from "../../../../Redux/Slices/addStaff";
import { Btn } from "../../../../Samvenda-Patient/UI/Button";
import { addStaff } from "../../../../services/admin/staff";
import { Form } from "./Form";
import styles from "./staff.module.css";
import { useState } from "react";
import { CircularProgress } from "../../../../../node_modules/@mui/material/index";

export const AddStaff = () => {
  const { staff } = useSelector((i) => i.addStaff);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { errors } = useSelector((i) => i.addStaff);
  console.log(errors, "errors--");

  const createStaff = () => {
    dispatch(setErrors({}));
    toast.remove();
    const formData = new FormData();
    setIsLoading(true);
    for (let key in staff) formData.append(key, staff[key]);
    addStaff(formData).then((res) => {
      setIsLoading(false);
      const errors = res?.data?.error;
      // const errorLength = Object.keys(errors).length;
      // console.log(errors, "errors++++++");
      // console.log(errorLength, "errorLength");
      // if (errorLength) {
      //   toast.error("Please check required fields");
      // }
      dispatch(setErrors(errors));
      if (res?.data?.status) {
        navigate("/Samvedna/staff");
        toast.success(res?.data?.msg);
      }
    });
  };
  useEffect(() => {
    dispatch(setErrors({}));
  }, []);

  const links = [
    {
      path: "/Samvedna/staff",
      linkText: "Staff",
    },
  ];
  return (
    <>
      <Toaster />
      <div>
        <SimpleBreadcrum text="Add Staff" link={links} />
        <div className={styles.container}>
          <Form />
          {isLoading && (
            <div>
              <div style={{ margin: "0 0 30px 0" }}>
                <CircularProgress size={30} />
                <p style={{ color: "black" }}>
                  Please wait ! We are validating the details...
                </p>
              </div>
            </div>
          )}
          <div className={styles.buttons} style={{ margin: "20px 0px" }}>
            <Btn
              width="100px"
              borderRadius="100px"
              bgColor="#2A2A2A"
              disabled={isLoading}
              click={() => navigate("/Samvedna/staff")}
            >
              Cancel
            </Btn>
            <Btn
              width="100px"
              borderRadius="100px"
              bgColor="#00B091"
              disabled={isLoading}
              click={createStaff}
            >
              Create
            </Btn>
          </div>
        </div>
      </div>
    </>
  );
};
