import axios from "../../config/axiosConfig";
export const getMyProgressTrendData = async ({ dateGap, props }) => {
  console.log(props, "0000");
  return await axios
    .get(
      `/admin/progressTrendGraph?fromdate=${dateGap?.startDate}&todate=${dateGap?.endDate}&patient_id=${props}`
    )
    .then((res) => res)
    .catch((err) => err);
};
