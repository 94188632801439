import React from "react";
import classes from "../../assets/css/login.module.css";
import { useState } from "react";
import { useContext } from "react";
import AuthContext from "../../Auth/AuthContext";
import axios from "../../config/axiosConfig";
import { useNavigate } from "react-router-dom";
import { InputWithIcon } from "../../Component/UI/CustomInputs";
import { Eye } from "../../assets/icons/Eye";
import { ADMIN_ROUTES } from "../../Routes/routesConstants";

export const Login = (props) => {
  const { user } = props;
  const navigate = useNavigate();

  const ctx = useContext(AuthContext);
  const [auth, setAuth] = useState({});
  const [errorMessage, setErrorMessage] = React.useState("");
  const [error, setError] = useState({
    email: "",
    password: "",
  });

  const loginUser = (e) => {
    e.preventDefault();

    try {
      axios
        .post("/admin/login", auth)
        .then(async (res) => {
          console.log("res", res);
          if (res?.data.status === true) {
            ctx.auth({
              ...res.data.data,
              is_loggedin: true,
              role: "Admin!$23",
              expireDate: new Date().getTime() + 24 * 60 * 60 * 1000,
            });
            navigate(ADMIN_ROUTES.DASHBOARD);
          } else {
            setError(res?.data?.error[0]);
            console.log("ereremsg", error);
          }
        })
        .catch((err) => {
          setErrorMessage(err?.response?.data?.msg);
        });
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <>
      <form onSubmit={loginUser}>
        <p className={classes.heading}>Sign in to your account</p>

        <label>Email Address:</label>
        <div className={classes.input}>
          <input
            type="text"
            id="email"
            name="email"
            required
            placeholder="Email Address"
            onChange={(e) => setAuth({ ...auth, email: e.target.value })}
            style={
              user ? { outlineColor: "#7a51ae" } : { outlineColor: "#00B091" }
            }
            autoComplete="off"
          />
        </div>
        <br />
        <label>Password:</label>
        <div className={classes.input}>
          <InputWithIcon
            type="password"
            error={error?.password}
            labelSize={14}
            placeholder="Enter Password"
            icon={Eye}
            onChange={(e) => setAuth({ ...auth, password: e.target.value })}
          />
        </div>
        <div className={classes.error_text}>
          <p style={{ color: "red", float: "left" }}>
            {errorMessage ? errorMessage : null}
          </p>
        </div>
        <div className={classes.forgot_text}>
          <p
            onClick={() => navigate("/smv-admin/forgotPassword")}
            style={
              user
                ? { cursor: "pointer", color: "#7a51ae" }
                : { cursor: "pointer", color: "#00B091" }
            }
          >
            Forgot Password?
          </p>
        </div>

        <div className={classes.submit}>
          <input
            type="submit"
            value="Sign in"
            style={{ cursor: "pointer", background: "#00B091" }}
          />
        </div>
      </form>
    </>
  );
};
