import { useState } from "react";
import { Accordion } from "./Accordion/Accordion";
import { AccordionSummary } from "./Accordion/AccordionSummary";
import { AccordionDetails } from "./Accordion/AccordionDetails";
import Typography from "@mui/material/Typography";
import styles from "./faq.module.css";
export const CustomizedAccordions = ({ content }) => {
  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      {content?.map((item, i) => (
        <Accordion
          expanded={expanded === `panel${i + 1}`}
          onChange={handleChange(`panel${i + 1}`)}
          // defaultExpanded={true}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography
              style={{
                color: "#2A2A2A",
                fontFamily: "Public Sans",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "21px",
              }}
            >
              {item?.Title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{
              color: "#2A2A2A",
              fontFamily: "Public Sans",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "28px",
            }}
          >
            <ul style={{ listStyleType: "none" }}>
              {item?.content?.desc?.map(
                (ele, idex) => (
                  <li
                    className={styles.faqList}
                    dangerouslySetInnerHTML={{
                      __html: ele,
                    }}
                  />
                )
                // <li dangerouslySetInnerHTML={} key={idex}>{ele}</li>
              )}
            </ul>
            <ul style={{ listStyleType: "none" }}>
              {item?.content?.points?.map((ele, idex) => (
                <li key={idex}>{`${idex + 1}.  ${ele}`}</li>
              ))}
            </ul>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};
