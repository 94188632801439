import React from "react";
import { Paper } from "@mui/material/index";
import { IconShadow } from "../../../../../Component/UI/IconShadow";
import { Btn } from "../../../../UI/Button";

import styles from "./helpComp.module.css";

export const ConnectCard = ({
  title,
  contactdetails,
  btnText,
  icon,
  handleBtn,
}) => {
  return (
    <>
      <div>
        <Paper
          elevation={1}
          sx={{
            borderRadius: "15px",
            padding: "20px",
            margin: "25px 10px",
          }}
        >
          <div className={styles.container}>
            <div style={{ display: "flex" }}>
              <IconShadow icon={icon} size="50px" color={"#E9ECF2"} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  fontWeight: "500",
                  margin: "0 20px",
                }}
              >
                <div className={styles.title}>{title}</div>
                <div className={styles.details}>{contactdetails}</div>
              </div>
            </div>

            <Btn
              borderRadius="8px"
              bgColor="#7a51ae"
              color="#fff"
              width="100px"
              height="35px"
              margin="25px 0px 0px 0px"
              click={handleBtn}
            >
              {btnText}
            </Btn>
          </div>
        </Paper>
      </div>
    </>
  );
};
