import React from "react";
import { Link } from "react-router-dom";
import LoginImage from "../../assets/icons/LoginImage.svg";
import logo from "../../assets/icons/icon/logo.svg";
import classes from "./common.module.css";

const NotExist = () => {
  return (
    <div
      className={classes.common_container}
      style={{ background: `url(${LoginImage})` }}
    >
      <div style={{ margin: "10px" }}>
        <img src={logo} alt="" height="50px" />
      </div>

      <div className={classes.commom_subcontainer}>
        <p
          style={{
            fontSize: "70px",
            lineHeight: "70px",
          }}
        >
          404
        </p>
        <h1 style={{ margin: "20px 0 10px 0" }}>Look like you're lost</h1>
        <p style={{ marginBottom: "20px" }}>
          the page you are looking for not available!
        </p>
        <p className={classes.common_text}>
          <Link to="/dashboard" style={{ textDecoration: "none" }}>
            Click here{" "}
          </Link>
          to view your dashboard.
        </p>
      </div>
    </div>
  );
};

export default NotExist;
