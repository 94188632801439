import { Link } from "react-router-dom";
import { useNavigate } from "../../../node_modules/react-router-dom/index";
import styles from "../../assets/css/PatientUI/subheader.module.css";
import { cap } from "../../config/capatilizeName";
import { MonthPicker } from "../UI/monthPicker/MonthPicker";
import { UserPanel } from "../UserPanel";
import { Btn } from "./Button";
import { TabView } from "./TabView";
import { useAuth } from "../../Auth/AuthProvider";
import bannerImg from "../../assets/images/banner.jpg";
import bannerImgMobile from "../../assets/images/banner-mo.webp";
import { useEffect } from "react";
import { getBannerAPI } from "../../services/patient/appointments";
import { useState } from "react";
export const SubHeader = ({ name, tab, calendar }) => {
  const navigate = useNavigate();
  const ctx = useAuth();
  const [dashboard, setDashboard] = useState();
  const [mobileBanner, setMobileBanner] = useState();
  console.log(ctx, "ctx");
  useEffect(() => {
    getBannerList();
    getBannerListMo();
  }, []);
  const getBannerList = () => {
    getBannerAPI("0").then((res) => {
      if (res?.data?.status) {
        setDashboard(res?.data?.list);
      }
    });
  };
  const getBannerListMo = () => {
    getBannerAPI("1").then((res) => {
      if (res?.data?.status) {
        setMobileBanner(res?.data?.list);
      }
    });
  };

  console.log(dashboard, "dashboard-");
  return (
    <div
      className={styles.subheader}
      style={{
        margin: "16px",
        display: "flex",
        margin: "10px 0",
        padding: "0 10px",
        width: "100%",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      {tab ? (
        <div className={styles.content}>
          <TabView calendar={calendar} values={tab} />
        </div>
      ) : (
        <>
          <div className={styles.user}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <UserPanel withBorder />
              <div className={styles.greeting}>
                <h2>Welcome Back, {cap(name)}</h2>
                <p style={{ marginTop: "8px" }}>
                  Mental health... is not a destination, but a process. - Noam
                  Shpancer
                </p>
                {ctx?.loginUser?.customer?.enterprise_id !== null && (
                  <p
                    style={{
                      marginTop: "25px",
                      fontSize: "15px",
                      marginBottom: "10px",
                      fontWeight: 600,
                    }}
                  >
                    *Check Reports menu for assessments taken with your
                    organization.
                  </p>
                )}
              </div>
            </div>
            <div className={styles.bannerImgDesktop}>
              <img src={dashboard?.banner_url} alt="" />
            </div>
            <div className={styles.bannerImgMobile}>
              <img src={mobileBanner?.banner_url} alt="" />
            </div>
            <div
              className={styles.bookService}
              style={{ marginTop: "8px", marginBottom: "12px" }}
            >
              <div className={styles.petientService}>
                Start your journey. Have you booked a service yet?
                {/* <Link style={{textDecoration: "none"}} to={"/patient/services"}>Book a Service</Link> */}
              </div>

              <div className={styles.rightBox}>
                <Btn
                  click={() => {
                    navigate("/patient/services");
                  }}
                  style={{ margin: "0 10px" }}
                  variant="contained"
                >
                  Book A Service
                </Btn>
                <Btn
                  click={() => {
                    navigate("/patient/doctors");
                  }}
                  style={{ margin: "0 10px" }}
                  variant="contained"
                >
                  Meet Our Experts
                </Btn>
                {/* <Link style={{textDecoration: "none", marginRight: "5px"}} to={"/patient/doctors"}>Know our Experts</Link> */}
                {/* <Link style={{textDecoration: "none"}} to={"/clientselfcareplan"}>Track your wellness</Link> */}
              </div>

              {/* <Link style={{textDecoration: "none"}} to={"/patient/doctors"}>Know our Experts</Link> */}
            </div>
          </div>
        </>
      )}
      {/* <div className={styles.calenderContainer}>{!calendar && <MonthPicker />}</div> */}
    </div>
  );
};
