import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AssessmentCard2 } from "../../Components/Assessments/AssessmentCard2";
import { DementiaScreening } from "../../Components/Images/DementiaScreening";
import styles from "./assessment.module.css";
import Popup from "./LinkPopup";
import ResquestSentIcon from "../../../assets/icons/RequestSentIcon";
import { AssessmentDetailPopup } from "./AssessmentDetails/AssessmentDetailPopup";
import { useEffect } from "react";
import axios from "../../../config/axiosConfig";
import { formatttedDate } from "../../../config/formattedDate";
import { requestEnterpriseAssessment } from "../../../services/enterprise/assessment";
import { Empty } from "../../Components/UI/Empty";
import { useDispatch, useSelector } from "react-redux";
import moment from "../../../../node_modules/moment/moment";
import {
  setDate,
  setToggle,
  setYear,
} from "../../../Redux/Slices/Patient/PatienCalender";

export const OtherAssessments = () => {
  const [otherData, setOtherData] = useState([]);
  const [assessmentId, setAssessmentId] = useState();

  useEffect(() => {
    axios.post("/enterprise/getAssessments", { status: 2 }).then((res) => {
      let FinalList = res.data.list?.filter((item) => {
        return item.status === 1;
      });
      setOtherData(FinalList);
    });
  }, []);

  const dispatch = useDispatch();

  const { selectedDate } = useSelector((i) => i.DateFilterSlice);
  const FinalArr = otherData || [];

  const searchResult = FinalArr.filter((event) => {
    return selectedDate.includes("All")
      ? event.date_of_creation.includes(selectedDate.split(" ")?.[1])
      : event.date_of_creation.includes(moment(selectedDate).format("YYYY-MM"));
  });

  useEffect(() => {
    return () => {
      dispatch(setDate(`All ${new Date().getFullYear()}`));
      dispatch(setToggle(false));
      dispatch(setYear(new Date().getFullYear()));
    };
  }, []);

  const navigate = useNavigate();

  const [popup, setPopup] = useState(false);
  const [popupAction, setPopupAction] = React.useState({
    open: false,
    name: "",
  });

  return (
    <>
      <Popup
        popupAction={popupAction}
        setPopupAction={setPopupAction}
        children={
          <div className={styles.otherAssContent}>
            <h2>Request Sent</h2>
            <p> We will connect you shortly</p>
          </div>
        }
        icon={<ResquestSentIcon />}
      />
      <AssessmentDetailPopup
        popup={popup}
        setPopup={setPopup}
        assessmentData={assessmentId}
      />
      <div
        className={styles.cards}
        style={
          searchResult?.length
            ? {}
            : { justifyContent: "center", translate: "0 50px" }
        }
      >
        {searchResult.length ? (
          searchResult?.map((assessment) => {
            const date = formatttedDate(assessment?.date_of_creation);
            const AssessmentImage = () => (
              <img
                src={assessment?.assessment_image}
                alt=""
                width="100px"
                height="100px"
                style={{ objectFit: "contain" }}
              />
            );
            return (
              <div className={styles.container}>
                <AssessmentCard2
                  title={assessment?.name}
                  image={<AssessmentImage />}
                  numberofques={`${assessment?.no_of_question} Questions`}
                  height="95%"
                  date={date}
                  // handleBtn={() => {
                  //   requestEnterpriseAssessment({
                  //     assissement_id: assessment.id,
                  //   })
                  //     .then((res) => {
                  //       if (res.data.status) {
                  //         setPopupAction({
                  //           ...popupAction,
                  //           open: true,
                  //         });
                  //       }
                  //     })
                  //     .catch((err) => console.log(err));
                  // }}
                  // btntext="Request"
                  detailsButton="Details"
                  handleDetailsBtn={() => {
                    setPopup(true);
                    setAssessmentId(assessment);
                  }}
                />
              </div>
            );
          })
        ) : (
          <Empty title="There are no assessment at this time." />
        )}
      </div>
    </>
  );
};
