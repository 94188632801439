import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Moment from "moment";
import {
  addAppointment,
  getreschedulestaff,
  getTimeSlotAdmin,
} from "../../../services/admin/appointment";
import BasicBreadcrumbs from "../../../Component/UI/BreadCrumb/BreadCrumbs";
import moment from "../../../../node_modules/moment/moment";
import styles from "./appointment.module.css";
import toast, { Toaster } from "react-hot-toast";
import { getPatientList } from "../../../services/admin/subscriptions";
import ReactSelect from "../../../../node_modules/react-select/dist/react-select.cjs";
import { Input } from "../../UI/Input";
import { Datepicker, Select } from "../../../Component/UI/CustomInputs";
import {
  getRoles,
  getRolesApp,
  getServiceApp,
} from "../../../services/admin/staff";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";
import axios from "../../../../node_modules/axios/index";
import { to12Hour } from "../../../config/formattedDate";
import OnlineImg from "../../../assets/images/appOnline.svg";
import OfflineImg from "../../../assets/images/appOffline.svg";
import HybirdImg from "../../../assets/images/appHybird.svg";
import {
  assignStaff,
  getAvailStaff,
} from "../../../services/admin/appointment";
export const AddAppointments = () => {
  const [formErrors, setFormErrors] = useState({});

  let navigate = useNavigate();
  const [patientsList, setPatientsList] = useState([]);
  const [patientEmail, setPatientEmail] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [selectedPatient, setSelectedPatient] = useState({});
  const [selectedRole, setSelectedRole] = useState("");
  const [roles, setRoles] = useState();
  const [selectedStaffList, setSelectedStaffList] = useState();
  const [currentDate, setCurrentDate] = useState();
  const [staff, setStaff] = useState();
  const [timeSlotFilter, setTimeSlotFilter] = useState([]);
  const [reserveTime, setReserveTime] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subscriptionServices, setSubscriptionServices] = useState();
  const [timeSlot, setTimeSlot] = useState();
  const [selectedRosterId, setSelectedRosterId] = useState();
  const [emailError, setEmailError] = useState("");
  const rgx = /^(?:[0-9+]+)?$/;

  console.log(patientEmail, "patientEmail---");
  const [user, setUser] = useState({
    paitient_id: "",
    mobile: "",
    address: "",
    staff_id: "",
    appointment_link: "",
    //specialization_id: "",
    appointment_date: "",
    start_time: "",
  });

  const { appointment_date, end_time } = user;

  const timeSlotdata = [
    {
      id: "09",
      value: "09:00 AM - 10:00 AM",
    },
    {
      id: "10",
      value: "10:00 AM - 11:00 AM",
    },
    {
      id: "11",
      value: "11:00 AM - 12:00 AM",
    },
    {
      id: "12",
      value: "12:00 PM - 01:00 PM",
    },
    {
      id: "13",
      value: "01:00 PM - 02:00 PM",
    },
    {
      id: "14",
      value: "02:00 PM - 03:00 PM",
    },
    {
      id: "15",
      value: "03:00 PM - 04:00 PM",
    },
    {
      id: "16",
      value: "04:00 PM - 05:00 PM",
    },
    {
      id: "17",
      value: "05:00 PM - 06:00 PM",
    },
    {
      id: "18",
      value: "06:00 PM - 07:00 PM",
    },
  ];

  useEffect(() => {
    getTimeSlotAdmin(appointment_date).then((res) => {
      const response = res?.data?.list;
      const times = [];
      for (let time in response) {
        const addOneHour = +time + 1;
        const value = `${to12Hour(time)} - ${to12Hour(addOneHour)}`;
        if (response[time]) {
          times.push({
            value: null,
            //id: null,
            // id: time + ":00:00",
            // disabled: true,
          });
        } else {
          times.push({
            value,
            id: time.padStart(2, 0) + ":00:00",
            disabled: false,
          });
        }
      }
      setTimeSlot(times?.filter((i) => i?.value !== null));
    });
  }, [appointment_date]);

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  useEffect(() => {
    getPatientList().then((res) => {
      const patientData = res?.data?.list?.map(
        ({ id, name, email, mobile, address_line1 }) => {
          return { value: id, label: name, email, mobile, address_line1 };
        }
      );
      setPatientsList(patientData);
    });
  }, []);

  useEffect(() => {
    getRolesApp().then((res) => {
      const list = res?.data?.list?.map(({ name, id }, i) => {
        return { id, value: name, ...(i === 0 && { selected: true }) };
      });
      setRoles(list);
      // setSelectedRole(data?.body?.staff_data?.role_name[0].id);
    });
  }, []);

  useEffect(() => {
    const dataFinal = {
      appointment_date: user?.appointment_date,
      time_slot: user?.start_time,
    };
    getAvailStaff(dataFinal).then((res) => {
      setStaff(res?.data?.list);
    });
  }, [user?.start_time]);

  // useEffect(() => {
  //   setCurrentDate(moment(user?.appointment_date).isSame(moment(), "day"));
  //   getreschedulestaff(user?.appointment_date).then((res) => {
  //     setStaff(res?.data?.list);
  //   });
  // }, [user?.appointment_date]);

  useEffect(() => {
    const staffByRole = staff?.filter((item) => {
      const filteredStaff = item?.users?.role_name?.filter(
        (i) => i?.id?.toString() === selectedRole?.toString()
      );

      if (filteredStaff?.length !== 0) return item;
      else return "";
    });

    setSelectedStaffList(
      staffByRole
        ?.filter((i) => i !== "")
        .map((i) => {
          return { rosterId: i?.id, id: i?.users?.id, value: i?.users?.name };
        })
    );
    // setAppDate({
    //   ...appDate,
    //   staffId: staffByRole && staffByRole[0].id
    // })
  }, [selectedRole, staff]);

  useEffect(() => {
    setReserveTime(
      staff?.find((i) => i?.id?.toString() === user?.staff_id?.toString())
    );
  }, [staff, user?.staff_id]);

  // useEffect(() => {
  //   const currentTime = moment().add(1, "hour").format("HH");

  //   const reserveTimedata = reserveTime?.map((i) =>
  //     moment(i?.appointment_date).format("HH")
  //   );

  //   const reserveTimeId = reserveTime?.map((i) => {
  //     return {
  //       ...i,
  //       id: moment(i?.appointment_date).format("HH"),
  //     };
  //   });

  //   const timeSlotList = timeSlotdata?.map((i, index) => {
  //     const reserveTimeStatus = reserveTimeId?.find((j) => j.id === i.id);
  //     if (reserveTimedata?.includes(i.id) && reserveTimeStatus.status !== 2) {
  //       return { ...i, disabled: true };
  //     } else {
  //       return i;
  //     }
  //   });
  //   setTimeSlotFilter(timeSlotList);
  //   if (currentDate) {
  //     setTimeSlotFilter(
  //       timeSlotList.map((i) => {
  //         if (i.id <= currentTime) {
  //           return { ...i, disabled: true };
  //         } else {
  //           return i;
  //         }
  //       })
  //     );
  //   }
  // }, [reserveTime, user]);

  useEffect(() => {
    getServiceApp().then((res) => {
      const list = res?.data?.list?.map(({ name, id, type_of_service }, i) => {
        return {
          id,
          value: name,
          typeOfService: type_of_service,
          //  ...(i === 0 && { selected: true })
        };
      });
      setSubscriptionServices(list);
      // setSelectedRole(data?.body?.staff_data?.role_name[0].id);
    });
  }, []);

  useEffect(() => {
    setServiceType(
      subscriptionServices?.find(
        (i) => i?.id?.toString() === user?.service_id?.toString()
      )?.typeOfService
    );
  }, [user?.service_id]);

  useEffect(() => {
    const getPatientData =
      patientsList &&
      patientsList?.find(({ value }) => value === user?.paitient_id);
    setSelectedPatient(getPatientData);
    setPatientEmail(getPatientData?.email ? getPatientData?.email : "");
    setUser({
      ...user,
      mobile: getPatientData?.mobile ? getPatientData?.mobile : "",
      address: getPatientData?.address_line1
        ? getPatientData?.address_line1
        : "",
    });
  }, [patientsList, user?.paitient_id]);

  //get rosterId base on time slot
  useEffect(() => {
    //get Roster id base on Select time slot
    selectedStaffList?.map((i) => {
      if (i?.id?.toString() === user?.staff_id.toString()) {
        return setSelectedRosterId(i?.rosterId);
      }
    });
  }, [user?.staff_id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    addAppointment({
      patient_id: user?.paitient_id,
      mobile: user?.mobile,
      address: user?.address,
      staff_id: user?.staff_id,
      appointment_link: user?.appointment_link,
      specialization_id: selectedRole,
      appointment_date: user?.appointment_date,
      service_id: user?.service_id,
      start_time: user?.start_time,
      roster_id: selectedRosterId,
      email: patientEmail ? patientEmail : user?.email,
    }).then((res) => {
      setLoading(false);
      if (res?.data?.msg) {
        toast.success(res?.data?.msg);
      }

      if (res?.data?.status) navigate("/Appointments");
      setFormErrors(res?.data?.error);
    });

    // const currentDate = moment(new Date()).format("YYYY-MM-DD");

    // const selectedStartTime = moment(
    //   currentDate + " " + user.appointment_time,
    //   "YYYY-MM-DD hh:mm"
    // );
    // const selectedEndTime = moment(
    //   currentDate + " " + user.end_time,
    //   "YYYY-MM-DD hh:mm"
    // );

    // const appointmentDateWithTime = moment(
    //   appointment_date + " " + moment().format("hh:mm")
    // ).format("YYYY-MM-DD hh:mm");

    // const endDiff = selectedEndTime.diff(moment(), "minutes");
    // const startDiff = selectedStartTime.diff(moment(), "minutes");

    // const eventDateDiff = moment().diff(appointmentDateWithTime, "hours");

    // const diffMin = selectedEndTime.diff(selectedStartTime, "seconds");

    // const dateTime = Moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    // const date = Moment(dateTime).format("YYYY-MM-DD HH:mm:ss");

    // if (diffMin > 0) {
    //   if (endDiff < 0 && eventDateDiff >= 0) {
    //     toast.error("End time should be more than current time!");
    //   } else if (startDiff < 0 && eventDateDiff >= 0) {
    //     toast.error("Start time should be more than current time!");
    //   } else {
    //     addAppointment(user).then((res) => {
    //       console.log(res, "res");
    //       if (res?.data?.status) navigate("/Appointments");
    //       setFormErrors(res?.data?.error);
    //     });
    //   }
    // } else {
    //   toast.error("End time should be more than start time!");
    // }
  };

  const links = [
    {
      path: "/Appointments",
      linkText: "Appointments",
    },
  ];

  return (
    <div className={styles.appointment}>
      <Toaster />
      <BasicBreadcrumbs link={links} text="Add Appointment">
        <form onSubmit={handleSubmit}>
          <div className="form-group"></div>

          <Grid item xs={12} md={12} lg={12} className={styles.mainDiv}>
            <Grid item xs={6} md={6} lg={6}>
              <Grid
                className={styles.marginLeft5}
                style={{
                  fontSize: "14px",
                  marginTop: "5px",
                  fontWeight: "400",
                  marginBottom: "10px",
                }}
              >
                <Grid>
                  <label style={{ fontSize: "1rem" }}>
                    Patient Name<span style={{ color: "#D3232F" }}>*</span>
                  </label>
                  <ReactSelect
                    className={styles.appAppointment}
                    classNamePrefix="select"
                    name="paitient_id"
                    value={selectedPatient}
                    options={patientsList}
                    // isDisabled={expiryDate}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        marginTop: 10,
                        // width: 320,
                        height: 48,
                      }),
                    }}
                    onChange={(data) => {
                      const e = {
                        target: {
                          name: "paitient_id",
                          value: data.value,
                        },
                      };
                      setSelectedPatient(data);
                      onInputChange(e);
                      setEmailError("");
                      setPatientEmail(data?.email ? data?.email : "");

                      // setUser({
                      //   ...user,
                      //   mobile: data?.mobile ? data?.mobile : '',
                      // });
                    }}
                  />
                </Grid>
              </Grid>

              <p
                style={{
                  color: "red",
                  marginLeft: "6px",
                  fontSize: "1rem",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                {formErrors?.paitient_name}
              </p>
            </Grid>

            <Grid item xs={6} md={6} lg={6}>
              <Grid
                className={styles.marginLeft115}
                style={{
                  fontSize: "14px",
                  marginTop: "5px",
                  fontWeight: "400",
                }}
              >
                <p style={{ fontSize: "1rem" }}>Patient Email</p>
              </Grid>

              <Grid
                className={styles.marginLeft115}
                style={{
                  fontSize: "16px",
                  marginTop: "5px",
                  fontWeight: "600",
                  marginBottom: "10px",
                }}
              >
                <input
                  label="Patient Email"
                  className={styles.appAppointment}
                  disabled={patientEmail}
                  value={
                    patientEmail
                      ? patientEmail
                      : "" || user?.email
                      ? user?.email
                      : ""
                  }
                  onChange={(e) => {
                    setUser({
                      ...user,
                      email: e.target.value,
                    });
                  }}
                  style={{
                    borderRadius: "8px",
                    height: "48px",
                    outline: "none",
                    border: "1px solid #dadada",
                    fontSize: "16px",
                    marginTop: "8px",
                    paddingLeft: "8px",
                  }}
                />
                <p
                  style={{
                    color: "red",
                    marginLeft: "6px",
                    fontSize: "1rem",
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  {formErrors?.email}
                </p>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12} lg={12} className={styles.mainDiv}>
            <Grid item xs={6} md={6} lg={6}>
              <Grid
                className={styles.marginLeft5}
                style={{
                  fontSize: "14px",
                  marginTop: "5px",
                  fontWeight: "400",
                }}
              >
                <p style={{ fontSize: "1rem" }}>Address</p>
              </Grid>
              <Grid
                className={styles.marginLeft5}
                style={{
                  fontSize: "16px",
                  marginTop: "5px",
                  fontWeight: "600",
                  marginBottom: "10px",
                }}
              >
                {" "}
                <input
                  type="text"
                  name="address"
                  className={styles.appAppointment}
                  value={user.address}
                  placeholder="Address"
                  onChange={(e) => onInputChange(e)}
                  style={{
                    borderRadius: "8px",
                    height: "48px",
                    outline: "none",
                    border: "1px solid #dadada",
                    fontSize: "16px",
                    marginTop: "8px",
                    paddingLeft: "8px",
                  }}
                />
              </Grid>
              <p
                style={{
                  color: "red",
                  marginLeft: "6px",
                  fontSize: "1rem",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                {formErrors?.address}
              </p>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Grid
                className={styles.marginLeft115}
                style={{
                  fontSize: "14px",
                  marginTop: "5px",
                  fontWeight: "400",
                }}
              >
                <p style={{ fontSize: "1rem" }}>Mobile</p>
              </Grid>

              <Grid
                className={styles.marginLeft115}
                style={{
                  fontSize: "16px",
                  marginTop: "5px",
                  marginBottom: "10px",
                  fontWeight: "600",
                }}
              >
                <input
                  type="text"
                  name="mobile"
                  value={user.mobile}
                  placeholder="Mobile"
                  className={styles.appAppointment}
                  onChange={(e) => {
                    const isNum = rgx.test(e.target.value);
                    isNum && setUser({ ...user, mobile: e.target.value });
                  }}
                  // onChange={(e) => onInputChange(e)}
                  onWheel={(e) => e.target.blur()}
                  maxlength="11"
                  style={{
                    borderRadius: "8px",
                    height: "48px",
                    outline: "none",
                    border: "1px solid #dadada",
                    fontSize: "16px",
                    marginTop: "8px",
                    paddingLeft: "8px",
                  }}
                />
                <p
                  style={{
                    color: "red",
                    // marginLeft: "110px",
                    fontSize: "1rem",
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  {formErrors?.mobile}
                </p>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12} lg={12} className={styles.mainDiv}>
            <Grid item xs={6} md={6} lg={6}>
              <Grid
                className={styles.marginLeft5}
                style={{
                  fontSize: "16px",
                  marginTop: "5px",
                  fontWeight: "500",
                  marginBottom: "10px",
                }}
              >
                <p style={{ color: "#000", fontSize: "15px" }}>
                  Appointment Date
                </p>
                <div
                  style={{ marginTop: "8px" }}
                  className={`${styles.options} ${styles.appAppointment}`}
                >
                  <Datepicker
                    label=""
                    name="appointment_date"
                    // display="flex"
                    minDate={new Date()}
                    value={appointment_date}
                    onChange={(e) => {
                      setSelectedRole("");
                      setUser({
                        ...user,
                        start_time: "",
                        appointment_date: moment(e).format("YYYY-MM-DD"),
                      });
                    }}
                  />
                </div>
              </Grid>
              <p
                style={{
                  color: "red",
                  // marginLeft: "110px",
                  fontSize: "1rem",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                {formErrors?.appointment_date}
              </p>
            </Grid>

            <Grid item xs={6} md={6} lg={6}>
              <Grid
                className={styles.marginLeft115}
                style={{
                  fontSize: "16px",
                  marginTop: "5px",
                  fontWeight: "500",
                  marginBottom: "10px",
                }}
              >
                <p style={{ color: "#000", fontSize: "15px" }}>Time Slot</p>
                <div className={`${styles.options} ${styles.appAppointment}`}>
                  <Select
                    name="start_time"
                    options={timeSlot}
                    className={styles.timeSlot}
                    value={user?.start_time}
                    onChange={(e) => {
                      setSelectedRole("");
                      setUser({
                        ...user,
                        start_time: e.target.value,
                      });
                    }}
                    disabled={!appointment_date}
                  />
                  <p
                    style={{
                      color: "red",
                      marginLeft: "6px",
                      fontSize: "1rem",
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    {formErrors?.start_time}
                  </p>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={12} className={styles.mainDiv}>
            <Grid item xs={6} md={6} lg={6}>
              <Grid
                className={styles.marginLeft5}
                style={{
                  fontSize: "16px",
                  marginTop: "5px",
                  fontWeight: "500",
                  marginBottom: "10px",
                }}
              >
                <p
                  style={{
                    color: "#000",
                    fontSize: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Service{" "}
                  {serviceType ? (
                    serviceType === "0" ? (
                      <>
                        (<span>Offline</span>{" "}
                        <img
                          style={{
                            width: "17px",
                            marginTop: "1px",
                            marginRight: "0px",
                          }}
                          src={OfflineImg}
                          alt={"Offline"}
                        />
                        )
                      </>
                    ) : serviceType === "1" ? (
                      <>
                        (<span>Online</span>{" "}
                        <img
                          style={{
                            width: "17px",
                            marginTop: "1px",
                            marginRight: "0px",
                          }}
                          src={OnlineImg}
                          alt={"Online"}
                        />
                        )
                      </>
                    ) : serviceType === "2" ? (
                      <>
                        (<span>Hybrid</span>{" "}
                        <img
                          style={{
                            width: "17px",
                            marginTop: "1px",
                            marginRight: "0px",
                          }}
                          src={HybirdImg}
                          alt={"Hybrid"}
                        />
                        )
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </p>
                <div className={`${styles.options} ${styles.appAppointment}`}>
                  <Select
                    name="service_id"
                    value={user?.service_id}
                    //width="320px"
                    fontSize={15}
                    margin="0"
                    labelSize="14px"
                    error={formErrors?.service_id}
                    options={subscriptionServices}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        service_id: e.target.value,
                      });
                    }}

                    // disabled={expiryDate}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Grid
                className={styles.marginLeft115}
                style={{
                  fontSize: "16px",
                  marginTop: "5px",
                  fontWeight: "500",
                  marginBottom: "10px",
                }}
              >
                <p style={{ color: "#000", fontSize: "15px" }}>
                  Type of Specialization
                </p>
                <div className={`${styles.options} ${styles.appAppointment}`}>
                  <Select
                    disabled={!user?.appointment_date}
                    options={roles}
                    value={selectedRole}
                    onChange={(e) => {
                      setSelectedRole(e.target.value);
                      setUser({
                        ...user,
                        staff_id: "",
                      });
                    }}
                  />
                  <p
                    style={{
                      color: "red",
                      marginLeft: "6px",
                      fontSize: "1rem",
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    {formErrors?.specialization_id}
                  </p>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12} lg={12} className={styles.mainDiv}>
            <Grid item xs={6} md={6} lg={6}>
              <Grid
                className={styles.marginLeft5}
                style={{
                  fontSize: "16px",
                  marginTop: "5px",
                  fontWeight: "500",
                  marginBottom: "10px",
                }}
              >
                <p style={{ color: "#000", fontSize: "15px" }}>Select Doctor</p>
                <div className={`${styles.options} ${styles.appAppointment}`}>
                  <Select
                    name="staff_id"
                    options={selectedStaffList}
                    onChange={(e) => {
                      var selectedId = e.target.value;
                      setUser({
                        ...user,
                        staff_id: selectedId,
                      });
                    }}
                    error={formErrors?.staff_id}
                    value={user?.staff_id}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Grid
                className={styles.marginLeft115}
                style={{
                  fontSize: "16px",
                  marginTop: "5px",
                  fontWeight: "500",
                  marginBottom: "10px",
                }}
              >
                {/* <p style={{ color: "#000", fontSize: "15px" }}>
                Appointment Link{" "}
              </p> */}
                <div
                  style={{
                    width: "100%",
                    marginTop: "8px",
                    marginLeft: "5px",
                    marginBottom: "10px",
                  }}
                  className={styles.options}
                >
                  <Input
                    className={styles.appAppointment}
                    name="appointment_link"
                    label="Appointment Link/Address"
                    onChange={onInputChange}
                    style={{
                      borderRadius: "8px",
                      height: "48px",
                      outline: "none",
                      border: "1px solid #dadada",
                      fontSize: "16px",
                      marginTop: "8px",
                      paddingLeft: "8px",
                    }}
                    //error={err?.appointment_link}
                    value={user?.appointment_link}
                    width="320px"
                    // value={servicesCardData?.length}
                  />
                  <p
                    style={{
                      color: "red",
                      marginLeft: "6px",
                      fontSize: "1rem",
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    {formErrors?.appointment_link}
                  </p>
                </div>
              </Grid>
            </Grid>
          </Grid>

          {loading && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress size={30} />
              <p style={{ color: "black" }}>
                Please wait ! We are validating the details...
              </p>
            </div>
          )}

          <button className={styles.addButtuon} disabled={loading}>
            ADD
          </button>
        </form>
      </BasicBreadcrumbs>
    </div>
  );
};
