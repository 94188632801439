import { useState } from "react";
import styles from "../../assets/css/PatientUI/tabview.module.css";
import { MonthPicker } from "../UI/monthPicker/MonthPicker";

export const TabView = ({ values, calendar }) => {
  const [select, setSelect] = useState(0);
  return (
    <div>
      <div className={styles.toggleContainer}>
        <div className={styles.toggle}>
          {values?.map(({ label, diable }, i) => {
            return (
              <div
                key={i}
                className={styles.slider}
                style={{
                  background: i === select ? "#7a51ae" : null,
                  color: i === select ? "#fff" : null,
                }}
                onClick={() => !diable && setSelect(i)}
              >
                <span>{label}</span>
              </div>
            );
          })}
        </div>
        {!calendar && <MonthPicker />}
      </div>
      {values?.map(({ component }, i) => {
        return select === i && <div key={i}>{component}</div>;
      })}
    </div>
  );
};
