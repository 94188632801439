import React from "react";

export const InfoIconRight = ({ color }) => {
  return (
    <div>
      <svg width="34" height="32" viewBox="0 0 34 32" fill="none">
        <g opacity="0.08">
          <path
            d="M33.3984 9.02457C33.3984 9.02457 32.6536 16.6175 24.6086 21.0869C24.6086 21.0869 15.1531 26.8944 15.4748 32C15.4748 32 11.2894 25.9636 16.6056 19.7278C16.6056 19.7278 19.6002 16.0626 24.7897 15.0339C24.7897 15.034 29.8381 14.3762 33.3984 9.02457Z"
            fill={color || "#00B091"}
          />
          <path
            d="M28.5625 12.2492C28.5625 12.2492 22.03 15.8781 13.9151 11.5443C13.9151 11.5443 3.93094 6.76175 0.00033793 9.90161C0.00033793 9.90161 2.51169 2.95278 10.5171 3.97435C10.5171 3.97435 15.1494 4.46422 18.8913 8.30067C18.8913 8.30055 22.2539 12.225 28.5625 12.2492Z"
            fill={color || "#00B091"}
          />
          <path
            d="M22.7997 5.756C22.7997 2.57737 25.3072 0.000683978 28.4 0.000684249C31.4928 0.000684519 34 2.57737 34 5.756C34 8.93497 31.4928 11.5117 28.4 11.5117C25.3072 11.5117 22.7997 8.93497 22.7997 5.756Z"
            fill={color || "#00B091"}
          />
        </g>
      </svg>
    </div>
  );
};
