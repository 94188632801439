import React from "react";
import { useState } from "react";
import {
  CircularProgress,
  Paper,
} from "../../../../node_modules/@mui/material/index";
import { Helpcircle } from "../../../assets/icons/icon/Helpcircle";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import { CalenderIcon } from "../Report/CalenderIcon";
import styles from "./assessmentcard.module.css";

export const AssessmentCard2 = ({
  title,
  image,
  numberofques,
  date,
  width,
  height,
  handleBtn,
  btntext,
  detailsButton,
  handleDetailsBtn,
  btnbg,
  disable,
  assessmentId,
  disableId,
}) => {
  return (
    <div>
      <Paper
        elevation={1}
        className={styles.mainCon}
        sx={{
          width: width || "250px",
          height,
          borderRadius: "15px",
          padding: "5px 10px",
          margin: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div style={{ textAlign: "center", marginTop: "25px" }}>{image}</div>
        <div>
          <div className={styles.title}>{title}</div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "15px 0",
            }}
          >
            <div style={{ display: "flex", marginLeft: "8px" }}>
              <span>
                <Helpcircle width="16" height="16" color="black" />
              </span>
              <span style={{ marginLeft: "3px", fontSize: "14px" }}>
                {numberofques}
              </span>
            </div>
            <div style={{ display: "flex", margin: "auto" }}>
              {date && (
                <span>
                  <CalenderIcon />
                </span>
              )}
              <span style={{ marginLeft: "3px", fontSize: "14px" }}>
                {date}
              </span>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", margin: "10px 0" }}>
          {btntext && (
            <Btn
              variant="contained"
              borderRadius="8px"
              bgColor={btnbg || "#3592FF"}
              width="100%"
              height="36px"
              fontWeight="400"
              click={handleBtn}
              margin="0 5px"
              disabled={assessmentId === disableId && disable}
            >
              {assessmentId === disableId && disable ? (
                <CircularProgress size={30} />
              ) : (
                btntext
              )}
            </Btn>
          )}
          {detailsButton && (
            <Btn
              variant="contained"
              borderRadius="8px"
              border="1.5"
              bgColor="transparent"
              color="#000"
              borderColor="#B8B8B8"
              width="100%"
              height="36px"
              fontWeight="400"
              click={handleDetailsBtn}
              margin="0 5px"
            >
              {detailsButton}
            </Btn>
          )}
        </div>
      </Paper>
    </div>
  );
};
