import React from "react";
import { Paper } from "@mui/material/index";
import styles from "./help.module.css";
import { ConnectCard } from "../../Components/Help/ConnectCard";

import { IoCall } from "react-icons/io5";
import { AiFillMail } from "react-icons/ai";
import { RiWhatsappFill } from "react-icons/ri";

export const ConnectWithUs = () => {
  return (
    <>
      <div style={{ margin: "10px 0" }}>
        <Paper elevation={1} className={styles.papercontainer}>
          <div className={styles.heading}>Connect with us</div>
          <div style={{ margin: "20px 0px" }}>
            <ConnectCard
              title="Call Us"
              icon={<IoCall size="28" color="#3592FF" />}
              contactdetails="(+91) 7428097301"
              btnText="Call Now"
              handleBtn={() => {
                window.open("tel:7428097301");
              }}
            />
            <ConnectCard
              title="Write Us"
              icon={<AiFillMail size="28" color="#3592FF" />}
              contactdetails="contact@samvednacare.com"
              btnText="Email Us"
              handleBtn={() => {
                window.open("mailto:contact@samvednacare.com");
              }}
            />
            <ConnectCard
              title="Say Hi on whatsapp "
              icon={<RiWhatsappFill size="28" color="#3592FF" />}
              contactdetails="(+91) 9810883429"
              btnText="Say Hi"
              handleBtn={() => {
                window.open(
                  "https://api.whatsapp.com/send?phone=9810883429&text=Hi"
                );
              }}
            />
          </div>
        </Paper>
      </div>
    </>
  );
};
