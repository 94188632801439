import React from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { Input } from "../../../../Component/UI/CustomInputs";
import TextField from "@mui/material/TextField";
import BasicBreadcrumbs from "../../../../Component/UI/BreadCrumb/BreadCrumbs";

const AddNotification = () => {
  return (
    <BasicBreadcrumbs link={"Add notification"} text={"Add Notification"}>
      <div style={{ paddingTop: 20 }}>
        {/* <h2>Add Notification</h2> */}

        <Paper
          elevation={1}
          style={{
            position: "absolute",
            top: "135px",
            width: "82%",
            left: "260px",
            height: "70%",
            padding: "25px 30px",
          }}
        >
          
            <div>
              <Input label="Name" />
            </div>

          

          <div style={{ width: "60%", marginTop: "25px" }}>
            <div>Description</div>
            <TextField
              variant="outlined"
              placeholder="Write here..."
              multiline
              fullWidth
               rows={4}
              
            />
          </div>
          <div className="addSmsBtn" style={{ position:"absolute",bottom:20 }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#2A2A2A",
                borderRadius: 50,
                marginRight: 10,
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: "#00B091", borderRadius: 50 }}
            >
              Create
            </Button>
          </div>
        </Paper>
      </div>
    </BasicBreadcrumbs>
  );
};

export default AddNotification;