import React from "react";
import styles from "./chart.module.css";
import ReactApexChart from "react-apexcharts";
import Excellent from "./images/Excellent.svg";
import Happy from "./images/Happy.svg";
import Upset from "./images/Upset.svg";
import Angry from "./images/Angry.svg";
import Sad from "./images/Sad.svg";
class SleepQualityChart extends React.Component {
  constructor(props) {
    super(props);
    const d = props.getSleepQuality;
    const apiData = [d?.["Poor"], d?.["Good"], d?.["Very Good"]];
    this.state = {
      series: [
        {
          data: apiData,
        },
      ],
      noData: {
        text: "No data available",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "14px",
        },
      },
      options: {
        chart: {
          width: 465,
          height: 250,
          type: "bar",
          events: {
            click: function (chart, w, e) {
              // console.log(chart, w, e)
            },
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        colors: ["#EE4925", "#AAC514", "#23C363", "#FBAA34"],
        plotOptions: {
          bar: {
            columnWidth: "12%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: [["Poor"], ["Good"], ["Very Good"]],
          labels: {
            style: {
              // colors: colors,
              fontSize: "12px",
            },
          },
        },
        yaxis: {
          max: d?.count,
          forceNiceScale: true,
          labels: {
            formatter: function (value) {
              if (Number.isInteger(value)) {
                return value;
              }
            },
          },
          title: {
            text: "No. of employees",
            style: {
              color: "#2A2A2A",
              fontSize: "10px",
              fontFamily: "Public Sans",
              fontWeight: 300,
            },
          },
        },
      },
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.getSleepQuality !== this.props.getSleepQuality) {
      const apiDa = [
        {
          data: [
            this.props.getSleepQuality?.["Poor"],
            this.props.getSleepQuality?.["Good"],
            this.props.getSleepQuality?.["Very Good"],
          ],
        },
      ];
      this.setState({
        ...this.state,
        series: apiDa,
        options: {
          ...this.state.options,
          yaxis: {
            ...this.state.options.yaxis,
            max: this.props.getSleepQuality["count"],
          },
        },
      });
    }
  }

  render() {
    return (
      <div className={styles.mainChartWrapper}>
        <div className={styles.wrapper} id="chart">
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="bar"
            width={"100%"}
            height={250}
          />
        </div>
        <div className={styles.bottomLabel}>
          <div className={styles.bottomlabelText}>
            <span className={styles.LevelOne}></span>
            <div className={styles.bottomText}>
              <p className={styles.levelSec}>Poor</p>
            </div>
          </div>
          <div className={styles.bottomlabelText}>
            <span className={styles.LevelTwo}></span>
            <div className={styles.bottomText}>
              <p className={styles.levelSec}>Good</p>
            </div>
          </div>
          <div className={styles.bottomlabelText}>
            <span className={styles.LevelThree}></span>
            <div className={styles.bottomText}>
              <p className={styles.levelSec}>Very Good</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SleepQualityChart;
