import { createSlice } from "@reduxjs/toolkit";

const getTestimonialSlice = createSlice({
  name: "get feedbacks",
  initialState: {
    newTestimonials: [],
    acceptedTestimonials: [],
    rejectedTestimonials: [],
    enterpriseTestimonials: [],
  },
  reducers: {
    getNewTestimonials: (state, action) => {
      state.newTestimonials = action.payload;
    },
    getAcceptedTestimonials: (state, action) => {
      state.acceptedTestimonials = action.payload;
    },
    getRejectedTestimonials: (state, action) => {
      state.rejectedTestimonials = action.payload;
    },
    getEnterpriseTestimonials: (state, action) => {
      state.enterpriseTestimonials = action.payload;
    },
  },
});

export const {
  getNewTestimonials,
  getAcceptedTestimonials,
  getRejectedTestimonials,
  getEnterpriseTestimonials,
} = getTestimonialSlice.actions;

export default getTestimonialSlice.reducer;
