import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { RiCloseLine } from "react-icons/ri";
import styles from "../../assets/css/PatientUI/servicepopup.module.css";
import { Btn } from "./Button";

const closeBtn = {
  cursor: "pointer",
  fontWeight: "500",
  padding: "5px 8px",
  borderRadius: "8px",
  border: "none",
  fontSize: "18px",
  color: "#2c3e50",
  background: "white",
  transition: "all 0.25s ease",
  boxShadow: "0 5px 20px 0 rgb(0 0 0 / 6%)",
  position: "relative",
  bottom: "0px",
  left: "0px",
};

export default function Popup({ popupAction, setPopupAction, title, icon }) {
  const handleClose = () => setPopupAction({ ...popupAction, open: false });
  return (
    <div>
      <Modal open={popupAction.open}>
        <Box className={styles.popUpContainer}>
          <div
            style={{
              width: "100%",
              background: "#7a51ae",
              top: "0px",
              left: "0px",
              position: "absolute",
              borderTopLeftRadius: "19px",
              borderTopRightRadius: "19px",
              height: "40px",
              color: "white",
              fontSize: "18px",
              fontWeight: "600",
              padding: "5px",
              textTransform: "uppercase",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                alignSelf: "center",
                width: "100%",
                textAlign: "center",
              }}
            >
              Notification
            </div>
            <button style={closeBtn} onClick={handleClose}>
              <RiCloseLine style={{ marginBottom: "-3px" }} />
            </button>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "40px",
              margin: "auto",
            }}
          >
            {icon}
            {/* <h2>{popupAction.name}</h2> */}
            <p
              style={{
                fontSize: "18px",
                fontWeight: "500",
                margin: "40px 0px",
                textAlign: "center",
                lineHeight: "26px",
              }}
            >
              {title}
            </p>
            <Btn
              bgColor="#7A51AE"
              width="100px"
              height="35px"
              variant="contained"
              click={handleClose}
            >
              Ok
            </Btn>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
