import { Avatar } from "./Avatar";
import { IconShadow } from "./IconShadow";
import { Camera } from "../../assets/icons/Camera";
import styles from "../../assets/css/UI/uploadProfile.module.css";
import { useState } from "react";
import { TEXT } from "../../Samvenda-Admin/contant/constant";

export const UploadProfile = (props) => {
  const [av, setAv] = useState("");
  return (
    <>
      <input
        name={props.name}
        type="file"
        id="file"
        accept="image/jpeg, image/png, image/jpg"
        style={{ display: "none" }}
        onChange={(e) => {
          const file = e.target.files[0];
          console.log(e.target.files);
          const avatar =
            file.size > TEXT.MAX_FILE_SIZE ? "" : URL.createObjectURL(file);
          props.upload(file);
          props.setImageSize && props.setImageSize(file.size);
          setAv(avatar);
        }}
        disabled={props.disabled}
      />
      <div className={styles.profilePic}>
        <label htmlFor="file">
          <div className={styles.profile}>
            <Avatar
              border
              size={props.size || "160px"}
              bgSize="cover"
              profile={av || props.profile}
            />
            <div className={styles.icon}>
              <IconShadow
                color={props.color || "#E69A8DF7"}
                size="36px"
                icon={<Camera />}
              />
            </div>
          </div>
        </label>
        {props.removeButton && (
          <p style={{ fontSize: "14px", color: "#2A2A2A", fontWeight: "600" }}>
            Remove Picture
          </p>
        )}
        {props.imgCaption}
        <p style={{ color: "red" }}>{props?.error?.toString()}</p>
        <p style={{ fontSize: '12px' }}>{"Max Image Size is 2MB"}</p>
      </div>
    </>
  );
};
