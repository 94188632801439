import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRadioOption } from "../../Redux/Slices/QuickAsk/index";
import classes from "./radioBtn.module.css";

export const RadioBtn = ({ question }) => {
  const [clicked, setClicked] = useState(null);
  const dispatch = useDispatch();

  const handleClick = (mutid) => {
    //console.log(mutid, "iddddd");
    dispatch(setRadioOption(mutid));
    setClicked(mutid);
  };
  console.log(clicked, 'clicked')

  return (
    <div className={classes.cardContainer}>
      {question.options?.map((option, index) => {
        return (
          <div
            key={index}
            id={`${option.option}`}
            onClick={() => handleClick(`${option.id}`)}
            className={""}
            style={{
              height: `${option.image.length !== 0 ? "105px" : "fit-content"}`,
              border: "2px solid #b6b6b6",
              minHeight: "105px",
              padding: "5px",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              borderColor: clicked === `${option.id}` ? "#7a51ae" : "#b6b6b6",
              // background: clicked === `${option.id}` ? "#7a51ae50" : "",
            }}
          >
            <div style={{ textAlign: "center" }}>
              {option.image && (
                <img
                  src={option.image}
                  style={{ width: "35px", height: "35px" }}
                  alt="option"
                />
              )}
              <p>{option.option}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};
