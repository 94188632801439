import React from "react";
import classes from "../../assets/css/login.module.css";
import AssessmentStyles from "../../assets/css/assessment.module.css";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import LoginImage from "../../assets/icons/LoginImage.svg";
import logo from "../../assets/icons/icon/logo.svg";
import { Input } from "../../Component/UI/CustomInputs";
import { useState } from "react";
import { resetPassword } from "../../services/patient/forgotPassword";
import { Toaster } from "react-hot-toast";
import toast from "react-hot-toast";
import { Button } from "@mui/material";
import { passwordValidate } from "../../config/passwordValidate";
import { useEffect } from "react";
import { useContext } from "react";
import AuthContext from "../../Auth/AuthContext";

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [msg, setMessage] = useState("");

  const handleChange = (e) => {
    setNewPasswordError("");
    setConfirmPasswordError("");
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [disableBtn, setDisableBtn] = useState(false);

  const ctx = useContext(AuthContext);
  const navigate = useNavigate();
  const is_loggedin = ctx?.loginUser?.is_loggedin;

  useEffect(() => {
    is_loggedin && navigate("/dashboard");
  }, []);

  const validate = (formData) => {
    let isValid = false;
    if (!passwordValidate(formData.newPassword)) {
      setNewPasswordError(
        "Password should be atleast of 8 characters. Must contain CAPITAL letter, Special character (@,$...) & number"
      );
      isValid = false;
    }
    if (!formData.newPassword.trim().length) {
      setConfirmPasswordError("Password does not match");
      isValid = false;
    }
    if (formData.newPassword !== formData.confirmPassword) {
      setConfirmPasswordError("Password does not match");
      isValid = false;
    } else {
      setNewPasswordError("");
      setConfirmPasswordError("");
      isValid = true;
    }
    return isValid;
  };

  const fetchApi = async () => {
    resetPassword(formData)
      .then((res) => {
        setDisableBtn(false);
        if (res?.data?.status) {
          setMessage(res.data.msg);
          toast.success("Password Changed Successfully");
          setTimeout(() => {
            navigate("/user/signin");
          }, 2000);
        } else {
          setDisableBtn(false);
        }
      })
      .catch((err) => setDisableBtn(false));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const isValid = validate(formData);
    if (isValid) {
      fetchApi();
    }
  };
  return (
    <>
      <Toaster />
      <div
        className={classes.login_container}
        style={{ background: `url(${LoginImage})` }}
      >
        <header className={AssessmentStyles.header}>
          <Link to="/">
            <img className={AssessmentStyles.logo} src={logo} alt="logo" />
          </Link>
        </header>
        <div className={classes.login_subcontainer}>
          <form onSubmit={onSubmit}>
            <p className={classes.heading}>Reset Password !</p>
            <div>
              <div className={classes.input}>
                <Input
                  type="password"
                  label="New Password"
                  id="newPassword"
                  value={formData.newPassword}
                  name="newPassword"
                  onChange={handleChange}
                  placeholder="New Password"
                  style={{ outlineColor: "#7a51ae" }}
                  autoComplete="off"
                  error={newPasswordError}
                />
              </div>
            </div>

            <div>
              <div className={classes.input}>
                <Input
                  type="password"
                  id="confirmPassword"
                  value={formData.confirmPassword}
                  name="confirmPassword"
                  label="Confirm Password"
                  onChange={handleChange}
                  placeholder="Confirm Password"
                  style={{ outlineColor: "#7a51ae" }}
                  autoComplete="off"
                  error={confirmPasswordError}
                />
              </div>
            </div>
            <div className={classes.submit} style={{ margin: "20px 0" }}>
              {msg && <p className={classes.error_msg}>{msg}</p>}
              <Button
                variant="contained"
                size="small"
                type="submit"
                // disabled={disableBtn}
                style={{
                  textTransform: "none",
                  width: "100%",
                  height: "48px",
                  backgroundColor: "#7A51AE",
                }}
                onClick={onSubmit}
              >
                {disableBtn ? (
                  <CircularProgress color="inherit" />
                ) : (
                  "Reset Password"
                )}
              </Button>
            </div>
            <Link to="/user/signin">
              <p style={{ color: "#7a51ae" }}>Back to Sign In</p>
            </Link>
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
