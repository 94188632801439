import React from "react";
import BottomCard from "./BottomCard";
import { GiSandsOfTime } from "react-icons/gi";
import { FaFireAlt } from "react-icons/fa";
import styles from "../../../../assets/css/PatientDashboard/progresstrend.module.css";

import Nutrition from "../../../../assets/icons/ProgressTrend/Nutrition.svg";
import Social from "../../../../assets/icons/ProgressTrend/Social.svg";
import Cognitve from "../../../../assets/icons/ProgressTrend/Cognitve.svg";
import Selfcare from "../../../../assets/icons/ProgressTrend/Selfcare.svg";
import Exercise from "../../../../assets/icons/ProgressTrend/Exercise.svg";

import LongestStreak from "../../../../assets/icons/ProgressTrend/LongestStreak.svg";
import CurrentStreak from "../../../../assets/icons/ProgressTrend/CurrentStreak.svg";

const BottomCardContainer = ({ dailyTracker, streakData }) => {
  const icons = {
    "Nutritional Wellness": Nutrition,
    "Cognitive Wellness": Cognitve,
    "Emotional Wellness": Selfcare,
    "Social Wellness": Social,
    "Physical Wellness": Exercise,
  };
  const bgColor = {
    "Nutritional Wellness": "#50CD8933",
    "Cognitive Wellness": "#00CCF133",
    "Emotional Wellness": "#FF797833",
    "Social Wellness": "#7A51AE33",
    "Physical Wellness": "#FFC07033",
  };
  const Names = {
    "Nutritional Wellness": "Nutrition",
    "Cognitive Wellness": "Cognition",
    "Emotional Wellness": "Self-Care",
    "Social Wellness": "Socialisation",
    "Physical Wellness": "Exercise",
  };

  console.log(dailyTracker);

  // const LeastIcon = icons.dailyTracker?.occurred?.min;
  return (
    <div className={styles.mainbottomcard}>
      <BottomCard
        text={"Longest streak"}
        days={
          streakData?.high_streak > 1
            ? `${streakData?.high_streak} days`
            : `${streakData?.high_streak} day`
        }
        color="#fac1b7"
        icon={LongestStreak}
      />
      <BottomCard
        text={"Current streak"}
        days={
          streakData?.lowest_streak > 1
            ? `${streakData?.lowest_streak} days`
            : `${streakData?.lowest_streak} day`
        }
        color="#fac1b7"
        icon={CurrentStreak}
      />
      <BottomCard
        text={"Least occurred activity"}
        days={Names[dailyTracker?.occurred?.min]}
        icon={icons[dailyTracker?.occurred?.min]}
        color={bgColor[dailyTracker?.occurred?.min]}
      />
      <BottomCard
        text={"Most occurred activity"}
        days={Names[dailyTracker?.occurred?.max]}
        icon={icons[dailyTracker?.occurred?.max]}
        color={bgColor[dailyTracker?.occurred?.max]}
      />
    </div>
  );
};

export default BottomCardContainer;
