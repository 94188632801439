import React from 'react'
import BasicBreadcrumbs from '../../../Component/UI/BreadCrumb/BreadCrumbs'
import PaperWrapper from '../../../Component/UI/PaperWrapper/PaperWrapper'
import Tables from '../../../Hooks/Table'
import Expertsdata from '../../../MockData/Experts.json'

const Experts = () => {
  const ecolumns = Object.keys(Expertsdata[0]);
  return (
    <BasicBreadcrumbs link1={"Assessment"}  link3={"Add Asessment"}>
      <PaperWrapper>
        <Tables theadData={ecolumns} tbodyData={Expertsdata}/>
      </PaperWrapper>
    </BasicBreadcrumbs>
      
  )
}

export default Experts