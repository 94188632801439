import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import { RiCloseLine } from "react-icons/ri";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  height: 250,
  bgcolor: "background.paper",
  outline: "none",
  borderRadius: "20px",
  boxShadow: 12,
  p: 4,
};

export default function Popup({
  popupAction,
  setPopupAction,
  title,
  icon,
  heading,
  styles,
  btnMargin,
}) {
  const handleClose = () => setPopupAction({ ...popupAction, open: false });
  return (
    <div>
      <Modal
        open={popupAction.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ...styles,
          }}
        >
          <div
            style={{
              width: "100%",
              background: "#00B091",
              top: "0px",
              left: "0px",
              position: "absolute",
              borderTopLeftRadius: "19px",
              borderTopRightRadius: "19px",
              height: "40px",
              color: "white",
              fontSize: "18px",
              fontWeight: "600",
              padding: "5px",
              textTransform: "uppercase",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                alignSelf: "center",
                width: "100%",
                textAlign: "center",
              }}
            >
              {heading}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "25px",
            }}
          >
            {icon}
            <h2 style={{ width: "-webkit-fill-available", marginLeft: "12px" }}>
              {popupAction.name}
            </h2>
            <p
              style={{
                fontSize: "18px",
                fontWeight: "500",
                margin: "20px 12px",
                textAlign: "center",
                lineHeight: "20px",
              }}
            >
              {title}
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "-webkit-fill-available",
                margin: "4px 0",
              }}
            >
              <Btn
                bgColor="#fff"
                color="#000"
                width="150px"
                height="40px"
                variant="contained"
                margin={btnMargin}
                click={handleClose}
              >
                No
              </Btn>
              <Btn
                bgColor="#00B091"
                variant="contained"
                width="150px"
                height="40px"
                margin={btnMargin}
                click={popupAction.action}
              >
                Yes
              </Btn>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
