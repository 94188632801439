import { Paper } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Input, InputWithIcon } from "../../../Component/UI/CustomInputs";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import { Eye } from "../../../assets/icons/Eye";
import { password } from "../../../services/enterprise/password";
import { useState } from "react";

import { useEffect } from "react";
import { passwordValidate } from "../../../config/passwordValidate";
import { Toaster } from "../../../../node_modules/react-hot-toast/dist/index";
import { EnterpriseAddTestimonial } from "./Testimonial/Testimonial";
import { useLocation } from "../../../../node_modules/react-router-dom/index";

export const AddTestimonial = () => {
  const { state } = useLocation();
  return (
    <>
      <Toaster />

      <EnterpriseAddTestimonial state={state} />
    </>
  );
};
