import React, { useState, useMemo } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import InputLabel from "@mui/material/InputLabel";
import { useEffect } from "react";

export const DatePicker = ({
  label,
  minDate,
  getSelectedDate,
  defaultDate,
  disabled,
}) => {
  const [value, setValue] = React.useState(new Date());

  const handleChange = (event) => {
    setValue(event);
    getSelectedDate(event);
  };

  useEffect(() => {
    setValue(defaultDate);
  }, [defaultDate]);

  return (
    <>
      <InputLabel htmlFor="input-with-icon-adornment" sx={{ color: "#000" }}>
        {label}
      </InputLabel>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          inputFormat="MM/dd/yyyy"
          minDate={minDate}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  width: "320px",
                  borderRadius: "8px",
                },
              }}
            />
          )}
        />
      </LocalizationProvider>
    </>
  );
};
