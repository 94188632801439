import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: false,
}

const changeSequence = createSlice({
  name: "change sequence",
  initialState,
  reducers: {
    setChangeSequence: (state) => {
      state.status = !state.status
    },
    clearState: () => initialState
  },
});

export const { setChangeSequence } = changeSequence.actions;

export default changeSequence.reducer;