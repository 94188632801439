import {
  Select,
  Input,
  SelectWithId,
} from "../../../Component/UI/CustomInputs";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { UploadProfile } from "../../../Component/UI/UploadProfile";
import { clientDetails } from "../../../Redux/Slices/clientDetails";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  listCity,
  listCountries,
  listState,
} from "../../../services/others/locations";
import { formattedLocations } from "../../../config/formattedLocations";
import CountryCodes from "../../../assets/common/countryCode.json";

const ClientDetailsForm = ({ click, err }) => {
  const dispatch = useDispatch();
  const { data } = useSelector((i) => i.clientDetails);
  const navigate = useNavigate();

  const [countries, setCountries] = useState();
  const [states, setStates] = useState();
  const [cities, setCities] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(clientDetails({ [name]: value }));
  };

  useEffect(() => {
    listCountries().then((res) => {
      const formattedCountries = formattedLocations(res, "name");
      setCountries(formattedCountries);
    });

    data?.country &&
      listState(data?.country).then((res) => {
        const formattedStates = formattedLocations(res, "name");
        setStates(formattedStates);
      });

    data?.state &&
      listCity(data?.state).then((res) => {
        const formattedCities = formattedLocations(res, "city");
        setCities(formattedCities);
      });
  }, [data?.country, data?.state]);

  return (
    <div style={{ display: "flex" }}>
      {/* <BasicBreadcrumbs link={links} text="Add Client"> */}

      <div className="leftFields" style={{ width: "320px" }}>
        <Input
          name="name"
          label="Full Name"
          value={data?.name}
          error={err?.name}
          onChange={handleChange}
          req
        />
        <Input
          type="email"
          name="email"
          label="Email"
          error={err?.email}
          value={data?.email}
          onChange={handleChange}
          req
        />

        <Input
          type="number"
          name="emergency_mobile"
          label="Emergency Mobile Number"
          error={err?.emergency_mobile}
          value={data?.emergency_mobile}
          onChange={handleChange}
          req
        />
        <Select
          label="State"
          name="state"
          value={data?.state}
          error={err?.state}
          options={states}
          onChange={handleChange}
          req
        />
        <Input
          name="address_line1"
          label="Address Line 1"
          error={err?.address_line1}
          value={data?.address_line1}
          onChange={handleChange}
          req
        />
        <Input
          name="zipcode"
          label="Pincode"
          error={err?.zipcode}
          value={data?.zipcode}
          onChange={handleChange}
          req
        />

        <div className="formButton" style={{ marginTop: 20 }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#2A2A2A",
              borderRadius: 50,
              marginRight: 10,
            }}
            onClick={() => navigate("/patients")}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: "#00B091", borderRadius: 50 }}
            onClick={click}
          >
            Save
          </Button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "600px",
        }}
      >
        <div className="rightFields" style={{ width: "320px" }}>
          <Select
            label="Relationship With Patient"
            name="relation_with_paitient"
            error={err?.relation_with_paitient}
            value={data?.relation_with_paitient}
            options={[
              { id: 1, value: "Brother" },
              { id: 2, value: "Father" },
            ]}
            onChange={handleChange}
            req
          />
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <SelectWithId
              name="countryCode"
              options={CountryCodes}
              width="130px"
              label="Country Code"
              value={data?.countryCode}
              onChange={handleChange}
            />
            <Input
              type="number"
              name="mobile"
              label="Mobile"
              error={err?.mobile}
              value={data?.mobile}
              onChange={handleChange}
              req
            />
          </div>
          <Select
            label="Country"
            name="country"
            error={err?.country}
            options={countries}
            value={data?.country}
            onChange={(e) =>
              dispatch(clientDetails({ country: e.target.value }))
            }
            req
          />
          <Select
            label="City"
            name="city"
            error={err?.city}
            value={data?.city}
            options={cities}
            onChange={handleChange}
            req
          />
          <Input
            label="Address Line 2"
            name="address_line2"
            error={err?.address_line2}
            value={data?.address_line2}
            onChange={handleChange}
            req
          />
        </div>
        <UploadProfile
          name="avtar"
          error={err?.avtar}
          profile={data?.client_image}
          upload={(value) => dispatch(clientDetails({ avtar: value }))}
        />
      </div>

      {/* </BasicBreadcrumbs> */}
    </div>
  );
};

export default ClientDetailsForm;
