import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const {
  ENTERPRISE_ASSESSMENT_LIST_ADMIN,
  GET_INDUSTRY_LIST_ADMIN,
  GET_DESIGNATION_LIST_ADMIN,
  ENTERPRISE_SERVICE_LIST_ADMIN,
  ENTERPRISE_SUBSCRIPTIONS_LIST_ADMIN,
} = API;

export const enterpriseAssessmentListAdmin = async (data) => {
  return await axios
    .post(ENTERPRISE_ASSESSMENT_LIST_ADMIN, data)
    .then((res) => res)
    .catch((err) => err);
};

export const enterpriseSubscriptionsListAdmin = async (data) => {
  return await axios
    .post(ENTERPRISE_SUBSCRIPTIONS_LIST_ADMIN, data)
    .then((res) => res)
    .catch((err) => err);
};

export const getEnterpriseIndustryAdmin = async () => {
  return await axios
    .get(GET_INDUSTRY_LIST_ADMIN)
    .then((res) => res)
    .catch((err) => err);
};

export const getEnterpriseDesignationAdmin = async () => {
  return await axios
    .get(GET_DESIGNATION_LIST_ADMIN)
    .then((res) => res)
    .catch((err) => err);
};

export const enterpriseServiceListAdmin = async (data) => {
  return await axios
    .post(ENTERPRISE_SERVICE_LIST_ADMIN, data)
    .then((res) => res)
    .catch((err) => err);
};

export const enterpriseTestimonialListAdmin = async (enterprise_id) => {
  return await axios
    .get(`admin/enterprise/testimonial?status=2&enterprise_id=${enterprise_id}`)
    .then((res) => res)
    .catch((err) => err);
};

export const enterpriseAddTestimonial = async (data) => {
  return await axios
    .post(`/admin/add/testimonial`, data)
    .then((res) => res)
    .catch((err) => err);
};
