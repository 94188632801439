import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getDoctorNew } from "../../../services/patient/doctor";
import Doctors from "./Doctors";

export const Counselors = () => {
  const [doctorList, setDoctorList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    getDoctorNew(1).then((res) => {
      setIsLoading(false);
      setDoctorList(res?.data?.list);
    });
  }, []);
  return (
    <>
      <Doctors
        Counselors={"No Counselor Found"}
        doctorList={doctorList}
        isLoading={isLoading}
      />
    </>
  );
};
