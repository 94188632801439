import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import styles from "../assets/css/userpanel.module.css";

import user from "../assets/icons/icon/user.svg";
import message from "../assets/icons/icon/message-circle.svg";
import smile from "../assets/icons/icon/smile.svg";
import help from "../assets/icons/icon/help-circle.svg";
import logout from "../assets/icons/icon/log-out.svg";
import profile from "../assets/icons/icon/default_profile.svg";
import { Link, useNavigate } from "react-router-dom";
import { Avatar } from "../Component/UI/Avatar";
import { useContext } from "react";
import AuthContext from "../Auth/AuthContext";
import { useAuth } from "../Auth/AuthProvider";
import { to12Hour } from "../config/formattedDate";
import { useDispatch } from "react-redux";
import { setCategoryId } from "../Redux/Reducers/PatientReducers/qa_storage/qa_storage";
import Facebook from "../assets/images/facebook.svg";
import YouTube from "../assets/images/youtube.svg";
import InstaGram from "../assets/images/instagram.svg";
import Twitter from "../assets/images/twitter.svg";
// import Linkdin from "../assets/images/linked-in.webp";
export const UserPanel = ({ withBorder }) => {
  const [state, setState] = React.useState({});
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // const ctx = useContext(AuthContext);
  const ctx = useAuth();

  const d = new Date();
  const currentDate = `${d.getDate()} ${
    months[d.getMonth()]
  } ${d.getFullYear()}`;

  const icons = [user, message, smile, help, logout];

  const options = [
    "My Account",
    "Testimonials",
    "Give Feedback",
    "Contact us",
    "Logout",
  ];

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div className={styles.container}>
      <Box
        sx={{
          width: 240,
          color: "#2A2A2A",
          position: "relative",
          height: "100vh",
        }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <div className={styles.top}>
          <Avatar
            profile={ctx?.loginUser?.customer?.customer_image}
            size="100px"
            border
          />
          <h1 className={styles.name}>
            {ctx?.loginUser?.customer?.name.toUpperCase()}
          </h1>
          {/*           <p className={styles.info}>Member since May 2022</p> */}
          {/* need to show created at field  */}
        </div>
        <List>
          {options.map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => {
                  switch (text) {
                    case options[0]:
                      navigate("/patient/MyAccount");
                      break;
                    case options[1]:
                      navigate("/patient/testimonial");
                      break;
                    case options[2]:
                      navigate("/patient/feedback");
                      break;
                    case options[4]:
                      ctx.logout();
                      navigate("/user/signin");
                      dispatch(setCategoryId(""));
                      break;
                    case options[3]:
                      window.open(
                        "https://www.samvednacare.com/contact-us",
                        "_blank"
                      );
                      break;
                    default:
                  }
                }}
              >
                <img className={styles.icons} src={icons[index]} alt="icons" />
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <div className={styles.socialIcons}>
          <h1>Follow us on :</h1>

          <ul>
            <li>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.facebook.com/samvednacare"
              >
                <img
                  loading="lazy"
                  src={Facebook}
                  alt="img"
                  class="img-fluid"
                  width="12"
                  height="23"
                />
              </a>
            </li>
            <li>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.youtube.com/channel/UC2aT18t9dQ1ZrjXfx_NvqoA"
              >
                <img
                  loading="lazy"
                  src={YouTube}
                  alt="img"
                  class="img-fluid"
                  width="23"
                  height="16"
                />
              </a>
            </li>

            <li>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.instagram.com/samvednacare"
              >
                <img
                  loading="lazy"
                  src={InstaGram}
                  alt="img"
                  class="img-fluid"
                  width="21"
                  height="21"
                />
              </a>
            </li>
            {/* <li>
              <Link
                rel="nofollow"
                target="_blank"
                to="https://twitter.com/SamvednaSeniorC"
              >
                <img
                  loading="lazy"
                  src={Twitter}
                  alt="img"
                  class="img-fluid"
                  width="23"
                  height="19"
                />
              </Link>
            </li>
            <li>
              <Link
                rel="nofollow"
                target="_blank"
                to="https://www.linkedin.com/company/samvedna-senior-care-private-limited"
              >
                <img
                  loading="lazy"
                  src={Linkdin}
                  alt="img"
                  class="img-fluid"
                  width="16"
                  height="16"
                />
              </Link>
            </li> */}
          </ul>
        </div>
        <div className={styles.bottom}>
          <p>Last account activity </p>
          {currentDate},<p>{to12Hour(d)}</p>
        </div>
      </Box>
    </div>
  );

  return (
    <div>
      <div onClick={toggleDrawer("right", true)}>
        <Avatar
          profile={ctx?.loginUser?.customer?.customer_image}
          border={withBorder ? true : false}
        />
      </div>
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        {list("right")}
      </Drawer>
    </div>
  );
};
