import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const { ADD_TESTIMONIAL } = API;

export const addTestimonial = async (data) => {
    return await axios
      .post(ADD_TESTIMONIAL, data)
      .then((res) => res)
      .catch((err) => err);
  };
  