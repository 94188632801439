import React, { useEffect, useState } from "react";
import styles from "./assignPopup.module.css";
import { RiCloseLine } from "react-icons/ri";
import DoctorsImg from "../../../assets/images/doctor.svg";
import { Datepicker, Input, Select } from "../../../Component/UI/CustomInputs";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import { getRoles, getRolesApp } from "../../../services/admin/staff";
import {
  reAssignStaff,
  getreschedulestaff,
} from "../../../services/admin/appointment";
import { toast } from "../../../../node_modules/react-hot-toast/dist/index";
import { StringDtoY, to12Hour } from "../../../config/formattedDate";
import moment from "moment";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";
import { useAuth } from "../../../Auth/AuthProvider";
const ReschedulePopup = ({
  setAssignSuccess,
  setRescheduleAppointment,
  data,
  modalData,
  getApi,
}) => {
  const [roles, setRoles] = useState();
  const [staff, setStaff] = useState();
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedStaffList, setSelectedStaffList] = useState();
  const ctx = useAuth();

  const condCheckRoles = ctx?.loginUser?.admin?.access_role;

  const [reAssignData, setReAssignData] = useState({
    appointment_link: data?.body?.appointment_link,
  });
  const [err, setErr] = useState({});
  const [appDate, setAppDate] = useState({
    start_date: data?.body?.appointment_date,
    staffId: data?.body?.staff_id,
  });
  const [reserveTime, setReserveTime] = useState([]);
  const [timeSlotFilter, setTimeSlotFilter] = useState([]);

  const [timeSlotNew, setTimeSlotNew] = useState();
  const [currentDate, setCurrentDate] = useState();
  const [loading, setLoading] = useState(false);

  const timeSlot = data?.body?.time_slot;
  const appointmentDate = data?.body?.appointment_date;
  const service_type = data?.body?.service_type;
  const rosteridSelectedTime = data?.body?.roster_id;
  const [selectedRosterId, setSelectedRosterId] = useState();

  useEffect(() => {
    setCurrentDate(moment(appDate?.start_date).isSame(moment(), "day"));
    getreschedulestaff(appDate?.start_date).then((res) => {
      setStaff(res?.data?.list);
    });
  }, [appDate?.start_date]);

  useEffect(() => {
    getRolesApp().then((res) => {
      const list = res?.data?.list?.map(({ name, id }, i) => {
        return { id, value: name, ...(i === 0 && { selected: true }) };
      });
      setRoles(list);
      const selectedSpecialization_id =
        data?.body?.staff_data?.role_name?.filter(
          (i) => i?.id?.toString() === data?.body?.specialization_id?.toString()
        );
      setSelectedRole(selectedSpecialization_id[0].id);
    });
  }, []);

  useEffect(() => {
    const staffByRole = staff?.filter((item) => {
      const filteredStaff = item?.role_name?.filter(
        (i) => i?.id?.toString() === selectedRole?.toString()
      );

      if (filteredStaff.length !== 0) return item;
      else return "";
    });

    setSelectedStaffList(
      staffByRole
        ?.filter((i) => i !== "")
        ?.map((i) => {
          const availability = i?.availability?.some(
            (k) => moment(k?.schedule_date).isSame(appDate?.start_date),
            "day"
          );
          if (availability) {
            return { id: i.id, value: i.name };
          }
        })
        .filter((i) => i !== undefined)
    );
    // setSelectedStaffList(
    //   staffByRole
    //     ?.filter((i) => i !== "")
    //     .map((i) => {
    //       return { id: i.id, value: i.name };
    //     })
    // );
    // setAppDate({
    //   ...appDate,
    //   staffId: staffByRole && staffByRole[0].id
    // })
  }, [selectedRole, staff]);

  const timeSlotdata = [
    {
      id: "09",
      value: "09:00 AM - 10:00 AM",
    },
    {
      id: "10",
      value: "10:00 AM - 11:00 AM",
    },
    {
      id: "11",
      value: "11:00 AM - 12:00 PM",
    },
    {
      id: "12",
      value: "12:00 PM - 01:00 PM",
    },
    {
      id: "13",
      value: "01:00 PM - 02:00 PM",
    },
    {
      id: "14",
      value: "02:00 PM - 03:00 PM",
    },
    {
      id: "15",
      value: "03:00 PM - 04:00 PM",
    },
    {
      id: "16",
      value: "04:00 PM - 05:00 PM",
    },
    {
      id: "17",
      value: "05:00 PM - 06:00 PM",
    },
    {
      id: "18",
      value: "06:00 PM - 07:00 PM",
    },
  ];

  useEffect(() => {
    setReserveTime(
      staff?.find((i) => i?.id?.toString() === appDate?.staffId?.toString())
        ?.availability
    );
  }, [staff, appDate?.staffId]);

  useEffect(() => {
    const selectedTime = timeSlot?.split(":");
    setAppDate({
      ...appDate,
      start_time: selectedTime[0]?.toString(),
    });
  }, []);

  useEffect(() => {
    const currentTime = moment().format("HH");
    // const currentTime = moment().add(1, "hour").format("HH");

    const reserveTimedata = reserveTime
      ?.map((i) => {
        const bookedDate = moment(i?.schedule_date).format("YYYY-MM-DD");
        const dateSame = moment(bookedDate).isSame(appDate?.start_date);
        if (dateSame && i?.status !== 2 && i?.status !== 4) {
          const sueduledDate = i?.schedule_date + " " + i?.slot_time;
          return moment(sueduledDate).format("HH");
        } else {
          return null;
        }
      })
      .filter((i) => i !== null);

    //     const reserveTimeId = reserveTime?.map((i) =>
    //    {
    //     const bookedDate = moment(i?.appointment_date).format("YYYY-MM-DD")
    //     const dateSame = moment(bookedDate).isSame(appDate?.start_date)
    //     if(dateSame && i?.status !== 2 && i?.status !== 4){
    //       return {
    //         ...i,
    //         id :  moment(i?.appointment_date).format("HH")
    //       }
    //      }
    //      else {
    //        return null
    //      }

    // }
    //   ).filter((i) => i !== null)

    const timeSlotList = timeSlotdata?.map((i, index) => {
      // const reserveTimeStatus = reserveTimeId?.find((j) => j.id === i.id)
      if (reserveTimedata?.includes(i.id)) {
        return { ...i, disabled: false };
      } else {
        return { ...i, disabled: true };
      }
    });
    setTimeSlotFilter(timeSlotList);
    if (currentDate) {
      setTimeSlotFilter(
        timeSlotList.map((i) => {
          if (i.id <= currentTime) {
            return { ...i, disabled: true };
          } else {
            return i;
          }
        })
      );
    }
  }, [reserveTime, appDate]);

  //get rosterId base on time slot
  useEffect(() => {
    //get Roster id base on Select time slot

    reserveTime
      ?.map((i) => {
        const bookedDate = moment(i?.schedule_date).format("YYYY-MM-DD");
        // const rosterIdInTime = i?timeSlot === appDate?.start_time +
        const dateSame = moment(bookedDate).isSame(appDate?.start_date);
        const sueduledDateTime = i?.schedule_date + " " + i?.slot_time;
        const rosterIdInTime = moment(sueduledDateTime).format("HH");
        if (dateSame && i?.status !== 2 && i?.status !== 4) {
          if (rosterIdInTime === appDate?.start_time) {
            return setSelectedRosterId(i?.id);
          }
        } else {
          return null;
        }
      })
      .filter((i) => i !== null);
  }, [appDate?.start_time, reserveTime]);

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    const appTimeSlot = staff?.filter((item) => {
      const filteredTime = item?.appointment?.filter(
        (i) => i?.staff_id?.toString() === reAssignData?.staff_id?.toString()
      );

      if (filteredTime?.length !== 0) {
        return item;
      } else {
        return "";
      }
    });

    setTimeSlotNew(
      appTimeSlot &&
        appTimeSlot
          .at(0)
          ?.appointment?.filter((i) => i !== "")
          .map((i) => {
            var timeNew = moment(i.appointment_date)
              .add(1, "hour")
              .format("hh:mm A");
            return {
              id: i.appointment_time,
              value: `${to12Hour(i.appointment_time)} - ${timeNew}`,
            };
          })
    );
  }, [reAssignData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setReAssignData({ ...reAssignData, [name]: value });
  };

  const handleSubmit = () => {
    if (!appDate?.start_time) {
      return toast.error("Time Slot is required");
    }
    setLoading(true);
    const finalData = {
      appointment_id: data?.id,
      staff_id: appDate?.staffId,
      specialization_id: selectedRole,
      start_time: appDate?.start_time + ":00:00",
      appointment_date: appDate?.start_date,
      appointment_link: reAssignData?.appointment_link,
      type_of_service: service_type,
      roster_id: selectedRosterId ? selectedRosterId : rosteridSelectedTime,
    };
    reAssignStaff(finalData).then((res) => {
      setLoading(false);
      setErr(res?.data?.error);
      if (res?.data?.status) {
        setRescheduleAppointment({ open: false });
        setAssignSuccess({ open: true, body: { data: res?.data?.msg } });
        getApi();
      }
    });
  };

  return (
    <>
      <div
        className={styles.darkBG}
        onClick={() => setRescheduleAppointment({ open: false })}
      />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <div className={styles.heading}>
              <div style={{ width: "100%" }}>{"Reschedule Appointment"} </div>
              {
                <button
                  className={styles.closeBtn}
                  onClick={() => setRescheduleAppointment({ open: false })}
                >
                  <RiCloseLine style={{ marginBottom: "-3px" }} />
                </button>
              }
            </div>
          </div>

          <div className={styles.modalContent}>
            <div className={styles.mainDiv}>
              <div style={{}}>
                <p style={{ color: "#000", fontSize: "15px" }}>
                  Appointment Date
                </p>
                <div
                  style={{ width: "222px", marginTop: "8px" }}
                  className={styles.options}
                >
                  <Datepicker
                    label=""
                    name="start_date"
                    // display="flex"
                    minDate={new Date()}
                    // maxDate={new Date().setMonth(4)}
                    defaultDate={data?.body?.appointment_date}
                    //defaultDate={appDate?.start_date}
                    onChange={(e) => {
                      setAppDate({
                        ...appDate,
                        staffId: "",
                        start_time: "",
                        start_date: moment(e).format("YYYY-MM-DD"),
                      });
                    }}
                  />
                </div>
              </div>
              <div style={{}}>
                <p style={{ color: "#000", fontSize: "15px" }}>
                  Type of Specialization
                </p>
                <div style={{ width: "222px" }} className={styles.options}>
                  <Select
                    disabled={
                      condCheckRoles === "3" ||
                      condCheckRoles === "4" ||
                      condCheckRoles === "5"
                    }
                    options={roles}
                    value={selectedRole}
                    onChange={(e) => {
                      setSelectedRole(e.target.value);
                      setAppDate({
                        ...appDate,
                        staffId: "",
                      });
                    }}
                  />
                </div>
              </div>
            </div>

            <div className={styles.mainDiv}>
              <div style={{}}>
                <p style={{ color: "#000", fontSize: "15px" }}>Select Doctor</p>
                <div style={{ width: "222px" }} className={styles.options}>
                  <Select
                    disabled={
                      condCheckRoles === "3" ||
                      condCheckRoles === "4" ||
                      condCheckRoles === "5"
                    }
                    name="staff_id"
                    options={selectedStaffList}
                    onChange={(e) => {
                      var selectedId = e.target.value;
                      setAppDate({
                        ...appDate,
                        staffId: selectedId,
                        start_time: "",
                      });
                    }}
                    error={err?.staff_id}
                    value={appDate?.staffId}
                  />
                </div>
              </div>

              <div style={{}}>
                <p style={{ color: "#000", fontSize: "15px" }}>Time Slot</p>
                <div style={{ width: "222px" }} className={styles.options}>
                  <Select
                    className={styles.timeSlot}
                    name="start_time"
                    options={timeSlotFilter}
                    value={appDate?.start_time}
                    onChange={(e) => {
                      setAppDate({
                        ...appDate,
                        start_time: e.target.value,
                      });
                    }}
                    disabled={!appDate?.staffId}
                  />
                </div>
              </div>
            </div>
            <div style={{}}>
              <p style={{ color: "#000", fontSize: "15px" }}>
                {service_type === 0
                  ? "Address"
                  : service_type === 1
                  ? "Appointment Link"
                  : service_type === 2
                  ? "Appointment Link/Address"
                  : ""}
              </p>
              <div
                style={{ width: "100%", marginTop: "8px" }}
                className={styles.options}
              >
                <Input
                  name="appointment_link"
                  onChange={handleChange}
                  error={err?.appointment_link}
                  value={reAssignData?.appointment_link}
                  // width="260px"
                  //value={servicesCardData?.length}
                />
              </div>
            </div>
          </div>
          {loading && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress size={30} />
              <p style={{ color: "black" }}>
                Please wait ! We are validating the details...
              </p>
            </div>
          )}
          <div className={styles.btnGroup}>
            <Btn
              variant="contained"
              bgColor="#000"
              color="#ffffff"
              width="120px"
              height="40px"
              whiteSpace="nowrap"
              borderRadius="100px"
              margin="15px"
              click={() => {
                setRescheduleAppointment({ open: false });
              }}
            >
              Cancel
            </Btn>
            <Btn
              variant="contained"
              bgColor="#00B091"
              color="#ffffff"
              width="120px"
              height="40px"
              whiteSpace="nowrap"
              disabled={loading}
              borderRadius="100px"
              click={handleSubmit}
            >
              Update
            </Btn>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReschedulePopup;
