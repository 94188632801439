import React from "react";
import ReactApexChart from "react-apexcharts";

class Chart extends React.Component {
  constructor(props) {
    super(props);
    console.log(props.sleepHourData, "props.sleepHourData");
    // const d = props.getSleepTracker;
    // const apiData = [d?.level1, d?.level2, d?.level3, d?.level4];
    this.state = {
      series:
        props.type === "bar"
          ? [
              {
                data: props.sleepHourData,
              },
            ]
          : props.sleepHourData,

      noData: {
        text: "No data available",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "14px",
        },
      },
      options: {
        chart: {
          width: props.width,
          height: 250,
          type: props.type,
          stacked: true,
          events: {
            click: function (chart, w, e) {
              // console.log(chart, w, e)
            },
          },
          toolbar: {
            show: false,
            tools: {
              download: false,
            },
          },
        },
        colors: props.colors,
        labels: props.labels,
        plotOptions: {
          bar: {
            columnWidth: "15%",
            distributed: true,
          },
          pie: {
            donut: {
              size: "45%",
            },
          },
        },

        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },

        fill: {
          opacity: 1,
        },
        xaxis: {
          categories: props.type === "bar" ? props.sleepHourDataCat ?? [] : "",
          labels: {
            style: {
              // colors: colors,
              fontSize: "12px",
            },
          },
        },
        yaxis: {
          // max: d?.customerCount,
          forceNiceScale: true,
          labels: {
            formatter: function (value) {
              if (Number.isInteger(value)) {
                return value;
              }
            },
          },
          // title: {
          //   text: "No. of employeess",
          //   style: {
          //     color: "#2A2A2A",
          //     fontSize: "10px",
          //     fontFamily: "Public Sans",
          //     fontWeight: 300,
          //   },
          // },
        },
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.type === "bar") {
      if (prevProps.sleepHourData !== this.props.sleepHourData) {
        const apiDa = [
          {
            data: this.props.sleepHourData,
          },
        ];
        this.setState({
          ...this.state,
          series: apiDa,
          options: {
            ...this.state.options,
            yaxis: {
              ...this.state.options.yaxis,
            },
          },
        });
      }
    } else {
      if (prevProps.sleepHourData !== this.props.sleepHourData) {
        const sum = this.props.sleepHourData.reduce((i, a) => i + a);
        const percentageValue = this.props.sleepHourData.map((i) => {
          const perctValue = (i / sum) * 100;
          return Math.round(perctValue);
        });
        this.setState({ ...this.state, series: percentageValue });
      }
    }
  }

  render() {
    return (
      <ReactApexChart
        options={this.state.options}
        series={this.state.series}
        type={this.props.type}
        height={250}
        width={this.props.width}
      />
    );
  }
}

export default Chart;
