import Button from "@mui/material/Button";

export const Btn = (props) => {
  return (
    <Button
      type="submit"
      variant={props.variant || "contained"}
      width={props.width}
      sx={{
        background: props.bgColor || "#7A51AE",
        color: props.color,
        borderColor: props.borderColor || props.color,
        fontSize: props.fontSize,
        fontWeight: props.fontWeight,
        textTransform: "none",
        width: props.width,
        height: props.height,
        display: props.display,
        boxShadow: props.boxShadow,
        fontFamily: props.fontFamily,
        whiteSpace: props.whiteSpace,
        borderRadius: props.borderRadius || "8px",
        "&:hover": {
          backgroundColor: props.bgColor || "#7A51AE",
          borderColor: props.borderColor || props.color,
        },
        margin: props.margin,
        marginBottom: props.marginBottom,
        marginRight: props.marginRight,
        border: `${props.border}px solid ${props.borderColor}`,
        padding: props.padding,
        ...props.style,
      }}
      disableRipple
      boxShadow={props.boxShadow}
      disabled={props.disabled}
      onClick={props.click}
      className={props.className}
    >
      {props.children}
    </Button>
  );
};
