import React from "react";
import styles from "../../PreLogin/Assessment/assessmentEnterprise.module.css";
import LoginImage from "../../../assets/icons/LoginImage.svg";
import { useLocation } from "../../../../node_modules/react-router-dom/index";

export const ThankYou = () => {
  const { state } = useLocation();
  return (
    <div
      className={styles.login_container}
      style={{
        background: `url(${LoginImage})`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 30,
      }}
    >
      <p style={{ fontWeight: 600, fontSize: 26 }}>Thank You</p>
      <p>{state?.message}</p>
    </div>
  );
};
