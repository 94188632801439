import { createSlice } from "@reduxjs/toolkit";

const resultDetailsSlice = createSlice({
  name: "result details",
  initialState: { data: "" },
  reducers: {
    resultDetails: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
  },
});

export const { resultDetails } = resultDetailsSlice.actions;

export default resultDetailsSlice.reducer;
