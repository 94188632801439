import stars from "../assets/icons/icon/stars.svg";
import styles from "../assets/css/PatientDashboard/eventCard.module.css";
import { Btn } from "./UI/Button";
import { AiOutlineClockCircle } from "react-icons/ai";
import { enrollEvent } from "../services/patient/events";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../Auth/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { enrollMessage } from "../Redux/Slices/PatientEvents";
import toast from "react-hot-toast";
import moment from "../../node_modules/moment/moment";
import { getEnrolledEvents } from "../Redux/Slices/UserEvents/enrolledEvents";
import { useNavigate, Link } from "react-router-dom";

export const EventCards = (props) => {
  const formatDate = (date, format) => moment(date).format(format);

  const month = formatDate(props.date, "MM");
  const date = formatDate(props.date, "DD");
  const [disable, setDisable] = useState(false);

  const ctx = useContext(AuthContext);
  const customerId = ctx.loginUser?.customer?.id;
  const dispatch = useDispatch();

  const [btnDisable, setBtnDisable] = useState(true);

  const { enrolled } = useSelector((i) => i.enrolledEvents);
  const { all } = useSelector((i) => i.PatientEvents);
  const navigate = useNavigate();
  useEffect(() => {
    const c = setInterval(() => {
      const dt = formatDate(new Date(), "YYYY-MM-DD HH:mm:ss");
      const currentTime = moment(dt, "YYYY-MM-DD hh:mm:ss");
      const eventTime = moment(props.date, "YYYY-MM-DD hh:mm:ss");
      const currentDate = formatDate(new Date(), "YYYY-MM-DD");
      const eventEndTime = moment(
        currentDate + " " + props.end,
        "YYYY-MM-DD hh:mm:ss"
      );

      const startDiff = eventTime.diff(currentTime, "seconds");
      const endDiff = eventEndTime.diff(currentTime, "seconds");

      const FinalEvents = all.filter((item) => {
        const FinalEndTime = moment(
          item.event_date.split(" ")[0] + " " + item.end_time,
          "YYYY-MM-DD hh:mm:ss"
        );
        return FinalEndTime.diff(currentTime, "seconds") > 0;
      });

      if (props.link) {
        if (startDiff <= 60) setBtnDisable(false);

        if (endDiff <= 0 || props.status === "Completed") {
          setBtnDisable(true);
          dispatch(getEnrolledEvents(FinalEvents));
        }
      }
    }, 1000);

    return () => clearInterval(c);
  }, []);

  const startTime = moment(props.start, "HH:mm:ss").format("hh:mm A");
  const endTime = moment(props.end, "HH:mm:ss").format("hh:mm A");

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleEnroll = () => {
    setDisable(true);
    enrollEvent(props.eventId, customerId)
      .then((i) => {
        setDisable(false);
        dispatch(enrollMessage(i));
        toast.success("Event Enrolled Successfully!");
      })
      .catch((i) => {
        setDisable(false);
        toast.success("Something went wrong!");
      });
  };

  return (
    <div
      className={
        props.dashboard === "dashboard"
          ? styles.dashboardEventCard
          : styles.eventCard
      }
      style={{
        margin: props.margin,
        cursor: "pointer",
        userSelect: "none",
      }}
    >
      <div className={styles.header}>
        <div>
          <h2>{date}</h2>
          <h2>{months[month - 1]}</h2>
        </div>
        <img
          style={{ width: "50%", position: "absolute", right: "0" }}
          src={stars}
          alt="stars"
        />
      </div>
      <div className={styles.content}>
        <div
          className={
            props.dashboard === "dashboard"
              ? styles.dashboard
              : styles.innerContainer
          }
        >
          <h2>{props.name}</h2>
          <div className={styles.timing}>
            <AiOutlineClockCircle style={{ fontSize: "14px" }} />
            <p style={{ fontSize: "13px" }}>
              {startTime} - {endTime}
            </p>
          </div>
          <p className={styles.eventMode}>
            Mode : {!props.type ? "Offline" : "Online"}
          </p>

          <p className={styles.eventMode}>
            Speaker Name : {props.speaker_name}
          </p>
          {props.dashboard ? (
            ""
          ) : (
            <p className={styles.summary}>{props.summary}</p>
          )}
        </div>
        {props.status?.toLowerCase() !== "completed" ? (
          <a
            href={!btnDisable && props.link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: "contents" }}
          >
            <div
              style={{
                pointerEvents: "auto",
                with: props.width,
                // marginTop: props.marginTop
              }}
            >
              {props.btnText === "Enrolled" ? (
                <Btn
                  className={styles.joinBtn}
                  style={{ width: "100%" }}
                  disabled
                  variant="contained"
                >
                  {props.btnText}
                </Btn>
              ) : (
                <Btn
                  className={styles.joinBtn}
                  style={{ width: "100%" }}
                  width={90}
                  height={36}
                  variant="contained"
                  display={
                    props.type
                      ? "block"
                      : props.btnText !== "Enroll"
                      ? "none"
                      : "block"
                  }
                  disabled={props.link ? btnDisable : disable}
                  click={() => props.btnText === "Enroll" && handleEnroll()}
                >
                  {props.btnText}
                </Btn>
              )}
            </div>
          </a>
        ) : (
          <Btn
            className={styles.joinBtn}
            style={{ width: "100%" }}
            disabled
            variant="contained"
          >
            Completed
          </Btn>
        )}
        {props.dashboard ? (
          <>
            {/* <Link
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "10px",
                color: "#7A51AE",
                fontSize: "12px",
                pointerEvents: "auto",
              }}
              to="/patient/events"
            >
              View All
            </Link> */}
            {/* <Btn
              style={{ marginTop: "15px" }}
              click={() => {
                navigate("/patient/services");
              }}
              variant="contained"
            >
              View All
            </Btn> */}
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

EventCards.defaultProps = {
  btnText: "Enroll",
  start: "00:00:00",
  end: "00:00:00",
  name: "Default Event",
  date: "2022-01-29",
  link: null,
};
