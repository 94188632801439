import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";
export const updatePassword = async (data) => {
    return await axios
      .post("/admin/updatePassword", data)
      .then((res) => res)
      .catch((err) => err);
  };

  export const forgetPasswordAdmin = async (email) => {
    return await axios
      .get(`/users/forgot/password?email=${email}`)
      .then((res) => res)
      .catch((err) => err);
  };