import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import Typography from "@mui/material/Typography";

import { Input } from "../../../UI/Input";
import instance from "../../../../config/axiosConfig";
import { API } from "../../../../config/constant";

const QuickAddCategory = ({ todayaddCat }) => {
  const [categoryName, setCategoryName] = useState();
  const [question, setQuestion] = useState();

  const addpayload = {
    name: categoryName,
    num_of_questions: question,
  };
  const sucessNotify = (msg) => toast.success(msg);

  const errorNotify = (error) => toast.error(error);

  const APIQUICKANDTODAY = todayaddCat
    ? API.ADDCATEGORYTODAYACTIVTY
    : API.ADDCATEGORYQUICKASK;

  console.log("APIQUICKANDTODAY", APIQUICKANDTODAY);

  // useEffect(() => {
  //   try {
  //     const response = instance
  //       .post(APIQUICKANDTODAY, addpayload)
  //       .then((res) => {
  //         res.data.status === true && sucessNotify(res.data.msg);
  //       });
  //   } catch (error) {
  //     console.log(error, "error");
  //   }
  // }, []);

  const CategoryHandler = (e) => {
    e.preventDefault();
    setCategoryName(e.target.value);
  };

  const QuestionHandler = (e) => {
    e.preventDefault();
    setQuestion(e.target.value);
  };

  return (
    <>
      <div>
        <Typography variant="h5" gutterBottom>
          {todayaddCat ? "Today Activity Category" : "Quick Ask Category"}
        </Typography>
      </div>
      <div>
        <Input
          label="Category Name"
          type={"text"}
          name={"categoryname"}
          defaultValue={""}
          onChange={CategoryHandler}
        />
      </div>
      <div>
        <Input
          label="No. of Question"
          type={"text"}
          name={"question"}
          defaultValue={""}
          onChange={QuestionHandler}
        />
      </div>
      <Toaster />
    </>
  );
};

export default QuickAddCategory;
