export const NoEvents = () => {
  return (
    <div>
      <svg width="180" height="180" viewBox="0 0 180 180" fill="none">
        <path
          d="M167.124 152.399H12.8762C10.4169 152.397 8.05908 151.412 6.32009 149.663C4.5811 147.913 3.6029 145.541 3.6001 143.066V50.2657C3.6029 47.7912 4.5811 45.4189 6.32009 43.6692C8.05908 41.9194 10.4169 40.9352 12.8762 40.9324H167.124C169.583 40.9352 171.941 41.9194 173.68 43.6692C175.419 45.4189 176.397 47.7912 176.4 50.2657V143.066C176.397 145.541 175.419 147.913 173.68 149.663C171.941 151.412 169.583 152.397 167.124 152.399ZM12.8762 42.5324C10.8385 42.5347 8.88489 43.3503 7.44401 44.8C6.00314 46.2498 5.19262 48.2154 5.19028 50.2657V143.066C5.19262 145.116 6.00314 147.082 7.44401 148.532C8.88489 149.982 10.8385 150.797 12.8762 150.799H167.124C169.162 150.797 171.115 149.982 172.556 148.532C173.997 147.082 174.808 145.116 174.81 143.066V50.2657C174.808 48.2155 173.997 46.2498 172.556 44.8C171.115 43.3503 169.162 42.5347 167.124 42.5324H12.8762Z"
          fill="#7A51AE"
        />
        <path
          d="M33.5486 62.7992C32.2382 62.7992 30.9571 62.4082 29.8675 61.6757C28.7779 60.9431 27.9287 59.9019 27.4272 58.6837C26.9257 57.4656 26.7945 56.1251 27.0502 54.8319C27.3058 53.5387 27.9369 52.3508 28.8635 51.4184C29.7901 50.4861 30.9707 49.8512 32.256 49.5939C33.5413 49.3367 34.8735 49.4687 36.0842 49.9733C37.2949 50.4779 38.3297 51.3324 39.0578 52.4287C39.7858 53.525 40.1744 54.814 40.1744 56.1325C40.1724 57.9 39.4737 59.5946 38.2316 60.8444C36.9894 62.0942 35.3053 62.7972 33.5486 62.7992ZM33.5486 51.0658C32.5527 51.0658 31.5791 51.363 30.751 51.9197C29.9229 52.4764 29.2775 53.2678 28.8964 54.1936C28.5152 55.1194 28.4155 56.1381 28.6098 57.121C28.8041 58.1038 29.2837 59.0066 29.9879 59.7152C30.6922 60.4238 31.5894 60.9063 32.5662 61.1018C33.543 61.2973 34.5555 61.197 35.4757 60.8135C36.3958 60.43 37.1822 59.7806 37.7356 58.9474C38.2889 58.1142 38.5842 57.1346 38.5842 56.1325C38.5827 54.7892 38.0517 53.5014 37.1076 52.5515C36.1636 51.6016 34.8837 51.0673 33.5486 51.0658Z"
          fill="#7A51AE"
        />
        <path
          d="M145.391 62.7992C144.081 62.7992 142.8 62.4082 141.71 61.6757C140.621 60.9431 139.771 59.9019 139.27 58.6837C138.768 57.4656 138.637 56.1251 138.893 54.8319C139.149 53.5387 139.78 52.3508 140.706 51.4184C141.633 50.4861 142.813 49.8512 144.099 49.5939C145.384 49.3367 146.716 49.4687 147.927 49.9733C149.138 50.4779 150.172 51.3324 150.901 52.4287C151.629 53.525 152.017 54.814 152.017 56.1325C152.015 57.9 151.316 59.5946 150.074 60.8444C148.832 62.0942 147.148 62.7972 145.391 62.7992ZM145.391 51.0658C144.395 51.0658 143.422 51.363 142.594 51.9197C141.766 52.4764 141.12 53.2678 140.739 54.1936C140.358 55.1194 140.258 56.1381 140.453 57.121C140.647 58.1038 141.126 59.0066 141.831 59.7152C142.535 60.4238 143.432 60.9063 144.409 61.1018C145.386 61.2973 146.398 61.197 147.318 60.8135C148.239 60.43 149.025 59.7806 149.578 58.9474C150.132 58.1142 150.427 57.1346 150.427 56.1325C150.425 54.7892 149.894 53.5014 148.95 52.5515C148.006 51.6016 146.726 51.0673 145.391 51.0658Z"
          fill="#7A51AE"
        />
        <path
          opacity="0.2"
          d="M95.8156 100.669H78.8835C78.1769 100.668 77.4995 100.356 76.9998 99.8011C76.5002 99.2461 76.2191 98.4936 76.2183 97.7087V86.5631C76.2191 85.7782 76.5002 85.0257 76.9998 84.4707C77.4995 83.9157 78.1769 83.6035 78.8835 83.6025H95.8156C96.5222 83.6035 97.1996 83.9157 97.6993 84.4707C98.1989 85.0257 98.48 85.7782 98.4808 86.5631V97.7087C98.48 98.4936 98.1989 99.2461 97.6993 99.8011C97.1996 100.356 96.5222 100.668 95.8156 100.669Z"
          fill="#E69A8D"
        />
        <g opacity="0.4">
          <path
            d="M40.1741 80.1321L47.1351 94.3215L62.7017 96.6108L51.4379 107.65L54.0962 123.244L40.1741 115.878L26.252 123.244L28.9103 107.65L17.6465 96.6108L33.2131 94.3215L40.1741 80.1321Z"
            fill="url(#paint0_linear_179_12202)"
            stroke="url(#paint1_linear_179_12202)"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          opacity="0.2"
          d="M128.15 100.669H111.217C110.511 100.668 109.833 100.356 109.334 99.8011C108.834 99.2461 108.553 98.4936 108.552 97.7087V86.5631C108.553 85.7782 108.834 85.0257 109.334 84.4707C109.833 83.9157 110.511 83.6035 111.217 83.6025H128.15C128.856 83.6035 129.534 83.9157 130.033 84.4707C130.533 85.0257 130.814 85.7782 130.815 86.5631V97.7087C130.814 98.4936 130.533 99.2461 130.033 99.8011C129.534 100.356 128.856 100.668 128.15 100.669Z"
          fill="#E69A8D"
        />
        <path
          opacity="0.2"
          d="M143.721 84.1323C143.11 84.1331 142.524 84.4023 142.092 84.881C141.66 85.3596 141.417 86.0086 141.416 86.6855V97.5792C141.417 98.2561 141.66 98.9051 142.092 99.3838C142.524 99.8624 143.11 100.132 143.721 100.132H160.314C160.925 100.132 161.511 99.8624 161.943 99.3838C162.375 98.9051 162.618 98.2561 162.618 97.5792V86.6855C162.618 86.0086 162.375 85.3596 161.943 84.881C161.511 84.4023 160.925 84.1331 160.314 84.1323H143.721Z"
          fill="#E69A8D"
        />
        <path
          opacity="0.2"
          d="M95.8156 122.535H78.8835C78.1769 122.534 77.4995 122.222 76.9998 121.667C76.5002 121.112 76.2191 120.36 76.2183 119.575V108.429C76.2191 107.644 76.5002 106.892 76.9998 106.337C77.4995 105.782 78.1769 105.469 78.8835 105.469H95.8156C96.5222 105.469 97.1996 105.782 97.6993 106.337C98.1989 106.892 98.48 107.644 98.4808 108.429V119.575C98.48 120.36 98.1989 121.112 97.6993 121.667C97.1996 122.222 96.5222 122.534 95.8156 122.535Z"
          fill="#E69A8D"
        />
        <path
          opacity="0.2"
          d="M128.15 122.535H111.217C110.511 122.534 109.833 122.222 109.334 121.667C108.834 121.112 108.553 120.36 108.552 119.575V108.429C108.553 107.644 108.834 106.892 109.334 106.337C109.833 105.782 110.511 105.469 111.217 105.469H128.15C128.856 105.469 129.534 105.782 130.033 106.337C130.533 106.892 130.814 107.644 130.815 108.429V119.575C130.814 120.36 130.533 121.112 130.033 121.667C129.534 122.222 128.856 122.534 128.15 122.535Z"
          fill="#E69A8D"
        />
        <path
          opacity="0.2"
          d="M160.483 122.535H143.551C142.844 122.534 142.167 122.222 141.667 121.667C141.168 121.112 140.887 120.36 140.886 119.575V108.429C140.887 107.644 141.168 106.892 141.667 106.337C142.167 105.782 142.844 105.469 143.551 105.469H160.483C161.19 105.469 161.867 105.782 162.367 106.337C162.866 106.892 163.147 107.644 163.148 108.429V119.575C163.147 120.36 162.866 121.112 162.367 121.667C161.867 122.222 161.19 122.534 160.483 122.535Z"
          fill="#E69A8D"
        />
        <path
          d="M33.5486 58C33.0567 57.9994 32.5852 57.8025 32.2374 57.4526C31.8896 57.1026 31.694 56.6282 31.6934 56.1333V29.4665C31.6934 28.9715 31.8888 28.4967 32.2367 28.1466C32.5847 27.7965 33.0565 27.5999 33.5486 27.5999C34.0406 27.5999 34.5125 27.7965 34.8604 28.1466C35.2083 28.4967 35.4038 28.9715 35.4038 29.4665V56.1333C35.4032 56.6282 35.2075 57.1026 34.8598 57.4526C34.512 57.8025 34.0404 57.9994 33.5486 58Z"
          fill="#7A51AE"
        />
        <path
          d="M145.391 58C144.899 57.9994 144.428 57.8025 144.08 57.4526C143.732 57.1026 143.537 56.6282 143.536 56.1333V29.4665C143.536 28.9715 143.732 28.4967 144.08 28.1466C144.427 27.7965 144.899 27.5999 145.391 27.5999C145.883 27.5999 146.355 27.7965 146.703 28.1466C147.051 28.4967 147.247 28.9715 147.247 29.4665V56.1333C147.246 56.6282 147.05 57.1026 146.703 57.4526C146.355 57.8025 145.883 57.9994 145.391 58Z"
          fill="#7A51AE"
        />
        <defs>
          <linearGradient
            id="paint0_linear_179_12202"
            x1="40.1741"
            y1="80.1321"
            x2="40.1741"
            y2="123.244"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7A51AE" />
            <stop offset="1" stopColor="#7A51AE" stopOpacity="0.21" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_179_12202"
            x1="40.1741"
            y1="80.1321"
            x2="40.1741"
            y2="123.244"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7A51AE" />
            <stop offset="1" stopColor="#7A51AE" stopOpacity="0.21" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};
