import React from "react";
import SimpleBreadcrum from "../../../Component/UI/SimpleBreadcrum";
import { Form } from "./Form";
import { Toaster } from "../../../../node_modules/react-hot-toast/dist/index";

export const UpdateSubscription = () => {
  const links = [
    {
      path: "/subscriptions",
      linkText: "Subscriptions",
    },
  ];
  return (
    <>
      <Toaster />
      <div>
        <SimpleBreadcrum text="Edit Subscription" link={links} />
        <Form />
      </div>
    </>
  );
};
