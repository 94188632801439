import { createSlice } from "@reduxjs/toolkit";

const enrolledEventsSlice = createSlice({
  name: "enrolled events",
  initialState: { enrolled: [] },
  reducers: {
    getEnrolledEvents: (state, action) => {
      state.enrolled = action.payload;
    },
  },
});

export const { getEnrolledEvents } = enrolledEventsSlice.actions;

export default enrolledEventsSlice.reducer;
