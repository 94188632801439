import axios from "../../config/axiosConfig";

export const getReports = async (id, date, type) => {
  return await axios
    .get(
      `/admin/report?assessment_id=${id}${
        date
          ? `&fromdate=${date?.startDate}&todate=${date?.endDate}`
          : `&type=assessment`
      }`
    )
    .then((res) => {
      return res.data.list;
    })
    .catch((err) => console.log(err));
};

export const getSubscriptionReports = async (id) => {
  return await axios
    .post(`/admin/enterprise/servicereport?id=${id}`)
    .then((res) => {
      return res.data.list;
    })
    .catch((err) => console.log(err));
};

export const getReportAssessment = async (date) => {
  return await axios
    .get(
      `/admin/reportAssessment?fromdate=${date?.startDate}&todate=${date?.endDate}`
    )
    .then((res) => {
      return res.data.list;
    })
    .catch((err) => console.log(err));
};

export const assessmentList = async () => {
  return await axios
    .get(`/admin/assessment/category`)
    .then((res) => {
      return res;
    })
    .catch((err) => console.log(err));
};

export const assessmentListReport = async () => {
  return await axios
    .get(`/admin/assessment/categoryReport`)
    .then((res) => {
      return res;
    })
    .catch((err) => console.log(err));
};

export const getEnterprisePdfReport = async (data) => {
  return await axios
    .post("/admin/enterprise/report", data)
    .then((res) => res)
    .catch((err) => console.log(err));
};
