import { createSlice } from "@reduxjs/toolkit";

const patientEventsSlice = createSlice({
  name: "patient events",
  initialState: { all: [], upcoming: [], completed: [], message: "" },
  reducers: {
    upcomingEvents: (state, action) => {
      state.upcoming = action.payload;
    },

    completedEvents: (state, action) => {
      state.completed = action.payload;
    },

    allEvents: (state, action) => {
      state.all = action.payload;
    },

    enrollMessage: (state, action) => {
      state.message = action.payload;
    },
  },
});

export const { upcomingEvents, completedEvents, allEvents, enrollMessage } =
  patientEventsSlice.actions;

export default patientEventsSlice.reducer;
