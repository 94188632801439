import React from "react";
import { AssessmentCard } from "../../Components/Assessments/AssessmentCard";
import styles from "./assessment.module.css";
import Popup from "./LinkPopup";
import { DementiaScreening } from "../../Components/Images/DementiaScreening";
import { useNavigate } from "react-router-dom";
import LinkIcon from "../../../assets/icons/LinkIcon";
import { useEffect } from "react";
import axios from "../../../config/axiosConfig";
import { useState } from "react";
import { formatttedDate } from "../../../config/formattedDate";
import { sendEnterpriseLink } from "../../../services/enterprise/assessment";
import { useAuth } from "../../../Auth/AuthProvider";
import { Empty } from "../../Components/UI/Empty";
import { useDispatch, useSelector } from "react-redux";
import moment from "../../../../node_modules/moment/moment";
import {
  setDate,
  setToggle,
  setYear,
} from "../../../Redux/Slices/Patient/PatienCalender";

import { AssessmentCard2 } from "../../Components/Assessments/AssessmentCard2";

export const ActiveAssessments = () => {
  const [popupAction, setPopupAction] = React.useState({
    open: false,
    name: "",
  });

  const [activeData, setActiveData] = useState([]);
  const [disable, setDisable] = useState(false);
  const [disableId, setDisableId] = useState(null);

  useEffect(() => {
    // axios.post("/enterprise/getAssessments", { status: 1 }).then((res) => {
    //   console.log(res.data.list);
    // });

    axios.post("/enterprise/getAssessmentsByService").then((res) => {
      let FinalList = res.data.list?.filter((item) => {
        return item.expiryService === false;
      });
      setActiveData(FinalList);
    });
  }, []);

  const { selectedDate } = useSelector((i) => i.DateFilterSlice);

  const FinalArr = activeData || [];

  const searchResult = FinalArr.filter((event) => {
    return selectedDate?.includes("All")
      ? event.date_of_creation.includes(selectedDate.split(" ")?.[1])
      : event.date_of_creation.includes(moment(selectedDate).format("YYYY-MM"));
  });

  // const assessment_link = "sd";
  const ctx = useAuth();
  const company_slug = ctx?.loginUser.enterprise.company_slug;
  const domain = window.location.host;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setDate(`All ${new Date().getFullYear()}`));
      dispatch(setToggle(false));
      dispatch(setYear(new Date().getFullYear()));
    };
  }, []);

  return (
    <>
      <Popup
        popupAction={popupAction}
        setPopupAction={setPopupAction}
        icon={<LinkIcon />}
        children={
          <p className={styles.linktext}>
            Link has been sent to your email id.
          </p>
        }
      />

      <div
        className={styles.cards}
        style={
          searchResult?.length
            ? {}
            : { justifyContent: "center", translate: "0 50px" }
        }
      >
        {searchResult?.length ? (
          searchResult?.map((assessment) => {
            const date = formatttedDate(assessment?.date_of_creation);
            const AssessmentImage = () => (
              <img
                src={assessment?.assessment_image}
                alt=""
                width="100px"
                height="100px"
                style={{ objectFit: "contain" }}
              />
            );
            return (
              <div className={styles.container}>
                <AssessmentCard2
                  title={assessment?.name}
                  image={<AssessmentImage />}
                  numberofques={`${assessment?.no_of_question} Questions`}
                  date={date}
                  height="95%"
                  handleBtn={() => {
                    setDisable(true);
                    setDisableId(assessment.id);
                    sendEnterpriseLink({
                      link: `http://${domain}/enterprise/${company_slug}/assessment`,
                      assissment_id: assessment?.id,
                    })
                      .then((res) => {
                        if (res.data.status) {
                          setPopupAction({
                            ...popupAction,
                            open: true,
                          });
                        }
                      })
                      .catch((err) => console.log(err))
                      .finally(() => {
                        setDisable(false);
                      });
                  }}
                  btntext="Get Link"
                  detailsButton="Details"
                  handleDetailsBtn={() => {
                    navigate("/enterprise/assessments/details", {
                      state: {
                        id: assessment?.id,
                        assessmentData: assessment,
                      },
                    });
                  }}
                  disable={disable}
                  disableId={disableId}
                  assessmentId={assessment.id}
                />
              </div>
            );
          })
        ) : (
          <Empty title="There are no assessment at this time." />
        )}
      </div>
    </>
  );
};
