import React from "react";
import { useState, useEffect } from "react";
import {
  assessmentList,
  assessmentListReport,
} from "../../../services/admin/AssessmentReport";

import styles from "./AssessmentReport.module.css";
import DownloadReportCard from "../../UI/DownloadReportCard";
import { Toaster } from "../../../../node_modules/react-hot-toast/dist/index";
import DownloadReportCardAll from "../../UI/DownloadReportCardS";
import { Calender } from "../../../Component/UI/Calender";
const AssessmentReport = () => {
  const [assessments, setAssessments] = useState([]);
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });

  console.log(date, "date--");
  useEffect(() => {
    assessmentListReport().then((res) => {
      let finalData = res.data.list.filter((item) => {
        return item.created_for === 0;
      });
      setAssessments(res.data.list);
    });
  }, []);

  const assessmentData = {
    name: "Client Assessment Details",
  };
  console.log(assessments, "assessments---");

  return (
    <>
      <Toaster />
      <div className={styles.AdminReport}>
        <div className={styles.reportHeader}>
          <h3>Download Assessment Report</h3>
          <Calender setDateGap={setDate} init />
        </div>

        <div className={styles.ReportCard_container}>
          <DownloadReportCardAll assessmentData={assessmentData} date={date} />
          {assessments.map((assessment) => {
            return (
              <>
                <DownloadReportCard assessmentData={assessment} date={date} />
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default AssessmentReport;
