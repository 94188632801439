import React, { useContext } from "react";

import SidebarLink from "./SidebarLink";

import classes from "../../assets/css/sidebar.module.css";
import sidebarTitleData from "../../MockData/sidebar.json";
import ClientSidebarData from "../../MockData/ClientSidebar.json";
import { ReactComponent as Logo } from "../../assets/icons/icon/logo.svg";
import { useLocation } from "react-router-dom";
import AuthContext from "../../Auth/AuthContext";
import { useState } from "react";
import { EnterpriseSidebarLinks } from "./SidebarLinks/EnterpriseSidebar";
import samvednaLogo from "../../assets/icons/icon/logo.svg";
import { findFunc } from "../../config/methods";
import { useSelector } from "react-redux";
import PermissionData from "../../Samvenda-Admin/DashboardPages/SamvednaNew/Roles and Permissions/Data.json";

const SideBar = ({ setDrawer, setTitle }) => {
  const location = useLocation();
  const ctx = useContext(AuthContext);
  const role = ctx?.loginUser?.role;
  const [selected, setSelected] = useState(location.pathname.slice(1));
  const enterpriseLogo = ctx?.loginUser?.enterprise?.lead?.img_url;
  // const permissionStringified = ctx?.loginUser.admin.role_name?.[0]?.permission;
  const { data } = useSelector((state) => state.rolesAndPermissions);
  const rolesAndPerms = data?.access_role?.permission ?? "[]";
  const ifRoleNameBlank = JSON.parse(rolesAndPerms);
  const permissionData = ifRoleNameBlank;
  return (
    <>
      <div className={role === "Admin!$23" ? classes.sidebarResponsive : ""}>
        {role === "Admin!$23" &&
          sidebarTitleData.map((res) => {
            const checkText = findFunc(
              permissionData?.categories,
              "name",
              res?.text
            );
            return (
              <>
                {checkText?.status || checkText === undefined ? (
                  <SidebarLink
                    setDrawer={setDrawer}
                    setTitle={setTitle}
                    key={res.id}
                    text={res.text}
                    Icon={res.icon}
                    id={res.id}
                    disableId={"a" + res.id}
                    activeRole={true}
                    routesname={res.link}
                    role="Admin!$23"
                  />
                ) : null}
              </>
            );
          })}
        {role === "client" && (
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Logo className={classes.logoimg} />
            </div>
            {ClientSidebarData.map((res) => (
              <SidebarLink
                setDrawer={setDrawer}
                key={res.id}
                text={res.text}
                disableId={"p" + res.id}
                routesname={res.link}
                Icon={res.icon}
                id={res.id}
                selected={selected}
                setSelected={setSelected}
                role="client"
              />
            ))}
          </div>
        )}
        {role === "enterprise!$23" && (
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={enterpriseLogo || samvednaLogo}
                alt="Enterprise logo"
                className={classes.enterpriseLogo}
              />
            </div>
            {EnterpriseSidebarLinks().map((res) => (
              <SidebarLink
                setDrawer={setDrawer}
                key={res.id}
                text={res.text}
                disableId={"e" + res.id}
                routesname={res.link}
                Icon={res.icon}
                id={res.id}
                logout={res.logout}
                role="enterprise!$23"
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default SideBar;
