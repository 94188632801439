import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { TabView } from "../../UI/TabView";
import styles from "./doctor.module.css";
import { useEffect } from "react";
import { getDoctor } from "../../../services/patient/doctor";
import { All } from "./All";
import { DoctorsTab } from "./DoctorsTab";
import { Counselors } from "./Counselors";

export const KnowYourExport = () => {
  const [doctorList, setDoctorList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const items = [
    // {
    //   label: "All",
    //   component: <All />,
    // },
    {
      label: "Doctors",
      component: <DoctorsTab />,
    },
    {
      label: "Counsellors",
      component: <Counselors />,
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    getDoctor().then((res) => {
      setIsLoading(false);
      setDoctorList(res?.data?.list);
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Know our Experts Doctors - Samvedna Map</title>
        <link
          rel="canonical"
          href="https://map.samvednacare.com/patient/doctors"
        />
        <meta name="title" content="Know our Experts Doctors - Samvedna Map" />
        <meta
          name="description"
          content="Find here the list of experts and specialisats like doctors, psychologists and counsellors. Book an appointment today and consult with our expert doctors."
        />
        <meta name="robots" content="index,follow" />
      </Helmet>
      <div className={styles.mainDiv}>
        <TabView calendar values={items} />
      </div>
    </>
  );
};
