import React from "react";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import offering from "../../Components/Images/offering.png";
import styles from "../landing.module.css";

export const Offering = () => {
  return (
    <div className={styles.offering}>
      <img src={offering} alt="" />
      <div>
        <h2 className={styles.offerings_heading}>
          Our Offerings for Your Employees
        </h2>
        <div className={styles.list}>
          <div>
            <span></span>
            <p>Self-Care Programs</p>
          </div>
          <div>
            <span></span>
            <p>Wellness Coaching Program</p>
          </div>
          <div>
            <span></span>
            <p>Thematic group workshops</p>
          </div>
          <div>
            <span></span>
            <p>Counselling Helpline/ Chat Services</p>
          </div>
          <div>
            <span></span>
            <p>Custom Podcast Solutions</p>
          </div>
          <div>
            <span></span>
            <p>Custom assessments for mental well-being</p>
          </div>
        </div>
        <div className={styles.btn}>
          <Btn
            bgColor="#3592FF"
            color="#fff"
            fontFamily="Urbanist"
            className={styles.button}
            click={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            Request a Call-back
          </Btn>
        </div>
      </div>
    </div>
  );
};
