import axios from "../../config/axiosConfig";

export const getRolesAndPermissions = async (id) => {
  return await axios
    .get(`/rolePermission?id=${id}`)
    .then((res) => {
      return res.data.list;
    })
    .catch((err) => console.log(err));
};

export const saveRolesAndPermissions = async (data) => {
  return await axios
    //admin/available/staff
    .post("/admin/updatePermission", data)
    .then((res) => res)
    .catch((err) => err);
};
