import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const PaymentSlices = createSlice({
  name: "PaymentSlices",
  initialState: {
    paymentData: [],
  },
  reducers: {
    setPaymentData: (state, { payload }) => {
      state.paymentData = payload;
    },
  },
});

export const { setPaymentData } = PaymentSlices.actions;

export default PaymentSlices.reducer;
