import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import Typography from "@mui/material/Typography";

import { Input } from "../../../UI/Input";
import instance from "../../../../config/axiosConfig";
import { API } from "../../../../config/constant";
import QuickAddCategory from "./QuickAddCategory";

const TodayActivityAddCategory = () => {
  return <QuickAddCategory todayaddCat />;
};

export default TodayActivityAddCategory;
