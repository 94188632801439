import React from "react";
import { Routes, Route } from "react-router-dom";

import { PatientSignup } from "../Samvenda-Patient/Auth/Signup";
import { PatientLogin } from "../Samvenda-Patient/Auth/PatientLogin";
import ForgotPassword from "../Samvenda-Patient/Auth/ForgotPassword";

import { AssessmentNew } from "../Samvenda-Patient/AssessmentNew";
import { LoginContainer } from "../Samvenda-Admin/Login/LoginContainer";
import { PatientLandingPage } from "../Samvenda-Patient/PatientLandingPage/PatientLandingPage";
import LayoutNew from "../Component/Layout/Layoutnew";
import ResetPassword from "../Samvenda-Patient/Auth/ResetPassword";
import { useAuth } from "../Auth/AuthProvider";
//TODO:*******SAMV-ADMIN******

// NotficationManagement
import AdminRoutes from "./AdminRoutes";
import ClientRoutes from "./ClientRoutes";
import EnterpriseRoutes from "./EnterpriseRoutes";
import { NotificationManagement } from "../Samvenda-Admin/DashboardPages/SamvednaNew/Notification/NotificationManagement";
import { Email } from "../Samvenda-Admin/DashboardPages/SamvednaNew/Notification/Email/Email";
import Unauthorised from "../Component/Common/Unauthorised";
import { EnterpriseLandingPage } from "../Samvedna-Enterprise/PreLogin/LandingPage.jsx";
import { LoginEnterprise } from "../Samvedna-Enterprise/Auth/Login";
import { ForgotPasswordEnterprise } from "../Samvedna-Enterprise/Auth/ForgotPassword";
import AssessmentEnterprise from "../Samvedna-Enterprise/PreLogin/Assessment/index";
import { VerifyEmployee } from "../Samvedna-Enterprise/Auth/VerifyEmployee";
import LandingPageNew from "../Samvedna-Enterprise/PreLogin/LandingPageNew";
import { MapLanding } from "../Samvedna-Enterprise/PreLogin/MapLanding/MapLanding";
import { PrevLandingUrl } from "../Samvedna-Enterprise/PreLogin/MapLanding/PrevLandingUrl";
import { PaymentThanks } from "../Samvenda-Patient/PreLogin/PaymentThanks";
import ForgotPasswordAdmin from "../Samvenda-Admin/Login/ForgotPassword";
import { Enroll } from "../Samvedna-Enterprise/DashboardPages/Account/Enroll";
import { ThankYou } from "../Samvedna-Enterprise/DashboardPages/Account/ThankYou";

const patient = "patient";
const admin = "smv-admin";

export const RouteComponent = () => {
  const ROLES = {
    Client: "client",
    Admin: "Admin!$23",
  };

  const Auth = useAuth();

  return (
    <Routes>
      <Route path={`user/signin`} element={<PatientLogin />} />
      <Route path={`user/signup`} element={<PatientSignup />} />
      <Route path={`/thanks/success`} element={<PaymentThanks />} />
      <Route path="/self-assessment" element={<AssessmentNew />} />
      <Route path={`user/Forgot`} element={<ForgotPassword />} />
      <Route path={`user/reset-password`} element={<ResetPassword />} />
      {/* <Route path={"/"} element={<PatientLandingPage />} /> */}
      <Route path={`${admin}/signin`} element={<LoginContainer />} />
      <Route
        path={`${admin}/forgotPassword`}
        element={<ForgotPasswordAdmin />}
      />
      <Route
        path={"enterprise/mental-health-in-the-workplace"}
        element={<LandingPageNew />}
      />
      <Route path={"enterprise/landing"} element={<PrevLandingUrl />} />

      <Route path={"/"} element={<MapLanding />} />
      {/* <Route path={"enterprise/MapLanding"} element={<MapLanding />} /> */}
      <Route path={"enterprise/:orgId/login"} element={<LoginEnterprise />} />
      <Route path={"enterprise/login"} element={<LoginEnterprise />} />
      <Route
        path={"enterprise/:orgId/forgot"}
        element={<ForgotPasswordEnterprise />}
      />
      <Route
        path={"enterprise/:orgId/assessment/:assessment_id/"}
        element={<AssessmentEnterprise />}
      />
      <Route path={"enterprise/enroll/:orgId"} element={<Enroll />} />
      <Route
        path={"enterprise/enroll/:orgId/thankyou"}
        element={<ThankYou />}
      />
      <Route
        path={"enterprise/verifyemployee/:employeeId"}
        element={<VerifyEmployee />}
      />

      {Auth?.loginUser?.role === "Admin!$23" ? (
        <Route path="*" element={<AdminRoutes />} />
      ) : null}

      {Auth?.loginUser?.role === "client" ? (
        <Route path="*" element={<ClientRoutes />} />
      ) : null}

      {Auth?.loginUser?.role === "enterprise!$23" ? (
        <Route path="*" element={<EnterpriseRoutes />} />
      ) : null}

      <Route path="*" element={<Unauthorised />} />
    </Routes>
  );
};
