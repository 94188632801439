import React, { useState } from "react";
import { SelectSelf } from "../../../../Component/UI/CustomInputs";
import AllTabs from "../../../../Component/UI/Tabs/AllTabs";
import LabelSelfCare from "./LabelSelfCare";
import { useLocation } from "react-router-dom";
import BasicBreadcrumbs from "../../../../Component/UI/BreadCrumb/BreadCrumbs";
import PaperWrapper from "../../../../Component/UI/PaperWrapper/PaperWrapper";
import Typography from "@mui/material/Typography";
import { TEXT } from "../../../contant/constant";

//Links
const link = [{ path: "/Assessments", linkText: "Assessments" }];

//SelectOption
const options = [
  { value: "1", text: "Dementia Screening" },
  { value: "2", text: "Mental WellBeing" },
  { value: "3", text: "Caregiver Burden" },
];

//TabsName
const btnApi = {
  AddSelfCareTips: [
    { "Level 1": "Level 1", "Level 2": "Level 2", "Level 3": "Level 3" },
  ],
};

const AddSelfCareTips = () => {
  const location = useLocation();
  const { id, value, from } = location?.state;
  console.log(id, "----------------", value);

  const topSidebtn = [{ btnName: "Reset" }, { btnName: "Add" }];

  const [selected, setSelected] = useState();

  //Select
  const handleChange = (event) => {
    console.log(event.target.value, "e.target.value");
    setSelected(event.target.value);
  };

  //Level==>1==>2==>3
  const levelOne = (
    <LabelSelfCare
      //apiData={selfCare[selected]?.selfcare}
      flag={value}
      selected={selected}
      qid={id}
      level={"1"}
    />
  );

  const leveltwo = (
    <LabelSelfCare
      //apiData={selfCare[selected]?.selfcare}
      flag={value}
      selected={selected}
      qid={id}
      level={"2"}
    />
  );

  const levelthree = (
    <LabelSelfCare
      //apiData={selfCare[selected]?.selfcare}
      flag={value}
      selected={selected}
      qid={id}
      level={"3"}
    />
  );

  const ComponentName = [levelOne, leveltwo, levelthree];

  return (
    <>
      <BasicBreadcrumbs
        link={link}
        text={`${from === "edit" ? "Edit" : "Add"} Self Care Tips`}
      >
        {/* <PaperWrapper> */}
        <div style={{ margin: "15px", padding: "10px" }}>
          {value === TEXT.FLAG ? (
            <SelectSelf
              label="Select Assessment"
              options={options}
              onChange={handleChange}
              clickedData={selected}
              labelSize={"20px"}
            />
          ) : (
            <Typography variant="h4" gutterBottom>
              {value}
            </Typography>
          )}
        </div>
        <AllTabs
          ComponentName={ComponentName}
          btnApi={btnApi.AddSelfCareTips}
          tag={true}
          btnNamemap={topSidebtn}
          btnAvialble={true}
          // onClick={addBtn}
        ></AllTabs>
        {/* </PaperWrapper> */}
      </BasicBreadcrumbs>
    </>
  );
};

export default AddSelfCareTips;
