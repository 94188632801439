import React from "react";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import about from "../../Components/Images/about4.png";
import styles from "../landing.module.css";

export const About = () => {
  return (
    <div className={styles.about}>
      <img src={about} alt="" />
      <div>
        <h2 className={styles.aboutHeading}>About Samvedna Care</h2>
        {/* <p className={styles.topPara}> */}

        <p className={styles.description}>
          Samvedna is a pioneer in technology-enabled mental health and
          well-being services, providing expert-led counselling and guidance to
          adults across the globe. Our mission is to empower individuals to take
          charge of their mental health and lead a life of well-being and
          balance. By leveraging the latest technological advances, we offer
          comprehensive services designed to promote mental resilience and
          improve psychological health outcomes. From depression and anxiety to
          post-traumatic stress disorder and more, Samvedna Care is here to
          provide you with the support you need.
        </p>
        {/* <div className={styles.card}>
          <p className={styles.cardHeading}>100K</p>
          <p>Hours of Mental Health Care Services</p>
        </div> */}
        <p className={styles.topPara}>
          Invest in your team's mental health with our corporate therapy
          services and see the positive impact on your business's bottom line.
        </p>
        <p className={styles.topPara}>
          To discover how we can help you thrive, <a href="mailto:contact@samvednacare.com">contact@samvednacare.com</a> or
          call us at <a href="tel:+91 74280 97301">+91 74280 97301</a> or <a href="tel:+91 124 4229659">+91 124 4229659</a>.
        </p>
        <div className={styles.btn}>
          <Btn
            variant="contained"
            fontFamily="Urbanist"
            bgColor="#3592FF"
            className={styles.button}
            click={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            Request a Call-back
          </Btn>
        </div>
      </div>
    </div>
  );
};
