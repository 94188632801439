import * as React from "react";
import classes from "./TimerForEditEvent.module.css";

export default function TimerForEditEvent({
  labelName,
  onChange,
  defaultValue,
}) {
  return (
    <>
      <label>{labelName}</label>
      <div className={classes.outerTimePicker}>
        <input
          type="time"
          name="start_time"
          defaultValue={defaultValue}
          onChange={onChange}
          className={classes.timePicker}
        ></input>
      </div>
    </>
  );
}
