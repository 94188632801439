import { useState } from "react";
import { Accordion } from "./Accordion";
import { AccordionSummary } from "./AccordionSummary";
import { AccordionDetails } from "./AccordionDetails";
import Typography from "@mui/material/Typography";

export const CustomizedAccordions = ({ heading, content }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        defaultExpanded={true}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>{heading}</Typography>
        </AccordionSummary>
        <AccordionDetails>{content}</AccordionDetails>
      </Accordion>
    </>
  );
};
