import React from "react";
import { TabViewEnterprise } from "../../Components/UI/TabViewEnterprise/TabViewEnterprise";
import { ConnectWithUs } from "./ConnectWithUs";
import { Faq } from "./Faq";

export const HelpSupport = () => {
  return (
    <>
      <TabViewEnterprise
        values={[
          { label: "Connect with us", component: <ConnectWithUs /> },
          { label: "Frequently Asked Questions", component: <Faq /> },
        ]}
      />
    </>
  );
};
