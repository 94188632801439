import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const { GETHEALTH, UPDATEHEALTH } = API;

export const getHealth = async () => {
  return await axios
    .get(GETHEALTH)
    .then((res) => res)
    .catch((err) => err);
};

export const editHealth = async (data) => {
  return await axios
    .post(UPDATEHEALTH, data)
    .then((res) => res)
    .catch((err) => err);
};
