import React from 'react'
import { Helmet } from 'react-helmet'
import PrintComponent, { FunctionalComponent } from './Print'
import { ProgressTrends } from './ProgressTrends'

function ProgressTrendsIndex() {
  return (
    <>
     <Helmet>
        <meta charSet="utf-8" />
        <title>Patient Progress Trends - Samvedna Map</title>
        <link rel="canonical" href="https://map.samvednacare.com/patient/progresstrends" />
        <meta name="title" content="Patient Progress Trends - Samvedna Map" />
        <meta
          name="description"
          content="Samvedna MAP provides you a progress trend dashboard based on the activities completed. Check your daily acitivity and mood tracker here."
        />
        <meta name="robots" content="index,follow" />
      </Helmet>
    <PrintComponent />
    </>
  )
}

export default ProgressTrendsIndex