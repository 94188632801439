import React from 'react'
import BasicBreadcrumbs from '../../../Component/UI/BreadCrumb/BreadCrumbs'
import TextWithCard from '../../../Hooks/TextWithCard'
import PatientsProfileData from "../../../MockData/PatientProfileMock/PatientProfileMock.json"

const NotficationManagement = () => {
  const NotificationData = {
    "General":[
        {
            "id":1,
            "label" : "Email"
        },
        {   
            "id":2,
            "label" : "SMS"
        },
        {   
            "id":3,
            "label" : "Notification"
        }
    
    ]
  };

  return (
    <>
      <BasicBreadcrumbs link={"Samvedna > Notification Management"} text={"Notification Management"}>
      <TextWithCard  cardData={NotificationData.General} />
      </BasicBreadcrumbs>
    </>
  )
}

export default NotficationManagement

