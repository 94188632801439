import { createSlice } from "@reduxjs/toolkit";

const assessmentQuesSlice = createSlice({
  name: "assessment ques",
  initialState: { quest: [] },
  reducers: {
    getAssessmentQues: (state, action) => {
      state.quest = action.payload;
    },
  },
});

export const { getAssessmentQues } = assessmentQuesSlice.actions;

export default assessmentQuesSlice.reducer;
