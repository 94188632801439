import React, { useEffect } from "react";
import styles from "./rosterManagement.module.css";
import stylesTime from "./Components/checkBox.module.css";
import { weekDays } from "./rosterFun";
import { TimeSlot } from "./Components/TimeSlot";
import { useSelector } from "react-redux";
import moment from "../../../../../node_modules/moment/moment";
export const StaffDataSec = ({ tHead, timeSlotsBooked, staffTimeSlotdata }) => {
  const timeSlotdata = [
    {
      id: "09",
      value: "09:00-10:00",
    },
    {
      id: "10",
      value: "10:00-11:00",
    },
    {
      id: "11",
      value: "11:00-12:00",
    },
    {
      id: "12",
      value: "12:00-01:00",
    },
    {
      id: "13",
      value: "01:00-02:00",
    },
    {
      id: "14",
      value: "02:00-03:00",
    },
    {
      id: "15",
      value: "03:00-04:00",
    },
    {
      id: "16",
      value: "04:00-05:00",
    },
    {
      id: "17",
      value: "05:00-06:00",
    },
    {
      id: "18",
      value: "06:00-07:00",
    },
  ];

  //set Booked date in Header Color green

  const timeSlotsBookeddate = [...new Set(timeSlotsBooked?.map((i) => i.date))];

  const weekArr = tHead?.filter((k) =>
    timeSlotsBookeddate.includes(k?.date?.toString().padStart(2, 0))
  );

  const bookedDataObj = {};
  for (const k of weekArr) {
    bookedDataObj[k?.date] = true;
  }


  const {selectWeek } = useSelector((i) => i.WeekFilterSlice);

  useEffect(() => {
    for(let i=0; i<tHead?.length; i++ ){
      document.getElementById('styleId'+i)?.scrollTo(0, 0)
    }
    
  },[selectWeek])

  const isLeave = staffTimeSlotdata?.leave
  ?.filter((i) => i?.is_leave)
  const is_leave_Date = isLeave?.map((k) => moment(k?.schedule_date).format("DD"))
  const isLeaveBooked = tHead?.map((j) => j?.disabled ? false : is_leave_Date?.includes(j?.date?.toString().padStart(2, 0)))
  return (
    <div className={styles.tablemain}>
      <table className={styles.table}>
        <tr className={styles.tableTr}>
          {tHead.map((item, i) => {
            return (
              <th
                className={`${styles.thead} ${
                  item.disabled === true ? styles.timeDisable : ""
                }`}
              >
                <div className={styles.thDiv}>
                  <div className={styles.monthDay}>{weekDays[i]}</div>
                  <div
                    className={`${styles.monthDate} ${
                      item.disabled === true 
                      ? '' 
                      : bookedDataObj[item?.date] 
                      ? styles.bookedDate 
                      : ''
                    }
                    ${isLeaveBooked[i] ? styles.isLeave : ''}
                    `}
                  >
                    {item?.date}
                  </div>
                </div>
              </th>
            );
          })}
        </tr>
        <tr className={styles.tableTr}>
          {tHead.map(({ date, disabled }, i) => {
            const bookedSlot = disabled
              ? []
              : timeSlotsBooked?.filter(
                  (i) => i.date === date.toString().padStart(2, 0)
                );

            return (
              <td
                className={`${styles.tdData} ${
                  disabled ? styles.timeDisable : ""
                }`}
              >
                <div className={styles.scrollbar} id={"styleId"+i}>
                  <div className={styles.timeSlot}>
                    {timeSlotdata?.map((item) => {
                      const bookedTime = bookedSlot
                        ?.map((j) => j.timeId)
                        .includes(item.id);

                      return (
                        <div className={stylesTime.timeSlotContainerView}>
                          <div
                            className={`${stylesTime.timeSlotCheckBoxView} ${
                              stylesTime.selectCheckedCheckBoxView
                            } ${bookedTime ? stylesTime.bookedTimeSlot : ""}`}
                          >
                            <label>
                              <span>{item.value}</span>
                            </label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </td>
            );
          })}
        </tr>
      </table>
    </div>
  );
};
