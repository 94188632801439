import axios from "../../config/axiosConfig";

export const listTestimonial = async (
  status,
  customer_id = "",
  search_key,
  from_date,
  to_date
) => {
  console.log("testimonial");
  return await axios
    .get(
      `admin/testimonial?status=${status}&customer_id=${customer_id}&search_key=${search_key}&fromdate=${from_date}&todate=${to_date}`
    )
    .then((res) => res)
    .catch((err) => err);
};

export const getPatientTestimonials = async (
  status,
  search_key,
  from_date,
  to_date
) => {
  console.log("testimonial");
  return await axios
    .get(
      `admin/getPatientTestimonials?status=${status}&search_key=${search_key}&fromdate=${from_date}&todate=${to_date}`
    )
    .then((res) => res)
    .catch((err) => err);
};

export const listEnterpriseTestimonial = async (
  status,
  search_key,
  from_date,
  to_date
) => {
  return await axios
    .get(
      `admin/getEnterpriseTestimonials?status=${status}&search_key=${search_key}&fromdate=${from_date}&todate=${to_date}`
    )
    .then((res) => res)
    .catch((err) => err);
};

export const updateTestimonial = async (testimonial_id, status) => {
  return await axios
    .get(
      `admin/update/testimonial/status?testimonial_id=${testimonial_id}&status=${status}`
    )
    .then((res) => res)
    .catch((err) => err);
};

export const updateStatusTestimonial = async (data) => {
  return await axios
    .post(`admin/active/testimonial`, data)
    .then((res) => res)
    .catch((err) => err);
};
