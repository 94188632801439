import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import classes from "../../../../assets/css/staff.module.css";
import {
  Input,
  Select,
  SelectWithId,
} from "../../../../Component/UI/CustomInputs";
import { addPatientData } from "../../../../Redux/Slices/addPatient";
import { formattedLocations } from "../../../../config/formattedLocations";
import {
  listCity,
  listCountries,
  listState,
} from "../../../../services/others/locations";

export const EmergencyContact = ({
  err,
  data,
  writePermissions,
  CountryCodes,
}) => {
  console.log(data, "datarajeev");
  const dispatch = useDispatch();
  const rgx = /^(?:[0-9+]+)?$/;
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(addPatientData({ [name]: value }));
  };

  useEffect(() => {
    listCountries().then((res) => {
      const countries = formattedLocations(res, "name");
      setCountries(countries);
    });

    data?.emergency_country &&
      listState(data?.emergency_country).then((res) => {
        const states = formattedLocations(res, "name");
        setStates(states);
      });

    data?.emergency_state &&
      listCity(data?.emergency_state).then((res) => {
        const cities = formattedLocations(res, "city");
        setCities(cities);
      });
  }, [data?.emergency_country, data?.emergency_state]);

  return (
    <div className={classes.ill_history}>
      <div className={classes.general_obv}>
        <div>
          <Input
            name="emergency_name"
            label="Full Name"
            value={data?.emergency_name}
            error={err?.emergency_name}
            InputVisible="true"
            placeholder="Full Name"
            onChange={handleChange}
            disabled={writePermissions === 0 ? true : false}
          />
        </div>
        <div>
          <Select
            name="emergency_relationship"
            label="Relationship"
            value={data?.emergency_relationship}
            error={err?.emergency_relationship}
            options={[{ id: 1, value: "Friend" }]}
            onChange={handleChange}
            disabled={writePermissions === 0 ? true : false}
          />
        </div>
      </div>
      <div className={classes.general_obv}>
        <div>
          <Input
            name="emergency_email"
            label="Email"
            value={data?.emergency_email}
            error={err?.emergency_email}
            type="email"
            placeholder="Email"
            onChange={handleChange}
            disabled={writePermissions === 0 ? true : false}
          />
        </div>
        <div
          style={{ display: "flex", alignItems: "center", gap: "10px" }}
          className={`${classes.size}${classes.phoneSec}`}
        >
          <SelectWithId
            name="emergencyCountryCode"
            options={CountryCodes}
            width="180px"
            label="Country Code"
            value={data?.emergencyCountryCode}
            onChange={handleChange}
          />
          <Input
            type="text"
            name="emergency_mobile"
            label="Mobile"
            value={data?.emergency_mobile}
            error={err?.emergency_mobile}
            placeholder="Mobile"
            onChange={(e) => {
              const isNum = rgx.test(e.target.value);
              isNum &&
                dispatch(addPatientData({ emergency_mobile: e.target.value }));
            }}
            disabled={writePermissions === 0 ? true : false}
          />
        </div>
      </div>
      <div className={classes.general_obv}>
        <div>
          <Input
            name="emergency_address_line1"
            label="Address Line 1"
            value={data?.emergency_address_line1}
            error={err?.emergency_address_line1}
            placeholder="Address Line 1"
            onChange={handleChange}
            disabled={writePermissions === 0 ? true : false}
          />
        </div>
        <div>
          <Input
            name="emergency_address_line2"
            label="Address Line 2"
            value={data?.emergency_address_line2}
            error={err?.emergency_address_line2}
            placeholder="Address Line 2"
            onChange={handleChange}
            disabled={writePermissions === 0 ? true : false}
          />
        </div>
      </div>
      <div className={classes.general_obv}>
        <div>
          <Select
            name="emergency_country"
            label="Country"
            value={data?.emergency_country || 101}
            error={err?.emergency_country}
            options={countries}
            onChange={handleChange}
            disabled={writePermissions === 0 ? true : false}
          />
        </div>
        <div>
          <Select
            name="emergency_state"
            label="State"
            value={data?.emergency_state}
            error={err?.emergency_state}
            options={states}
            onChange={handleChange}
            disabled={writePermissions === 0 ? true : false}
          />
        </div>
      </div>
      <div className={classes.general_obv}>
        <div>
          <Select
            name="emergency_city"
            options={cities}
            label="City"
            value={data?.emergency_city}
            error={err?.emergency_city}
            onChange={handleChange}
            disabled={writePermissions === 0 ? true : false}
          />
        </div>
        <div>
          <Input
            type="number"
            name="emergency_zipcode"
            label="Zip Code"
            value={data?.emergency_zipcode}
            error={err?.emergency_zipcode}
            placeholder="Zip Code"
            disabled={writePermissions === 0 ? true : false}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};
