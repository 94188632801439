import React from "react";
import styles from "./checkBox.module.css";
export const TimeSlot = ({
  timeSlotdata,
  timeSlotCheckbox,
  timeSlotChecked,
  isBooked,
  availability,
}) => {
  return (
    <div className={styles.timeSlotCheckBoxes}>
      {timeSlotdata?.map((item) => {
        const isBookedTime = isBooked?.includes(item.id);
        return (
          <div className={styles.timeSlotContainer}>
            <div
              className={`${isBookedTime ? styles.bookedTime : ""} ${
                styles.timeSlotCheckBox
              } ${styles.timeSelectCheckedCheckBox} ${
                timeSlotChecked[item.id] ? styles.selectedItem : ""
              }`}
            >
              <label>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    if (availability === "3") {
                      timeSlotCheckbox(e.target.value);
                    }
                  }}
                  value={item.id}
                  checked={timeSlotChecked[item.id]}
                />
                <span>{item.value}</span>
                {timeSlotChecked[item.value]}
              </label>
            </div>
          </div>
        );
      })}
    </div>
  );
};
