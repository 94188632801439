import React from "react";
import classes from "../../../../assets/css/assessmentsummary.module.css";
import needs from "../../../../MockData/AssessmentNeed.json";
import { AssessNeedCard } from "../../../UI/AssessNeedCard";

export const Needs = () => {
  return (
    <div className={classes.need_container}>
      {needs.map((i) => (
        <AssessNeedCard title={i.title} description={i.description} />
      ))}
    </div>
  );
};
