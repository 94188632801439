import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const { GETCOUNTRYCUSTOMER, GETSTATECUSTOMER, GETCITYCUSTOMER } = API;

export const listCountries = async () => {
  return await axios
    .get(GETCOUNTRYCUSTOMER)
    .then((res) => res.data.list)
    .catch((err) => console.log(err));
};

export const listState = async (country_id) => {
  return await axios
    .get(GETSTATECUSTOMER + country_id)
    .then((res) => res.data.list)
    .catch((err) => console.log(err));
};

export const listCity = async (state_id) => {
  return await axios
    .get(GETCITYCUSTOMER + state_id)
    .then((res) => res.data.list)
    .catch((err) => console.log(err));
};
