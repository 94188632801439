import { createSlice } from "@reduxjs/toolkit";

const dashboardStatsSlice = createSlice({
  name: "get patients",
  initialState: { stats: "" },
  reducers: {
    getDashboardStats: (state, action) => {
      state.stats = action.payload;
    },
  },
});

export const { getDashboardStats } = dashboardStatsSlice.actions;

export default dashboardStatsSlice.reducer;
