import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import Typography from "@mui/material/Typography";
import styles from "../../../../assets/css/PatientDashboard/progresstrend.module.css";

const CircularBar = ({ Name, percentage, barcolor, icon }) => {
  const [progress, setProgress] = useState(0);
  let interval = useRef(null);
  useEffect(() => {
    interval.current = setInterval(() => {
      setProgress((old) => old + 30);
    }, 1000);
    return () => {
      clearInterval(interval.current);
    };
  }, []);

  useEffect(() => {
    if (progress < 100) return;
    clearInterval(interval.current);
  }, [progress]);

  const series = [percentage]; //70 percent
  const options = {
    series: [progress],
    colors: [barcolor],
    plotOptions: {
      radialBar: {
        hollow: {
          size: "60%",
          image: `${icon}`,
          imageWidth: 50,
          imageHeight: 50,
          imageClipped: false,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
      },
    },
  };
  return (
    <>
      <ul className={styles.ulcontainter} style={{ userSelect: "none" }}>
        <li>
          <Chart
            type="radialBar"
            series={series}
            options={options}
            width={160}
            height={200}
          />
        </li>
        <li>
          <Typography variant="span" gutterBottom>
            {Name}
          </Typography>
          <li>
            <Typography sx={{ textAlign: "center" }} variant="h6" gutterBottom>
              {percentage}%
            </Typography>
          </li>
          <li className={styles.listcss}>
            <Typography
              variant="caption"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
              gutterBottom
            >
              {"Completed"}
            </Typography>
          </li>
        </li>
      </ul>
    </>
  );
};

export default CircularBar;
