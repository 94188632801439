import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Datepicker,
  Input,
  NativeDatePicker,
  Select,
  TextArea,
} from "../../../../Component/UI/CustomInputs";
import MySelect from "../../../../Component/UI/MultiSelect/SelectMultiSelect";
import { UploadProfile } from "../../../../Component/UI/UploadProfile";
import { clearStaff, setStaff } from "../../../../Redux/Slices/addStaff";
import { getDesignation, getRoles } from "../../../../services/admin/staff";
import styles from "./staff.module.css";
import { components } from "react-select";
import { useSelector } from "react-redux";
import moment from "../../../../../node_modules/moment/moment";
import { Editor } from "../../../../Hooks/Editor";

export const Form = () => {
  const { errors } = useSelector((i) => i.addStaff);
  const [roles, setRoles] = useState([]);
  const [accessRoles, setAccessRoles] = useState([]);
  const [designation, setDesignation] = useState([]);
  const dispatch = useDispatch();
  const { staff } = useSelector((i) => i.addStaff);
  console.log(staff, "staff---");
  const [email_template, setEmail_template] = useState();
  const employeeType = [
    {
      id: 1,
      value: "Full time",
    },
    {
      id: 2,
      value: "Part time",
    },
  ];

  const modeOfWorking = [
    {
      id: 1,
      value: "Remote",
    },
    {
      id: 2,
      value: "Hybrid",
    },
    {
      id: 3,
      value: "In office",
    },
  ];

  const Option = (props) => {
    return (
      <div style={{ textAlign: "left !important" }}>
        <components.Option {...props}>
          <input
            required={true}
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  useEffect(() => {
    setEmail_template(staff?.description);
  }, [staff]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(setStaff({ [name]: value }));
  };

  const inputHandler = (value) => {
    dispatch(setStaff({ description: value }));
  };
  console.log(staff, "staff------");
  useEffect(() => {
    getRoles().then((res) => {
      const list = res?.data?.list?.map(({ name, id }) => {
        return { label: name, value: id };
      });
      console.log(list, "list+++");
      setRoles(list);
      const listRoles = res?.data?.list?.map(({ name, id }) => {
        return { id: id, value: name };
      });
      setAccessRoles(listRoles);
    });
    return () => dispatch(clearStaff());
  }, []);
  useEffect(() => {
    getDesignation().then((res) => {
      const listRoles = res?.data?.list?.map(({ name, id }) => {
        return { id: id, value: name };
      });
      setDesignation(listRoles);
    });
    return () => dispatch(clearStaff());
  }, []);

  console.log(designation, "bjsdbcbdus");

  return (
    <div className={styles.form}>
      <div>
        <div>
          <Input
            name="name"
            label="Full Name"
            width="250px"
            value={staff?.name}
            error={errors?.name}
            onChange={handleChange}
          />
          <NativeDatePicker
            label="Date of Birth"
            name="dob"
            max={moment().format("YYYY-MM-DD")}
            value={staff?.dob}
            error={errors?.dob}
            onChange={handleChange}
          />
        </div>
        <div>
          <Input
            name="email"
            type="email"
            value={staff?.email}
            label="Email"
            width="250px"
            error={errors?.email}
            onChange={handleChange}
          />
          <Input
            name="mobile"
            type="mobile"
            value={staff?.mobile}
            label="Mobile"
            width="250px"
            error={errors?.mobile}
            onChange={handleChange}
          />
        </div>

        <div>
          <div>
            <label>Role</label>
            <MySelect
              isMulti
              maxMenuHeight={230}
              controlShouldRenderValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{ Option }}
              options={roles}
              value={staff?.role_names}
              // options={
              //   selectedValue?.length > 4 ? selectedValue : enterpriselistoption
              // }
              // isDisabled={assessmentFor.toString() !== "1"}
              isSearchable={false}
              placeholder={
                staff?.role_names?.length
                  ? `${staff?.role_names?.length} Selected`
                  : "Select"
              }
              // className={styles.Select}
              isClearable={false}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "lightgrey",
                },
              })}
              onChange={(data) => {
                dispatch(
                  setStaff({
                    role_names: data.map(({ value, label }) => {
                      return { value, label };
                    }),
                  })
                );
                dispatch(setStaff({ role: data.map(({ value }) => value) }));
              }}
              // styles={colourStyles}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  marginTop: 5,
                  width: 250,
                  height: 40,
                }),
              }}
            />
            <p style={{ color: "red", margin: "5px" }}>
              {errors?.role?.toString()}
            </p>
          </div>
          <Select
            name="mode"
            label="Mode of Working"
            value={staff?.mode}
            width="250px"
            error={errors?.mode}
            options={modeOfWorking}
            onChange={handleChange}
          />
        </div>
        <div>
          <NativeDatePicker
            name="joining_date"
            label="Date of Joining"
            value={staff?.joining_date}
            width="250px"
            error={errors?.joining_date}
            onChange={handleChange}
          />
          <Input
            name="qualification"
            label="Qualification"
            value={staff?.qualification}
            width="250px"
            error={errors?.qualification}
            onChange={handleChange}
          />
        </div>
        <div>
          <Input
            name="specialization"
            label="Specialization"
            value={staff?.specialization}
            width="250px"
            error={errors?.specialization}
            onChange={handleChange}
          />
          <Select
            name="access_role"
            label="Role access"
            value={staff?.access_role}
            width="250px"
            options={accessRoles}
            error={errors?.access_role}
            onChange={handleChange}
          />
        </div>
        <div>
          <Select
            name="designations_id"
            label="Designations"
            value={staff?.designations_id}
            width="250px"
            options={designation}
            error={errors?.designations_id}
            onChange={handleChange}
          />
        </div>
        <div>
          <Select
            name="employe_type"
            label="Employee type"
            value={staff?.employe_type}
            width="250px"
            options={employeeType}
            error={errors?.employe_type}
            onChange={handleChange}
          />
          <Input
            name="aadhar_number"
            type="number"
            label="Aadhar Number"
            value={staff?.aadhar_number}
            width="250px"
            error={errors?.aadhar_number}
            onChange={handleChange}
          />
        </div>

        <div>
          <NativeDatePicker
            name="probation_date"
            label="Probation period"
            value={staff?.probation_date}
            width="250px"
            error={errors?.probation_date}
            onChange={handleChange}
          />
          <NativeDatePicker
            name="last_working"
            label="Last day of working"
            value={staff?.last_working}
            width="250px"
            error={errors?.last_working}
            onChange={handleChange}
          />
        </div>
        <div>
          <p>Address</p>
        </div>
        <div>
          <Input
            name="current_address"
            label="Current Address"
            value={staff?.current_address}
            width="250px"
            error={errors?.current_address}
            onChange={handleChange}
          />
          <Input
            name="permanent_address"
            label="Permentant Address"
            value={staff?.permanent_address}
            width="250px"
            error={errors?.permanent_address}
            onChange={handleChange}
          />
          {/* <Select label="State" width="250px" />
          <Select label="City" width="250px" /> */}
          {/* <TextArea label="Current Address" width="520px" height="70px" /> */}
        </div>
        <div>
          {/* <Select label="State" width="250px" />
          <Select label="City" width="250px" /> */}
          {/* <TextArea label="Permentant Address" width="520px" height="70px" /> */}
        </div>
        <div>
          <p>Bank Details</p>
        </div>
        <div>
          <Input
            name="bank_name"
            type="text"
            label="Bank Name"
            value={staff?.bank_name}
            width="250px"
            error={errors?.bank_name}
            onChange={handleChange}
          />
          <Input
            name="ifsc_code"
            type="text"
            label="IFSC Code"
            value={staff?.ifsc_code}
            width="250px"
            error={errors?.ifsc_code}
            onChange={handleChange}
          />
        </div>
        <div>
          <Input
            name="account_number"
            type="text"
            label="Account Number"
            value={staff?.account_number}
            width="250px"
            error={errors?.account_number}
            onChange={handleChange}
          />
          <Input
            name="account_branch"
            type="text"
            label="Branch"
            value={staff?.account_branch}
            width="250px"
            error={errors?.account_branch}
            onChange={handleChange}
          />
        </div>
        <div>
          <p>Emergency Contact</p>
        </div>
        <div>
          <Input
            name="emergency_contact_name"
            type="text"
            label="Name"
            value={staff?.emergency_contact_name}
            width="250px"
            error={errors?.emergency_contact_name}
            onChange={handleChange}
          />
          <Input
            name="emergency_contact_num"
            type="number"
            label="Number"
            value={staff?.emergency_contact_num}
            width="250px"
            error={errors?.emergency_contact_num}
            onChange={handleChange}
          />
        </div>
        <>
          <div style={{ margin: "10px 0" }}>About Employee</div>
          <Editor data={email_template} change={(val) => inputHandler(val)} />

          {/* <TextArea
            name="description"
            label="About Employee"
            value={staff?.description}
            width="520px"
            height="90px"
            error={errors?.description}
            onChange={handleChange}
          /> */}
        </>
        <p style={{ color: "red" }}>{errors?.description}</p>
      </div>
      <UploadProfile
        error={errors?.logo}
        profile={staff?.staff_image}
        upload={(value) => {
          dispatch(setStaff({ logo: value }));
        }}
      />
    </div>
  );
};
