import React from "react";
import styles from "../../PreLogin/landing.module.css";

export const ForwardIcon = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      className={styles.nextIcon}
    >
      <path
        d="M31.9999 58.6666C46.7275 58.6666 58.6666 46.7275 58.6666 31.9999C58.6666 17.2723 46.7275 5.33325 31.9999 5.33325C17.2723 5.33325 5.33325 17.2723 5.33325 31.9999C5.33325 46.7275 17.2723 58.6666 31.9999 58.6666Z"
        fill="white"
        stroke="#242424"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28 42.6666L38.6667 31.9999L28 21.3333"
        stroke="#242424"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
