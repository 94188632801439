import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const { STAFF_LIST_ADMIN, STAFFSTATUS } = API;

export const stafListAdmin = async (search, from_date, to_date) => {
  return await axios
    .get(
      `${STAFF_LIST_ADMIN}?search_key=${search}&from_date=${from_date}&to_date=${to_date}`
    )
    .then((res) => res)
    .catch((err) => err);
};

export const staffStatus = async (staff_id, status) => {
  return await axios
    .post(STAFFSTATUS, {
      staff_id,
      status,
    })
    .then((res) => res)
    .catch((err) => err);
};

export const addStaff = async (data) => {
  return await axios
    .post("/admin/add/staff", data)
    .then((res) => res)
    .catch((err) => err);
};

export const getRoles = async () => {
  return await axios
    .get("/admin/get/role")
    .then((res) => res)
    .catch((err) => err);
};

export const getDesignation = async () => {
  return await axios
    .get("/admin/get/staff/designation")
    .then((res) => res)
    .catch((err) => err);
};


export const getRolesApp = async () => {
  return await axios
    .get("/admin/appointment/role")
    .then((res) => res)
    .catch((err) => err);
};

export const editStaff = async (data) => {
  return await axios
    .post("/admin/update/staff", data)
    .then((res) => res)
    .catch((err) => err);
};

export const getServiceApp = async (data) => {
  return await axios
    .get(`/admin/subscription/services?subscription_type=${0}`)
    .then((res) => res)
    .catch((err) => err);
};
