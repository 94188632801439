import React from "react";
import TextField from "@mui/joy/TextField";
import classes from "../../../../../assets/css/activity.module.css";
import trash from "../../../../../assets/icons/icon/trash.svg";
import timer from "../../../../../assets/icons/icon/timer.svg";
import { Input } from "../../../../../Component/UI/CustomInputs";
import { ActivityPerformance } from "./ActivityPerformance";
import { CustomizedAccordions } from "../../../../../Component/UI/Accordion/CustomizedAccordions";

export const Activity = () => {
  const accordiontitle = [
    "Word Search",
    "Follow-up",
    "Find the difference_App",
    "Repeat after me",
  ];
  const accordion_opt = <ActivityPerformance />;
  return (
    <>
      {accordiontitle.map((i) => (
        <>
          <CustomizedAccordions content={accordion_opt} heading={i} />
        </>
      ))}
    </>
  );
};
