import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PrevIcon from "../Images/arrow-left.svg";
import ForwardIcon from "../Images/arrow-right.svg";
import "./carousel.css";

const PrevArrow = ({ className, style, onClick }) => (
  <button
    className={className}
    style={{ ...style, display: "block" }}
    onClick={onClick}
  >
    <img src={PrevIcon} alt="left" />
  </button>
);
const NextArrow = ({ className, style, onClick }) => (
  <button
    className={className}
    style={{ ...style, display: "block" }}
    onClick={onClick}
  >
    <img src={ForwardIcon} alt="ForwardIcon" />
  </button>
);

export const ClientCarousel = ({
  data,
  Component,
  customProps,
  slides,
  indexItem,
  handleStart,
}) => {
  const settings = {
    infinite: true,
    slidesToShow: slides || 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplayTimeout: 1000,
    lazyLoad: true,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  console.log(data, "data");
  return (
    <Slider {...settings}>
      {data &&
        data?.map((ele, i) => {
          return (
            <ul>
              <Component
                {...ele}
                {...customProps}
                key={ele?.id}
                index={i}
                instruction={ele?.instruction}
                Icon={ele?.logo}
                onClick={() => handleStart(ele)}
                homePage={true}
              />
            </ul>
          );
        })}
    </Slider>
  );
};
