import React from "react";
import { ActivityCard } from "../../ActivityCard/ActivityCard";
import { MoodCard } from "../../MoodCard/MoodCard";
import { Dates } from "../../UI/Dates";
import instance from "../../../config/axiosConfig";
import { API } from "../../../config/constant";

import styles from "../../../assets/css/activitycard.module.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDisable } from "../../../Redux/Slices/WellnessTracker/monthChangerSlice";

const Daily = () => {
  const [activityData, setActivityData] = React.useState(null);
  const [toggle, setToggle] = React.useState();
  const [loaded, setLoaded] = React.useState(false);

  const { disable } = useSelector((state) => state.MonthChangerSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    getActivityData();
    dispatch(setDisable(true));
    return () => {
      dispatch(setDisable(false));
    };
  }, []);

  const getActivityData = () => {
    instance
      .get(API.TODAYACTIVITY)
      .then((res) => {
        setActivityData(res.data.list);
        setToggle(res.data.list.answered.answer);
      })
      .finally(() => setLoaded(true));
  };

  return (
    <>
      {/* <Dates /> */}
      <div style={{ padding: "15px" }}>
        <h6 className={styles.mainHeading}>
          <span>Today's Activity</span>
        </h6>
        <ActivityCard
          toggle={toggle}
          getActivityData={getActivityData}
          disabled={false}
          activityAnswer={activityData?.answered}
          question={activityData?.question}
          icon={activityData?.question_image}
          loaded={loaded}
        />
      </div>
      <div className={styles.moodCartSelf} style={{ padding: "0 15px 15px 15px" }}>
        <MoodCard />
      </div>
    </>
  );
};

export default Daily;
