import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allEvents } from "../../../Redux/Slices/PatientEvents";
import { getEvents } from "../../../services/patient/events";
import { Cards } from "./Cards";
import moment from "moment";
import {
  setToggleSearch,
  setSearchText,
} from "../../../Redux/Slices/patientSearch";
import {
  setDate,
  setToggle,
  setYear,
} from "../../../Redux/Slices/Patient/PatienCalender";

export const searchAndDateFilter = (arr, search, date) => {
  const FinalArr = arr || [];

  // console.log(arr, "arrarrarr");

  const searchResult = FinalArr.length
    ? FinalArr.filter((event) =>
        event?.name?.toLowerCase()?.includes(search?.toLowerCase())
      ).filter((event) =>
        date?.includes("All")
          ? event?.event_date.includes(date?.split(" ")?.[1])
          : event?.event_date.includes(moment(date).format("YYYY-MM"))
      )
    : [];
  return searchResult;
};

export const All = () => {
  const dispatch = useDispatch();
  const { all, message } = useSelector((i) => i.PatientEvents);
  const { selectedDate } = useSelector((i) => i.DateFilterSlice);
  const { searchText, toggleSearch } = useSelector((i) => i.patientSearch);
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    getEvents("all").then((i) => {
      dispatch(allEvents(i));
      setAllData(i);
    });

    return () => {
      dispatch(setToggleSearch(false));
      dispatch(setSearchText(""));
      dispatch(setDate(`All ${new Date().getFullYear()}`));
      dispatch(setToggle(false));
      dispatch(setYear(new Date().getFullYear()));
    };
  }, [message]);

  useEffect(() => {
    const searchResult = searchAndDateFilter(allData, searchText, selectedDate);
    dispatch(allEvents(searchResult));
  }, [searchText, selectedDate, allData]);

  return <Cards data={all} />;
};
