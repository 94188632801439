import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { RiDeleteBinLine } from "react-icons/ri";
import Grid from "@mui/material/Grid";
import toast, { Toaster } from "react-hot-toast";

import FileUpload from "../../../UI/FileUpload/FileUpload";
import { CustomRadio } from "../../../../Component/UI/CustomInputs";
import style from "../../../../assets/css/AdminAssessment/addAssesmentQuestion.module.css";
import BasicBreadcrumbs from "../../../../Component/UI/BreadCrumb/BreadCrumbs";
import { convertToBase64 } from "./ConvertToBase64";
import { useNavigate, useLocation } from "react-router-dom";
import instance from "../../../../config/axiosConfig";
import { API } from "../../../../config/constant";
import { SelectType } from "../../../../Component/UI/CustomInputs";
import { Input } from "../../../UI/Input";
import { TEXT } from "../../../contant/constant";
import { optionFieldValidate, Validate } from "./OptionFieldValidation";
import AssessmentMinMax from "./AssessmentMinMax";

const options = [
  "Select",
  "Single Choice",
  "Multi Select",
  "Toggle",
  "Range",
  "BMI",
  "Text",
];
const myRevOptions = {
  radio: "Single Choice",
  checkbox: "Multi Select",
  toggle: "Toggle",
  range: "Range",
  bmi: "BMI",
  text: "Text",
};

const myOptions = {
  "Single Choice": "radio",
  "Multi Select": "checkbox",
  Toggle: "toggle",
  Range: "range",
  BMI: "bmi",
  Text: "text",
};
const AssesmentQuestion = () => {
  const [inputFields, setInputFields] = useState([
    {
      option: "",
      scores: "",
      icon: "",
    },
  ]);

  const location = useLocation();
  const { cid, sno, qid, apiflag } = location?.state;

  const link = [
    { path: "/Assessments", linkText: "Assessments" },
    {
      path: "/AddAssessment",
      linkText: "Add Assessment",
      cid,
    },
  ];

  const navigate = useNavigate();

  const [flag, setFlag] = useState(false);
  const [respData, setRespData] = useState({
    option: "",
    scores: "",
    id: 0,
    icon: "",
  });

  const [error, setError] = useState();

  const [selected, setSelected] = useState("Select");
  const [questionValue, setQuestionValue] = useState("");
  const [questImage, setQuestImage] = useState("");
  const [inputData, setInputData] = useState(TEXT.MYSELF);
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [tagsValue, setTagsValue] = useState("");
  const [questImageComeFromApi, setQuestImageComeFromApi] = useState("");
  const [maxLength, setMaxLength] = useState(0);
  const [optionError, setOptionError] = useState(false);
  const [quesImagePath, setQuesImagePath] = useState();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    ApiDataSpit();
  }, [flag]);

  useEffect(() => {
    IfReset();
  }, [myOptions[selected]]);

  const getData = () => {
    try {
      instance
        .get(`${API.ASSESSMENTQUESTIONGETDETAILS}?question_id=${qid}`)
        .then((res) => {
          setSelected(myRevOptions[res.data.question?.input_type]);
          setRespData(res.data.question);
          setQuestionValue(res.data.question.question);
          setQuestImage(res.data.question?.question_image);
          setQuestImageComeFromApi(res.data.question?.question_image);
          setInputData(res.data.question?.assesment_for);
          setMaxValue(res.data.question?.maximum);
          setMinValue(res.data.question?.minimum);
          setTagsValue(res.data.question?.tag);
          setFlag(!flag);
          setQuesImagePath(res.data.question?.ques_image);
        });
    } catch (error) {
      console.log(error, "error");
    }
  };

  const ResetBtn = (flag) => {
    const result = inputFields.filter(
      (el) =>
        el.option === "" &&
        el.scores === "" &&
        el.option !== "" &&
        el.scores !== ""
    );
    setInputFields(result);
    setQuestionValue("");
    setQuesImagePath("");
    setQuestImage("");
  };

  const IfReset = () => {
    myOptions[selected] === respData.input_type?.toLowerCase()
      ? ApiDataSpit()
      : ResetBtn();
  };

  const selecedHandleChange = (e) => {
    setSelected(e.target.value);
    IfReset();
  };

  const QustionInputHandler = (e) => {
    e.preventDefault();
    const qvalue = selected !== TEXT.BMI ? e.target.value : TEXT.AUTOFILLEDBMI;
    //console.log(selected, "qvalue", qvalue);
    setQuestionValue(e.target.value);
  };

  const QuesImageUploadHandler = async (e) => {
    const acceptOnlyImage = e.target.accept.split(",").filter((result) => {
      return result === e.target.files[0].type;
    });

    acceptOnlyImage[0] === e.target.files[0].type
      ? setImage(e)
      : errorNotify("Please select image");
  };

  const setImage = async (e) => {
    setQuestImage(e.target.files[0]);
    const base64 = await convertToBase64(e.target.files[0]);
    setQuesImagePath(base64);
  };

  const onDeleteQuestionImageHandler = () => {
    const que_Id = {
      question_id: apiflag === TEXT.COMEINGTOEDIT ? qid : "",
    };
    try {
      instance.post(API.DELETEQUESTIONIMAGE, que_Id).then((res) => {
        if (res.data.status) {
          sucessNotify(res.data.msg);
          getData();
        } else {
          setQuesImagePath("");
          setQuestImage("");
        }
      });
    } catch (error) {
      console.log(error, "error");
    }
  };

  //Image Upload
  const ImageUploadHandler = async (e, imageid) => {
    e.preventDefault();

    const acceptOnlyImage = e.target.accept.split(",").filter((result) => {
      return result === e.target.files[0].type;
    });

    const imageData =
      acceptOnlyImage[0] === e.target.files[0].type
        ? e.target.files[0]
        : errorNotify("Please select image");
    const imgName = e.target.files[0].name;
    const base64 = await convertToBase64(imageData);

    let data = [...inputFields];

    data[imageid]["icon"] = base64;
    data[imageid]["imageName"] = imgName;
    setInputFields(data);
  };

  //AddFieldButton
  const addBtn = () => {
    let id = inputFields[inputFields.length - 1]?.id || 0;
    let APILength = inputFields.length > 1 ? 0 : inputFields.length + 1;

    if (selected === TEXT.TOGGLE) {
      if (maxLength < APILength) {
        let newField = {
          option: "",
          scores: "",
          icon: "",
          id: ++id,
        };
        setInputFields([...inputFields, newField]);
        setMaxLength(maxLength + 1);
      }
    } else {
      let newField = {
        option: "",
        scores: "",
        icon: "",
        id: ++id,
      };
      setInputFields([...inputFields, newField]);
    }
  };

  //Remove Button
  const removeApi = (id) => {
    let removepayload = {
      category_id: "qid",
      question_id: inputFields[id].id,
    };

    try {
      instance.post(API.DELETESELFCARETIPS, removepayload).then((res) => {
        res.data.status === true && getData();
      });
    } catch (error) {
      console.log(error, "error");
    }
  };

  const removeLocal = (id) => {
    if (
      window.confirm(
        `Are you sure you want to delete this ${inputFields[id].option}? Please note that this cannot be undo & will be saved immediately.`
      ) === true
    ) {
      let data = [...inputFields];
      data.splice(id, 1);
      setInputFields(data);
    }
  };

  const removeBtn = (e, id) => {
    let existing = inputFields[id].existing;

    existing
      ? window.confirm(
          ` Are you sure you want to delete this ${inputFields[id].option}? Please note that this cannot be undo & will be saved immediately.`
        ) === true && removeApi(id)
      : removeLocal(id);
  };

  const reset = () => {
    const result = inputFields.filter(
      (el) => el.option !== "" && el.scores !== "" && el.icon !== ""
    );

    setInputFields(result);
  };

  //Add Fields

  const re = /^([1-9][-]([1-9][0-9]))$/;
  const reSingle = /^([1-9][-]([1-9]))$/;
  const rex = /^(([1-9][0-9])[-]([1-9][0-9]))$/;
  const rexs = /^(([1-9][0-9])[-]([1-9][0-9][0-9]))$/;
  const AddInputhandleChange = (e, id) => {
    e.preventDefault();
    setError({
      option: false,
      scores: false,
    });
    let data = [...inputFields];

    data[id][e.target.name] = e.target.value;

    if (selected === TEXT.RANGE && Object.keys(data[id])[0] === e.target.name) {
      if (
        data[id].option === "" ||
        re.test(e.target.value) ||
        rex.test(e.target.value) ||
        rexs.test(e.target.value) ||
        reSingle.test(e.target.value)
      ) {
        setInputFields(data);
        setOptionError(false);
      } else {
        setOptionError(true);
      }
    }
    setInputFields(data);
  };

  const ApiDataSpit = () => {
    const splitOption =
      myOptions[selected] === respData.input_type?.toLowerCase()
        ? respData?.options?.split(",") || []
        : [];
    const splitScore =
      selected !== "BMI" ? respData?.scores?.split(",") || [] : [];
    const splitImg =
      selected !== "BMI" || selected !== "Range"
        ? respData.option_images
          ? respData.option_images?.split("&&")
          : [""]
        : [];

    let finalData = [];

    for (let i = 0; i < splitOption?.length; i++) {
      let myitem = {
        option: splitOption[i],
        scores: splitScore[i],
        icon: splitImg[i],
        id: i,
      };
      finalData.push(myitem);
    }
    setQuestionValue(respData?.question);
    setQuesImagePath(respData?.ques_image);
    setInputFields(finalData);
  };

  const OnSuccess = (res) => {
    sucessNotify(res.data.msg);
    navigate("/AddAssessment", {
      state: { id: cid, cid: cid, ass_id: cid, from: "edit", count: 2 },
    });
  };

  const sucessNotify = (msg) => toast.success(msg);

  const errorNotify = (error) => toast.error(error);

  const filterdata = (data) => {
    const newData = data.split("&&");

    const isEmpty = newData.filter((item) => !item.length).length;
    if (isEmpty === newData.length) {
      return true;
    } else {
      const img = newData.filter((item) => item.length);
      return img.length === newData.length;
    }
  };

  useEffect(() => {
    return () => toast.remove();
  }, []);

  //SaveButton
  const SaveHandler = () => {
    toast.remove();
    const a = inputFields.map((i) => Object.values(i));
    const Finalresp = (ind) => a.map((i) => i[ind]);

    let length = Finalresp(2).length; // find an array length
    let optionImage = "";
    for (let i = 0; i < length; i++) {
      optionImage += Finalresp(2)[i]; // concat Array value to a string variable
      if (i < length - 1) {
        optionImage += "&&"; // add separator
      }
    }

    const checkAssesmentFor =
      inputData === "someone_else" ? "someone_else" : "Myself";
    const assesment_for = cid === 1 ? checkAssesmentFor : "Myself";

    const options = Finalresp(0).toString() || [];

    const quest = selected === TEXT.BMI ? TEXT.AUTOFILLEDBMI : questionValue;

    const previousQuesImage =
      questImageComeFromApi === questImage ? "" : questImage;
    const questImg =
      selected === TEXT.BMI || selected === TEXT.TEXT ? "" : previousQuesImage;
    const serialNo = apiflag === TEXT.COMEINGTOEDIT ? sno : "";

    const validate = Validate(
      myOptions[selected],
      minValue,
      maxValue,
      tagsValue
    );
    const optionalField =
      (myOptions[selected] === TEXT.BMI.toLowerCase() && true) ||
      (myOptions[selected] === TEXT.TEXT.toLowerCase() && true);

    const optfieldvalidate = optionalField
      ? true
      : optionFieldValidate(
          myOptions[selected],
          inputFields.length,
          options,
          Finalresp(1).toString()
        );

    let form_data = new FormData();
    form_data.append("category_id", cid);
    form_data.append("serial_number", serialNo);
    form_data.append("assesment_for", assesment_for);
    form_data.append("options", options);
    form_data.append("scores", Finalresp(1).toString());
    form_data.append("input_type", myOptions[selected]);
    form_data.append("question_id", qid);
    form_data.append("question", quest);
    form_data.append("minimum", minValue || "");
    form_data.append("maximum", maxValue || "");
    form_data.append("tag", tagsValue || "");
    form_data.append("option_images", optionImage || "");
    form_data.append("question_image", questImg);

    if (selected !== undefined && selected !== "Select") {
      if (quest !== "undefined" && quest !== undefined && quest !== "") {
        if (filterdata(optionImage)) {
          if (optionError === false) {
            if ((validate || validate === undefined) && optfieldvalidate) {
              const AccToInputLength =
                apiflag === TEXT.COMEINGTOEDIT
                  ? API.ASSESSMENTQUESTIONGETUPDATE
                  : API.ASSESSMENTQUESTIONGETAdd;
              console.log("trrrreew");
              try {
                instance.post(AccToInputLength, form_data).then((res) => {
                  res.data.status === true
                    ? OnSuccess(res)
                    : errorNotify(res.data.error);
                });
              } catch (error) {
                console.log(error, "error");
              }
            }
          }
        } else {
          errorNotify("Please select all option images");
        }
      } else {
        errorNotify("Question field is required");
      }
    } else {
      errorNotify("Type of Input are required");
    }
  };

  const CancelHandler = () => {
    navigate("/AddAssessment", {
      state: { id: cid, cid: cid, ass_id: cid, from: "edit", count: 2 },
    });
  };

  const MinHandler = (e) => {
    e.preventDefault();
    setMinValue(e.target.value);
  };

  const MaxHandler = (e) => {
    e.preventDefault();
    setMaxValue(e.target.value);
  };

  const TagsHandler = (e) => {
    e.preventDefault();
    setTagsValue(e.target.value);
  };
  //console.log(questionValue, "quesValue");
  return (
    <>
      <BasicBreadcrumbs link={link} text={"Add Question"}>
        {/* <PaperWrapper> */}
        <div className={style.paperContainercss}>
          <div className={style.mainQuestions}>
            <Grid container>
              <Grid item xs={3} sx={{ paddingRight: "10px" }}>
                <div className={style.quesOne}>
                  <SelectType
                    option={options}
                    value={selected}
                    label="Type of Input"
                    onChange={(e) => selecedHandleChange(e)}
                  />
                </div>
              </Grid>
              <Grid item xs={4} sx={{ paddingRight: "10px" }}>
                <Input
                  label="Question"
                  type={"text"}
                  name={"question"}
                  // value={questionValue}
                  onChange={(e) => QustionInputHandler(e)}
                  defaultValue={
                    selected === "BMI" ? TEXT.AUTOFILLEDBMI : questionValue
                  }
                />
              </Grid>
              {selected !== TEXT.BMI && selected !== TEXT.TEXT && (
                <Grid item xs={3} sx={{ paddingRight: "10px" }}>
                  <p>Question Image</p>
                  <FileUpload
                    onChange={QuesImageUploadHandler}
                    fileName={questImage?.name}
                  />
                </Grid>
              )}
              <Grid
                xs={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "15px",
                }}
              >
                {quesImagePath && (
                  <img
                    src={quesImagePath}
                    alt="question images"
                    style={{ width: "50px", heigth: "50px" }}
                  />
                )}

                <span id="file-chosen">{questImage?.name}</span>
                {quesImagePath && (
                  <span>
                    <RiDeleteBinLine
                      style={{
                        width: "25px",
                        height: "25px",
                        cursor: "pointer",
                        marginLeft: "15px",
                        color: "green",
                      }}
                      onClick={onDeleteQuestionImageHandler}
                    />
                  </span>
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={4}>
                  {cid === 1 && (
                    <CustomRadio
                      text="Assessment For"
                      values={[
                        { id: "Myself", value: "Myself" },
                        { id: "someone_else", value: "Someone Else" },
                      ]}
                      checked={inputData}
                      click={(value) => setInputData(value)}
                    />
                  )}
                </Grid>
              </Grid>
              {selected === TEXT.RANGE && (
                <AssessmentMinMax
                  minValue={minValue}
                  MinHandler={MinHandler}
                  maxValue={maxValue}
                  MaxHandler={MaxHandler}
                  tagsValue={tagsValue}
                  TagsHandler={TagsHandler}
                />
              )}
            </Grid>
          </div>

          {selected !== TEXT.BMI && selected !== TEXT.TEXT && (
            <div className={style.addQuestion}>
              <h2 className={style.h2text}>
                Add Choices
                {selected === TEXT.RANGE && (
                  <p style={{ marginTop: "5px" }}>
                    Please add score along with range ex:- 1-10
                  </p>
                )}
              </h2>

              <div className="addButtton">
                <Button
                  variant="contained"
                  size="large"
                  style={{
                    backgroundColor: "black",
                    borderRadius: 25,
                    padding: "10px 35px",
                  }}
                  onClick={addBtn}
                >
                  Add
                </Button>
              </div>
            </div>
          )}

          {selected !== TEXT.BMI && selected !== TEXT.TEXT && (
            <Grid container spacing={0} style={{ margin: "20px" }}>
              {inputFields?.map((el, index) => {
                return (
                  <Grid
                    container
                    spacing={1}
                    style={{ margin: "5px -10px" }}
                    key={el.id}
                  >
                    <Grid item xs={4}>
                      <Input
                        label="Option"
                        name="option"
                        type="text"
                        placeholder={"Please Enter the option"}
                        id={index}
                        defaultValue={el.option}
                        onChange={(e) => AddInputhandleChange(e, index)}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Input
                        label="Scores"
                        name="scores"
                        type="number"
                        pattern="[0-9]*"
                        placeholder={"Please Enter the scores"}
                        id={index}
                        defaultValue={el.scores}
                        onChange={(e) => AddInputhandleChange(e, index)}
                      />
                    </Grid>

                    {selected === TEXT.SINGLECH && (
                      <Grid
                        item
                        xs={3}
                        style={{
                          marginTop: "22px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div>
                            <input
                              type="file"
                              id={`actual-btn${index}`}
                              name="image"
                              accept="image/png,image/jpeg,image/svg,image/gif,image/jpg,image/tiff"
                              defaultValue={el.image}
                              hidden
                              onChange={(e) => ImageUploadHandler(e, index)}
                            />

                            <label
                              htmlFor={`actual-btn${index}`}
                              className="uploadLabel"
                            >
                              Choose File
                            </label>
                          </div>
                          {/* <span id="file-chosen">{el.imageName}</span> */}
                          {el.icon && (
                            <img
                              src={el.icon}
                              alt="option images"
                              style={{ width: "25px", height: "25px" }}
                            />
                          )}
                        </div>
                      </Grid>
                    )}

                    <Grid
                      item
                      xs={3}
                      sx={{
                        marginTop: "22px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <span id="file-chosen" style={{ fontSize: "12px" }}>
                        {el.imageName}
                      </span>
                      <RiDeleteBinLine
                        style={{
                          width: "25px",
                          height: "25px",
                          cursor: "pointer",
                          marginRight: "15px",
                          color: "green",
                        }}
                        onClick={(e) => {
                          removeBtn(e, index);
                        }}
                      />
                    </Grid>
                  </Grid>
                );
              })}
              <p style={{ color: "red" }}>
                {optionError && "Please add score along with range ex:- 1-10"}
              </p>
            </Grid>
          )}
          <div className="formButton" style={{ marginTop: 20 }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#2A2A2A",
                borderRadius: 50,
                marginRight: 10,
              }}
              onClick={CancelHandler}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: "#00B091", borderRadius: 50 }}
              onClick={SaveHandler}
            >
              Save
            </Button>
          </div>
        </div>
        {/* </PaperWrapper> */}
        <Toaster />
      </BasicBreadcrumbs>
    </>
  );
};
export default AssesmentQuestion;
