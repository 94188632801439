import React from "react";
import TimePickers from "../../Samvenda-Admin/UI/TimePicker";
import { RadioBtn } from "./RadioBtn";
import Timer from "./Timer";

const QuestionContent = ({ question, id }) => {
  return (
    <>
      {/* radio */}
      {question.input_type === "Single Choice" && (
        <RadioBtn question={question} id={id} />
      )}
      {/* timer */}
      {question.input_type === "Time" && <Timer id={id} question={question} />}
    </>
  );
};

export default QuestionContent;
