import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "../../../../../node_modules/react-router-dom/index";
import axios from "../../../../config/axiosConfig";
import Styles from "./assessment.module.css";

export const Questions = () => {
  const { state } = useLocation();
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    axios
      .post("/enterprise/getAssissmentQuestion", { assissement_id: state?.id })
      .then((res) => {
        setQuestions(res?.data?.list);
      });
  }, []);

  console.log(questions, "___");

  let arr = [
    {
      id: "01",
      question: "What is your age?",
    },
    {
      id: "02",
      question: "Gender?",
    },
    {
      id: "03",
      question: "Years of education?",
    },
    {
      id: "04",
      question: "What is your marital status?",
    },
    {
      id: "05",
      question: "Do you smoke?",
    },
  ];

  return (
    <>
      <div>
        <div className={Styles.questionRow}>
          <p style={{ margin: "0 25px" }}>Q. No.</p>
          <p>Question</p>
        </div>
        {questions?.map((item) => {
          return (
            <div className={Styles.questionRow}>
              <p style={{ margin: "0 40px" }}>{item?.serial_number}</p>
              <p>{item?.question}</p>
            </div>
          );
        })}
      </div>
    </>
  );
};
