import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "../../../../../node_modules/react-hot-toast/dist/index";
import { Select, SelectCard } from "../../../../Component/UI/CustomInputs";
import {
  setSelectedStaff,
  setSelectedWeek,
} from "../../../../Redux/Slices/AdminRosterManegment/RosterManagement";
import { LeaveCheckBox } from "./Components/LeaveCheckBox";
import { MultiSelectCheckBox } from "./Components/MultiSelectCheckBox";
import { SelectCardRoster } from "./Components/SelectCard";
import { TimeSlot } from "./Components/TimeSlot";
import { weekDays } from "./rosterFun";
import styles from "./rosterManagement.module.css";

export const Form = ({
  selectDropDown,
  tHead,
  selectWeek,
  staffListData,
  selectStaff,
  setCheckedAll,
  checkedAll,
  checked,
  setChecked,
  timeSlotChecked,
  settimeSlotChecked,
  setAvailability,
  availability,
  errors,
  state,
  leave,
  handleLeaveChange,
  timeSlotdata,
  isBooked,
  setLeave
}) => {

  const dispatch = useDispatch();

  const singleCheckBox = (inputName) => {
    setLeave("no")
    setChecked({
      ...checked,
      [inputName]: !checked[inputName],
    });
  };

  const selectAllCheckBox = (value) => {
    setLeave("no")
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = value;
      }
      return newState;
    });
  };



  const timeSlotCheckbox = (date) => {
    if(!availability){
      toast.error("Availability is required")
      return
    }
    if(leave === "yes"){
      toast.error("You have mark it as leave")
      return
    }
    settimeSlotChecked({
      ...timeSlotChecked,
      [date]: !timeSlotChecked[date],
    });
  };



  
  const selectTimeinAvailability = timeSlotdata?.map((item) => item.id);
  const autoTimeSelect = (arr, bool) => {
    return arr.reduce(
      (i, k) => ({
        ...i,
        [k]: bool,
      }),
      {}
    );
  };

  const setTimeSlotAvail = (availability) => {
    switch (availability) {
      case "0":
        settimeSlotChecked(autoTimeSelect(selectTimeinAvailability, true));

        break;
      case "1":
        settimeSlotChecked(
          autoTimeSelect(selectTimeinAvailability.slice(0, 5), true)
        );
        break;
      case "2":
        settimeSlotChecked(
          autoTimeSelect(selectTimeinAvailability.slice(-5), true)
        );
        break;

      default:
        settimeSlotChecked(autoTimeSelect(selectTimeinAvailability, false));
        break;
    }
  };

  useEffect(() => {
    dispatch(setSelectedStaff(state?.select));

  }, []);


 

// console.log(state?.timeSlotsBooked, 'timeSlotsBookeddate')
// console.log(tHead, 'tHead')

// console.log(weekArr, 'weekArr')







  

  // useEffect(() => {
  //  setChecked(prev => {
  // return  {
  //   ...prev,
  //   ...obj
  // }
  //  });
  // }, [selectWeek, Object.keys(obj).length]);
  // useEffect(() => {
  //   setChecked(obj);
  //  }, [selectWeek]);


  // console.log(obj, 'obj====')


  return (
    <div className={styles.form}>
      <div className={styles.inputFeild}>
        {
          !state?.edit && 
          <div className={styles.selectStaff}>
          <p style={{ color: "#000", fontSize: "15px" }}>Select Doctor</p>
          <div style={{ width: "320px" }} className={styles.options}>
            <Select
              options={staffListData}
              value={selectStaff}
              onChange={(e) => {
                if(!state?.edit){
                  dispatch(setSelectedStaff(e.target.value))
                }
              }}
            />
          </div>
          <p style={{ color: "red", margin: "5px" }}>
            {errors?.staff_id?.toString()}
          </p>
        </div>
        }
        
        <div className={styles.selectWeek}>
          <p style={{ color: "#000", fontSize: "15px" }}>Select Week</p>
          <div style={{ width: "320px" }} className={styles.options}>
            <Select
              options={selectDropDown}
              value={selectWeek}
              onChange={(e) => {
                dispatch(setSelectedWeek(e.target.value));
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.workingDays}>
        <div className={styles.workingLabel}>Working Days</div>
        <div className={styles.checkboxComp}>
          <MultiSelectCheckBox
            singleCheckBox={singleCheckBox}
            tHead={tHead}
            selectAllCheckBox={selectAllCheckBox}
            checkedAll={checkedAll}
            checked={checked}
            weekDays={weekDays}
          />
          <p style={{ color: "red", margin: "5px" }}>
            {errors?.date?.toString()}
          </p>
        </div>
      </div>
      <div className={styles.workingDays}>
        <div className={styles.workingLabel}>Availability</div>
        <div className={styles.checkboxComp}>
          <SelectCardRoster
            labelstyle={{ width: 120 }}
            titleMargin="0 0 10px 0"
            options={["Full Day", "First Half", "Second Half", "Custom"]}
            value={availability}
            name="availability"
            onChange={(e) => {
              if(leave === "yes"){
                toast.error("You have mark it as leave")
                return
              }
              const selectAvail = e.target.value;
              setTimeSlotAvail(selectAvail);
              setAvailability(selectAvail);
            }}
            // flag={flag}
            // disabled={edit}
          />
          <p style={{ color: "red", margin: "5px" }}>
            {errors?.available_type?.toString()}
          </p>
        </div>
      </div>
      <div className={styles.workingDays}>
        <div className={styles.workingLabel}>Hours of working</div>
        <div className={styles.checkboxComp}>
          <TimeSlot
            timeSlotdata={timeSlotdata}
            timeSlotCheckbox={timeSlotCheckbox}
            timeSlotChecked={timeSlotChecked}
            isBooked={isBooked}
            availability={availability}
          />
          <p style={{ color: "red", margin: "5px" }}>
            {errors?.timeSlot?.toString()}
          </p>
        </div>
      </div>
          {
            state?.edit &&
            <div className={styles.workingDays}>
            <div className={styles.workingLabel}>Mark it as leave?</div>
            <div className={styles.checkboxComp}>
              <LeaveCheckBox 
               leave={leave}
               handleLeaveChange={handleLeaveChange}
              />
            </div>
          </div>
          }
     

      
    </div>
  );
};
