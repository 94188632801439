import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import classes from "../../../assets/css/tabs.module.css";
import { TabPanel } from "./TabPanel";
import { a11yProps } from "./TabProps";
import { Btn } from "../../../Samvenda-Patient/UI/Button";

export default function BasicTabs({
  tabcontent,
  value,
  setValue,
  allowDisable,
  btnText,
  handleClick,
  disabledBtn,
  color,
  width,
  margin,
  fullWidth,
}) {
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <div
      style={{ display: "flex", margin, width: fullWidth ? "100%" : "auto" }}
    >
      <Box sx={{ width: "100%" }}>
        <Box sx={{ bgcolor: "background.paper" }}>
          <Tabs
            className={classes.tab_container}
            style={{ width }}
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            sx={{
              "& button.Mui-selected": {
                color: color || "#00B091 ",
              },
            }}
            TabIndicatorProps={{
              style: {
                backgroundColor: color || "#00B091",
              },
            }}
          >
            {tabcontent.map((i, index) => (
              <Tab
                style={{ color: i?.error ? "red" : null }}
                label={i.label}
                {...a11yProps(index)}
                disabled={allowDisable ? !i?.disabled : false}
              />
            ))}
          </Tabs>
        </Box>
        {tabcontent.map((i, index) => (
          <TabPanel value={value} index={index}>
            {i.component}
          </TabPanel>
        ))}
      </Box>
      {btnText && (
        <Btn
          style={{ alignSelf: "flex-start" }}
          variant="contained"
          borderRadius="50px"
          bgColor="#2A2A2A"
          click={handleClick}
          disabled={disabledBtn}
        >
          {btnText}
        </Btn>
      )}
    </div>
  );
}

BasicTabs.defaultProps = {
  value: 0,
};
