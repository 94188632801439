import { createSlice } from "@reduxjs/toolkit";

const updateProfileSlice = createSlice({
  name: "update profile",
  initialState: { data: "" },
  reducers: {
    updateProfile: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    clearProfile: (state) => {
      state.data = "";
    },
  },
});

export const { updateProfile, clearProfile } = updateProfileSlice.actions;

export default updateProfileSlice.reducer;
