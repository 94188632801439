import { useState } from "react";
import { useSelector } from "react-redux";
import { addClient } from "../../../services/admin/client";
import ClientDetailsForm from "./ClientDetailsForm";
import { useNavigate } from "react-router-dom";
import BasicBreadcrumbs from "../../../Component/UI/BreadCrumb/BreadCrumbs";

export const AddClient = () => {
  const [response, setResponse] = useState();
  const { data } = useSelector((i) => i.clientDetails);
  const navigate = useNavigate();

  const handleClick = () => {
    const formData = new FormData();
    for (let key in data) formData.append(key, data[key]);

    addClient(formData).then((i) => {
      setResponse(i?.data);
      if (i?.data?.status) {
        navigate("/patient");
      }
    });
  };

  const links = [
    {
      path: "/Patients",
      linkText: "Clients",
    },
  ];

  return (
    <BasicBreadcrumbs link={links} text="Add User">
      <ClientDetailsForm err={response?.error} click={handleClick} />
    </BasicBreadcrumbs>
  );
};
