import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Star } from "./Star";

const StarRating = ({ setRatingStars, ratingStars, hover, setHover }) => {
  return (
    <div style={{ display: "flex" }}>
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <>
            <div
              key={index}
              onClick={() => setRatingStars(index)}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(ratingStars)}
              style={{ cursor: "pointer" }}
            >
              {index <= (hover || ratingStars) ? (
                <Star color="#FFB800" />
              ) : (
                <Star />
              )}
            </div>
          </>
        );
      })}
    </div>
  );
};

export default StarRating;
