import {
  Input,
  InputWithIcon,
  CheckBox,
  Datepicker2,
  Select,
  SelectWithId,
} from "../../Component/UI/CustomInputs";
import { Eye } from "../../assets/icons/Eye";
import { Btn } from "../UI/Button";

import styles from "../../assets/css/PatientAuth/patientSignupp.module.css";
import classes from "../../assets/css/signup.module.css";
import LoginImage from "../../assets/icons/LoginImage.svg";
import { useContext, useState } from "react";
import AuthContext from "../../Auth/AuthContext";

import { Link, useNavigate } from "react-router-dom";
import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";
import { useDispatch, useSelector } from "react-redux";
import AssessmentStyles from "../../assets/css/assessment.module.css";
import logo from "../../assets/icons/icon/logo.svg";
import { StringDtoY } from "../../config/formattedDate";
import { useEffect } from "react";
import {
  qaStore,
  setAssessment_for,
  setNumberOfQues,
} from "../../Redux/Reducers/PatientReducers/qa_storage/qa_storage";
import { passwordValidate } from "../../config/passwordValidate";
import { CircularProgress } from "../../../node_modules/@mui/material/index";
import { Helmet } from "react-helmet";
import toast, {
  Toaster,
} from "../../../node_modules/react-hot-toast/dist/index";
import CountryCodes from "../../assets/common/countryCode.json";
export const PatientSignup = () => {
  const ctx = useContext(AuthContext);
  const {
    qaData,
    category_id,
    numberOfQues,
    Timetaken,
    assessment_for,
    relationName,
    relation,
  } = useSelector((state) => state.qa_storage);

  const { location } = useSelector((i) => i.location);
  console.log(CountryCodes, "CountryCodes");

  const feedbackSelectdOption =
    location?.split("?")?.[2]?.slice(0, 1)?.toUpperCase() +
    location?.split("?")?.[2]?.slice(1);

  const dispatch = useDispatch();
  const rgx =
    /^(?!.*(\d)\1{9})(?!.*1234567890)(?!^00)(\([0-9]{3}\)\s*|[0-9]{3}[-/.]?)?[0-9]{3}[-/.]?[0-9]{4}$/;
  // const user = useContext(AuthContext);
  const [auth, setAuth] = useState({
    assessment_result: qaData,
    assessment_id: category_id,
    dob: StringDtoY(new Date(1970, 0, 1)),
    status: "inprogress",
    percentage: (qaData.length / Number(numberOfQues)) * 100,
    time_taken: Timetaken,
    countryCode: "+91",
  });
  const [check, setCheck] = useState(false);
  const [check2, setCheck2] = useState(true);
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState({});
  const [passwordErr, setPasswordErr] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);

  const navigate = useNavigate();

  const { REGISTERCUSTOMER } = API;

  const ProgressStatus =
    Number(numberOfQues) === qaData.length ? "completed" : "inprogress";

  useEffect(() => {
    setAuth({
      ...auth,
      status: ProgressStatus,
      assessment_for,
      relation,
      relation_name: relationName,
    });
  }, []);

  const is_loggedin = ctx?.loginUser?.is_loggedin;

  useEffect(() => {
    is_loggedin && navigate("/dashboard");
  }, []);
  console.log(auth, "auth");

  const createAccount = async (e) => {
    e.preventDefault();
    toast.remove();
    const isNum = rgx.test(auth?.mobile);
    if (!auth?.mobile) {
      toast.error("Mobile no required!");
      return;
    }
    if (!isNum) {
      toast.error("Invalid mobile number!");
      return;
    }
    if (passwordErr === "") {
      setErrMsg({});
      setDisableBtn(true);
      const payload = {
        ...auth,
        mobile: `${auth?.countryCode}-${auth?.mobile}`,
      };
      await axios
        .post(REGISTERCUSTOMER, payload)
        .then((res) => {
          setDisableBtn(false);
          if (res.status === 200) {
            setSuccessMsg(res?.data?.messege);
            if (res?.data?.data?.token) {
              ctx.auth({
                ...res.data.data,
                role: "client",
                is_loggedin: true,
                expireDate: new Date().getTime() + 24 * 60 * 60 * 1000,
              });
              dispatch(qaStore([]));
              dispatch(setNumberOfQues("number"));
              dispatch(setAssessment_for("Myself"));
              if (auth.status === "completed") {
                navigate("/patient/assessment/results", {
                  state: { assessment_for, category_id },
                });
              } else if (location?.split("?")?.[1] === "feedback") {
                navigate("/patient/feedback", {
                  state: {
                    feedback_type: feedbackSelectdOption,
                  },
                });
              } else if (location?.split("?")?.[1] === "testimonial") {
                navigate("/patient/testimonial", {
                  state: {
                    feedback_type: feedbackSelectdOption,
                  },
                });
              } else if (location?.split("?")?.[1] === "Assessment") {
                navigate("/patient/Assessment", {
                  state: {
                    feedback_type: feedbackSelectdOption,
                  },
                });
              } else if (location?.split("?")?.[1] === "clientselfcareplan") {
                navigate("/clientselfcareplan", {
                  state: {
                    feedback_type: feedbackSelectdOption,
                  },
                });
              } else if (location?.split("?")?.[1] === "progresstrends") {
                navigate("/patient/progresstrends", {
                  state: {
                    feedback_type: feedbackSelectdOption,
                  },
                });
              } else {
                navigate("/patient/Assessment");
              }
            }
          }
        })
        .catch((err) => {
          setDisableBtn(false);
          const eror = err?.response?.data?.error;
          if (eror) setErrMsg(eror);
        });
    } else {
      setErrMsg({ errMsg, password: passwordErr });
    }
  };

  const validate = (e) => {
    const check = passwordValidate(e.target.value);
    if (check !== true)
      setPasswordErr(
        "Password should be atleast of 8 characters. Must contain CAPITAL letter, Special character (@,$...) & number"
      );
    else {
      setErrMsg({});
      setPasswordErr("");
    }
  };

  const domain = window.location.host;
  const canonicalUrl = `https://${domain}/user/signup`;

  return (
    <>
      <Toaster />
      <Helmet>
        <meta charSet="utf-8" />
        <title>User - Sign Up or Create an Account - Samvedna Map</title>
        <meta
          name="title"
          content="User - Sign Up or Create an Account - Samvedna Map"
        />
        <meta
          name="description"
          content="Create an account to schedule a  self assessment test, view scores, update your profile and more. Signup / Create an Account · Learn more about the mental health tests."
        />
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div>
        <Link to="/">
          <img className={AssessmentStyles.logo} src={logo} alt="logo" />
        </Link>
        <div
          className={classes.signup_container}
          style={{ background: `url(${LoginImage})` }}
        >
          <form
            className={classes.signup_subcontainer}
            /*style={{top:"40%"}}*/ onSubmit={createAccount}
          >
            <div className={styles.patientSignup}>
              <h1 style={{ margin: "0 10px" }}>Create Account</h1>

              <h3
                style={{
                  marginTop: "10px",
                  color: "green",
                  fontWeight: "normal",
                }}
              >
                {successMsg ? successMsg : null}
              </h3>
              <div className={styles.inputs}>
                <Input
                  type="text"
                  label="Full Name"
                  labelSize={14}
                  placeholder="Full Name"
                  error={errMsg?.name}
                  onChange={(e) => setAuth({ ...auth, name: e.target.value })}
                  margin="0 10px"
                  width="320px"
                />
                <Input
                  type="email"
                  label="Email Address"
                  error={errMsg?.email}
                  labelSize={14}
                  placeholder="Email Address"
                  id={styles.sam}
                  onChange={(e) => setAuth({ ...auth, email: e.target.value })}
                  margin="0 10px"
                />
                <div className={styles.mobileNoSec}>
                  <SelectWithId
                    labelSize={14}
                    options={CountryCodes}
                    width="120px"
                    label="Country Code"
                    value={auth.countryCode}
                    onChange={(e) =>
                      setAuth({ ...auth, countryCode: e.target.value })
                    }
                  />
                  <Input
                    type="text"
                    label="Mobile Number"
                    labelSize={14}
                    error={errMsg?.mobile}
                    onChange={(e) => {
                      setAuth({ ...auth, mobile: e.target.value });
                    }}
                    margin="0 10px"
                  />
                </div>

                <Datepicker2
                  error={errMsg?.dob}
                  label="Date of Birth"
                  onChange={(value) => {
                    setAuth({ ...auth, dob: StringDtoY(value) });
                  }}
                  editable
                  defaultDate={new Date(1970, 0, 1)}
                />
              </div>
              <div>
                <InputWithIcon
                  type="password"
                  label="Password"
                  error={errMsg?.password}
                  labelSize={14}
                  placeholder="Enter Password"
                  icon={Eye}
                  display="flex"
                  onChange={(e) => {
                    validate(e);
                    setAuth({ ...auth, password: e.target.value });
                  }}
                  margin="0 10px"
                />
                {/* <p style={{color: "red", fontSize: "14px", marginTop: "2px" }}>
                {passwordErr}
              </p> */}
              </div>

              <CheckBox
                boldness={300}
                fontSize={14}
                click={(v) => setCheck(v)}
                check={check}
                value={
                  <span>
                    I agree to all the{" "}
                    <a
                      href="https://www.samvednacare.com/term-and-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "#7A51AE" }}
                    >
                      Terms of Service
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.samvednacare.com/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "#7A51AE" }}
                    >
                      Privacy Policy
                    </a>
                  </span>
                }
              />
              <CheckBox
                boldness={300}
                fontSize={14}
                click={(v) => setCheck2(v)}
                check={check2}
                value="Yes, I want to receive Samvedna Care Offers, Care Tips and Emails."
              />
              <div>
                <Btn
                  width={320}
                  height={48}
                  fontSize={18}
                  variant="contained"
                  disabled={!check}
                  click={createAccount}
                  margin="10px 10px"
                >
                  {disableBtn ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    "Create Account"
                  )}
                </Btn>
                {disableBtn && (
                  <p> Please wait while your account is being setup </p>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
