import React, { useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import classess from "../../../../assets/css/assessment/range.module.css";

import {
  selRange,
  selAge,
  selWeight,
  selHeight,
  setSubquestion,
} from "../../../../Redux/Reducers/PatientReducers/qa_storage/qa_storage";
import { useDispatch, useSelector } from "react-redux";

export const Range = (props) => {
  const dispatch = useDispatch();
  const { range, subquestion } = useSelector((state) => state.qa_storage);
  // const { question } = props;
  const [stateRange, setStateRange] = useState(40);

  const [feet, setFeet] = useState(null);

  const tag = props.question.tag;
  const min = props.question.minimum;
  const max = props.question.maximum;

  useEffect(() => {
    if (props.subquestion) {
      setStateRange(subquestion);
    } else {
      setStateRange(range);
    }

    if (tag?.toLowerCase().includes("height")) {
      setFeet((range * 0.0328084).toFixed(2));
    }
  }, [range, subquestion]);

  const handleChange = (e) => {
    let getRange = Number(e.target.value);
    if (e.target.value > max) {
      getRange = max;
    }
    setStateRange(getRange);

    if (props.subquestion) {
      dispatch(setSubquestion(getRange));
    } else {
      dispatch(selRange(getRange));
      if (props.question.question.toLowerCase().includes("age")) {
        dispatch(selAge(getRange));
      }
      if (props.question.question.toLowerCase().includes("weight")) {
        dispatch(selWeight(getRange));
      }
      if (props.question.question.toLowerCase().includes("height")) {
        dispatch(selHeight(getRange));
      }
    }
  };

  return (
    <>
      <div>
        {/* {labels.map((label) => {
          return ( */}
        <div>
          <div
            style={{
              width: "320px",
              margin: "30px 0",
              height: "48px",
              alignItems: "center",
            }}
          >
            <Slider
              aria-label="Volume"
              onChange={(e) => handleChange(e)}
              value={stateRange}
              name={tag}
              min={min}
              max={max}
              style={{ color: "orange", marginRight: "15px" }}
              sx={{
                "& .MuiSlider-thumb": { height: 40, width: 40 },
                "& .MuiSlider-valueLabel": {
                  backgroundColor: "transparent",
                  color: "#111",
                  fontSize: "18px",
                },
              }}
              valueLabelDisplay="auto"
            />
            <div
              style={{
                width: "320px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>{min}</span>
              <span>{max}</span>
            </div>
          </div>
          <div
            style={{ width: "320px", margin: "30px 0" }}
            className={classess.rangeInput}
          >
            <span>
              {tag} {Boolean(feet) && `(${feet} feet)`}
            </span>
            <input
              type="number"
              name={tag}
              value={stateRange}
              onChange={(e) => handleChange(e)}
              onWheel={(e) => e.target.blur()}
            />
            {/* {stateRange < min && (
              <div>{`Please choose ${label} between ${min}-${max} `}</div>
            )} */}
          </div>
        </div>
        {/* );
        })} */}
      </div>
    </>
  );
};
