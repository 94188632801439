import React from "react";
import { CustomizedAccordions } from "../../../Component/UI/Accordion/CustomizedAccordions";

export const Faq = () => {
  const data = [
    {
      id: 1,
      Title:
        "1. What kind of mental health support services does Samvedna provide?",
      content:
        "We provide services at an organizational level which help employers proactively take care of employee mental wellbeing. We also provide specific services for employees like 1:1 wellness coaching,  group workshops and individual counselling.",
    },
    {
      id: 2,
      Title: "2. What is employee mental wellbeing index ?",
      content:
        "A mental health index provides dip stick overview for the management to understand how employees are viewing their mental health. This can help plan for specific mental health programs depending on the pain points/areas where employees will benefit most from additional support.",
    },
    {
      id: 3,
      Title:
        "3. Does the employee mental health program address individual concerns ?",
      content:
        "Yes,  Samvedna provides a virtual counselling helpdesk for employees to dial in and seek support for their personal mental health issues.",
    },
    {
      id: 4,
      Title:
        "4. Does Samvedna provide mental health services which are inclusive ?",
      content:
        "Yes, Samvedna programs are designed to ensure inclusivity for all employees. We also have  special programs designed for Working Women dealing with caregiver stress, LGBTQ support, people dealing with disabilities.",
    },
    {
      id: 5,
      Title: "5. Does Samvedna conduct workshops on mental health ?",
      content:
        "Yes Samvedna conducts group workshops/programs for employees dealing with mental health concerns, caregiver stress, lifestyle changes.",
    },
    {
      id: 6,
      Title:
        "6. Does Samvedna have any specific programs / workshops for employee’s family members ?",
      content:
        "Yes, Samvedna has expertise in working with employees and their families. We can provide group programs for  Active Ageing and (Early Stage Dementia) Mild Cognitive Impairment for older adults, these can be offered for employee families.",
    },
    {
      id: 7,
      Title: "7. What is the mode of delivery of the services ?",
      content: "Our services are available online as well as at location.",
    },
  ];

  return (
    <>
      {data.map((item, index) => {
        return (
          <div style={{ margin: "10px 0px" }} key={item.id}>
            <CustomizedAccordions heading={item.Title} content={item.content} />
          </div>
        );
      })}
    </>
  );
};
