import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, CircularProgress } from "@mui/material";
import { Buttons } from "../Button/Button";
import classes from "../../../assets/css/PatientUI/servicecard.module.css";
import Modal from "./Modal";
import { useState } from "react";
import bookServiceImg from "../../../assets/icons/bookServiceImg.svg";
import ServicePopup from "./ServicePopup";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "../../../config/axiosConfig";

import { counsellor } from "./speaktocounsellorid";
import ModalSuccess from "./ModalSuccess";
import ConsentPopup from "./ConsentPopup";
import { useAuth } from "../../../Auth/AuthProvider";
import { getProfile } from "../../../services/patient/profile";
import { toast } from "../../../../node_modules/react-hot-toast/dist/index";
import moment from "moment";
export const ServicesCards = ({
  data,
  status,
  select,
  enterpriseId,
  selectEnterPrise,
  allDataList,
}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenConstent, setIsOpenConstent] = useState({
    open: false,
    consent: false,
  });
  const [userProfile, setUserProfile] = React.useState({ country: "105" });

  const [isOpenSuccess, setIsOpenSuccess] = useState({ open: false });
  const [isLoading, setIsLoading] = useState(false);

  // const is_consent = JSON.parse(sessionStorage.getItem("is_consent"));
  const [popupAction, setPopupAction] = useState({
    open: false,
    action: () => navigate("/patient/Assessment"),
  });

  const checkCond = parseInt(data?.consume) === parseInt(data?.number_session);

  const checkDataCond = allDataList?.filter((i) => i?.id === data?.id);

  const checkData = checkDataCond?.some((i) => i?.booked === true);

  // console.log(checkCond, 'checkCond--')

  // new code start
  const checkSameData = allDataList?.filter((i) => i?.id === data?.id);
  console.log(checkSameData, "checkSameData---");
  const getBookedTrue = checkSameData?.find((i) => i?.booked === true);
  const addThreeMonth = moment(getBookedTrue?.subscription_expiry)
    .add(3, "months")
    .format("YYYY-MM-DD");
  const approveDate = moment(data?.subscription_approve).format("YYYY-MM-DD");
  const checkDateCond = data?.booked
    ? false
    : getBookedTrue !== undefined
    ? moment(approveDate).isBefore(addThreeMonth)
    : false;

  console.log(addThreeMonth, approveDate, getBookedTrue, "rajeev");
  // console.log(checkDateCond, 'checkDateCond--')

  // const addThreeMonth = getBookedTrue
  //     ?.map((i) => {
  //       const bookedDate = moment(i?.subscription_expiry).add(3, "month").format("YYYY-MM-DD");
  //       return bookedDate

  //     })
  //     .filter((i) => i !== null);

  // const getApproveDate = getBookedFalse
  //     ?.map((i) => {
  //       const bookedDate = moment(i?.subscription_approve).format("YYYY-MM-DD");
  //       return bookedDate

  //     })
  //     .filter((i) => i !== null);

  // const compareDate =  addThreeMonth?.map((i) => moment(i).isBefore(getApproveDate[i]))

  // console.log(compareDate,getApproveDate,addThreeMonth, 'compareDate')

  const handleClick = () => {
    // if(is_consent === 0){
    //   setIsOpenConstent(true)
    // }
    if (selectEnterPrise !== "0") {
      if (parseInt(data?.consume) === parseInt(data?.number_session)) {
        toast.error(
          "All sessions have been exhausted. Please reach out to your organisation for renewal/extension"
        );
        return;
      } else {
        if (select !== 0) {
          if (
            data?.id === counsellor?.[data?.category] &&
            status !== "completed"
          ) {
            setPopupAction({ ...popupAction, open: true });
          } else {
            setIsOpen(true);
          }
        } else {
          setIsOpen(true);
        }
      }
      return;
    }
    if (select !== 0) {
      if (data?.id === counsellor?.[data?.category] && status !== "completed") {
        setPopupAction({ ...popupAction, open: true });
      } else {
        setIsOpen(true);
      }
    } else {
      setIsOpen(true);
    }
  };

  return (
    <>
      <ServicePopup
        popupAction={popupAction}
        setPopupAction={setPopupAction}
        title="You need to take a Self Assessment before Speaking To a Counsellor"
      />
      <div
        className={`${classes.serviceCardContainer} ${
          checkData || checkCond || data?.booked
            ? checkDateCond
              ? ""
              : classes.serviceBooked
            : ""
        }`}
      >
        <Card
          style={{
            borderRadius: "20px",
            paddingBotttom: "300px",
            height: "100%",
          }}
        >
          <CardActionArea>
            <div
              style={{
                height: "140px",
                backgroundColor: "#fdf5f4",
              }}
            >
              <div
                style={{
                  // backgroundImage: `url(${bookServiceImg})`,
                  width: "72px",
                  height: "83px",
                  margin: "auto",
                  backgroundPosition: "center",
                }}
              >
                <img src={bookServiceImg} style={{ marginTop: "25px" }} />
                {/* <CardMedia
    <div className={classes.serviceCardContainer}>
      <Card
        style={{
          borderRadius: "20px",
          paddingBotttom: "300px",
          height: "100%",
        }}
      >
        <CardActionArea>
          <div
            style={{
              height: "140px",
              backgroundColor: "#fdf5f4",
            }}
          >
            <div
              style={{
                // backgroundImage: `url(${bookServiceImg})`,
                width: "72px",
                height: "83px",
                margin: "auto",
                backgroundPosition: "center",
              }}
            >
              <img src={bookServiceImg} style={{ marginTop: "25px" }} />
              {/* <CardMedia
              // style={{
              component="img"
              image={bookServiceImg}
              //}}
            /> */}
              </div>
            </div>

            <div
              style={{
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "175px",
              }}
            >
              <div>
                <Typography
                  gutterBottom
                  variant="h2"
                  component="div"
                  style={{
                    display: "flex",
                    textAlign: "left",
                    fontSize: "18px",
                    fontWeight: "400",
                    minHeight: "50px",
                  }}
                >
                  {data.name}
                </Typography>

                <div
                  style={{
                    display: "flex",
                    textAlign: "left",
                    fontSize: "18px",
                    fontWeight: "400",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{
                      display: "flex",
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    {data.amount === "0" ? "Free" : `Rs. ${data.amount}`}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{
                      display: "flex",
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    {data?.type_of_service === "0" ? (
                      <span
                        style={{
                          backgroundColor: "#F19204",
                          color: "#ffffff",
                          width: "78px",
                          height: "24px",
                          padding: "5px",
                          fontSize: "12px",
                          textAlign: "center",
                          borderRadius: "20px",
                          textTransform: "uppercase",
                        }}
                      >
                        Offline
                      </span>
                    ) : data?.type_of_service === "1" ? (
                      <span
                        style={{
                          backgroundColor: "#18BA4B",
                          color: "#ffffff",
                          width: "78px",
                          height: "24px",
                          fontSize: "12px",
                          padding: "5px",
                          textAlign: "center",
                          borderRadius: "20px",
                          textTransform: "uppercase",
                        }}
                      >
                        Online
                      </span>
                    ) : data?.type_of_service === "2" ? (
                      <span
                        style={{
                          backgroundColor: "#047FF1",
                          color: "#ffffff",
                          width: "78px",
                          height: "24px",
                          padding: "5px",
                          fontSize: "12px",
                          textAlign: "center",
                          borderRadius: "20px",
                          textTransform: "uppercase",
                        }}
                      >
                        Hybrid
                      </span>
                    ) : (
                      ""
                    )}
                  </Typography>
                </div>
              </div>

              {/* {!data?.booked ? ( */}
              <Buttons
                className={
                  checkData || checkCond || data?.booked
                    ? checkDateCond
                      ? ""
                      : classes.disabledBtn
                    : ""
                }
                click={handleClick}
                disabled={
                  checkDateCond ? false : checkData || checkCond || data?.booked
                }
                name={
                  selectEnterPrise === "0"
                    ? "Book Now"
                    : checkDateCond
                    ? "Avail Now"
                    : data?.booked
                    ? "Availed"
                    : checkData
                    ? "Already Availed"
                    : "Avail Now"
                }
                style={{
                  backgroundColor:
                    checkData || checkCond || data?.booked
                      ? checkDateCond
                        ? "#7A51AE"
                        : "#b1b1b1"
                      : "#7A51AE",
                  color:
                    checkData || checkCond || data?.booked
                      ? checkDateCond
                        ? "#ffffff"
                        : "#000000"
                      : "#ffffff",
                  marginTop: "10px",
                  borderRadius: "8px",
                  textTransform: "none",
                }}
              />
              {/* ) : (
              <Buttons
                name="Booked"
                disabled={true}
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.12)",

                  marginTop: "10px",
                  borderRadius: "8px",
                }}
              />
            )} */}

              {isOpenConstent.open && (
                <ConsentPopup
                  setIsOpenConstent={setIsOpenConstent}
                  isOpenConstent={isOpenConstent}
                  setIsOpen={setIsOpen}
                  isLoading={isLoading}
                />
              )}

              {isOpen && (
                <Modal
                  setIsOpenSuccess={setIsOpenSuccess}
                  setIsOpen={setIsOpen}
                  modalData={data}
                  counsellorId={counsellor}
                  // is_consent={is_consent}
                  isOpenConstent={isOpenConstent}
                  setIsOpenConstent={setIsOpenConstent}
                  setIsLoading={setIsLoading}
                  selectEnterPrise={selectEnterPrise}
                />
              )}
              {isOpenSuccess.open && (
                <ModalSuccess
                  setIsOpenSuccess={setIsOpenSuccess}
                  isOpenSuccess={isOpenSuccess}
                />
              )}
            </div>
          </CardActionArea>
        </Card>
      </div>
    </>
  );
};
