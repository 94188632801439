import { ReportsCard } from "../../UI/ReportsCard";
import styles from "../../../assets/css/PatientDashboard/reports.modules.css";
import { Btn } from "../../UI/Button";
import { useEffect } from "react";
import { useState } from "react";
import { getUploadedReports } from "../../../services/patient/reports";
import moment from "../../../../node_modules/moment/moment";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";
import { EmptyReports } from "../../UI/EmptyReports";

export const UploadedAssessments = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [enterPriseList, setEnterPriseList] = useState();
  useEffect(() => {
    getSelfAssessments();
  }, []);
  const getSelfAssessments = () => {
    setIsLoading(true);
    getUploadedReports().then((res) => {
      console.log(res, "res---");
      setIsLoading(false);
      setEnterPriseList(res);
    });
  };
  return (
    <>
      {/* <div
        style={{ textAlign: "right", marginBottom: "20px", marginTop: "10px" }}
        className={styles.addReport}
      >
        <Btn>Add Report</Btn>
      </div> */}
      {!isLoading ? (
        <div
          className={styles.session}
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            columnGap: "20px",
          }}
        >
          {enterPriseList?.length ? (
            enterPriseList?.map((item) => (
              <ReportsCard
                status={item?.report_type}
                name={item?.report_name}
                date={item?.report_date}
                pdfLink={item?.report_url}
              />
            ))
          ) : (
            <EmptyReports />
          )}
        </div>
      ) : (
        <CircularProgress size={28} />
      )}
    </>
  );
};
