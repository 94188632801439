import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "../../../../../node_modules/@mui/material/index";
import moment from "../../../../../node_modules/moment/moment";
import {
  toast,
  Toaster,
} from "../../../../../node_modules/react-hot-toast/dist/index";
import {
  useLocation,
  useNavigate,
} from "../../../../../node_modules/react-router-dom/index";
import { SimpleHeader } from "../../../../Hooks/SimpleHeader";
import { setSelectedWeek } from "../../../../Redux/Slices/AdminRosterManegment/RosterManagement";
import { Btn } from "../../../../Samvenda-Patient/UI/Button";
import {
  addRoster,
  gettimeSlots,
  staffListRoster,
} from "../../../../services/admin/roster";
import { AddRosterSuccesses, AddRosteSuccesses } from "./Components/Popup";
import RosterAddIcon from "../../../../assets/images/rosterAdded.svg";
import { Form } from "./Form";
import {
  convertedMonthArr,
  filterWeek,
  getDay,
  getDropDownlist,
  selectedMonthArr,
  weekDays,
} from "./rosterFun";
import styles from "./rosterManagement.module.css";

export const AddRoster = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const [staffListData, setStaffListData] = useState();
  const [checkedAll, setCheckedAll] = useState(false);
  const obj = {};
  const [timeSlotChecked, settimeSlotChecked] = useState({});
  const [availability, setAvailability] = useState("");

  const staffDropDown = staffListData?.map(({ id, name }) => {
    return {
      id: id,
      value: name,
    };
  });

  const links = [
    {
      path: "/Samvedna/roster",
      linkText: "Roster Management",
    },
  ];

  useEffect(() => {
    staffListRoster()
      .then((res) => setStaffListData(res.data.list))
      .catch((err) => console.log(err));
  }, []);

  const { selectedDate, toggle, year, fullDate } = useSelector(
    (i) => i.DateFilterSlice
  );
  const { selectWeek, selectStaff } = useSelector((i) => i.WeekFilterSlice);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [staffDataInRoster, setStaffDataInRoster] = useState();

  const daysInSelectedMonth = new Date(
    fullDate.getFullYear(),
    fullDate.getMonth() + 1,
    0
  ).getDate();

  // useEffect(() => {
  //  setIsLoadingData(true);
  //   var data = {
  //     staff_id: selectStaff,
  //     month: moment(fullDate).format("MM"),
  //     year: moment(fullDate).format("YYYY"),
  //   };
  //   gettimeSlots(data)
  //     .then((res) => {
  //      setIsLoadingData(false);
  //      setStaffDataInRoster(res.data.list);
  //     })
  //     .catch((err) => console.log(err));
  // }, [selectStaff, fullDate]);

  //week Dropdown

  //static Time Slot
  const timeSlotdata = [
    {
      id: "09",
      value: "09:00-10:00",
    },
    {
      id: "10",
      value: "10:00-11:00",
    },
    {
      id: "11",
      value: "11:00-12:00",
    },
    {
      id: "12",
      value: "12:00-01:00",
    },
    {
      id: "13",
      value: "01:00-02:00",
    },
    {
      id: "14",
      value: "02:00-03:00",
    },
    {
      id: "15",
      value: "03:00-04:00",
    },
    {
      id: "16",
      value: "04:00-05:00",
    },
    {
      id: "17",
      value: "05:00-06:00",
    },
    {
      id: "18",
      value: "06:00-07:00",
    },
  ];

  const startDay = getDay(1, fullDate);
  const endDay = getDay(daysInSelectedMonth, fullDate);

  const daysInPrevMonth = new Date(
    fullDate.getFullYear(),
    fullDate.getMonth(),
    0
  ).getDate();
  const daysInNextMonth = new Date(
    fullDate.getFullYear(),
    fullDate.getMonth() + 2,
    0
  ).getDate();

  const totalWeekDays = 6;
  const startDayIndex = weekDays?.findIndex((e) => e === startDay);
  const endDayIndex = totalWeekDays - weekDays?.findIndex((e) => e === endDay);
  const [dateArr, setDateArr] = useState([]);

  const prevMonthDaysArr =
    startDayIndex > 0
      ? selectedMonthArr(daysInPrevMonth).slice(-startDayIndex)
      : [];
  const nextMonthDaysArr =
    endDayIndex > 0
      ? selectedMonthArr(daysInNextMonth).slice(0, endDayIndex)
      : [];

  useEffect(() => {
    dispatch(setSelectedWeek("1"));
    return () => {
      dispatch(setSelectedWeek("1"));
    };
  }, [fullDate]);

  // add and edit tHead
  const currentMonthArr = selectedMonthArr(daysInSelectedMonth);
  const checkMonth = moment(fullDate).format("MM") === moment().format("MM");
  const currentDate = moment().format("DD");
  const prevMonthArrData = convertedMonthArr(prevMonthDaysArr, true);
  const nextMonthArrData = convertedMonthArr(nextMonthDaysArr, true);
  const currentMonthArrData = convertedMonthArr(
    currentMonthArr,
    false,
    checkMonth,
    currentDate
  );

  const finalMonthArr = [
    ...prevMonthArrData,
    ...currentMonthArrData,
    ...nextMonthArrData,
  ];
  const tHead = filterWeek(finalMonthArr, selectWeek)?.map((i) => i);

  const checkedArr = tHead?.filter((i) => !i.disabled);
  for (const k of checkedArr) {
    obj[k?.date] = false;
  }

  const selectTimeinAvailability = timeSlotdata?.map((item) => item.id);
  const autoTimeSelect = (arr, bool) => {
    return arr.reduce(
      (i, k) => ({
        ...i,
        [k]: bool,
      }),
      {}
    );
  };

  const [checked, setChecked] = useState();
  useEffect(() => {
    setChecked(obj);
  }, [selectWeek]);

  // clear timeSlot and availability sec

  useEffect(() => {
    setCheckedAll(false);
    settimeSlotChecked(autoTimeSelect(selectTimeinAvailability, false));
    setAvailability("");
  }, [selectWeek]);

  const dateArray = [];
  useEffect(() => {
    for (const value in checked) {
      if (checked[value]) {
        dateArray.push(value);
      }
    }
    setDateArr(dateArray);
  }, [checked]);

  useEffect(() => {
    for (const value in checked) {
      if (checked[value] && dateArray?.length === 7) {
        setCheckedAll(true);
      } else {
        setCheckedAll(false);
      }
    }
  }, [checked]);

  useEffect(() => {
    if (state?.edit) {
      const obj = {};
      //header date select unselect in EDIT case
      const timeSlotsBookeddate = [
        ...new Set(state?.timeSlotsBooked?.map((i) => i.date)),
      ];
      for (const k of checkedArr) {
        if (timeSlotsBookeddate?.includes(k?.date?.toString().padStart(2, 0))) {
          obj[k?.date] = true;
        } else {
          obj[k?.date] = false;
        }
      }
      setChecked(obj);
    }
  }, [state, selectWeek]);

  const [isBookedTime, setIsBookedTime] = useState([]);

  useEffect(() => {
    //show slected selected data in Edit case
    if (state?.edit) {
      const objTime = {};
      const weekDaysArr = tHead
        ?.map((i) => {
          if (!i?.disabled) {
            return i?.date?.toString().padStart(2, 0);
          }
        })
        .filter((i) => i !== undefined);
      const dateArrFilter = dateArr?.map((i) => i?.padStart(2, 0));
      const isBookedFilter = state?.timeSlotsBooked?.filter((i) =>
        dateArrFilter?.includes(i?.date)
      );

      const isBookedTimeSlot = state?.timeSlotsBooked
        ?.filter((i) => i?.is_booked && dateArrFilter?.includes(i?.date))
        ?.map((k) => k?.timeId);
      const dateLength = Object.values?.(checked || {})?.filter(
        (i) => i
      )?.length;
      if (isBookedFilter?.length) {
        for (const n of isBookedFilter || []) {
          if (dateLength === 1) {
            console.log(weekDaysArr?.includes(n?.date.toString()), "Cond---");
            if (weekDaysArr?.includes(n?.date.toString())) {
              objTime[n?.timeId] = true;
              setAvailability(isBookedFilter?.at(0)?.available_type);
              setIsBookedTime(isBookedTimeSlot);
            } else {
              objTime[n?.timeId] = false;
              setAvailability("");
              setIsBookedTime("");
            }
          } else {
            objTime[n?.timeId] = false;
            setAvailability("");
            setIsBookedTime("");
          }
        }
      } else {
        setAvailability("");
        setIsBookedTime("");
      }

      settimeSlotChecked(objTime);
    }
  }, [checked, dateArr]);

  const [timeSlotArr, setTimeSlotArr] = useState([]);
  const [errors, setErrors] = useState();
  const [isLoading, setLoading] = useState(false);

  const timeSlotArray = [];
  useEffect(() => {
    for (const value in timeSlotChecked) {
      if (timeSlotChecked[value]) {
        timeSlotArray.push(value);
      }
    }
    setTimeSlotArr(timeSlotArray);
  }, [timeSlotChecked]);

  const [popupOpen, setPopupOpen] = useState({
    open: false,
  });

  ///Leave Sec start
  const [leave, setLeave] = React.useState("no");
  const [leaveConfirmOpen, setLeaveConfirmOpen] = React.useState({
    open: false,
  });

  const handleLeaveChange = (event) => {
    setLeave(event.target.value);
    if (event.target.value === "yes") {
      setLeaveConfirmOpen({
        open: true,
        body: { data: "Are you sure want to mark as leave?" },
      });
    }
  };

  const onChangeLeave = () => {
    setAvailability("");
    settimeSlotChecked(autoTimeSelect(selectTimeinAvailability, false));
    setLeaveConfirmOpen(false);
  };

  ///Leave Sec end
  const addRosterTime = () => {
    if (state?.edit) {
      const dateArrFilter = dateArr?.map((i) => i?.padStart(2, 0));
      const isBooked = state?.timeSlotsBooked
        ?.filter((i) => i?.is_booked && dateArrFilter?.includes(i?.date))
        ?.map((k) => k?.timeId);
      const isTimeBooked = timeSlotArr?.some((j) => isBooked?.includes(j));
      if (!isTimeBooked) {
        if (isBooked?.length && leave === "yes") {
          return toast.error(
            "Your booking already schedule, please reschedule your booking."
          );
        } else if (isBooked?.length && leave === "no") {
          return toast.error(
            "Your booking already schedule, please reschedule your booking."
          );
        }
      }
    }

    setLoading(true);
    const data = {
      staff_id: selectStaff,
      week: selectWeek,
      date: dateArr?.map((k) =>
        moment(fullDate).set("date", k).format("YYYY-MM-DD")
      ),
      timeSlot: timeSlotArr?.map((k) => k + ":00"),
      available_type: availability,
      is_leave: state?.edit ? (leave === "yes" ? 1 : 0) : "",
    };
    addRoster(data, state?.edit).then((res) => {
      setLoading(false);
      if (res?.data?.status) {
        setPopupOpen({ open: true, body: { data: res?.data?.msg } });
      }
      dispatch(setErrors(res?.data?.error));
    });
  };

  const selectedstaffName = staffListData
    ?.map((i) => {
      if (i?.id === selectStaff) {
        return i?.name;
      }
    })
    .filter((i) => i !== undefined);
  return (
    <div>
      <Toaster />
      {popupOpen.open && (
        <AddRosterSuccesses
          popupOpen={popupOpen}
          RosterAddIcon={RosterAddIcon}
          setPopupOpen={setPopupOpen}
          closeBtn={"Close"}
        />
      )}

      {leaveConfirmOpen.open && (
        <AddRosterSuccesses
          popupOpen={leaveConfirmOpen}
          RosterAddIcon={RosterAddIcon}
          setPopupOpen={setLeaveConfirmOpen}
          closeBtn={"No"}
          yesBtnText={"Yes"}
          onChangeLeave={onChangeLeave}
          setLeave={setLeave}
        />
      )}

      <SimpleHeader
        text={state?.edit ? "Edit Roster" : "Add Roster"}
        links={links}
        basic
        noPaddingTop
        editStaffName={state?.edit ? selectedstaffName?.at(0) : ""}
      />
      <div className={styles.container}>
        <Form
          selectDropDown={getDropDownlist(finalMonthArr)}
          tHead={tHead}
          selectWeek={selectWeek}
          staffListData={staffDropDown}
          selectStaff={selectStaff}
          checkedAll={checkedAll}
          setCheckedAll={setCheckedAll}
          checked={checked}
          setChecked={setChecked}
          settimeSlotChecked={settimeSlotChecked}
          timeSlotChecked={timeSlotChecked}
          setAvailability={setAvailability}
          availability={availability}
          errors={errors}
          state={state}
          leave={leave}
          setLeave={setLeave}
          handleLeaveChange={handleLeaveChange}
          timeSlotdata={timeSlotdata}
          isBooked={isBookedTime}
        />
        {isLoading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress size={30} />
            <p style={{ color: "black" }}>
              Please wait ! We are validating the details...
            </p>
          </div>
        )}
        <div className={styles.buttons}>
          <Btn
            width="100px"
            borderRadius="100px"
            bgColor="#2A2A2A"
            marginRight="24px"
            click={() => navigate("/Samvedna/roster")}
          >
            Cancel
          </Btn>
          <Btn
            width="100px"
            borderRadius="100px"
            bgColor="#00B091"
            disabled={isLoading}
            click={addRosterTime}
          >
            {state?.edit ? "Update" : "Add"}
          </Btn>
        </div>
      </div>
    </div>
  );
};
