import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomTable } from "../../../Component/UI/Table";
import { NavLink, Link } from "react-router-dom";
import { TbEdit } from "react-icons/tb";
import { Edit } from "../../../assets/icons/Edit";
import { useEffect, useState } from "react";
import { assessmentData } from "../../../Redux/Slices/AssessmentTableReducer";
import axios from "../../../config/axiosConfig";
import moment from "moment";
import { useLocation } from "react-router-dom";
import styles from "./payment.module.css";
import {
  setFilter,
  setFrom_date,
  setSearch_key,
  setTo_date,
  setClear,
} from "../../../Redux/Slices/AdminFilters/Search";
import { setPaymentData } from "../../../Redux/Slices/Payments/PaymentSlices";
import { Download } from "../../../assets/icons/Download";
import { Print } from "../../../assets/icons/Print";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import Modal from "./Model";
import Reschedule from "../../../assets/images/reschedule.svg";
import { toast } from "../../../../node_modules/react-hot-toast/dist/index";

export const Pending = ({ permissions }) => {
  const fullpermissions = +permissions?.listControl?.access?.["Full Control"];
  const writePermissions = +permissions?.listControl?.access?.Write;
  const tHead = [
    "Transaction ID",
    "Date",
    "Amount",
    "Patient Name",
    "Mode",
    ...(writePermissions ? ["Action"] : []),
  ];
  const [active, setActive] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [joinPopup, setJoinPopup] = useState({ open: false });

  const dispatch = useDispatch();
  const { search_key, from_date, to_date, filter } = useSelector(
    (i) => i.SearchFilters
  );
  const { paymentData } = useSelector((state) => state.PaymentSlices);
  const { state } = useLocation();
  const customerId = state?.props || "";

  useEffect(() => {
    getTable();
  }, [filter]);

  const getTable = async () => {
    setIsLoadingData(true);
    try {
      const response = await axios.get(
        `/admin/payment?customer_id=${customerId}&status=pending&search_key=${search_key}&fromdate=${from_date}&todate=${to_date}`
      );
      if (response.data.status) {
        setIsLoadingData(false);
        dispatch(setPaymentData(response.data.list));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setSearch_key(""));
      dispatch(setTo_date(""));
      dispatch(setFrom_date(""));
      dispatch(setFilter());
      dispatch(setClear());
    };
  }, []);

  const reSendPayment = async () => {
    setIsLoading(true);
    const pastData = {
      id: joinPopup?.id,
      payment_link: joinPopup?.link,
    };
    await axios
      .post("/admin/reSend/paymentLink", pastData)
      .then((res) => {
        setIsLoading(false);
        // dispatch(fetchEvents());
        //toast(res.data.messege);
        setJoinPopup({
          open: false,
          ...joinPopup,
          successMsg: res?.data?.messege,
        });
      })
      .catch((err) => console.error(err));
  };

  const tBodyData = paymentData
    ?.map(
      (
        {
          // assessment_image,
          payment_id,
          created_at,
          amount,
          customer,
          mode,
          payment_done,
          short_url,
          id,
        },
        i
      ) => {
        const disableRescheduleIcon = payment_id?.includes("order");
        const getCreatedDate = created_at ? moment(created_at).add(1, "M") : "";
        const getCurrentDate = moment();
        const checkDateCond = moment(getCreatedDate).isBefore(getCurrentDate);
        if (checkDateCond && disableRescheduleIcon) {
          return null;
        } else {
          return {
            payment_id,
            created_at: moment(created_at).format("DD-MM-YYYY"),
            amount,
            customer: customer ? customer?.name : "No Name",
            mode: (
              // style={{ color: payment_done ? "#00B091" : "red" }}
              <span>{"Online"}</span>
            ),
            ...(writePermissions && {
              action: (
                <div
                  className={styles.paymentIcons}
                  style={{ cursor: "pointer" }}
                >
                  <Download color="#2A2A2A" size={20} />
                  <Print />

                  <div
                    style={{
                      cursor: disableRescheduleIcon ? "not-allowed" : "pointer",
                    }}
                    fontSize="12px"
                    onClick={() => {
                      !disableRescheduleIcon &&
                        setJoinPopup({
                          ...joinPopup,
                          open: true,
                          link: short_url,
                          id: id,
                        });
                    }}
                  >
                    <img
                      style={{ marginTop: "5px" }}
                      src={Reschedule}
                      alt="Reschedule"
                    />
                  </div>
                </div>
              ),
            }),
          };
        }
      }
    )
    .filter((i) => i !== null);

  return (
    <div>
      {joinPopup.open && (
        <Modal
          link={joinPopup?.link}
          textHeading={"Do you want to resend payment link?"}
          successMsg={joinPopup?.successMsg}
          setJoinPopup={setJoinPopup}
          label="Payment Link"
          reSendPayment={reSendPayment}
          isLoading={isLoading}
        />
      )}
      <CustomTable
        isLoadingData={isLoadingData}
        tHead={tHead}
        tBody={tBodyData}
      />
    </div>
  );
};
