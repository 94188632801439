import React from "react";
import { Box, Modal } from "@mui/material/index";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import { TextArea } from "../../../Component/UI/CustomInputs";
import { useState } from "react";
import { requestAssessmentChange } from "../../../services/enterprise/assessment";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";
import { Cross } from "../../../assets/icons/Cross";

import styles from "./assessmentfeedback.module.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  outline: "none",
  borderRadius: "10px",
  boxShadow: 8,
  p: 4,
};

export const AssessmentFeedbackPop = ({
  popup,
  setPopup,
  assissement_id,
  popupAction,
  setPopupAction,
}) => {
  const [err, setErr] = useState({
    comments: "",
  });
  const [value, setValue] = useState({
    comments: "",
  });

  const [disableBtn, setDisableBtn] = useState(false);
  return (
    <div>
      <Modal
        open={popup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={styles.feedbackModal}>
          <div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <TextArea
                label="Please write below your feedbacks"
                placeholder="Enter your feedbacks"
                type="text"
                error={err.comments}
                width="300px"
                height="200px"
                value={value.comments}
                maxlength="300"
                onChange={(e) => {
                  setValue({ ...value, comments: e.target.value });
                }}
              />
              <Btn
                bgColor="#3592FF"
                color="#fffff"
                fontSize="16px"
                width="100px"
                height="40px"
                borderRadius="10px"
                variant="contained"
                disabled={disableBtn}
                click={() => {
                  setDisableBtn(true);
                  setErr({
                    ...err,
                    comments: "",
                  });
                  requestAssessmentChange({
                    assissement_id,
                    feedback_value: value.comments,
                  })
                    .then((res) => {
                      console.log(res);
                      if (res.data.status) {
                        setValue({ ...value, comments: "" });
                        setPopup(false);
                        setPopupAction({
                          ...popupAction,
                          open: true,
                        });
                      } else {
                        setErr({
                          ...err,
                          comments: res.data.error.feedback_value,
                        });
                      }
                    })
                    .catch((err) => console.log(err))
                    .finally(() => {
                      setDisableBtn(false);
                    });
                }}
              >
                {disableBtn ? <CircularProgress size={20} /> : "Submit"}
              </Btn>
            </div>
            <div
              onClick={() => {
                setPopup(false);
                setValue({ ...value, comments: "" });
              }}
              style={{ position: "absolute", top: "20px", right: "20px" }}
            >
              <Cross color={"black"} size={30} />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
