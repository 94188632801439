import styles from "../../../assets/css/PatientDashboard/assessmentResults.module.css";
import { Brain } from "../../../assets/icons/Brain";
import { AssesmentResult } from "../../UI/AssessmentResult/AssesmentResult";
import { Badge } from "../../UI/Badge";
import { Btn } from "../../UI/Button";
import { useLocation, useNavigate } from "react-router-dom";
import list from "./lists.json";
import CarouselSimple from "react-simply-carousel";
import {
  assessmentGet,
  assessmentLevel,
  assessmentLevelRelative,
} from "../../../services/patient/assessment";
import { useState } from "react";
import { useEffect } from "react";

import Modal from "../../../Component/UI/ServicesCard/Modal";
import { Toaster } from "../../../../node_modules/react-hot-toast/dist/index";
import axios from "../../../config/axiosConfig";
import { useDispatch } from "react-redux";
import {
  setCustomerRelativeData,
  setSelectOptions,
} from "../../../Redux/Slices/Services/selectOptions";
import Popup from "../../UI/Popup";
import AuthContext from "../../../Auth/AuthContext";
import { useContext } from "react";

export const AssessmentResults = () => {
  const { state } = useLocation();

  const [data, setData] = useState(state?.data);
  const dispatch = useDispatch();
  const [activeSlideAssessment, setActiveSlideAssessment] = useState(0);
  const [datafetched, setDataFetched] = useState(false);
  const [popupAction, setPopupAction] = useState({
    open: state?.popStatus === false,
    // open: true,
    name: "Information",
    title:
      "You have already taken this assessment before, you can retake your assessment after 30 days. Continue to see your previous results.",
  });
  const navigate = useNavigate();

  const ctx = useContext(AuthContext);
  const isParent = ctx.loginUser?.customer?.is_parent;

  useEffect(() => {
    if (state?.assessment_for === "someone_else" && !isParent) {
      setPopupAction({
        open: true,
        name: "Information",
        title:
          "Someone else assessment functionality is not available for your account yet.",
      });
    }
  }, []);

  useEffect(() => {
    axios.get("/customer/relative/list").then((res) => {
      const user = res?.data?.list;
      const Relativedata = user?.relative?.map(({ id, name, assessments }) => {
        if (assessments.length) {
          return {
            id,
            value: name,
          };
        } else return null;
      });

      assessmentGet().then((res) => {
        let dementiaYourselfData = res.filter((item) => {
          return item.id === 1;
        });
        let yourselfStatusTrue =
          dementiaYourselfData?.[0].is_mapped?.status === "completed";

        // let selectedOptionsData = yourselfStatusTrue
        //   ? [...[{ id: user?.id, value: "Yourself" }], ...Relativedata]
        //   : Relativedata;

        let selectedOptionsData = [
          ...[{ id: user?.id, value: "Yourself" }],
          ...Relativedata,
        ];

        dispatch(
          setSelectOptions(
            selectedOptionsData.filter(
              (item) => item?.id === data?.is_mapped.customer_id
            )
          )
        );

        dispatch(setCustomerRelativeData(user));
      });
    });
  }, [datafetched]);

  useEffect(() => {
    if (data === undefined) {
      if (state?.assessment_for === "someone_else" && isParent) {
        assessmentLevelRelative().then((i) => {
          setData({
            ...i?.[0],
            is_mapped: i?.[0]?.customer,
            assessment_name: i?.[0]?.customer.customer_data.name,
          });
          setDataFetched(true);
        });
      } else {
        assessmentLevel().then((i) => {
          const currentAssessmentData = i.filter((item) => {
            return item.id === state?.category_id;
          });
          console.log(currentAssessmentData);

          setData({
            ...currentAssessmentData?.[0],
            assessment_name:
              currentAssessmentData?.[0]?.id === 1 ? "Yourself" : null,
          });
          setDataFetched(true);
        });
      }
    }
  }, []);

  const Dot = ({ color }) => (
    <div
      style={{
        background: color,
        width: "12px",
        height: "12px",
        borderRadius: "50%",
        marginRight: "8px",
      }}
    ></div>
  );

  const style = { display: "flex", alignItems: "baseline" };

  return (
    <>
      <Toaster />
      <Popup
        popupAction={popupAction}
        setPopupAction={setPopupAction}
        title={popupAction.title}
      />
      <div className={styles.container}>
        <div className={styles.assessmentResults}>
          {data && <AssesmentResult large data={data} />}
          <div className={styles.buttons}>
            <div className={styles.selfCareButton}>
              <Btn
                variant="contained"
                width="100%"
                height="48px"
                bgColor="orange"
                click={() => {
                  navigate("/clientselfcareplan");
                }}
              >
                View Self Care Plan
              </Btn>
            </div>
          </div>
        </div>

        <div className={styles.kyb}>
          <h1>Know your brain</h1>
          <p>
            Different parts of the brain get affected in dementia. Click on the
            different brain areas to know about how they are involved in our
            thinking and behavior:
          </p>
          <div className={styles.brain}>
            <Brain
              click={(val) => setActiveSlideAssessment(val)}
              active={activeSlideAssessment}
            />
            <ul style={{ listStyleType: "none" }}>
              {list.map(({ text, color }, i) => (
                <div style={style} key={i}>
                  <Dot color={color} />
                  <li>{text}</li>
                </div>
              ))}
            </ul>
          </div>
          <CarouselSimple
            containerProps={{
              style: {
                position: "relative",
                marginTop: "10px",
              },
            }}
            itemsToShow={1}
            activeSlideIndex={activeSlideAssessment}
            onRequestChange={setActiveSlideAssessment}
            hideNavIfAllVisible={false}
            speed={300}
            dotsNav={{
              show: false,
            }}
            infinite={false}
            forwardBtnProps={{
              children: ">",
              style: {
                border: "none",
                background: "none",
                fontSize: "20px",
                cursor: "pointer",
                position: "absolute",
                top: "-20px",
                right: "30px",
                outline: "none",
              },
            }}
            backwardBtnProps={{
              children: "<",
              style: {
                border: "none",
                background: "none",
                fontSize: "20px",
                cursor: "pointer",
                position: "absolute",
                top: "-20px",
                right: "50px",
                outline: "none",
              },
            }}
          >
            {list.map(({ text, color, paragraph, badges }, index) => {
              return (
                <div key={index} className={styles.content}>
                  <div style={style}>
                    <Dot color={color} />
                    <h2>{text}</h2>
                  </div>
                  <p>{paragraph}</p>
                  <div className={styles.badges}>
                    {badges?.map((text, index) => (
                      <Badge key={index} text={text} />
                    ))}
                  </div>
                </div>
              );
            })}
          </CarouselSimple>
        </div>
      </div>
    </>
  );
};
