import { HealthHOC } from "./Health";
import { PasswordHOC } from "./Password";
import { NotificationsHOC } from "./Notifications";
import { PaymentHistoryHOC } from "./PaymentHistory";
import { TabView } from "../UI/TabView";
import { ProfileHOC } from "./Profile";
import { Toaster } from "react-hot-toast";
import Consent from "./Consent";
import { Helmet } from "react-helmet";

export const MyAccount = () => {
  const items = [
    {
      label: "General",
      component: <ProfileHOC />,
    },
    {
      label: "Password",
      component: <PasswordHOC />,
      diable: false,
    },
    {
      label: "Health",
      component: <HealthHOC />,
      // diable: true,
    },
    {
      label: "Payment History",
      component: <PaymentHistoryHOC />,
      diable: false,
    },
    {
      label: "Consent",
      component: <Consent />,
      diable: false,
    },
    /* { */
    /*   label: "Notifications", */
    /*   component: <NotificationsHOC />, */
    /*   // diable: true, */
    /* }, */
  ];
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Patient Account - Samvedna Map</title>
        <link
          rel="canonical"
          href="https://map.samvednacare.com/patient/MyAccount"
        />
        <meta name="title" content="Patient Account - Samvedna Map" />
        <meta
          name="description"
          content="Samvedna MAP is a personalized mental healthcare service platform. Create an account for easy access to assessments, self-care plan, counsellors, doctors and extended medical services."
        />
        <meta name="robots" content="index,follow" />
      </Helmet>
      <div style={{ margin: "16px" }}>
        {/*<TabView items={items} />*/}
        <Toaster />
        <TabView values={items} calendar />
      </div>
    </>
  );
};
