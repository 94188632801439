import React, { useEffect, useState } from "react";
import RejectedData from "../../../MockData/EntRejected.json";
import { CustomTable } from "../../../Component/UI/Table";
import axios from "../../../config/axiosConfig";
import { useDispatch, useSelector } from "react-redux";
import {
  setFilter,
  setFrom_date,
  setSearch_key,
  setTo_date,
  setClear,
} from "../../../Redux/Slices/AdminFilters/Search";
import { Avatar } from "../../../Component/UI/Avatar";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";

export const Rejected = ({ setRejectedData, setExportData }) => {
  const [client, setClient] = useState([]);
  const nlcolumns = Object.keys(RejectedData[0]);
  const tHead = nlcolumns;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { search_key, from_date, to_date, filter } = useSelector(
    (i) => i.SearchFilters
  );

  const getApi = () => {
    setIsLoading(true);
    axios
      .post(`/admin/enterprise/filter`, {
        type: "rejected",
        search_key,
        from_date,
        to_date,
      })
      .then((res) => {
        setIsLoading(false);
        setClient(res.data.list);
        setExportData(
          res.data.list.map((i) => {
            return {
              Company: i.company_name,
              Designation: i.designation,
              Email: i.email,
              Domain: i.domain,
              Name: i.name,
              Mobile: i.mobile,
              "Total Employee": i.total_employee,
              "Other Domains": i.other_domains,
              Industry: i.industry,
              "Reject Reason": i.reject_reson,
              // Status: i.status === 1 ? "active" : "inactive",
            };
          })
        );
        console.log("res", client);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getApi();
    //toggle status
    // toggleStatus(2, 0);
  }, [filter]);

  useEffect(() => {
    setRejectedData(client);
  }, [client]);

  useEffect(() => {
    return () => {
      //   dispatch(setSearch_key(""));
      dispatch(setTo_date(""));
      dispatch(setFrom_date(""));
      dispatch(setFilter());
      dispatch(setClear());
    };
  }, []);

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 35,
        height: 35,
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  const tBodyData = client?.map(
    ({ id, company_name, name, mobile, email, reject_reson, img_url }) => {
      const companyname = (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            height: 0,
          }}
        >
          <Avatar size="28px" profile={img_url} border="1px solid #ccc" />{" "}
          <span style={{ marginLeft: "8px" }}> {company_name} </span>
        </span>
      );

      return {
        companyname,
        name,
        mobile,
        email,
        reject_reson,
      };
    }
  );

  return !isLoading ? (
    <CustomTable tHead={tHead} tBody={tBodyData} />
  ) : (
    <div className="loader" style={{ textAlign: "center", margin: "60px" }}>
      <CircularProgress size={28} />
    </div>
  );
};
