import { EventCards } from "../../EventCards";
import { EmptyEvent } from "../../UI/EmptyEvent";
import styles from "../../../assets/css/PatientDashboard/events.module.css";

export const Cards = ({ data }) => {
  // const allMapped = data?.map((i) => i.mapped?.length);
  // const allEnrolled = allMapped?.some((i) => i === 0);
  // const isEvents = data?.length && allEnrolled;

  // console.log(allMapped, allEnrolled, "data");

  return (
    <div className={styles.cards}>
      {data.length ? (
        data.map(
          ({
            id,
            name,
            event_date,
            start_time,
            end_time,
            description,
            mapped,
            event_status,
            type,
            speaker_name,
          }) => (
            // mapped.length === 0 ? (
            <EventCards
              key={id}
              eventId={id}
              name={name}
              date={event_date}
              start={start_time}
              end={end_time}
              summary={description}
              status={event_status}
              btnText={mapped.length === 0 ? "Enroll" : "Enrolled"}
              type={type}
              speaker_name={speaker_name}
            />
          )
          // ) : null
        )
      ) : (
        <EmptyEvent />
      )}
    </div>
  );
};
