import { Fragment } from "react";

export const Download = ({ color, size }) => {
  const paths = [
    "M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10",
    "M4.66675 6.66699L8.00008 10.0003L11.3334 6.66699",
    "M8 10V2",
  ];
  return (
    <svg
      width={size || "16"}
      height={size || "16"}
      viewBox="0 0 16 16"
      fill="none"
    >
      {paths.map((i) => (
        <Fragment key={i}>
          <path
            d={i}
            stroke={color || "#7A51AE"}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </Fragment>
      ))}
    </svg>
  );
};
