import axios from "axios";

/* const token = patientToken; */
const instance = axios.create({
  baseURL: "https://mapapi.samvednacare.com/api",
  // baseURL: "http://demo7.iitpl.com/api",
  //baseURL: "http://demo3.iitpl.com/api",
  // baseURL: "https://prepodmapapi.samvednacare.com/api",
});

export const setToken = (token) =>
  (instance.defaults.headers.common["Authorization"] = `Bearer ${token}`);

export default instance;
