import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { upcomingEvents } from "../../../Redux/Slices/PatientEvents";
import { getEvents } from "../../../services/patient/events";
import { Cards } from "./Cards";
import { searchAndDateFilter } from "./All";
import {
  setSearchText,
  setToggleSearch,
} from "../../../Redux/Slices/patientSearch";
import {
  setDate,
  setToggle,
  setYear,
} from "../../../Redux/Slices/Patient/PatienCalender";

export const Upcoming = () => {
  const dispatch = useDispatch();
  const { upcoming, message } = useSelector((i) => i.PatientEvents);
  const { selectedDate } = useSelector((i) => i.DateFilterSlice);
  const { searchText } = useSelector((i) => i.patientSearch);
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    getEvents("upcoming")
      .then((i) => {
        dispatch(upcomingEvents(i));
        setAllData(i);
      })
      .catch((err) => console.log(err));
    return () => {
      dispatch(setToggleSearch(false));
      dispatch(setSearchText(""));
      dispatch(setDate(`All ${new Date().getFullYear()}`));
      dispatch(setToggle(false));
      dispatch(setYear(new Date().getFullYear()));
    };
  }, [message]);

  useEffect(() => {
    const searchResult = searchAndDateFilter(allData, searchText, selectedDate);
    dispatch(upcomingEvents(searchResult));
  }, [searchText, selectedDate, allData]);

  return <Cards data={upcoming} />;
};
