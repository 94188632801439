import React, { useEffect, useState } from "react";
import TextField from "@mui/joy/TextField";
import toast, { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import classess from "../../../../assets/css/AdminAssessment/assessmentdetails.module.css";
import DragNDrop from "../../../../Hooks/Drag&Drop/DragNDrop";
import CustomizedButtons from "../../../../Component/UI/Button/CustomizeButton";

import axios from "../../../../config/axiosConfig";
import { getAssessmentId } from "../../../../Redux/Slices/assessmentId";
import { DatePicker } from "../../../UI/DatePicker";
import { StringDtoY } from "../../../../config/formattedDate";
import { TEXT } from "../../../contant/constant";
import {
  MultiSelect,
  Select,
  TextArea,
} from "../../../../Component/UI/CustomInputs";
import { DateRange } from "../../../../Component/DateRange";

import { Editor } from "../../../../Hooks/Editor";

const Details = ({ flag, id, setCount }) => {
  const dispatch = useDispatch();
  const { data } = useSelector((i) => i.assessmentDetails);

  const [error, setError] = useState({});
  const [imageSize, setImageSize] = useState();

  const [detail, setDetail] = useState({
    created_for: "0",
  });

  const [email_template, setEmail_template] = useState(
    "<p>{enterprise_name} is committed to making your mental health a priority! In order for us to provide you right holistic mental health and wellbeing solutions we would like you to take this assessment and answer a few questions. So go-ahead and take the first step to care for your mental wellbeing, click on the link here {link} to get started. Please complete this latest by DATE</p>"
  );

  const [logo, setLogo] = useState();
  const [img, setImg] = useState();
  const [date, setDate] = useState({
    date_of_creation: StringDtoY(new Date()),
  });

  const [enterpriseOptions, setEnterpriseOptions] = useState([
    { id: 0, value: "None" },
  ]);

  const location = useLocation();
  const { ass_id } = location?.state;

  const catID = id || ass_id;

  const sucessNotify = (msg) => toast.success(msg);

  const errorNotify = (error) => toast.error(error);

  useEffect(() => {
    if (catID) {
      try {
        axios
          .get(`/admin/detail/assessment/category?id=${catID}`)
          .then((res) => {
            setDetail(res.data.list);
            setLogo(res.data.list.logo);
            setImg(res.data.list.logo);
            if (res.data.list.email_template) {
              setEmail_template(res.data.list.email_template);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  const getImage = async (img) => {
    setLogo(img);
  };
  const handleChange = (e) => {
    setDetail({ ...detail, [e.target.name]: e.target.value });
  };

  const inputHandler = (value) => {
    setEmail_template(value);
  };

  const getSelectedDate = (e) => {
    setDate({
      ...date,
      date_of_creation: StringDtoY(e),
    });
  };
  let errorMsg;
  const Validate = (
    name,
    short_description,
    email_template,
    no_of_question,
    logo
  ) => {
    if (name === "" || name === undefined) {
      errorNotify(TEXT.NAMEREQUIRED);
      errorMsg = false;
    } else if (short_description === "" || short_description === undefined) {
      errorNotify(TEXT.DESCREQUIRED);
      errorMsg = false;
    } else if (no_of_question === "" || no_of_question === undefined) {
      errorNotify(TEXT.QUESTIONREQUIRED);
      errorMsg = false;
    } else if (email_template === "" || email_template === undefined) {
      errorNotify(TEXT.EMAILREQUIRED);
      errorMsg = false;
    } else if (logo === "" || logo === undefined) {
      errorNotify(TEXT.LOGOREQUIRED);
      errorMsg = false;
    } else if (imageSize > TEXT.MAX_FILE_SIZE) {
      errorNotify("File size is too large!");
      errorMsg = false;
    } else {
      errorMsg = true;
    }
    return errorMsg;
  };
  const saveButton = async (e) => {
    toast.remove();
    const logoImg = logo === img ? "" : logo;
    const validate = Validate(
      detail.name,
      detail.short_description,
      email_template,
      detail.no_of_question,
      logo
    );
    const Data = new FormData();
    Data.append("name", detail.name);
    Data.append("short_description", detail.short_description);
    Data.append("no_of_question", detail.no_of_question);
    Data.append("date_of_creation", date.date_of_creation);
    Data.append("logo", logoImg);
    Data.append("created_for", detail.created_for);
    Data.append("email_template", email_template);
    Data.append("id", id || 0);
    if (detail?.created_for.toString() === "1")
      Data.append("enterprise_id", detail.enterprise_id);

    if (validate) {
      const res = await axios.post("/admin/add/assessment/category", Data);

      if (res?.data?.status) {
        dispatch(getAssessmentId(res?.data?.data_id));
        setCount((count) => count + 1);
        console.log(res);
      } else {
        errorNotify("Please fill all fields");
      }
    }
  };

  const editButton = async () => {
    toast.remove();

    const logoImg = logo === img ? "" : logo;
    const Data = new FormData();
    Data.append("name", detail.name);
    Data.append("short_description", detail.short_description);
    Data.append("no_of_question", detail.no_of_question);
    Data.append("date_of_creation", detail.date_of_creation);
    Data.append("logo", logoImg);
    Data.append("created_for", detail.created_for);
    Data.append("email_template", email_template);
    Data.append("id", id);
    if (detail?.enterprise_id)
      Data.append("enterprise_id", detail.enterprise_id);

    const validate = Validate(
      detail.name,
      detail.short_description,
      email_template,
      detail.no_of_question,
      logo
    );

    if (validate) {
      const res = await axios.post("/admin/add/assessment/category", Data);
      if (res?.data?.status) {
        sucessNotify(res.data.msg);
        setCount((count) => count + 1);
        console.log(res);
      } else {
        setError(res?.data?.error);
        errorNotify(res?.data?.error);
      }
    }
  };

  useEffect(() => {
    axios.get("/admin/fetch/enterprise").then((res) => {
      if (res?.data?.status) {
        setEnterpriseOptions([
          ...enterpriseOptions,
          ...res.data?.list.map(({ company_name, id }) => {
            return { id, value: company_name };
          }),
        ]);
      }
    });
  }, []);

  useEffect(() => {
    return () => toast.remove();
  }, []);

  return (
    <div className={classess.detail_div}>
      <div
        className={classess.div_flex}
        style={{ justifyContent: "space-between" }}
      >
        <div style={{ marginLeft: "12px" }}>
          <p>
            Assessment for <span style={{ color: "#D3232F" }}>*</span>
          </p>
          <div style={{ display: "flex", marginTop: "20px" }}>
            <input
              type="radio"
              id="user"
              name="created_for"
              value="0"
              style={{ outline: "none" }}
              onChange={handleChange}
              checked={detail?.created_for.toString() === "0"}
              disabled={catID}
            />
            <label for="general">General</label>
            <span style={{ marginLeft: "20px" }}>
              <input
                type="radio"
                id="enterprise"
                name="created_for"
                value="1"
                style={{ outline: "none" }}
                onChange={handleChange}
                checked={detail?.created_for.toString() === "1"}
                disabled={catID}
              />
              <label style={{ marginLeft: "6px" }} for="enterprise">
                Enterprise
              </label>
            </span>
          </div>
        </div>
        <Select
          name="enterprise_id"
          label="Enterprise Name (Optional)"
          width="45%"
          margin="0 10px 0 0"
          value={
            detail?.created_for.toString() === "1" ? detail?.enterprise_id : ""
          }
          options={enterpriseOptions}
          onChange={handleChange}
          disabled={detail?.created_for.toString() === "0" || flag}
        />
      </div>
      <div className={classess.div_flex}>
        <div className={classess.span_div}>
          <TextField
            label="Assessment Name"
            placeholder="Assessment Name"
            required
            name="name"
            size="md"
            fullWidth
            value={detail?.name}
            onChange={handleChange}
          />
          <span> {error && <p>{error?.name}</p>}</span>
        </div>
        <div className={classess.span_div}>
          <TextField
            label="Short Description"
            placeholder="Description"
            required
            size="md"
            name="short_description"
            fullWidth
            onChange={handleChange}
            // onChange={(e) =>
            //   dispatch(assessmentDetails({ short_description: e.target.value }))
            // }
            value={detail?.short_description}
          />
          <span> {error && <p>{error?.short_description}</p>}</span>
        </div>
      </div>
      <div className={classess.div_flex}>
        <div className={classess.span_div}>
          <TextField
            label="No. of questions"
            placeholder="No. of questions"
            type="number"
            required
            size="md"
            name="no_of_question"
            fullWidth
            value={detail?.no_of_question}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 12);
            }}
            onChange={handleChange}
            onWheel={(e) => e.target.blur()}
          />
          <span> {error && <p>{error?.no_of_question}</p>}</span>
        </div>
        <div className={classess.span_div}>
          <DatePicker
            label="Date of Creation"
            // minDate={new Date()}
            getSelectedDate={getSelectedDate}
            defaultDate={detail?.date_of_creation}
            disabled
          />

          <span> {error && <p>{error?.date_of_creation}</p>}</span>
        </div>
      </div>
      {/* <div className={classess.span_div}> */}
      {/*   <DateRange /> */}
      {/*   <span> {error && <p>{error?.date_of_creation}</p>}</span> */}
      {/* </div> */}

      {/* <TextArea
        label="Email Template"
        name="email_template"
        value={detail?.email_template}
        onChange={handleChange}
        height="200px"
        margin="18px"
      /> */}

      <div style={{ margin: "15px" }}>
        <div style={{ margin: "10px 0" }}>Email Template</div>
        <Editor data={email_template} change={(val) => inputHandler(val)} />
      </div>

      <div className={classess.span_div}>
        <DragNDrop
          TypographyLabel={"Assessment Image"}
          getImage={(logo) => getImage(logo)}
          imagelogo={detail?.assessment_image}
          className={classess.s}
          setImageSize={setImageSize}
        />
        <span> {error && <p>{error?.logo}</p>}</span>
      </div>

      {!flag ? (
        <CustomizedButtons ButtonLabel={"Save"} click={saveButton} />
      ) : (
        <CustomizedButtons ButtonLabel={"Update"} click={editButton} />
      )}
      <Toaster />
    </div>
  );
};
export default Details;
