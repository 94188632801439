import styles from "../../assets/css/sessioninfocard.module.css";

export const SessionInfoCard = () => {
  return (
    <div className={styles.sessionCard}>
      <div>
        <h1>Session Information</h1>
        <div className={styles.sessionInfo}>
          <div>
            <p>Session ID</p>
            <p>5MKU0Q</p>
          </div>
          <div>
            <p>Session Name</p>
            <p>Dementia Assessment</p>
          </div>
          <div>
            <p>Session Date</p>
            <p>22 - 06 - 2022</p>
          </div>
          <div>
            <p>Session by</p>
            <p>Dr. Ravina Tondon</p>
          </div>
        </div>
      </div>
    </div>
  );
};
