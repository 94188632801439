import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const { ADDAPPOINTMENT, UPDATEAPPOINTMENT, APPOINTMENTDETAILS } = API;

export const addAppointment = async (data) => {
  return await axios
    .post(ADDAPPOINTMENT, data)
    .then((res) => res)
    .catch((err) => err);
};

export const updateAppointment = async (data) => {
  return await axios
    .post(UPDATEAPPOINTMENT, data)
    .then((res) => res)
    .catch((err) => err);
};

export const getAppointmentDetail = async (id) => {
  return await axios
    .get(APPOINTMENTDETAILS + id)
    .then((res) => res)
    .catch((err) => err);
};

export const getAvailStaff = async (data) => {
  return await axios
    //admin/available/staff
    .post("/admin/available/staffNew", data)
    .then((res) => res)
    .catch((err) => err);
};

export const getTimeSlotAdmin = async (data) => {
  return await axios
    .get(`/admin/appointment/time-slot-new?appt_date=${data}`)
    .then((res) => res)
    .catch((err) => err);
};

export const getreschedulestaff = async (data) => {
  //admin/appointment/get-reschedule-staff
  return await axios
    .get(`/admin/appointment/get-reschedule-staffNew?appt_date=${data}`)
    .then((res) => res)
    .catch((err) => err);
};


export const assignStaff = async (data) => {
  return await axios
  //admin/assign/staff
    .post("/admin/assign/staffNew", data)
    .then((res) => res)
    .catch((err) => err);
};


export const reAssignStaff = async (data) => {
  return await axios
    .post("/admin/update/reschedule-staff-new", data)
    .then((res) => res)
    .catch((err) => err);
};
