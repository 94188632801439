import { createSlice } from "@reduxjs/toolkit";

const dashboardAssessmentSlice = createSlice({
  name: "dashboard assessment",
  initialState: { assessment: [] },
  reducers: {
    dashboardAssessment: (state, action) => {
      state.assessment = action.payload;
    },
  },
});

export const { dashboardAssessment } = dashboardAssessmentSlice.actions;

export default dashboardAssessmentSlice.reducer;
