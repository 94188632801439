import React from "react";
import { SubscriptionIcon } from "../../../assets/icons/SubscriptionIcon";
import { Badge } from "../../../Component/UI/Badge";
import { Btn } from "../../../Samvenda-Patient/UI/Button";
import styles from "./subscriptionCard.module.css";

export const SubscriptionCard = ({
  name,
  amount,
  extended,
  validity,
  onClick,
}) => {
  const oneMonth = 30;

  const convertedValidity =
    validity >= oneMonth
      ? validity / oneMonth > 1
        ? validity / oneMonth + " Months"
        : validity / oneMonth + " Month"
      : validity + " Days";

  let nf = new Intl.NumberFormat("en-US");

  return (
    <div className={styles.card}>
      <div className={styles.head}>
        <SubscriptionIcon />
        <div className={styles.badges}>
          {validity && (
            <Badge
              text={convertedValidity}
              style={{ background: "#2A2A2A", height: "24px" }}
            />
          )}
          {extended && (
            <Badge
              text="Extended"
              style={{ background: "#18BA4B", height: "24px" }}
            />
          )}
        </div>
      </div>
      <div className={styles.content}>
        <p className={styles.name}>
          {name || "Counselling with Psychologist / Doctor"}
        </p>
        <p className={styles.amount}>{`₹${nf.format(amount)}`} </p>
        <Btn
          className={styles.btn}
          variant="contained"
          bgColor="#3592ff"
          click={onClick}
        >
          Details
        </Btn>
      </div>
    </div>
  );
};
