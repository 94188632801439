import { TabView } from "../../UI/TabView";

export const AssessmentsPage = () => {
  const items = [
    {
      label: "Assessments",
      component: "",
    },
    {
      label: "Plans",
      component: "",
    },
  ];
  return (
    <div>
      <TabView values={items} />
    </div>
  );
};
