import styles from "../../../assets/css/AdminDashboard/services.module.css";
import { useSelector, useDispatch } from "react-redux";
import CustomizedButtons from "../../../Component/UI/Button/CustomizeButton";
import { ServiceForm } from "./ServiceForm";
import {
  getServiceDetails,
  editServices,
} from "../../../services/admin/services";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { serviceDetails } from "../../../Redux/Slices/serviceDetails";
import BasicBreadcrumbs from "../../../Component/UI/BreadCrumb/BreadCrumbs";

export const EditService = () => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState();
  const [error, setError] = useState([]);
  const { data } = useSelector((i) => i.serviceDetails);
  const { state } = useLocation();
  const flag = state?.flag;
  const id = state?.props;
  const from = state?.from;
  const navigate = useNavigate();

  const newData = { ...data, service_id: id };

  useEffect(() => {
    getServiceDetails(id).then((i) => {
      dispatch(serviceDetails(i));
    });
  }, []);

  const editService = (e) => {
    e.preventDefault();
    editServices(newData).then((i) => {
      setResponse(i?.data);
      if (i?.data?.status) {
        navigate("/Services");
      } else {
        setError(i?.data?.error);
      }
    });
  };
  const cancelService = () => {
    navigate("/Services");
  };

  const links = [
    {
      path: "/services",
      linkText: "Services",
    },
  ];
  return (
    <BasicBreadcrumbs link={links} text="Edit Service">
      {/* <div className={styles.addService}> */}
      <form onSubmit={editService}>
        <ServiceForm
          response={response}
          formdata={data}
          flag={flag}
          error={error}
          from={from}
        />
        <div
          className="styles.buttons_flex"
          style={{ display: "flex", marginTop: "20px", gap: "20px" }}
        >
          <CustomizedButtons ButtonLabel={"Cancel"} click={cancelService} />

          <CustomizedButtons
            ButtonLabel={"Save"}
            click={editService}
            type={"submit"}
          />
        </div>
      </form>

      {/* </div> */}
    </BasicBreadcrumbs>
  );
};
