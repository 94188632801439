import React from "react";
import { useState } from "react";
import { Star, StarSmall } from "./Star";
export const TestStarView = ({ 
  starRating,
  hover
}) => {
  return (
    <div style={{ display: "flex" }}>
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <>
            <div
              key={index}
              style={{ cursor: "pointer" }}
            >
              {index <= (hover || starRating) ? <StarSmall color="#FFB800" /> : <StarSmall />}
            </div>
          </>
        );
      })}
    </div>
  );
};


