import { createSlice } from "@reduxjs/toolkit";
// import axios from 'axios'
import instance from "../../config/axiosConfig";
import { API } from "../../config/constant";
import { token } from "../../assets/constants";

export const initialState = {
  loading: false,
  hasErrors: false,
  NextBtnData: [],
  SaveDataOnNextBtn: [],
};

const quickAskNextBtnSlice = createSlice({
  name: "quickAskNextBtn",
  initialState,
  reducers: {
    postLoading: (state) => {
      state.loading = true;
    },
    postSuccess: (state, { payload }) => {
      state.NextBtnData = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    SaveSuccess: (state, { payload }) => {
      state.SaveDataOnNextBtn = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    postFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const { postLoading, postSuccess, postFailure, SaveSuccess } =
  quickAskNextBtnSlice.actions;

export const quickAskNextBtnSelector = (state) => state.quickAskNextBtn;

export default quickAskNextBtnSlice.reducer;

//PostApi
export function fetchQuickAskNextBtn(data) {
  console.log(data, "data");
  return (dispatch) => {
    dispatch(postLoading());
    try {
      const response = instance.post(API.QUICKASKNEXTBTNAPI, data);
      const data = response;
      console.log(data, "QuickAskNextBtn Api data");
      dispatch(postSuccess(data.data));
    } catch (error) {
      dispatch(postFailure());
    }
  };
}
//saveData
export function fetchQuickAskSaveDataOnNextBtn(id) {
  return async (dispatch) => {
    dispatch(postLoading());
    try {
      dispatch(SaveSuccess(id));
    } catch (error) {
      dispatch(postFailure());
    }
  };
}
