import React, { useContext, useEffect, useState } from "react";

import AssessmentOwnerCSS from "../../../assets/css/assessmentOwner.module.css";
import { Helpcircle } from "../../../assets/icons/icon/Helpcircle";
import { ProgressBar } from "../../../Component/UI/ProgressBar";
import Clock from "../../../assets/icons/icon/clock.svg";
import QuestionContent from "./QuestionContent";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../config/axiosConfig";
import { API } from "../../../config/constant";

import {
  qaStore,
  selRange,
  selOption,
  selScore,
  selectMultiOption,
  selectMultiScore,
  SetbmiCalculate,
  setAssessment_for,
  setNumberOfQues,
  setTimetaken,
  setSubquestion,
  setRetakeType,
  resetQaStorage,
} from "../../../Redux/Reducers/PatientReducers/qa_storage/qa_storage";
import AuthContext from "../../../Auth/AuthContext";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material/index";
import { EnterpriseAssessmentSave } from "../../../services/enterprise/prelogin";
import toast from "../../../../node_modules/react-hot-toast/dist/index";

const Question = (props) => {
  const { question, category, show, handleClick } = props;
  // const labels = question.label.split(",");

  const [disabled, setDisabled] = useState(true);
  const [isDone, setIsDone] = useState(false);
  const [progress, setProgress] = useState(0);
  const [submitDisable, setSubmitDisable] = useState(false);
  const {
    qaData,
    selectedOption,
    selectedScore,
    range,
    multiOption,
    multiScore,
    category_id,
    numberOfQues,
    Timetaken,
    bmiCalculate,
    subquestion,
    assessment_for,
    relation,
    relationName,
    relation_id,
    retakeType,
    employee_email,
  } = useSelector((state) => state.qa_storage);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { UPDATEASSESSMENTRESULT } = API;

  const DisableHandleNext = () => {
    const data = [range, selectedOption || "", multiOption];
    const res = data.every((item) => item?.length === 0);
    if (question.minimum && question?.input_type === "range") {
      if (data[0] < question.minimum) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    } else if (question.input_type === "bmi") {
      setDisabled(false);
    } else {
      setDisabled(res);
    }
  };

  useEffect(() => {
    DisableHandleNext();
  }, [range, multiOption, selectedOption]);

  const ctx = useContext(AuthContext);

  useEffect(() => {
    props.goToStep(qaData.length + 1);
    dispatch(selRange(""));
    // dispatch(selOption(""));
    // dispatch(selScore(""));
    dispatch(selectMultiOption([]));
    dispatch(selectMultiScore([]));
    dispatch(setSubquestion(""));
    let totalNumberOfQuestions =
      ctx?.loginUser?.is_loggedin && ctx?.loginUser?.role === "client"
        ? props.totalSteps
        : props.totalSteps - 1;
    dispatch(setNumberOfQues(totalNumberOfQuestions));
  }, []);

  // const total = props?.totalSteps;
  const total =
    ctx?.loginUser?.is_loggedin && ctx?.loginUser?.role === "client"
      ? props.totalSteps
      : props.totalSteps - 1;

  const currentStep = props?.currentStep;

  useEffect(() => {
    if (isDone && !props.enterprise) {
      SubmitAssessment();
    }
    if (isDone && props.enterprise) {
      // alert("enterprise assessment submitted");
      setSubmitDisable(true);
      EnterpriseAssessmentSave({
        email: employee_email,
        assessment_result: qaData,
        assessment_id: category_id,
        time_taken: Timetaken,
        percentage: (qaData.length / Number(numberOfQues)) * 100 || "",
        assessment_for: assessment_for,
        status: "completed",
      })
        .then((res) => {
          if (res.data.status) {
            props.nextStep();
          } else {
            toast.remove();
            toast.error("something went wrong");
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setSubmitDisable(false));
    }
  }, [isDone]);

  useEffect(() => {
    setProgress((currentStep / total) * 100);
  }, [currentStep]);

  const [previousMinute, previousSecond] = Timetaken?.split(":");

  const [min, setMin] = useState(Number(previousMinute));
  const [sec, setSec] = useState(Number(previousSecond));

  const Timer = () => {
    const clock = setInterval(() => {
      if (sec < 59) setSec(sec + 1);
      else setSec(0);

      if (sec === 59) {
        setMin(min + 1);
      }

      clearInterval(clock);
    }, 1000);

    return (
      <p>
        {min > 9 ? min : `0${min}`}:{sec < 10 ? `0${sec}` : sec}
      </p>
    );
  };
  let Finaltimetaken = Timer();

  const AddSelectedDatatoqaData = () => {
    let arr = [...qaData];
    let selectedOptions;
    let selectedOptionScore;
    if (question.input_type.toLowerCase() === "radio") {
      selectedOptions = selectedOption;
      selectedOptionScore = selectedScore;
    } else if (question.input_type.toLowerCase() === "range") {
      selectedOptions = range;
      selectedOptionScore = "";
    } else if (question.input_type.toLowerCase() === "checkbox") {
      selectedOptions = multiOption;
      selectedOptionScore = multiScore;
    } else if (question.input_type.toLowerCase() === "bmi") {
      selectedOptions = bmiCalculate.toFixed(2);
      selectedOptionScore = "";
    } else {
      selectedOptions = selectedOption;
      selectedOptionScore = selectedScore;
    }

    let obj = {
      ...question,
      selectedOption: selectedOptions,
      selectedOptionScore: selectedOptionScore,
    };

    if (question.subquestion !== null) {
      obj = {
        ...obj,
        subquestionSelectedOption: subquestion,
      };
    }

    const exists = arr.findIndex((ele) => ele.id === obj.id);
    if (exists >= 0) {
      arr[currentStep - 1] = obj;
      dispatch(qaStore(arr));
    } else {
      arr.push(obj);
      dispatch(qaStore(arr));
    }
    dispatch(setTimetaken(Finaltimetaken?.props?.children.join("")));
  };

  const handlePreviousState = () => {
    const arr = [...qaData];

    // const preSelectedOption = arr.filter(function (e) {
    //   return e.serial_number === props.currentStep - 1;
    // });

    const preSelectedOption = arr[props.currentStep - 2];

    if (preSelectedOption.input_type.toLowerCase() === "radio") {
      dispatch(selOption(preSelectedOption.selectedOption));
      dispatch(selScore(preSelectedOption.selectedOptionScore));
      if (preSelectedOption.subquestion) {
        dispatch(setSubquestion(preSelectedOption.subquestionSelectedOption));
      }
    } else if (preSelectedOption.input_type.toLowerCase() === "range") {
      dispatch(selRange(preSelectedOption.selectedOption));
    } else if (preSelectedOption.input_type.toLowerCase() === "checkbox") {
      dispatch(selectMultiOption(preSelectedOption.selectedOption));
      dispatch(selectMultiScore(preSelectedOption.selectedOptionScore));
    } else {
      dispatch(selOption(preSelectedOption.selectedOption));
      dispatch(selScore(preSelectedOption.selectedOptionScore));
    }
  };

  const handleNextState = () => {
    const arr = [...qaData];
    // const preSelectedOption = arr.filter(function (e) {
    //   return e.serial_number === props.currentStep + 1;
    // });

    const preSelectedOption = arr[props.currentStep];

    //console.log(preSelectedOption, "preSelectedOption");

    if (preSelectedOption.input_type.toLowerCase() === "radio") {
      dispatch(selOption(preSelectedOption.selectedOption));
      dispatch(selScore(preSelectedOption.selectedOptionScore));

      if (preSelectedOption.subquestion) {
        dispatch(setSubquestion(preSelectedOption.subquestionSelectedOption));
      }
    } else if (preSelectedOption.input_type.toLowerCase() === "range") {
      dispatch(selRange(preSelectedOption.selectedOption));
    } else if (preSelectedOption.input_type.toLowerCase() === "checkbox") {
      dispatch(selectMultiOption(preSelectedOption.selectedOption));
      dispatch(selectMultiScore(preSelectedOption.selectedOptionScore));
    } else {
      dispatch(selOption(preSelectedOption.selectedOption));
      dispatch(selScore(preSelectedOption.selectedOptionScore));
    }
  };

  const handlePrev = () => {
    if (props.currentStep === 1) {
      handleClick(show);
    } else {
      props.previousStep();
    }
    handlePreviousState();
  };

  const handleNext = () => {
    const arr = [...qaData];
    props.nextStep();
    AddSelectedDatatoqaData();

    if (arr.length <= props.currentStep) {
      dispatch(selRange(""));
      dispatch(selOption(""));
      dispatch(selScore(""));
      dispatch(selectMultiOption([]));
      dispatch(selectMultiScore([]));
      dispatch(setSubquestion(""));
      if (question.input_type === "bmi") {
        dispatch(SetbmiCalculate(""));
      }
    } else {
      handleNextState();
    }

    // if (ctx.loginUser?.is_loggedin && ctx.loginUser?.role === "client") {
    //   UpdateAssessment();
    // }
  };

  const UpdateAssessment = () => {
    axios
      .post(UPDATEASSESSMENTRESULT, {
        assessment_id: category_id,
        result: qaData,
        status: "inprogress",
        assessment_for,
      })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => console.error(err));
  };

  const SubmitAssessment = () => {
    axios
      .post(UPDATEASSESSMENTRESULT, {
        assessment_id: category_id,
        result: qaData,
        status: "completed",
        percentage: (qaData.length / Number(numberOfQues)) * 100 || "",
        time_taken: Timetaken,
        assessment_for,
        relation,
        relation_name: relationName,
        relation_id,
        type: retakeType,
      })
      .then((res) => {
        setSubmitDisable(false);
        if (res.data.status) {
          props.setStart(false);
          navigate("/patient/assessment/results", {
            state: { assessment_for, category_id },
          });
          dispatch(qaStore([]));
          dispatch(setAssessment_for("Myself"));
          dispatch(setNumberOfQues("number"));
          dispatch(setRetakeType("first"));
          // dispatch(resetQaStorage());
        }
      })
      .catch((err) => {
        console.error(err);
        setSubmitDisable(false);
      });
  };

  console.log(isDone, props.enterprise, "hello---");

  const handleSubmit = () => {
    debugger;
    if (ctx?.loginUser?.is_loggedin && ctx?.loginUser?.role === "client") {
      AddSelectedDatatoqaData();
      dispatch(selRange(""));
      dispatch(selOption(""));
      dispatch(selScore(""));
      dispatch(selectMultiOption([]));
      dispatch(selectMultiScore([]));
      // dispatch(resetQaStorage());
      //api call to submit assessment
      setSubmitDisable(true);
      setIsDone(true);
    } else {
      if (props.enterprise) {
        AddSelectedDatatoqaData();
        dispatch(selRange(""));
        dispatch(selOption(""));
        dispatch(selScore(""));
        dispatch(selectMultiOption([]));
        dispatch(selectMultiScore([]));
        // dispatch(resetQaStorage());
        //api call to submit assessment
        setIsDone(true);
      } else {
        props.nextStep();
        AddSelectedDatatoqaData();
        dispatch(selRange(""));
        dispatch(selOption(""));
        dispatch(selScore(""));
        dispatch(selectMultiOption([]));
        dispatch(selectMultiScore([]));
        // dispatch(resetQaStorage());
      }
    }
  };

  let submitStage =
    ctx?.loginUser?.is_loggedin && ctx?.loginUser?.role === "client"
      ? props.currentStep === props.totalSteps
      : props.currentStep === props.totalSteps - 1;
  console.log(ctx, submitStage, "submitStage");
  const w = window.innerWidth;
  const display = w > 600 ? "flex" : "none";

  return (
    <>
      <div className={AssessmentOwnerCSS.outer}>
        {/* Header Component */}
        <div>
          <span id="bmiQuestion"></span>
          <div>
            <ProgressBar progress={progress} />
          </div>
          <div className={AssessmentOwnerCSS.assessmentName}>
            <div
              className={AssessmentOwnerCSS.q_no}
              style={{ display: display }}
            >
              {/* <span>
                <Helpcircle width="23" height="23" color="#7a51ae" />
              </span> */}
              &nbsp;
              {/* <span>
                {question?.serial_number > 9
                  ? `${props.currentStep} of ${props.totalSteps - 1}`
                  : `0${props.currentStep} of ${props.totalSteps - 1}`}
              </span> */}
            </div>
            <div className={AssessmentOwnerCSS.category}>
              <h2>{category.name}</h2>
            </div>

            <div
              className={AssessmentOwnerCSS.timer}
              style={{ visibility: submitDisable ? "hidden" : "" }}
            >
              <img src={Clock} alt="clock" />
              &nbsp;<span id="timer">{Timer()}</span>
            </div>
          </div>
          <div className={AssessmentOwnerCSS.question}>
            <h1>{question?.question}</h1>
          </div>
        </div>
        {/* Header Component Close */}

        {/* Content */}

        <QuestionContent question={question} category={category} />
        {/* {labels.map((label) => {
          return <QuestionContent question={question} category={category} />;
        })} */}

        {/* for subquestion */}
        {question.subquestion &&
          selectedOption === question.subquestion.cond && (
            <QuestionContent
              question={question.subquestion}
              category={category}
              subquestion={true}
            />
          )}

        {/* Content CLose  */}
        {/* footer */}
        <div className={AssessmentOwnerCSS.buttons}>
          <Button
            variant="contained"
            size="small"
            id="back_btn"
            style={{
              textTransform: "none",
              margin: "0 12px",
              width: "160px",
              height: "48px",
              backgroundColor: "#fff",
              color: "#111",
            }}
            onClick={handlePrev}
          >
            Back
          </Button>

          {!submitStage ? (
            <Button
              variant="contained"
              size="small"
              disabled={disabled}
              style={{
                textTransform: "none",
                margin: "0 12px",
                width: "160px",
                height: "48px",
                backgroundColor: "#7A51AE",
              }}
              onClick={handleNext}
            >
              Next
            </Button>
          ) : (
            <Button
              variant="contained"
              size="small"
              disabled={disabled}
              style={{
                textTransform: "none",
                margin: "0 12px",
                width: "160px",
                height: "48px",
                backgroundColor: "#7A51AE",
                // color: "white",
              }}
              onClick={handleSubmit}
            >
              {submitDisable ? (
                <div style={{ color: "#fff", display: "flex" }}>
                  <CircularProgress color="inherit" />
                </div>
              ) : props.enterprise ? (
                "Submit"
              ) : (
                "See Your Results"
              )}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default Question;
