import React from 'react';
import ProgressBarStyles from '../../assets/css/progress.module.css'

export const ProgressBar = (props) => {
    const {color="#3DD365", progress,width="100%", height=4} = props
    return(
        <div className={ProgressBarStyles.bar} style={{width:width}}>
            <div style={{width:`${progress}%`, height:height, backgroundColor: color}}></div>
        </div>
    )
}