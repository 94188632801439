import React from "react";

import Tables from "../../../Hooks/Table";
import ButtonName from "../../../MockData/ButtonName.json";
import PaperWrapper from "../../../Component/UI/PaperWrapper/PaperWrapper";
import BasicBreadcrumbs from "../../../Component/UI/BreadCrumb/BreadCrumbs";
import DScreeningData from "../../../MockData/DementiaScreening.json";



export default function DementiaScreening() {
  const btnApi = ButtonName.Appointments;
  const columns = Object.keys(DScreeningData[0]);

  return (
    <BasicBreadcrumbs link1={"Patients"} link3={"DementiaScreening"}>
      <PaperWrapper>
      <Tables theadData={columns} tbodyData={DScreeningData} />
      </PaperWrapper>
    </BasicBreadcrumbs>
  );
}
