import * as React from "react";
import { styled } from "@mui/material/styles";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

import { CardActionArea } from "@mui/material";

import { Buttons } from "../Button/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import img from "../../../assets/images/Group320.svg";
import img1 from "../../../assets/images/Group321.svg";
import img2 from "../../../assets/images/Group322.svg";
import img3 from "../../../assets/images/Group323.svg";
import img4 from "../../../assets/images/Group324.svg";
import img6 from "../../../assets/images/edit.svg";
import img7 from "../../../assets/images/trash.svg";
import { BsFilter } from "react-icons/bs";
import { useEffect } from "react";
import { getFeedback } from "../../../services/patient/feedback";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const FeedbackCards = () => {
  useEffect(() => {
    getFeedback().then((res) => console.log(res, "feedback_"));
  }, []);

  return (
    <Grid container style={{ marginLeft: "0px" }}>
      <Grid item xs={6} md={4} lg={4} style={{ marginTop: "19px" }}>
        <Box sx={{ margin: "0px" }}>
          <Card
            style={{
              padding: "20px",
              width: "600px",
              borderRadius: "10px",
              paddingBottom: "160px",
            }}
          >
            <Grid container spacing={1} style={{ marginTop: "5px" }}>
              <Grid item xs={6}>
                {" "}
                <h3
                  style={{
                    textAlign: "left",
                    fontSize: "20px",
                    marginBottom: "20px",
                    marginLeft: "5px",
                  }}
                >
                  Give a Feedback
                </h3>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  textAlign: "right",
                  fontSize: "18px",
                  color: "#85219C",
                }}
              ></Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Grid container spacing={1} style={{ marginTop: "5px" }}>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", textAlign: "left" }}
                  >
                    <Grid
                      style={{
                        marginLeft: "5px",
                        fontSize: "14px",
                        marginTop: "0px",
                        fontWeight: "400",
                      }}
                    >
                      <p>Service Name</p>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", textAlign: "left" }}
                  >
                    <Grid
                      style={{
                        marginLeft: "5px",
                        fontSize: "15px",
                        marginTop: "0px",
                      }}
                    >
                      <select
                        style={{
                          padding: "10px 100px 10px 10px",
                          borderRadius: "8px",
                          fontSize: "16px",
                          border: "1px solid #dadada",
                        }}
                      >
                        <option
                          value=""
                          disabled="disabled"
                          selected="selected"
                        >
                          Dementia Care Services
                        </option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                      </select>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={1} style={{ marginTop: "5px" }}>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", textAlign: "left" }}
                  >
                    <Grid
                      style={{
                        marginLeft: "5px",
                        fontSize: "14px",
                        marginTop: "5px",
                        fontWeight: "400",
                      }}
                    >
                      <p>Write Feedback</p>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", textAlign: "left" }}
                  >
                    <Grid
                      style={{
                        marginLeft: "5px",
                        fontSize: "15px",
                        marginTop: "0px",
                      }}
                    >
                      <textarea
                        name="message"
                        id="message"
                        style={{
                          width: "550px",
                          height: "150px",
                          borderRadius: "8px",
                          paddingLeft: "8px",
                          paddingTop: "5px",
                          border: "1px solid #dadada",
                        }}
                      >
                        Write Here
                      </textarea>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={1} style={{ marginTop: "5px" }}>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", textAlign: "left" }}
                  >
                    <Grid
                      style={{
                        marginLeft: "5px",
                        fontSize: "15px",
                        marginTop: "0px",
                      }}
                    >
                      <Buttons
                        name="Submit"
                        style={{
                          backgroundColor: "#7A51AE",
                          marginTop: "0px",
                          padding: "8px 40px 8px 40px",
                          borderRadius: "8px",
                          marginTop: "0px",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Grid>

      <Grid item xs={6} md={4} lg={4}>
        <Box sx={{ margin: "20px" }}>
          <Card
            style={{
              padding: "20px",
              width: "500px",
              borderRadius: "10px",
              paddingBottom: "280px",
              marginLeft: "170px",
            }}
          >
            <Grid container spacing={1} style={{ marginTop: "5px" }}>
              <Grid item xs={12}>
                {" "}
                <h3
                  style={{
                    textAlign: "left",
                    fontSize: "20px",
                    marginBottom: "20px",
                  }}
                >
                  My Feedbacks
                </h3>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Card
                  style={{
                    width: "450px",
                    borderRadius: "10px",
                    marginTop: "0px",
                    border: "1px solid #dadada",
                  }}
                >
                  <CardActionArea>
                    <CardContent>
                      <Grid container spacing={1} style={{ marginTop: "5px" }}>
                        <Grid
                          item
                          xs={10}
                          style={{ display: "flex", textAlign: "left" }}
                        >
                          <Grid
                            style={{
                              marginLeft: "5px",
                              fontSize: "16px",
                              marginTop: "0px",
                              fontWeight: "600",
                            }}
                          >
                            <p>Service</p>
                          </Grid>
                        </Grid>

                        <Grid item xs={1} style={{ textAlign: "end" }}>
                          <img src={img6} />
                        </Grid>

                        <Grid item xs={1} style={{ textAlign: "end" }}>
                          <img src={img7} />
                        </Grid>
                      </Grid>

                      <Grid container spacing={1} style={{ marginTop: "0px" }}>
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex", textAlign: "left" }}
                        >
                          <Grid
                            style={{
                              marginLeft: "5px",
                              fontSize: "15px",
                              marginTop: "0px",
                            }}
                          >
                            <p>Dementia Care Services</p>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container spacing={1} style={{ marginTop: "5px" }}>
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex", textAlign: "left" }}
                        >
                          <Grid
                            style={{
                              marginLeft: "5px",
                              fontSize: "16px",
                              marginTop: "0px",
                              fontWeight: "600",
                            }}
                          >
                            <p>Feedback</p>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex", textAlign: "left" }}
                        >
                          <Grid
                            style={{
                              marginLeft: "5px",
                              fontSize: "15px",
                              marginTop: "0px",
                              paddingBottom: "20px",
                            }}
                          >
                            <p>
                              Healthy sleep also helps the body remain healthy
                              and stave off diseases. Without enough sleep, the
                              brain cannot function properly. This can impair
                              your..Read more
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
};
