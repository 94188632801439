import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const { GET_ENTERPRISE_SERVICE } = API;

export const getEnterpriseActiveService = async (status) => {
  return await axios
    .get(GET_ENTERPRISE_SERVICE + `?status=${status}`)
    .then((res) => res)
    .catch((err) => err);
};
