import React, { useEffect, useState } from "react";

import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import toast, { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { selfCareSelector } from "../../../../Redux/Slices/Instruction";
import axios from "../../../../config/axiosConfig";
import { Editor } from "../../../../Hooks/Editor";
import instance from "../../../../config/axiosConfig";
import { API } from "../../../../config/constant";

const Instruction = ({ id, setCount, flag, ass_id }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { data, loading, hasErrors } = useSelector(selfCareSelector);

  const [inputField, setinputField] = useState("");

  const { cat_id } = useSelector((i) => i.assessmentId);
  const category_id = id === "" || id === undefined ? cat_id : id;

  const addCat = {
    category_id: id,
    instruction: inputField,
    id: id || 0,
  };

  console.log(id, "id___");

  const onSuccess = (res) => {
    sucessNotify(res.data.msg);
    setCount((count) => count + 1);
  };
  const sucessNotify = (msg) => toast.success(msg);

  const errorNotify = (error) => toast.error(error);

  const addDetails = () => {
    try {
      instance.post(API.ASSESSMENTINSTRUCTION, addCat).then((res) => {
        res.data.status && onSuccess(res);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const inputHandler = (value) => {
    setinputField(value);
  };

  useEffect(() => {
    if (id) {
      try {
        axios
          .post(`/admin/get/assessment/instruction?category_id=${id}`)
          .then((res) => {
            setinputField(res?.data?.list);
          });
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  useEffect(() => {
    return () => toast.remove();
  }, []);

  return (
    <>
      <Typography variant="h9" gutterBottom component="div">
        Add Instructions
      </Typography>

      <Editor data={inputField} change={(val) => inputHandler(val)} />

      <Button
        variant="contained"
        style={{
          backgroundColor: "#00B091",
          borderRadius: 50,
          marginTop: 10,
        }}
        onClick={addDetails}
      >
        {!flag ? "Save" : "Update"}
      </Button>
      <Toaster />
    </>
  );
};

export default Instruction;
