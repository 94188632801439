import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import moment from "../../../../node_modules/moment/moment";
import {
  toast,
  Toaster,
} from "../../../../node_modules/react-hot-toast/dist/index";
import { Avatar } from "../../../Component/UI/Avatar";
import BasicBreadcrumbs from "../../../Component/UI/BreadCrumb/BreadCrumbs";
import { CustomTable } from "../../../Component/UI/Table";
import { formatttedDate } from "../../../config/formattedDate";

import { ADMIN_ROUTES } from "../../../Routes/routesConstants";
import { enterpriseSubscriptionsListAdmin } from "../../../services/admin/enterprise";
import { usePermissions } from "../../../Hooks/usePermissions";

export const EnterpriseSubscription = () => {
  const tableHeadings = [
    "Subscription Name",
    "Amount",
    "Date",
    "No of Services",
    "Validity",
    "Status",
  ];
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [subscriptionData, setSubscriptionData] = useState();
  const { from_date, to_date } = useSelector((state) => state.SearchFilters);
  const permissions = usePermissions("name", "enterprises");

  const links = [
    {
      path: ADMIN_ROUTES.ENTERPRISES,
      linkText: "Enterprise",
    },
  ];
  if (state?.data) {
    links.push({
      path: ADMIN_ROUTES.ENTERPRISES_VIEWBOX,
      linkText: state?.data?.company_name,
      data: { ...state?.data, permissions },
    });
  }

  const getListing = () => {
    if (to_date !== "Invalid date") {
      enterpriseSubscriptionsListAdmin({
        enterprise_id: state?.data?.id,
        from_date,
        to_date,
      })
        .then((res) => setSubscriptionData(res?.data?.list))
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    getListing();
  }, [from_date, to_date]);

  let nf = new Intl.NumberFormat("en-US");
  console.log(subscriptionData, "subscriptionData+++");

  const tBodyData = subscriptionData?.map((item) => {
    const {
      name,
      sum_price,
      status,
      created_at,
      validity,
      expiry_at,
      total_service,
    } = item;
    const isExpired = moment(expiry_at).isBefore(new Date(), "day");
    console.log(isExpired, "isExpired");
    return {
      name: (
        <>
          <Link
            to={ADMIN_ROUTES.ENTERPRISE_SUBSCRIPTION_DETAILS}
            style={{
              color: "#000",
              textDecoration: "none",
              display: "flex",
              pointerEvents: "auto",
            }}
            state={{ item, data: state?.data }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                height: 0,
              }}
            >
              {
                <Avatar
                  size="28px"
                  // profile={customer_image}
                  border="1px solid #ccc"
                />
              }
              <span style={{ marginLeft: "8px" }}>{name}</span>
            </span>
          </Link>
        </>
      ),

      amount: `₹${nf.format(sum_price)}`,
      date: formatttedDate(created_at),
      service: total_service,
      validity:
        Number(validity) >= 30
          ? `${validity / 30} ${validity / 30 > 1 ? "Months" : "Month"}`
          : `${validity} Days`,
      status: status.toString() === "0" ? "In Active" : "Active",
    };
  });

  return (
    <>
      <Toaster />
      <BasicBreadcrumbs
        link={links}
        text="Subscriptions"
        isEnableCalender={true}
      >
        <CustomTable
          tHead={tableHeadings}
          tBody={tBodyData}
          message={"No Subscriptions is available for this enterprise."}
        />
      </BasicBreadcrumbs>
    </>
  );
};
