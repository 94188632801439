export const API = {
  //****************ADMIN API *****************
  LOGIN: "admin/login",
  ADDSELFCARETIPS: "/admin/add/selfcare",
  GETSELFCARETIPS: "/admin/get/selfcare",
  DELETESELFCARETIPS: "/admin/delete/selfcare/tip",
  GETQUICKASK: "/admin/get/quickask/category",
  POSTADDQUESTION: "/admin/add/quickask/question",
  GETGENERALQUESTION: "/admin/detail/quickask/category",
  GETGENERALQUESTIONWITHQUES: "/admin/detail/quickask/question",
  DELETEQUICKASK: "/admin/delete/quickask/option",
  STATUSQUICKASKAPI: "/admin/update/status/quickask/category",
  ADDCATEGORYQUICKASK: "/admin/add/quickask/category",
  ADDCATEGORYTODAYACTIVTY: "/admin/add/activity/category",
  TODAYACTIVITYGETCATEGORY: "/admin/get/activity/category",
  TODAYACTIVITYQUESTION: "/admin/detail/activity/category",
  TODAYACTIVITYADDQUESTION: "/admin/detail/activity/question",
  POSTADDQUESTIONTODAYACTIVITY: "/admin/add/activity/question",
  DELETETODAYACTIVITY: "/admin/delete/activity/option",
  STATUSTODAYACTIVITY: "/admin/update/status/activity/category",
  GETPATIENTS: "/admin/get/customer",
  ADDPATIENT: "/admin/add/customer",
  EDITPATIENT: "/admin/update/customer",
  GETCLIENTS: "/admin/get/client",
  GETCLIENTDETAILS: "/admin/detail/client?id=",
  CLIENTSTATUS: "/admin/client/status/change",
  EDITCLIENT: "/admin/update/client",
  ADDCLIENT: "/admin/add/client",
  PATIENTSTATUS: "/admin/change/status/customer",
  GETPATIENTDETAILS: "admin/detail/customer?customer_id=",
  GETEVENTS: "/customer/events?type=",
  ENROLLEVENT: "/customer/enroll/event",
  ENROLLEDEVENTS: "/customer/enrolled/events",
  GETPROFILE: "/customer/profile",
  UPDATEPROFILE: "/customer/update/profile",
  BOOKEDAPPOINMENTS: "customer/booked/services",
  ADDASSESSMENTQUEST: "/admin/add/assessment/question",
  UPDATEASSESSMENTQUEST: "/admin/update/assessment/question",
  DELETEQUESTIONIMAGE: "/admin/delete/question/image",
  CLIENTQUICKASK: "/customer/quick/ask",
  CLIENTSELFCAREPLAN: "/customer/selfcare",
  TODAYACTIVITY: "/customer/activity",
  TODAYACTIVITYTOGGLE: "/customer/answer/activity",
  QUICKASKNEXTBTNAPI: "/customer/answer/quick/ask",
  ASSESSMENTQUESTIONGETDETAILS: "/admin/detail/assessment/questions",
  ASSESSMENTQUESTIONGETUPDATE: "/admin/update/assessment/question",
  ASSESSMENTQUESTIONGETAdd: "/admin/add/assessment/question",
  ASSESSMENTLEVEL: "/customer/assessments",
  GETCOUNTRY: "/admin/get/country",
  GETSTATE: "/admin/get/state?country_id=",
  GETCITY: "/admin/get/city?state_id=",
  GETCOUNTRYCUSTOMER: "/customer/get/country",
  GETSTATECUSTOMER: "/customer/get/state?country_id=",
  GETCITYCUSTOMER: "/customer/get/city?state_id=",
  ADMINDASHBOARD: "/admin/dashboard",
  ADMINDASHBOARD_APPOINTMENT: "/admin/dashboard/appointments",
  ADMINDASHBOARD_PAYMENT: "/admin/dashboard/payment",
  ADDAPPOINTMENT: "/admin/add/appointment",
  UPDATEAPPOINTMENT: "/admin/update/appointment",
  APPOINTMENTDETAILS: "/admin/detail/appointment?id=",
  ASSESSMENTCATEGORYDETAIL: "/customer/assesment/category/detail",
  UPDATEASSESSMENTRESULT: "/customer/update/assessment/result",
  ALLASSESSMENT: "/customer/all/assessment",
  REGISTERCUSTOMER: "/customer/register",
  LOGINCUSTOMER: "/customer/login",
  FORGOTPASSWORD: "/customer/forgot/password?email=",
  RESET_PASSWORD: "/customer/forgot/password?email=",
  ASSESSMENTINSTRUCTION: "/admin/add/assessment/instruction",
  SOCIAL_LOGIN: "/customer/social/login",
  CHANGEPASSWORD: "/customer/update/password",
  SOCIALPASSWORDCHANGE: "/customer/update/social/password",
  SHARE_REPORT_EMAIL: "/customer/send/report",
  DELETEASSESSMENTQUESTION: "/admin/delete/assessment/question?question_id=",
  WELLNESS_TRACKER_ACTIVITY: "/customer/show/activity",
  WELLNESS_TRACKER_ACTIVITY_MONTH: "/admin/selfcarePlan",
  CUSTOMERPAYMENTHISTORY: "/customer/payment",
  GETHEALTH: "/customer/get/health",
  UPDATEHEALTH: "/customer/update/health",
  GETNOTIFICATION: "/customer/get/notification",
  UPDATENOTIFICATION: "/customer/update/notification",
  Assessment_relative: "/customer/relative",
  ADDEMAILTEMPLATE: "/admin/add/template",
  UPDATEMAILTEMPLATE: "/admin/update/template",
  GETEMAILTEMPLATE: "/admin/get/template",
  GET_REPORT: "/customer/getAssessmentReport",
  ENTERPRISE_ASSESSMENT_LIST_ADMIN: "/admin/enterprise/assessments",
  STAFF_LIST_ADMIN: "/admin/list/staff",
  STAFFSTATUS: "/admin/staff/changestatus",
  ENTERPRISE_SUBSCRIPTIONS_LIST_ADMIN: "/admin/enterprise/subscription",
  GET_INDUSTRY_LIST_ADMIN: "/admin/industry",
  GET_DESIGNATION_LIST_ADMIN: "/admin/designation",
  ADD_TESTIMONIAL: "/customer/add/testimonial",
  ADD_FEEDBACK: "customer/add/feedback",
  EDIT_FEEDBACK: "customer/update/feedback",
  DELETE_FEEDBACK: "customer/delete/feedback",
  GET_FEEDBACK: "customer/get/feedback",
  ENTERPRISE_SERVICE_LIST_ADMIN: "/admin/enterprise/service",

  // enterprises
  REGISTER_ENTERPRISE: "/enterprise/register",
  ENTERPRISE_LOGIN_DATA: "/enterprise/getEnterpriseData?enterprise=",
  LOGIN_ENTERPRISE: "/enterprise/login",
  SEND_ASSESSMENT_LINK: "/enterprise/sendAssessmentsLink",
  REQUEST_ASSESSMENT: "/enterprise/assissmentOtherRequest",
  REQUEST_ASSESSMENT_CHANGE: "/enterprise/assissmentUpdateRequest",
  EMPLOYEE_VERIFICATION: "/enterprise/employeeVerification",
  OTP_VERIFICATION: "enterprise/code/verify",
  VERIFY_ACCOUNT_PAGE: "/enterprise/verifyAccount",
  FORGOT_PASSWORD_ENTERPRISE: "/enterprise/forgotPassword",
  UPDATE_PASSWORD: "/enterprise/updatePassword",
  ADD_TESTIMONIALS: "/enterprise/add/testimonial",
  GET_ASSESSMENT_DETAILS: "/enterprise/assesment/detail",
  ENTERPRISE_ASSESSMENT_SAVE: "/enterprise/assesment/save",
  ENTERPRISE_ASSESSMENT_LIST: "/enterprise/assessmentListByEnteprise",
  ENTERPRISE_DASHBOARD_DATA: "/enterprise/getRecordByAssessment",
  GET_ENTERPRISE_PROFILE: "/enterprise/getProfile",
  GET_INDUSTRY_LIST: "/enterprise/industry",
  GET_DESIGNATION_LIST: "/enterprise/designation",
  UPDATE_ENTERPRISE_PROFILE: "/enterprise/updateProfile",
  GET_ENTERPRISE_ALL_ASSESSMENT: "/",
  DOWNLOAD_ENTERPRISE_REPORT: "/enterprise/report",
  GET_UNMAPPED_ASSESSMENTS: "/enterprise/getUnmappedAssessments",
  GET_ENTERPRISE_SERVICE: "/enterprise/service",
  GET_UNMAPPED_SERVICES: "/allEnterpriseService",
  GET_UNMAPPED_TESTIMONIALS: "/enterprise/landing/testimonial?status=2",
  QUESTION_SERIALIZATION: "/admin/update/serial/assessment/question",
  QUESTION_SERIALIZATION_ASK:
    "/admin/update/serial/assessment/quickAskQuestion",
  GET_SLEEP_QUALITY: "/enterprise/enterpriseUserSleepQuality",
  CREATE_ENTERPRISE_EMPLOYEE: "/enterprise/createEnterpriseEmployee",
  SEND_ONBOARD_LINK: "/enterprise/sendOnBoardLink",

  // Patient
  KNOW_OUR_EXPERTS: "/get/expert",
  BOOK_PACKAGES: "/booked/packages",
};
