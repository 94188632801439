import { Fragment } from "react";

export const Share = () => {
  const paths = [
    "M12 5.33301C13.1046 5.33301 14 4.43758 14 3.33301C14 2.22844 13.1046 1.33301 12 1.33301C10.8954 1.33301 10 2.22844 10 3.33301C10 4.43758 10.8954 5.33301 12 5.33301Z",
    "M4 10C5.10457 10 6 9.10457 6 8C6 6.89543 5.10457 6 4 6C2.89543 6 2 6.89543 2 8C2 9.10457 2.89543 10 4 10Z",
    "M12 14.667C13.1046 14.667 14 13.7716 14 12.667C14 11.5624 13.1046 10.667 12 10.667C10.8954 10.667 10 11.5624 10 12.667C10 13.7716 10.8954 14.667 12 14.667Z",
    "M5.72656 9.00684L10.2799 11.6602",
    "M10.2732 4.33984L5.72656 6.99318",
  ];

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      {paths.map((i) => (
        <Fragment key={i}>
          <path
            d={i}
            key={i}
            stroke="#7A51AE"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </Fragment>
      ))}
    </svg>
  );
};
