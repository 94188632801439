import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const { GETEVENTS, ENROLLEVENT, ENROLLEDEVENTS } = API;

export const getEvents = async (type) => {
  return await axios
    .get(GETEVENTS + type)
    .then((res) => res.data.list)
    .catch((err) => err);
};

export const enrollEvent = async (event_id, customer_id) => {
  return await axios
    .post(ENROLLEVENT, { event_id, customer_id })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const enrolledEvents = async () => {
  return await axios
    .get(ENROLLEDEVENTS)
    .then((res) => res.data.list)
    .catch((err) => console.log(err));
};
