import React, { useState } from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Btn } from "../../../../Samvenda-Patient/UI/Button";
import question from "../../../../assets/icon/question.svg";
import { Cross } from "../../../../assets/icons/Cross";
import styles from "./assessment.module.css";
import axios from "../../../../config/axiosConfig";
import { requestEnterpriseAssessment } from "../../../../services/enterprise/assessment";
import ResquestSentIcon from "../../../../assets/icons/RequestSentIcon";
import Popup from "../LinkPopup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "20px",
  outline: "none",
  boxShadow: 12,
  p: 4,
};

export const AssessmentDetailPopup = ({
  popup,
  setPopup,
  assessmentData,
  noquest,
}) => {
  // const [details, setDetails] = useState([]);
  const [popupAction, setPopupAction] = React.useState({
    open: false,
    name: "",
  });

  // useEffect(() => {
  //   axios
  //     .post("/enterprise/getAssessmentDetails", { assissement_id: id })
  //     .then((res) => {
  //       setDetails(res?.data?.list);
  //     });
  // }, [id]);

  const handleRequest = () => {
    requestEnterpriseAssessment({ assissement_id: assessmentData.id })
      .then((res) => {
        if (res.data.status) {
          setPopupAction({
            ...popupAction,
            open: true,
          });
          setPopup(false);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <Modal open={popup}>
        <Box
          sx={style}
          style={{ display: "flex", alignItems: "center" }}
          className={styles.assessmentmodal}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div className={styles.innercardSection}>
              <div>
                <img
                  src={assessmentData?.assessment_image}
                  alt="assessment img"
                  width="100px"
                  height="100px"
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div>
                <div>
                  <h2 style={{ fontSize: "24px", fontWeight: "500" }}>
                    {assessmentData?.name}
                  </h2>
                  {!noquest ? (
                    <div className={styles.questionlines}>
                      <img src={question} alt="question" />
                      <p>{assessmentData?.no_of_question} Questions</p>
                    </div>
                  ) : null}
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "300",
                      margin: "15px 5px",
                      textAlign: "left",
                      lineHeight: "28px",
                      color: "black !important",
                    }}
                  >
                    {assessmentData?.short_description}
                  </p>
                </div>
                <div
                  onClick={() => {
                    setPopup(false);
                  }}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "10px",
                  }}
                >
                  <Cross color="black" size="30" />
                </div>
              </div>
            </div>
            {/* <Btn
              color="#fff"
              bgColor="#3592FF"
              width="111px"
              variant="contained"
              click={handleRequest}
              borderRadius="8px"
            >
              Request
            </Btn> */}
          </div>
        </Box>
      </Modal>
      <Popup
        popupAction={popupAction}
        setPopupAction={setPopupAction}
        children={
          <div className={styles.otherAssContentDetails}>
            <h2>Request Sent</h2>
            <p> We will connect you shortly</p>
          </div>
        }
        icon={<ResquestSentIcon />}
      />
    </div>
  );
};
