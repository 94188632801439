import React from "react";
import classes from "../../../../assets/css/assessmentsummary.module.css";
import Dropdown from "../../../../Component/UI/Dropdown/Dropdown";
import { Input, Select } from "../../../../Component/UI/CustomInputs";

export const Summary = () => {
  return (
    <div className={classes.ill_history}>
      <div className={classes.general_obv}>
        <div>
          <Input label="Summary of Findings" placeholder="Write Details" />
        </div>
        <div>
          <Input label="Impression" placeholder="Write Details" />
        </div>
      </div>
      <div className={classes.general_obv}>
        <div>
          <Input label="Recommentations" placeholder="Write Details" />
        </div>
        <div>
          <Input label="Assessed By" placeholder="Write Details" />
        </div>
      </div>
      <div className={classes.general_obv}>
        <div>
          <Select label="Designation" placeholder="Write Details" />
        </div>
        <div>
          <Input label="RCI No" placeholder="Write Details" />
        </div>
      </div>
      <div className={classes.general_obv}>
        <div>
          <Input label="Reviewed By" placeholder="Ram Krishan Sharma" />
        </div>
        <div>
          <Input label="Review Date" placeholder="22/07/22" />
        </div>
      </div>
    </div>
  );
};
