import React from "react";
import { useState } from "react";

export const Readmore = ({ text }) => {
  const [more, setMore] = useState(false);
  const readLess = () => {
    return '...Read Less'
  }
  return (
    <span
      onClick={() => setMore(!more)}
      style={{
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "24px",
        wordWrap: "break-word",
        color: more ? "#000" : "#7a51ae",
        cursor: more ? "pointer" : "pointer",
        border: '0px'
      }}
    >
      {more ? <><span>{text}</span><span style={{color:"#7a51ae", paddingLeft: '0px'}}>...Read Less</span></> : "...Read more"}
    </span>
  );
};
