import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import StepWizard from "react-step-wizard";
import AuthContext from "../../../Auth/AuthContext";
import { Submition } from "../Submission";
import Question from "./Question";
import QuestionAssessmentFor from "./QuestionAssesmentFor";

import assesmentQuestion from "./AssessmentQuestion.json";
import { SubmitionEnterprise } from "../../../Samvedna-Enterprise/PreLogin/Assessment/SubmitionEnterprise";

const QuestionWrapper = ({
  categoryData,
  show,
  handleClick,
  setStart,
  enterprise,
}) => {
  let custom = {};

  const ctx = useContext(AuthContext);
  const is_loggedin = ctx?.loginUser?.is_loggedin;
  console.log(categoryData, "Rajeev");
  const { InitalData, assessment_for } = useSelector(
    (state) => state.qa_storage
  );

  console.log(InitalData, "InitalData");

  let selectedData = InitalData?.filter(function (e) {
    return e.assesment_for === assessment_for;
  });

  // let assesmentQuestion = InitalData.filter(function (e) {
  //   return e.assesment_for === "Both";
  // });

  let quesData = selectedData?.sort(
    (a, b) => a.serial_number - b.serial_number
  );

  console.log(quesData, "quesData");

  return (
    <>
      <StepWizard transitions={custom}>
        {categoryData?.id === 1 && !is_loggedin && (
          <QuestionAssessmentFor
            number_ofQuestion={categoryData?.no_of_question}
            question={assesmentQuestion}
            category={categoryData}
            show={show}
            handleClick={handleClick}
          />
        )}
        {quesData?.map((ques) => {
          return (
            <Question
              setStart={setStart}
              key={ques.id}
              number_ofQuestion={categoryData?.no_of_question}
              question={ques}
              category={categoryData}
              show={show}
              handleClick={handleClick}
              enterprise={enterprise}
            />
          );
        })}

        {!is_loggedin && !enterprise ? <Submition /> : null}
        {enterprise && <SubmitionEnterprise name={categoryData?.name} />}
      </StepWizard>
    </>
  );
};

export default QuestionWrapper;
