import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PrevIcon } from "../Images/PrevIcon";
import { ForwardIcon } from "../Images/ForwardIcon";
import "./carousel.css";

const PrevArrow = ({ className, style, onClick }) => (
  <button
    className={className}
    style={{ ...style, display: "block" }}
    onClick={onClick}
  >
    <PrevIcon />
  </button>
);
const NextArrow = ({ className, style, onClick }) => (
  <button
    className={className}
    style={{ ...style, display: "block" }}
    onClick={onClick}
  >
    <ForwardIcon />
  </button>
);

export const Carousel = ({
  data,
  Component,
  customProps,
  slides,
  indexItem,
}) => {
  const settings = {
    infinite: true,
    slidesToShow: slides || 1,
    slidesToScroll: 1,
    lazyLoad: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      // {
      //   breakpoint: 1024,
      //   settings: {
      //     slidesToShow: 3,
      //     slidesToScroll: 3,
      //     infinite: true,
      //   },
      // },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {data &&
        data?.map((item) => {
          return (
            <Component
              {...item}
              {...customProps}
              // indexItem={indexItem}
              item={item}
            />
          );
        })}
    </Slider>
  );
};
