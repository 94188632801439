import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "../../../../../node_modules/moment/moment";
import { Toaster } from "../../../../../node_modules/react-hot-toast/dist/index";
import Text_Seach_Filter_Export from "../../../../Hooks/Text_Seach_Filter_Export";
import {
  getAcceptedTestimonials,
  getNewTestimonials,
  getRejectedTestimonials,
  getEnterpriseTestimonials,
} from "../../../../Redux/Slices/adminTestimonial";
import BasicTabs from "../../../UI/Tab/Tabs";
import { AcceptedTestimonial } from "./TabComponents/AcceptedTestimonial";
import { NewTestimonial } from "./TabComponents/NewTestimonial";
import { RejectedTestimonial } from "./TabComponents/RejectedTestimonial";
import { usePermissions } from "../../../../Hooks/usePermissions";
import { findFunc } from "../../../../config/methods";
import { EnterpriseTestimonials } from "./TabComponents/EnterpriseTestimonial";

export const Testimonials = () => {
  const permissions = usePermissions("name", "samvedna");
  const testimonialsPermissions = findFunc(
    permissions?.subCategories,
    "listName",
    "Testimonials"
  );
  const {
    newTestimonials,
    rejectedTestimonials,
    acceptedTestimonials,
    enterpriseTestimonials,
  } = useSelector((i) => i.adminTestimonial);
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNewTestimonials([]));
    dispatch(getAcceptedTestimonials([]));
    dispatch(getRejectedTestimonials([]));
    dispatch(getEnterpriseTestimonials([]));
  }, []);

  const tabData = [
    {
      label: `New Testimonials (${newTestimonials.length})`,
      component: (
        <NewTestimonial testimonialsPermissions={testimonialsPermissions} />
      ),
    },
    {
      label: "Accepted",
      component: (
        <AcceptedTestimonial
          testimonialsPermissions={testimonialsPermissions}
        />
      ),
    },
    {
      label: "Rejected",
      component: (
        <RejectedTestimonial
          testimonialsPermissions={testimonialsPermissions}
        />
      ),
    },
    {
      label: `Enterprise Testimonials (${enterpriseTestimonials.length})`,
      component: (
        <EnterpriseTestimonials
          testimonialsPermissions={testimonialsPermissions}
        />
      ),
    },
  ];

  const exportData = newTestimonials.length
    ? newTestimonials
    : rejectedTestimonials.length
    ? rejectedTestimonials
    : acceptedTestimonials.length
    ? acceptedTestimonials
    : enterpriseTestimonials;

  const exportTestimonialData = exportData.map(
    ({ id, thoughts, customer, created_at, rating }) => {
      const date = moment(created_at).format("DD/MM/YYYY");
      return {
        ID: id,
        Name: customer?.name,
        Date: date,
        Rating: rating,
        Description: thoughts,
      };
    }
  );

  return (
    <>
      <Toaster />
      <Text_Seach_Filter_Export
        ApiData={exportTestimonialData}
        text="Testimonials"
        permissions={testimonialsPermissions}
      >
        <BasicTabs
          tabcontent={tabData}
          value={count}
          setValue={setCount}
          fullWidth
        />
      </Text_Seach_Filter_Export>
    </>
  );
};
