import { createSlice } from "@reduxjs/toolkit";

const assessmentDetailsSlice = createSlice({
  name: "assessment details",
  initialState: {},
  reducers: {
    assessmentDetails: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
  },
});

export const { assessmentDetails } = assessmentDetailsSlice.actions;

export default assessmentDetailsSlice.reducer;
