import axios from "../../config/axiosConfig";
import { API } from "../../config/constant";

const { ADD_TESTIMONIALS } = API;

export const addTestimonials = async (data) => {
  return await axios
    .post(ADD_TESTIMONIALS, data)
    .then((res) => res)
    .catch((err) => console.log(err));
};
