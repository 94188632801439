// id for speak to counsellor

// for demo8
// export const counsellor = {
//   1: 13,
//   2: 22,
//   3: 14,
// };

// for pre production

export const counsellor = {
  1: 13,
  2: 1,
  3: 14,
};
