import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import ButtonName from "../../../MockData/ButtonName.json";
import Clients from "./Clients";
import PatientTable from "./PatientsTable";
import Text_Seach_Filter_Export from "../../../Hooks/Text_Seach_Filter_Export";
import AllTabs from "../../../Component/UI/Tabs/AllTabs";
import ImportFile from "./ImportFile";
import { usePermissions } from "../../../Hooks/usePermissions";
import { useAuth } from "../../../Auth/AuthProvider";
import { useLocation } from "../../../../node_modules/react-router-dom/index";
import moment from "../../../../node_modules/moment/moment";
import { YtoD } from "../../../config/formattedDate";

export default function Patients() {
  const patientsPerms = usePermissions("name", "patients");
  const fullpermissions = +patientsPerms?.listControl?.access?.["Full Control"];

  const ctx = useAuth();

  const condCheckRoles = ctx?.loginUser?.admin?.access_role;
  const btnApi =
    condCheckRoles === "3" || condCheckRoles === "4" || condCheckRoles === "5"
      ? ButtonName.PatientsTab
      : fullpermissions
      ? ButtonName.Patients
      : ButtonName.ImportPerms;

  const ComponentName = [
    <PatientTable permissions={patientsPerms} />,
    <Clients permissions={patientsPerms} />,
    fullpermissions ? <ImportFile /> : "",
  ];

  let defaultTab = Object.keys(btnApi[0]);
  const [tabID, selectedTabID] = useState(defaultTab[0]);

  const Routes = {
    Patients: "patientProfile",
    Clients: "add",
  };

  const CollectAllTabData = {
    Patient: useSelector((i) => i.getPatients),
    Client: useSelector((i) => i.getClients),
  };
  const { state } = useLocation();

  const activeStatus = state?.status;

  const FinalPatientData = CollectAllTabData.Patient?.data?.map((response) => {
    const currentDate = moment().format("MM");
    if (activeStatus === "active" && response?.status !== 1) return {};
    if (activeStatus === "inactive" && response?.status !== 0) return {};
    if (
      activeStatus === "birthday" &&
      moment(response?.dob).format("MM") !== currentDate
    )
      return {};
    if (state?.wellness && !state?.data.includes(response?.id)) return {};

    return {
      ID: response.id,
      Client: response.name,
      Mobile: response.mobile,
      Date: response.dob,
      Status: response.status === 1 ? true : false,
      Address1: response.address_line1,
      Address2: response.address_line2,
      "Zip Code": response.zipcode,
      "Anniversary Date": response.anniversary_date,
      City: response.city_name,
      State: response.state_name,
      Country: response.country_name,
      Email: response.email,
      "Marital Status": response.marital_status,
      Occupation: response.occupation,
      " Year Of Education": response.year_of_education,
    };
  });

  const FinalClientData = CollectAllTabData.Client.data?.map((response) => ({
    "Client Name": response.name,
    "Customer Number": response.customer_number,
    Mobile: response.mobile,
    DOB: response.dob,
    Address1: response.address_line1,
    Address2: response.address_line2,
    "Zip Code": response.zipcode,
    "Anniversary Date": response.anniversary_date,
    "Assessment Result": response.assessment_result,
    City: response.city_name,
    State: response.state_name,
    Country: response.country_name,
    Email: response.email,
    "Marital Status": response.marital_status,
    Occupation: response.occupation,
    " Year Of Education": response.year_of_education,
    Status: response.status === 1 ? true : false,
  }));

  const obj = {
    Patients: FinalPatientData?.filter((obj) => Object.keys(obj).length > 0),
    Clients: FinalClientData?.filter((obj) => Object.keys(obj).length > 0),
  };

  return (
    <Text_Seach_Filter_Export
      text={tabID}
      route={Routes[tabID]}
      ApiData={obj[tabID]}
      searchText={`Search for ${tabID.slice(0, -1).toLowerCase()}`}
      permissions={patientsPerms?.listControl}
    >
      <AllTabs
        ComponentName={ComponentName}
        btnApi={btnApi}
        selectedTabID={selectedTabID}
      />
    </Text_Seach_Filter_Export>
  );
}
