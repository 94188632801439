import React, { useState } from "react";
import moment from "moment";
import ButtonName from "../../../MockData/ButtonName.json";
import { Appointments } from "./Appointments";
import { CancelledAppointment } from "./CancelledAppointment";
import { OnGoingAppointment } from "./OnGoingAppointment";
import { CompletedAppointment } from "./CompletedAppointment";
import AllTabs from "../../../Component/UI/Tabs/AllTabs";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { Search } from "../../../Component/UI/Search";
import { Calender } from "../../../Component/UI/Calender";
import { Filter } from "../../../Component/UI/Filter/Filter";
import { Exports } from "../../../Component/UI/Export/Export";
import CustomizedButtons from "../../../Component/UI/Button/CustomizeButton";
import Text_Seach_Filter_Export from "../../../Hooks/Text_Seach_Filter_Export";
import { usePermissions } from "../../../Hooks/usePermissions";

export const Appointmentss = () => {
  // permission code
  const permissions = usePermissions("name", "appointments");
  console.log(permissions, "permissions");
  // permission end
  const btnApi = ButtonName.Appointments;
  let defaultTab = Object.keys(btnApi[0]);
  const [tabID, selectedTabID] = useState(defaultTab[0]);
  const [upcomingData, setUpcomingData] = useState();
  const [OnGoingData, setOnGoingData] = useState();
  const [CancelledData, setCancelledData] = useState();
  const [CompletedData, setCompletedData] = useState();
  const ComponentName = [
    <Appointments
      setUpcomingData={setUpcomingData}
      permissions={permissions}
    />,
    <OnGoingAppointment
      setOnGoingData={setOnGoingData}
      permissions={permissions}
    />,
    <CancelledAppointment
      setCancelledData={setCancelledData}
      permissions={permissions}
    />,
    <CompletedAppointment
      setCompletedData={setCompletedData}
      permissions={permissions}
    />,
  ];

  const FinalUpcomingData = upcomingData?.map((el) => ({
    Date: moment(el.appointment_date).format("DD-MM-YYYY"),
    "Session Id": el.session_id,
    "Patient Name": el.paitient_name,
    "Service Name": el?.service_name,
    Mobile: el.mobile,
    "Start Time": el.appointment_time,
    "End Time": el.end_time,
    Status: el.status === 1 ? true : false,
  }));

  const FinalOnGoingData = OnGoingData?.map((el) => ({
    Date: moment(el.appointment_date).format("DD-MM-YYYY"),
    "Session Id": el.session_id,
    "Patient Name": el.paitient_name,
    "Service Name": el?.service_name,
    Mobile: el.mobile,
    "Start Time": el.appointment_time,
    "End Time": el.end_time,
    Staff: el.staff,
    Status: el.status === 1 ? true : false,
  }));

  const FinalCancelledData = CancelledData?.map((el) => ({
    Date: moment(el.appointment_date).format("DD-MM-YYYY"),
    "Session Id": el.session_id,
    "Patient Name": el.paitient_name,
    "Service Name": el?.service_name,
    Mobile: el.mobile,
    "Start Time": el.appointment_time,
    "End Time": el.end_time,
    "Cancel Date": el?.updated_at
      ? moment(el?.updated_at).format("DD-MM-YYYY")
      : "",
    Staff: el.staff,
    Status: el.status === 2 && "Cancelled",
  }));

  const FinalCompletedData = CompletedData?.map((el) => ({
    Date: moment(el.appointment_date).format("DD-MM-YYYY"),
    "Session Id": el.session_id,
    "Patient Name": el.paitient_name,
    "Service Name": el?.service_name,
    Mobile: el.mobile,
    "Start Time": el.appointment_time,
    "End Time": el.end_time,
    Staff: el.staff,
    Report: "",
  }));

  const obj = {
    Upcoming: FinalUpcomingData,
    onGoing: FinalOnGoingData,
    Cancelled: FinalCancelledData,
    Completed: FinalCompletedData,
  };

  console.log("tabId", tabID);
  return (
    <>
      {/* <Grid container spacing={2} style={{ margin: "15px 0px", width: "100%" }}>
        <Grid item xs={2.7}>
          <Typography variant="h4" component="div" gutterBottom>
            Appointments
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Search value={"Search"} />
        </Grid>
        <Grid item xs={3}>
          <Calender />
        </Grid>
        <Grid item xs={1}>
          <Filter />
        </Grid>
        <Grid item xs={1}>
          <div>
            <Exports />
          </div>
        </Grid>
        <Grid item xs={1} style={{ marginLeft: "1%" }}>
          <Link to="/add_appointments" style={{ textDecoration: "none" }}>
            <CustomizedButtons ButtonLabel={"Add"} />
          </Link>
        </Grid>
      </Grid> */}
      <Text_Seach_Filter_Export
        text={"Appointments"}
        ApiData={obj[tabID]}
        route="/add_appointments"
        searchText="Search for appointment"
        permissions={permissions?.listControl}
        appointment={true}
      >
        <AllTabs
          ComponentName={ComponentName}
          btnApi={btnApi}
          selectedTabID={selectedTabID}
        />
      </Text_Seach_Filter_Export>
    </>
  );
};
