import React, { useEffect, useState } from "react";
import styles from "../../../assets/css/PatientUI/modal.module.css";
import { RiCloseLine } from "react-icons/ri";
import axios from "../../../config/axiosConfig";
import AuthContext from "../../../Auth/AuthContext";
import { useContext } from "react";
import { Datepicker, Input, Select } from "../CustomInputs";
import { StringDtoY, to12Hour } from "../../../config/formattedDate";
import { useNavigate } from "react-router-dom";
import toast from "../../../../node_modules/react-hot-toast/dist/index";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { emailValidate } from "../../../config/emailValidate";
import { useSelector } from "react-redux";
import { assessmentGet } from "../../../services/patient/assessment";
import { counsellor } from "./speaktocounsellorid";
import { Readmore } from "./ReadMore";
import { consentSubmit } from "../../../services/patient/appointments";
import { useAuth } from "../../../Auth/AuthProvider";
import { getProfile } from "../../../services/patient/profile";

const Modal = ({
  setIsOpen,
  modalData,
  setIsOpenSuccess,
  setIsOpenConstent,
  isOpenConstent,
  is_consent,
  setIsLoading,
  selectEnterPrise,
}) => {
  const today = StringDtoY(new Date());
  const Time = `${new Date().getHours()}:${new Date().getMinutes()}`;
  const getMydata = JSON.parse(sessionStorage.getItem("mydata"));
  const { options, customerRelativeData } = useSelector(
    (state) => state.selectOptions
  );
  const { loginUser } = useAuth();

  const isConsent = Number(loginUser?.customer?.is_consent);
  const [timeSlot, setTimeSlot] = useState();

  const [datetime, setDateTime] = useState({
    start_date: today,
    start_time: "",
  });

  const [msg, setMsg] = useState();
  const [error, setError] = useState(false);
  const ctx = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const customer_id = ctx?.loginUser?.customer?.id;
  const service_id = modalData?.id;
  const [serviceFor, setServiceFor] = useState({
    for: customer_id,
    email: "",
    err: "",
  });
  console.log(serviceFor, "serviceFor");
  const [relativeMail, setRelativeMail] = useState("");
  const [yourselfStatus, setYourselfStatus] = useState(true);

  const [optionsForDropdown, setOptionsForDropdown] = useState([]);
  useEffect(() => {
    //customer/appointment/time-slot
    axios
      .get(
        `/customer/appointment/time-slot-new?appt_date=${datetime?.start_date}`
      )
      .then((res) => {
        const response = res?.data?.list;
        const times = [];
        console.log(times, "times");
        for (let time in response) {
          const addOneHour = +time + 1;
          const value = `${to12Hour(time)} - ${to12Hour(addOneHour)}`;
          if (response[time]) {
            times.push({
              value: null,
              //id: null,
              // id: time + ":00:00",
              // disabled: true,
            });
          } else {
            times.push({
              value,
              id: time + ":00:00",
              disabled: false,
            });
          }
        }
        setTimeSlot(times?.filter((i) => i?.value !== null));
      });
  }, [datetime?.start_date]);

  const start_date = datetime?.start_date;
  const start_time = datetime?.start_time;
  const [isConsentUpdated, setIsConsentUpdated] = useState(false);
  const t1 = moment(start_time, "hh:mm");
  const t2 = moment(Time, "hh:mm");
  const dt = t1.diff(t2, "hour");
  const navigate = useNavigate();

  useEffect(() => {
    const selectedData = customerRelativeData?.relative?.filter((item) => {
      return Number(item.id) === Number(serviceFor.for);
    });

    const selectedEmail = selectedData?.[0]?.email;

    setRelativeMail(selectedEmail ?? "");
    setServiceFor({
      ...serviceFor,
      email: selectedEmail ?? "",
      err: "",
    });
  }, [serviceFor.for]);

  console.log(modalData, "modalData===");

  const bookservice = async (e) => {
    if (!datetime?.start_time) {
      toast.error("Appointment Time  is required");
      return;
    }

    if (selectEnterPrise !== "0") {
      if (
        parseInt(modalData?.consume) === parseInt(modalData?.number_session)
      ) {
        toast.error(
          "All sessions have been exhausted. Please reach out to your organisation for renewal/extension"
        );
        return;
      }
    }

    const Data = new FormData();

    const postBody = {
      enterprise_id: selectEnterPrise !== "0" ? selectEnterPrise : "",
      subscription_id:
        selectEnterPrise !== "0" ? modalData?.subscription_id : "",
      service_id: service_id,
      customer_id: serviceFor.for,
      start_date: start_date,
      start_time: start_time,
      ...(serviceFor.for !== customer_id && { email: serviceFor.email }),
    };

    let [currentDateYear, currentDateMonth, currentDateDate] = today.split("-");
    let [selectedDateYear, selectedDateMonth, selectedDateDate] =
      start_date.split("-");

    const currentDate = moment([
      currentDateYear,
      currentDateMonth - 1,
      currentDateDate,
    ]);
    const selectedDate = moment([
      selectedDateYear,
      selectedDateMonth - 1,
      selectedDateDate,
    ]);

    const dayDifference = selectedDate.diff(currentDate, "days");

    const bookService = async () => {
      try {
        const response = await axios.post(`/customer/book/service?`, Data);
        if (response.data.status) {
          setError(false);
          setMsg(response?.data?.messege);
          navigate("/patient/Appointments");
        } else {
          setError(true);
          setMsg(response?.data?.messege);
        }
      } catch (error) {
        setError(true);
        setMsg(error.response.data.messege);
        // setError(true);
      }
    };

    //code added by ajay Kumar
    const loadScript = (src) => {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          resolve(false);
        };
        document.body.appendChild(script);
      });
    };

    const displayRazorpay = async () => {
      const API =
        Number(serviceFor.for) !== customer_id
          ? "/customer/generate-order-relative"
          : "/customer/generate-order";
      try {
        const res = await loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!res) {
          alert("Razorpay SDK failed to load. Are you online?");
          return;
        }
        setLoading(true);

        const { data } = await axios.post(API, postBody);
        console.log("data", data);
        if (!data.status) {
          /* toast.error(data?.message); */
          setServiceFor({
            ...serviceFor,
            err: data?.message?.email,
          });
          setLoading(false);
        } else {
          if (data?.data?.payment_require) {
            const options = {
              key: process.env.REACT_APP_PAYMENT_KEY,
              currency: data.data.currency,
              amount: "0",
              order_id: data.data.order_id,
              name: modalData?.name,
              description: "Thank you for choosing samvednacare.com",
              // image: 'http://localhost:1337/logo.svg',
              handler: async (response) => {
                const paymentStatus = await axios.post(
                  "/customer/verify-payment",
                  { ...response, ...postBody }
                );
                if (paymentStatus.data.status) {
                  setIsOpenSuccess({
                    open: true,
                    body: "Your request for appointment has been received. Please check your email for further details",
                  });
                  // toast.success(paymentStatus.data.messege);
                  setLoading(false);
                  setIsOpen(false);
                  setTimeout(() => {
                    navigate("/patient/Appointments");
                  }, 2000);
                }
              },
              modal: {
                ondismiss: function () {
                  setLoading(false);
                  setIsOpen(false);
                  toast.error("Your service was not booked");
                },
              },
              prefill: {
                name: ctx?.loginUser?.customer?.name,
                email: ctx?.loginUser?.customer?.email,
                phone_number: ctx?.loginUser?.customer
                  ? ctx?.loginUser?.customer?.mobile
                  : "",
              },
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
          } else {
            setLoading(false);
            setIsOpen(false);
            setIsOpenSuccess({
              open: true,
              body: "Your request for appointment has been received. Please check your email for further details",
            });
            //toast.success("Your appointment has been booked successfully.");
          }
        }
      } catch (err) {
        toast.error(err.response.data.messege);
        setLoading(false);
        setIsOpen(false);
      }
    };

    if (dayDifference > 0) {
      displayRazorpay();
      // bookService()
    } else if (dt > 0) {
      displayRazorpay();
      // bookService();
    } else {
      console.log({ dt });
      if (dt < 1) {
        toast.error("Next Available Appointment is after one hour !");
      } else {
        toast.error("Not able to Book Appointment");
      }
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);
    consentSubmit({
      customer_id: getMydata?.customer?.id,
    }).then((res) => {
      if (res?.data?.status) {
        setIsConsentUpdated((prev) => !prev);
        // setOpenReschedule({ open: false });
        toast.success(res?.data?.msg);
        setIsOpenConstent({ open: false });
        setIsLoading(false);
        bookservice();
        //setIsOpen(true);
        // setOpen(false);
        // navigate("/patient/Appointments");
      }
    });
  };

  useEffect(() => {
    assessmentGet().then((res) => {
      let dementiaYourselfData = res.filter((item) => {
        return item.id === 1;
      });
      setYourselfStatus(
        dementiaYourselfData?.[0].is_mapped?.status === "completed"
      );
    });
  }, []);

  useEffect(() => {
    setOptionsForDropdown(OptionsForDropdown);
  }, [yourselfStatus]);

  const OptionsForDropdown =
    counsellor?.[1] === modalData?.id && !yourselfStatus
      ? options.filter((item) => item !== null && item?.value !== "Yourself")
      : options.filter((item) => item !== null);

  useEffect(() => {
    setServiceFor({
      ...serviceFor,
      for: optionsForDropdown?.filter((item) => item !== null)?.[0]?.id,
    });
  }, [optionsForDropdown, options]);

  const feedBackText = (...c) => modalData?.description?.slice(...c);

  useEffect(() => {
    getProfile().then((i) => {
      ctx.auth({
        ...ctx.loginUser,
        customer: { ...ctx.loginUser.customer, ...i },
      });
    });
  }, [isConsentUpdated]);

  return (
    <>
      <divn onClick={() => setIsOpen(false)} />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <div className={styles.heading}>
              <div style={{ width: "100%" }}>Book Your Service</div>
              <button
                className={styles.closeBtn}
                onClick={() => setIsOpen(false)}
              >
                <RiCloseLine />
              </button>
            </div>
          </div>

          <div className={styles.modalContent}>
            <div className={styles.modelheader}>
              <div className={styles.modelheaderInner}>
                <p className={styles.titleHead}>Service</p>
                <p className={styles.modelDescription}>
                  {modalData.name ? modalData.name : "-"}
                </p>
              </div>
              <div className={styles.modelheaderInner}>
                <p className={styles.titleHead}>Amount</p>
                <p className={styles.modelDescription}>
                  {modalData.amount === "0"
                    ? "Free"
                    : `Rs. ${modalData.amount}`}
                </p>
              </div>
              <div className={styles.modelheaderInner}>
                <p className={styles.titleHead}>Type of Service</p>
                <p className={styles.modelDescription}>
                  {modalData?.type_of_service === "0" ? (
                    <span
                      style={{
                        backgroundColor: "#F19204",
                        color: "#ffffff",
                        width: "78px",
                        height: "24px",
                        padding: "5px",
                        fontSize: "12px",
                        textAlign: "center",
                        borderRadius: "20px",
                        textTransform: "uppercase",
                      }}
                    >
                      Offline
                    </span>
                  ) : modalData?.type_of_service === "1" ? (
                    <span
                      style={{
                        backgroundColor: "#18BA4B",
                        color: "#ffffff",
                        width: "78px",
                        height: "24px",
                        fontSize: "12px",
                        padding: "5px",
                        textAlign: "center",
                        borderRadius: "20px",
                        textTransform: "uppercase",
                      }}
                    >
                      Online
                    </span>
                  ) : modalData?.type_of_service === "2" ? (
                    <span
                      style={{
                        backgroundColor: "#047FF1",
                        color: "#ffffff",
                        width: "78px",
                        height: "24px",
                        padding: "5px",
                        fontSize: "12px",
                        textAlign: "center",
                        borderRadius: "20px",
                        textTransform: "uppercase",
                      }}
                    >
                      Hybrid
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            </div>

            {/* <div style={{ width: "100%" }}>{modalData.name}</div> */}
            <p className={styles.titleHead}>Details</p>
            <p className={styles.doctorDescriptiontag}>
              {feedBackText(0, 140)}
              {feedBackText(0).length > 150 ? (
                <Readmore text={feedBackText(140)} />
              ) : null}
            </p>
            {/* <div style={{ lineHeight: "50px" }}>
              <h3>
                Amount
                <span style={{ fontWeight: "500", marginLeft: "10px" }}>
                  Rs. {modalData.amount}
                </span>
              </h3>
            </div> */}
          </div>

          <form
            className={styles.bookServices}
            onSubmit={(e) => {
              e.preventDefault();
              isConsent === 0
                ? setIsOpenConstent({
                    open: true,
                    consent: false,
                    action: () => handleSubmit(),
                  })
                : bookservice();
            }}
          >
            <div className={styles.forms}>
              {options.filter((item) => item !== null).length &&
                options.filter(
                  (item) => item?.value !== "Yourself" && item !== null
                ).length &&
                modalData &&
                modalData?.category?.toString() === "1" && (
                  <div className={styles.app_time}>
                    <>
                      <div
                        style={{
                          marginBottom: "0",
                          lineHeight: "15px",
                          fontSize: "15px",
                          letterSpacing: "0.02857em",
                        }}
                      >
                        Book an Appointment:
                      </div>

                      <div
                        //style={{ width: "222px" }}
                        className={styles.options}
                      >
                        <Select
                          options={optionsForDropdown}
                          width="260px"
                          value={serviceFor.for}
                          onChange={(e) =>
                            setServiceFor({
                              ...serviceFor,
                              for: e.target.value,
                            })
                          }
                        />
                      </div>
                    </>
                  </div>
                )}
              {Number(serviceFor.for) !== customer_id &&
                options.filter(
                  (item) => item !== null && item.value !== "Yourself"
                ).length && (
                  <div className={styles.start_time}>
                    <div
                      style={{
                        marginBottom: "0",
                        lineHeight: "15px",
                        fontSize: "15px",
                        letterSpacing: "0.02857em",
                      }}
                    >
                      Enter their Email:
                    </div>
                    <Input
                      placeholder="Email"
                      value={serviceFor.email}
                      disabled={relativeMail}
                      width="260px"
                      error={serviceFor.err}
                      onChange={(e) => {
                        const emailCheck = emailValidate(e.target.value);
                        setServiceFor({
                          ...serviceFor,
                          email: e.target.value,
                          err: emailCheck ? "" : "The email is invalid!",
                        });
                      }}
                    />
                  </div>
                )}
            </div>
            <div className={styles.forms}>
              <div className={styles.app_time}>
                <Datepicker
                  label="Appointment Date:"
                  name="start_date"
                  // display="flex"
                  minDate={new Date()}
                  width="260px"
                  editable
                  defaultDate={datetime?.start_date}
                  onChange={(e) =>
                    setDateTime({
                      ...datetime,
                      start_date: StringDtoY(e),
                    })
                  }
                />
              </div>
              <div className={styles.start_time}>
                <div
                  style={{
                    marginBottom: "0",
                    lineHeight: "45px",
                    fontSize: "15px",
                    letterSpacing: "0.02857em",
                  }}
                >
                  Appointment Time:
                </div>
                <Select
                  name="start_time"
                  className={styles.timeSlot}
                  value={datetime.start_time}
                  width="260px"
                  fontSize={15}
                  marginTop={0}
                  labelSize="14px"
                  // error={error?.service_id}
                  options={timeSlot}
                  onChange={(e) => {
                    var date = e.target.value.split("-");
                    setDateTime({
                      ...datetime,
                      start_time: date.at(0),
                    });
                  }}
                  //disabled={expiryDate}
                  req
                />
                {/* <input
                  type="time"
                  name="start_time"
                  value={datetime.start_time}
                  required
                  min="09:00:00"
                  max="19:00:00"
                  onChange={(e) =>
                    setDateTime({
                      ...datetime,
                      start_time: e.target.value,
                    })
                  }
                  className={styles.inputfield}
                ></input> */}
              </div>
            </div>

            <div className={styles.modalActions}>
              <div className={styles.actionsContainer}>
                {!loading ? (
                  <>
                    <button
                      style={{
                        border: "1px solid #000000",
                        marginRight: "10px",
                      }}
                      className={styles.cancleBtn}
                      onClick={() => setIsOpen(false)}
                    >
                      Cancel
                    </button>
                    <button className={styles.deleteBtn} type="submit">
                      Send
                    </button>
                  </>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress size={30} />
                    <p style={{ color: "black" }}>
                      Please wait ! We are validating the details...
                    </p>
                  </div>
                )}
              </div>

              {error && (
                <div
                  style={{
                    padding: "12px",
                    color: "#000000",
                    textAlign: "center",
                  }}
                >
                  <p style={{ color: "black" }}>{msg}</p>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Modal;
