import React, { useEffect, useState } from "react";
import styles from "./BookNow.module.css";
import { RiCloseLine } from "react-icons/ri";
import { Input, Select } from "../../../../Component/UI/CustomInputs";
import { Btn } from "../../../../Samvenda-Patient/UI/Button";
import { useNavigate } from "react-router-dom";
import { getBookPackages } from "../../../../services/patient/bookNow";
import CustomSelect from "./CustomSelect";
import { CircularProgress } from "../../../../../node_modules/@mui/material/index";
//import pic from "../../../assets/images/doctor.png";

const BookNowModel = ({
  stylesNew,
  selectedValue,
  setPopupAction,
  popupAction,
  setSuccessPopupAction,
}) => {
  const navigate = useNavigate();
  const [err, setErr] = useState({});
  const [selectedOption, setSelectedOption] = useState();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState({
    package_name: popupAction?.data?.planName,
    price: selectedValue,
  });
  const reqfields = ["name", "person_email", "price", "mobile"];
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      const r = /^[a-zA-Z\s]*$/;
      if (!r.test(value)) return;
    }
    setFormData({ ...formData, [name]: value });
    setIsExpanded(false);
  };

  const handleSend = () => {
    const errObj = {};
    reqfields.forEach((i) => {
      const key = i.charAt(0).toUpperCase() + i.slice(1);
      if (!Object.keys(formData).includes(i)) {
        if (i === "person_email") errObj[i] = `Email field is required.`;
        else errObj[i] = `${key} field is required.`;
      } else if (!formData[i].length) {
        errObj[i] = `${key} field is required.`;
      }
    });
    // const emailRgx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (!emailRgx.test(formData.person_email)) {
    //   return (errObj["person_email"] = `Email field is invalid.`);
    // }
    setErr(errObj);
    // console.log(popupAction, 'popupAction')

    if (!!Object.keys(errObj).length) return;
    setIsLoading(true)
    getBookPackages(formData)
      .then((res) => {
        setIsLoading(false)
        if (res?.data?.status === true) {
          setPopupAction({ ...popupAction, open: false });
          setSuccessPopupAction((prev) => {
            return { ...prev, open: true };
          });
          // console.log("response", res);
          // navigate("/user/signin");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // console.log(formData, 'formdata---');
  };

  console.log(formData, 'formData---')

  return (
    <>
      <div
        onClick={() =>
          setPopupAction((prev) => {
            return { ...prev, open: false };
          })
        }
      />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <div className={styles.heading}>
              <div style={{ width: "100%" }}>Book Package</div>
              {
                <button
                  className={styles.closeBtn}
                  onClick={() =>
                    setPopupAction((prev) => {
                      return { ...prev, open: false };
                    })
                  }
                >
                  <RiCloseLine style={{ marginBottom: "-3px" }} />
                </button>
              }
            </div>
          </div>

          <div className={styles.modalContent}>
            <div style={{ marginBottom: "15px" }}>
              <p
                className={stylesNew.inputLabel}
                style={{ fontSize: "14px", marginBottom: "10px" }}
              >
                {popupAction?.data?.planName}
              </p>
              <div className={stylesNew.radioBtnTable}>
               <CustomSelect 
               popupAction={popupAction?.data?.selectOptions} 
               handleChange={handleChange}
               selectedOption={selectedOption}
               setSelectedOption={setSelectedOption}
               isExpanded={isExpanded}
               setIsExpanded={setIsExpanded}
               formData={formData}
               />
                {/* {popupAction?.data?.selectOptions?.map(
                  (item) =>
                    item?.value && (
                      <label className={stylesNew.formControl}>
                        <p
                          className={stylesNew.labelPricePopup}
                        >{`₹${item?.value}`}</p>
                        {item?.session && (
                          <p className={stylesNew.subLabelPrice}>{`(${
                            item?.session
                          } ${item?.session > 1 ? "Sessions" : "Session"})`}</p>
                        )}

                        <div className={stylesNew.radioBtn}>
                          
                          <input
                            style={{ margin: "0 20px" }}
                            checked={
                              formData?.price
                                ? item?.value === formData?.price
                                : item?.value ===
                                  popupAction?.data?.selectedValue
                            }
                            onChange={(e) => handleChange(e)}
                            name={"price"}
                            value={item?.value}
                            type={"radio"}
                          />
                        </div>
                      </label>
                    )
                )} */}
              </div>
              <p style={{ color: "red" }}>{err.price}</p>

              {/* <Select
                label={popupAction?.data?.planName}
                name="month"
                options={popupAction?.data?.selectOptions}

                // value={selected}
                // onChange={(e) => {
                //   setSelected(e.target.value);
                // }}
              /> */}
            </div>
            <div style={{ marginBottom: "15px" }}>
              <Input
                label="Full Name"
                type="text"
                id="fullName"
                error={err?.name}
                value={formData?.name}
                name="name"
                labelSize={14}
                onChange={(e) => handleChange(e)}
                placeholder="Your Full Name"
                style={{ outlineColor: "#7a51ae" }}
                autoComplete="off"
                color="#2A2A2A"
                req
                //error={error?.email}
              />
            </div>
            <div style={{ marginBottom: "15px" }}>
              <Input
                label="Email Address"
                type="email"
                id="email"
                value={formData?.person_email}
                error={err?.person_email}
                name="person_email"
                labelSize={14}
                onChange={(e) => handleChange(e)}
                placeholder="Your Email Address"
                style={{ outlineColor: "#7a51ae" }}
                autoComplete="off"
                color="#2A2A2A"
                req
                //error={error?.email}
              />
            </div>
            <div style={{ marginBottom: "15px" }}>
              <Input
                label="Mobile Number"
                type="number"
                id="mobile"
                value={formData?.mobile}
                error={err?.mobile}
                name="mobile"
                labelSize={14}
                onChange={(e) => handleChange(e)}
                placeholder="Your Mobile Number"
                style={{ outlineColor: "#7a51ae" }}
                autoComplete="off"
                color="#2A2A2A"
                req
                //error={error?.email}
              />
            </div>
            <div style={{ marginBottom: "15px" }}>
              <Input
                label="Message"
                type="text"
                id="message"
                value={formData?.message}
                name="message"
                labelSize={14}
                onChange={(e) => handleChange(e)}
                placeholder="Type your message"
                style={{ outlineColor: "#7a51ae" }}
                autoComplete="off"
                color="#2A2A2A"
                //error={error?.email}
              />
            </div>
            <div>
              {
                isLoading &&  <div style={{ textAlign: "center", marginTop: '40px', marginBottom: '20px' }}>
                <CircularProgress size={30} />
                <p style={{ color: "black" }}>
                  Please wait ! We are validating the details...
                </p>
              </div>
              }
              <Btn
                click={() => handleSend()}
                // click ={rows?.bookNowBtn?.click}
                variant="contained"
                bgColor="#7A51AE"
                width="100%"
                height="48px"
                whiteSpace="nowrap"
                //disabled={rows?.bookNowBtn?.disabled}
                borderRadius="8px"
              >
                Send
              </Btn>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookNowModel;
